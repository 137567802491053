import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const validationSchema = yup.object({
    role_id: yup.string().required(VALIDATION.required),
    payroll_period: yup.string().required(VALIDATION.required),
    adjustments: yup.array().of(
        yup.object().shape({
            adjustment_name: yup.string().required(VALIDATION.required),
            adjustment_description: yup.string().required(VALIDATION.required),
            adjustment_amount: yup.string().required(VALIDATION.required),
        })
    )
})

