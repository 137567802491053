import React, { useRef, useState, useContext, useEffect } from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Overlay,
  Tooltip,
  Dropdown,
  Row,
  Col,
  OverlayTrigger
} from 'react-bootstrap'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { styled } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'

import { MainContext } from '../../../context/MainContext'
import "./style.css";
import { Link } from 'react-router-dom'
import Image from "react-bootstrap/Image";
import {DrawerHeader} from "../../Drawer/StyledComponents";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginLeft: open ? drawerWidth.open : drawerWidth.close,
  // width: `calc(100% - ${open ? drawerWidth.open : drawerWidth.close}px)`,
  [theme.breakpoints.down('sm')]: {
    // width: `${open ? 100 : 67 }%!important` as any,
    // mr: `${open ? 0 : 40 }px`,
    // "300px!important" as any
    width: '100% !important' as any,
    zIndex: '1',
  },
}))

const AppBar = () => {

  const mainCont = useContext(MainContext)

  const redirectToLogin = () => {
    window.location.replace('/')
  }



  return (
    <StyledAppBar
      className={mainCont?.openDrawer ? 'app-bar-open' : 'app-bar-close'}
      style={{
        background: 'white',
        border: '1px transparent linear-gradient(90deg, #F26930 0%, #FBA91A 100%'

      }}
      elevation={0}
      position='fixed'
      open={mainCont?.openDrawer}
    >
      <Toolbar>
        <Link to='/'>
          <Avatar className='bg-transparent' sx={{ width: 45, height: 50 }}>
            <Image
                className='text-center'
                style={{
                  width: '100%',
                }}
                src='/Octaneware.png'
            />
          </Avatar>
        </Link>
        <Box sx={{ flexGrow: 1 }} />

        <>
          <Button
            style={{ height: '38px', color: '#F2692F' }}
            size={'sm'}
            variant='primary'
            className='btn text-white mx-1'
            onClick={() => redirectToLogin()}
          >
            Get Started Now
          </Button>{' '}
        </>

        <Box sx={{ flexGrow: 0.01 }} />



      </Toolbar>
    </StyledAppBar>
  )
}

export default AppBar
