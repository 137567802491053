import React from 'react'

const HomeIcon = () => {
  return (
    <svg id='Home' xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'>
      <g id='Rectangle_159' data-name='Rectangle 159' fill='none' stroke='#a7a9ac' strokeWidth='1'>
        <rect width='8' height='9' stroke='none' />
        <rect x='0.5' y='0.5' width='7' height='8' fill='none' />
      </g>
      <g
        id='Rectangle_160'
        data-name='Rectangle 160'
        transform='translate(11 9)'
        fill='none'
        stroke='#a7a9ac'
        strokeWidth='1'
      >
        <rect width='7' height='9' stroke='none' />
        <rect x='0.5' y='0.5' width='6' height='8' fill='none' />
      </g>
      <g
        id='Rectangle_161'
        data-name='Rectangle 161'
        transform='translate(11)'
        fill='none'
        stroke='#f26930'
        strokeWidth='1'
      >
        <rect width='7' height='7' stroke='none' />
        <rect x='0.5' y='0.5' width='6' height='6' fill='none' />
      </g>
      <g
        id='Rectangle_162'
        data-name='Rectangle 162'
        transform='translate(0 11)'
        fill='none'
        stroke='#f26930'
        strokeWidth='1'
      >
        <rect width='8' height='7' stroke='none' />
        <rect x='0.5' y='0.5' width='7' height='6' fill='none' />
      </g>
    </svg>
  )
}

export default HomeIcon
