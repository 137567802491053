import React from 'react'

const Write = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16.143'
      height='16.268'
      viewBox='0 0 16.143 16.268'
    >
      <g id='Edit' transform='translate(0.5 0.627)'>
        <g id='Icon_feather-edit' data-name='Icon feather-edit' transform='translate(-3 -2.818)'>
          <path
            id='Path_44'
            data-name='Path 44'
            d='M9.773,6H4.505A1.505,1.505,0,0,0,3,7.505V18.04a1.505,1.505,0,0,0,1.505,1.505H15.04a1.505,1.505,0,0,0,1.505-1.505V12.773'
            transform='translate(0 -1.586)'
            fill='none'
            stroke='#a7a9ac'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
          <path
            id='Path_45'
            data-name='Path 45'
            d='M19.9,3.286a1.6,1.6,0,0,1,2.258,2.258L15.01,12.692,12,13.445l.753-3.01Z'
            transform='translate(-4.485 0)'
            fill='none'
            stroke='#f26930'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
        </g>
      </g>
    </svg>
  )
}

export default Write
