import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup, Modal,
  Row,
  Stack,
  Table,
} from 'react-bootstrap'
import './style.css'
import Avatar from '@mui/material/Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import View from 'asset/SvgComponent/View'
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import DownloadOrange from 'asset/SvgComponent/DownloadOrange'
import Download from 'asset/SvgComponent/Download'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import axios from 'api/index'
import {
  API_ENDPOINT,
  PAYROLL_COMPUTATION, 
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { IAPI_Response } from 'api/types'
import { IEmployee, IData } from './types'
import moment from "moment"
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

export default function Index() {

  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [success, setSuccess] = React.useState('')
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)

  const [lists, setData] = useState<IData[]>([])
  const [dateFormat, setDateFormat] = useState('')

  const [showUpload, setShowUpload] = React.useState(false)
  const [uploadFile, setUploadFile] = React.useState({});
  const [searched, setSearched] = React.useState('');

  const handleShowUpload = () => {
    setShowUpload(true);
  }
  const handleCloseUpload = () => {
    setShowUpload(false);
  }

  const handleDownloadSampleFormat = async () => {
    console.log("download")

    try {
      const response = await axios.get(API_ENDPOINT.DOWNLOAD_EMPLOYEE_SAMPLE_FORMAT, {
        headers: {
          'Content-Type': 'blob'
        },
        responseType: 'arraybuffer'
      })
      const outputFilename = `Employee_Upload_Sample_Format.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();

      FileSaver.writeFileSync(outputFilename, response.data);

    } catch {
      // setShowError(true)
      // setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
  }

  const handleOnSearch = async () => {
    if(searched != ""){
      try {
        const res = await axios.get<IAPI_Response<IEmployee>>(API_ENDPOINT.GET_EMPLOYEE_BY_SEARCH, {
          params: {
            page: null,
            searched: searched
          },

        })
        const result = await res
        if (result?.data?.results?.data?.length) {
          setData(result?.data?.results?.data)
          setCurrentPage(result?.data?.results?.current_page)
          setLastPage(result?.data?.results?.last_page)
          setPerPage(result?.data?.results?.per_page)
          setItemCount(result?.data?.results?.total)
          setFrom(result?.data?.results?.from)
        }else{
          setData([])
        }
        setShowLoading(false)
      } catch {
        setShowError(true)
        setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }

      setTimeout(() => {
        setShowError(false)
      }, 3000);
    }else{
      getEmployee(null)
    }
  }

  const uploadEmployees = async () => {
    await axios.post(API_ENDPOINT.BULK_UPLOAD_EMPLOYEE, uploadFile).then(response => {

      if (response?.data?.status * 1 === 1) {
        // setShow(false)
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.EMPLOYEE_SUCCESSFULLY_UPLOAD)
        // getPayrollRange(null)
        // setShowEdit(false)
        // navigate('/employeeManagement/employee-list');
      }
    }).catch(error => {
      setShowError(true)
      setError(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);

    setShowUpload(false);
  }

  const handleChangeUploadFile = (e : any) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt?.target?.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws,{range:1, raw: false});
      setUploadFile(data);
    };
    reader.readAsBinaryString(file);


  }

  const getEmployee = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const res = await axios.get<IAPI_Response<IEmployee>>(API_ENDPOINT.GET_EMPLOYEE, {
        params: {
          page: page_number,
        },
      })
      const result = await res
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  useEffect( () => {
    const timer = setTimeout(() => {
      handleOnSearch()
    }, 300)

    return () => clearTimeout(timer)
  }, [searched])

  useEffect(() => {
    ;(async () => {
      getEmployee(null)
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Employee List</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='#'>
            Employees
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Employee List</Breadcrumb.Item>
        </Breadcrumb>

        <Alert
            type="success"
            show={showSuccess}
            message={success}
            setShow={() => {
              setShowSuccess(false);
            }
            }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={4}>
                <span className='fw-bold'>Employee List</span>
              </Col>
              <Col sm={8} className='d-flex justify-content-end hidden'>
                <InputGroup size='sm' className={'w-25'}>
                  <Form.Control
                    aria-label='search'
                    aria-describedby='inputGroup-sizing-sm'
                    placeholder='Search...'
                    onChange={e => setSearched(e.target.value)}
                  />
                  <InputGroup.Text className='bg-transparent search-input '>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
                <Button
                    className=' add-new-btn rounded mx-2 text-white'
                    variant='primary'
                    onClick={handleShowUpload}
                >
                  <DownloadOrange/> <span className="d-none d-md-inline"> &nbsp; Bulk Upload</span>
                </Button>
                <Link
                  key='/employeeManagement/add-employee'
                  to='/employeeManagement/add-employee'
                  className='links'
                >
                  <Button
                    className=' add-new-btn rounded mx-2 text-white'
                    variant='warning'
                    title={"Add New Employee"}
                    >
                    <EditIcon/><span className="d-none d-md-inline"> &nbsp; Add New Employee</span>
                  </Button>
                </Link>
                <Button
                  className=' add-new-btn rounded me-12 text-white'
                  variant='primary'
                  title={"Download Employee"}
                  >
                  <DownloadOrange/><span className="d-none d-md-inline"> &nbsp; Download Employee</span>
                </Button>
              </Col>
            </Row>
            {/* <hr/>*/}
            <Table responsive className='bordered-tabs-top mt-3 employee-list'>
              <thead>
                <tr className='text-center'>
                  <th>No.</th>
                  <th>Employee Name</th>
                  <th>Department</th>
                  <th>Designation</th>
                  <th>Worksite</th>
                  <th>Branch</th>
                  <th>Phone No.</th>
                  <th>Date of Joining</th>
                  <th style={{ width: '50px' }}>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                {lists.length > 0 ? (
                  lists.map((item, index) => (
                    <tr className='mb-2' key={index}>
                      <td>{from + index}</td>
                      <td>
                        <Stack direction='horizontal' style={{cursor: 'pointer'}}>
                          <div>
                            {
                              item.photo != "" ?
                                  <Avatar
                                      sx={{ width: 20, height: 20 }}
                                      src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                  ></Avatar> :
                                  <Avatar
                                      sx={{ width: 20, height: 20}}
                                      src={""}
                                  ></Avatar>
                            }
                          </div>
                          <div>&nbsp;{item.last_name}, {item.first_name}</div>
                        </Stack>
                      </td>
                      <td>{item.department != null ? item.department.department_name : ''}</td>
                      <td>{item.designation != null ? item.designation.designation_name : ''}</td>
                      <td>{item.work_site != null ? item.work_site: ''}</td>
                      <td>{item.branch != null ? item.branch.branch_name : ''}</td>
                      <td>{item.phone}</td>
                      <td>{moment(new Date(item.date_of_joining ?? "")).format(dateFormat)}</td>
                      <td className='text-center'>
                        <div className='d-grid'>
                          {item.status == 1 ? (
                          <Button
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-success'
                            size='sm'
                          > Active
                          </Button>
                          ) : (
                          <Button
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-secondary'
                            size='sm'
                          > Inactive
                          </Button>
                          )}
                        </div>
                      </td>
                      <td className='text-center'>
                        <Link to={`/employeeManagement/show-employee/${item.employee_id}`}>
                          <Button
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <View/>
                          </Button>
                        </Link>
                        <Link to={`/employeeManagement/edit-employee/${item.employee_id}`}>
                          <Button
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Write/>
                          </Button>
                        </Link>
                        <Button
                          className=' p-1 pt-0 pb-0'
                          style={{ fontSize: '12px', color: 'white' }}
                          variant=' btn-transparent'
                          size='sm'
                          >
                          <Delete/>
                        </Button>
                      </td>
                      <br />
                    </tr>
                  ))
                  ) : (
                    <tr className='no-item'>
                      <td colSpan={11}>{TEXT.NOT_FOUND}</td>
                    </tr>
                )}
                </>
              </tbody>
            </Table>

            <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
              pageClicked={(page: number) => { 
                  getEmployee(page);
                }
              }
              />

          <Loader show={showLoading}/>
          </Card.Body>
        </Card>
        <br />

        {/* UPLOAD EMPLOYEES MODAL*/}
        <Modal className='modal' centered show={showUpload} onHide={handleCloseUpload}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Upload Employees</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
              <Form.Group as={Col} md="4">
                <Form.Label column="sm">Upload Excel File</Form.Label>
              </Form.Group>
              <Form.Group as={Col} md="8">
                <Form.Control
                    type='file'
                    size={'sm'}
                    name="photo"
                    onChange={handleChangeUploadFile}
                    id={'fileUpload'}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
              </Form.Group>
              <Form.Group as={Col} md="8">
                <Form.Label onClick={handleDownloadSampleFormat}  column="sm"><Download/> &nbsp; &nbsp; <span className="sample-format">Sample Excel File Format</span></Form.Label>
              </Form.Group>
            </Row>
              <Row className="mt-2">
                <Form.Group as={Col} md="12">
                  <Form.Label column="sm">Note: Please follow and don't delete the sample format for each column.</Form.Label>
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={uploadEmployees}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  )
}
