import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IFormInitialValue } from './types'

const initialState: IFormInitialValue = {
  employee_id: 0,
  first_name: '',
  last_name: '',
  middle_name: '',
  phone: '',
  emergency_contacts: '',
  date_of_birth: '',
  marital_status: '',
  address: '',
  email: '',
  gender: '',
  photo: '',
  religion: '',
  username: '',
  role_id: '',
  password: '',
  password_confirmation: '',
  bank_name: '',
  bank_account_number: '',
  custom_fields: [{
    name: '',
    value: ''
  }]
}

export const PersonalSlice = createSlice({
  name: 'Personal',
  initialState,
  reducers: {
    editPersonal: (_state, action: PayloadAction<IFormInitialValue>) => action.payload,
  },
})

export const { editPersonal } = PersonalSlice.actions
export default PersonalSlice.reducer
