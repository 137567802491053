import React, {useEffect} from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Stack,
    Table,
} from 'react-bootstrap'
import './style.css'
import {
    EditOutlined,
    FilePresentOutlined,
    SaveAsOutlined,
    ViewInArOutlined,
} from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import axios from "../../../../api";
import {IAPI_Response} from "../../../../api/types";
import {
    API_ENDPOINT,
    ERROR_MESSAGE,
    PAYROLL_COMPUTATION,
    ROLE_PERMISSION,
    SUCCESS_MESSAGE,
    TEXT
} from "../../../../utils/globalConstant";
import {IList, IPayrollComputation, IPayrollComputationSetting} from './types'
import Pagination from 'components/Pagination'
import Alert from "components/Alert";

export default function Index() {

    const [error, setError] = React.useState('')
    const [showError, setShowError] = React.useState(false)

    const [currentPage, setCurrentPage] = React.useState(0)
    const [lastPage, setLastPage] = React.useState(0)
    const [itemsPerPage, setPerPage] = React.useState(6)
    const [itemsCount, setItemCount] = React.useState(0)
    const [from, setFrom] = React.useState(0)
    const [data, setData] = React.useState<IList[]>([])
    const [show, setShow] = React.useState(false)

    const [fileName, setFileName] = React.useState('Upload Boundary File')

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [showEdit, setShowEdit] = React.useState(false)

    const handleCloseEdit = () => setShowEdit(false)
    const handleShowEdit = () => setShowEdit(true)
    const [showLoading, setShowLoading] = React.useState(false)
    const [success, setSuccess] = React.useState('')
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [used, setUsed] = React.useState(0)
    const [addedEmployee, setAddedEmployee] = React.useState('');

    const handleInputChange = async (e: any) => {
        setAddedEmployee(e.target.value)
    }

    const addEmployee = async (e: any) => {
        console.log("added employee ", addedEmployee)

        setShowSuccess(true)
        setSuccess(addedEmployee + PAYROLL_COMPUTATION.SUCCESSFULLY_ADDED)

        setTimeout(() => {
            setShowSuccess(false)
        }, 2000);
    }

    const getPayrollComputation = async (page_number: null | number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IPayrollComputation>>(API_ENDPOINT.ALL_PAYROLL_COMPUTATION, {
                params: {
                    page: page_number,
                },
            })
            const result = await response

            if (result?.data?.results?.data?.length) {
                setData(result?.data?.results?.data)
                setCurrentPage(result?.data?.results?.current_page)
                setLastPage(result?.data?.results?.last_page)
                setPerPage(result?.data?.results?.per_page)
                setItemCount(result?.data?.results?.total)
                setFrom(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const updatePayrollComputation = async (id: any) => {
        await axios.put(API_ENDPOINT.UPDATE_PAYROLL_COMPUTATION +'/'+ id).then(response => {
            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_USED)
                setUsed(id)

                setTimeout(() => {
                    setShowSuccess(false)
                }, 2000);
            }
        })
    }

    const getCurrentPayrollComputation = async () => {

        try {
            const response = await axios.get<IAPI_Response<IPayrollComputationSetting>>(API_ENDPOINT.CURRENT_PAYROLL_COMPUTATION)
            const result = await response
            setShowLoading(false)
            setUsed(Number(result?.data?.results?.setting_value))
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }


    useEffect(() => {
        ;(async () => {
            getPayrollComputation(null)
            getCurrentPayrollComputation()
        })()
    }, [])


    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Payroll Computation</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none' href='/home'>
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/payroll-range'>
                        Payroll
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href='#'>Setup</Breadcrumb.Item>
                    <Breadcrumb.Item active>Payroll Computation</Breadcrumb.Item>
                </Breadcrumb>
                <Card className='border-0  p-0 bordered-card'>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <span className='fw-bold'>&nbsp;</span>
                            </Col>
                            <Col sm={6} className='d-flex justify-content-end hidden'>
                                <InputGroup size='sm' className={'w-25'}>
                                    <Form.Control
                                        aria-label='search'
                                        aria-describedby='inputGroup-sizing-sm'
                                        placeholder='Search...'
                                    />
                                    <InputGroup.Text className='bg-transparent search-input '>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                        {/* <hr/>*/}
                        <Table responsive className='bordered-tabs-top mt-3'>
                            <thead>
                            <tr className='text-center'>
                                <th style={{ width: '30%' }}>Computation Name</th>
                                <th style={{ width: '30%' }}>Formula</th>
                                <th style={{ width: '30%' }}>Description (Example)</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.length > 0 ? (
                                    data.map((payrollComputation, index) => (
                                        <tr className='mb-2' key={payrollComputation.id}>
                                            <td>{payrollComputation.name}</td>
                                            <td>{payrollComputation.formula}</td>
                                            <td dangerouslySetInnerHTML={{__html: payrollComputation.description}}></td>
                                            <td className='text-center'>
                                                {/*<Button*/}
                                                {/*    onClick={handleShow}*/}
                                                {/*    className=' p-1 pt-0 pb-0'*/}
                                                {/*    style={{ fontSize: '12px', color: 'white' }}*/}
                                                {/*    variant=' btn-transparent'*/}
                                                {/*    size='sm'*/}
                                                {/*>*/}
                                                {/*    <svg*/}
                                                {/*        xmlns='http://www.w3.org/2000/svg'*/}
                                                {/*        width='16.143'*/}
                                                {/*        height='16.143'*/}
                                                {/*        viewBox='0 0 16.143 16.143'*/}
                                                {/*    >*/}
                                                {/*        <g id='New' transform='translate(0.5 0.5)'>*/}
                                                {/*            <g*/}
                                                {/*                id='Icon_feather-plus-circle'*/}
                                                {/*                data-name='Icon feather-plus-circle'*/}
                                                {/*                transform='translate(-3 -3)'*/}
                                                {/*            >*/}
                                                {/*                <path*/}
                                                {/*                    id='Path_46'*/}
                                                {/*                    data-name='Path 46'*/}
                                                {/*                    d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'*/}
                                                {/*                    fill='none'*/}
                                                {/*                    stroke='#a7a9ac'*/}
                                                {/*                    strokeLinecap='round'*/}
                                                {/*                    strokeLinejoin='round'*/}
                                                {/*                    strokeWidth='1'*/}
                                                {/*                />*/}
                                                {/*                <path*/}
                                                {/*                    id='Path_47'*/}
                                                {/*                    data-name='Path 47'*/}
                                                {/*                    d='M18,12v6.057'*/}
                                                {/*                    transform='translate(-7.429 -4.457)'*/}
                                                {/*                    fill='none'*/}
                                                {/*                    stroke='#f26930'*/}
                                                {/*                    strokeLinecap='round'*/}
                                                {/*                    strokeLinejoin='round'*/}
                                                {/*                    strokeWidth='1'*/}
                                                {/*                />*/}
                                                {/*                <path*/}
                                                {/*                    id='Path_48'*/}
                                                {/*                    data-name='Path 48'*/}
                                                {/*                    d='M12,18h6.057'*/}
                                                {/*                    transform='translate(-4.457 -7.429)'*/}
                                                {/*                    fill='none'*/}
                                                {/*                    stroke='#f26930'*/}
                                                {/*                    strokeLinecap='round'*/}
                                                {/*                    strokeLinejoin='round'*/}
                                                {/*                    strokeWidth='1'*/}
                                                {/*                />*/}
                                                {/*            </g>*/}
                                                {/*        </g>*/}
                                                {/*    </svg>*/}
                                                {/*</Button>*/}
                                                <Button
                                                    className='pe-3 px-3 text-white'
                                                    style={{ fontSize: '12px', width: '70px' }}
                                                    variant={used == payrollComputation.id? "primary": "secondary"}
                                                    size='sm'

                                                    onClick={() => updatePayrollComputation(payrollComputation.id)}
                                                >
                                                    {used == payrollComputation.id? "Used": "Use"}
                                                </Button>
                                            </td>
                                            <br />
                                        </tr>
                                    ))
                                    ) : (
                                    <tr className='no-item'>
                                        <td colSpan={4}>{TEXT.NOT_FOUND}</td>
                                    </tr>
                                )
                            }

                            </tbody>
                        </Table>
                        <Pagination
                            itemsCount={itemsCount}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            lastPage={lastPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={false}
                            pageClicked={(page: number) => {
                                getPayrollComputation(page);
                            }
                            }
                        />
                    </Card.Body>
                </Card>
                {/* ADD MODAL*/}
                <Modal className='modal' centered show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Add Employee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                <Form.Label className='text-muted fw-bold'>Add Employee to Payroll Computation</Form.Label>
                                <Form.Control onChange={handleInputChange} size={'sm'} type='text' placeholder='Employee name' autoFocus autoComplete={'off'} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' size={'sm'} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={addEmployee}>
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <br />
            </Container>
        </>


    )
}
