import { lazy, Suspense, useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Container, Breadcrumb } from 'react-bootstrap'
import './style.css'
import TabList from 'components/TabList'
import ProgressLoading from 'components/ProgressLoading'
import Alert from 'components/Alert'
const Personal = lazy(() => import('./Personal'))
const Company = lazy(() => import('./Company'))
const Education = lazy(() => import('./Education'))
const Work = lazy(() => import('./Work'))
const Government = lazy(() => import('./Government'))
const Shift = lazy(() => import('./Shift'))
const Salary = lazy(() => import('./Salary'))
const Preview = lazy(() => import('./Preview'))

import axios from 'api/index'
import {
    API_ENDPOINT,
    ERROR_MESSAGE
} from 'utils/globalConstant'
import { IAPI_Response } from 'api/types'
import { IItem } from './types'

import { useDispatch } from 'react-redux'
import { addPersonal } from 'redux/EmployeeManagement/AddEmployee/Custom/Personal/PersonalSlice'
import { addCompany } from 'redux/EmployeeManagement/AddEmployee/Custom/Company/CompanySlice'
import { addEducation } from 'redux/EmployeeManagement/AddEmployee/Custom/Education/EducationSlice'
import { addWork } from 'redux/EmployeeManagement/AddEmployee/Custom/Work/WorkSlice'
import { addGorvernment } from 'redux/EmployeeManagement/AddEmployee/Custom/Government/GovernmentSlice'
import { addShift } from 'redux/EmployeeManagement/AddEmployee/Custom/Shift/ShiftSlice'
import { addSalary } from 'redux/EmployeeManagement/AddEmployee/Custom/Salary/SalarySlice'

export default function Index() {
    const renderComponent = (comp: JSX.Element) => (
        <Suspense fallback={<ProgressLoading />}>{comp}</Suspense>
    )
    const dispatch = useDispatch()
    const {id} = useParams();

    const [currentTab, setCurrentTab] = useState(0)
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [showTab, setTab] = useState(false)

    const showCandidate = async () => {
        try {
            const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_CANDIDATE +'/'+ id)
            const result = await response
            if (result?.data?.results) {
                dispatch(addPersonal({
                    candidate_id: id ?? "0",
                    first_name: result?.data?.results?.first_name,
                    last_name: result?.data?.results?.last_name,
                    middle_name: result?.data?.results?.middle_name != null? result?.data?.results?.middle_name : '' ,
                    phone: result?.data?.results?.contact_number,
                    emergency_contacts: result?.data?.results?.emergency_contacts,
                    date_of_birth: result?.data?.results?.date_of_birth,
                    marital_status: result?.data?.results?.marital_status,
                    address: result?.data?.results?.address,
                    email: result?.data?.results?.email,
                    gender: result?.data?.results?.gender,
                    photo: result?.data?.results?.photo,
                    photo_url: "",
                    religion: result?.data?.results?.religion,
                    username: '',
                    role_id: '',
                    password: '',
                    password_confirmation: '',
                }))

                dispatch(addCompany({
                    company_email: '',
                    employee_no: '',
                    finger_id: '',
                    supervisor_id: '',
                    department_id: '',
                    designation_id: '',
                    branch_id: '',
                    date_of_joining: '',
                    workflow:  [{
                        approver_employee_id: ''
                    }],
                    approvers_order: '',
                    employee_type: ''
                }))

                dispatch(addSalary({
                    pay_grade_id: 0,
                    pay_grade_name: '',
                    basic_salary: '',
                    gross_salary: '',
                    isCustom: '',
                    is_daily_rate: 0,
                    daily_rate: '',
                    allowances: [],
                    deductions: []
                }))


                dispatch(addEducation([{
                    board_university: '',
                    degree: '',
                    cgpa: '',
                    institute: '',
                    passing_year: '',
                    result: '',
                }]))


                dispatch(addWork([{
                    organization_name: '',
                    designation: '',
                    from_date: '',
                    to_date: '',
                }]))


                dispatch(addGorvernment({
                    nbi_url: '',
                    pag_ibig: '',
                    phil_health: '',
                    sss: '',
                    tin: '',
                }))

                dispatch(addShift({
                    no_of_hours_work_per_day: '',
                    schedule_type: '',
                    work_shift_id: '0',
                    work_site: '',
                    grace_time_period: '',
                    grace_period_minutes: 0,
                    grace_time_period_out: '',
                    grace_period_minutes_out: 0,
                    shift_type: 0,
                    shift: [
                        {
                            id: 0,
                            employee_id: 0,
                            day: 0,
                            start_from: '',
                            end_to: '',
                            break_in: '',
                            break_out: '',
                            is_rest_day: '',
                            late_count_time: '',
                            created_at: '',
                            updated_at: ''
                        },
                        {
                            id: 0,
                            employee_id: 0,
                            day: 1,
                            start_from: '',
                            end_to: '',
                            break_in: '',
                            break_out: '',
                            is_rest_day: '',
                            late_count_time: '',
                            created_at: '',
                            updated_at: ''
                        },
                        {
                            id: 0,
                            employee_id: 0,
                            day: 2,
                            start_from: '',
                            end_to: '',
                            break_in: '',
                            break_out: '',
                            is_rest_day: '',
                            late_count_time: '',
                            created_at: '',
                            updated_at: ''
                        },
                        {
                            id: 0,
                            employee_id: 0,
                            day: 3,
                            start_from: '',
                            end_to: '',
                            break_in: '',
                            break_out: '',
                            is_rest_day: '',
                            late_count_time: '',
                            created_at: '',
                            updated_at: ''
                        },
                        {
                            id: 0,
                            employee_id: 0,
                            day: 4,
                            start_from: '',
                            end_to: '',
                            break_in: '',
                            break_out: '',
                            is_rest_day: '',
                            late_count_time: '',
                            created_at: '',
                            updated_at: ''
                        },
                        {
                            id: 0,
                            employee_id: 0,
                            day: 5,
                            start_from: '',
                            end_to: '',
                            break_in: '',
                            break_out: '',
                            is_rest_day: '',
                            late_count_time: '',
                            created_at: '',
                            updated_at: ''
                        },
                        {
                            id: 0,
                            employee_id: 0,
                            day: 6,
                            start_from: '',
                            end_to: '',
                            break_in: '',
                            break_out: '',
                            is_rest_day: '',
                            late_count_time: '',
                            created_at: '',
                            updated_at: ''
                        },
                    ]
                }))

                setTab(true)
            }
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    useEffect(() => {
        ;(async () => {
            showCandidate()
        })()
    }, [])

    return (
        <Container fluid>
            <h5 className='fw-bold'>Promote Candidate</h5>
            <Breadcrumb className='breadcrumbs'>
                <Breadcrumb.Item className='text-decoration-none' href='/home'>
                    Home
                </Breadcrumb.Item>
                <Breadcrumb.Item className='text-decoration-none' href='#'>
                    Candidates
                </Breadcrumb.Item>
                <Breadcrumb.Item href='/employeeManagement/employee-list'>Candidate List</Breadcrumb.Item>
                <Breadcrumb.Item active>Promote Candidate</Breadcrumb.Item>
            </Breadcrumb>

            <Alert
                type="error"
                show={showError}
                message={error}
                setShow={() => {
                    setShowError(false);
                }
                }
            />

            <TabList
                lists={[
                    {
                        label: 'Personal',
                        component: renderComponent(showTab ? (<Personal setCurrentTab={setCurrentTab} />) : <></>),
                        disabled: !(currentTab === 0),
                    },
                    {
                        label: 'Company',
                        component: renderComponent(showTab ? (<Company setCurrentTab={setCurrentTab} />) : <></>),
                        disabled: !(currentTab === 1),
                    },
                    {
                        label: 'Salary',
                        component: renderComponent(showTab ? (<Salary setCurrentTab={setCurrentTab} />) : <></>),
                        disabled: !(currentTab === 2),
                    },
                    {
                        label: 'Shift',
                        component: renderComponent(showTab ? (<Shift setCurrentTab={setCurrentTab} />) : <></>),
                        disabled: !(currentTab === 3),
                    },
                    {
                        label: 'Education',
                        component: renderComponent(showTab ? (<Education setCurrentTab={setCurrentTab} />) : <></>),
                        disabled: !(currentTab === 4),
                    },
                    {
                        label: 'Work',
                        component: renderComponent(showTab ? (<Work setCurrentTab={setCurrentTab} />) : <></>),
                        disabled: !(currentTab === 5),
                    },
                    {
                        label: 'Government',
                        component: renderComponent(showTab ? (<Government setCurrentTab={setCurrentTab} />) : <></>),
                        disabled: !(currentTab === 6),
                    },
                    {
                        label: 'Preview',
                        component: renderComponent(showTab ? (<Preview setCurrentTab={setCurrentTab} />) : <></>),
                        disabled: !(currentTab === 7),
                    },
                ]}
                currentTab={currentTab}
            />

            <br/>
        </Container>
    )
}
