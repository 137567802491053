import React, { useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Table
} from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Formik } from 'formik';
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
    API_ENDPOINT,
    SUCCESS_MESSAGE,
    ERROR_MESSAGE
} from 'utils/globalConstant'
import {ISearchedFeature} from "../GlobalSearch/types";
import {Link} from "react-router-dom";

export default function Index() {
    const searchedWord = localStorage.getItem('searched_word')
    const [key, setKey] = useState('features')
    const [searchedResults, setSearchedResults] = useState<ISearchedFeature>()

    const searchedFeature = async () => {

        try {
            const res = await axios.get<IAPI_Response<ISearchedFeature>>(API_ENDPOINT.SEARCH_FEATURE, {
                params: {
                    searched: localStorage.getItem('searched_word'),
                    limit: false
                }
            })
            const result = await res

            setSearchedResults(result.data.results)


        } catch {
            console.log('There is something wrong')
        }


    }

    const highlightWord = (feature: string) => {
        const re = new RegExp(`(${localStorage.getItem('searched_word')})`, 'gi')
        return (
            feature.replace(re, '<b>$1</b>')
        )

    }

    useEffect(() => {
        ;(async () => {
            searchedFeature()
        })()
    }, [])

    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Global Search</h5>

                <Card className='border-0  p-0 bordered-card'>
                    <Card.Body>
                        <Row>
                            <Col sm={2}>
                            </Col>
                            <Col sm={8}>
                                <div className="mb-3">
                                    {
                                        searchedWord != "" ?
                                            <span className='fw-normal'>Showing results for <b>'{searchedWord}'</b></span>
                                            : <span className='fw-normal'>No Search yet.</span>
                                    }
                                </div>

                                <Tabs
                                    variant={'tabs'}
                                    className='tabs'
                                    id='controlled-tab-example'
                                    activeKey={key}
                                    onSelect={(k: any) => {
                                        setKey(k)
                                    }}

                                >
                                    <Tab className='p-0 feature-tab' eventKey='features' title={'Features (' + searchedResults?.features_count + ")"}>
                                        <Card className='border-0  p-0 bordered-card-tabs'>
                                            <Card.Body>
                                                {
                                                    searchedResults?.features && searchedResults?.features?.length > 0 ?
                                                        <>
                                                            <span className="mb-2">The following features are the matches for your search:</span>
                                                            {
                                                                searchedResults?.features.map((feature, index) => (

                                                                    <ul key={index} className="bullet-list">
                                                                        {
                                                                            feature.features.map((data, index2) => (
                                                                                <Link key={index2} to={data.menu_url} className='links'>
                                                                                    <li className="search-list" dangerouslySetInnerHTML={{__html: highlightWord(data.feature_name)}}></li>
                                                                                </Link>
                                                                            ))
                                                                        }

                                                                    </ul>


                                                                ))
                                                            }
                                                            <hr className="mt-3" />
                                                        </>
                                                        : <Row>
                                                            <span className="mb-2">Your search did not match anything.</span>
                                                            <span className="mb-2">Suggestions:</span>
                                                            <ul className="bullet-list">
                                                                <li>
                                                                    Make sure that all the words are spelled correctly.
                                                                </li>
                                                                <li>
                                                                    Try different keywords.
                                                                </li>
                                                                <li>
                                                                    Make sure you have access to the information you are searching for.
                                                                </li>
                                                            </ul>
                                                            <hr className="mt-3" />
                                                        </Row>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab className='p-0 feature-tab' eventKey='users' title={'Users (' + searchedResults?.users_count +')'}>
                                        <Card className='border-0  p-0 bordered-card-tabs'>
                                            <Card.Body>
                                                {
                                                    searchedResults?.users && searchedResults?.users?.length > 0 ?
                                                        <>
                                                            <span className="mb-2">The following users are the matches for your search:</span>
                                                            <ul className="bullet-list">
                                                            {
                                                                searchedResults?.users.map((user, index) => (
                                                                    <Link key={index} to={"/employeeManagement/show-employee/"+user.employee_id} className='links'>
                                                                        <li className="search-list" dangerouslySetInnerHTML={{__html: highlightWord(user.last_name + ", " + user.first_name + " " + user.middle_name)}}></li>
                                                                    </Link>
                                                                ))
                                                            }
                                                            </ul>
                                                            <hr className="mt-3" />
                                                        </>
                                                        : <Row>
                                                            <span className="mb-2">Your search did not match anything.</span>
                                                            <span className="mb-2">Suggestions:</span>
                                                            <ul className="bullet-list">
                                                                <li>
                                                                    Make sure that all the words are spelled correctly.
                                                                </li>
                                                                <li>
                                                                    Try different keywords.
                                                                </li>
                                                                <li>
                                                                    Make sure you have access to the information you are searching for.
                                                                </li>
                                                            </ul>
                                                            <hr className="mt-3" />
                                                        </Row>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab className='p-0 feature-tab' eventKey='others' title={'Other Results (' + searchedResults?.others_count +')'}>
                                        <Card className='border-0  p-0 bordered-card-tabs'>
                                            <Card.Body>
                                                {
                                                    searchedResults?.others && searchedResults?.others?.length > 0 ?
                                                        <>
                                                            <span className="mb-2">The following are the matches for your search:</span>
                                                            <ul className="bullet-list">
                                                                {
                                                                    searchedResults?.others.map((other, index) => (
                                                                        <Link key={index} to={other.url} className='links'>
                                                                            <li className="search-list" dangerouslySetInnerHTML={{__html: highlightWord(other.search_name)}}></li>
                                                                        </Link>
                                                                    ))
                                                                }
                                                            </ul>
                                                            <hr className="mt-3" />
                                                        </>
                                                        : <Row>
                                                            <span className="mb-2">Your search did not match anything.</span>
                                                            <span className="mb-2">Suggestions:</span>
                                                            <ul className="bullet-list">
                                                                <li>
                                                                    Make sure that all the words are spelled correctly.
                                                                </li>
                                                                <li>
                                                                    Try different keywords.
                                                                </li>
                                                                <li>
                                                                    Make sure you have access to the information you are searching for.
                                                                </li>
                                                            </ul>
                                                            <hr className="mt-3" />
                                                        </Row>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                </Tabs>
                            </Col>
                            <Col sm={2}>
                            </Col>

                        </Row>

                        {/*<Table responsive className='bordered-tabs-top mt-3'>*/}
                        {/*    <thead>*/}
                        {/*    <tr>*/}
                        {/*        <th style={{ width: '5%' }}>No.</th>*/}
                        {/*        <th style={{ width: '12%' }}>Work Site Name</th>*/}
                        {/*        <th className="text-center" style={{ width: '12%' }}>Semi-monthly Rate</th>*/}
                        {/*        <th className="text-center" style={{ width: '12%' }}>Daily Rate</th>*/}
                        {/*        <th className="text-center" style={{ width: '12%' }}>Overtime Rate</th>*/}
                        {/*        <th className="text-center" style={{ width: '12%' }}>Night Diff <br/> (per month)</th>*/}
                        {/*        <th className="text-center" style={{ width: '12%' }}>5 Days Incentive</th>*/}
                        {/*        <th className="text-center" style={{ width: '10%' }}>Status</th>*/}
                        {/*        <th className={'text-center'}>Actions</th>*/}
                        {/*    </tr>*/}
                        {/*    </thead>*/}
                        {/*    <tbody>*/}
                        {/*    <>*/}
                        {/*        {lists.length > 0 ? (*/}
                        {/*            lists.map((item, index) =>*/}
                        {/*                <tr className='mb-2' key={index}>*/}
                        {/*                    <td>{from + index}</td>*/}
                        {/*                    <td className='fw-bold'>{item.work_site_name}</td>*/}
                        {/*                    <td className="text-center">{item.isDailyRate == 0 ? item.semi_monthly_rate: "-"}</td>*/}
                        {/*                    <td className="text-center">{item.isDailyRate == 1 ? item.daily_rate: "-"}</td>*/}
                        {/*                    <td className="text-center">{item.overtime_rate}</td>*/}
                        {/*                    <td className="text-center">{item.night_diff}</td>*/}
                        {/*                    <td className="text-center">{item.five_days_incentive}</td>*/}
                        {/*                    <td className="text-center">*/}
                        {/*                        <div className='d-grid'>*/}
                        {/*                            {item.status == 1 ? (*/}
                        {/*                                <Button*/}
                        {/*                                    className=' p-1 pt-0 pb-0'*/}
                        {/*                                    style={{ fontSize: '12px', color: 'white' }}*/}
                        {/*                                    variant=' btn-success'*/}
                        {/*                                    size='sm'*/}
                        {/*                                > Active*/}
                        {/*                                </Button>*/}
                        {/*                            ) : (*/}
                        {/*                                <Button*/}
                        {/*                                    className=' p-1 pt-0 pb-0'*/}
                        {/*                                    style={{ fontSize: '12px', color: 'white' }}*/}
                        {/*                                    variant=' btn-secondary'*/}
                        {/*                                    size='sm'*/}
                        {/*                                > Inactive*/}
                        {/*                                </Button>*/}
                        {/*                            )}*/}
                        {/*                        </div>*/}
                        {/*                    </td>*/}
                        {/*                    <td className='text-center'>*/}
                        {/*                        <Button*/}
                        {/*                            onClick={() => showWorkSite(item.id)}*/}
                        {/*                            className=' p-1 pt-0 pb-0'*/}
                        {/*                            style={{ fontSize: '12px', color: 'white' }}*/}
                        {/*                            variant=' btn-transparent'*/}
                        {/*                            size='sm'*/}
                        {/*                        >*/}
                        {/*                            <Write/>*/}
                        {/*                        </Button>*/}
                        {/*                        <Button*/}
                        {/*                            onClick={() => handleShowConfirm(item.id)}*/}
                        {/*                            className=' p-1 pt-0 pb-0'*/}
                        {/*                            style={{ fontSize: '12px', color: 'white' }}*/}
                        {/*                            variant=' btn-transparent'*/}
                        {/*                            size='sm'*/}
                        {/*                        >*/}
                        {/*                            <Delete/>*/}
                        {/*                        </Button>*/}
                        {/*                    </td>*/}
                        {/*                </tr>*/}
                        {/*            )*/}
                        {/*        ) : (*/}
                        {/*            <tr className='no-item'>*/}
                        {/*                <td colSpan={8}>No matching records found</td>*/}
                        {/*            </tr>*/}
                        {/*        )}*/}
                        {/*    </>*/}
                        {/*    </tbody>*/}
                        {/*</Table>*/}

                        {/*<Pagination*/}
                        {/*    itemsCount={itemsCount}*/}
                        {/*    itemsPerPage={itemsPerPage}*/}
                        {/*    currentPage={currentPage}*/}
                        {/*    lastPage={lastPage}*/}
                        {/*    setCurrentPage={setCurrentPage}*/}
                        {/*    alwaysShown={false}*/}
                        {/*    pageClicked={(page: number) => {*/}
                        {/*        getWorkSite(page);*/}
                        {/*    }*/}
                        {/*    }*/}
                        {/*/>*/}



                        {/*<Loader show={showLoading}/>*/}
                    </Card.Body>
                </Card>

            </Container>
        </>
    )
}
