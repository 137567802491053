import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import './style.css'
import {Button} from 'react-bootstrap'

const redirectHome = () => {
    return window.location.replace('/home')
}

const Index = () => {
    return (
        <div className="not-found-container">
            <h1 className="not-found-title">Oopss!</h1>
            <p className="not-found-message">The page you are looking for could not be found.</p>
            <h6>Please check the URL you entered and try again.</h6>
            <Button className='add-new-btn rounded text-white'
                    variant='primary'
                    type="button"
                    onClick={redirectHome}
            >Back to Home</Button>
        </div>
    )
}

export default Index
