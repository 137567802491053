import React from 'react'

const AttendancePresentIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 18.628 18.282'
    >
      <g id='Attendance-Present-Icon' transform='translate(1.217 1.174)'>
        <path
          id='Path_72'
          data-name='Path 72'
          d='M19,10.258v.736a8,8,0,1,1-4.743-7.31'
          transform='translate(-3 -2.991)'
          fill='none'
          stroke='#82c341'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_73'
          data-name='Path 73'
          d='M23.9,6l-8,8.006-2.4-2.4'
          transform='translate(-7.9 -4.396)'
          fill='none'
          stroke='#82c341'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  )
}

export default AttendancePresentIcon
