import { styled, Card, CardProps, Typography, TypographyProps } from '@mui/material'

interface StyledCardProps extends CardProps {
  userVariant: 'primary' | 'info' | 'success' | 'default'
}
export const StyledCard = styled(Card)<StyledCardProps>(({ theme, userVariant }) => {
  let bgColor
  switch (userVariant) {
    case 'primary':
      bgColor =
        'transparent linear-gradient(286deg, #F26930 0%, #FBA91A 100%) 0% 0% no-repeat padding-box'
      break
    case 'info':
      bgColor =
        'transparent linear-gradient(106deg, #27699C 0%, #453D98 100%) 0% 0% no-repeat padding-box'
      break
    case 'success':
      bgColor =
        'transparent linear-gradient(106deg, #82C341 0%, #1EA66D 100%) 0% 0% no-repeat padding-box'
      break
    default:
      bgColor =
        'transparent linear-gradient(107deg, #C8C8C8 0%, #A7A9AC 100%) 0% 0% no-repeat padding-box'
      break
  }
  return {
    borderRadius: 15,
    boxShadow: 'none',
    background: bgColor,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  }
})

interface StyledTextProps extends TypographyProps {
  isBold?: boolean
  size?: number
  lineHeight: number
}
export const StyledText = styled(Typography)<StyledTextProps>(
  ({ theme, isBold = false, size, lineHeight }) => ({
    color: '#fff',
    fontWeight: isBold ? theme.fontStyle.weight.normal : theme.fontStyle.weight.bold,
    fontSize: size,
    lineHeight: `${lineHeight}px`,
  }),
)
