import React, {useEffect, useRef, useState} from 'react'
import { RegistrationStepComp } from '../types'
import {
    Button,
    Col,
    Form,
    InputGroup,
    Row,
} from 'react-bootstrap'
import { Formik } from 'formik';
import { validationSchema } from './validationSchema'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState } from 'redux/appStore'
import { addStep2 } from 'redux/Register/Step2/Step2Slice'

import "./style.css"

const Personal: React.FC<RegistrationStepComp> = ({ setCurrentStep }) => {
    const initValues = useSelector((state: RootState) => state.registerStep2)
    const dispatch = useDispatch()
    const onSubmit = async (value: any) => {
        setCurrentStep(3)
        dispatch(addStep2(value))
    }
    const [uploadedProfilePicture, setUploadedProfilePicture] = useState("")
    const [uploadedProfilePictureBase64, setUploadedProfilePictureBase64] = useState("")


    const handleUploadFile = async(e: any) => {
        setUploadedProfilePicture(URL.createObjectURL(e.target.files[0]));

        const reader = new FileReader()

        reader.readAsDataURL(e.target.files[0])

        reader.onload = () => {
            // @ts-ignore
            setUploadedProfilePictureBase64(reader.result)
        }

    }

    // useEffect(() => {
    //     ;(async () => {
    //
    //     })()
    // }, [])

    return (
        <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                onSubmit(values)
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
              }) => (

                <Form noValidate onSubmit={handleSubmit}>
                    <Row className={'mt-4'}>
                        <Col sm={12}>
                            <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                                <Form.Label className='fw-light required text-white'>
                                    Company Name
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Company Name'
                                    name="company_name"
                                    value={values.company_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.company_name && !!errors.company_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.company_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={'mt-2'}>
                        <Col sm={12}>
                            <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                                <Form.Label className='fw-light required text-white'>
                                    Email Address
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Domain Name'
                                    name="domain_name"
                                    value={values.domain_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.domain_name && !!errors.domain_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.domain_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={'mt-2'}>
                        <Col sm={12}>
                            <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                                <Form.Label className='fw-light text-white'>
                                    Company Logo
                                </Form.Label>
                                <Form.Control
                                    type='file'
                                    size={'sm'}
                                    name="company_logo"
                                    // value={values.company_documents}
                                    onChange={(e) => {
                                        // handleChange(e)
                                        handleUploadFile(e)
                                    }}
                                    multiple
                                    accept=".png,.jpg,.jpeg"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.company_logo}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-5 text-right">

                        <Form.Group as={Col} md="12">
                            <Button
                                type="button"
                                // disabled={isSubmitting}
                                className={"nextButton bg-white text-primary m-2"}
                                style={{ width: '25%' }}
                                size="sm"
                                onClick={() => setCurrentStep(1)}>
                                Previous
                            </Button>
                            <Button
                                type="submit"
                                // disabled={isSubmitting}
                                className={"nextButton bg-white text-primary"}
                                style={{ width: '25%' }}
                                size="sm">
                                Next
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

export default Personal
