import React, { useRef, useState, useContext, useEffect } from 'react'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Overlay,
  Tooltip,
  Dropdown,
  Row,
  Col,
  OverlayTrigger
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { Badge } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'

import { MainContext } from '../../context/MainContext'
import {API_ENDPOINT, drawerWidth} from '../../utils/globalConstant'
import moment from 'moment'
import QuestionMarkIcon from "asset/SvgComponent/QuestionMarkIcon";
import Popover from "react-bootstrap/Popover";
import "./style.css";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {StyledTypoListItemText} from "../Drawer/StyledComponents";
import {ExpandMore} from "@material-ui/icons";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import axios from "../../api";
import {IAPI_Response} from "../../api/types";
import {IFeature, ISearchedFeature} from './types'
import { Link } from 'react-router-dom'
import AdminIcon from '../../asset/SvgComponent/AdminIcon'
import EmployeeIcon from '../../asset/SvgComponent/EmployeeIcon'
import LeavesIcon from '../../asset/SvgComponent/LeavesIcon'
import AttendanceIcon from '../../asset/SvgComponent/AttendanceIcon'
import PayrollIcon from '../../asset/SvgComponent/PayrollIcon'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginLeft: open ? drawerWidth.open : drawerWidth.close,
  width: `calc(100% - ${open ? drawerWidth.open : drawerWidth.close}px)`,
  [theme.breakpoints.down('sm')]: {
    // width: `${open ? 100 : 67 }%!important` as any,
    // mr: `${open ? 0 : 40 }px`,
    // "300px!important" as any
    width: '100% !important' as any,
    zIndex: '1',
  },
}))

const AppBar = () => {
  const [displayTime, setDisplayTime] = useState<string>('')
  const [showDate, setShowDate] = useState(false)
  const [showTime, setShowTime] = useState(false)
  const [showUser, setShowUser] = useState(false)
  const [showHelp, setShowHelp] = useState(false)
  const [showSearchSuggestion, setShowSearchSuggestion] = useState(false)
  const [initials, setInitials] = useState('')
  const [userDetails, setUserDetails] = useState({
    first_name : localStorage.getItem('first_name') ?? "",
    last_name : localStorage.getItem('last_name') ?? "",
    photo : localStorage.getItem('photo') ?? ""
  })
  const [searched, setSearched] = useState('')
  const [searchedResults, setSearchedResults] = useState<ISearchedFeature>()
  // const modules = JSON.parse(localStorage.getItem('modules') ?? "") ?? []

  const mainCont = useContext(MainContext)

  const targetDate = useRef(null)
  const targetTime = useRef(null)
  const targetUser = useRef(null)
  const targetHelp = useRef(null)
  const targetSearch = useRef(null)

  const handleDrawerOpen = () => {
    // @ts-ignore
    mainCont?.setOpenDrawer((data: boolean) => !data)
  }

  const redirectProfile = () => {
    window.location.replace('/profile')
  }

  const redirectPayslip = () => {
    window.location.replace('/payroll/payslips')
  }

  const redirectChangePassword = () => {
    window.location.replace('/administration/change-password')
  }

  const redirectLogin = async () => {

    try {
      const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.LOGOUT)
      const result = await res

      localStorage.removeItem('jwt');
      localStorage.removeItem('role');
      localStorage.removeItem('first_name');
      localStorage.removeItem('last_name');
      localStorage.removeItem('designation');
      localStorage.removeItem('shifts');
      window.location.replace('/login')

    } catch {
      console.log('There is something wrong')
    }


  }

  const searchedFeature = async () => {


    try {
      const res = await axios.get<IAPI_Response<ISearchedFeature>>(API_ENDPOINT.SEARCH_FEATURE, {
        params: {
          searched: searched,
          limit: true
        }
      })
      const result = await res

      setSearchedResults(result.data.results)

      if(searched == ""){
        setShowSearchSuggestion(false)
      }else{
        setShowSearchSuggestion(true)
      }


    } catch {
      console.log('There is something wrong')
    }


  }

  const displayIcon = (feature: string) => {
    if(feature.toLowerCase() == "administration"){
      return (<AdminIcon/>)
    }else if(feature.toLowerCase() == "employees"){
      return (<EmployeeIcon/>)
    }else if(feature.toLowerCase() == "leaves"){
      return (<LeavesIcon/>)
    }else if(feature.toLowerCase() == "attendance"){
      return (<AttendanceIcon/>)
    }else if(feature.toLowerCase() == "payroll"){
      return (<PayrollIcon/>)
    }else if(feature.toLowerCase() == "setup"){
      return (<PayrollIcon/>)
    }
  }

  const highlightWord = (feature: string) => {
    const re = new RegExp(`(${searched})`, 'gi')
    return (
        feature.replace(re, '<b>$1</b>')
    )

  }

  const handleViewAllSearch = () => {
    window.location.replace('/search-results')
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(localStorage.getItem('jwt')){
        searchedFeature()
      }

    }, 800);
    return () => clearTimeout(timeoutId);
  }, [searched]);


  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayTime(moment().format('LTS'))
      if(userDetails.first_name != "" && userDetails.last_name != ""){
        setInitials(userDetails.first_name[0] + userDetails.last_name[0]);
      }else{
        setInitials(userDetails.first_name[0]);
      }
    }, 1000)
    return () => clearInterval(interval)
  })


  return (
    <StyledAppBar
      className={mainCont?.openDrawer ? 'app-bar-open' : 'app-bar-close'}
      style={{
        background: 'transparent linear-gradient(90deg, #F26930 0%, #FBA91A 100%'

      }}
      elevation={0}
      position='fixed'
      open={mainCont?.openDrawer}
    >
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerOpen}
          edge='start'
          sx={{
            marginRight: 3,
          }}
        >
          <MenuIcon className={'text-white'} />
        </IconButton>
        <InputGroup className={'w-25'} >
          <Form.Control
              aria-label='search'
              aria-describedby='inputGroup-sizing-sm'
              placeholder='Search...'
              ref={targetSearch}
              className={"search-bar"}
              onChange={e => {
                setSearched(e.target.value)
                if(e.target.value != ""){
                  localStorage.setItem('searched_word', e.target.value)
                }
              }}
              onFocus={ (e:any) => {
                if(e.target.value != ""){
                  setShowSearchSuggestion(true)
                }

              }}
              onBlur={ () => {
                setShowSearchSuggestion(false)
              }}
          />
          <InputGroup.Text className='bg-white search-input '>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <Overlay
              target={targetSearch.current}
              show={showSearchSuggestion}
              placement='bottom'

          >
            <Popover className={"popover-class"} id={`popover-positioned-bottom`} style={{fontSize: '12px', width: '320px', maxWidth: '320px'}} >
              <Popover.Body>
                <>

                  {/*<Row>*/}
                  {/*  <Col><b>Features</b></Col>*/}
                  {/*</Row>*/}
                  {
                    searchedResults?.features && searchedResults?.features?.length > 0 ?
                        <>
                          <Row className="title">
                            <Col sm={8}><b>Features</b></Col>
                            <Col sm={4} className="d-flex justify-content-end view-all"><span
                                onClick={handleViewAllSearch}>View All</span></Col>
                          </Row>
                          {
                            searchedResults?.features.map((feature, index) => (
                                <div key={index}>
                                  {
                                    feature.features.map((data, index2) => (
                                        <Row key={index2} className={"feature-list mb-1"}>
                                          <Link to={data.menu_url} className='links'>
                                            <Row className={"feature"}>
                                              <Col sm={1} className={"icon"} >{displayIcon(feature.module_name)}</Col>
                                              <Col sm={11} className={"search-name"}><span dangerouslySetInnerHTML={{__html: highlightWord(data.feature_name)}}></span>
                                                <br/>
                                                <span className={"breadcrumbs"}>{feature.module_name} {'>'} {data.feature_name}</span>
                                              </Col>
                                            </Row>
                                          </Link>
                                        </Row>
                                    ))
                                  }

                                </div>

                            ))
                          }
                          <hr className="mt-3" />
                        </>
                        : ""
                  }



                  {
                    searchedResults?.users && searchedResults?.users?.length > 0 ?
                        <>

                          <Row>
                            <Col sm={8}><b>Users</b></Col>
                            <Col sm={4} className="d-flex justify-content-end view-all" ><span
                                onClick={handleViewAllSearch}>View All</span></Col>
                          </Row>
                          {
                            searchedResults?.users.map((user, index) => (
                                <Row key={index} className={"sub-module mb-1"}>
                                  <Link to={"/employeeManagement/show-employee/"+user.employee_id} className='links'>
                                    <Row className={"feature"}>
                                      <Col sm={1} className={"icon"}
                                      ><Avatar
                                          sx={{ width: 20, height: 20 }}
                                          src={process.env.REACT_APP_IMAGE_URL+ user.photo}
                                      ></Avatar>
                                      </Col>
                                      <Col sm={11} className={"search-name"}><span dangerouslySetInnerHTML={{__html: highlightWord(user.last_name+" "+ user.first_name+" " + user.middle_name)}}></span>
                                        <br/>
                                        <span className={"breadcrumbs"} dangerouslySetInnerHTML={{__html: highlightWord(user.designation.designation_name)}}></span>
                                      </Col>
                                    </Row>
                                  </Link>
                                </Row>

                            ))
                          }
                          <hr className="mt-3" />
                        </>
                         : ""

                  }

                  {
                    searchedResults?.others && searchedResults?.others?.length > 0 ?
                        <>

                          <Row>
                            <Col sm={8}><b>Other results</b></Col>
                            <Col sm={4} className="d-flex justify-content-end view-all" ><span
                                onClick={handleViewAllSearch}>View All</span></Col>
                          </Row>
                          {
                            searchedResults?.others.map((other, index3) => (
                                <Row key={index3} className={"feature-list mb-1"}>
                                  {/*to={data.menu_url}*/}
                                  <Link to={other.url} className='links'>
                                    <Row className={"feature"}>
                                      <Col sm={1} className={"icon"} >{displayIcon(other.module_name)}</Col>
                                      <Col sm={11} className={"search-name"}><span dangerouslySetInnerHTML={{__html: highlightWord(other.search_name)}}></span>
                                        <br/>
                                        <span className={"breadcrumbs"}>{other.breadcrumb}</span>
                                      </Col>
                                    </Row>
                                  </Link>
                                </Row>
                            ))
                          }
                          <hr className="mt-3" />
                        </>
                        : ""

                  }

                  {
                    searchedResults?.others && searchedResults?.others?.length == 0 &&
                      searchedResults?.features && searchedResults?.features?.length == 0 &&
                        searchedResults?.users && searchedResults?.users?.length == 0 ?
                        <>

                          <Row >
                            <Col sm={8}><b>Other results</b></Col>
                            <Col sm={4} className="d-flex justify-content-end view-all"><span
                                onClick={handleViewAllSearch}>View All</span></Col>
                          </Row>
                          <Row className={"sub-module"}>
                            <Col >No results found.</Col>
                          </Row>
                        </>
                        : ""

                  }

                </>
              </Popover.Body>
            </Popover>
          </Overlay>
        </InputGroup>
        {/* <Form className='d-flex'>
          <InputGroup>
            <FormControl
              type='text'
              placeholder='Search...'
              className='me-auto border-0'
              aria-label='Search'
            />
            <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white border-0'>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
          </InputGroup>
        </Form> */}
        <Box sx={{ flexGrow: 1 }} />

        <>
          <Overlay target={targetDate.current} show={showDate} placement='bottom'>
            {(props) => <Tooltip {...props}>{moment().format('LL')}</Tooltip>}
          </Overlay>
          <Button
            style={{ height: '38px' }}
            size={'sm'}
            variant='light'
            className='mx-1'
            ref={targetDate}
            onClick={() => setShowDate(!showDate)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 22.344 24.605'
            >
              <g id='Calendar' transform='translate(1 1)'>
                <path
                  id='Path_4'
                  data-name='Path 4'
                  d='M4.5,15H24.844'
                  transform='translate(-4.5 -5.959)'
                  fill='none'
                  stroke='#f26930'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
                <path
                  id='Path_1'
                  data-name='Path 1'
                  d='M6.76,6H22.583a2.232,2.232,0,0,1,2.26,2.2V23.619a2.232,2.232,0,0,1-2.26,2.2H6.76a2.232,2.232,0,0,1-2.26-2.2V8.2A2.232,2.232,0,0,1,6.76,6Z'
                  transform='translate(-4.5 -3.217)'
                  fill='none'
                  stroke='#a7a9ac'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
                <path
                  id='Path_2'
                  data-name='Path 2'
                  d='M24,3V8.566'
                  transform='translate(-9.307 -3)'
                  fill='none'
                  stroke='#f26930'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
                <path
                  id='Path_3'
                  data-name='Path 3'
                  d='M12,3V8.566'
                  transform='translate(-6.35 -3)'
                  fill='none'
                  stroke='#f26930'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
              </g>
            </svg>
            <small className='text-muted fw-bold d-none d-sm-inline'>&nbsp;{moment().format('LL')}</small>
          </Button>{' '}
        </>

        <Box sx={{ flexGrow: 0.01 }} />
        <>
          <Overlay target={targetTime.current} show={showTime} placement='bottom'>
            {(props) => <Tooltip {...props}>{displayTime}</Tooltip>}
          </Overlay>
          <Button
            style={{ height: '38px' }}
            size={'sm'}
            variant='light'
            className='mx-1'
            ref={targetTime}
            onClick={() => setShowTime(!showTime)}
            >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 24.605 24.605'
            >
              <g id='Clock' transform='translate(1 1)'>
                <path
                  id='Path_5'
                  data-name='Path 5'
                  d='M25.6,14.3A11.3,11.3,0,1,1,14.3,3,11.3,11.3,0,0,1,25.6,14.3Z'
                  transform='translate(-3 -3)'
                  fill='none'
                  stroke='#a7a9ac'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
                <path
                  id='Path_6'
                  data-name='Path 6'
                  d='M18,9v6.781l4.521,2.26'
                  transform='translate(-6.697 -4.479)'
                  fill='none'
                  stroke='#f26930'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                />
              </g>
            </svg>
            <small className='text-muted fw-bold d-none d-sm-inline'>&nbsp;{displayTime}</small>
          </Button>{' '}
        </>
        <Box sx={{ flexGrow: 0.01 }} />
        <Button className='p-0 mx-1' variant={'transparent'} size={'sm'} title={"Notifications"}>
          <Avatar sx={{ width: 38, height: 38 }} variant='rounded' className='bg-white'>
            <Badge color='error' overlap='circular' badgeContent=' ' variant='dot'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 30 32.995'
              >
                <g
                  id='Icon_feather-bell'
                  data-name='Icon feather-bell'
                  transform='translate(-3 -1.5)'
                >
                  <path
                    id='Path_13'
                    data-name='Path 13'
                    d='M27,12A9,9,0,0,0,9,12C9,22.5,4.5,25.5,4.5,25.5h27S27,22.5,27,12'
                    fill='none'
                    stroke='#707070'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='3'
                  />
                  <path
                    id='Path_14'
                    data-name='Path 14'
                    d='M20.595,31.5a3,3,0,0,1-5.19,0'
                    fill='none'
                    stroke='#707070'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='3'
                  />
                </g>
              </svg>
            </Badge>
          </Avatar>
        </Button>
        <Box sx={{ flexGrow: 0.01 }} />
        <div style={{zIndex: '99'}}>
          <Overlay
              target={targetHelp.current}
              show={showHelp}
              placement='bottom'

          >
            <Popover id={`popover-positioned-bottom`} style={{fontSize: '12px', width: '700px', maxWidth: '700px', zIndex: '2000'}} >
              <Popover.Body>
                <>

                  <Row>
                    <Col className="text-center"><b>Modules</b></Col>
                  </Row>
                  {/*{*/}
                  {/*  modules.map((module: any) => (*/}
                  {/*          <>*/}
                  {/*            {*/}
                  {/*              module.sidenav_show ?*/}
                  {/*                <Row className="mt-3" key={module.id}>*/}
                  {/*                  <Col><b>{module.name}</b></Col>*/}
                  {/*                </Row> : ""*/}
                  {/*            }*/}

                  {/*            <>*/}
                  {/*              {*/}
                  {/*                module.sidenav_sublinks.map((submodule: any, index: number) => (*/}
                  {/*                    submodule.hasPermission?*/}
                  {/*                      <Row key={submodule.id} className={"sub-module"}>*/}
                  {/*                        <Col className={"submodule"}>{submodule.name}</Col>*/}
                  {/*                      </Row>*/}
                  {/*                        : ""*/}
                  {/*                ))*/}
                  {/*              }*/}
                  {/*            </>*/}

                  {/*          </>*/}

                  {/*  ))*/}
                  {/*}*/}
                  <Row>
                    <Col><b>Administration</b></Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Notice</Col>
                    <Col className={"submodule"}>Allowed Devices</Col>
                  </Row>
                  <Row className="sub-module">
                    <Col className={"submodule"}>Change Password</Col>
                  </Row>
                  <Row className="mt-3">
                    <Col><b>Employees</b></Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Employee List</Col>
                    <Col className={"submodule"}>Scheduling</Col>
                  </Row>
                  <Row className="mt-3">
                    <Col><b>Leaves</b></Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Leave Application</Col>
                    <Col className={"submodule"}>Report</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Leave Approval</Col>
                  </Row>
                  <Row className="mt-3">
                    <Col><b>Attendance</b></Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Attendance Report</Col>
                    <Col className={"submodule"}>Manual Attendance</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Official Business</Col>
                    <Col className={"submodule"}>Certificate of Attendance</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Overtime</Col>
                    <Col className={"submodule"}>Undertime</Col>
                  </Row>
                  <Row className="mt-3">
                    <Col><b>Payroll</b></Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Report</Col>
                    <Col className={"submodule"}>Generate Payroll</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Adjustment</Col>
                  </Row>
                  <Row className="mt-3">
                    <Col><b>Setup</b></Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Timezone</Col>
                    <Col className={"submodule"}>Department</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Designation</Col>
                    <Col className={"submodule"}>Branch</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Worksites</Col>
                    <Col className={"submodule"}>Shifts</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Attendance Setup</Col>
                    <Col className={"submodule"}>Holiday Management</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Holiday Type</Col>
                    <Col className={"submodule"}>Leave Configuration</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Allowance</Col>
                    <Col className={"submodule"}>Deduction</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Paygrade</Col>
                    <Col className={"submodule"}>Tax Payroll Setup</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Gov. Contributions</Col>
                    <Col className={"submodule"}>Payroll Range</Col>
                  </Row>
                  <Row className={"sub-module"}>
                    <Col className={"submodule"}>Payroll Computation</Col>
                    <Col className={"submodule"}>Late Computation</Col>
                  </Row>
                </>
              </Popover.Body>
            </Popover>
          </Overlay>
          <Button
              className='p-0 mx-1'
              variant={'transparent'}
              size={'sm'}
              title={"Help"}
              ref={targetHelp}
              onClick={() => {
                setShowHelp(!showHelp)
                setShowUser(false)
              }}
          >
            <Avatar sx={{ width: 38, height: 38 }} variant='rounded' className='bg-white'>
              <QuestionMarkIcon/>
            </Avatar>
          </Button>
        </div>

        <Box sx={{ flexGrow: 0.01 }} />

        <>
        <Overlay target={targetUser.current} show={showUser} placement='bottom' rootClose onHide={() => setShowUser(false)}>
          <Dropdown.Menu show style={{paddingTop: '12px'}}>
            <Dropdown.Item onClick={() => redirectProfile()} eventKey="1">Profile</Dropdown.Item>
            <Dropdown.Item onClick={() => redirectPayslip()} eventKey="2">Payslips</Dropdown.Item>
            <Dropdown.Item onClick={() => redirectChangePassword()} eventKey="3">Change Password</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => redirectLogin()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Overlay>

        <Button 
          className='p-0 '
          variant={'transparent'}
          size={'sm'}
          ref={targetUser}
          onClick={() => {
            setShowUser(!showUser)
            setShowHelp(false)
          }}
          title={"My Profile"}
          >
          {
            userDetails.photo != "" ?
                <Avatar sx={{ width: 38, height: 38 }} src={process.env.REACT_APP_IMAGE_URL+ userDetails?.photo} variant='rounded'></Avatar> :
                <Avatar sx={{ width: 38, height: 38, backgroundColor: "white", color: "#F36F2E" }}variant='rounded'>{initials.toUpperCase()}</Avatar>
          }
        </Button>
        </>
      </Toolbar>
    </StyledAppBar>
  )
}

export default AppBar
