import React from 'react'

const EmployeeIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18.533'
      height='15.352'
      viewBox='0 0 18.533 15.352'
    >
      <g id='Employee' transform='translate(0.5 0.506)'>
        <path
          id='Path_7'
          data-name='Path 7'
          d='M14.252,27.282V25.688A3.188,3.188,0,0,0,11.064,22.5H4.688A3.188,3.188,0,0,0,1.5,25.688v1.594'
          transform='translate(-1.5 -12.936)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_8'
          data-name='Path 8'
          d='M13.876,7.688A3.188,3.188,0,1,1,10.688,4.5,3.188,3.188,0,0,1,13.876,7.688Z'
          transform='translate(-4.313 -4.5)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_9'
          data-name='Path 9'
          d='M32.391,27.373V25.779A3.188,3.188,0,0,0,30,22.7'
          transform='translate(-14.857 -13.027)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_10'
          data-name='Path 10'
          d='M24,4.7a3.188,3.188,0,0,1,0,6.177'
          transform='translate(-12.045 -4.592)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default EmployeeIcon
