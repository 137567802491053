import React from 'react'

const CircleCreate = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16.143'
      height='16.143'
      viewBox='0 0 16.143 16.143'
    >
      <g id='New' transform='translate(0.5 0.5)'>
        <g
          id='Icon_feather-plus-circle'
          data-name='Icon feather-plus-circle'
          transform='translate(-3 -3)'
        >
          <path
            id='Path_46'
            data-name='Path 46'
            d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'
            fill='none'
            stroke='#a7a9ac'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
          <path
            id='Path_47'
            data-name='Path 47'
            d='M18,12v6.057'
            transform='translate(-7.429 -4.457)'
            fill='none'
            stroke='#f26930'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
          <path
            id='Path_48'
            data-name='Path 48'
            d='M12,18h6.057'
            transform='translate(-4.457 -7.429)'
            fill='none'
            stroke='#f26930'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
        </g>
      </g>
    </svg>
  )
}

export default CircleCreate
