import React, { useEffect } from 'react'
import {
  Button,
  Card,
  Col,
  Row,
  Table
} from "react-bootstrap";
import Pagination from 'components/Pagination'
import Alert from 'components/Alert'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { IAttendance } from './types'
import { useSelector,  useDispatch} from 'react-redux'
import type { RootState } from 'redux/appStore'
import { addAttendance } from 'redux/Dashboard/Attendance/AttendanceSlice'

export default function Index() {
  const lists = useSelector((state: RootState) => state.attendance)
  const dispatch = useDispatch()

  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(6)
  const [itemsCount, setItemCount] = React.useState(0)

  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)

  const getAttendaceAuth = async (page_number: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IAttendance>>(API_ENDPOINT.GET_ATTENDANCE_AUTH, {
        params: {
          page: page_number
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        dispatch(addAttendance(result?.data?.results?.data))
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getAttendaceAuth(null)
    })()
  }, [])

  return (
    <>
      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />
      <Card className="border-0 p-0 bordered-card" style={{overflowY: 'hidden', height: '325px'}}>
        <Card.Body className="pb-0">
          <Row className="p-1">
            <Col sm={6}>
              <span className="fw-bold">My Attendance</span>
            </Col>
          </Row>
          {/*<hr/>*/}
          <Table responsive size="sm" className="bordered-tabs-top mt-2" style={{whiteSpace: 'nowrap'}}>
            <thead className="thead-table">
              <tr className="text-center">
                <th style={{width: '10%'}}>Date</th>
                <th style={{width: '5%'}}>Status</th>
                <th style={{width: '10%'}}>Clock In</th>
                <th style={{width: '10%'}}>Clock Out</th>
                <th style={{width: '10%'}}>Worked Time</th>
                <th style={{width: '10%'}}>Overtime In</th>
                <th style={{width: '10%'}}>Overtime Out</th>
                <th style={{width: '10%'}}>Late</th>

              </tr>
            </thead>
            <tbody>
              <>
              {lists.length > 0 ? (
                lists.map((item, index) => 
                   <tr className="mb-2" key={index}>
                    <td className="text-center">{item.date}</td>
                     <td className="text-center">
                       <div className="d-grid">
                         {(() => {
                           if (item.status == 'Leave') {
                             return (
                                 <Button
                                     className=" p-1 pt-0 pb-0"
                                     style={{fontSize: '12px', color: 'white'}}
                                     variant="info"
                                     size="sm"
                                 >
                                   Leave
                                 </Button>
                             )
                           } else if(item.status == 'Present') {
                             return (
                                 <Button
                                     className=" p-1 pt-0 pb-0"
                                     style={{fontSize: '12px', color: 'white'}}
                                     variant="success"
                                     size="sm"
                                 >
                                   Present
                                 </Button>
                             )
                           }

                           return (
                               <Button
                                   className=" p-1 pt-0 pb-0"
                                   style={{fontSize: '12px', color: 'white'}}
                                   variant='secondary'
                                   size="sm"
                               >
                                 Absent
                               </Button>
                           )
                         })()}
                       </div>
                     </td>
                    <td className="text-center">{item.in_time != null ? item.in_time : '-'}</td>
                    <td className="text-center">{item.out_time != null ? item.out_time : '-'}</td>
                    <td className="text-center">{item.worked_time != null ? item.worked_time : '-'}</td>
                     <td className="text-center">{item.overtime_in != null ? item.overtime_in : '-'}</td>
                     <td className="text-center">{item.overtime_out != null ? item.overtime_out : '-'}</td>
                    <td className="text-center">{item.late_time != null ? item.late_time : '-'}</td>

                  </tr>
                  )
                  ) : (
                  <tr className='no-item'>
                    <td colSpan={8}>{TEXT.NOT_FOUND}</td>
                  </tr>
                )}
              </>
            </tbody>
          </Table>

          <Pagination
            itemsCount={itemsCount}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            lastPage={lastPage}
            setCurrentPage={setCurrentPage}
            alwaysShown={false}
            pageClicked={(page: number) => { 
                getAttendaceAuth(page);
              }
            }
          />
        </Card.Body>
      </Card>
    </>
  );
}
