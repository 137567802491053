import React from 'react'

const EmployeeWhite = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='43.075'
      height='35.877'
      viewBox='0 0 43.075 35.877'
    >
      <g id='Icon_feather-users' data-name='Icon feather-users' transform='translate(0 -2.912)'>
        <path
          id='Path_7'
          data-name='Path 7'
          d='M30.645,33.43V29.786A7.286,7.286,0,0,0,23.359,22.5H8.786A7.286,7.286,0,0,0,1.5,29.786V33.43'
          transform='translate(0 3.859)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          id='Path_8'
          data-name='Path 8'
          d='M22.073,11.786A7.286,7.286,0,1,1,14.786,4.5,7.286,7.286,0,0,1,22.073,11.786Z'
          transform='translate(1.286)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          id='Path_9'
          data-name='Path 9'
          d='M35.465,33.388V29.745A7.286,7.286,0,0,0,30,22.7'
          transform='translate(6.11 3.901)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          id='Path_10'
          data-name='Path 10'
          d='M24,4.7a7.286,7.286,0,0,1,0,14.117'
          transform='translate(4.824 0.042)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}

export default EmployeeWhite
