import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IWorkValue } from './types'

const initialState: IWorkValue[] = [{
  organization_name: '',
  designation: '',
  from_date: '',
  to_date: '',
}]

const WorkSlice = createSlice({
  name: 'Work',
  initialState,
  reducers: {
    addWork: (_state, state: PayloadAction<IWorkValue[]>) => state.payload,
  },
})

export const { addWork } = WorkSlice.actions
export default WorkSlice.reducer
