import React from 'react'

const AdminActiveIcon = () => {
  return (
    <svg
      id='AdminActive'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='16'
      height='17.777'
      viewBox='0 0 16 17.777'
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='0.5'
          x2='0.5'
          y2='1'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#f26930' />
          <stop offset='1' stopColor='#fba91a' />
        </linearGradient>
      </defs>
      <path
        id='Path_30'
        data-name='Path 30'
        d='M4.5,9.222,12.5,3l8,6.222V19a1.778,1.778,0,0,1-1.778,1.778H6.278A1.778,1.778,0,0,1,4.5,19Z'
        transform='translate(-4.5 -3)'
        fill='url(#linear-gradient)'
      />
      <path
        id='Path_31'
        data-name='Path 31'
        d='M13.5,25.857V18h5.333v7.857'
        transform='translate(-8.166 -9.111)'
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1'
      />
    </svg>
  )
}

export default AdminActiveIcon
