import React from 'react'

const EmployeeActiveIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='17.752'
      height='15.344'
      viewBox='0 0 17.752 15.344'
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='0.5'
          x2='0.5'
          y2='1'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#f26930' />
          <stop offset='1' stopColor='#fba91a' />
        </linearGradient>
      </defs>
      <g id='EmployeeActive' transform='translate(0.5 0.5)'>
        <path
          id='Path_54'
          data-name='Path 54'
          d='M14.252,27.282V25.688A3.188,3.188,0,0,0,11.064,22.5H4.688A3.188,3.188,0,0,0,1.5,25.688v1.594'
          transform='translate(2.5 -12.937)'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
          fill='url(#linear-gradient)'
        />
        <path
          id='Path_53'
          data-name='Path 53'
          d='M13.876,7.688A3.188,3.188,0,1,1,10.688,4.5,3.188,3.188,0,0,1,13.876,7.688Z'
          transform='translate(-0.313 -4.5)'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
          fill='url(#linear-gradient)'
        />
        <path
          id='Path_7'
          data-name='Path 7'
          d='M14.252,27.282V25.688A3.188,3.188,0,0,0,11.064,22.5H4.688A3.188,3.188,0,0,0,1.5,25.688v1.594'
          transform='translate(-1.5 -12.937)'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
          fill='url(#linear-gradient)'
        />
        <path
          id='Path_8'
          data-name='Path 8'
          d='M13.876,7.688A3.188,3.188,0,1,1,10.688,4.5,3.188,3.188,0,0,1,13.876,7.688Z'
          transform='translate(-4.313 -4.5)'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
          fill='url(#linear-gradient)'
        />
      </g>
    </svg>
  )
}

export default EmployeeActiveIcon
