import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
  Stack
} from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Accept from 'asset/SvgComponent/Accept'
import AcceptDisabled from 'asset/SvgComponent/AcceptDisabledIcon'
import Reject from 'asset/SvgComponent/Reject'
import RejectDisabled from 'asset/SvgComponent/RejectDisabledIcon'
import View from 'asset/SvgComponent/View'
import Delete from 'asset/SvgComponent/Delete'
import Write from 'asset/SvgComponent/Write'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Calendar from 'asset/SvgComponent/Calendar'
import Clock from 'asset/SvgComponent/Clock'
import Download from 'asset/SvgComponent/Download'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Avatar } from '@mui/material'

import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import { Formik, FieldArray} from 'formik';
import { validationSchema } from './validationSchema'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT, IMAGE_BASE_URL
} from 'utils/globalConstant'
import { ICOA, IList, IItem, IItemRequest, ICOARequest, IListRequest } from './types'
import moment from "moment"
import Select from "components/ReactSelect";
import {IEmployee} from "../../AttendanceReport/Construction/types";
import CircleCreate from "asset/SvgComponent/CircleCreate";

export default function Index() {
  const [key, setKey] = useState('certificate-application')
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [currentPage1, setCurrentPage1] = useState(0)
  const [lastPage1, setLastPage1] = useState(0)
  const [itemsPerPage1, setPerPage1] = useState(10)
  const [itemsCount1, setItemCount1] = useState(0)
  const [from1, setFrom1] = useState(0)

  const [showCreate, setShowCreate] = useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = async () => {
    setAddedFiles([])
    setShowCreate(true)
    getAllEmployee()
  }

  const [showEdit, setShowEdit] = useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {

    setShowConfirm(true)
    setItem({
      id: id,
      employee_id: 0,
      type: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      requested_attendance: [{
        id: 0,
        certificate_of_attendance_id: 0,
        time_in_out: '',
        time_in_out_date: '',
        time_in_out_time: ''
      }],
      employee: {
        employee_id: 0,
        first_name: "",
        last_name: "",
        user_name: {
          role: {
            role_name: ""
          }
        },
        photo: ""
      }
    })
  }

  const [showDetail, setShowDetail] = useState(false)
  const handleCloseDetail = () => setShowDetail(false)
  const handleShowDetail = () => setShowDetail(true)

  const [showAccept, setShowAccept] = useState(false)
  const handleCloseAccept = () => setShowAccept(false)
  const handleShowAccept = (id: any) => {
    setShowAccept(true)
    setItemRequest({
      id: id,
      employee_id: 0,
      type: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      requested_attendance: [{
        id: 0,
        certificate_of_attendance_id: 0,
        time_in_out: '',
        time_in_out_date: '',
        time_in_out_time: ''
      }],
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: '',
        approvers_order: ''
      }],
      documents: [],
      approvers_order: 0,
      approve_by: "0",
      applied_by_first_name: "",
      applied_by_last_name: "",
      levels_of_approval: [
        {
          approvers_order: "",
          level: ""
        }
      ],
      current: ''
    })
  }

  const [showReject, setShowReject] = useState(false)
  const handleCloseReject = () => setShowReject(false)
  const handleShowReject = (id: any) => {
    setShowReject(true)
    setItemRequest({
      id: id,
      employee_id: 0,
      type: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      requested_attendance: [{
        id: 0,
        certificate_of_attendance_id: 0,
        time_in_out: '',
        time_in_out_date: '',
        time_in_out_time: ''
      }],
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: '',
        approvers_order: ''
      }],
      documents: [],
      approvers_order: 0,
      approve_by:"0",
      applied_by_first_name: "",
      applied_by_last_name: "",
      levels_of_approval: [
        {
          approvers_order: "",
          level: ""
        }
      ],
      current: ''
    })
  }

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const [lists, setData] = useState<IList[]>([])
  const [requests, setRequest] = useState<IListRequest[]>([])
  const [item, setItem] = useState<IItem>({
    id: 0,
    employee_id: 0,
    type: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    requested_attendance: [{
      id: 0,
      certificate_of_attendance_id: 0,
      time_in_out: '',
      time_in_out_date: '',
      time_in_out_time: ''
    }],
    employee: {
      employee_id: 0,
      first_name: "",
      last_name: "",
      user_name: {
        role: {
          role_name: ""
        }
      },
      photo: ""
    }
  })

  const [itemRequest, setItemRequest] = useState<IItemRequest>({
    id: 0,
    employee_id: 0,
    type: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    approvers_order: 0,
    requested_attendance: [{
      id: 0,
      certificate_of_attendance_id: 0,
      time_in_out: '',
      time_in_out_date: '',
      time_in_out_time: ''
    }],
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      level: '',
      approvers_order: ''
    }],
    documents: [],
    approve_by: "0",
    applied_by_first_name: "",
    applied_by_last_name: "",
    levels_of_approval: [
      {
        approvers_order: "",
        level: ""
      }
    ],
    current: ''
  })
  const [dateFormat, setDateFormat] = useState('')
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [selectedEmployeeOptionForEdit, setSelectedEmployeeOptionForEdit] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
  const [disableSelect, setDisableSelect] = useState(false)
  const [addedFiles, setAddedFiles] = useState([])
  const [uploadedFilesDisplay, setUploadedFilesDisplay] = useState([])
  const [showApproveCancelButton, setShowApproveCancelButton] = useState(true)

  const handleMultipleFiles = (file: any) => {
    const limit = 1024 * 1024 * 4; // 4mb
    const files = file.target.files;
    const fileArray = Array.from(files);



    let exceed = false;
    let count = 0;
    for(let i =0; i<fileArray.length; i++){
      // @ts-ignore
      if(fileArray[i].size > limit){
        exceed = true

      }
      count++
    }

    if(!exceed){
      setAddedFiles(Array.from(files))
    }else{
      setAddedFiles([])
      setShowError(true)
      setError("File must not be greater than 4MB.")

      setTimeout(() => {
        setShowError(false)
      }, 3000);
    }

    if(count > 5){
      setAddedFiles([])
      setShowError(true)
      setError("Only 5 files are allowed to upload.")

      setTimeout(() => {
        setShowError(false)
      }, 3000);
    }


  }

  const handleDisableAcceptRejectButton = (item: any) => {
    if(item.status == '3' || item.status == '2'){
      return true
    }else if(item.current == '0' || item.current == '2'){
      return true
    }else{
      return false
    }

  }

  const handleChangeDropdownOverview = (selectedOption: any) => {
    setSelectedEmployeeOption(selectedOption)
  }

  const handleChangeDropdownOverviewForEdit = (selectedOption: any) => {
    setSelectedEmployeeOptionForEdit(selectedOption)
  }

  const handleDownloadFile = (fileName: string) => {
    window.location.href = `${IMAGE_BASE_URL}api/downloadFileForCertificate/${fileName}`;
  }

  const getAllEmployee = async () => {
    try {
      const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
      const result = await res
      if (result?.data?.results?.length) {
        const employees = result?.data?.results;
        if(JSON.parse(localStorage.getItem('role') ?? "") != "Admin" &&
            JSON.parse(localStorage.getItem('role') ?? "") != "Supervisor"){
          employees.map(employee => {
            if(employee.employee_id == Number(localStorage.getItem('employee_id'))){
              setSelectedEmployeeOption({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
              setDisableSelect(true)
              setEmployeeOptions([])
              setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
              setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
            }
          })

        }else{
          setDisableSelect(false)
          setEmployeeOptions([])
          setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
          employees.map(employee => {
            setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
          })
        }

      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getCertificate = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<ICOA>>(API_ENDPOINT.GET_CERTIFICATE, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showCertificate = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_CERTIFICATE +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
        setSelectedEmployeeOptionForEdit({label: result?.data?.results?.employee?.first_name + " " + result?.data?.results?.employee?.last_name, value: result?.data?.results?.employee_id})
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postCertificate = async (value: any, {resetForm} : any) => {
    if(selectedEmployeeOption.value != 0){
      await axios.post(API_ENDPOINT.POST_CERTIFICATE_FOR_MFBC, {
        type: value.type,
        reason: value.reason,
        requested: value.requested,
        employee_id: selectedEmployeeOption.value
      }).then(response => {
        if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.CERTIFICATE.CREATED)
          getCertificate(null);
          getCertificateRequest(null)
          if(addedFiles.length > 0){
            handleUploadFiles(response?.data?.results?.id);
          }

        }
        resetForm({})
        handleCloseCreate();
      });
    }else{
      setError("Please select employee.")
      setShowError(true)
    }



    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const handleUploadFiles = async (id: any) => {
    const formData = new FormData();
    addedFiles.forEach((file) => {
      formData.append('files[]', file); // Use 'files[]' to handle multiple files
    });
    formData.append("id", id);


    await axios.post(API_ENDPOINT.UPLOAD_FILE_CERTIFICATE_FOR_JVD, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    }).then(response => {
      if (response?.data?.status * 1 === 1) {
        console.log("success")
      }
    });

  }

  const updateCertificate = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_CERTIFICATE +'/'+ value.id, {
      type: value.type,
      reason: value.reason,
      requested: value.requested
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.CERTIFICATE.UPDATED)
          getCertificate(currentPage);
       }
       resetForm({})
       handleCloseEdit();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const deleteCertificate = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_CERTIFICATE +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.CERTIFICATE.DELETED)
        getCertificate(currentPage);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getCertificateRequest = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<ICOARequest>>(API_ENDPOINT.GET_CERTIFICATE_REQUEST_FOR_MFBC, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setRequest(result?.data?.results?.data)
        setCurrentPage1(result?.data?.results?.current_page)
        setLastPage1(result?.data?.results?.last_page)
        setPerPage1(result?.data?.results?.per_page)
        setItemCount1(result?.data?.results?.total)
        setFrom1(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showCertificateRequest = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItemRequest>>(API_ENDPOINT.SHOW_CERTIFICATE_REQUEST_FOR_MFBC +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItemRequest(result?.data?.results)
      }
      handleShowDetail()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const acceptCertificateRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.ACCEPT_CERTIFICATE_REQUEST_FOR_MFBC +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.CERTIFICATE.ACCEPTED)
          getCertificateRequest(currentPage1);
          getCertificate(currentPage)
       }
       handleCloseAccept();
       handleCloseDetail();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const rejectCertificateRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.REJECT_CERTIFICATE_REQUEST_FOR_MFBC +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.CERTIFICATE.REJECTED)
          getCertificateRequest(currentPage1);
          getCertificate(currentPage)
       }
       handleCloseReject();
       handleCloseDetail();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getCertificate(null)
      getCertificateRequest(null)
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Certificate of Attendance</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/tax-rule-setup'>
            Attendance
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Certificate of Attendance</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Tabs
          variant={'tabs'}
          className='tabs '
          id='controlled-tab-example'
          activeKey={key}
          onSelect={(k: any) => {
            setKey(k)
          }}
          >
          <Tab eventKey='certificate-application' title='Application'>
            <Card className='border-0 p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Certificate of Attendance Application</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25 mx-2'}>
                      <Form.Control
                        aria-label='search'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                    <Button
                      onClick={handleShowCreate}
                      className=' add-new-btn rounded text-white'
                      variant='warning'
                      >
                      <EditIcon/>
                      &nbsp;Apply for COA
                    </Button>{' '}
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table className='bordered-tabs-top mt-3' responsive>
                  <thead>
                    <tr className='text-center'>
                      <th style={{width: '4%'}}>No.</th>
                      <th>Application Date</th>
                      <th style={{width: '15%'}}>Employee Name</th>
                      <th style={{width: '18%'}}>Type</th>
                      <th style={{width: '18%'}}>Reason</th>
                      <th>Applied By</th>
                      <th style={{ width: '50px' }}>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {lists.length > 0 ? (
                        lists.map((item, index) => 
                          <tr className='mb-2' key={index}>
                            <td>{from + index}</td>
                            <td className="text-center">{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                            <td>
                              <Stack direction='horizontal'>
                                <div>
                                  {
                                    item.photo != "" ?
                                        <Avatar
                                            sx={{ width: 20, height: 20 }}
                                            src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                        ></Avatar> :
                                        <Avatar
                                            sx={{ width: 20, height: 20}}
                                            src={""}
                                        ></Avatar>
                                  }
                                </div>
                                <div>&nbsp;{item.first_name} {item.last_name}</div>
                              </Stack>
                            </td>
                            <td>{item.type}</td>
                            <td className="display-reason" title={item.reason ?? ""}>{item.reason}</td>
                            <td>{item.applied_by_first_name} {item.applied_by_last_name}</td>
                            <td className='text-center'>
                              <div className='d-grid'>
                                <>
                                  {(() => {
                                    switch (item.status) {
                                      case '1':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-secondary'
                                            size='sm'
                                            >
                                            Pending
                                          </Button>
                                        )
                                      case '2':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-danger'
                                            size='sm'
                                            >
                                            Disapproved
                                          </Button>
                                        )
                                      case '3':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-success'
                                            size='sm'
                                            >
                                            Approved
                                          </Button>
                                        )
                                    }
                                  })()}
                                </>
                              </div>
                            </td>
                            <td className='text-center'>
                              <Button
                                  onClick={() => {
                                    showCertificateRequest(item.id)
                                    setShowApproveCancelButton(false)
                                  }}
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                              >
                                <View/>
                              </Button>
                              <Button
                                onClick={() => showCertificate(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                disabled={item.status == '3' ? true: false}
                                >
                                <Write/>
                              </Button>
                              <Button
                                onClick={() => handleShowConfirm(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                disabled={item.status == '3' ? true: false}
                                >
                                <Delete/>
                              </Button>
                            </td>
                          </tr>
                        )
                        ) : (
                        <tr className='no-item'>
                          <td colSpan={8}>{TEXT.NOT_FOUND}</td>
                        </tr>
                      )}
                    </>
                  </tbody>
                </Table>

                <Pagination
                  itemsCount={itemsCount}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  lastPage={lastPage}
                  setCurrentPage={setCurrentPage}
                  alwaysShown={false}
                  pageClicked={(page: number) => { 
                      getCertificate(page);
                    }
                  }
                  />

                <Dialog 
                  show={showConfirm} 
                  setShow={handleCloseConfirm}
                  setEvent={() => { 
                      deleteCertificate(item.id)
                    }
                  }
                />

                <Loader show={showLoading}/>
              </Card.Body>
            </Card>
          </Tab>

          <Tab eventKey='certificate-approval' title='Approval'>
            <Card className='border-0 p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Certificate Of Attendance Approval</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25'}>
                      <Form.Control
                        aria-label='search'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table className='bordered-tabs-top mt-3' responsive>
                  <thead>
                    <tr className='text-center'>
                      <th style={{width: '4%'}}>No.</th>
                      <th>Application Date</th>
                      <th style={{width: '15%'}}>Employee Name</th>
                      <th>Type</th>
                      <th style={{width: '18%'}}>Reason</th>
                      <th style={{width: '15%'}}>Applied By</th>
                      <th style={{ width: '50px' }}>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {requests.length > 0 ? (
                        requests.map((item, index) => 
                          <tr className='mb-2' key={index}>
                            <td>{from1 + index}</td>
                            <td className="text-center">{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                            <td>
                              <Stack direction='horizontal'>
                                <div>
                                  {
                                    item.photo != "" ?
                                        <Avatar
                                            sx={{ width: 20, height: 20 }}
                                            src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                        ></Avatar> :
                                        <Avatar
                                            sx={{ width: 20, height: 20}}
                                            src={""}
                                        ></Avatar>
                                  }
                                </div>
                                <div>&nbsp;{item.first_name} {item.last_name}</div>
                              </Stack>
                            </td>
                            <td>{item.type}</td>
                            <td className="display-reason" title={item.reason ?? ""}>{item.reason}</td>
                            <td>{item.applied_by_first_name} {item.applied_by_last_name}</td>
                            <td className='text-center'>
                              <div className='d-grid'>
                                <>
                                  {(() => {
                                    switch (item.status) {
                                      case '1':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-secondary'
                                            size='sm'
                                            >
                                            Pending
                                          </Button>
                                        )
                                      case '2':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-danger'
                                            size='sm'
                                            >
                                            Disapproved
                                          </Button>
                                        )
                                      case '3':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-success'
                                            size='sm'
                                            >
                                            Approved
                                          </Button>
                                        )
                                    }
                                  })()}
                                </>
                              </div>
                            </td>
                            <td className='text-center'>
                              <Button
                                onClick={() => {
                                  showCertificateRequest(item.id)
                                  setShowApproveCancelButton(true)
                                }}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                >
                                <View/>
                              </Button>
                              <Button
                                  onClick={() => handleShowAccept(item.id)}
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                                  disabled={handleDisableAcceptRejectButton(item)}
                              >
                                {
                                  handleDisableAcceptRejectButton(item) ?
                                   <AcceptDisabled/> : <Accept/>
                                }
                              </Button>
                              <Button
                                onClick={() => handleShowReject(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                disabled={handleDisableAcceptRejectButton(item)}
                                >
                                {
                                  handleDisableAcceptRejectButton(item) ?
                                      <RejectDisabled/> : <Reject/>
                                }

                              </Button>
                            </td>
                          </tr>
                        )
                        ) : (
                        <tr className='no-item'>
                          <td colSpan={8}>{TEXT.NOT_FOUND}</td>
                        </tr>
                      )}
                    </>
                  </tbody>
                </Table>
                  <Pagination
                    itemsCount={itemsCount1}
                    itemsPerPage={itemsPerPage1}
                    currentPage={currentPage1}
                    lastPage={lastPage1}
                    setCurrentPage={setCurrentPage1}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                        getCertificateRequest(page);
                      }
                    }
                    />

                  <Dialog 
                    show={showAccept} 
                    setShow={handleCloseAccept}
                    setEvent={() => { 
                        acceptCertificateRequest(itemRequest.id)
                      }
                    }
                    variant='accept'
                    message='Please confirm to accept certificate request?'
                  />

                  <Dialog 
                    show={showReject} 
                    setShow={handleCloseReject}
                    setEvent={() => { 
                        rejectCertificateRequest(itemRequest.id)
                      }
                    }
                    variant='reject'
                    message='Do you really want to reject these certificate request?'
                  />

                  <Loader show={showLoading}/>

              </Card.Body>
            </Card>
          </Tab>
        </Tabs>

        {/* ADD MODAL*/}
        <Formik
          initialValues={{
            type: '',
            reason: '',
            requested: [{
              time_in_out: '',
              time_in_out_date: '',
              time_in_out_time: ''
            }],
            employee_id: '',
            documents: ''
          }}
          validationSchema={validationSchema}
          onSubmit={postCertificate}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="certificateCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' size='lg' centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Certificate of Attendance Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels required'>Employees</Form.Label>
                          <Select
                              name="employee_id"
                              value={selectedEmployeeOption}
                              options={employeeOptions}
                              onChange={(e:any )=> {
                                handleChangeDropdownOverview(e)

                              }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.employee_id}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                          <Form.Label className='fw-bold required'>
                            Type
                          </Form.Label>
                          <Form.Select
                              size='sm'
                              name="type"
                              value={values.type}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.type && !!errors.type}
                          >
                            <option value="" disabled>Select Type</option>
                            <option value="Power Outage">Power Outage</option>
                            <option value="Biometric device malfunction">Biometric device malfunction</option>
                            <option value="Computer malfunction">Computer malfunction</option>
                            <option value="Others">Others</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.type}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                          <Form.Label className='fw-bold required'>
                            Reason
                          </Form.Label>
                          <Form.Control
                              as='textarea'
                              placeholder=''
                              style={{fontSize: '90%'}}
                              rows={3}
                              name="reason"
                              value={values.reason}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.reason && !!errors.reason}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.reason}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                          <Form.Label className='fw-bold'>
                            Supporting Document/s
                          </Form.Label>
                          <Form.Control
                              type='file'
                              size={'sm'}
                              name="documents"
                              value={values.documents}
                              onChange={(e) => {
                                handleChange(e)
                                handleMultipleFiles(e)
                              }}
                              multiple
                              accept=".png,.jpg,.jpeg,.pdf"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.documents}
                          </Form.Control.Feedback>
                          <ul className="mt-1">
                            {
                              addedFiles.length > 0 ?
                                addedFiles.map((f : File, index) => (
                                    <li className="display-filename" key={index}>{f.name}</li>
                                )) :
                              <li>No file yet.</li>
                            }
                          </ul>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Row>
                        <Form.Group as={Col} md="3" className="position-relative">
                          <Form.Label column="sm" className="required fw-bold">Type</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="3" className="position-relative">
                          <Form.Label column="sm" className="required fw-bold">Date</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} md="3" className="position-relative">
                          <Form.Label column="sm" className="required fw-bold">Time</Form.Label>
                        </Form.Group>
                      </Row>
                      <FieldArray
                        name="requested"
                        render={arrayHelpers => (
                          <>
                            {values.requested.map((item: any, index: any) => (
                              <div key={index}>
                                {(() => {
                                  const ticketErrors = errors.requested?.length && errors.requested[index] || {};
                                  const ticketTouched = touched.requested?.length && touched.requested[index] || {};
                                  return(
                                    <>
                                      <Row className="mb-4">
                                        <Form.Group as={Col} md="3" className="position-relative">
                                          {/*<Form.Label column="sm" className="required">Type</Form.Label>*/}
                                          <InputGroup size={'sm'}>
                                            <Form.Select 
                                              size={'sm'}
                                              name={`requested.${index}.time_in_out`}
                                              value={item.time_in_out}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.time_in_out : false) && !!ticketTouched.time_in_out}
                                              >
                                                <option value="" disabled>Select Type</option>
                                                <option value="clock_in">Clock In</option>
                                                <option value="clock_out">Clock Out</option>
                                              </Form.Select>
                                              <Form.Control.Feedback type="invalid" tooltip>
                                                { (typeof ticketErrors === 'object') ? ticketErrors.time_in_out : ''}
                                              </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" className="position-relative">
                                          {/*<Form.Label column="sm" className="required">Date</Form.Label>*/}
                                          <InputGroup size={'sm'}>
                                            <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                              <Calendar/>
                                            </InputGroup.Text>
                                            <Form.Control 
                                              size='sm' 
                                              type='date' 
                                              placeholder=''
                                              name={`requested.${index}.time_in_out_date`}
                                              value={item.time_in_out_date}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.time_in_out_date : false) && !!ticketTouched.time_in_out_date}
                                              />
                                              <Form.Control.Feedback type="invalid" tooltip>
                                                { (typeof ticketErrors === 'object') ? ticketErrors.time_in_out_date : ''}
                                              </Form.Control.Feedback>
                                          </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" className="position-relative">
                                          {/*<Form.Label column="sm" className="required">Time</Form.Label>*/}
                                          <InputGroup size={'sm'}>
                                            <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                              <Clock/>
                                            </InputGroup.Text>
                                            <Form.Control 
                                              size='sm' 
                                              type='time' 
                                              placeholder=''
                                              name={`requested.${index}.time_in_out_time`}
                                              value={item.time_in_out_time}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.time_in_out_time : false) && !!ticketTouched.time_in_out_time}
                                              />
                                              <Form.Control.Feedback type="invalid" tooltip>
                                                { (typeof ticketErrors === 'object') ? ticketErrors.time_in_out_time : ''}
                                              </Form.Control.Feedback>
                                          </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" className='d-flex'>
                                            <Button
                                                onClick={() => arrayHelpers.push({
                                                  time_in_out: '',
                                                  time_in_out_date: '',
                                                  time_in_out_time: '',
                                                })}
                                                className='rounded text-white'
                                                variant="transparent"
                                                size="sm"
                                                style={{ fontSize: '12px', color: 'white' }}
                                            >
                                              <CircleCreate/>
                                            </Button>
                                            <Button
                                                onClick={() => arrayHelpers.remove(index)}
                                                className='rounded text-white'
                                                variant="transparent"
                                                size="sm"
                                                disabled={values.requested.length == 1}
                                                style={{ fontSize: '12px', color: 'white' }}
                                            >
                                              <Reject/>
                                            </Button>

                                        </Form.Group>
                                      </Row>
                                    </>
                                  )
                                })()}
                    
                              </div>
                            ))}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button 
                    variant='primary text-white' 
                    form="certificateCreate"  
                    className='mx-2' 
                    size={'sm'} 
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Send Application
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
          initialValues={{
            id: item.id,
            type: item.type,
            reason: item.reason,
            requested: item.requested_attendance,
            employee_id: selectedEmployeeOptionForEdit.value
          }}
          validationSchema={validationSchema}
          onSubmit={updateCertificate}
          enableReinitialize
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="certificateUpdate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' size='lg' centered show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Certificate of Attendance Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels required'>Employees</Form.Label>
                          <Select
                              name="employee_id"
                              value={selectedEmployeeOptionForEdit}
                              options={employeeOptions}
                              onChange={(e:any )=> {
                                handleChangeDropdownOverviewForEdit(e)

                              }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.employee_id}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                          <Form.Label className='fw-bold required'>
                            Type
                          </Form.Label>
                          <Form.Select
                              size='sm'
                              name="type"
                              value={values.type}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.type && !!errors.type}
                          >
                            <option value="" disabled>Select Type</option>
                            <option value="Power Outage">Power Outage</option>
                            <option value="Biometric device malfunction">Biometric device malfunction</option>
                            <option value="Computer malfunction">Computer malfunction</option>
                            <option value="Others">Others</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.type}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                          <Form.Label className='fw-bold required'>
                            Reason
                          </Form.Label>
                          <Form.Control
                              as='textarea'
                              placeholder=''
                              style={{fontSize: '90%'}}
                              rows={3}
                              name="reason"
                              value={values.reason}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.reason && !!errors.reason}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.reason}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <FieldArray
                        name="requested"
                        render={arrayHelpers => (
                          <>
                            {values.requested.map((item: any, index: any) => (
                              <div key={index}>
                                {(() => {
                                  const ticketErrors = errors.requested?.length && errors.requested[index] || {};
                                  const ticketTouched = touched.requested?.length && touched.requested[index] || {};
                                  return(
                                    <>
                                      <Row className="mb-4">
                                        <Form.Group as={Col} md="3" className="position-relative">
                                          <Form.Label column="sm" className="required">Type</Form.Label>
                                          <InputGroup size={'sm'}>
                                            <Form.Select 
                                              size={'sm'}
                                              name={`requested.${index}.time_in_out`}
                                              value={item.time_in_out}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.time_in_out : false) && !!ticketTouched.time_in_out}
                                              >
                                                <option value="" disabled>Select Type</option>
                                                <option value="clock_in_am">Clock In AM</option>
                                                <option value="clock_out_am">Clock Out AM</option>
                                                <option value="clock_in_pm">Clock In PM</option>
                                                <option value="clock_out_pm">Clock Out PM</option>
                                              </Form.Select>
                                              <Form.Control.Feedback type="invalid" tooltip>
                                                { (typeof ticketErrors === 'object') ? ticketErrors.time_in_out : ''}
                                              </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" className="position-relative">
                                          <Form.Label column="sm" className="required">Date</Form.Label>
                                          <InputGroup size={'sm'}>
                                            <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                              <Calendar/>
                                            </InputGroup.Text>
                                            <Form.Control 
                                              size='sm' 
                                              type='date' 
                                              placeholder=''
                                              name={`requested.${index}.time_in_out_date`}
                                              value={item.time_in_out_date}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.time_in_out_date : false) && !!ticketTouched.time_in_out_date}
                                              />
                                              <Form.Control.Feedback type="invalid" tooltip>
                                                { (typeof ticketErrors === 'object') ? ticketErrors.time_in_out_date : ''}
                                              </Form.Control.Feedback>
                                          </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" className="position-relative">
                                          <Form.Label column="sm" className="required">Time</Form.Label>
                                          <InputGroup size={'sm'}>
                                            <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                              <Clock/>
                                            </InputGroup.Text>
                                            <Form.Control 
                                              size='sm' 
                                              type='time' 
                                              placeholder=''
                                              name={`requested.${index}.time_in_out_time`}
                                              value={item.time_in_out_time}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.time_in_out_time : false) && !!ticketTouched.time_in_out_time}
                                              />
                                              <Form.Control.Feedback type="invalid" tooltip>
                                                { (typeof ticketErrors === 'object') ? ticketErrors.time_in_out_time : ''}
                                              </Form.Control.Feedback>
                                          </InputGroup>
                                        </Form.Group>
                                        <Form.Group style={{paddingTop: '30px'}} as={Col} md="3" className='d-flex'>
                                          <Button
                                              onClick={() => arrayHelpers.remove(index)}
                                              className='rounded text-white mx-1'
                                              variant='secondary'
                                              size="sm"
                                              disabled={values.requested.length == 1}
                                          >
                                            x
                                          </Button>
                                          <Button
                                              onClick={() => arrayHelpers.push({
                                                time_in_out: '',
                                                time_in_out_date: '',
                                                time_in_out_time: '',
                                              })}
                                              className='rounded text-white'
                                              variant='warning'
                                              size="sm"
                                          >
                                            +
                                          </Button>
                                        </Form.Group>
                                      </Row>
                                    </>
                                  )
                                })()}
                    
                              </div>
                            ))}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                    Cancel
                  </Button>
                  <Button 
                    variant='primary text-white' 
                    form="certificateUpdate"  
                    className='mx-2' 
                    size={'sm'} 
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Send Application
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* DETAIL MODAL*/} 
        <Modal className='modal' centered size={'lg'} show={showDetail} onHide={handleCloseDetail}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>
              <Stack direction='horizontal'>
                <div>
                  {
                    itemRequest.employee.photo != "" ?
                        <Avatar
                            sx={{ width: 65, height: 65 }}
                            src={process.env.REACT_APP_IMAGE_URL+ itemRequest.employee.photo}
                        ></Avatar> :
                        <Avatar
                            sx={{ width: 65, height: 65}}
                            src={""}
                        ></Avatar>
                  }
                </div>
                <div className='mx-3'>
                  &nbsp;{itemRequest.employee?.first_name} {itemRequest.employee?.last_name}
                  <br />
                  &nbsp;<span className='text-muted fw-light'>{itemRequest.employee?.user_name?.role?.role_name}</span>
                </div>
              </Stack>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>

                  <tr className='lh-1'>
                    <td className='fw-bold' width={90}>
                      Type:
                    </td>
                    <td>{itemRequest.type}</td>
                  </tr>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={90}>
                      Applied On:
                    </td>
                    <td>{itemRequest.application_date}</td>
                  </tr>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={90}>
                      Applied By:
                    </td>
                    <td>{itemRequest.applied_by_first_name} {itemRequest.applied_by_last_name}</td>
                  </tr>

                  </tbody>
                </Table>
              </Col>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={90}>
                      Reason:
                    </td>
                    <td>{itemRequest.reason}</td>
                  </tr>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={90}>
                      Documents:
                    </td>
                    <td>

                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {
                        itemRequest.documents ?
                            itemRequest.documents.map((doc: string, index) => (
                                <tr key={index}>
                                  <a href={IMAGE_BASE_URL + doc} target="_blank"  rel="noreferrer">
                                    <td className="uploaded-documents display-filename" width={250}>{doc.split("/")[2]}</td>
                                  </a>
                                  <td>
                                    <Button
                                        onClick={() => handleDownloadFile(doc.split("/")[2])}
                                        variant=' btn-transparent'
                                        className=' p-1 pt-0 pb-0'
                                        size={'sm'}
                                    >
                                      <Download/>
                                    </Button>

                                  </td>
                                </tr>
                            )) :
                            <tr>
                              <td width={250}>No file/s uploaded.</td>
                              <td></td>
                            </tr>
                      }
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr/>
            <Row style={{marginTop: '-15px'}}>
              <Table borderless className='mt-0 mb-0'>
                <tbody>
                <tr className='lh-1'>
                  <th className='text-center'>Certificate of Attendance Request</th>
                </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col sm={12}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody>
                    <tr>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Time</th>
                    </tr>
                    {itemRequest?.requested_attendance.length > 0 ? (
                      itemRequest?.requested_attendance.map((item, index) =>

                          <tr className='lh-1' key={index}>
                            <td>{item.time_in_out == "clock_in"? "Clock In" :
                                  item.time_in_out == "clock_out"? "Clock Out" : ""}</td>
                            <td>{item.time_in_out_date}</td>
                            <td>{item.time_in_out_time}</td>
                          </tr>

                      )
                      ) : (
                      <></>
                    )}
                  </tbody>
                </Table>  
              </Col>
            </Row>
            <hr/>
            <Row style={{marginTop: '-15px'}}>
              <Table borderless className='mt-0 mb-0'>
                <tbody>
                <tr className='lh-1'>
                  <th className='text-center'>Approvals</th>
                </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col sm={12}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <th className='text-center' style={{width: '20%'}}>Levels</th>
                    <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                    <th className='text-center'>Role</th>
                    <th className='text-center' style={{width: '15%'}}>Status</th>
                    <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                  </tr>
                  {
                    itemRequest.request_status.length > 0 ? (
                        itemRequest.request_status.map((item: any, index: any) =>
                            (
                                <tr key={index} className='lh-1'>
                                  <td>
                                    {
                                      index != 0 && itemRequest.request_status[index - 1].level == item.level ?
                                          "" : <>
                                            <b>Level {item.level}</b> : {item.approvers_order == "0" ? "By Order" : "Anyone"}
                                          </>
                                    }
                                  </td>
                                  <td>
                                    <Stack direction='horizontal'>
                                      <div>
                                        {
                                          item.employee?.photo != "" ?
                                              <Avatar
                                                  sx={{ width: 20, height: 20 }}
                                                  src={process.env.REACT_APP_IMAGE_URL+ item.employee?.photo}
                                                  className={"profile-avatar"}
                                              ></Avatar> :
                                              <Avatar
                                                  sx={{ width: 20, height: 20}}
                                                  src={""}
                                                  className={"profile-avatar"}
                                              ></Avatar>
                                        }
                                      </div>
                                      <div>&nbsp;{item.employee?.first_name} {item.employee?.last_name}</div>
                                    </Stack>
                                  </td>
                                  <td>{item.employee?.user_name?.role?.role_name}</td>
                                  {(() => {
                                    // Approved:2
                                    if (item.status == '3') {

                                      return (
                                          <>
                                            <td className='fw-bold text-center'>
                                              <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                                Approved
                                              </Button>
                                            </td>
                                            <td className='text-center'>{moment(new Date(item.approve_date ?? "")).format(dateFormat)}</td>
                                          </>
                                      )

                                      // Reject:3
                                    } else if(item.status == '2') {
                                      return (
                                          <>
                                            <td className='fw-bold text-center'>
                                              <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                                Disapproved
                                              </Button>
                                            </td>
                                            <td className='text-center'>{moment(new Date(item.reject_date ?? "")).format(dateFormat)}</td>

                                          </>
                                      )
                                    } else if(item.status == '4') {
                                      return (
                                          <>
                                            <td className='text-center'>-</td>
                                            <td className='text-center'>-</td>
                                          </>

                                      )
                                    }

                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                              Pending
                                            </Button>
                                          </td>
                                          <td className='text-center'>-</td>
                                        </>
                                    )
                                  })()}


                                </tr>

                            )

                        )
                    ) : (
                        <></>
                    )
                  }
                  </tbody>
                </Table>
              </Col>

            </Row>
          </Modal.Body>
          <Modal.Footer>
            {
              showApproveCancelButton ?
                  <>
                    <Button
                        onClick={() => rejectCertificateRequest(itemRequest.id)}
                        variant='outline-primary'
                        className=' px-5 pe-5 mx-2'
                        size={'sm'}
                        disabled={handleDisableAcceptRejectButton(itemRequest)}
                    >
                      Rejected
                    </Button>
                    <Button
                        onClick={() => acceptCertificateRequest(itemRequest.id)}
                        variant='primary'
                        className='text-white px-5 pe-5'
                        size={'sm'}
                        disabled={handleDisableAcceptRejectButton(itemRequest)}
                    >
                      Approved
                    </Button>
                  </>

              : ""
            }

          </Modal.Footer>
        </Modal>
        <br />
      </Container>
    </>
  )
}
