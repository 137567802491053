import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Stack,
  Table,
} from 'react-bootstrap'
import './style.css'
import Delete from 'asset/SvgComponent/Delete'
import Write from 'asset/SvgComponent/Write'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Calendar from 'asset/SvgComponent/Calendar'
import Clock from 'asset/SvgComponent/Clock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Avatar } from '@mui/material'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import { Formik } from 'formik';
import { validationSchema } from './validationSchema'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { ILeaveApplication, ILeaveType, ILeaveBalance, IList, IItem } from './types'
import moment from "moment"
import Select from "components/ReactSelect";
import {IEmployee} from "../../../Attendance/AttendanceReport/Construction/types";
import {ILeaveCredit} from "../../../EmployeeManagement/EmployeeAdd/Custom/Company/types";

export default function Index() {
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [showCreate, setShowCreate] = useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)

  const [showEdit, setShowEdit] = useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      leave_application_id: id,
      employee_id: 0,
      application_from_date: '',
      application_to_date: '',
      application_date: '',
      number_of_day: '',
      approve_date: '',
      reject_date: '',
      approve_by: '',
      reject_by: '',
      purpose: '',
      remarks: '',
      status: '',
      isHalfOrWholeDay: '',
      hour_from: '',
      hour_to: '',
      leave_type_id: '',
      leave_type: {
        leave_type_id: 0,
        leave_type_name: '',
        leave_description: ''
      },
      employee: {
        first_name: "",
        last_name: ""
      },
      credits: {
        id: 0,
        employee_id: 0,
        leave_type: 0,
        credits: ""
      }
    })
  }

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showHourly, setShowHourly] = useState(true)

  const [lists, setData] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    leave_application_id: 0,
    employee_id: 0,
    application_from_date: '',
    application_to_date: '',
    application_date: '',
    number_of_day: '',
    approve_date: '',
    reject_date: '',
    approve_by: '',
    reject_by: '',
    purpose: '',
    remarks: '',
    status: '',
    isHalfOrWholeDay: '',
    leave_type_id: '',
    hour_from: '',
    hour_to: '',
    leave_type: {
      leave_type_id: 0,
      leave_type_name: '',
      leave_description: ''
    },
    employee: {
      first_name: "",
      last_name: ""
    },
    credits: {
      id: 0,
      employee_id: 0,
      leave_type: 0,
      credits: ""
    }
  })
  const [leaveTypes, setLeaveTypes] = useState<ILeaveType[]>([
    { 
      leave_type_id: 0,
      leave_type_name: '',
      leave_description: ''
    }
  ])
  const [leaveBalance, setLeaveBalance] = useState<ILeaveBalance>(
    { 
      balance: 0,
      num_of_day: 0,
    }
  )
  const [leaveCredit, setLeaveCredit] = useState<ILeaveCredit>(
      {
        id: 0,
        employee_id: 0,
        leave_type: 0,
        credits: ""
      }
  )
  const [leaveCreditOnEdit, setLeaveCreditOnEdit] = useState<ILeaveCredit>(
      {
        id: 0,
        employee_id: 0,
        leave_type: 0,
        credits: ""
      }
  )
  const [dateFormat, setDateFormat] = useState('')
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
  const [selectedEmployeeOptionEdit, setSelectedEmployeeOptionEdit] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})

  const handleChangeDropdownEmployee = (selectedOption: any) => {
    setSelectedEmployeeOption(selectedOption)
  }

  const handleChangeDropdownEmployeeEdit = (selectedOption: any) => {
    setSelectedEmployeeOptionEdit(selectedOption)
  }

  const getAllEmployee = async () => {
    try {
      const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
      const result = await res
      if (result?.data?.results?.length) {
        const employees = result?.data?.results;
        if(JSON.parse(localStorage.getItem('role') ?? "") != "Admin"){
          employees.map(employee => {
            if(employee.employee_id == Number(localStorage.getItem('employee_id'))){
              setSelectedEmployeeOption({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
              // setDisableSelect(true)
            }
          })
        }
        setEmployeeOptions([])
        setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
        employees.map(employee => {
          setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
        })
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getNumberOfDays = (startDate: any, endDate: any) => {
     //define two date object variables with dates inside it  
    const date1 = new Date(startDate);  
    const date2 = new Date(endDate);  

    //calculate time difference  
    const time_difference = date2.getTime() - date1.getTime();  

    //calculate days difference by dividing total milliseconds in a day  
    return (time_difference / (1000 * 60 * 60 * 24)) + 1;  
  };

  const showLeaveBalance = async (id: null | number) => {
    const res = await axios.get<IAPI_Response<ILeaveCredit>>(API_ENDPOINT.SHOW_LEAVE_CREDITS +'/'+ id+'/'+selectedEmployeeOption.value)
    const result = await res
    if (result?.data?.results) {
      setLeaveCredit(result?.data?.results)
    }
  }

  const showLeaveBalanceOnEdit = async (id: null | number) => {
    const res = await axios.get<IAPI_Response<ILeaveCredit>>(API_ENDPOINT.SHOW_LEAVE_CREDITS +'/'+ id+'/'+selectedEmployeeOptionEdit.value)
    const result = await res
    if (result?.data?.results) {
      setLeaveCreditOnEdit(result?.data?.results)
    }
  }


  const getLeaveApplication = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<ILeaveApplication>>(API_ENDPOINT.GET_LEAVE_APPLICATION, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getLeaveType = async () => {
    const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_LEAVE_CONFIGURATION)
    const result = await res
    if (result?.data?.results?.length) {
      setLeaveTypes(result?.data?.results)
    }
  }

  const showLeaveApplication = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_LEAVE_APPLICATION +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
        setSelectedEmployeeOptionEdit({label: result?.data?.results?.employee.first_name + " " + result?.data?.results?.employee.last_name, value: result?.data?.results?.employee_id})
        setLeaveCreditOnEdit(result?.data?.results?.credits)
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postLeaveApplication = async (value: any, {resetForm} : any) => {
    await axios.post(API_ENDPOINT.POST_LEAVE_APPLICATION, {
      leave_type_id: value.leave_type_id,
      application_from_date: value.application_from_date,
      application_to_date: value.application_to_date,
      number_of_day: value.number_of_day,
      purpose: value.purpose,
      isHalfOrWholeDay: value.isHalfOrWholeDay,
      employee_id: value.employee_id
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.LEAVE_APPLICATION.CREATED)
          getLeaveApplication(null);
       }
       resetForm({})
       handleCloseCreate();
    });

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const updateLeaveApplication = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_LEAVE_APPLICATION +'/'+ value.leave_application_id, {
      employee_id: value.employee_id,
      leave_type_id: value.leave_type_id,
      application_from_date: value.application_from_date,
      application_to_date: value.application_to_date,
      number_of_day: value.number_of_day,
      purpose: value.purpose,
      isHalfOrWholeDay: value.isHalfOrWholeDay
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.LEAVE_APPLICATION.UPDATED)
          getLeaveApplication(null);
       }
       resetForm({})
       handleCloseEdit();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const deleteLeaveApplication = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_LEAVE_APPLICATION +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.LEAVE_APPLICATION.DELETED)
        getLeaveApplication(null);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getLeaveApplication(null)
      getLeaveType()
      setDateFormat(localStorage.getItem('date_format') ?? "")
      getAllEmployee()
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Leave Application</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/leaves/holiday-management'>
            Leaves
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Leave Application</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Card className='border-0 p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <span className='fw-bold'>Leave Summary</span>
              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                <InputGroup size='sm' className={'w-25'}>
                  <Form.Control
                    aria-label='search'
                    aria-describedby='inputGroup-sizing-sm'
                    placeholder='Search...'
                  />
                  <InputGroup.Text className='bg-transparent search-input '>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
                <Button
                  onClick={handleShowCreate}
                  className=' add-new-btn rounded mx-2 text-white'
                  variant='warning'
                >
                  <EditIcon/>
                  &nbsp;Apply for Leave
                </Button>{' '}
              </Col>
            </Row>
            <Table responsive size='sm' className='bordered-tabs-top mt-3'>
              <thead>
                <tr className='text-center'>
                  <th>No.</th>
                  <th>Leave Type</th>
                  <th>Date Applied</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Days</th>
                  <th>Reason</th>
                  <th>Remarks</th>
                  <th style={{ width: '50px' }}>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {lists.length > 0 ? (
                    lists.map((item, index) => 
                      <tr className='mb-2' key={index}>
                        <td>{from + index}</td>
                        <td>{item.leave_type?.leave_description}</td>
                        <td className='text-center'>{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                        <td className='text-center'>{moment(new Date(item.application_from_date ?? "")).format(dateFormat)}</td>
                        <td className='text-center'>{moment(new Date(item.application_to_date ?? "")).format(dateFormat)}</td>
                        <td className='text-center'>{item.number_of_day} days</td>
                        <td className='text-center'>{item.purpose}</td>
                        <td className='text-truncate table-responsive'>{item.remarks}</td>
                        <td className='text-center'>
                          <div className='d-grid'>
                            <>
                              {(() => {
                                switch (item.status) {
                                  case '1':
                                    return (
                                      <Button
                                        className=' p-1 pt-0 pb-0'
                                        style={{ fontSize: '12px', color: 'white' }}
                                        variant=' btn-secondary'
                                        size='sm'
                                        >
                                        Pending
                                      </Button>
                                    )
                                  case '3':
                                    return (
                                      <Button
                                        className=' p-1 pt-0 pb-0'
                                        style={{ fontSize: '12px', color: 'white' }}
                                        variant=' btn-success'
                                        size='sm'
                                        >
                                        Approved
                                      </Button>
                                    )
                                  case '2':
                                    return (
                                      <Button
                                        className=' p-1 pt-0 pb-0'
                                        style={{ fontSize: '12px', color: 'white' }}
                                        variant=' btn-danger'
                                        size='sm'
                                        >
                                        Disapproved
                                      </Button>
                                    )
                                }
                              })()}
                            </>
                          </div>
                        </td>
                        <td className='text-center'>
                          <Button
                            onClick={() => showLeaveApplication(item.leave_application_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Write/>
                          </Button>
                          <Button
                            onClick={() => handleShowConfirm(item.leave_application_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Delete/>
                          </Button>
                        </td>
                      </tr>
                    )
                    ) : (
                    <tr className='no-item'>
                      <td colSpan={10}>{TEXT.NOT_FOUND}</td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>

            <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
              pageClicked={(page: number) => { 
                  getLeaveApplication(page);
                }
              }
              />

            <Dialog 
              show={showConfirm} 
              setShow={handleCloseConfirm}
              setEvent={() => { 
                deleteLeaveApplication(item.leave_application_id)
                }
              }
            />

            <Loader show={showLoading}/>
          </Card.Body>
        </Card>

        {/* ADD MODAL*/}
        <Formik
          initialValues={{
            employee_id: 0,
            leave_type_id: '',
            application_from_date: '',
            application_to_date: '',
            number_of_day: '',
            purpose: '',
            isHalfOrWholeDay: ''
          }}
          validationSchema={validationSchema}
          onSubmit={postLeaveApplication}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form id="leaveApplicationCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Leave Application Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold form-labels required'>Employees</Form.Label>
                            <Select
                                name="employee_id"
                                value={selectedEmployeeOption}
                                options={employeeOptions}
                                onChange={(e:any )=> {
                                  handleChangeDropdownEmployee(e)
                                  values.employee_id = e.value
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.employee_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Leave Type</Form.Label>
                            <Form.Select 
                              size={'sm'}
                              name="leave_type_id"
                              value={values.leave_type_id}
                              onChange={e => {
                                handleChange(e)
                                showLeaveBalance(parseInt(e.target.value))
                              }}
                              onBlur={handleBlur}
                              isInvalid={touched.leave_type_id && !!errors.leave_type_id}
                              >
                              <option value="">Select Leave Type</option>
                              {leaveTypes.map((leaveType, index) => (
                                <option value={leaveType.leave_type_id} key={index}>{leaveType.leave_description}</option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.leave_type_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>No. of Days </Form.Label>
                            <Form.Control 
                              size='sm' 
                              type='number' 
                              step={0.5} 
                              placeholder=''
                              name="number_of_day"
                              value={values.number_of_day}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.number_of_day && !!errors.number_of_day} 
                              disabled={true}
                              />
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.number_of_day}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Balance </Form.Label>
                            <Form.Control 
                              size='sm' 
                              type='number' 
                              step={0.5} 
                              placeholder='' 
                              value={leaveCredit.credits}
                              disabled={true}
                              />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Date From </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="application_from_date"
                                value={values.application_from_date}
                                onChange={e => {
                                  handleChange(e)
                                  if (e.target.value != '' && values.application_to_date != '') {
                                    const days = getNumberOfDays(
                                      e.target.value,
                                      values.application_to_date
                                    );
                                    values.number_of_day = days.toString();
                                  }
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.application_from_date && !!errors.application_from_date} 
                                />
                                <Form.Control.Feedback className="left" type="invalid">
                                  {errors.application_from_date}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Date To </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="application_to_date"
                                value={values.application_to_date}
                                onChange={e => {
                                  handleChange(e)
                                  if (e.target.value != '' && values.application_from_date != '') {
                                    const days = getNumberOfDays(
                                      values.application_from_date,
                                      e.target.value
                                    );
                                    values.number_of_day = days.toString();
                                  }
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.application_to_date && !!errors.application_to_date}  
                                />
                              <Form.Control.Feedback className="left" type="invalid">
                                {errors.application_to_date}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                      <Form.Label className='fw-bold'>Reason</Form.Label>
                      <Form.Control 
                        as='textarea' 
                        rows={3}
                        name="purpose"
                        value={values.purpose}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.purpose && !!errors.purpose} 
                        />
                        <Form.Control.Feedback className="left" type="invalid">
                          {errors.application_to_date}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {['radio'].map((type: any) => (
                      <div key={`inline-${type}`} className='mb-3'>
                        <Form.Check
                          inline
                          label='Whole Day'
                          name='isHalfOrWholeDay'
                          type={type}
                          value='whole_day'
                          id={`inline-${type}-1`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            if (values.application_from_date  != '' && values.application_to_date != '') {
                              const days = getNumberOfDays(
                                values.application_from_date,
                                values.application_to_date
                              );
                              values.number_of_day = days.toString();
                            }
                          }}
                          checked={values.isHalfOrWholeDay === 'whole_day'}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />
                        <Form.Check
                          inline
                          label='First Half'
                          name='isHalfOrWholeDay'
                          type={type}
                          value='first_half'
                          id={`inline-${type}-2`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            values.number_of_day = '0.5';
                          }}
                          checked={values.isHalfOrWholeDay === 'first_half'}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />
                        <Form.Check
                          inline
                          name='isHalfOrWholeDay'
                          label='Second Half'
                          type={type}
                          value='second_half'
                          id={`inline-${type}-3`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            values.number_of_day = '0.5';
                          }}
                          checked={values.isHalfOrWholeDay === 'second_half'}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />

                        <span style={{ color: '#dc3545', fontSize: '0.875em' }}>
                          {touched.isHalfOrWholeDay && errors.isHalfOrWholeDay}
                        </span>
                      </div>
                    ))}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button
                    type="submit" 
                    disabled={isSubmitting} 
                    form="leaveApplicationCreate" 
                    variant='primary text-white'
                    size={'sm'}
                    className='mx-2'
                    >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
          initialValues={item}
          validationSchema={validationSchema}
          onSubmit={updateLeaveApplication}
          enableReinitialize
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form id="leaveApplicationEdit" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Edit Leave Application</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold form-labels required'>Employees</Form.Label>
                            <Select
                                name="employee_id"
                                value={selectedEmployeeOptionEdit}
                                options={employeeOptions}
                                onChange={(e:any )=> {
                                  handleChangeDropdownEmployeeEdit(e)
                                  values.employee_id = e.value
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.employee_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Leave Type</Form.Label>
                            <Form.Select 
                              size={'sm'}
                              name="leave_type_id"
                              value={values.leave_type_id}
                              onChange={e => {
                                handleChange(e)
                                showLeaveBalanceOnEdit(parseInt(e.target.value))
                              }}
                              onBlur={handleBlur}
                              isInvalid={touched.leave_type_id && !!errors.leave_type_id}
                              >
                              <option value="">Select Leave Type</option>
                              {leaveTypes.map((leaveType, index) => (
                                <option value={leaveType.leave_type_id} key={index}>{leaveType.leave_type_name}</option>
                              ))}
                            </Form.Select>
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.leave_type_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>No. of Days </Form.Label>
                            <Form.Control 
                              size='sm' 
                              type='number' 
                              step={0.5} 
                              placeholder=''
                              name="number_of_day"
                              value={values.number_of_day}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.number_of_day && !!errors.number_of_day} 
                              disabled={true}
                              />
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.number_of_day}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Balance </Form.Label>
                            <Form.Control 
                              size='sm' 
                              type='number' 
                              step={0.5} 
                              placeholder='' 
                              value={leaveCreditOnEdit.credits}
                              disabled={true}
                              />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Date From </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="application_from_date"
                                value={values.application_from_date}
                                onChange={e => {
                                  handleChange(e)
                                  if (e.target.value != '' && values.application_to_date != '') {
                                    const days = getNumberOfDays(
                                      e.target.value,
                                      values.application_to_date
                                    );
                                    values.number_of_day = days.toString();
                                  }
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.application_from_date && !!errors.application_from_date} 
                                />
                                <Form.Control.Feedback className="left" type="invalid">
                                  {errors.application_from_date}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Date To </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="application_to_date"
                                value={values.application_to_date}
                                onChange={e => {
                                  handleChange(e)
                                  if (e.target.value != '' && values.application_from_date != '') {
                                    const days = getNumberOfDays(
                                      values.application_from_date,
                                      e.target.value
                                    );
                                    values.number_of_day = days.toString();
                                  }
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.application_to_date && !!errors.application_to_date}  
                                />
                              <Form.Control.Feedback className="left" type="invalid">
                                {errors.application_to_date}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                      <Form.Label className='fw-bold'>Reason</Form.Label>
                      <Form.Control 
                        as='textarea' 
                        rows={3}
                        name="purpose"
                        value={values.purpose}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.purpose && !!errors.purpose} 
                        />
                        <Form.Control.Feedback className="left" type="invalid">
                          {errors.application_to_date}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {['radio'].map((type: any) => (
                      <div key={`inline-${type}`} className='mb-3'>
                        <Form.Check
                          inline
                          label='Whole Day'
                          name='isHalfOrWholeDay'
                          type={type}
                          value='whole_day'
                          id={`inline-${type}-1`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            if (values.application_from_date  != '' && values.application_to_date != '') {
                              const days = getNumberOfDays(
                                values.application_from_date,
                                values.application_to_date
                              );
                              values.number_of_day = days.toString();
                            }
                          }}
                          checked={values.isHalfOrWholeDay == 'whole_day' ? true : false}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />
                        <Form.Check
                          inline
                          label='First Half'
                          name='isHalfOrWholeDay'
                          type={type}
                          value='first_half'
                          id={`inline-${type}-2`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            values.number_of_day = '0.5';
                          }}
                          checked={values.isHalfOrWholeDay == 'first_half' ? true : false}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />
                        <Form.Check
                          inline
                          name='isHalfOrWholeDay'
                          label='Second Half'
                          type={type}
                          value='second_half'
                          id={`inline-${type}-3`}
                          onChange={e => {
                            handleChange(e)
                            setShowHourly(true)
                            values.number_of_day = '0.5';
                          }}
                          checked={values.isHalfOrWholeDay == 'second_half' ? true : false}
                          isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                        />

                        <span style={{ color: '#dc3545', fontSize: '0.875em' }}>
                          {touched.isHalfOrWholeDay && errors.isHalfOrWholeDay}
                        </span>
                      </div>
                    ))}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                    Cancel
                  </Button>
                  <Button
                    type="submit" 
                    disabled={isSubmitting} 
                    form="leaveApplicationEdit" 
                    variant='primary text-white'
                    size={'sm'}
                    className='mx-2'
                    >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>
        <br />
      </Container>
    </>
  )
}
