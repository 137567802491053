import React, { useEffect } from 'react'
import {
  Button,
  Card,
  Col,
  Row,
  Table
} from "react-bootstrap";
import Pagination from 'components/Pagination'
import Alert from 'components/Alert'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { IEmployee, IList } from './types'

export default function Index() {

  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(6)
  const [itemsCount, setItemCount] = React.useState(0)

  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)

  const [lists, setData] = React.useState<IList[]>([])

  const getAttendaceTeam = async (page_number: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IEmployee>>(API_ENDPOINT.GET_ATTENDANCE_TEAM_FOR_JVD, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getAttendaceTeam(null)
    })()
  }, [])

  return (
    <>
      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />
      <Card className="border-0 mb-3 p-0 bordered-card " style={{overflowY: 'hidden', height: '311px'}}>
        <Card.Body className="pb-0">
            <Row>
              <Col sm={6}>
                <span className="fw-bold">Team Attendance</span>
              </Col>
            </Row>
            {/*<hr/>*/}
            <Table responsive size="sm" className="bordered-tabs-top mt-2" style={{whiteSpace: 'nowrap'}}>
              <thead className="thead-table">
                <tr className="text-center">
                  <th style={{width: '40%'}}>Employee</th>
                  <th style={{width: '25%'}}>Time</th>
                  <th style={{width: '25%'}}>Type</th>
                  <th style={{width: '10%'}}>Status</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {lists.length > 0 ? (
                    lists.map((item, index) => 
                      <tr className="mb-2" key={index}>
                        <td className="fw-bold">{item.first_name} {item.last_name}</td>
                        <td className="text-center">{
                                item.out_time != null ? item.out_time :
                                    item.in_time != null ? item.in_time : '-'
                        }</td>
                        <td className="text-center">{
                                  item.out_time != null ? <span className="text-danger">Clock Out</span> :
                                      item.in_time != null ? <span className="text-success">Clock In</span> : '-'
                        }</td>
                        <td className="text-center">
                          <div className="d-grid">
                            {(() => {
                              if (item.leaves && item.leaves.length > 0) {
                                return (
                                  <Button 
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="info"
                                    size="sm"
                                    >
                                    Leave
                                  </Button>
                                )
                              } else if(item.attendancesv2 && item.attendancesv2.length > 0) {
                                return (
                                  <Button 
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="success"
                                    size="sm"
                                    >
                                    Present
                                  </Button>
                                )
                              } 

                              return (
                                <Button 
                                  className=" p-1 pt-0 pb-0"
                                  style={{fontSize: '12px', color: 'white'}}
                                  variant='danger'
                                  size="sm"
                                  >
                                  Absent
                                </Button>
                              )
                            })()}
                          </div>
                        </td>
                      </tr>
                    )
                    ) : (
                    <tr className='no-item'>
                      <td colSpan={4}>{TEXT.NOT_FOUND}</td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>

            <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
              pageClicked={(page: number) => { 
                  getAttendaceTeam(page);
                }
              }
              />
        </Card.Body>
      </Card>
    </>
  );
}
