import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Formik } from 'formik';
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Calendar from 'asset/SvgComponent/Calendar'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { validationSchema } from './validationSchema'
import { IHoliday, IHolidayType, IList, IItem } from './types'
import moment from "moment"
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

export default function Index() {
  const [key, setKey] = useState('fix_date_holidays')
  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [currentPage2, setCurrentPage2] = React.useState(0)
  const [lastPage2, setLastPage2] = React.useState(0)
  const [itemsPerPage2, setPerPage2] = React.useState(10)
  const [itemsCount2, setItemCount2] = React.useState(0)
  const [from2, setFrom2] = React.useState(0)

  const [validation, setValidation] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)

  const [showCreate, setShowCreate] = React.useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)

  const [showCreateFlexible, setShowCreateFlexible] = React.useState(false)
  const handleCloseCreateFlexible = () => setShowCreateFlexible(false)
  const handleShowCreateFlexible = () => setShowCreateFlexible(true)

  const [showEdit, setShowEdit] = React.useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = React.useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      holiday_details_id: 0,
      holiday_id: id,
      holiday_type: 0,
      from_date: '',
      to_date: '',
      holiday_name: '',
      isApply: 0,
      holiday: {
        holiday_id: 0,
        holiday_name: '',
        created_at: '',
        updated_at: ''
      }
    })
  }

  const [lists, setData] = useState<IList[]>([])
  const [lists2, setData2] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    holiday_details_id: 0,
    holiday_id: 0,
    holiday_type: 0,
    from_date: '',
    to_date: '',
    holiday_name: '',
    isApply: 0,
    holiday: {
      holiday_id: 0,
      holiday_name: '',
      created_at: '',
      updated_at: ''
    }
  })
  const [dateFormat, setDateFormat] = useState('')
  const [years, setYears] = useState<number[]>([])
  const [yearValue, setYearValue] = useState(moment().year())
  const [searchedFixed, setSearchedFixed] = useState("")
  const [searchedFlexible, setSearchedFlexible] = useState("")

  const handleChangeSearchedFixed = (e: any) => {
    setSearchedFixed(e.target.value)
    getHoliday(currentPage2, 1, yearValue, e.target.value)
  }

  const handleChangeSearchedFlexible = (e: any) => {
    setSearchedFlexible(e.target.value)
    getHoliday(currentPage2, 0, yearValue, e.target.value)
  }

  const handleGetYears = () => {
    const currYear = moment().year() + 1
    const yearsList = [];
    yearsList.push(currYear)
    yearsList.push(currYear - 1)
    yearsList.push((currYear - 2))
    yearsList.push((currYear - 3))
    yearsList.push((currYear - 4))
    yearsList.push((currYear - 5))
    yearsList.push((currYear - 6))

    setYears(yearsList)
  }

  const handleChangeYear = (e: any) => {
    setYearValue(e.target.value)
    getHoliday(currentPage2, 0, e.target.value, searchedFlexible)
  }

  const getHoliday = async (page_number: null | number, is_fixed: number, year_value: number, searchedKeyword: string) => {
    setShowLoading(true)
    try {
      const res = await axios.get<IAPI_Response<IHoliday>>(API_ENDPOINT.GET_HOLIDAY_FOR_STANDARD+"/"+is_fixed, {
        params: {
          page: page_number,
          year: year_value,
          searched: searchedKeyword
        },
      })
      const result = await res
      if(is_fixed == 1){
        if (result?.data?.results?.data?.length) {
          setData(result?.data?.results?.data)
          setCurrentPage(result?.data?.results?.current_page)
          setLastPage(result?.data?.results?.last_page)
          setPerPage(result?.data?.results?.per_page)
          setItemCount(result?.data?.results?.total)
          setFrom(result?.data?.results?.from)
        }else{
          setData([])
        }
      }else{
        if (result?.data?.results?.data?.length) {
          setData2(result?.data?.results?.data)
          setCurrentPage2(result?.data?.results?.current_page)
          setLastPage2(result?.data?.results?.last_page)
          setPerPage2(result?.data?.results?.per_page)
          setItemCount2(result?.data?.results?.total)
          setFrom2(result?.data?.results?.from)
        }else{
          setData2([])
        }
      }

      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const [holidayTypes, setHolidayTypes] = useState<IHolidayType[]>([
    { 
      id: 0,
      holiday_type_name: '',
      rate_of_basic: '',
      created_at: '',
      updated_at: ''
    }
  ])

  const getAllHolidayType = async () => {
    const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_HOLIDAY_TYPE)
    const result = await res
    if (result?.data?.results?.length) {
      setHolidayTypes(result?.data?.results)
    }
  }

  const showHoliday = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_HOLIDAY +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem({
          holiday_details_id: result?.data?.results?.holiday_details_id,
          holiday_id: result?.data?.results?.holiday_id,
          holiday_type: result?.data?.results?.holiday_type,
          from_date: result?.data?.results?.from_date,
          to_date: result?.data?.results?.to_date,
          holiday_name: result?.data?.results?.holiday?.holiday_name,
          isApply: result?.data?.results?.isApply,
          holiday: {
            holiday_id: 0,
            holiday_name: '',
            created_at: '',
            updated_at: ''
          }
        })
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postHoliday = async (value: any, {resetForm} : any) => {
    await axios.post(API_ENDPOINT.POST_HOLIDAY_FOR_STANDARD, {
      holiday_name: value.holiday_name,
      holiday_type: value.holiday_type,
      from_date: value.from_date,
      is_fixed: value.is_fixed
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.HOLIDAY.CREATED)
          getHoliday(null, 0, yearValue, searchedFlexible);
          getHoliday(null, 1, yearValue, searchedFixed);
       }
       resetForm({})
       handleCloseCreate();
       handleCloseCreateFlexible();
    }).catch(error => {
       setValidation(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const updateHoliday = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_HOLIDAY +'/'+ value.holiday_id, {
      holiday_name: value.holiday_name,
      holiday_type: value.holiday_type,
      from_date: value.from_date,
      isApply: value.isApply
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.HOLIDAY.UPDATED)
          getHoliday(currentPage, 1, yearValue, searchedFixed);
          getHoliday(currentPage2, 0, yearValue, searchedFlexible);
       }
       resetForm({})
       handleCloseEdit();
    }).catch(error => {
      setValidation(error.response.data.message)
    })
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const deleteHoliday = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_HOLIDAY +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.HOLIDAY.DELETED)
        getHoliday(currentPage, 1, yearValue, searchedFixed);
        getHoliday(currentPage2, 0, yearValue, searchedFlexible);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getHoliday(null, 0, yearValue, searchedFlexible)
      getHoliday(null, 1, yearValue, searchedFixed);
      getAllHolidayType()
      setDateFormat(localStorage.getItem('date_format') ?? "")
      handleGetYears()
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Holiday Management</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/leaves/holiday-management'>
            Leaves
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Holiday Management</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Tabs
            variant={'tabs'}
            className='tabs'
            id='controlled-tab-example'
            activeKey={key}
            onSelect={(k: any) => {
              setKey(k)
            }}
            // sx={{
            //   opacity: open ? 1 : 0,
            //   fontWeight: openDropdown === 0 ? 700 : 100,
            // }}
        >
          <Tab  eventKey='fix_date_holidays' title='Fixed Holidays'>
            <Card className='border-0  p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>

                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25'}>
                      <Form.Control
                          aria-label='search'
                          aria-describedby='inputGroup-sizing-sm'
                          placeholder='Search...'
                          onChange={handleChangeSearchedFixed}
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                    <Button
                        onClick={handleShowCreate}
                        className=' add-new-btn rounded mx-2 text-white'
                        variant='warning'
                    >
                      <EditIcon/>
                      &nbsp;Add Fixed Holiday
                    </Button>
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table responsive className='bordered-tabs-top mt-3'>
                  <thead>
                  <tr>
                    <th className="text-center" style={{ width: '5%' }}>No.</th>
                    <th className="text-center" style={{ width: '10%' }}>Holiday Date</th>
                    <th className="text-center" style={{ width: '20%' }}>Holiday Name</th>
                    <th className="text-center" style={{ width: '15%' }}>Holiday Type</th>
                    <th className="text-center" style={{ width: '15%' }}>Apply</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <>
                    {lists.length > 0 ? (
                        lists.map((item, index) =>
                            <tr className='mb-2' key={index}>
                              <td>{from + index}</td>
                              <td className="text-center">{moment(new Date(item.from_date ?? "")).format("MMM-DD")}</td>
                              <td>{item.holiday?.holiday_name}</td>
                              <td className="text-center">{item.type?.holiday_type_name}</td>
                              <td className="text-center">{item.isApply == 0 ? "Not Applied": "Applied"}</td>
                              <td className='text-center'>
                                <Button
                                    onClick={() => showHoliday(item.holiday_id)}
                                    className=' p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                >
                                  <Write/>
                                </Button>
                                <Button
                                    onClick={() => handleShowConfirm(item.holiday_id)}
                                    className=' p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                >
                                  <Delete/>
                                </Button>
                              </td>
                              <br />
                            </tr>
                        )
                    ) : (
                        <tr className='no-item'>
                          <td colSpan={6}>{TEXT.NOT_FOUND}</td>
                        </tr>
                    )}
                  </>
                  </tbody>
                </Table>
                <Pagination
                    itemsCount={itemsCount}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getHoliday(page, 1, yearValue, searchedFixed);
                    }
                    }
                />

              </Card.Body>
            </Card>
          </Tab>

          <Tab  eventKey='flexible_holidays' title='Flexible Holidays'>
            <Card className='border-0  p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={2}>
                    <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                      <Form.Label className='fw-bold form-labels'>Year</Form.Label>
                      <Form.Select
                          size={'sm'}
                          name="year"
                          value={yearValue}
                          onChange={handleChangeYear}
                      >
                        <option value="">Select Year</option>
                        {
                          years.map((year, index) => (
                              <option key={index} value={year}>{year}</option>
                          ))

                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={4}></Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                      <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                      <br />
                      <InputGroup size='sm' className={'w-100'}>
                        <Form.Control
                            aria-label='search'
                            aria-describedby='inputGroup-sizing-sm'
                            placeholder='Search...'
                            onChange={handleChangeSearchedFlexible}
                        />
                        <InputGroup.Text className='bg-transparent search-input '>
                          <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                      <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                      <br />
                      <Button
                          onClick={handleShowCreateFlexible}
                          className=' add-new-btn rounded mx-2 text-white'
                          variant='warning'
                      >

                        <EditIcon/>
                        &nbsp;Add Flexible Holiday
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table responsive className='bordered-tabs-top mt-3'>
                  <thead>
                  <tr>
                    <th className="text-center" style={{ width: '5%' }}>No.</th>
                    <th className="text-center" style={{ width: '10%' }}>Holiday Date</th>
                    <th className="text-center" style={{ width: '20%' }}>Holiday Name</th>
                    <th className="text-center" style={{ width: '15%' }}>Holiday Type</th>
                    <th className="text-center" style={{ width: '15%' }}>Apply</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <>
                    {lists2.length > 0 ? (
                        lists2.map((item, index) =>
                            <tr className='mb-2' key={index}>
                              <td>{from2 + index}</td>
                              <td className="text-center">{moment(new Date(item.from_date ?? "")).format("MMM-DD-YYYY")}</td>
                              <td>{item.holiday?.holiday_name}</td>
                              <td className="text-center">{item.type?.holiday_type_name}</td>
                              <td className="text-center">{item.isApply == 0 ? "Not Applied": "Applied"}</td>
                              <td className='text-center'>
                                <Button
                                    onClick={() => showHoliday(item.holiday_id)}
                                    className=' p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                >
                                  <Write/>
                                </Button>
                                <Button
                                    onClick={() => handleShowConfirm(item.holiday_id)}
                                    className=' p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                >
                                  <Delete/>
                                </Button>
                              </td>
                              <br />
                            </tr>
                        )
                    ) : (
                        <tr className='no-item'>
                          <td colSpan={6}>{TEXT.NOT_FOUND}</td>
                        </tr>
                    )}
                  </>
                  </tbody>
                </Table>
                <Pagination
                    itemsCount={itemsCount2}
                    itemsPerPage={itemsPerPage2}
                    currentPage={currentPage2}
                    lastPage={lastPage2}
                    setCurrentPage={setCurrentPage2}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getHoliday(page, 0, yearValue, searchedFlexible);
                    }
                    }
                />

              </Card.Body>
            </Card>
          </Tab>
        </Tabs>

        <Dialog
            show={showConfirm}
            setShow={handleCloseConfirm}
            setEvent={() => {
              deleteHoliday(item.holiday_id)
            }
            }
        />

        <Loader show={showLoading}/>

        {/* ADD MODAL FOR FIXED HOLIDAY*/}
        <Formik
            initialValues={
              { 
                holiday_name: '',
                holiday_type: '',
                from_date: '',
                is_fixed: 1
              }
            }
            validationSchema={validationSchema}
            onSubmit={postHoliday}
            >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form id="holidayCreate" noValidate onSubmit={handleSubmit}>
                <Modal className='modal border-0' centered show={showCreate} onHide={handleCloseCreate}>
                  <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>Add Fixed Holiday</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                          <Form.Label className='text-muted fw-bold required'>Holiday Name</Form.Label>
                          <Form.Control 
                            size={'sm'}
                            type='text' 
                            placeholder='Holiday name'
                            name="holiday_name"
                            value={values.holiday_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.holiday_name && !!errors.holiday_name || !!validation}
                            />
                            {
                            validation != '' ?
                              (Object.keys(validation).map((message : any, index) => (
                                <Form.Control.Feedback type="invalid" key={index}>
                                  {validation[message][index]}
                                </Form.Control.Feedback>
                              ))
                              ) : (
                              <Form.Control.Feedback type="invalid">
                                {errors.holiday_name}
                              </Form.Control.Feedback>
                              )
                            }
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                          <Form.Label className='text-muted fw-bold required'>Holiday Type</Form.Label>
                          <Form.Select 
                            size={'sm'}
                            name="holiday_type"
                            value={values.holiday_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.holiday_type && !!errors.holiday_type}
                            >
                            <option value="">Select Holiday Type</option>
                            {holidayTypes.map((holidayType, index) => (
                              <option value={holidayType.id} key={index}>{holidayType.holiday_type_name}</option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback className="left" type="invalid">
                            {errors.holiday_type}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='text-muted fw-bold required'>Holiday Date</Form.Label>
                          <InputGroup size={'sm'}>
                            <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                              <Calendar/>
                            </InputGroup.Text>
                            <Form.Control 
                              size='sm' 
                              type='date' 
                              placeholder=''
                              name="from_date"
                              value={values.from_date}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.from_date && !!errors.from_date}
                              />
                              <Form.Control.Feedback className="left" type="invalid">
                                {errors.from_date}
                              </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isSubmitting} form="holidayCreate" variant='primary text-white' className='mx-2' size={'sm'}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
        </Formik>

        {/* ADD MODAL FOR FLEXIBLE HOLIDAY*/}
        <Formik
            initialValues={
              {
                holiday_name: '',
                holiday_type: '',
                from_date: '',
                is_fixed: 0
              }
            }
            validationSchema={validationSchema}
            onSubmit={postHoliday}
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form id="holidayFlexibleCreate" noValidate onSubmit={handleSubmit}>
                <Modal className='modal border-0' centered show={showCreateFlexible} onHide={handleCloseCreateFlexible}>
                  <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>Add Flexible Holiday</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                          <Form.Label className='text-muted fw-bold required'>Holiday Name</Form.Label>
                          <Form.Control
                              size={'sm'}
                              type='text'
                              placeholder='Holiday name'
                              name="holiday_name"
                              value={values.holiday_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.holiday_name && !!errors.holiday_name || !!validation}
                          />
                          {
                            validation != '' ?
                                (Object.keys(validation).map((message : any, index) => (
                                        <Form.Control.Feedback type="invalid" key={index}>
                                          {validation[message][index]}
                                        </Form.Control.Feedback>
                                    ))
                                ) : (
                                    <Form.Control.Feedback type="invalid">
                                      {errors.holiday_name}
                                    </Form.Control.Feedback>
                                )
                          }
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                          <Form.Label className='text-muted fw-bold required'>Holiday Type</Form.Label>
                          <Form.Select
                              size={'sm'}
                              name="holiday_type"
                              value={values.holiday_type}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.holiday_type && !!errors.holiday_type}
                          >
                            <option value="">Select Holiday Type</option>
                            {holidayTypes.map((holidayType, index) => (
                                <option value={holidayType.id} key={index}>{holidayType.holiday_type_name}</option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback className="left" type="invalid">
                            {errors.holiday_type}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='text-muted fw-bold required'>Holiday Date</Form.Label>
                          <InputGroup size={'sm'}>
                            <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                              <Calendar/>
                            </InputGroup.Text>
                            <Form.Control
                                size='sm'
                                type='date'
                                placeholder=''
                                name="from_date"
                                value={values.from_date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.from_date && !!errors.from_date}
                            />
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.from_date}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreateFlexible}>
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isSubmitting} form="holidayFlexibleCreate" variant='primary text-white' className='mx-2' size={'sm'}>
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
            initialValues={item}
            validationSchema={validationSchema}
            onSubmit={updateHoliday}
            enableReinitialize
            >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
            <Form id="holidayUpdated" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Edit Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='text-muted fw-bold required'>Holiday Name</Form.Label>
                        <Form.Control 
                          size={'sm'}
                          type='text' 
                          placeholder='Holiday name'
                          name="holiday_name"
                          value={values.holiday_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.holiday_name && !!errors.holiday_name || !!validation}
                          />
                          {
                          validation != '' ?
                            (Object.keys(validation).map((message : any, index) => (
                              <Form.Control.Feedback type="invalid" key={index}>
                                {validation[message][index]}
                              </Form.Control.Feedback>
                            ))
                            ) : (
                            <Form.Control.Feedback type="invalid">
                              {errors.holiday_name}
                            </Form.Control.Feedback>
                            )
                          }
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='text-muted fw-bold required'>Holiday Type</Form.Label>
                        <Form.Select 
                          size={'sm'}
                          name="holiday_type"
                          value={values.holiday_type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.holiday_type && !!errors.holiday_type}
                          >
                          <option value="">Select Holiday Type</option>
                          {holidayTypes.map((holidayType, index) => (
                            <option value={holidayType.id} key={index}>{holidayType.holiday_type_name}</option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback className="left" type="invalid">
                          {errors.holiday_type}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                        <Form.Label className='text-muted fw-bold required'>Holiday Date</Form.Label>
                        <InputGroup size={'sm'}>
                          <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                            <Calendar/>
                          </InputGroup.Text>
                          <Form.Control 
                            size='sm' 
                            type='date' 
                            placeholder=''
                            name="from_date"
                            value={values.from_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.from_date && !!errors.from_date}
                            />
                            <Form.Control.Feedback className="left" type="invalid">
                              {errors.from_date}
                            </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='text-muted fw-bold required'>Apply?</Form.Label>
                        <Form.Select
                            size={'sm'}
                            name="isApply"
                            value={values.isApply}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.isApply && !!errors.isApply}
                        >
                          <option value="">Select Apply</option>
                          <option value="1">Apply</option>
                          <option value="0">Do not Apply</option>
                        </Form.Select>
                        <Form.Control.Feedback className="left" type="invalid">
                          {errors.isApply}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isSubmitting} form="holidayUpdated" variant='primary text-white' className='mx-2' size={'sm'}>
                    Update
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
            )}
        </Formik>
      </Container>
    </>
  )
}
