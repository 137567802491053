import React, { useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Stack,
    Table,
    Carousel
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Avatar } from '@mui/material'
import Accept from 'asset/SvgComponent/Accept'
import Reject from 'asset/SvgComponent/Reject'
import View from 'asset/SvgComponent/View'
import Calendar from 'asset/SvgComponent/Calendar'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
    API_ENDPOINT,
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    TEXT
} from 'utils/globalConstant'
import {IList, IItem, ILeaveRequest, ILeaveType, ILeaveReportsData} from './types'
import { identical } from 'ramda'
import moment from "moment"
import AcceptDisabled from "asset/SvgComponent/AcceptDisabledIcon";
import RejectDisabled from "asset/SvgComponent/RejectDisabledIcon";
import {Formik} from "formik";
import EditIcon from "asset/SvgComponent/EditIcon";
import Delete from "asset/SvgComponent/Delete";
import Select from "components/ReactSelect";
import DownloadWhite from "asset/SvgComponent/DownloadWhite";
import FileSaver from "file-saver";
import {IEmployee} from "../../Attendance/AttendanceReport/Retail/types";
import MyPieChart from "components/PieChartCustom";
import BarChart from "components/BarChart";
import {Bar} from "react-chartjs-2";
import Trophy from "asset/SvgComponent/Trophy";

export default function Index() {

    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [itemsPerPage, setPerPage] = useState(10)
    const [itemsCount, setItemCount] = useState(0)
    const [from, setFrom] = useState(0)


    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "All Employees"})
    const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);

    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    const [requests, setRequests] = useState<IList[]>([])
    const [pendings, setPendings] = useState<IList[]>([])
    const [item, setItem] = useState<IItem>({
        leave_application_id: 0,
        application_from_date: '',
        application_to_date: '',
        application_date: '',
        number_of_day: '',
        purpose: '',
        approvers_order: 0,
        employee: {
            employee_id: 0,
            first_name: '',
            last_name: '',
            user_name: {
                role: {
                    role_name: ''
                }
            },
            photo: ""
        },
        leave_type: {
            leave_type_id: 0,
            leave_type_name: '',
            leave_description: ''
        },
        request_status: [{
            status: '',
            approve_date: '',
            reject_date: '',
            employee: {
                employee_id: 0,
                first_name: '',
                last_name: '',
                user_name: {
                    role: {
                        role_name: ''
                    }
                },
                photo: ""
            },
            level: "",
            approvers_order: ""
        }],
        created_by_first_name: "",
        created_by_last_name: ""
    })
    const [dateFormat, setDateFormat] = useState('')
    const [searched, setSearched] = useState("")
    const [searchedCompleted, setSearchedCompleted] = useState("")
    const [dateFilterPending, setDateFilterPending] = useState({dateFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split("T")[0], dateTo: new Date().toISOString().split("T")[0]})
    const [dateFilterCompleted, setDateFilterCompleted] = useState({dateFrom: '', dateTo: ''})
    const [status, setStatus] = useState(0);
    const [leaveType, setLeaveType] = useState(0);
    const [leaveTypes, setLeaveTypes] = useState<ILeaveType[]>([]);
    const [leaveReports, setLeaveReports] = useState<ILeaveReportsData>({
        leave_status_breakdown_label: [],
        leave_status_breakdown_values: [],
        leave_types_distribution_label: [],
        leave_types_distribution_values: [],
        leaves_by_employees_label: [],
        leaves_by_employees_values: []
    })


    const handleDisplayDays = (day: string|null) => {
        const dayFormat = parseFloat(day ?? "0")
        if(dayFormat > 1){
            return `${dayFormat} days`
        }else{
            return `${dayFormat} day`
        }
    }

    const getNumberOfDays = (startDate: any, endDate: any) => {
        //define two date object variables with dates inside it
        const date1 = new Date(startDate);
        const date2 = new Date(endDate);

        //calculate time difference
        const time_difference = date2.getTime() - date1.getTime();

        //calculate days difference by dividing total milliseconds in a day
        return (time_difference / (1000 * 60 * 60 * 24)) + 1;
    };

    const handleChangeDropdownEmployee = (selectedOption: any) => {
        setSelectedEmployeeOption(selectedOption)
    }

    const _chunk = (arr: any, n: any) => {
        const group = [];
        for (let i = 0, end = arr.length / n; i < end; ++i)
            group.push(arr.slice(i * n, (i + 1) * n));
        return group;
    }



    const handleChangeSearchedKeyword = (e:any) => {
        setSearched(e.target.value)

        getLeaveRequest(null, selectedEmployeeOption.value, e.target.value, status, dateFilterPending.dateFrom, dateFilterPending.dateTo, leaveType)
    }


    const handleDisableAcceptRejectButton = (item: any) => {
        if(item.status == '3' || item.status == '2'){
            return true
        }else if(item.current == '0' || item.current == '2'){
            return true
        }else{
            return false
        }

    }

    const filterLeaveRequestPending = async(values: any) => {
        setDateFilterPending({dateFrom: values.date_from, dateTo: values.date_to})
        getLeaveRequest(null, values.employee_id, searched, values.status, values.date_from, values.date_to, values.leave_type)
        getAllLeaveReportsData(selectedEmployeeOption.value, values.status, values.leave_type, values.date_from, values.date_to)
    }


    const handleExportPendingLeave = async () => {
        try {
            const response = await axios.get(API_ENDPOINT.EXPORT_LEAVE_APPROVAL, {
                params: {
                    employee_id: selectedEmployeeOption.value,
                    status: status,
                    date_from: dateFilterPending.dateFrom,
                    date_to: dateFilterPending.dateTo,
                    searched: searched,
                    leaveType: leaveType
                },
                headers: {
                    'Content-Type': 'blob'
                },
                responseType: 'arraybuffer'
            })
            const outputFilename = `Leave_Report_${moment().format('YYYY-MM-DD')}.xlsx`;

            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();

            FileSaver.writeFileSync(outputFilename, response.data);

        } catch {
            // setShowError(true)
            // setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

    }

    const getAllEmployee = async () => {
        try {
            const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
            const result = await res
            if (result?.data?.results?.length) {
                const employees = result?.data?.results;
                setEmployeeOptions([])
                if(JSON.parse(localStorage.getItem('role') ?? "") == "Employee"){
                    employees.map(employee => {
                        if(employee.employee_id == Number(localStorage.getItem('employee_id'))){
                            setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
                            setSelectedEmployeeOption({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})

                        }

                    })

                }else{
                    setEmployeeOptions(current => [...current, {value: 0, label: "All Employees"}]);
                    employees.map(employee => {
                        setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
                    })
                }

            }
        } catch {
            console.log('There is something wrong')
        }
    }

    const getAllLeaveTypes = async () => {
        try {
            const res = await axios.get<IAPI_Response<ILeaveType[]>>(API_ENDPOINT.ALL_LEAVE_CONFIGURATION)
            const result = await res

            if (result?.data?.results?.length) {
                setLeaveTypes(result.data.results)
            }else{
                setLeaveTypes([])
            }
        } catch {
            console.log('There is something wrong')
        }
    }

    const getLeaveRequest = async (page_number: null | number, employee_id: number, searchedKeyword: string, status: number, dateFrom: string, dateTo: string, leave_type: number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<ILeaveRequest>>(API_ENDPOINT.GET_ALL_LEAVE_APPLICATIONS, {
                params: {
                    limit: 10,
                    page: page_number,
                    employee_id: employee_id,
                    searched: searchedKeyword,
                    status: status,
                    leaveType: leave_type,
                    date_from: dateFrom,
                    date_to: dateTo
                },
            })
            const result = await response
            // if(status == 0){
            if (result?.data?.results?.data?.length) {
                setRequests(result?.data?.results?.data)
                setCurrentPage(result?.data?.results?.current_page)
                setLastPage(result?.data?.results?.last_page)
                setPerPage(result?.data?.results?.per_page)
                setItemCount(result?.data?.results?.total)
                setFrom(result?.data?.results?.from)
            }else{
                setRequests([])
            }
            // }else{
            //   if (result?.data?.results?.data?.length) {
            //     setRequestsCompleted(result?.data?.results?.data)
            //     setCurrentPage1(result?.data?.results?.current_page)
            //     setLastPage1(result?.data?.results?.last_page)
            //     setPerPage1(result?.data?.results?.per_page)
            //     setItemCount1(result?.data?.results?.total)
            //     setFrom1(result?.data?.results?.from)
            //   }else{
            //     setRequestsCompleted([])
            //   }
            // }

            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }


    const showLeaveRequest = async (id: null | number) => {
        try {
            const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_LEAVE_REQUEST_FOR_MFBC +'/'+ id)
            const result = await response
            if (result?.data?.results) {
                setItem(result?.data?.results)
            }
            handleShow()
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const getAllLeaveReportsData = async (employee_id: number, status: number, leave_type: number, date_from: string, date_to: string) => {
        try {
            const res = await axios.get<IAPI_Response<ILeaveReportsData>>(API_ENDPOINT.GET_LEAVE_REPORTS_FOR_CHARTS, {
                params: {
                    employee_id: employee_id,
                    status: status,
                    leaveType: leave_type,
                    date_from: date_from,
                    date_to: date_to
                },
            })
            const result = await res

            if(result?.data?.results){
                setLeaveReports(result?.data?.results)
            }

        } catch {
            console.log('There is something wrong')
        }
    }

    useEffect(() => {
        ;(async () => {
            getLeaveRequest(null, selectedEmployeeOption.value, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo, leaveType)
            setDateFormat(localStorage.getItem('date_format') ?? "")
            getAllEmployee()
            getAllLeaveTypes()
            getAllLeaveReportsData(selectedEmployeeOption.value, status, leaveType, dateFilterPending.dateFrom, dateFilterPending.dateTo)
        })()
    }, [])

    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Leave Report</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none' href='/home'>
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className=' text-decoration-none' href='/leaves/holiday-management'>
                        Leaves
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Leave Report</Breadcrumb.Item>
                </Breadcrumb>

                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <Card className='border-0  p-0 bordered-card-tabs'>
                    <Card.Body>
                        <Row>
                            <Col sm={12}>
                                <Formik
                                    initialValues={
                                        {
                                            employee_id: 0,
                                            status: '',
                                            leave_type: '',
                                            date_to: new Date().toISOString().split("T")[0],
                                            date_from: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split("T")[0]
                                        }
                                    }
                                    onSubmit={filterLeaveRequestPending}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting
                                      }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Row>
                                                <Col sm={2}>
                                                    <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                        <Form.Label className='fw-bold form-labels'>Employee Name</Form.Label>
                                                        <Select
                                                            name="employee_id"
                                                            value={selectedEmployeeOption}
                                                            options={employeeOptions}
                                                            onChange={(e: any) => {
                                                                setSelectedEmployeeOption(e)
                                                                values.employee_id = e.value
                                                                handleSubmit()
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.employee_id}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={2}>
                                                    <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                        <Form.Label className='fw-bold form-labels'>Status</Form.Label>
                                                        <Form.Select
                                                            size={'sm'}
                                                            name="status"
                                                            value={values.status}
                                                            onChange={(e: any) => {
                                                                handleChange(e);
                                                                handleSubmit();
                                                                setStatus(e.target.value)
                                                            }}
                                                            onBlur={handleBlur}
                                                            isInvalid={touched.status && !!errors.status}
                                                        >
                                                            <option value="0">All Status</option>
                                                            <option value="1">Pending</option>
                                                            <option value="3">Approved</option>
                                                            <option value="2">Disapproved</option>
                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.status}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={2}>
                                                    <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                        <Form.Label className='fw-bold form-labels'>Leave Type</Form.Label>
                                                        <Form.Select
                                                            size={'sm'}
                                                            name="leave_type"
                                                            value={values.leave_type}
                                                            onChange={(e: any) => {
                                                                handleChange(e);
                                                                handleSubmit();
                                                                setLeaveType(e.target.value)
                                                            }}
                                                            onBlur={handleBlur}
                                                            isInvalid={touched.leave_type && !!errors.leave_type}
                                                        >
                                                            <option value="0">All Leave Types</option>
                                                            {
                                                                leaveTypes.map((type, index) => (
                                                                    <option key={index} value={type.leave_type_id}>{type.leave_type_name}</option>
                                                                ))
                                                            }

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.leave_type}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={2}>
                                                    <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                        <Form.Label className='fw-bold form-labels'>Date From</Form.Label>
                                                        <InputGroup className='mb-2 ' size={'sm'}>
                                                            <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                                                <Calendar/>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                size={'sm'}
                                                                type='date'
                                                                placeholder='Friday, June 03, 2022'
                                                                name='date_from'
                                                                value={values.date_from}
                                                                onChange={(e: any) => {
                                                                    handleChange(e);
                                                                    values.date_from = e.target.value
                                                                    handleSubmit();
                                                                }}
                                                                onBlur={handleBlur}
                                                                isInvalid={touched.date_from && !!errors.date_from}
                                                                max={moment().format("YYYY-MM-DD")}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.date_from}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={2}>
                                                    <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                        <Form.Label className='fw-bold form-labels'>Date To</Form.Label>
                                                        <InputGroup className='mb-2 ' size={'sm'}>
                                                            <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                                                <Calendar/>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                size={'sm'}
                                                                type='date'
                                                                placeholder='Friday, June 03, 2022'
                                                                name='date_to'
                                                                value={values.date_to}
                                                                onChange={(e: any) => {
                                                                    handleChange(e);
                                                                    values.date_to = e.target.value
                                                                    handleSubmit();
                                                                }}
                                                                onBlur={handleBlur}
                                                                isInvalid={touched.date_to && !!errors.date_to}
                                                                max={moment().format("YYYY-MM-DD")}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.date_to}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>



                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </Col>
                        </Row>
                        <Row className={'mt-2 mb-2'}>
                            <Col sm={6}>
                                <p style={{marginBottom: '0'}} className={'text-center fw-bold'}>Leave Status Breakdown (days)</p>
                                <MyPieChart labels={leaveReports.leave_status_breakdown_label} values={leaveReports.leave_status_breakdown_values}/>
                            </Col>
                            <Col sm={6}>
                                <p style={{marginBottom: '0'}} className={'text-center fw-bold'}>Leave Types Distribution (days)</p>
                                <MyPieChart labels={leaveReports.leave_types_distribution_label} values={leaveReports.leave_types_distribution_values}/>
                            </Col>
                        </Row>
                        <Row className={'mt-2 mb-2'}>
                            <Col sm={12} className={'text-center'}>
                                <p style={{marginBottom: '0'}} className={'text-center fw-bold'}>Leaves By Employees</p>
                                <BarChart labelName={"Days"} labels={leaveReports.leaves_by_employees_label}  values={leaveReports.leaves_by_employees_values}/>
                            </Col>
                            {/*<Col sm={6}>*/}
                            {/*    <p style={{marginBottom: '0'}} className={'text-center fw-bold'}>Top Employees Leave</p>*/}
                            {/*    <div className="container">*/}
                            {/*        <div className="row justify-content-center">*/}
                            {/*            <div className="col-12 col-md-8 text-center">*/}
                            {/*                <Table responsive size='sm' className='bordered-tabs-top mt-3'>*/}
                            {/*                    <thead>*/}
                            {/*                    <tr className='text-center'>*/}
                            {/*                        <th style={{width: '15%'}}>Rank.</th>*/}
                            {/*                        <th>Employee Name</th>*/}
                            {/*                        <th style={{width: '15%'}}>Days</th>*/}
                            {/*                    </tr>*/}
                            {/*                    </thead>*/}
                            {/*                    <tbody>*/}
                            {/*                    <tr>*/}
                            {/*                        <td className={'text-center'}>1</td>*/}
                            {/*                        <td><Trophy color={"#FFD700"}/> John Doe</td>*/}
                            {/*                        <td className={'text-center'}>10</td>*/}
                            {/*                    </tr>*/}
                            {/*                    <tr>*/}
                            {/*                        <td className={'text-center'}>2</td>*/}
                            {/*                        <td><Trophy color={"#C0C0C0"}/> John Smith</td>*/}
                            {/*                        <td className={'text-center'}>8</td>*/}
                            {/*                    </tr>*/}
                            {/*                    <tr>*/}
                            {/*                        <td className={'text-center'}>3</td>*/}
                            {/*                        <td><Trophy color={"#CD7F32"}/> James Paterson</td>*/}
                            {/*                        <td className={'text-center'}>7</td>*/}
                            {/*                    </tr>*/}
                            {/*                    <tr>*/}
                            {/*                        <td className={'text-center'}>4</td>*/}
                            {/*                        <td>George Smith</td>*/}
                            {/*                        <td className={'text-center'}>4</td>*/}
                            {/*                    </tr>*/}
                            {/*                    <tr>*/}
                            {/*                        <td className={'text-center'}>5</td>*/}
                            {/*                        <td>Dwayne Johnson</td>*/}
                            {/*                        <td className={'text-center'}>4</td>*/}
                            {/*                    </tr>*/}
                            {/*                    </tbody>*/}
                            {/*                </Table>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</Col>*/}
                        </Row>
                        <Row>
                            <Col sm={8}></Col>
                            <Col sm={4} className='d-flex justify-content-end hidden'>
                                <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                                    <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                    <br />
                                    <InputGroup size='sm' className={'w-100'}>
                                        <Form.Control
                                            aria-label='search'
                                            aria-describedby='inputGroup-sizing-sm'
                                            placeholder='Search...'
                                            onChange={handleChangeSearchedKeyword}
                                        />
                                        <InputGroup.Text className='bg-transparent search-input '>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                                    <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                    <br />
                                    <Button
                                        onClick={handleExportPendingLeave}
                                        className=' add-new-btn rounded mx-3 text-white'
                                        variant='warning'
                                    >
                                        <DownloadWhite/>
                                        &nbsp; Export
                                    </Button>
                                </Form.Group>

                            </Col>
                        </Row>
            {/*<span className='fw-bold'>Leaves Summary</span>*/}
                        <Table responsive size='sm' className='bordered-tabs-top mt-3'>
                            <thead>
                            <tr className='text-center'>
                                <th>No.</th>
                                <th style={{width: '20%'}}>Employee Name</th>
                                <th>Leave Type</th>
                                <th>Date Applied</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Days</th>
                                <th style={{width: '20%'}}>Reason</th>
                                {/* <th>Remarks</th> */}
                                <th style={{ width: '50px' }}>Status</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <>
                                {requests.length > 0 ? (
                                    requests.map((item, index) =>
                                        <tr className='mb-2' key={index}>
                                            <td>{from + index}</td>
                                            <td>
                                                <Stack direction='horizontal'>
                                                    <div>
                                                        {
                                                            item.photo != "" ?
                                                                <Avatar
                                                                    sx={{ width: 20, height: 20 }}
                                                                    src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                                                    className={"profile-avatar"}
                                                                ></Avatar> :
                                                                <Avatar
                                                                    sx={{ width: 20, height: 20}}
                                                                    src={""}
                                                                    className={"profile-avatar"}
                                                                ></Avatar>
                                                        }
                                                    </div>
                                                    <div>&nbsp;{item.first_name} {item.last_name}</div>
                                                </Stack>
                                            </td>
                                            <td>{item.leave_type.leave_type_name}</td>
                                            <td className='text-center'>{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                                            <td className='text-center'>{moment(new Date(item.application_from_date ?? "")).format(dateFormat)}</td>
                                            <td className='text-center'>{moment(new Date(item.application_to_date ?? "")).format(dateFormat)}</td>
                                            <td className='text-center'>{handleDisplayDays(item.number_of_day)}</td>
                                            <td className='text-center display-reason' title={item.purpose ?? ""}>{item.purpose}</td>
                                            {/* <td className='text-truncate table-responsive'>{item.remarks}</td> */}
                                            <td className='text-center'>
                                                <div className='d-grid'>
                                                    <>
                                                        {(() => {
                                                            // Approved:2
                                                            if (item.status && item.status == 3) {
                                                                return (
                                                                    <Button
                                                                        className=" p-1 pt-0 pb-0"
                                                                        style={{fontSize: '12px', color: 'white'}}
                                                                        variant="success"
                                                                        size="sm"
                                                                    >
                                                                        Approved
                                                                    </Button>
                                                                )
                                                                // Reject:3
                                                            } else if(item.status && item.status == 2) {
                                                                return (
                                                                    <Button
                                                                        className=" p-1 pt-0 pb-0"
                                                                        style={{fontSize: '12px', color: 'white'}}
                                                                        variant="danger"
                                                                        size="sm"
                                                                    >
                                                                        Disapproved
                                                                    </Button>
                                                                )
                                                            }

                                                            return (
                                                                <Button
                                                                    className=" p-1 pt-0 pb-0"
                                                                    style={{fontSize: '12px', color: 'white'}}
                                                                    variant="secondary"
                                                                    size="sm"
                                                                >
                                                                    Pending
                                                                </Button>
                                                            )

                                                        })()}
                                                    </>
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                <Button
                                                    onClick={() => showLeaveRequest(item.leave_application_id)}
                                                    className=' p-0 pt-0 pb-0'
                                                    style={{ fontSize: '12px', color: 'white' }}
                                                    variant='btn-transparent'
                                                    size='sm'
                                                >
                                                    <View/>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                ) : (
                                    <tr className='no-item'>
                                        <td colSpan={10}>{TEXT.NOT_FOUND}</td>
                                    </tr>
                                )}
                            </>
                            </tbody>
                        </Table>

                        <Pagination
                            itemsCount={itemsCount}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            lastPage={lastPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={false}
                            pageClicked={(page: number) => {
                                getLeaveRequest(page, selectedEmployeeOption.value, searched, status, dateFilterPending.dateFrom, dateFilterPending.dateTo, leaveType);
                            }
                            }
                        />




                    </Card.Body>
                </Card>

                <Loader show={showLoading}/>

                {/* DETAIL MODAL*/}
                <Modal className='modal' centered size={'lg'} show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>
                            <Stack direction='horizontal'>
                                <div>
                                    {
                                        item.employee.photo != "" ?
                                            <Avatar
                                                sx={{ width: 65, height: 65 }}
                                                src={process.env.REACT_APP_IMAGE_URL+ item.employee.photo}
                                            ></Avatar> :
                                            <Avatar
                                                sx={{ width: 65, height: 65}}
                                                src={""}
                                            ></Avatar>
                                    }
                                </div>
                                <div className='mx-3'>
                                    &nbsp;{item.employee?.first_name} {item.employee?.last_name}
                                    <br />
                                    &nbsp;<span className='text-muted fw-light'>{item.employee?.user_name?.role?.role_name}</span>
                                </div>
                            </Stack>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={6}>
                                <Table borderless className='mt-0 mb-0'>
                                    <tbody style={{ padding: 'none' }}>
                                    <tr className='lh-1'>
                                        <td className='fw-bold' width={120}>
                                            Leave Type:
                                        </td>
                                        <td>{item.leave_type?.leave_type_name}</td>
                                    </tr>
                                    <tr className='lh-1'>
                                        <td className='fw-bold' width={120}>
                                            No. of Days:
                                        </td>
                                        <td >{handleDisplayDays(item.number_of_day)}</td>
                                    </tr>
                                    <tr className='lh-1'>
                                        <td className='fw-bold' width={120}>
                                            Date of Leave:
                                        </td>
                                        <td>{item.application_from_date} - {item.application_to_date}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col sm={6}>
                                <Table borderless className='mt-0 mb-0'>
                                    <tbody style={{ padding: 'none' }}>
                                    <tr className='lh-1'>
                                        <td className='fw-bold' width={120}>
                                            Applied By:
                                        </td>
                                        <td>{item.created_by_first_name} {item.created_by_last_name}</td>
                                    </tr>
                                    <tr className='lh-1'>
                                        <td className='fw-bold' width={120}>
                                            Applied On:
                                        </td>
                                        <td>{item.application_date}</td>
                                    </tr>
                                    <tr className='lh-1'>
                                        <td className='fw-bold' width={120}>
                                            Reason:
                                        </td>
                                        <td>{item.purpose}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>

                        </Row>
                        <hr/>
                        <Row style={{marginTop: '-15px'}}>
                            <Table borderless className='mt-0 mb-0'>
                                <tbody>
                                <tr className='lh-1'>
                                    <th className='text-center'>Approvals</th>
                                </tr>
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Table borderless className='mt-0 mb-0'>
                                    <tbody style={{ padding: 'none' }}>
                                    <tr className='lh-1'>
                                        <th className='text-center' style={{width: '20%'}}>Levels</th>
                                        <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                                        <th className='text-center'>Role</th>
                                        <th className='text-center' style={{width: '15%'}}>Status</th>
                                        <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                                    </tr>
                                    {
                                        item.request_status.length > 0 ? (
                                            item.request_status.map((item1: any, index: any) =>
                                                (
                                                    <tr key={index} className='lh-1'>
                                                        <td>
                                                            {
                                                                index != 0 && item.request_status[index - 1].level == item1.level ?
                                                                    "" : <>
                                                                        <b>Level {item1.level}</b> : {item1.approvers_order == "0" ? "By Order" : "Anyone"}
                                                                    </>
                                                            }
                                                        </td>
                                                        <td>
                                                            <Stack direction='horizontal'>
                                                                <div>
                                                                    {
                                                                        item1.employee?.photo != "" ?
                                                                            <Avatar
                                                                                sx={{ width: 20, height: 20 }}
                                                                                src={process.env.REACT_APP_IMAGE_URL+ item1.employee?.photo}
                                                                                className={"profile-avatar"}
                                                                            ></Avatar> :
                                                                            <Avatar
                                                                                sx={{ width: 20, height: 20}}
                                                                                src={""}
                                                                                className={"profile-avatar"}
                                                                            ></Avatar>
                                                                    }
                                                                </div>
                                                                <div style={{color: item1.current == "1" ? "#ff7043": ""}}>&nbsp;{item1.employee?.first_name} {item1.employee?.last_name}</div>
                                                            </Stack>
                                                        </td>
                                                        <td>{item1.employee?.user_name?.role?.role_name}</td>
                                                        {(() => {
                                                            // Approved:2
                                                            if (item1.status == '3') {

                                                                return (
                                                                    <>
                                                                        <td className='fw-bold text-center'>
                                                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                                                                Approved
                                                                            </Button>
                                                                        </td>
                                                                        <td className='text-center'>{moment(new Date(item1.approve_date ?? "")).format(dateFormat)}</td>
                                                                    </>
                                                                )

                                                                // Reject:3
                                                            } else if(item1.status == '2') {
                                                                return (
                                                                    <>
                                                                        <td className='fw-bold text-center'>
                                                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                                                                Disapproved
                                                                            </Button>
                                                                        </td>
                                                                        <td className='text-center'>{moment(new Date(item1.reject_date ?? "")).format(dateFormat)}</td>

                                                                    </>
                                                                )
                                                            } else if(item1.status == '4') {
                                                                return (
                                                                    <>
                                                                        <td className='text-center'>-</td>
                                                                        <td className='text-center'>-</td>
                                                                    </>

                                                                )
                                                            }

                                                            return (
                                                                <>
                                                                    <td className='fw-bold text-center'>
                                                                        <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                                                            Pending
                                                                        </Button>
                                                                    </td>
                                                                    <td className='text-center'>-</td>
                                                                </>
                                                            )
                                                        })()}


                                                    </tr>

                                                )

                                            )
                                        ) : (
                                            <></>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </Col>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {/*<Button*/}
                        {/*    onClick={() => rejectLeaveRequest(item.leave_application_id)}*/}
                        {/*    variant='outline-primary'*/}
                        {/*    className=' px-5 pe-5 mx-2'*/}
                        {/*    size={'sm'}*/}
                        {/*    disabled={handleDisableAcceptRejectButton(item)}*/}
                        {/*>*/}
                        {/*    Rejected*/}
                        {/*</Button>*/}
                        {/*<Button*/}
                        {/*    onClick={() => acceptLeaveRequest(item.leave_application_id)}*/}
                        {/*    variant='primary'*/}
                        {/*    className='text-white px-5 pe-5'*/}
                        {/*    size={'sm'}*/}
                        {/*    disabled={handleDisableAcceptRejectButton(item)}*/}
                        {/*>*/}
                        {/*    Approved*/}
                        {/*</Button>*/}
                    </Modal.Footer>
                </Modal>
                <br />
            </Container>
        </>
    )
}
