import React from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Pagination,
  Row,
  Stack,
  Table,
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'

export default function Index() {
  const [show, setShow] = React.useState(false)
  const [fileName, setFileName] = React.useState('Upload Boundary File')
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [showEdit, setShowEdit] = React.useState(false)

  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)
  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Report</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/payroll-range'>
            Payroll
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Report</Breadcrumb.Item>
        </Breadcrumb>
        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <span className='fw-bold'>&nbsp;</span>
              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                <InputGroup size='sm' className={'w-25'}>
                  <Form.Control
                    aria-label='search'
                    aria-describedby='inputGroup-sizing-sm'
                    placeholder='Search...'
                  />
                  <InputGroup.Text className='bg-transparent search-input '>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
                {/* <Button*/}
                {/*    onClick={handleShow}*/}
                {/*    className=" add-new-btn rounded mx-2 text-white" variant="warning">*/}
                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"*/}
                {/*         viewBox="0 0 20.055 20.207">*/}
                {/*        <g id="Edit-Icon" transform="translate(0.5 0.652)">*/}
                {/*            <g id="Icon_feather-edit" data-name="Icon feather-edit">*/}
                {/*                <path id="Path_44" data-name="Path 44"*/}
                {/*                      d="M11.523,6H4.894A1.894,1.894,0,0,0,3,7.894V21.152a1.894,1.894,0,0,0,1.894,1.894H18.152a1.894,1.894,0,0,0,1.894-1.894V14.523"*/}
                {/*                      transform="translate(-3 -3.991)" fill="none" stroke="#fff"*/}
                {/*                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>*/}
                {/*                <path id="Path_45" data-name="Path 45"*/}
                {/*                      d="M21.943,3.406a2.009,2.009,0,1,1,2.841,2.841l-9,9L12,16.191l.947-3.788Z"*/}
                {/*                      transform="translate(-6.318 -2.818)" fill="none" stroke="#fff"*/}
                {/*                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>*/}
                {/*            </g>*/}
                {/*        </g>*/}
                {/*    </svg>*/}
                {/*    &nbsp;Add New Report Grade*/}
                {/* </Button>{' '}*/}
              </Col>
            </Row>
            <hr />
            <Table responsive>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: '5%' }}>No.</th>
                  <th style={{ width: '15%' }}>Employee Name</th>
                  <th style={{ width: '25%' }}>Payroll Period</th>
                  <th style={{ width: '15%' }}>Pay Grade</th>
                  <th style={{ width: '15%' }}>Total</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr className='mb-2'>
                  <td>1</td>
                  <td>Employee 1</td>
                  <td>June 11,2022 - June 25,2022</td>
                  <td>A</td>
                  <td className='text-right'>0.00</td>
                  <td className='text-center'>
                    <Button
                      onClick={handleShowEdit}
                      className=' p-1 pt-0 pb-0'
                      style={{ fontSize: '12px', color: 'white' }}
                      variant=' btn-transparent'
                      size='sm'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16.143'
                        height='16.268'
                        viewBox='0 0 16.143 16.268'
                      >
                        <g id='Edit' transform='translate(0.5 0.627)'>
                          <g
                            id='Icon_feather-edit'
                            data-name='Icon feather-edit'
                            transform='translate(-3 -2.818)'
                          >
                            <path
                              id='Path_44'
                              data-name='Path 44'
                              d='M9.773,6H4.505A1.505,1.505,0,0,0,3,7.505V18.04a1.505,1.505,0,0,0,1.505,1.505H15.04a1.505,1.505,0,0,0,1.505-1.505V12.773'
                              transform='translate(0 -1.586)'
                              fill='none'
                              stroke='#a7a9ac'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='1'
                            />
                            <path
                              id='Path_45'
                              data-name='Path 45'
                              d='M19.9,3.286a1.6,1.6,0,0,1,2.258,2.258L15.01,12.692,12,13.445l.753-3.01Z'
                              transform='translate(-4.485 0)'
                              fill='none'
                              stroke='#f26930'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='1'
                            />
                          </g>
                        </g>
                      </svg>
                    </Button>
                    <Button
                      className='p-1 pt-0 pb-0'
                      style={{ fontSize: '12px' }}
                      variant='transparent'
                      size='sm'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='15.598'
                        height='17.221'
                        viewBox='0 0 15.598 17.221'
                      >
                        <g id='Delete' transform='translate(0.5 0.5)'>
                          <path
                            id='Path_23'
                            data-name='Path 23'
                            d='M4.5,9H19.1'
                            transform='translate(-4.5 -5.756)'
                            fill='none'
                            stroke='#a7a9ac'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                          />
                          <path
                            id='Path_24'
                            data-name='Path 24'
                            d='M18.854,6.244V17.6a1.622,1.622,0,0,1-1.622,1.622H9.122A1.622,1.622,0,0,1,7.5,17.6V6.244m2.433,0V4.622A1.622,1.622,0,0,1,11.555,3H14.8a1.622,1.622,0,0,1,1.622,1.622V6.244'
                            transform='translate(-5.879 -3)'
                            fill='none'
                            stroke='#a7a9ac'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                          />
                          <path
                            id='Path_25'
                            data-name='Path 25'
                            d='M15,16.5v4.866'
                            transform='translate(-9.322 -9.201)'
                            fill='none'
                            stroke='#f26930'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                          />
                          <path
                            id='Path_26'
                            data-name='Path 26'
                            d='M21,16.5v4.866'
                            transform='translate(-12.078 -9.201)'
                            fill='none'
                            stroke='#f26930'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                          />
                        </g>
                      </svg>
                    </Button>
                  </td>
                  <br />
                </tr>
                <tr className='mb-2'>
                  <td>2</td>
                  <td>Employee 1</td>
                  <td>June 11,2022 - June 25,2022</td>
                  <td>A</td>
                  <td className='text-right'>0.00</td>
                  <td className='text-center'>
                    <Button
                      onClick={handleShowEdit}
                      className=' p-1 pt-0 pb-0'
                      style={{ fontSize: '12px', color: 'white' }}
                      variant=' btn-transparent'
                      size='sm'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16.143'
                        height='16.268'
                        viewBox='0 0 16.143 16.268'
                      >
                        <g id='Edit' transform='translate(0.5 0.627)'>
                          <g
                            id='Icon_feather-edit'
                            data-name='Icon feather-edit'
                            transform='translate(-3 -2.818)'
                          >
                            <path
                              id='Path_44'
                              data-name='Path 44'
                              d='M9.773,6H4.505A1.505,1.505,0,0,0,3,7.505V18.04a1.505,1.505,0,0,0,1.505,1.505H15.04a1.505,1.505,0,0,0,1.505-1.505V12.773'
                              transform='translate(0 -1.586)'
                              fill='none'
                              stroke='#a7a9ac'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='1'
                            />
                            <path
                              id='Path_45'
                              data-name='Path 45'
                              d='M19.9,3.286a1.6,1.6,0,0,1,2.258,2.258L15.01,12.692,12,13.445l.753-3.01Z'
                              transform='translate(-4.485 0)'
                              fill='none'
                              stroke='#f26930'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='1'
                            />
                          </g>
                        </g>
                      </svg>
                    </Button>
                    <Button
                      className='p-1 pt-0 pb-0'
                      style={{ fontSize: '12px' }}
                      variant='transparent'
                      size='sm'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='15.598'
                        height='17.221'
                        viewBox='0 0 15.598 17.221'
                      >
                        <g id='Delete' transform='translate(0.5 0.5)'>
                          <path
                            id='Path_23'
                            data-name='Path 23'
                            d='M4.5,9H19.1'
                            transform='translate(-4.5 -5.756)'
                            fill='none'
                            stroke='#a7a9ac'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                          />
                          <path
                            id='Path_24'
                            data-name='Path 24'
                            d='M18.854,6.244V17.6a1.622,1.622,0,0,1-1.622,1.622H9.122A1.622,1.622,0,0,1,7.5,17.6V6.244m2.433,0V4.622A1.622,1.622,0,0,1,11.555,3H14.8a1.622,1.622,0,0,1,1.622,1.622V6.244'
                            transform='translate(-5.879 -3)'
                            fill='none'
                            stroke='#a7a9ac'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                          />
                          <path
                            id='Path_25'
                            data-name='Path 25'
                            d='M15,16.5v4.866'
                            transform='translate(-9.322 -9.201)'
                            fill='none'
                            stroke='#f26930'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                          />
                          <path
                            id='Path_26'
                            data-name='Path 26'
                            d='M21,16.5v4.866'
                            transform='translate(-12.078 -9.201)'
                            fill='none'
                            stroke='#f26930'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                          />
                        </g>
                      </svg>
                    </Button>
                  </td>
                  <br />
                </tr>
              </tbody>
            </Table>
            <Pagination size={'sm'}>
              <Stack direction={'horizontal'} gap={2}>
                <Pagination.First />
                <Pagination.Prev />
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Next />
                <Pagination.Last />
              </Stack>
            </Pagination>
          </Card.Body>
        </Card>
        {/* ADD MODAL*/}
        <Modal className='modal' centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Add New Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label className='text-muted fw-bold'>Report Name</Form.Label>
                <Form.Control type='text' placeholder='Report name' autoFocus />
              </Form.Group>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label className='text-muted fw-bold'>Report Type</Form.Label>
                <Form.Select size={'sm'}>
                  <option selected>All types</option>
                  <option> Percentage</option>
                  <option>Snack department</option>
                  <option>Staff department</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label className='text-muted fw-bold'>Percentage of Basic</Form.Label>
                <Form.Control type='number' placeholder='Percentage of Basic' autoFocus />
              </Form.Group>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label className='text-muted fw-bold'>Limit Per Month</Form.Label>
                <Form.Control type='number' placeholder='Limit Per Month' autoFocus />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={handleClose}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* EDIT MODAL*/}
        <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Edit Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                <Form.Label className='text-muted fw-bold'>Payroll range</Form.Label>
                <Form.Control type='text' placeholder='Department name' autoFocus />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
              Cancel
            </Button>
            <Button
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
              onClick={handleCloseEdit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  )
}
