import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  type: yup
    .string()
    .required(VALIDATION.required),
    device_name: yup
        .string()
        .required(VALIDATION.required),
    browser: yup
        .string()
        .required(VALIDATION.required),
})
