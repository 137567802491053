import React, {useEffect, useRef, useState} from 'react'
import { RegistrationStepComp } from '../types'
import {
    Button,
    Col,
    Form,
    InputGroup,
    Row,
} from 'react-bootstrap'
import { Formik } from 'formik';
import { validationSchema } from './validationSchema'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState } from 'redux/appStore'
import { addStep1 } from 'redux/Register/Step1/Step1Slice'

import "./style.css"

const Personal: React.FC<RegistrationStepComp> = ({ setCurrentStep }) => {
    const initValues = useSelector((state: RootState) => state.registerStep1)
    const dispatch = useDispatch()
    const onSubmit = async (value: any) => {
        setCurrentStep(2)
        dispatch(addStep1(value))
    }


    // useEffect(() => {
    //     ;(async () => {
    //
    //     })()
    // }, [])

    return (
        <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                onSubmit(values)
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
              }) => (

                <Form noValidate onSubmit={handleSubmit}>
                    <Row className={'mt-4'}>
                        <Col sm={12}>
                            <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                                <Form.Label className='fw-light required text-white'>
                                    Full Name
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Full Name'
                                    name="full_name"
                                    value={values.full_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.full_name && !!errors.full_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.full_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={'mt-2'}>
                        <Col sm={12}>
                            <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                                <Form.Label className='fw-light required text-white'>
                                    Email Address
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Email Address'
                                    name="email_address"
                                    value={values.email_address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.email_address && !!errors.email_address}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email_address}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-5 text-right">
                        <Form.Group as={Col} md="12">
                            <Button
                                type="submit"
                                // disabled={isSubmitting}
                                className={"nextButton bg-white text-primary"}
                                style={{ width: '25%' }}
                                size="sm">
                                Next
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

export default Personal
