import React, {useEffect, useState} from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Stack,
    Table,
} from 'react-bootstrap'
import './style.css'
import {
    EditOutlined,
    FilePresentOutlined,
    SaveAsOutlined,
    ViewInArOutlined,
} from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import axios from "../../../../api";
import {IAPI_Response} from "../../../../api/types";
import {
    IEmployee, IEmployeeList,
    IList,
    ILateComputation,
    IPayrollComputationSetting,
    IPayrollRange, IPayrollRangeEmployee,
    ILateComputationList
} from "../LateComputation/types";
import {API_ENDPOINT, ERROR_MESSAGE, PAYROLL_COMPUTATION, TEXT} from "../../../../utils/globalConstant";
import Pagination from 'components/Pagination'
import Alert from "components/Alert";
import Write from "asset/SvgComponent/Write";
import View from 'asset/SvgComponent/View'
import {IItem} from "../../../Administration/NoticeBoard/types";

export default function Index() {

    const [show, setShow] = React.useState(false)
    const [showUpdate, setShowUpdate] = React.useState(false)
    const [fileName, setFileName] = React.useState('Upload Boundary File')
    const handleClose = () => {
        setShow(false)
        setPayrollRange('');
    }
    const handleShow = () => {
        setPayrollRange('');
        setShow(true)
    }
    const handleUpdateClose = () => {
        setShowUpdate(false)
        setPayrollRange('');
    }
    const handleUpdateShow = () => setShowUpdate(true)
    const [showEdit, setShowEdit] = React.useState(false)
    const [showEmployeeList, setShowEmployeeList] = React.useState(false)
    const [showEmployeeListOnly, setShowEmployeeListOnly] = React.useState(false)
    const [showTransfer, setShowTransfer] = React.useState(false)
    const [error, setError] = React.useState('')
    const [showError, setShowError] = React.useState(false)
    const [success, setSuccess] = React.useState('')
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState(0)
    const [lastPage, setLastPage] = React.useState(0)
    const [itemsPerPage, setPerPage] = React.useState(6)
    const [itemsCount, setItemCount] = React.useState(0)
    const [from, setFrom] = React.useState(0)
    const [currentPage2, setCurrentPage2] = React.useState(0)
    const [lastPage2, setLastPage2] = React.useState(0)
    const [itemsPerPage2, setPerPage2] = React.useState(6)
    const [itemsCount2, setItemCount2] = React.useState(0)
    const [from2, setFrom2] = React.useState(0)
    const [data, setData] = React.useState<IList[]>([])
    const [data2, setData2] = React.useState<IEmployeeList[]>([])
    const [showLoading, setShowLoading] = React.useState(false)
    const weekdays = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    const [used, setUsed] = React.useState(0)
    const [payrollRange, setPayrollRange] = React.useState('')
    const [payrollRangeData, setPayrollRangeData] = React.useState<ILateComputationList>({
        id: 0,
        name: "",
        description: "",
        formula: "",
        isUsed: 0,
    })
    const [employees, setEmployees] = React.useState<IEmployee[]>([
        {
            employee_id: 0,
            first_name: '',
            last_name: '',
            payroll_range: ''
        }
    ])
    const [employeeChosen, setEmployeeChosen] = React.useState(0)
    const [payrollRangeChosen, setPayrollRangeChosen] = React.useState(0)
    const [employeeNewPayrollRange, setEmployeeNewPayrollRange] = React.useState(0)
    const [payrollRangeMemberText, setPayrollRangeMemberText] = React.useState('')
    const [employeeSelected, setEmployeeSelected] = React.useState(0)
    const [selectedEmployee, setSelectedEmployee] = React.useState<IEmployee[]>([])
    const [selectedPayrollRange, setSelectedPayrollRange] = React.useState("");
    const [usedLateComputationData, setUsedLateComputationData] = React.useState<ILateComputationList[]>([])
    const [disableAssign, setDisableAssign] = React.useState(true)
    const [showConfirmation, setShowConfirmation] = React.useState(false)
    const [showUsedError, setShowUsedError] = React.useState(false)
    const [disableTransfer, setDisableTransfer] = React.useState(true)
    const [disableProceed, setDisableProceed] = React.useState(true)
    const [item, setItem] = useState<ILateComputationList>({
        id: 0,
        name: "",
        description: "",
        formula: "",
        isUsed: 0
    })

    // const handleUpdatePayrollRangeName = (e:any) => {
    //     setItem({...item, payroll_range_name: e.target.value})
    // }

    const handleShowUsedError = (id : any) => {
        setShowUsedError(true)
    }

    const handleCloseUsedError = () => {
        setShowUsedError(false)
    }

    const handleShowConfirmation = (id : any) => {
        setShowConfirmation(true)
        setPayrollRangeChosen(id)
    }

    const handleCloseConfirmation = () => {
        setShowConfirmation(false)
    }
    const handleCloseEdit = async () => setShowEdit(false)
    const handleShowEdit = (id : any) => {
        setShowEdit(true)
        handleGetEmployees()
        setPayrollRangeChosen(id)
        handleGetUsedLateComputation()
    }

    const handleCloseEmployeeList = () => {
        setShowEmployeeList(false)
        setEmployeeSelected(0)
        setSelectedEmployee([])
    }
    const handleShowEmployeeList = async (id : any) => {
        setShowEmployeeList(true)

        setPayrollRangeChosen(id)
        getEmployeesOnLateComputation(null, id);
    }

    const handleCloseEmployeeListOnly = () => setShowEmployeeListOnly(false)

    const handleCloseTransfer = () => {
        setShowTransfer(false)
        setSelectedEmployee([])
    }


    const handleGetUsedLateComputation = async () => {
        try {
            const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_USED_LATE_COMPUTATION)
            const result = await res
            if (result?.data?.results?.length) {
                setUsedLateComputationData(result?.data?.results)
            }else{
                setUsedLateComputationData([])
            }
        } catch {
            console.log('There is something wrong')
        }
    }

    const handleCheckboxChange = (id : any, e:any) => {
        data2.forEach((employee) => {
            if (employee.employee_id == id) {
                if (e.target.checked) {
                    setDisableProceed(false)
                    setSelectedEmployee(current => [...current, {employee_id: employee.employee_id, first_name: employee.first_name, last_name: employee.last_name, payroll_range: ""}])
                    setEmployeeSelected(selectedEmployee.length)

                }else{
                    if(employeeSelected == 1){
                        setDisableProceed(true)
                    }
                    setSelectedEmployee(current => current.filter((employee) => employee.employee_id !== id));
                    setEmployeeSelected(selectedEmployee.length)

                }


            }
        })

    }

    const handleSubmitTransfer = async () => {
        await axios.put(API_ENDPOINT.TRANSFER_EMPLOYEE_LATE_COMPUTATION, {
            employee_id: selectedEmployee,
            late_computation_id: employeeNewPayrollRange
        }).then(response => {

            if (response?.data?.status * 1 === 1) {
                setShow(false)
                setShowSuccess(true)
                setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_TRANSFERRED)
                setShowTransfer(false)
                getLateComputation(null)
                setSelectedEmployee([])
            }
        }).catch(error => {
            setShowError(true)
            setError(error.response.data.message)
            setSelectedEmployee([])
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const handleChangeNewLateComputation = async (e: any) => {
        const value = e.target.value;

        if(value != ""){
            setEmployeeNewPayrollRange(value);
            setDisableTransfer(false)

            try {
                const response = await axios.get<IAPI_Response<IPayrollRangeEmployee>>(API_ENDPOINT.ALL_EMPLOYEES_LATE_COMPUTATION+"/"+value, {
                    params: {
                        page: null,
                    },
                })
                const result = await response

                if (result?.data?.results?.data?.length) {
                    const employeesData = result?.data?.results?.data;
                    if(employeesData.length != 0){
                        setPayrollRangeMemberText(`${employeesData[employeesData.length - 1].first_name} ${employeesData[employeesData.length - 1].last_name} and ${employeesData.length - 1} others are assigned to this payroll range.`)
                    }else{
                        setPayrollRangeMemberText(`No employee assigned to this payroll range.`)
                    }
                }else{
                    setPayrollRangeMemberText(`No employee assigned to this payroll range.`)
                }

            }catch {

                setShowError(true)
                setError(ERROR_MESSAGE.SYSTEM_ERROR)
            }
        }else{
            setDisableTransfer(true)
        }

    }

    const handleTransfer = async () => {

        setShowTransfer(true)
        setShowEmployeeList(false);
        setShowUsedError(false)
        handleGetUsedLateComputation()

        data.forEach(lateComputation => {
            if(lateComputation.id == payrollRangeChosen){
                setSelectedPayrollRange(lateComputation.name!)
            }
        })

        if(selectedEmployee.length == 0){
            try {
                const response = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEES_LATE_COMPUTATION_NO_PAGINATION+"/"+payrollRangeChosen)
                const result = await response


                if (result?.data?.results?.length) {
                    setSelectedEmployee(result?.data?.results)
                }
                setShowLoading(false)
            } catch {
                setShowError(true)
                setError(ERROR_MESSAGE.SYSTEM_ERROR)
            }

            setTimeout(() => {
                setShowError(false)
            }, 3000);
        }

    }

    const handleAssignEmployee = async () => {
        await axios.post(API_ENDPOINT.ASSIGN_LATE_COMPUTATION, {
            employee_id: employeeChosen,
            late_computation_id: payrollRangeChosen
        }).then(response => {

            if (response?.data?.status * 1 === 1) {
                setShow(false)
                setShowSuccess(true)
                setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_ASSIGNED)
                getLateComputation(null)
                setShowEdit(false)
                // navigate('/employeeManagement/employee-list');
            }
        }).catch(error => {
            setShowError(true)
            setError(error.response.data.message)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);

    }

    const getEmployeesOnLateComputation = async (page_number: null | number, id: null | number = 0) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IPayrollRangeEmployee>>(API_ENDPOINT.ALL_EMPLOYEES_LATE_COMPUTATION+"/"+id, {
                params: {
                    page: page_number,
                },
            })
            const result = await response

            if (result?.data?.results?.data?.length) {
                setData2(result?.data?.results?.data)
                setCurrentPage2(result?.data?.results?.current_page)
                setLastPage2(result?.data?.results?.last_page)
                setPerPage2(result?.data?.results?.per_page)
                setItemCount2(result?.data?.results?.total)
                setFrom2(result?.data?.results?.from)
            }else{
                setData2([])
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const handleEmployeeChosen = (e : any) => {
        const value = e.target.value;
        if(value != ""){
            setEmployeeChosen(value)
            setDisableAssign(false)
        }else{
            setDisableAssign(true)
        }
    }

    const handleGetEmployees = async () => {
        try {
            const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_EMPLOYEE_UNASSIGNED_LATE_COMPUTATION)
            const result = await res

            if (result?.data?.results?.length) {
                setEmployees(result?.data?.results)
            }else{
                setEmployees([])
            }
        } catch {
            console.log('There is something wrong')
        }
    }


    const getLateComputation = async (page_number: null | number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<ILateComputation>>(API_ENDPOINT.GET_LATE_COMPUTATION, {
                params: {
                    page: page_number,
                },
            })
            const result = await response

            if (result?.data?.results?.data?.length) {
                setData(result?.data?.results?.data)
                setCurrentPage(result?.data?.results?.current_page)
                setLastPage(result?.data?.results?.last_page)
                setPerPage(result?.data?.results?.per_page)
                setItemCount(result?.data?.results?.total)
                setFrom(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }


    const updatePayrollRange = async () => {
        let hasAssigned = false;
        data.forEach((lateComputation) => {
            if(lateComputation.id == payrollRangeChosen){
                if(lateComputation.assigned != 0){
                    setShowConfirmation(false)
                    setShowUsedError(true)
                    hasAssigned = true;
                    return false;
                }
            }
        })

        if(!hasAssigned){
            await axios.put(API_ENDPOINT.UPDATE_LATE_COMPUTATION +'/'+ payrollRangeChosen).then(response => {
                if (response?.data?.status * 1 === 1) {
                    setShowSuccess(true)
                    setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_USED)
                    getLateComputation(null)
                    setShowConfirmation(false)

                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 2000);
                }
            })
        }

    }


    useEffect(() => {
        ;(async () => {
            setEmployeeSelected(selectedEmployee.length)
        })()
    }, [selectedEmployee])

    useEffect(() => {
        ;(async () => {
            getLateComputation(null)
            //getCurrentPayrollRange()
        })()
    }, [])

    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Late Computation</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none' href='#'>
                        Setup
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className=' text-decoration-none' href='#'>
                        Payroll
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Late Computation</Breadcrumb.Item>
                </Breadcrumb>
                <Card className='border-0  p-0 bordered-card'>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <span className='fw-bold'>&nbsp;</span>
                            </Col>
                            <Col sm={6} className='d-flex justify-content-end hidden'>
                                <InputGroup size='sm' className={'w-25'}>
                                    <Form.Control
                                        aria-label='search'
                                        aria-describedby='inputGroup-sizing-sm'
                                        placeholder='Search...'
                                    />
                                    <InputGroup.Text className='bg-transparent search-input '>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                </InputGroup>

                            </Col>
                        </Row>
                        {/* <hr/>*/}
                        <Table responsive className='bordered-tabs-top mt-3'>
                            <thead>
                            <tr className='text-center'>
                                <th style={{ width: '20%' }}>Late Computation Name</th>
                                <th style={{ width: '20%' }}>Description</th>
                                <th>Assigned</th>
                                <th>Actions</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.length > 0 ? (
                                    data.map((lateComputation, index) => (
                                        <tr className='mb-2' key={lateComputation.id}>
                                            <td>{lateComputation.name}</td>
                                            <td>{lateComputation.description}</td>
                                            <td className='text-center'>{lateComputation.assigned}</td>
                                            <td className='text-center'>
                                                <Button
                                                    onClick={() => handleShowEdit(lateComputation.id)}
                                                    className=' p-1 pt-0 pb-0'
                                                    style={{ fontSize: '12px', color: 'white' }}
                                                    variant=' btn-transparent'
                                                    size='sm'
                                                    title={'Assign Employee'}
                                                    disabled={lateComputation.isUsed == 1 ? false: true}
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='16.143'
                                                        height='16.143'
                                                        viewBox='0 0 16.143 16.143'
                                                    >
                                                        <g id='New' transform='translate(0.5 0.5)'>
                                                            <g
                                                                id='Icon_feather-plus-circle'
                                                                data-name='Icon feather-plus-circle'
                                                                transform='translate(-3 -3)'
                                                            >
                                                                <path
                                                                    id='Path_46'
                                                                    data-name='Path 46'
                                                                    d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'
                                                                    fill='none'
                                                                    stroke='#a7a9ac'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    strokeWidth='1'
                                                                />
                                                                <path
                                                                    id='Path_47'
                                                                    data-name='Path 47'
                                                                    d='M18,12v6.057'
                                                                    transform='translate(-7.429 -4.457)'
                                                                    fill='none'
                                                                    stroke='#f26930'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    strokeWidth='1'
                                                                />
                                                                <path
                                                                    id='Path_48'
                                                                    data-name='Path 48'
                                                                    d='M12,18h6.057'
                                                                    transform='translate(-4.457 -7.429)'
                                                                    fill='none'
                                                                    stroke='#f26930'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    strokeWidth='1'
                                                                />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </Button>
                                                <Button
                                                    onClick={() => handleShowEmployeeList(lateComputation.id)}
                                                    className=' p-1 pt-0 pb-0'
                                                    style={{ fontSize: '12px', color: 'white' }}
                                                    variant=' btn-transparent'
                                                    size='sm'
                                                    title={'Transfer Employee'}
                                                    disabled={lateComputation.isUsed == 1 ? false: true}
                                                >
                                                    <View/>
                                                </Button>
                                            </td>
                                            <td className='text-center'>

                                                <Button
                                                    className='pe-3 px-3 text-white'
                                                    style={{ fontSize: '12px', width: '70px' }}
                                                    variant={lateComputation.isUsed == 1? "primary": "secondary"}
                                                    size='sm'
                                                    onClick={() => handleShowConfirmation(lateComputation.id)}
                                                >
                                                    {lateComputation.isUsed == 1? "Used": "Use"}
                                                </Button>
                                            </td>
                                            <br />
                                        </tr>
                                    ))
                                ) : (
                                    <tr className='no-item'>
                                        <td colSpan={5}>{TEXT.NOT_FOUND}</td>
                                    </tr>
                                )

                            }


                            </tbody>
                        </Table>
                        <Pagination
                            itemsCount={itemsCount}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            lastPage={lastPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={false}
                            pageClicked={(page: number) => {
                                getLateComputation(page);
                            }
                            }
                        />
                    </Card.Body>
                </Card>

                {/* EDIT MODAL*/}
                <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Assign Employee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                
                                <Form.Group as={Col} md="8" className="position-relative">
                                    <Form.Label column="sm" className="required">Employees</Form.Label>
                                    <Form.Select
                                        size={'sm'}
                                        name="marital_status"
                                        onChange={handleEmployeeChosen}
                                        // onBlur={handleBlur}
                                    >
                
                                        {employees.length > 0 ?
                                            (<>
                                                <option value="" disabled={true} selected >Select Employee</option>
                                                <option value="0">All Employees</option>
                                                {employees.map((employee, index) => (
                                                    <option value={employee.employee_id??0} key={index}>{employee.first_name} {employee.last_name}</option>
                                                ))}
                                            </>)
                                            : (<option value={0} selected key={1} disabled={true}>No unassigned employees.</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {/*{errors.marital_status}*/}
                                    </Form.Control.Feedback>
                
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    <Button
                                        onClick={handleAssignEmployee}
                                        type="button"
                                        disabled={disableAssign}
                                        variant='primary text-white'
                                        style={{ width: '50%',marginBottom: '-70px' }}
                                        size="sm">
                                        Assign
                                    </Button>
                                </Form.Group>
                            </Row>
                            <br/>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary text-white'
                            size={'sm'}
                            className='mx-2'
                            onClick={handleCloseEdit}
                        >
                            Okay
                        </Button>
                    </Modal.Footer>
                </Modal>
                
                {/* EMPLOYEE LIST WITH TRANSFER MODAL*/}
                <Modal className='modal' centered show={showEmployeeList} onHide={handleCloseEmployeeList}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Employee List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group style={{textAlign:'right'}}>
                            <Form.Label column="sm" >{employeeSelected} selected</Form.Label>
                        </Form.Group>
                
                        <Table responsive className='bordered-tabs-top mt-3'>
                            <thead>
                            <tr className='text-center'>
                                <th style={{ width: '10%' }}></th>
                                <th style={{ width: '55%' }}>Name</th>
                                <th style={{ width: '35%' }}>Designation</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data2.length > 0 ? (
                                    data2.map((employee, index) => (
                                        <tr className='mb-2' key={employee.id}>
                                            <td>
                                                <Form.Group key={employee.id}>
                                                    <Form.Check
                                                        // label={menu.name}
                                                        name={employee?.id?.toString()}
                                                        value={employee.id?.toString()}
                                                        id={employee?.id?.toString()}
                                                        onClick={(event) => handleCheckboxChange(employee.employee_id, event)}
                                                    />
                                                </Form.Group>
                                            </td>
                                            <td >{employee.first_name + " " + employee.last_name}</td>
                                            <td>{employee.designation_name}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className='no-item'>
                                        <td colSpan={3}>{TEXT.NOT_FOUND}</td>
                                    </tr>
                                )
                
                            }
                
                
                            </tbody>
                        </Table>
                        <Pagination
                            itemsCount={itemsCount2}
                            itemsPerPage={itemsPerPage2}
                            currentPage={currentPage2}
                            lastPage={lastPage2}
                            setCurrentPage={setCurrentPage2}
                            alwaysShown={false}
                            pageClicked={(page: number) => {
                                getEmployeesOnLateComputation(page, payrollRangeChosen);
                            }
                            }
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' size={'sm'} onClick={handleCloseEmployeeList}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary text-white'
                            size={'sm'}
                            className='mx-2'
                            onClick={handleTransfer}
                            disabled={disableProceed}
                        >
                            Proceed
                        </Button>
                    </Modal.Footer>
                </Modal>
                
                {/* EMPLOYEE LIST ONLY MODAL*/}
                <Modal className='modal' centered show={showEmployeeListOnly} onHide={handleCloseEmployeeListOnly}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Employee List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive className='bordered-tabs-top mt-3'>
                            <thead>
                            <tr className='text-center'>
                                <th style={{ width: '60%' }}>Name</th>
                                <th style={{ width: '40%' }}>Designation</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data2.length > 0 ? (
                                    data2.map((employee, index) => (
                                        <tr className='mb-2' key={employee.id}>
                                            <td>{employee.first_name + " " + employee.last_name}</td>
                                            <td>{employee.designation_name}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className='no-item'>
                                        <td colSpan={2}>{TEXT.NOT_FOUND}</td>
                                    </tr>
                                )
                
                            }
                
                
                            </tbody>
                        </Table>
                        <Pagination
                            itemsCount={itemsCount2}
                            itemsPerPage={itemsPerPage2}
                            currentPage={currentPage2}
                            lastPage={lastPage2}
                            setCurrentPage={setCurrentPage2}
                            alwaysShown={false}
                            pageClicked={(page: number) => {
                                getEmployeesOnLateComputation(page, payrollRangeChosen);
                            }
                            }
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='primary text-white'
                            size={'sm'}
                            className='mx-2'
                            onClick={handleCloseEmployeeListOnly}
                        >
                            Okay
                        </Button>
                    </Modal.Footer>
                </Modal>
                
                {/* TRANSFER MODAL*/}
                <Modal className='modal' centered show={showTransfer} onHide={handleCloseTransfer}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Transfer Employee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                
                        <Form.Group className='mb-3'>
                            <Form.Label column="sm" className="text-muted fw-bold">From</Form.Label>
                            <Form.Control type='text' placeholder='Department name' style={{width: '50%'}} autoFocus value={selectedPayrollRange} disabled={true}/>
                
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label column="sm" className="text-muted fw-bold">To</Form.Label>
                            <Form.Select
                                size={'sm'}
                                name="marital_status"
                                onChange={handleChangeNewLateComputation}
                                style={{width: '50%'}}
                                // onBlur={handleBlur}
                            >
                                <option value="">Select Payroll Range</option>
                                {usedLateComputationData.map((late_computation, index) => (
                                    <option value={late_computation.id} key={index}>{late_computation.name}</option>
                                ))}
                
                            </Form.Select>
                
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label column="sm" className="text-muted fw-bold">{payrollRangeMemberText}</Form.Label>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label column="sm" className="text-muted fw-bold">Transfer?</Form.Label>
                            <Row>
                                <Form.Group as={Col} md="6">
                                    <ul>
                                        {(selectedEmployee.slice(0, Math.ceil(selectedEmployee.length / 2))).map((emp, index) => (
                                            <li key={index}>{emp.first_name} {emp.last_name}</li>
                
                                        ))}
                                    </ul>
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                    <ul>
                                        {(selectedEmployee.slice(Math.ceil(selectedEmployee.length / 2), selectedEmployee.length)).map((emp, index) => (
                
                                            <li key={index}>{emp.first_name} {emp.last_name}</li>
                                        ))}
                                    </ul>
                                </Form.Group>
                            </Row>
                
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' size={'sm'} onClick={handleCloseTransfer}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary text-white'
                            size={'sm'}
                            className='mx-2'
                            onClick={handleSubmitTransfer}
                            disabled={disableTransfer}
                        >
                            Transfer
                        </Button>
                    </Modal.Footer>
                </Modal>
                
                {/* USED/UNUSED CONFIRMATION MODAL */}
                <Modal className='modal' centered show={showConfirmation} onHide={handleCloseConfirmation}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group style={{textAlign:'center'}}>
                            <Form.Label column="sm" >Are you sure you want to proceed?</Form.Label>
                        </Form.Group>
                
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' size={'sm'} onClick={handleCloseConfirmation}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary text-white'
                            size={'sm'}
                            className='mx-2'
                            onClick={updatePayrollRange}
                        >
                            Proceed
                        </Button>
                    </Modal.Footer>
                </Modal>
                
                {/* USED/UNUSED ERROR MODAL */}
                <Modal className='modal' centered show={showUsedError} onHide={handleCloseUsedError}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Error</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group style={{textAlign:'center'}}>
                            <Form.Label column="sm" >Please transfer all employees to another Payroll Range Name. Do you want to transfer now?</Form.Label>
                        </Form.Group>
                
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' size={'sm'} onClick={handleCloseUsedError}>
                            Cancel
                        </Button>
                        <Button
                            variant='primary text-white'
                            size={'sm'}
                            className='mx-2'
                            onClick={handleTransfer}
                        >
                            Transfer
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <br />
            </Container>
        </>
    )
}
