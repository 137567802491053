import React, { useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Table
} from 'react-bootstrap'
import './style.css'
import { Formik } from 'formik';
import View from 'asset/SvgComponent/View'
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Calendar from 'asset/SvgComponent/Calendar'
import Attached from 'asset/SvgComponent/Attached'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
    API_ENDPOINT,
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    TEXT
} from 'utils/globalConstant'
import {IActivityLog, IList, IItem} from './types'
import moment from "moment";
import Select from "components/ReactSelect";
import {IEmployee} from "../../Attendance/AttendanceReport/Retail/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import CheckIcon from "asset/SvgComponent/CheckIcon";
import ErrorIcon from "asset/SvgComponent/ErrorIcon";

export default function Index() {
    const [currentPage, setCurrentPage] = React.useState(0)
    const [lastPage, setLastPage] = React.useState(0)
    const [itemsPerPage, setPerPage] = React.useState(10)
    const [itemsCount, setItemCount] = React.useState(0)
    const [from, setFrom] = React.useState(0)

    const [error, setError] = React.useState('')
    const [success, setSuccess] = React.useState('')
    const [showError, setShowError] = React.useState(false)
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [showLoading, setShowLoading] = React.useState(false)

    const [showActivityParameters, setShowActivityParameters] = React.useState(false)
    const handleShowActivityParameters = () => setShowActivityParameters(true)
    const handleCloseActivityParameters = () => setShowActivityParameters(false)

    const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "All Employees"})
    const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
    const [selectedEmployeeNo, setSelectedEmployeeNo] = useState(0);
    const [selectedFromDate, setSelectedFromDate] = useState('');
    const [selectedToDate, setSelectedToDate] = useState('');
    const [entries, setEntries] = useState(10)

    const [lists, setData] = useState<IList[]>([])
    const [item, setItem] = useState<IItem>({
        id: 0,
        module_name: "",
        activity: "",
        parameters: "",
        menu_url: "",
        status: 0,
        created_by_first_name: "",
        created_by_last_name: "",
        created_at: ""
    })
    const [dateFormat, setDateFormat] = useState("");
    const [timeFormat, setTimeFormat] = useState("");
    const [searched, setSearched] = useState("");

    const handleDisplayModule = (module_name: string, activity: string, module_id: number) => {
        if(activity.toLowerCase().includes("clock") && module_id == 16){
            return (
                <span>Kiosk</span>
            );
        }else{
            return (
                <span>{module_name}</span>
            )

        }
    }

    const handleDisplayPath = (menu_url: string, activity: string, module_id: number) => {
        const isolatedModule = ["allowance","deduction","payroll-range", "department", "designation",
            "branch","work-site","hours-shift","job-level","shift-name","team", "holiday","leave-configuration"]

        if(menu_url.toLowerCase() == "/scheduling"){
            menu_url = "Employees"+menu_url;
        }else if(isolatedModule.some(module => menu_url.includes(module)) && !menu_url.includes("setup")){
            menu_url = "Setup"+menu_url;
        }else if(activity.toLowerCase().includes("clock") && module_id != 63){
            menu_url = "/kiosk"
        }


        const url = menu_url.split('/')
        return <span>
            {url.map((u, index) => {
                if (u !== "") {
                    if (index + 1 === url.length && u != "logout" && u != "login" && u != "kiosk" && u != "home") {
                        // Last element, make it bold
                        return <b className='url-name' onClick={() => redirectToPage(menu_url)} key={index}>{convertString(u)}</b>;
                    }else if(u == "logout" || u == "login" || u == "kiosk" || u == "home"){
                        return (
                            <span key={index}>
                                {convertString(u)}
                            </span>
                        );
                    }else {
                        // Non-last elements, add the arrow symbol
                        return (
                            <span key={index}>
                                {convertString(u)} &gt;{' '}
                            </span>
                        );
                    }
                }
            })}
        </span>
    }

    const handleDisplayPerformedBy = (first_name: string, last_name: string, created_by: number) => {
        if(created_by == 1){
            return "-"
        }else{
            return first_name + " " + last_name
        }
    }

    const isValidJson = (str: string) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    const renderValue = (key: any, value: any) => {
        if(key.toLowerCase() == "password"){
            return (
                <span>{"".padStart(value.length, '*')}</span>
            )
        }

        if (typeof value === 'string' && value.startsWith('data:image/')) {
            // Render base64 image
            return <img style={{width: '200px', height: 'auto'}} src={value} alt={key} />;
        }

        if (Array.isArray(value)) {
            // Render arrays
            return (
                <ul>
                    {value.map((item, index) => (
                        <li key={index}>{renderValue(`${key}[${index}]`, item)}</li>
                    ))}
                </ul>
            );
        }

        if (typeof value === 'object' && value !== null) {
            // Render nested objects
            return (
                <div>
                    {Object.entries(value).map(([nestedKey, nestedValue]) => (
                        <Row key={nestedKey}>
                            <Col sm={5}>{nestedKey}:</Col>
                            <Col sm={7}>{renderValue(nestedKey, nestedValue)}</Col>
                        </Row>
                    ))}
                </div>
            );
        }

        // Render other data types
        return <span>{String(value)}</span>;
    };

    const handleShowActivity = (item: IList) => {
        console.log(item)
        setItem(item)
        handleShowActivityParameters()
    }

    const redirectToPage = (url: string) => {
        window.open(url, '_blank')
    }

    const convertString = (stringToConvert: string) => {
        if(stringToConvert == "employeeManagement"){
            stringToConvert = "Employees"
        }
        return stringToConvert.replace(/-/g, ' ') // Replace all hyphens with spaces
            .toLowerCase()      // Convert everything to lowercase first
            .replace(/\b\w/g, char => char.toUpperCase())
    }

    const handleChangeEntries = (e:any) => {
        setEntries(e.target.value);
        getActivityLog(
            null,
            selectedEmployeeNo,
            selectedFromDate,
            selectedToDate,
            e.target.value,
            searched
        );
    }

    const handleChangeSearchedKeyword = (e:any) => {
        setSearched(e.target.value)

        getActivityLog(
            null,
            selectedEmployeeNo,
            selectedFromDate,
            selectedToDate,
            entries,
            e.target.value
        );
    }

    const getDateFormat = async () => {
        try {
            const res = await axios.get<IAPI_Response<{dateFormat:string}>>(API_ENDPOINT.GET_DATE_FORMAT)
            const result = await res
            if (result?.data?.results) {
                setDateFormat(result?.data?.results?.dateFormat)

            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const getTimeFormat = async () => {
        try {
            const res = await axios.get<IAPI_Response<{timeFormat:string}>>(API_ENDPOINT.GET_TIME_FORMAT)
            const result = await res
            if (result?.data?.results) {
                setTimeFormat(result?.data?.results?.timeFormat)

            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const getActivityLog = async (page_number: null | number, employee_id: number, date_from: string, date_to: string, entries_number: number, searchedKeyword: string) => {
        setShowLoading(true)
        try {
            const res = await axios.get<IAPI_Response<IActivityLog>>(API_ENDPOINT.GET_ACTIVITY_LOG, {
                params: {
                    page: page_number,
                    employee_id: employee_id,
                    date_from: date_from,
                    date_to: date_to,
                    entries: entries_number,
                    searched: searchedKeyword
                },
            })
            const result = await res
            if (result?.data?.results?.data?.length) {
                setData(result?.data?.results?.data)
                setCurrentPage(result?.data?.results?.current_page)
                setLastPage(result?.data?.results?.last_page)
                setPerPage(result?.data?.results?.per_page)
                setItemCount(result?.data?.results?.total)
                setFrom(result?.data?.results?.from)
            }else{
                setData([])
                setCurrentPage(1)
                setLastPage(1)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const filterActivityLog = (value: any) => {

        setSelectedFromDate(value.date_from)
        setSelectedToDate(value.date_to)
        getActivityLog(
            null,
            selectedEmployeeNo,
            value.date_from ?? "",
            value.date_to ?? "",
            entries,
            searched
        );

    }

    const getAllEmployee = async () => {
        try {
            const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
            const result = await res
            if (result?.data?.results?.length) {
                const employees = result?.data?.results;
                setEmployeeOptions([])
                setEmployeeOptions(current => [...current, {value: 0, label: "All Employees"}]);
                employees.map(employee => {
                    setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
                })
            }else{
                setEmployeeOptions([])
            }
        } catch {
            setEmployeeOptions([])
            console.log('There is something wrong')
        }
    }

    // const deleteNotice = async (id: null | number) => {
    //     try {
    //         const response = await axios.delete(API_ENDPOINT.DELETE_NOTICE +'/'+ id)
    //         const result = await response
    //         if (result?.data?.status * 1 === 1) {
    //             setShowSuccess(true)
    //             setSuccess(SUCCESS_MESSAGE.NOTICE.DELETED)
    //             getNotice(null);
    //         }
    //     } catch {
    //         setShowError(true)
    //         setError(ERROR_MESSAGE.SYSTEM_ERROR)
    //     }
    //     handleCloseConfirm()
    //     setTimeout(() => {
    //         setShowSuccess(false)
    //         setShowError(false)
    //     }, 3000);
    // }

    useEffect(() => {
        ;(async () => {
            getActivityLog(null, selectedEmployeeNo, selectedFromDate, selectedToDate, entries, searched)
            getDateFormat()
            getAllEmployee()
            getTimeFormat()
        })()
    }, [])
    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Activity Log</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none' href='/home'>
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className=' text-decoration-none' href='#'>
                        Admin
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Activity Log</Breadcrumb.Item>
                </Breadcrumb>

                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <Card className='border-0  p-0 bordered-card'>
                    <Card.Body>
                        <Formik
                            initialValues={
                                {
                                    employee_id: '',
                                    date_from: '',
                                    date_to: ''
                                }
                            }
                            onSubmit={filterActivityLog}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm={2}>
                                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                <Form.Label className='fw-bold form-labels'>Employee Name</Form.Label>
                                                <Select
                                                    name="employee_id"
                                                    value={selectedEmployeeOption}
                                                    options={employeeOptions}
                                                    onChange={(e: any) => {
                                                        setSelectedEmployeeNo(e.value)
                                                        setSelectedEmployeeOption(e)
                                                        handleSubmit();
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                <Form.Label className='fw-bold form-labels'>Date From</Form.Label>
                                                <InputGroup className='mb-2 ' size={'sm'}>
                                                    <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                                        <Calendar/>
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        size={'sm'}
                                                        type='date'
                                                        placeholder='Friday, June 03, 2022'
                                                        name='date_from'
                                                        value={values.date_from}
                                                        onChange={(e: any) => {
                                                            handleChange(e);
                                                            values.date_from = e.target.value
                                                            handleSubmit();
                                                        }}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.date_from && !!errors.date_from}
                                                        // max={moment().format("YYYY-MM-DD")}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.date_from}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                <Form.Label className='fw-bold form-labels'>Date To</Form.Label>
                                                <InputGroup className='mb-2 ' size={'sm'}>
                                                    <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                                        <Calendar/>
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        size={'sm'}
                                                        type='date'
                                                        placeholder='Friday, June 03, 2022'
                                                        name='date_to'
                                                        value={values.date_to}
                                                        onChange={(e: any) => {
                                                            handleChange(e);
                                                            values.date_to = e.target.value
                                                            handleSubmit();
                                                        }}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.date_to && !!errors.date_to}
                                                        // max={moment().format("YYYY-MM-DD")}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.date_to}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={4}></Col>
                                        <Col sm={2} className='d-flex justify-content-end hidden'>
                                            <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                                                <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                                <br />
                                                <InputGroup size='sm' className={'w-100'}>
                                                    <Form.Control
                                                        aria-label='search'
                                                        aria-describedby='inputGroup-sizing-sm'
                                                        placeholder='Search...'
                                                        onChange={handleChangeSearchedKeyword}
                                                    />
                                                    <InputGroup.Text className='bg-transparent search-input '>
                                                        <FontAwesomeIcon icon={faSearch} />
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                        <Table responsive className='bordered-tabs-top mt-3'>
                            <thead>
                            <tr>
                                <th className='text-center' style={{ width: '5%' }}>No.</th>
                                <th className='text-center' style={{ width: '15%' }}>Date</th>
                                <th className='text-center' style={{ width: '15%' }}>Module</th>
                                <th className='text-center' style={{ width: '18%' }}>Path</th>
                                <th className='text-center' style={{ width: '32%' }}>Activity</th>
                                <th className='text-center' style={{ width: '15%' }}>Performed By</th>
                            </tr>
                            </thead>
                            <tbody>
                            <>
                                {lists.length > 0 ? (
                                    lists.map((item, index) =>
                                        <tr className='mb-2' key={index}>
                                            <td className='text-center'>{from + index}</td>
                                            <td className='text-center'>{moment(new Date(item.created_at ?? "")).format(dateFormat + " " + timeFormat)}</td>
                                            <td>{handleDisplayModule(item.module_name, item.activity, item.module_id)}</td>
                                            <td>{handleDisplayPath(item.menu_url, item.activity, item.module_id)}</td>
                                            <td onClick={() => handleShowActivity(item)} className="clickable-activity">{item.status == 1 ? <CheckIcon/> : <ErrorIcon/>} <span className="clickable-activity-label">{item.activity}</span></td>
                                            {/*<td className='text-center'></td>*/}
                                            <td className={"text-center"}>{handleDisplayPerformedBy(item.created_by_first_name, item.created_by_last_name, item.created_by)}</td>
                                            {/*<td className='text-center'>*/}
                                            {/*    <Button*/}
                                            {/*        className=' p-1 pt-0 pb-0'*/}
                                            {/*        style={{ fontSize: '12px' }}*/}
                                            {/*        variant=' btn-transparent'*/}
                                            {/*        size='sm'*/}
                                            {/*    >*/}
                                            {/*        <View/>*/}
                                            {/*    </Button>*/}
                                            {/*</td>*/}
                                        </tr>
                                    )
                                ) : (
                                    <tr className='no-item'>
                                        <td colSpan={6}>{TEXT.NOT_FOUND}</td>
                                    </tr>
                                )}
                            </>
                            </tbody>
                        </Table>
                        <Row>
                            <Col sm={4}>
                                <Row>
                                    <Col sm={7}>
                                        <Pagination
                                            itemsCount={itemsCount}
                                            itemsPerPage={itemsPerPage}
                                            currentPage={currentPage}
                                            lastPage={lastPage}
                                            setCurrentPage={setCurrentPage}
                                            alwaysShown={false}
                                            pageClicked={(page: number) => {
                                                getActivityLog(page, selectedEmployeeNo, selectedFromDate, selectedToDate, entries, searched);
                                            }
                                            }
                                        />
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Group >
                                            {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                                        </Form.Group>
                                        <Form.Group className="right-align" >
                                            <Form.Select
                                                size={'sm'}
                                                name="entries"
                                                value={entries}
                                                onChange={handleChangeEntries}
                                                style={{width: '80px'}}
                                                // onBlur={handleBlur}
                                                // isInvalid={touched.work_shift_id && !!errors.work_shift_id}
                                            >
                                                <option value="10" selected>10</option>
                                                <option value="25" >25</option>
                                                <option value="50" >50</option>
                                                <option value="100" >100</option>

                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Col>
                            <Col sm={10} className=''>

                            </Col>
                        </Row>

                        <Modal className='modal' centered show={showActivityParameters} onHide={handleCloseActivityParameters}>
                            <Modal.Header closeButton>
                                <Modal.Title className='fw-bold'>More Activity Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className={'mb-2'}>
                                    <Col sm={5}>Date:</Col>
                                    <Col sm={7}>{moment(new Date(item.created_at ?? "")).format(dateFormat + " " + timeFormat)}</Col>
                                </Row>
                                <Row className={'mb-2'}>
                                    <Col sm={5}>Performed By:</Col>
                                    <Col sm={7}>{item.created_by_first_name} {item.created_by_last_name}</Col>
                                </Row>
                                <Row className={'mb-2'}>
                                    <Col sm={5}>Module Name:</Col>
                                    <Col sm={7}>{item.module_name}</Col>
                                </Row>
                                <Row className={'mb-2'}>
                                    <Col sm={5}>Activity:</Col>
                                    <Col sm={7}>{item.activity}</Col>
                                </Row>
                                {
                                    isValidJson(item.parameters) ?
                                        Object.entries(JSON.parse(item.parameters)).map(([key, value]) => (
                                            <Row key={key} className={'mb-2'}>
                                                <Col sm={5}>{key}:</Col>
                                                <Col sm={7}>{renderValue(key, value)}</Col>
                                            </Row>
                                        ))
                                        : ""
                                }

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseActivityParameters}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        {/*<Dialog*/}
                        {/*    show={showConfirm}*/}
                        {/*    setShow={handleCloseConfirm}*/}
                        {/*    setEvent={() => {*/}
                        {/*        deleteNotice(item.notice_id)*/}
                        {/*    }*/}
                        {/*    }*/}
                        {/*/>*/}

                        <Loader show={showLoading}/>
                    </Card.Body>
                </Card>



            </Container>
        </>
    )
}
