import { lazy, Suspense, useState } from 'react'
import { Container, Breadcrumb } from 'react-bootstrap'
import './style.css'
import TabList from 'components/TabList'
import ProgressLoading from 'components/ProgressLoading'
const Personal = lazy(() => import('./Personal'))
const Company = lazy(() => import('./Company'))
const Shift = lazy(() => import('./Shift'))
const Education = lazy(() => import('./Education'))
const Work = lazy(() => import('./Work'))
const Preview = lazy(() => import('./Preview'))
const Government = lazy(() => import('./Government'))
const Salary = lazy(() => import('./Salary'))

export default function Index() {
  const renderComponent = (comp: JSX.Element) => (
    <Suspense fallback={<ProgressLoading />}>{comp}</Suspense>
  )

  const [currentTab, setCurrentTab] = useState(0)
  return (
    <Container fluid>
      <h5 className='fw-bold'>Add Employee</h5>
      <Breadcrumb className='breadcrumbs'>
        <Breadcrumb.Item className='text-decoration-none' href='/home'>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item className='text-decoration-none' href='#'>
          Employees
        </Breadcrumb.Item>
        <Breadcrumb.Item href='/employeeManagement/employee-list'>Employee List</Breadcrumb.Item>
        <Breadcrumb.Item active>Add Employee</Breadcrumb.Item>
      </Breadcrumb>

      <TabList
        lists={[
          {
            label: 'Personal',
            component: renderComponent(<Personal setCurrentTab={setCurrentTab} />),
            disabled: !(currentTab === 0),
          },
          {
            label: 'Company',
            component: renderComponent(<Company setCurrentTab={setCurrentTab} />),
            disabled: !(currentTab === 1),
          },
          {
            label: 'Salary',
            component: renderComponent(<Salary setCurrentTab={setCurrentTab} />),
            disabled: !(currentTab === 2),
          },
          {
            label: 'Shift',
            component: renderComponent(<Shift setCurrentTab={setCurrentTab} />),
            disabled: !(currentTab === 3),
          },
          {
            label: 'Education',
            component: renderComponent(<Education setCurrentTab={setCurrentTab} />),
            disabled: !(currentTab === 4),
          },
          {
            label: 'Work',
            component: renderComponent(<Work setCurrentTab={setCurrentTab} />),
            disabled: !(currentTab === 5),
          },
          {
            label: 'Government',
            component: renderComponent(<Government setCurrentTab={setCurrentTab} />),
            disabled: !(currentTab === 6),
          },
          {
            label: 'Preview',
            component: renderComponent(<Preview setCurrentTab={setCurrentTab} />),
            disabled: !(currentTab === 7),
          },
        ]}
        currentTab={currentTab}
      />
      <br/>
    </Container>
  )
}
