import React, {useEffect, useState} from 'react'
import {
  Button,
  Card,
  Col, 
  Modal,
  Row,
  Stack,
  Table
} from "react-bootstrap";
import Accept from 'asset/SvgComponent/Accept'
import Reject from 'asset/SvgComponent/Reject'
import View from 'asset/SvgComponent/View'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE
} from 'utils/globalConstant'
import {
  IList,
  ILeaveRequest,
  IItem,
  IRequest,
  IItemRequestOvertime,
  IItemRequestCertificate,
  IItemRequestOfficial
} from './types'
import {Avatar} from "@mui/material";
import moment from "moment"
import {IItemRequest} from "../../../../Attendance/OfficialBusiness/Construction/types";


export default function Index() {
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const [itemsCount, setItemCount] = React.useState(0)
  const [lists, setData] = React.useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    leave_application_id: 0,
    application_from_date: '',
    application_to_date: '',
    application_date: '',
    number_of_day: '',
    purpose: '',
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    leave_type: {
      leave_type_id: 0,
      leave_type_name: ''
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      }
    }],
    request_type: ''
  })
  const [itemRequestOfficial, setItemRequestOfficial] = useState<IItemRequestOfficial>({
    official_business_id: 0,
    employee_id: 0,
    date_from: '',
    date_to: '',
    time_from: '',
    time_to: '',
    clock_in_pm: '',
    clock_out_pm: '',
    location: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      }
    }],
    request_type: ''
  })

  const [itemRequestOvertime, setItemRequestOvertime] = useState<IItemRequestOvertime>({
    id: 0,
    employee_id: 0,
    classification: '',
    overtime_date: '',
    overtime_start: '',
    overtime_end: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      }
    }],
    created_by_last_name: "",
    created_by_first_name: "",
    request_type: ''
  })

  const [itemRequestCertificate, setItemRequestCertificate] = useState<IItemRequestCertificate>({
    id: 0,
    employee_id: 0,
    type: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    requested_attendance: [{
      id: 0,
      certificate_of_attendance_id: 0,
      time_in_out: '',
      time_in_out_date: '',
      time_in_out_time: ''
    }],
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      }
    }],
    request_type: ''
  })

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [showOfficial, setShowOfficial] = useState(false)
  const handleCloseOfficial = () => setShowOfficial(false)
  const handleShowOfficial = () => setShowOfficial(true)

  const [showOvertime, setShowOvertime] = useState(false)
  const handleCloseOvertime = () => setShowOvertime(false)
  const handleShowOvertime = () => setShowOvertime(true)

  const [showCertificate, setShowCertificate] = useState(false)
  const handleCloseCertificate = () => setShowCertificate(false)
  const handleShowCertificate = () => setShowCertificate(true)

  const [showAccept, setShowAccept] = useState(false)
  const handleCloseAccept = () => setShowAccept(false)
  const handleShowAccept = (id: any, type: string) => {
    setShowAccept(true)
    setItem({
      leave_application_id: id,
      application_from_date: '',
      application_to_date: '',
      application_date: '',
      number_of_day: '',
      purpose: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      leave_type: {
        leave_type_id: 0,
        leave_type_name: ''
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        }
      }],
      request_type: type
    })
  }

  const [showReject, setShowReject] = useState(false)
  const handleCloseReject = () => setShowReject(false)
  const handleShowReject = (id: any, type: string) => {
    setShowReject(true)
    setItem({
      leave_application_id: id,
      application_from_date: '',
      application_to_date: '',
      application_date: '',
      number_of_day: '',
      purpose: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      leave_type: {
        leave_type_id: 0,
        leave_type_name: ''
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        }
      }],
      request_type: type
    })
  }
  const [dateFormat, setDateFormat] = useState('')

  const getLeaveRequest = async () => {
    try {
      const response = await axios.get<IAPI_Response<IRequest>>(API_ENDPOINT.GET_ALL_REQUEST)
      const result = await response
      if (result?.data?.results) {
        setData(result?.data?.results?.data)
        setItemCount(result?.data?.results?.total)
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showRequest = async (id: null | number, type: string) => {
    try {
      if(type == "leave"){
        const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_LEAVE_REQUEST +'/'+ id)
        const result = await response
        if (result?.data?.results) {
          setItem(result?.data?.results)
        }
        handleShow()
      }else if(type == "ob"){
        const response = await axios.get<IAPI_Response<IItemRequestOfficial>>(API_ENDPOINT.SHOW_OFFICIAL_REQUEST +'/'+ id)
        const result = await response
        if (result?.data?.results) {
          setItemRequestOfficial(result?.data?.results)
        }
        handleShowOfficial()
      }else if(type == "overtime"){
        const response = await axios.get<IAPI_Response<IItemRequestOvertime>>(API_ENDPOINT.SHOW_OVERTIME_REQUEST +'/'+ id)
        const result = await response
        if (result?.data?.results) {
          setItemRequestOvertime(result?.data?.results)
        }
        handleShowOvertime()
      }else if(type == "coa"){
        const response = await axios.get<IAPI_Response<IItemRequestCertificate>>(API_ENDPOINT.SHOW_CERTIFICATE_REQUEST +'/'+ id)
        const result = await response
        if (result?.data?.results) {
          setItemRequestCertificate(result?.data?.results)
        }
        handleShowCertificate()
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const acceptRequest = async (id: null | number, type: string) => {
    try{
      if(type == "leave"){
        await axios.put(API_ENDPOINT.ACCEPT_LEAVE_REQUEST +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.LEAVE_APPROVAL.ACCEPTED)
            getLeaveRequest();
          }
          handleCloseAccept();
          handleClose();
        });
      }else if(type == "coa"){
        await axios.put(API_ENDPOINT.ACCEPT_CERTIFICATE_REQUEST +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.CERTIFICATE.ACCEPTED)
            getLeaveRequest();
          }
          handleCloseAccept();
          handleCloseCertificate();
        });
      }else if(type == "overtime"){
        await axios.put(API_ENDPOINT.ACCEPT_OVERTIME_REQUEST +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.OVERTIME.ACCEPTED)
            getLeaveRequest();
          }
          handleCloseAccept();
          handleCloseOvertime();
        });
      }else if(type == "ob"){
        await axios.put(API_ENDPOINT.ACCEPT_OFFICIAL_REQUEST +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.OFFICIAL.ACCEPTED)
            getLeaveRequest();
          }
          handleCloseAccept();
          handleCloseOfficial();
        });
      }

    }catch{
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const rejectRequest = async (id: null | number, type: string) => {
    try{
      if(type == "leave"){
        await axios.put(API_ENDPOINT.REJECT_LEAVE_REQUEST +'/'+ id, {
        }).then(response => {
           if (response?.data?.status * 1 === 1) {
              setShowSuccess(true)
              setSuccess(SUCCESS_MESSAGE.LEAVE_APPROVAL.REJECTED)
              getLeaveRequest();
           }
           handleCloseReject();
           handleClose();
        });
      }else if(type == "coa"){
        await axios.put(API_ENDPOINT.REJECT_CERTIFICATE_REQUEST +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.CERTIFICATE.REJECTED)
            getLeaveRequest();
          }
          handleCloseReject();
          handleCloseCertificate();
        });
      }else if(type == "ob"){
        await axios.put(API_ENDPOINT.REJECT_OFFICIAL_REQUEST +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.OFFICIAL.REJECTED)
            getLeaveRequest();
          }
          handleCloseReject();
          handleCloseOfficial();
        });
      }else if(type == "overtime"){
        await axios.put(API_ENDPOINT.REJECT_OVERTIME_REQUEST +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.OVERTIME.REJECTED)
            getLeaveRequest();
          }
          handleCloseReject();
          handleCloseOvertime();
        });
      }

    }catch{
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const handleNavigateLeaveRequests = () => {
    window.location.replace('/leaves/leave-approval')
  }

  useEffect(() => {
    ;(async () => {
      getLeaveRequest()
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Alert 
        type="success" 
        show={showSuccess} 
        message={success} 
        setShow={() => { 
            setShowSuccess(false);
          }
        }
      />

      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />

      <Card className="border-0 mb-3 p-0 bordered-card " style={{overflowY: 'hidden', height: '311px'}}>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <span className="fw-bold">Action Center</span>
            </Col>
          </Row>

          <Table responsive size={"sm"} className="bordered-tabs-top mt-2" style={{whiteSpace: 'nowrap'}}>
            <thead className="thead-table">
              <tr className="text-center">
                <th style={{width: '5%'}}>No.</th>
                <th>Employee</th>
                <th>Request Type</th>
                <th style={{width: '15%'}}>Reason</th>
                <th>Date Applied</th>
                <th>Applied By</th>
                <th style={{width: '8%'}}>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <>
                {
                  lists.length > 0 ?
                    lists.map((item, index) =>
                      <tr className="mb-2" key={item.id}>
                        <td>{index + 1}</td>
                        <td className="fw-bold">
                          <Stack direction='horizontal'>
                            <div>
                              {
                                item.photo != "" ?
                                    <Avatar
                                        sx={{ width: 20, height: 20 }}
                                        src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                    ></Avatar> :
                                    <Avatar
                                        sx={{ width: 20, height: 20}}
                                        src={""}
                                    ></Avatar>
                              }
                            </div>
                            <div>&nbsp; {item.last_name}, {item.first_name}</div>
                          </Stack>
                        </td>
                        <td>{
                          item.request_type == "leave" ? "Leave" :
                              item.request_type == "overtime" ? "Overtime" :
                                  item.request_type == "coa" ? "Certificate of Attendance" :
                                      item.request_type == "ob" ? "Official Business" : ""
                        }</td>
                        <td className="text-left">{item.reason}</td>
                        <td className="text-center">{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                        <td>{item.created_by_first_name} {item.created_by_last_name}</td>
                        <td>
                          <div className="d-grid">
                          {(() => {
                                // Approved:3
                                if (item.status && item.status == 3) {
                                  return (
                                    <Button
                                      className=" p-1 pt-0 pb-0"
                                      style={{fontSize: '12px', color: 'white'}}
                                      variant="success"
                                      size="sm"
                                      >
                                      Approved
                                    </Button>
                                  )
                                // Reject:2
                                } else if(item.status && item.status == 2) {
                                  return (
                                    <Button
                                      className=" p-1 pt-0 pb-0"
                                      style={{fontSize: '12px', color: 'white'}}
                                      variant="danger"
                                      size="sm"
                                      >
                                      Disapproved
                                    </Button>
                                  )
                                }

                                return (
                                  <Button
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="secondary"
                                    size="sm"
                                    >
                                    Pending
                                  </Button>
                                )

                              })()}
                          </div>
                        </td>
                        <td className='text-center'>
                          <Button
                            onClick={() => showRequest(item.id, item.request_type)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant='btn-transparent'
                            size='sm'
                            >
                            <View/>
                          </Button>
                          <Button
                              onClick={() => handleShowAccept(item.id, item.request_type)}
                              className='p-1 pt-0 pb-0'
                              style={{ fontSize: '12px', color: 'white' }}
                              variant='btn-transparent'
                              size='sm'
                          >
                            <Accept/>
                          </Button>
                          <Button
                            onClick={() => handleShowReject(item.id, item.request_type)}
                            className='p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant='btn-transparent'
                            size='sm'
                            >
                            <Reject/>
                          </Button>
                        </td>
                      </tr>
                    ) :
                  (
                    <tr>
                      <td className="text-center" colSpan={8}>No Data Available.</td>
                    </tr>
                  )
                }
              </>
            </tbody>
          </Table>
          {/*{itemsCount > 4 ? */}
          {/*  (*/}
          {/*  <div className="text-center mt-2">*/}
          {/*    <h6 className="fw-bold text-muted text-truncate" style={{fontSize: '12px'}} onClick={handleNavigateLeaveRequests}>*/}
          {/*      View All({itemsCount}) Leave Requests*/}
          {/*    </h6>*/}
          {/*  </div>*/}
          {/*  ) : ''*/}
          {/*}*/}

          <Dialog 
              show={showAccept} 
              setShow={handleCloseAccept}
              setEvent={() => { 
                  acceptRequest(item.leave_application_id, item.request_type)
                }
              }
              variant='accept'
              message='Please confirm to accept leave request?'
            />

            <Dialog 
              show={showReject} 
              setShow={handleCloseReject}
              setEvent={() => { 
                  rejectRequest(item.leave_application_id, item.request_type)
                }
              }
              variant='reject'
              message='Do you really want to reject these leave request?'
            />
        </Card.Body>
      </Card>

      {/* DETAIL MODAL*/}
      <Modal className='modal' centered size={'lg'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>
            <Stack direction='horizontal'>
              <div>
                {
                  item.employee.photo != "" ?
                      <Avatar
                          sx={{ width: 65, height: 65 }}
                          src={process.env.REACT_APP_IMAGE_URL+ item.employee.photo}
                      ></Avatar> :
                      <Avatar
                          sx={{ width: 65, height: 65}}
                          src={""}
                      ></Avatar>
                }
              </div>
              <div className='mx-3'>
                &nbsp;{item.employee?.first_name} {item.employee?.last_name}
                <br />
                &nbsp;<span className='text-muted fw-light'>{item.employee?.user_name?.role?.role_name}</span>
              </div>
            </Stack>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={7}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                  <tr>
                    <td className='fw-bold' width={120}>
                      Leave Type:
                    </td>
                    <td className='fw-bold'>{item.leave_type?.leave_type_name}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold' width={120}>
                      Applied On:
                    </td>
                    <td className='fw-bold'>{item.application_date}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold' width={120}>
                      No. of Days:
                    </td>
                    <td className='fw-bold'>{item.number_of_day} days</td>
                  </tr>
                  <tr>
                    <td className='fw-bold' width={120}>
                      Date of Leave:
                    </td>
                    <td className='fw-bold'>{item.application_from_date} - {item.application_to_date}</td>
                  </tr>
                  <tr>
                    <td className='fw-bold' width={120}>
                      Reason:
                    </td>
                    <td className='fw-bold'>{item.purpose}</td>
                  </tr>
                </tbody>
              </Table>    
            </Col>
            <Col
              sm={5}
              style={{
                borderLeft: 'solid 1px #A7A9AC',
              }}
              >
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                  {item.request_status.length > 0 ? (
                    item.request_status.map((item: any, index: any) =>
                      <div key={index}>
                        <tr>
                          <td className='fw-bold' width={180}>
                            Approver's {index+1} Name:
                          </td>
                          <td>{item.employee?.first_name} {item.employee?.last_name}</td>
                        </tr>
                        <tr>
                          <td className='fw-bold' width={180}>
                            Approver's Role:
                          </td>
                          <td>{item.employee?.user_name?.role?.role_name}</td>
                        </tr>
                        <>
                          {(() => {
                            // Approved:2
                            if (item.status == '3') {
                              return (
                                <>
                                  <tr>
                                    <td className='fw-bold' width={180}>
                                      Date Approved/Rejected:
                                    </td>
                                    <td>{item.approve_date}</td>
                                  </tr>
                                  <tr>
                                    <td className='fw-bold' width={180}>
                                      Status:
                                    </td>
                                    <td className='fw-bold'>
                                      <Button size={'sm'} className=' pe-1 px-1 text-white' variant={'success'}>
                                        Approved
                                      </Button>
                                    </td>
                                  </tr>
                                </>
                              )
                            // Reject:3
                            } else if(item.status == '2') {
                              return (
                                <>
                                  <tr>
                                    <td className='fw-bold' width={180}>
                                      Date Approved/Rejected:
                                    </td>
                                    <td>{item.reject_date}</td>
                                  </tr>
                                  <tr>
                                    <td className='fw-bold' width={180}>
                                      Status:
                                    </td>
                                    <td className='fw-bold'>
                                      <Button size={'sm'} className=' pe-1 px-1 text-white' variant={'danger'}>
                                        Disapproved
                                      </Button>
                                    </td>
                                  </tr>
                                </>
                              )
                            } 

                            return (
                              <>
                                <tr>
                                  <td className='fw-bold' width={180}>
                                    Status:
                                  </td>
                                  <td className='fw-bold'>
                                    <Button size={'sm'} className=' pe-1 px-1 text-white' variant={'secondary'}>
                                      Pending
                                    </Button>
                                  </td>
                                </tr>
                              </>
                            )
                          })()}
                        </>
                        <br/>
                      </div>
                    )
                    ) : (
                      <></>
                    )
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => rejectRequest(item.leave_application_id, 'leave')}
            variant='outline-primary' 
            className=' px-5 pe-5 mx-2'  
            size={'sm'}
            >
            Rejected
          </Button>
          <Button 
            onClick={() => acceptRequest(item.leave_application_id, 'leave')}
            variant='primary' 
            className='text-white px-5 pe-5' 
            size={'sm'}
            >
            Approved
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DETAIL MODAL ON OFFICIAL BUSINESS*/}
      <Modal className='modal' centered size={'lg'} show={showOfficial} onHide={handleCloseOfficial}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>
            <Stack direction='horizontal'>
              <div>
                {
                  itemRequestOfficial.employee.photo != "" ?
                      <Avatar
                          sx={{ width: 65, height: 65 }}
                          src={process.env.REACT_APP_IMAGE_URL+ itemRequestOfficial.employee.photo}
                      ></Avatar> :
                      <Avatar
                          sx={{ width: 65, height: 65}}
                          src={""}
                      ></Avatar>
                }
              </div>
              <div className='mx-3'>
                &nbsp;{itemRequestOfficial.employee?.first_name} {itemRequestOfficial.employee?.last_name}
                <br />
                &nbsp;<span className='text-muted fw-light'>{itemRequestOfficial.employee?.user_name?.role?.role_name}</span>
              </div>
            </Stack>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr>
                  <td className='fw-bold' width={200}>
                    Applied On:
                  </td>
                  <td className='fw-bold'>{itemRequestOfficial.application_date}</td>
                </tr>
                <tr>
                  <td className='fw-bold' width={200}>
                    Date of Official Business:
                  </td>
                  <td className='fw-bold'>{moment(new Date(itemRequestOfficial.date_from ?? "")).format(dateFormat)} - {moment(new Date(itemRequestOfficial.date_to ?? "")).format(dateFormat)}</td>
                </tr>
                <tr>
                  <td className='fw-bold' width={200}>
                    Hours of Official Business:
                  </td>
                  <td className='fw-bold'>{itemRequestOfficial.time_from} - {itemRequestOfficial.time_to}
                    {
                      <>{itemRequestOfficial.clock_in_pm ? <br/>: ""}</>
                    }
                    {itemRequestOfficial.clock_in_pm} - {itemRequestOfficial.clock_out_pm}
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold' width={200}>
                    Location:
                  </td>
                  <td className='fw-bold'>{itemRequestOfficial.location}</td>
                </tr>
                <tr>
                  <td className='fw-bold' width={200}>
                    Reason:
                  </td>
                  <td className='fw-bold'>{itemRequestOfficial.reason}</td>
                </tr>
                </tbody>
              </Table>
            </Col>
            <Col
                sm={6}
                style={{
                  borderLeft: 'solid 1px #A7A9AC',
                }}
            >
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                {itemRequestOfficial.request_status.length > 0 ? (
                    itemRequestOfficial.request_status.map((item: any, index: any) =>
                        <div key={index}>
                          <tr>
                            <td className='fw-bold' width={180}>
                              Approver's {index+1} Name:
                            </td>
                            <td>{item.employee?.first_name} {item.employee?.last_name}</td>
                          </tr>
                          <tr>
                            <td className='fw-bold' width={180}>
                              Approver's Role:
                            </td>
                            <td>{item.employee?.user_name?.role?.role_name}</td>
                          </tr>
                          <>
                            {(() => {
                              // Approved:2
                              if (item.status == '3') {
                                return (
                                    <>
                                      <tr>
                                        <td className='fw-bold' width={180}>
                                          Date Approved/Rejected:
                                        </td>
                                        <td>{moment(new Date(item.approve_date ?? "")).format(dateFormat)}</td>
                                      </tr>
                                      <tr>
                                        <td className='fw-bold' width={180}>
                                          Status:
                                        </td>
                                        <td className='fw-bold'>
                                          <Button size={'sm'} className=' pe-1 px-1 text-white pt-0 pb-0' variant={'success'}>
                                            Approved
                                          </Button>
                                        </td>
                                      </tr>
                                    </>
                                )
                                // Reject:3
                              } else if(item.status == '2') {
                                return (
                                    <>
                                      <tr>
                                        <td className='fw-bold' width={180}>
                                          Date Approved/Rejected:
                                        </td>
                                        <td>{item.reject_date}</td>
                                      </tr>
                                      <tr>
                                        <td className='fw-bold' width={180}>
                                          Status:
                                        </td>
                                        <td className='fw-bold'>
                                          <Button size={'sm'} className=' pe-1 px-1 text-white pt-0 pb-0' variant={'danger'}>
                                            Disapproved
                                          </Button>
                                        </td>
                                      </tr>
                                    </>
                                )
                              }

                              return (
                                  <>
                                    <tr>
                                      <td className='fw-bold' width={180}>
                                        Status:
                                      </td>
                                      <td className='fw-bold'>
                                        <Button size={'sm'} className='pe-1 px-1 text-white pt-0 pb-0' variant={'secondary'}>
                                          Pending
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                              )
                            })()}
                          </>
                          <br/>
                        </div>
                    )
                ) : (
                    <></>
                )
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={() => rejectRequest(itemRequestOfficial.official_business_id, "ob")}
              variant='outline-primary'
              className=' px-5 pe-5 mx-2'
              size={'sm'}
              disabled={itemRequestOfficial.status == '3' ? true: false}
          >
            Rejected
          </Button>
          <Button
              onClick={() => acceptRequest(itemRequestOfficial.official_business_id, "ob")}
              variant='primary'
              className='text-white px-5 pe-5'
              size={'sm'}
              disabled={itemRequestOfficial.status == '3' ? true: false}
          >
            Approved
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DETAIL MODAL FOR OVERTIME*/}
      <Modal className='modal' centered size={'lg'} show={showOvertime} onHide={handleCloseOvertime}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>
            <Stack direction='horizontal'>
              <div>
                {
                  itemRequestOvertime.employee.photo != "" ?
                      <Avatar
                          sx={{ width: 65, height: 65 }}
                          src={process.env.REACT_APP_IMAGE_URL+ itemRequestOvertime.employee.photo}
                      ></Avatar> :
                      <Avatar
                          sx={{ width: 65, height: 65}}
                          src={""}
                      ></Avatar>
                }
              </div>
              <div className='mx-3'>
                &nbsp;{itemRequestOvertime.employee?.first_name} {itemRequestOvertime.employee?.last_name}
                <br />
                &nbsp;<span className='text-muted fw-light'>{itemRequestOvertime.employee?.user_name?.role?.role_name}</span>
              </div>
            </Stack>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr>
                  <td className='fw-bold' width={120}>
                    Applied By:
                  </td>
                  <td className='fw-bold'>{itemRequestOvertime.created_by_first_name} {itemRequestOvertime.created_by_last_name}</td>
                </tr>
                <tr>
                  <td className='fw-bold' width={120}>
                    Applied On:
                  </td>
                  <td className='fw-bold'>{itemRequestOvertime.application_date}</td>
                </tr>
                <tr>
                  <td className='fw-bold' width={120}>
                    Classification
                  </td>
                  <td className='fw-bold'>{itemRequestOvertime.classification}</td>
                </tr>
                <tr>
                  <td className='fw-bold' width={120}>
                    Overtime In:
                  </td>
                  <td className='fw-bold'>{itemRequestOvertime.overtime_start} </td>
                </tr>
                <tr>
                  <td className='fw-bold' width={120}>
                    Overtime Out:
                  </td>
                  <td className='fw-bold'>{itemRequestOvertime.overtime_end} </td>
                </tr>
                <tr>
                  <td className='fw-bold' width={120}>
                    Reason:
                  </td>
                  <td className='fw-bold'>{itemRequestOvertime.reason}</td>
                </tr>
                </tbody>
              </Table>
            </Col>
            <Col
                sm={6}
                style={{
                  borderLeft: 'solid 1px #A7A9AC',
                }}
            >
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                {itemRequestOvertime.request_status.length > 0 ? (
                    itemRequestOvertime.request_status.map((item: any, index: any) =>
                        <div key={index}>
                          <tr>
                            <td className='fw-bold' width={180}>
                              Approver's {index+1} Name:
                            </td>
                            <td>{item.employee?.first_name} {item.employee?.last_name}</td>
                          </tr>
                          <tr>
                            <td className='fw-bold' width={180}>
                              Approver's Role:
                            </td>
                            <td>{item.employee?.user_name?.role?.role_name}</td>
                          </tr>
                          <>
                            {(() => {
                              // Approved:2
                              if (item.status == '3') {
                                return (
                                    <>
                                      <tr>
                                        <td className='fw-bold' width={180}>
                                          Date Approved/Rejected:
                                        </td>
                                        <td>{item.approve_date}</td>
                                      </tr>
                                      <tr>
                                        <td className='fw-bold' width={180}>
                                          Status:
                                        </td>
                                        <td className='fw-bold'>
                                          <Button size={'sm'} style={{fontSize:'12px'}} className=' pe-1 px-1 pt-0 pb-0 text-white' variant={'success'}>
                                            Approved
                                          </Button>
                                        </td>
                                      </tr>
                                    </>
                                )
                                // Reject:3
                              } else if(item.status == '2') {
                                return (
                                    <>
                                      <tr>
                                        <td className='fw-bold' width={180}>
                                          Date Approved/Rejected:
                                        </td>
                                        <td>{item.reject_date}</td>
                                      </tr>
                                      <tr>
                                        <td className='fw-bold' width={180}>
                                          Status:
                                        </td>
                                        <td className='fw-bold'>
                                          <Button size={'sm'} style={{fontSize:'12px'}} className=' pe-1 px-1 pt-0 pb-0 text-white' variant={'danger'}>
                                            Disapproved
                                          </Button>
                                        </td>
                                      </tr>
                                    </>
                                )
                              }

                              return (
                                  <>
                                    <tr>
                                      <td className='fw-bold' width={180}>
                                        Status:
                                      </td>
                                      <td className='fw-bold'>
                                        <Button size={'sm'} style={{fontSize:'12px'}} className=' pe-1 px-1 pt-0 pb-0 text-white' variant={'secondary'}>
                                          Pending
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                              )
                            })()}
                          </>
                          <br/>
                        </div>
                    )
                ) : (
                    <></>
                )
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={() => rejectRequest(itemRequestOvertime.id, "overtime")}
              variant='outline-primary'
              className=' px-5 pe-5 mx-2'
              size={'sm'}
              disabled={itemRequestOvertime.status == '3' ? true: false}
          >
            Rejected
          </Button>
          <Button
              onClick={() => acceptRequest(itemRequestOvertime.id, "overtime")}
              variant='primary'
              className='text-white px-5 pe-5'
              size={'sm'}
              disabled={itemRequestOvertime.status == '3' ? true: false}
          >
            Approved
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DETAIL MODAL ON CERTIFICATE OF ATTENDANCE*/}
      <Modal className='modal' centered size={'lg'} show={showCertificate} onHide={handleCloseCertificate}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>
            <Stack direction='horizontal'>
              <div>
                {
                  itemRequestCertificate.employee.photo != "" ?
                      <Avatar
                          sx={{ width: 65, height: 65 }}
                          src={process.env.REACT_APP_IMAGE_URL+ itemRequestCertificate.employee.photo}
                      ></Avatar> :
                      <Avatar
                          sx={{ width: 65, height: 65}}
                          src={""}
                      ></Avatar>
                }
              </div>
              <div className='mx-3'>
                &nbsp;{itemRequestCertificate.employee?.first_name} {itemRequestCertificate.employee?.last_name}
                <br />
                &nbsp;<span className='text-muted fw-light'>{itemRequestCertificate.employee?.user_name?.role?.role_name}</span>
              </div>
            </Stack>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr>
                  <td className='fw-bold' width={90}>
                    Applied On:
                  </td>
                  <td className='fw-bold'>{itemRequestCertificate.application_date}</td>
                </tr>
                <tr>
                  <td className='fw-bold' width={90}>
                    Type:
                  </td>
                  <td className='fw-bold'>{itemRequestCertificate.type}</td>
                </tr>
                <tr>
                  <td className='fw-bold' width={90}>
                    Reason:
                  </td>
                  <td className='fw-bold'>{itemRequestCertificate.reason}</td>
                </tr>
                <tr>
                  <td className='fw-bold' width={90}>
                    Request
                  </td>
                </tr>
                </tbody>
              </Table>

              <Table borderless className='mt-0 mb-0'>
                <tbody>
                {itemRequestCertificate?.requested_attendance.length > 0 ? (
                    itemRequestCertificate?.requested_attendance.map((item, index) =>
                        <div key={index} style={{ paddingLeft: '8px' }}>
                          <tr>
                            <td className='fw-bold' width={90}>
                              Type:
                            </td>
                            <td>{item.time_in_out == "clock_in_am"? "Clock In AM" :
                                item.time_in_out == "clock_out_am"? "Clock Out AM" :
                                    item.time_in_out == "clock_in_pm"? "Clock In PM" :
                                        "Clock Out PM"}</td>
                          </tr>
                          <tr>
                            <td className='fw-bold' width={90}>
                              Date:
                            </td>
                            <td>{item.time_in_out_date}</td>
                          </tr>
                          <tr>
                            <td className='fw-bold' width={90}>
                              Time:
                            </td>
                            <td>{item.time_in_out_time}</td>
                          </tr>
                          <br/>
                        </div>
                    )
                ) : (
                    <></>
                )}
                </tbody>
              </Table>
            </Col>
            <Col
                sm={6}
                style={{
                  borderLeft: 'solid 1px #A7A9AC',
                }}
            >
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                {itemRequestCertificate.request_status.length > 0 ? (
                    itemRequestCertificate.request_status.map((item: any, index: any) =>
                        <div key={index}>
                          <tr>
                            <td className='fw-bold' width={150}>
                              Approver's {index+1} Name:
                            </td>
                            <td>{item.employee?.first_name} {item.employee?.last_name}</td>
                          </tr>
                          <tr>
                            <td className='fw-bold' width={150}>
                              Approver's Role:
                            </td>
                            <td>{item.employee?.user_name?.role?.role_name}</td>
                          </tr>
                          <>
                            {(() => {
                              // Approved:2
                              if (item.status == '3') {
                                return (
                                    <>
                                      <tr>
                                        <td className='fw-bold' width={150}>
                                          Date Approved/Rejected:
                                        </td>
                                        <td>{moment(new Date(item.approve_date ?? "")).format(dateFormat)}</td>
                                      </tr>
                                      <tr>
                                        <td className='fw-bold' width={150}>
                                          Status:
                                        </td>
                                        <td className='fw-bold'>
                                          <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                            Approved
                                          </Button>
                                        </td>
                                      </tr>
                                    </>
                                )
                                // Reject:3
                              } else if(item.status == '2') {
                                return (
                                    <>
                                      <tr>
                                        <td className='fw-bold' width={150}>
                                          Date Approved/Rejected:
                                        </td>
                                        <td>{item.reject_date}</td>
                                      </tr>
                                      <tr>
                                        <td className='fw-bold' width={150}>
                                          Status:
                                        </td>
                                        <td className='fw-bold'>
                                          <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                            Disapproved
                                          </Button>
                                        </td>
                                      </tr>
                                    </>
                                )
                              }

                              return (
                                  <>
                                    <tr>
                                      <td className='fw-bold' width={150}>
                                        Status:
                                      </td>
                                      <td className='fw-bold'>
                                        <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                          Pending
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                              )
                            })()}
                          </>
                          <br/>
                        </div>
                    )
                ) : (
                    <></>
                )
                }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={() => rejectRequest(itemRequestCertificate.id, 'coa')}
              variant='outline-primary'
              className=' px-5 pe-5 mx-2'
              size={'sm'}
              disabled={itemRequestCertificate.status == '3' ? true: false}
          >
            Rejected
          </Button>
          <Button
              onClick={() => acceptRequest(itemRequestCertificate.id, 'coa')}
              variant='primary'
              className='text-white px-5 pe-5'
              size={'sm'}
              disabled={itemRequestCertificate.status == '3' ? true: false}
          >
            Approved
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}