import React, { useEffect, useState } from 'react'
import {Breadcrumb, Button, Card, Col, Container, Form, Modal, Row, Table} from 'react-bootstrap'
import Stack from "@mui/material/Stack";
import {IRole} from "../../../EmployeeManagement/EmployeeAdd/Custom/Personal/types";
import axios from "../../../../api";
import {IAPI_Response} from "../../../../api/types";
import {API_ENDPOINT, ATTENDANCE, ROLE_PERMISSION} from "../../../../utils/globalConstant";
import {IMenu, IModule, IPermission} from "./type";
import './style.css'
import {useNavigate} from "react-router-dom";
import Alert from "components/Alert";

export default function Index() {
    // const [key, setKey] = useState('personal-details');
    const navigate = useNavigate();
    const [roles, setRole] = useState<IRole[]>([
        {
            role_id: 0,
            role_name: '',
            created_at: '',
            updated_at: ''
        }
    ])
    const [modules, setModule] = useState<IModule[]>([
        {
            id: 0,
            name: '',
            icon_class: '',
            icon_class_active: ''
        }
    ])
    const [menus, setMenus] = useState<IMenu[]>([
        {
            id: 0,
            parent_id: 0,
            action: 0,
            name: "",
            menu_url: "",
            module_id: 0,
            status: 0,
            sublink_color: "",
            sublink_active_color: "",
            isChecked: 0,
            module_name: "",
            permissions: [],
            showModule: 0
        }
    ])
    const [subMenus, setSubMenus] = useState<IMenu[]>([
        {
            id: 0,
            parent_id: 0,
            action: 0,
            name: "",
            menu_url: "",
            module_id: 0,
            status: 0,
            sublink_color: "",
            sublink_active_color: "",
            isChecked: 0,
            module_name: "",
            permissions: [],
            showModule: 0
        }
    ])
    const [chosenRole, setChosenRole] = useState(true)
    const [roleId, setRoleId] = useState(0);
    const [error, setError] = useState('')
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [success, setSuccess] = useState('')
    const [subCurrentMenu, setCurrentSubMenu] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false)

    const handleCloseConfirmation = () => {
        setShowConfirmation(false)
    }

    const handleOpenConfirmation = () => {
        setShowConfirmation(true)
    }

    //get all roles
    const getAllRole = async () => {
        try {
            const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_ROLE)
            const result = await res
            if (result?.data?.results?.length) {
                setRole(result?.data?.results)
            }
        } catch {
            console.log('There is something wrong')
        }
    }

    //get all modules
    const getAllModules = async () => {
        try {
            const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.GET_MODULES)
            const result = await res
            if (result?.data?.results?.length) {
                setModule(result?.data?.results)
            }
        } catch {
            console.log('There is something wrong')
        }
    }

    const handleChange = (e: any) => {
        const value = e.target.value;
        if(value !== ""){

            getAllSubMenus();
            setChosenRole(false);
            setRoleId(value);

        }else{
            setChosenRole(true);
            setRoleId(0)
        }
    }

    //get all menus
    const getAllMenus = async () => {
        try {
            const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.GET_MENUS)
            const result = await res
            if (result?.data?.results?.length) {
                setMenus(result?.data?.results)
            }
        } catch {
            console.log('There is something wrong')
        }
    }

    //get all menus
    const getAllSubMenus = async () => {
        try {
            const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.GET_SUBMENUS)
            const result = await res
            if (result?.data?.results?.length) {
                setSubMenus(result?.data?.results)
            }
        } catch {
            console.log('There is something wrong')
        }
    }



    const handleCheckboxChange = (role_id:number, menu_id: number, e:any) => {
        const newMenus = menus;
        newMenus.map(menu => {
            if (menu.id == menu_id) {
                menu?.permissions?.map(permission => {
                    if (permission['role_id'] == role_id) {
                        permission['isChecked'] = permission['isChecked'] == 1 ? 0 : 1
                    }
                })
            }
        });

        setMenus([...menus])

    }

    const addRolePermission = async () => {

        await axios.post(API_ENDPOINT.POST_MENUS, {
            menus: menus
        }).then(response => {

            if (response?.data?.status * 1 === 1) {
                // window.location.reload()
                setShowSuccess(true)
                setSuccess(ROLE_PERMISSION.SUCCESS)
                setChosenRole(true);
                setShowConfirmation(false)
            }
        }).catch(error => {
            setShowError(true)
            setError(error.response.data.message)
            setShowConfirmation(false)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const identifyCheck = (menu_id: number, role_id: number) => {
        let isChecked = false;
        menus?.map((menu: any) => {
            if(menu.id == menu_id){
                menu.permissions.map((permission:any) => {
                    if(permission.role_id == role_id && permission.isChecked == 1){
                        isChecked = true;
                    }
                })
            }
        });
        return isChecked;
    }


    useEffect(() => {
        ;(async () => {
            getAllRole();
            getAllModules();
            getAllSubMenus();
            getAllMenus();
        })()
    }, [])

    return (
        <Container fluid>
            <Row>
                <Col sm={6}>
                    <h5 className='fw-bold'>Role Permission</h5>
                    <Breadcrumb className='breadcrumbs'>
                        <Breadcrumb.Item className=' text-decoration-none' href='/home'>
                            Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className=' text-decoration-none' href='#'>
                            Admin
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Role Permission</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col sm={6}>
                    <Stack className='justify-content-end' direction='row' spacing={1}>
                        {/* <Button variant="outlined" sx={{textTransform: 'none'}} color="inherit">*/}
                        {/*    <AddCircleOutlineOutlined className="mx-1"/> Add new employee*/}
                        {/* </Button>*/}
                    </Stack>
                </Col>
            </Row>
            <Card className='border-0  p-0 bordered-card'>
                <Card.Body>
                    <Col sm={6}>
                        <span className='fw-bold'>Role Permission</span>
                    </Col>
                    <br/>
                    <Table responsive className='bordered-tabs-top mt-3'>
                        <thead>
                        <tr className='text-center'>
                            <th style={{ width: '15%' }}>Module</th>
                            <th style={{ width: '15%' }}>Submodule</th>
                            {
                                roles.map((role, index) => (
                                    <th key={role.role_id}>{role.role_name}</th>
                                ))
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            menus.map((menu, index) => (
                                // {setCurrentModule(menu.module_name)}
                                <tr key={menu.id}>
                                    <td><strong>{menu.showModule == 1 ? menu.module_name: ""}</strong></td>
                                    <td>{menu.name}</td>
                                    {
                                        roles.map((role, index) => (
                                            <td key={role.role_id} >
                                                <Form.Group  style={{marginLeft: '50%'}}>
                                                    <Form.Check
                                                        label={""}
                                                        name={role.role_id + "-" + menu.name}
                                                        value={role.role_id + "-" + menu.name}
                                                        id={role.role_id + "-" + menu.name}
                                                        checked={identifyCheck(menu.id, role.role_id)}
                                                        onClick={(event) => handleCheckboxChange(role.role_id, menu.id, event)}
                                                    />
                                                </Form.Group>
                                            </td>
                                        ))
                                    }

                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>

                    <Form.Group >
                        <Button
                            onClick={handleOpenConfirmation}
                            type="submit"
                            variant='primary text-white'
                            style={{ width: '10%', float:'right' }}
                            size="sm">
                            Save Changes
                        </Button>
                    </Form.Group>
                </Card.Body>
            </Card>
            <br/>

            {/* CONFIRMATION MODAL */}
            <Modal className='modal' centered show={showConfirmation} onHide={handleCloseConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group style={{textAlign:'center'}}>
                        <Form.Label column="sm" >Are you sure you want to save your changes?</Form.Label>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-primary' size={'sm'} onClick={handleCloseConfirmation}>
                        Cancel
                    </Button>
                    <Button
                        variant='primary text-white'
                        size={'sm'}
                        className='mx-2'
                        onClick={addRolePermission}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Alert
                type="success"
                show={showSuccess}
                message={success}
                setShow={() => {
                    setShowSuccess(false);
                }
                }
            />

            <Alert
                type="error"
                show={showError}
                message={error}
                setShow={() => {
                    setShowError(false);
                }
                }
            />


        </Container>
    )
}