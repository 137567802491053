import React, {useEffect, useState} from 'react'
import {
  Button,
  Card,
  Col, Form, InputGroup, Modal,
  Row,
  Table
} from "react-bootstrap";
import View from 'asset/SvgComponent/View'
import Location from 'asset/SvgComponent/Location'
import Pagination from 'components/Pagination'
import Alert from 'components/Alert'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
  API_ENDPOINT,
  ERROR_MESSAGE, 
  IMAGE_BASE_URL,
  TEXT
} from 'utils/globalConstant'
import { IEmployee, IList } from './types'
import {MapContainer, TileLayer, Marker, Popup, Tooltip} from "react-leaflet";
import LocationMarker from "components/Marker";
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import Runningtime from "components/RunningTime";
import {Avatar} from "@mui/material";
import Stack from "@mui/material/Stack";
import moment from "moment/moment";

export default function Index() {
  const [timeFormat, setTimeFormat] = useState(localStorage.getItem('time_format') ?? "")

  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(6)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)

  const [lists, setData] = React.useState<IList[]>([])

  const [showImage, setShowImage] = React.useState(false);
  const [showLocation, setLocationImage] = React.useState(false);
  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
    address: ''
  })
  const [locationDetails, setLocationDetails] = useState({
    id: 0,
    latitude: 0,
    longitude: 0,
    address: ''
  })
  const [selectedEmployee, setSelectedEmployee] = React.useState(0);
  const [attendanceId, setAttendanceId] = React.useState(0);
  const [showSearchIcon, setShowSearchIcon] = React.useState(false);
  const [searched, setSearched] = React.useState('');

  const handleShowSearchField = () => {
    setShowSearchIcon(!showSearchIcon)
  }

  const handleCloseImage = () => setShowImage(false)
  const handleShowImage = (id: any) => {
    setShowImage(true)
    setSelectedEmployee(id);
  }
  const handleCloseLocation = () => setLocationImage(false)
  const handleShowLocation = (id: any) => {
    setSelectedEmployee(id);
    setLocationImage(true)
    setLocationDetails({
      id: 0,
      latitude: 0,
      longitude: 0,
      address: ""
    });
  }
  const getLocation = (data: any) => {
    setLocation(data)
  }

  const handleViewEmployees = () => {
    window.location.replace('/attendance/attendance-report')
  }

  const getAttendaceToday = async (page_number: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IEmployee>>(API_ENDPOINT.GET_ATTENDANCE_TODAY, {
        params: {
          page: page_number,
          searched: searched,
          employee_id: 0
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }else{
        setData([])
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleTimeStampChange = (e: any) => {
    const id = e.target.value;
    if(id != ""){
      setAttendanceId(id);
      lists.map((employee, index) =>{
        if(employee.employee_id == selectedEmployee){
          employee?.attendancesv2?.map((attendance, index2) => {
            attendance.locations_and_images.map(location => {
              if(parseInt(id) == location.id){
                const newLocation = {
                  id: location.id,
                  latitude: parseFloat(location.latitude),
                  longitude: parseFloat(location.longitude),
                  address: location.address
                }
                setLocationDetails({...newLocation});

              }
            })


          })
        }
      }
      );

    }


  }

  useEffect(() => {
    ;(async () => {
      getAttendaceToday(null)
    })()
  }, [])

  useEffect( () => {
    const timer = setTimeout(() => {
      getAttendaceToday(null)
    }, 300)

    return () => clearTimeout(timer)
  }, [searched])

  useEffect(() => {
    const interval = setInterval(() => {
      getAttendaceToday(null)
    }, 60000)
    return () => clearInterval(interval)
  })

  return (
    <>
      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />
      <Card className="border-0  p-0 bordered-card" style={{overflowY: 'hidden', height: '325px'}}>
        <Card.Body className="pb-0">
            <Row className="p-1">
              <Col sm={6}>
                <span className="fw-bold">Today's Attendance</span>
              </Col>
              <Col sm={3}></Col>
              <Col sm={3} className='d-flex justify-content-end hidden'>
                {!showSearchIcon ?
                    <InputGroup size='sm' className={'w-30'}>
                      <Form.Control
                          aria-label='search'
                          aria-describedby='inputGroup-sizing-sm'
                          placeholder='Search...'
                          onChange={e => setSearched(e.target.value)}
                      />
                      <InputGroup.Text className='bg-transparent search-input ' onClick={handleShowSearchField}>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                    : <InputGroup size='sm' className='d-flex justify-content-end hidden'>
                      <InputGroup.Text className='bg-transparent search-input ' onClick={handleShowSearchField}>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                }
              </Col>
            </Row>
            {/*<hr/>*/}
            <Table responsive size="sm" className="bordered-tabs-top mt-2" style={{whiteSpace: 'nowrap'}}>
              <thead className="thead-table">
                <tr className="text-center">
                  <th style={{width: '5%'}}>No.</th>
                  <th style={{width: '17%'}}>Employee</th>
                  <th style={{width: '12%'}}>Department</th>
                  <th style={{width: '9%'}}>Status</th>
                  <th style={{width: '9%'}}>Clock In</th>
                  <th style={{width: '9%'}}>Clock Out</th>
                  <th style={{width: '9%'}}>OT In</th>
                  <th style={{width: '9%'}}>OT Out</th>
                  <th style={{width: '7%'}}>Worked Time</th>
                  <th style={{width: '7%'}}>Late</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {lists.length > 0 ? (
                    lists.map((item, index) => 
                      <tr className="mb-2" key={index}>
                        <td>{from + index}</td>
                        <td className="fw-bold">
                          <Stack direction='row' spacing={1}>
                            <div>
                              {
                                item.photo != "" ?
                                    <Avatar
                                        sx={{ width: 20, height: 20 }}
                                        src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                    ></Avatar> :
                                    <Avatar
                                        sx={{ width: 20, height: 20}}
                                        src={""}
                                    ></Avatar>
                              }
                            </div>
                            <div>{item.first_name} {item.last_name}</div>
                          </Stack>
                        </td>
                        <td>{item.department?.department_name}</td>
                        <td className="text-center">
                          <div className="d-grid">
                            {(() => {
                              if (item.leaves && item.leaves.length > 0) {
                                return (
                                  <Button 
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="info"
                                    size="sm"
                                    >
                                    Leave
                                  </Button>
                                )
                              } else if(item.attendancesv2 && item.attendancesv2.length > 0) {
                                return (
                                  <Button 
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="success"
                                    size="sm"
                                    >
                                    Present
                                  </Button>
                                )
                              } 

                              return (
                                <Button 
                                  className=" p-1 pt-0 pb-0"
                                  style={{fontSize: '12px', color: 'white'}}
                                  variant='danger'
                                  size="sm"
                                  >
                                  Absent
                                </Button>
                              )
                            })()}
                          </div>
                        </td>
                        <td className="text-center">{item.in_time != null ? moment(new Date("2023-01-01 "+item.in_time ?? "")).format(timeFormat) : '-'}</td>
                        <td className="text-center">{item.out_time != null ? moment(new Date("2023-01-01 "+item.out_time ?? "")).format(timeFormat) : '-'}</td>
                        <td className="text-center">{item.overtime_in != null ? moment(new Date("2023-01-01 "+item.overtime_in ?? "")).format(timeFormat) : '-'}</td>
                        <td className="text-center">{item.overtime_out != null ? moment(new Date("2023-01-01 "+item.overtime_out ?? "")).format(timeFormat) : '-'}</td>
                        <Runningtime in_time={item.actual_in_time} out_time={item.actual_out_time} worked_time={item.worked_time} type={'worked_time'} break_in={item.actual_break_in} break_out={item.actual_break_out}/>
                        <td className="text-center">{item.late_time != null ? item.late_time : '-'}</td>
                        <td className="text-center">
                          <Button 
                            className=" p-1 pt-0 pb-0"
                            style={{fontSize: '12px', color: '#F26930'}}
                            variant=" btn-transparent"
                            size="sm"
                            title="Show Locations"
                            onClick={() => handleShowLocation(item.employee_id)}
                            >
                            <Location/>
                          </Button>
                          <Button 
                            className=" p-1 pt-0 pb-0"
                            style={{fontSize: '12px', color: '#F26930'}}
                            variant=" btn-transparent"
                            size="sm"
                            title="Show Images"
                            onClick={() => handleShowImage(item.employee_id)}
                            >
                            <View/>
                          </Button>
                        </td>
                      </tr>
                    )
                    ) : (
                    <tr className='no-item'>
                      <td colSpan={11}>{TEXT.NOT_FOUND}</td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>
            <Row>
              <Col sm={8}>
                <Pagination
                    itemsCount={itemsCount}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getAttendaceToday(page);
                    }
                    }
                />
              </Col>
              <Col sm={4} >
                <div className="text-right">
                  <h6 className="fw-bold text-muted text-truncate" style={{fontSize: '12px', cursor: 'pointer'}} onClick={handleViewEmployees}>
                    View All({itemsCount}) Employees
                  </h6>
                </div>
              </Col>

            </Row>

    
        </Card.Body>
      </Card>
      {/* IMAGE MODAL*/}
      <Modal className='modal' size="lg" centered show={showImage} onHide={handleCloseImage}>
        <Modal.Header closeButton >
          <Modal.Title className='fw-bold'>CAPTURED IMAGE/S</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {lists.map((employee, index) => (
                employee.employee_id == selectedEmployee?
                    (
                        employee?.attendancesv2?.length > 0 ?
                          (employee?.attendancesv2?.map((attendance, index) => (
                              attendance.locations_and_images.map(location => (
                                  <Form.Group as={Col} key={location.id} md="4" style={{padding:"10px"}}>
                                    <Link to={IMAGE_BASE_URL + location.photo_url} target="_blank" className="photo-a">
                                      <div className="card" style={{width: "15rem"}}>
                                        <img className="card-img-top" alt={"Captured Image"} src={IMAGE_BASE_URL + location.photo_url}/>
                                        <div className="card-body d-flex flex-column align-items-center">
                                          {location.attendance_status == "in_time"? <p className="card-text" style={{color:"#1EA66D"}}>{attendance.in_time} - Clock in</p> :
                                              location.attendance_status == "out_time"? <p className="card-text" style={{color:"#DA2B5D"}}>{attendance.out_time} - Clock out</p> :
                                                  location.attendance_status == "break_in"? <p className="card-text" style={{color:"#F26930"}}>{attendance.break_in} - Break in</p> :
                                                      location.attendance_status == "break_out"? <p className="card-text" style={{color:"#F26930"}}>{attendance.break_out} - Break out</p> : ""}
                                        </div>
                                      </div>
                                    </Link>
                                  </Form.Group>
                              ))


                          )))
                            : <p>No Attendance yet.</p>

                    ): ""

            ))}
          </Row>


        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={handleCloseImage}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* LOCATION MODAL*/}
      <Modal className='modal' size="lg" centered show={showLocation} onHide={handleCloseLocation}>
        <Modal.Header closeButton style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Modal.Title className='fw-bold'>LOCATIONS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          <Form.Group as={Col} md="5">
            <Form.Label column="sm"></Form.Label>
          </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label column="sm">Timestamps</Form.Label>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="4"></Form.Group>
            <Form.Group as={Col} md="4" className="position-relative">
              <Form.Select
                  size={'sm'}
                  name="timestamps"
                  // onBlur={handleBlur}
                  onChange={handleTimeStampChange}
              >
                <option value="">Select Timestamp</option>
                {lists.map((employee, index) => (
                    employee.employee_id == selectedEmployee?
                        (
                            (employee?.attendancesv2?.map((attendance, index) => (
                                attendance.locations_and_images.map(location => (
                                    <option value={location.id} key={location.id} style={{
                                      color: location.attendance_status == "out_time"? "#DA2B5D": location.attendance_status == "in_time"? "#1EA66D":
                                          location.attendance_status == "break_out" || location.attendance_status == "break_in"? "#FBA91A": ""
                                    }}>
                                      {location.attendance_status == "in_time"? attendance.in_time + " - Clock in":
                                          location.attendance_status == "out_time"? attendance.out_time + " - Clock out":
                                              location.attendance_status == "break_in"? attendance.break_in + " - Break in":
                                                  location.attendance_status == "break_out"? attendance.break_out + " - Break out":
                                                      location.attendance_status == "check in"? "Check in":""}
                                    </option>
                                ))


                            )))

                        ): ""

                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid" tooltip>
                {/*{errors.marital_status}*/}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <br/>
          <Row>
            <MapContainer
                style={{height: '400px'}}
                center={[locationDetails.latitude, locationDetails.longitude]}
                zoom={16}
                scrollWheelZoom={false}
                attributionControl={false}
            >
              <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

              />

              {locationDetails.latitude != 0 && locationDetails.longitude != 0 ? <LocationMarker func={getLocation} latLongGiven={locationDetails}/> : "" }

            </MapContainer>
            <div className="text-center">
              Location : <b>{locationDetails.address}</b>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={handleCloseLocation}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
