import * as React from "react"
const CheckIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
    >
        <title>{"Success"}</title>
        <circle cx={12} cy={12} r={10} stroke="#82C341" strokeWidth={1.5} />
        <path
            stroke="#82C341"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m8.5 12.5 2 2 5-5"
        />
    </svg>
)
export default CheckIcon
