import React from 'react'

const ArrowDown = () => {
    return (
        // <svg
        //     xmlns="http://www.w3.org/2000/svg"
        //     width="16"
        //     height="16"
        //     fill="currentColor"
        //     className="bi bi-arrow-down-short"
        //     color="#fba91a"
        //     viewBox="0 0 16 16"
        // >
        //     <path
        //         fill="#ffa900"
        //         fillRule="evenodd"
        //         d="M8 4a.5.5 0 01.5.5v5.793l2.146-2.147a.5.5 0 01.708.708l-3 3a.5.5 0 01-.708 0l-3-3a.5.5 0 11.708-.708L7.5 10.293V4.5A.5.5 0 018 4z"
        //     ></path>
        // </svg>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-down"
            viewBox="0 0 16 16"
            color="#fba91a"
        >
            <path
                fillRule="evenodd"
                d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
            ></path>
        </svg>
    )
}

export default ArrowDown
