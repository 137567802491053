import React, {useEffect, useRef, useState} from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Stack,
    Table,
} from 'react-bootstrap'
import Alert from "components/Alert";
import './style.css'
import OrgChart from 'react-orgchart';
import { Tree, TreeNode } from 'react-organizational-chart';
import {Avatar} from "@mui/material";
import ZoomIn from 'asset/SvgComponent/ZoomIn';
import ZoomOut from 'asset/SvgComponent/ZoomOut';
import AddEmployee from 'asset/SvgComponent/AddEmployee'
import {validationSchema} from "../EmployeeManagement/Designation/validationSchema";
import {Formik} from "formik";

export default function Index() {
    const [error, setError] = React.useState('')
    const [showError, setShowError] = React.useState(false)
    const [success, setSuccess] = React.useState('')
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [zoom, setZoom] = useState(1);
    const [showAdd, setShowAdd] = useState(false)
    const handleShowAdd = () => setShowAdd(true)
    const handleCloseAdd = () => setShowAdd(false)


    const [data, setData] = React.useState({
        id: 1,
        name: "John Doe",
        designation: "Chief Executive Officer",
        image: "",
        show: true,
        children: [
            {
                id: 2,
                name: "James Smith",
                designation: "HR Manager",
                image: "",
                show: false,
                children: [
                    {
                        id: 3,
                        name: "Michael Smith",
                        designation: "",
                        image: "",
                        show: false,
                    },
                ],
            },
            {
                id: 4,
                name: "Robert Smith",
                designation: "Finance Manager",
                image: "",
                show: false,
                children: [
                    {
                        id: 5,
                        name: "Maria Garcia",
                        designation: "Financial Advisor",
                        image: "",
                        show: false,
                        children: [
                            {
                                id: 6,
                                name: "David Smith",
                                designation: "Finance",
                                image: "",
                                show: false,
                            },
                            {
                                id: 7,
                                name: "Maria Rodriguez",
                                designation: "Collector",
                                image: "",
                                show: false,
                                children: [
                                    {
                                        id: 6,
                                        name: "Mary Smith:",
                                        designation: "Financial Advisor",
                                        image: "",
                                        show: false,
                                    },
                                ]
                            },
                            {
                                id: 8,
                                name: "Maria Hernandez",
                                designation: "Finance Asst. Manager",
                                image: "",
                                show: false,
                            }
                        ]
                    },
                ],
            },
            {
                id: 9,
                name: "Ben Doe",
                designation: "IT Department Head",
                image: "",
                show: false,
                children: [
                    {
                        id: 10,
                        name: "Maria Martinez",
                        designation: "Senior Software Engineer",
                        image: "",
                        show: false,
                    },
                    {
                        id: 14,
                        name: "Robert Robinson",
                        designation: "Junior Software Engineer",
                        image: "",
                        show: false,
                        children: [
                            {
                                id: 15,
                                name: "Thomas Edisson",
                                designation: "Software Developer",
                                image: "",
                                show: false,

                            }
                        ]
                    },
                ],
            },
            {
                id: 11,
                name: "James Johnson",
                designation: "Utility Manager",
                image: "",
                show: false,
                children: [
                    {
                        id: 12,
                        name: "Michael Doe",
                        designation: "Janitor",
                        image: "",
                        show: false,
                    },
                    {
                        id: 13,
                        name: "Charles Doe",
                        designation: "Maintenance",
                        image: "",
                        show: false,
                    },
                ],
            },
        ],
    });

    const addEmployee = () => {
        console.log("add")
    }

    const countChildren = (children: any) => {

        if (!children.children || children.children.length === 0) {
            return 0;
        }
        let count = children.children.length;
        for (const child of children.children) {
            count += countChildren(child);
        }
        return count;
    }


    // Function to handle zoom in
    const handleZoomIn = () => {
        setZoom((prevZoom) => Math.min(prevZoom + 0.1, 2));

    };

    // Function to handle zoom out
    const handleZoomOut = () => {
        setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5));

    };


    const handleShowHide = (parentId: number) => {
        // Create a copy of the 'data' state
        const updatedData = { ...data };


        if(parentId == updatedData.id){
            updatedData.show = !updatedData.show;

        }else{
            // Function to recursively find and update the child
            const findAndUpdateChild = (items: any) => {
                for (const item of items) {
                    if (item.id === parentId) {
                        // Found the child, update its properties
                        item.show = !item.show; // Example: Toggle 'show' for demonstration, modify as needed
                        break;
                    } else if (item.children) {
                        // Recursively search in children
                        findAndUpdateChild(item.children);
                    }
                }
            };

            // Call the recursive function starting from the top level children
            findAndUpdateChild(updatedData.children);
        }

        // Update the state with the modified data
        setData(updatedData);
    };

    const ContainerRoot = ({id, name, image, designation, show, child, parentId, node}: any) => {
        if(name != ""){
            return (
                <div className={`container-root`} >
                    <div>
                        <Button
                            variant=' btn-transparent bottom-center-button'
                            size='sm'
                            className="p-1 pt-0 pb-0 "
                            onClick={handleShowAdd}
                        ><AddEmployee/>
                        </Button>
                    </div>
                    <div onClick={() => handleShowHide(id)}>
                        <Row className='mt-2 '>
                            <Col sm={12}>
                                <Stack direction='horizontal' className='justify-content-center'>
                                    <div>
                                        {
                                            image != "" ?
                                                <Avatar
                                                    sx={{ width: 40, height: 40 }}
                                                    src={process.env.REACT_APP_IMAGE_URL+ image}
                                                ></Avatar> :
                                                <Avatar
                                                    sx={{ width: 40, height: 40}}
                                                    src={""}
                                                ></Avatar>
                                        }
                                    </div>
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <div >
                                <p><strong><span className='employee-label'>{name}</span></strong>
                                    <br />
                                    <span className='designation-label text-muted fw-light'>{designation}</span>
                                </p>
                            </div>
                        </Row>
                        {
                            child && child.length > 0 ?
                                <div >
                                    <p className='bottom-center-text' style={{color: !show ? "white" : "#ff7043", backgroundColor: !show ? '#ff7043': 'white', border: '1px solid #ff7043'}}>{countChildren(node)}</p>

                                </div> : ""

                        }
                    </div>
                </div>
            )
        }else{
            return (
                <div className="box">
                    <div className="line"></div>
                </div>

            )
        }

    }

    const renderTreeNodes = (node: any, parentId: number, show: boolean) => {
        return (

                (node.children && node.children.length != 0) ?
                    node.show ?
                    <TreeNode className={`node-children ${!show ? "hidden" : ""}`} label={<ContainerRoot node={node} id={node.id} parentId={parentId} name={node.name} designation={node.designation || ''} image={node.image} show={node.show} child={node.children}/>}>
                        {
                                node.children.map((child: any, index: number) => (

                                    <React.Fragment key={index}>{renderTreeNodes(child, node.id, node.show)}</React.Fragment>

                                ))
                        }

                    </TreeNode>
                        :<TreeNode className={`node-children ${!show ? "hidden" : ""}`} label={<ContainerRoot node={node} id={node.id} parentId={parentId} name={node.name} designation={node.designation || ''} image={node.image} show={node.show} child={node.children}/>}/>
                    :<TreeNode className={`node-children ${!show ? "hidden" : ""}`} label={<ContainerRoot node={node} id={node.id} parentId={parentId} name={node.name} designation={node.designation || ''} image={node.image} show={node.show} child={node.children}/>}/>

        );
    };


    useEffect(() => {
        document.documentElement.style.setProperty('--zoom', zoom.toString());
    }, [zoom]);


    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Organizational Chart</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none'>
                        Employees
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Organizational Chart</Breadcrumb.Item>
                </Breadcrumb>
                <Card className='border-0  p-0 bordered-card justify-content-center' >
                    <Card.Body className='scrollable-card-body' style={{ position: 'relative' }}>
                        <div className="mb-4 zoom-controls">
                            {/* Zoom controls */}
                            <Button className='zoom-buttons p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                    onClick={handleZoomIn}><ZoomIn /></Button>
                            <Button className='zoom-buttons p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                    onClick={handleZoomOut}><ZoomOut /></Button>
                        </div>
                        <div className='scrollable-div' >
                            <div className="tree-container" style={{ transform: `scale(${zoom})` }}>
                                <Tree

                                    lineWidth={'2px'}
                                    lineColor={'#ff7043'}
                                    lineBorderRadius={'10px'}
                                    label={<ContainerRoot show={data.show} node={data} id={data.id} parentId={data.id} name={data.name} designation={data.designation} image={data.image} child={data.children} />}>

                                    {data.children.map((child, index) => (
                                        <React.Fragment key={index}>{renderTreeNodes(child, data.id, data.show)}</React.Fragment>
                                    ))}

                                </Tree>
                            </div>

                        </div>


                    </Card.Body>
                </Card>

                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <Formik
                    initialValues={{ designation_name: '', order: ''}}
                    // validationSchema={validationSchema}
                    onSubmit={addEmployee}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (

                        <Form id="designationCreate" noValidate onSubmit={handleSubmit}>
                            <Modal className='modal' centered show={showAdd} onHide={handleCloseAdd}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Employee</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="mb-3">
                                        {/*<Col sm={6}>*/}
                                        {/*    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted'>Designation Name</Form.Label>*/}
                                        {/*        <Form.Control*/}
                                        {/*            size={'sm'}*/}
                                        {/*            type='text'*/}
                                        {/*            placeholder='Designation name'*/}
                                        {/*            name="designation_name"*/}
                                        {/*            value={values.designation_name}*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            isInvalid={touched.designation_name && !!errors.designation_name || !!validation}*/}
                                        {/*            autoFocus />*/}
                                        {/*        {*/}
                                        {/*            validation != '' ?*/}
                                        {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                        {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                        {/*                            {validation[message][index]}*/}
                                        {/*                        </Form.Control.Feedback>*/}
                                        {/*                    ))*/}
                                        {/*                ) : (*/}
                                        {/*                    <Form.Control.Feedback type="invalid">*/}
                                        {/*                        {errors.designation_name}*/}
                                        {/*                    </Form.Control.Feedback>*/}
                                        {/*                )*/}
                                        {/*        }*/}
                                        {/*    </Form.Group>*/}
                                        {/*</Col>*/}
                                        {/*<Col sm={6}>*/}
                                        {/*    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted'>Designation Order</Form.Label>*/}
                                        {/*        <Form.Control*/}
                                        {/*            size={'sm'}*/}
                                        {/*            type='number'*/}
                                        {/*            placeholder='Designation order'*/}
                                        {/*            name="order"*/}
                                        {/*            value={values.order}*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            isInvalid={touched.order && !!errors.order || !!validation}*/}
                                        {/*            autoFocus />*/}

                                        {/*        <Form.Control.Feedback type="invalid">*/}
                                        {/*            {errors.order}*/}
                                        {/*        </Form.Control.Feedback>*/}
                                        {/*    </Form.Group>*/}
                                        {/*</Col>*/}
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseAdd}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        form="designationCreate"
                                        variant='primary text-white'
                                        size={'sm'}
                                        className='mx-2'
                                    >
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                    )}
                </Formik>

                <br />
            </Container>
        </>


    )
}
