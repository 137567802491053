import * as React from "react"
const TableListIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            stroke="#a7a9ac"
            strokeWidth={1}
            d="M3 9.5h18m-18 5h18M8 4.5v15m-1.8 0h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C21 17.98 21 17.42 21 16.3V7.7c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C19.48 4.5 18.92 4.5 17.8 4.5H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C3 6.02 3 6.58 3 7.7v8.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.428.218.988.218 2.108.218Z"
        />
    </svg>
)
export default TableListIcon
