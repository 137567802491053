import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
  ListGroup
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Formik } from 'formik';
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { validationSchema } from './validationSchema'
import { IPayGrade, IAllowance, IDeduction,  IList, IItem } from './types'
import moment from "moment"

export default function Index() {
  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [validation, setValidation] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)

  const [showCreate, setShowCreate] = React.useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)

  const [showEdit, setShowEdit] = React.useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = React.useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      pay_grade_id: id,
      pay_grade_name: '',
      gross_salary: '',
      basic_salary: '',
      allowances: [{
        allowance_id: 0
      }],
      deductions: [{
        deduction_id: 0
      }],
    })
  }

  const [lists, setData] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    pay_grade_id: 0,
    pay_grade_name: '',
    gross_salary: '',
    basic_salary: '',
    allowances: [{
      allowance_id: 0
    }],
    deductions: [{
      deduction_id: 0
    }],
  })
  const [allowance_lists, setAllowance] = useState<IAllowance[]>([
    { 
      allowance_id: 0,
      allowance_name: '',
      limit_per_month: '',
      created_at: '',
      updated_at: '' 
    }
  ])
  const [deduction_lists, setDeduction] = useState<IDeduction[]>([
    { 
      deduction_id: 0,
      deduction_name: '',
      limit_per_month: '',
      created_at: '',
      updated_at: '' 
    }
  ])
  const [dateFormat, setDateFormat] = useState('')

  const getAllAllowance = async () => {
    try {
      const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_ALLOWANCE)
      const result = await res
      if (result?.data?.results?.length) {
        setAllowance(result?.data?.results)
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getAllDeduction = async () => {
    try {
      const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_DEDUCTION)
      const result = await res
      if (result?.data?.results?.length) {
        setDeduction(result?.data?.results)
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getPayGrade = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IPayGrade>>(API_ENDPOINT.GET_PAY_GRADE, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showPayGrade = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_PAY_GRADE +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(
          {
            pay_grade_id: result?.data?.results?.pay_grade_id,
            pay_grade_name: result?.data?.results?.pay_grade_name,
            basic_salary: result?.data?.results?.basic_salary,
            gross_salary: result?.data?.results?.gross_salary,
            allowances: result?.data?.results?.allowances.map(function (allowance) {
              return {
                allowance_id: parseInt(allowance.allowance_id.toString())
              };
            }),
            deductions: result?.data?.results?.deductions.map(function (deduction) {
              return {
                deduction_id: parseInt(deduction.deduction_id.toString())
              };
            }),
          }
        )
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postPayGrade = async (value: any, {resetForm} : any) => {
    await axios.post(API_ENDPOINT.POST_PAY_GRADE, {
      pay_grade_name: value.pay_grade_name,
      gross_salary: value.gross_salary,
      basic_salary: value.basic_salary,
      allowances: value.allowances,
      deductions: value.deductions
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.PAYGRADE.CREATED)
          getPayGrade(null);
       }
       resetForm({})
       handleCloseCreate();
    }).catch(error => {
      setValidation(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const updatePayGrade = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_PAY_GRADE +'/'+ value.pay_grade_id, {
      pay_grade_name: value.pay_grade_name,
      gross_salary: value.gross_salary,
      basic_salary: value.basic_salary,
      allowances: value.allowances,
      deductions: value.deductions
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.PAYGRADE.UPDATED)
          getPayGrade(null);
       }
       resetForm({})
       handleCloseEdit();
    }).catch(error => {
      setValidation(error.response.data.message)
    })
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const deletePayGrade = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_PAY_GRADE +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.PAYGRADE.DELETED)
        getPayGrade(null);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getPayGrade(null)
      getAllAllowance();
      getAllDeduction();
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])
  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Pay Grade</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/payroll-range'>
            Payroll
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Pay Grade</Breadcrumb.Item>
        </Breadcrumb>
        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <span className='fw-bold'>Pay Grade List</span>
              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                <InputGroup size='sm' className={'w-25'}>
                  <Form.Control
                    aria-label='search'
                    aria-describedby='inputGroup-sizing-sm'
                    placeholder='Search...'
                  />
                  <InputGroup.Text className='bg-transparent search-input '>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
                <Button
                  onClick={handleShowCreate}
                  className=' add-new-btn rounded mx-2 text-white'
                  variant='warning'
                  >
                  <EditIcon/>
                  &nbsp;Add New Pay Grade
                </Button>{' '}
              </Col>
            </Row>
            {/* <hr/>*/}
            <Table responsive className='bordered-tabs-top mt-3'>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: '5%' }}>No.</th>
                  <th style={{ width: '15%' }}>Pay Grade Name</th>
                  <th style={{ width: '15%' }}>Gross Salary</th>
                  <th style={{ width: '15%' }}>Basic Salary</th>
                  <th className={'text-center'}>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {lists.length > 0 ? (
                    lists.map((item, index) => 
                      <tr className='mb-2' key={index}>
                        <td>{from + index}</td>
                        <td className='fw-bold'>{item.pay_grade_name}</td>
                        <td className='text-right'>{item.gross_salary}</td>
                        <td className='text-right'>{item.basic_salary}</td>
                        <td className='text-center'>
                          <Button
                            onClick={() => showPayGrade(item.pay_grade_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Write/>
                          </Button>
                          <Button
                            onClick={() => handleShowConfirm(item.pay_grade_id)}
                            className='p-1 pt-0 pb-0'
                            style={{ fontSize: '12px' }}
                            variant='transparent'
                            size='sm'
                            >
                            <Delete/>
                          </Button>
                        </td>
                      </tr>
                    )
                    ) : (
                    <tr className='no-item'>
                      <td colSpan={5}>{TEXT.NOT_FOUND}</td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>

            <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
              pageClicked={(page: number) => { 
                  getPayGrade(page);
                }
              }
              />

            <Dialog 
              show={showConfirm} 
              setShow={handleCloseConfirm}
              setEvent={() => { 
                  deletePayGrade(item.pay_grade_id)
                }
              }
            />

            <Loader show={showLoading}/>
          </Card.Body>
        </Card>

        {/* ADD MODAL*/}
        <Formik
          initialValues={{ 
            pay_grade_name: '',
            basic_salary: '',
            gross_salary: '',
            allowances: [{
              allowance_id: 0
            }],
            deductions: [{
              deduction_id: 0
            }],
          }}
          validationSchema={validationSchema}
          onSubmit={postPayGrade}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

          <Form id="payGradeCreate" noValidate onSubmit={handleSubmit}>
            <Modal className='modal' centered size={'lg'} show={showCreate} onHide={handleCloseCreate}>
              <Modal.Header closeButton>
                <Modal.Title className='fw-bold'>Add New Pay Grade</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                        <Form.Label className='fw-bold required'>Pay Grade Name</Form.Label>
                        <Form.Control 
                          size='sm' 
                          type='text' 
                          placeholder='Pay grade name' 
                          name="pay_grade_name"
                          value={values.pay_grade_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={(touched.pay_grade_name && !!errors.pay_grade_name) || !!validation}
                          />
                          {
                          validation != '' ?
                            (Object.keys(validation).map((message : any, index) => (
                              <Form.Control.Feedback type="invalid" key={index}>
                                {validation[message][index]}
                              </Form.Control.Feedback>
                            ))
                            ) : (
                            <Form.Control.Feedback type="invalid">
                              {errors.pay_grade_name}
                            </Form.Control.Feedback>
                            )
                          }
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                        <Form.Label className='fw-bold required'>Gross Salary</Form.Label>
                        <Form.Control
                          size='sm'
                          type='number'
                          step={0.01}
                          min={0}
                          placeholder='Gross salary'
                          name="gross_salary"
                          value={values.gross_salary}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.gross_salary && !!errors.gross_salary}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.gross_salary}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                        <Form.Label className='fw-bold required'>Basic Salary</Form.Label>
                        <Form.Control
                          size='sm'
                          type='number'
                          step={0.01}
                          min={0}
                          placeholder='Basic salary'
                          name="basic_salary"
                          value={values.basic_salary}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.basic_salary && !!errors.basic_salary}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.basic_salary}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3 form-checkboxes' controlId='formHorizontalEmail'>
                        <Form.Label className='fw-bold'>Allowance</Form.Label>
                        <ListGroup>
                          <ListGroup.Item>
                            <Form.Check
                              label='Select All' 
                              name={`allowance_all`}
                              onChange={e => {
                                handleChange(e)
                                if (e.target.checked) {
                                  values.allowances = allowance_lists.map(function (allowance) {
                                    return {
                                      allowance_id: allowance.allowance_id
                                    };
                                  });
                                } else {
                                  values.allowances = [];
                                }
                              }} 
                              checked={allowance_lists.length === Object.keys(values.allowances).length ? true : false}
                            />
                          </ListGroup.Item>
                          {allowance_lists.map((allowance, index) => (
                            <ListGroup.Item key={index}>
                              <Form.Check
                                label={allowance.allowance_name +' - ₱ '+ allowance.limit_per_month} 
                                name={`allowance_item`}
                                value={allowance.allowance_id}
                                onChange={e => {
                                  handleChange(e)
                                  values.allowances = values.allowances.map(function (allowance) {
                                    return {
                                      allowance_id: allowance.allowance_id
                                    };
                                  });

                                  if (e.target.checked) {
                                    values.allowances.push({
                                      allowance_id: parseInt(e.target.value)
                                    })
                                  } else {
                                    values.allowances.some((element, index) => {
                                      if (element.allowance_id === parseInt(e.target.value)) {
                                        delete values.allowances[index];
                                      }
                                    })
                                  }
                                }} 
                                checked={
                                  values.allowances.some(element => {
                                    if (element.allowance_id == allowance.allowance_id) {
                                      return true;
                                    }
                                    return false;
                                  })
                                }
                                />
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3 form-checkboxes' controlId='formHorizontalEmail'>
                        <Form.Label className='fw-bold'>Deduction</Form.Label>
                        <ListGroup>
                          <ListGroup.Item>
                            <Form.Check
                              label='Select All' 
                              name={`deduction_all`}
                              onChange={e => {
                                handleChange(e)
                                if (e.target.checked) {
                                  values.deductions = deduction_lists.map(function (deduction) {
                                    return {
                                      deduction_id: deduction.deduction_id
                                    };
                                  });
                                } else {
                                  values.deductions = [];
                                }
                              }} 
                              checked={deduction_lists.length === Object.keys(values.deductions).length ? true : false}
                            />
                          </ListGroup.Item>
                          {deduction_lists.map((deduction, index) => (
                            <ListGroup.Item key={index}>
                              <Form.Check
                                label={deduction.deduction_name +' - ₱ '+ deduction.limit_per_month} 
                                name={`deduction_item`}
                                value={deduction.deduction_id}
                                onChange={e => {
                                  handleChange(e)
                                  values.deductions = values.deductions.map(function (deduction) {
                                    return {
                                      deduction_id: deduction.deduction_id
                                    };
                                  });

                                  if (e.target.checked) {
                                    values.deductions.push({
                                      deduction_id: parseInt(e.target.value)
                                    })
                                  } else {
                                    values.deductions.some((element, index) => {
                                      if (element.deduction_id === parseInt(e.target.value)) {
                                        delete values.deductions[index];
                                      }
                                    })
                                  }
                                }} 
                                checked={
                                  values.deductions.some(element => {
                                    if (element.deduction_id == deduction.deduction_id) {
                                      return true;
                                    }
                                    return false;
                                  })
                                }
                                />
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                  Cancel
                </Button>
                <Button 
                  type="submit"
                  disabled={isSubmitting}
                  form="payGradeCreate" 
                  variant='primary text-white'
                  size={'sm'}
                  className='mx-2'
                  >
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
          initialValues={item}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={updatePayGrade}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

          <Form id="payGradeEdit" noValidate onSubmit={handleSubmit}>
            <Modal className='modal' centered size={'lg'} show={showEdit} onHide={handleCloseEdit}>
              <Modal.Header closeButton>
                <Modal.Title className='fw-bold'>Edit Pay Grade</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                        <Form.Label className='fw-bold required'>Pay Grade Name</Form.Label>
                        <Form.Control 
                          size='sm' 
                          type='text' 
                          placeholder='Pay grade name' 
                          name="pay_grade_name"
                          value={values.pay_grade_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={(touched.pay_grade_name && !!errors.pay_grade_name) || !!validation}
                          />
                          {
                          validation != '' ?
                            (Object.keys(validation).map((message : any, index) => (
                              <Form.Control.Feedback type="invalid" key={index}>
                                {validation[message][index]}
                              </Form.Control.Feedback>
                            ))
                            ) : (
                            <Form.Control.Feedback type="invalid">
                              {errors.pay_grade_name}
                            </Form.Control.Feedback>
                            )
                          }
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                        <Form.Label className='fw-bold required'>Gross Salary</Form.Label>
                        <Form.Control
                          size='sm'
                          type='number'
                          step={0.01}
                          min={0}
                          placeholder='Gross salary'
                          name="gross_salary"
                          value={values.gross_salary}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.gross_salary && !!errors.gross_salary}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.gross_salary}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                        <Form.Label className='fw-bold required'>Basic Salary</Form.Label>
                        <Form.Control
                          size='sm'
                          type='number'
                          step={0.01}
                          min={0}
                          placeholder='Basic salary'
                          name="basic_salary"
                          value={values.basic_salary}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.basic_salary && !!errors.basic_salary}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.basic_salary}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={6}>
                      <Form.Group className='mb-3 form-checkboxes' controlId='formHorizontalEmail'>
                        <Form.Label className='fw-bold'>Allowance</Form.Label>
                        <ListGroup>
                          <ListGroup.Item>
                            <Form.Check
                              label='Select All' 
                              name={`allowance_all`}
                              onChange={e => {
                                handleChange(e)
                                if (e.target.checked) {
                                  values.allowances = allowance_lists.map(function (allowance) {
                                    return {
                                      allowance_id: allowance.allowance_id
                                    };
                                  });
                                } else {
                                  values.allowances = [];
                                }
                              }} 
                              checked={allowance_lists.length === Object.keys(values.allowances).length ? true : false}
                            />
                          </ListGroup.Item>
                          {allowance_lists.map((allowance, index) => (
                            <ListGroup.Item key={index}>
                              <Form.Check
                                label={allowance.allowance_name +' - ₱ '+ allowance.limit_per_month} 
                                name={`allowance_item`}
                                value={allowance.allowance_id}
                                onChange={e => {
                                  handleChange(e)
                                  values.allowances = values.allowances.map(function (allowance) {
                                    return {
                                      allowance_id: allowance.allowance_id
                                    };
                                  });

                                  if (e.target.checked) {
                                    values.allowances.push({
                                      allowance_id: parseInt(e.target.value)
                                    })
                                  } else {
                                    values.allowances.some((element, index) => {
                                      if (element.allowance_id === parseInt(e.target.value)) {
                                        delete values.allowances[index];
                                      }
                                    })
                                  }
                                }} 
                                checked={
                                  values.allowances.some(element => {
                                    if (element.allowance_id == allowance.allowance_id) {
                                      return true;
                                    }
                                    return false;
                                  })
                                }
                                />
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3 form-checkboxes' controlId='formHorizontalEmail'>
                        <Form.Label className='fw-bold'>Deduction</Form.Label>
                        <ListGroup>
                          <ListGroup.Item>
                            <Form.Check
                              label='Select All' 
                              name={`deduction_all`}
                              onChange={e => {
                                handleChange(e)
                                if (e.target.checked) {
                                  values.deductions = deduction_lists.map(function (deduction) {
                                    return {
                                      deduction_id: deduction.deduction_id
                                    };
                                  });
                                } else {
                                  values.deductions = [];
                                }
                              }} 
                              checked={deduction_lists.length === Object.keys(values.deductions).length ? true : false}
                            />
                          </ListGroup.Item>
                          {deduction_lists.map((deduction, index) => (
                            <ListGroup.Item key={index}>
                              <Form.Check
                                label={deduction.deduction_name +' - ₱ '+ deduction.limit_per_month} 
                                name={`deduction_item`}
                                value={deduction.deduction_id}
                                onChange={e => {
                                  handleChange(e)
                                  values.deductions = values.deductions.map(function (deduction) {
                                    return {
                                      deduction_id: deduction.deduction_id
                                    };
                                  });

                                  if (e.target.checked) {
                                    values.deductions.push({
                                      deduction_id: parseInt(e.target.value)
                                    })
                                  } else {
                                    values.deductions.some((element, index) => {
                                      if (element.deduction_id === parseInt(e.target.value)) {
                                        delete values.deductions[index];
                                      }
                                    })
                                  }
                                }} 
                                checked={
                                  values.deductions.some(element => {
                                    if (element.deduction_id == deduction.deduction_id) {
                                      return true;
                                    }
                                    return false;
                                  })
                                }
                                />
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                  Cancel
                </Button>
                <Button 
                  type="submit"
                  disabled={isSubmitting}
                  form="payGradeEdit" 
                  variant='primary text-white'
                  size={'sm'}
                  className='mx-2'
                  >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
          )}
        </Formik>
        <br />
      </Container>
    </>
  )
}
