import React, { useEffect, useState } from 'react'
import {Form, Button, Image, InputGroup, Row, Col} from 'react-bootstrap'
import './style.css'
import moment from 'moment'
import { validationSchema } from './validationSchema'
import User from 'asset/SvgComponent/User'
import Password from 'asset/SvgComponent/Password'
import PasswordShow from 'asset/SvgComponent/PasswordShow'
import PasswordIcon from 'asset/SvgComponent/PasswordIcon'
import axios from 'api/index'
import {API_ENDPOINT, ERROR_MESSAGE, LOGIN} from 'utils/globalConstant'
import { Formik } from 'formik';
import {ClientJS} from "clientjs";
import {browserName, isMobile} from "react-device-detect";
import Alert from "components/Alert";

const Index = () => {
  const [show, setShow] = useState(false);
  const [invalidLogin, setError] = useState<string>('')
  const [displayTime, setDisplayTime] = useState<string>('')
  const [showPassword, setShowPassword] = useState(false)
  const [deviceInformation, setDeviceInformation] = useState({
    isMobile: 0,
    browserFingerprint: '',
    browser: '',
    device: '',
    height: '',
    width: ''
  })
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleChangeShowPassword = () => {
    setShowPassword(!showPassword)
  }



  const redirectLogin = () => {
    window.location.replace('/home')
  }

  const onSubmit = async (value: any) => {
    await axios.get(API_ENDPOINT.LOGIN, {
      params: {
        username: value.username,
        password: value.password,
        browser_fingerprint: deviceInformation.browserFingerprint,
        device: deviceInformation.device,
        browser: deviceInformation.browser,
        isMobile: deviceInformation.isMobile,
        height: deviceInformation.height,
        width: deviceInformation.width,
        fromLogin: 1
      },
    }).then(response => {
      localStorage.setItem('jwt', JSON.stringify(response.data.results.token));
      localStorage.setItem('role', JSON.stringify(response.data.results.role.role_name));
      localStorage.setItem('role_id', JSON.stringify(response.data.results.role.role_id));
      localStorage.setItem('first_name', response.data.results.employee.first_name ?? '' );
      localStorage.setItem('last_name', response.data.results.employee.last_name?? '');
      localStorage.setItem('designation', response.data.results.employee.designation.designation_name?? '');
      localStorage.setItem('shifts', JSON.stringify(response.data.results.employee.shifts));
      localStorage.setItem('date_format', response.data.results.dateFormat?? '');
      localStorage.setItem('time_format', response.data.results.timeFormat?? '');
      localStorage.setItem('photo', response.data.results.employee.photo ?? '' );
      localStorage.setItem('employee_id', response.data.results.employee_id);
      // localStorage.setItem('clientProfile', response.data.results.clientProfile?? '');

      if (response?.data?.status * 1 == 1) {
        redirectLogin()
      }
    }).catch(error => {

      if(error.response.data.message == "device_not_allowed"){
        setShowErrorMessage(true)
        setErrorMessage(ERROR_MESSAGE.device_not_allowed)
      }else if(error.response.data.message == "device_still_pending"){
        setShowErrorMessage(true)
        setErrorMessage(ERROR_MESSAGE.device_still_pending)
      }else{
        setShow(true)
        setError(error.response.data.message)
      }
    })

    setTimeout(() => {
      setShow(false)
      setShowErrorMessage(false)
    }, 3000);
  }

  const getDeviceName = () => {
    // Get the user agent string
    const userAgent = window.navigator.userAgent;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Define some common device keywords to check against
    const deviceKeywords = ['iPhone', 'iPad', 'Android', 'Windows Phone', 'Windows'];

    // Find the matching device keyword
    const matchingKeyword = deviceKeywords.find(keyword => userAgent.includes(keyword));

    // Set the device name based on the matching keyword
    if (matchingKeyword) {
      console.log(matchingKeyword);
    } else {
      console.log('Unknown Device');
    }


    // Create a new ClientJS object
    const client = new ClientJS();
    const fingerprint = client.getFingerprint();

    setDeviceInformation({device: matchingKeyword ?? "Mac",
      browserFingerprint: fingerprint.toString(),
      browser: browserName,
      isMobile: isMobile ? 1 : 0,
      height: viewportHeight.toString(),
      width: viewportWidth.toString()
    })
  }

  useEffect(() => {
    // getDeviceName()
    const interval = setInterval(() => {
      setDisplayTime(moment().format('LTS'))
    }, 1000)
    return () => clearInterval(interval)
  })

  useEffect( () => {
    getDeviceName()
  }, [])

  // eslint-disable-next-line no-constant-condition
  // @ts-ignore
  const formJSX =  (
    <Formik
      initialValues={
        { 
          username: '',
          password: '' 
        }
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <Form noValidate  onSubmit={handleSubmit} onKeyPress={(event) => event.key === 'Enter' && handleSubmit} >
          <Row className="mb-3">
            <Form.Group controlId="validationFormikUsername">
              <InputGroup hasValidation>
                <InputGroup.Text id='basic-addon1' className='bg-white'>
                  <User/>
                  &nbsp;
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  aria-describedby="inputGroupPrepend"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className='form_inputs'
                  isInvalid={touched.username && !!errors.username}
                  style={{textTransform: 'lowercase'}}
                />
                <Form.Control.Feedback>
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="validationFormikPassword">
              <InputGroup hasValidation className='mb-3'>
                <InputGroup.Text id='basic-addon1' className='bg-white'>
                  <PasswordIcon/>
                </InputGroup.Text>
                <Form.Control
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder='Password'
                  aria-label='password'
                  aria-describedby="inputGroupPrepend"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  className='form_inputs'
                  isInvalid={touched.password && !!errors.password}
                  style={{borderRight: 'none'}}
                  />
                <InputGroup.Text
                    id='basic-addon1'
                    className="bg-white"
                    style={{cursor: 'pointer'}}
                    onClick={handleChangeShowPassword}
                    title={showPassword ? "Hide password" : "Show password"}
                >
                  {showPassword ? <PasswordShow/> : <Password/>}
                </InputGroup.Text>
                  <Form.Control.Feedback>
                  </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <p className="login-error text-black text-center" style={{position: "fixed"}}>
            {
              ((touched.username && !!errors.username) || (touched.password && !!errors.password)) ?
                  LOGIN.error
                  : (
                      !show? "" :
                          invalidLogin
                  )
            }
          </p>



          <div className='d-grid gap-2 pt-2'>
            <Button type="submit" disabled={isSubmitting} className='btn btn-dark text-white custom-orange'>
              Login
            </Button>
          </div>
        </Form>
    )}
    </Formik>
  )

  return (
    <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
      <div className='container'>
        <div className='row align-items-center p-0'>
          <div className='col-md-3' />
          <div className=' col-md-6 '>
            <div id='login_container' className='row border-0  rounded-4 shadow-lg'>
              <div className='col-md-5  p-5' id='left-side'>
                <div className='text-center mt-5'>
                  <Image rounded className='w-100 mb-4 logo'  src='/Octaneware1.png' alt='logo'/>
                </div>
                <div className='form-group text-center mb-4'>
                  <div className='col-xs-12'>
                    <h6 className='fw-bold' id=''>
                      {displayTime}
                    </h6>
                    <h6 className='fw-bold mt-4' id=''>
                      {moment().format('dddd')}
                    </h6>
                    <h6 className='fw-bold' id=''>
                      {moment().format('LL')}
                    </h6>
                  </div>
                </div>
              </div>
              <div className='col-md-7 p-4 bg-primary text-center' id='gradient_div'>
                <h4 className=' text-white mt-3' id=''>
                  Login
                </h4>
                <h6 id='sign_in_label' className=' text-white'>
                  Sign in to your account
                </h6>
                <br />
                <br />
                {formJSX}
              </div>
              <Alert
                  type="error"
                  show={showErrorMessage}
                  message={errorMessage}
                  setShow={() => {
                    setShowErrorMessage(false);
                  }
                  }
              />
            </div>
          </div>
          <div className='col-md-3' />
        </div>
      </div>
    </div>

  )
}

export default Index
