import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IFormInitialValue } from './types'

const initialState: IFormInitialValue = {
    company_name: "",
    domain_name: "",
    company_logo: ""
}

export const Step2Slice = createSlice({
    name: 'Step2',
    initialState,
    reducers: {
        addStep2: (_state, action: PayloadAction<IFormInitialValue>) => action.payload,
    },
})

export const { addStep2 } = Step2Slice.actions
export default Step2Slice.reducer
