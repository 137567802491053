import { configureStore } from '@reduxjs/toolkit'
import personalReducer from './EmployeeManagement/AddEmployee/Custom/Personal/PersonalSlice'
import companyReducer from './EmployeeManagement/AddEmployee/Custom/Company/CompanySlice'
import salaryReducer from './EmployeeManagement/AddEmployee/Custom/Salary/SalarySlice'
import shiftReducer from './EmployeeManagement/AddEmployee/Custom/Shift/ShiftSlice'
import educationReducer from './EmployeeManagement/AddEmployee/Custom/Education/EducationSlice'
import workReducer from './EmployeeManagement/AddEmployee/Custom/Work/WorkSlice'
import governmentReducer from './EmployeeManagement/AddEmployee/Custom/Government/GovernmentSlice'

import personalReducerRetail from './EmployeeManagement/AddEmployee/Retail/Personal/PersonalSlice'
import companyReducerRetail from './EmployeeManagement/AddEmployee/Retail/Company/CompanySlice'
import salaryReducerRetail from './EmployeeManagement/AddEmployee/Retail/Salary/SalarySlice'
import shiftReducerRetail from './EmployeeManagement/AddEmployee/Retail/Shift/ShiftSlice'
import educationReducerRetail from './EmployeeManagement/AddEmployee/Retail/Education/EducationSlice'
import workReducerRetail from './EmployeeManagement/AddEmployee/Retail/Work/WorkSlice'
import governmentReducerRetail from './EmployeeManagement/AddEmployee/Retail/Government/GovernmentSlice'

import personalEditReducer from './EmployeeManagement/EditEmployee/Custom/Personal/PersonalSlice'
import companyEditReducer from './EmployeeManagement/EditEmployee/Custom/Company/CompanySlice'
import salaryEditReducer from './EmployeeManagement/EditEmployee/Custom/Salary/SalarySlice'
import shiftEditReducer from './EmployeeManagement/EditEmployee/Custom/Shift/ShiftSlice'
import educationEditReducer from './EmployeeManagement/EditEmployee/Custom/Education/EducationSlice'
import workEditReducer from './EmployeeManagement/EditEmployee/Custom/Work/WorkSlice'
import governmentEditReducer from './EmployeeManagement/EditEmployee/Custom/Government/GovernmentSlice'

import personalEditReducerRetail from './EmployeeManagement/EditEmployee/Retail/Personal/PersonalSlice'
import companyEditReducerRetail from './EmployeeManagement/EditEmployee/Retail/Company/CompanySlice'
import salaryEditReducerRetail from './EmployeeManagement/EditEmployee/Retail/Salary/SalarySlice'
import shiftEditReducerRetail from './EmployeeManagement/EditEmployee/Retail/Shift/ShiftSlice'
import educationEditReducerRetail from './EmployeeManagement/EditEmployee/Retail/Education/EducationSlice'
import workEditReducerRetail from './EmployeeManagement/EditEmployee/Retail/Work/WorkSlice'
import governmentEditReducerRetail from './EmployeeManagement/EditEmployee/Retail/Government/GovernmentSlice'

import registerStep1 from './Register/Step1/Step1Slice'
import registerStep2 from './Register/Step2/Step2Slice'

import attendanceReducer from './Dashboard/Attendance/AttendanceSlice'
import statisticsReducer from './Dashboard/Statistics/StatisticsSlice'

import customFieldReducer from './EmployeeManagement/AddEmployee/Retail/CustomFields/CustomFieldsSlice'

export const store = configureStore({
  reducer: {
    employeePersonal: personalReducer,
    employeeCompany: companyReducer,
    employeeSalary: salaryReducer,
    employeeShift: shiftReducer,
    employeeEducation: educationReducer,
    employeeWork: workReducer,
    employeeGovernment: governmentReducer,

    employeePersonalRetail: personalReducerRetail,
    employeeCompanyRetail: companyReducerRetail,
    employeeSalaryRetail: salaryReducerRetail,
    employeeShiftRetail: shiftReducerRetail,
    employeeEducationRetail: educationReducerRetail,
    employeeWorkRetail: workReducerRetail,
    employeeGovernmentRetail: governmentReducerRetail,

    employeePersonalEdit: personalEditReducer,
    employeeCompanyEdit: companyEditReducer,
    employeeSalaryEdit: salaryEditReducer,
    employeeShiftEdit: shiftEditReducer,
    employeeEducationEdit: educationEditReducer,
    employeeWorkEdit: workEditReducer,
    employeeGovernmentEdit: governmentEditReducer,

    employeePersonalEditRetail: personalEditReducerRetail,
    employeeCompanyEditRetail: companyEditReducerRetail,
    employeeSalaryEditRetail: salaryEditReducerRetail,
    employeeShiftEditRetail: shiftEditReducerRetail,
    employeeEducationEditRetail: educationEditReducerRetail,
    employeeWorkEditRetail: workEditReducerRetail,
    employeeGovernmentEditRetail: governmentEditReducerRetail,

    registerStep1: registerStep1,
    registerStep2: registerStep2,

    attendance: attendanceReducer,
    statistics: statisticsReducer,

    customField: customFieldReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
