import React, {useEffect, useState} from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {
  Breadcrumb,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Button,
  Stack, Modal, InputGroup,
} from 'react-bootstrap'

import { Box, Paper } from '@mui/material'
import { AddCircleOutlineOutlined } from '@mui/icons-material'
import './style.css'
import { Link } from 'react-router-dom'
import axios from "../../../api";
import {API_ENDPOINT, ERROR_MESSAGE, PAYROLL_COMPUTATION, SETUP, SUCCESS_MESSAGE} from "../../../utils/globalConstant";
import {IAPI_Response} from "../../../api/types";
import {IPayroll, IPayrollList, IPayrollRange} from './types'
import moment from "moment"
import {IPayrollComputation} from "../../Setup/Payroll/PayrollComputation/types";
import Pagination from 'components/Pagination'
import Loader from "components/Loader";
import {Formik} from "formik";
import {validationSchema} from "./validationSchema";
import Delete from "asset/SvgComponent/Delete";
import Clock from "asset/SvgComponent/Clock";
import Download from "asset/SvgComponent/Download";
import Alert from "components/Alert";


export default function Index() {
  const [showLoading, setShowLoading] = React.useState(false)
  const [key, setKey] = useState('semi-table')
  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [success, setSuccess] = React.useState('')
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [dateFormat, setDateFormat] = React.useState('')
  const [currentPageWeekly, setCurrentPageWeekly] = React.useState(0)
  const [lastPageWeekly, setLastPageWeekly] = React.useState(0)
  const [itemsPerPageWeekly, setPerPageWeekly] = React.useState(6)
  const [itemsCountWeekly, setItemCountWeekly] = React.useState(0)
  const [fromWeekly, setFromWeekly] = React.useState(0)
  const [currentPageBiWeekly, setCurrentPageBiWeekly] = React.useState(0)
  const [lastPageBiWeekly, setLastPageBiWeekly] = React.useState(0)
  const [itemsPerPageBiWeekly, setPerPageBiWeekly] = React.useState(6)
  const [itemsCountBiWeekly, setItemCountBiWeekly] = React.useState(0)
  const [fromBiWeekly, setFromBiWeekly] = React.useState(0)
  const [currentPageSemiMonthly, setCurrentPageSemiMonthly] = React.useState(0)
  const [lastPageSemiMonthly, setLastPageSemiMonthly] = React.useState(0)
  const [itemsPerPageSemiMonthly, setPerPageSemiMonthly] = React.useState(6)
  const [itemsCountSemiMonthly, setItemCountSemiMonthly] = React.useState(0)
  const [fromSemiMonthly, setFromSemiMonthly] = React.useState(0)
  const [currentPageMonthly, setCurrentPageMonthly] = React.useState(0)
  const [lastPageMonthly, setLastPageMonthly] = React.useState(0)
  const [itemsPerPageMonthly, setPerPageMonthly] = React.useState(6)
  const [itemsCountMonthly, setItemCountMonthly] = React.useState(0)
  const [fromMonthly, setFromMonthly] = React.useState(0)
  const [weekly, setWeekly] = React.useState([{id: 0,
    month: '',
    dayFrom: '',
    dayTo: '',
    generated_by: 0,
    generated_on: '',
    isGenerated: 0,
    isPublished: 0,
    total_ney_pay: 0,
    payroll_cutoff_type: '',
    payroll_cutoff_id: 0,
    payroll_range_name: '',
    first_name : '',
    last_name: ''}])
  const [biWeekly, setBiWeekly] = React.useState([{id: 0,
    month: '',
    dayFrom: '',
    dayTo: '',
    generated_by: 0,
    generated_on: '',
    isGenerated: 0,
    isPublished: 0,
    total_ney_pay: 0,
    payroll_cutoff_type: '',
    payroll_cutoff_id: 0,
    payroll_range_name: '',
    first_name : '',
    last_name: ''}])
  const [semiMonthly, setSemiMonthly] = React.useState([{id: 0,
    month: '',
    dayFrom: '',
    dayTo: '',
    generated_by: 0,
    generated_on: '',
    isGenerated: 0,
    isPublished: 0,
    total_ney_pay: 0,
    payroll_cutoff_type: '',
    payroll_cutoff_id: 0,
    payroll_range_name: '',
    first_name : '',
    last_name: ''}])
  const [monthly, setMonthly] = React.useState([{id: 0,
    month: '',
    dayFrom: '',
    dayTo: '',
    generated_by: 0,
    generated_on: '',
    isGenerated: 0,
    isPublished: 0,
    total_ney_pay: 0,
    payroll_cutoff_type: '',
    payroll_cutoff_id: 0,
    payroll_range_name: '',
    first_name : '',
    last_name: ''}])
  const [showCreate, setShowCreate] = React.useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => {
    setShowCreate(true)
    getPayrollRange()
  }
  const [validation, setValidation] = React.useState('')
  const [payrollRangeList, setPayrollRangeList] = React.useState<IPayrollRange[]>([])
  const isRole = JSON.parse(localStorage.getItem('role') as string);


  const regeneratePayroll = async(id: any) => {
    try {
      const response = await axios.delete(API_ENDPOINT.REGENERATE_PAYROLL_FOR_JVD+"/"+id)


      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess("Payroll Regenerated Successfully.")
      }


    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
      setShowSuccess(false)
    }, 3000);
  }

  const postPayrollPeriod = async(value: any, {resetForm} : any) => {
    try{
      let type = "";
      if(value.payroll_type == "1"){
        type = "Weekly"
      }else if(value.payroll_type == "2"){
        type = "Bi-weekly"
      }else if(value.payroll_type == "3"){
        type = "Semi-monthly"
      }else if(value.payroll_type == "4"){
        type = "Monthly"
      }
      await axios.post(API_ENDPOINT.CREATE_PAYROLL_RANGE, {
        payroll_range_name: value.payroll_range_name,
        payroll_type: type,
        payroll_from: value.payroll_from,
        payroll_to: value.payroll_to
      }).then(response => {
        if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_CREATED_PAYROLL_RANGE)
          getPayrollWeekly(null)
          getPayrollBiWeekly(null)
          getPayrollSemiMonthly(null)
          getPayrollMonthly(null)
        }

        resetForm({})
        handleCloseCreate();

      })
    }catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)

    }

    setTimeout(() => {
      setShowError(false)
      setShowSuccess(false)
      setValidation('')
    }, 3000);
  }

  const getPayrollRange = async () => {
    try {
      const response = await axios.get<IAPI_Response<IPayrollRange[]>>(API_ENDPOINT.PAYROLL_RANGE_LIST)
      const result = await response

      if (result?.data?.results?.length) {
        setPayrollRangeList(result?.data?.results)
      }else{
        setPayrollRangeList([])
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getPayrollWeekly = async (page_number: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IPayrollList>>(API_ENDPOINT.GET_PAYROLL_WEEKLY, {
        params: {
          page: page_number,
        },
      })
      const result = await response

      if (result?.data?.results?.data?.length) {
        setWeekly(result?.data?.results?.data)
        setCurrentPageWeekly(result?.data?.results?.current_page)
        setLastPageWeekly(result?.data?.results?.last_page)
        setPerPageWeekly(result?.data?.results?.per_page)
        setItemCountWeekly(result?.data?.results?.total)
        setFromWeekly(result?.data?.results?.from)
      }else{
        setWeekly([])
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getPayrollBiWeekly = async (page_number: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IPayrollList>>(API_ENDPOINT.GET_PAYROLL_BIWEEKLY, {
        params: {
          page: page_number,
        },
      })
      const result = await response

      if (result?.data?.results?.data?.length) {
        setBiWeekly(result?.data?.results?.data)
        setCurrentPageBiWeekly(result?.data?.results?.current_page)
        setLastPageBiWeekly(result?.data?.results?.last_page)
        setPerPageBiWeekly(result?.data?.results?.per_page)
        setItemCountBiWeekly(result?.data?.results?.total)
        setFromBiWeekly(result?.data?.results?.from)
      }else{
        setBiWeekly([])
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getPayrollSemiMonthly = async (page_number: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IPayrollList>>(API_ENDPOINT.GET_PAYROLL_SEMIMONTHLY, {
        params: {
          page: page_number,
        },
      })
      const result = await response

      if (result?.data?.results?.data?.length) {
        setSemiMonthly(result?.data?.results?.data)
        setCurrentPageSemiMonthly(result?.data?.results?.current_page)
        setLastPageSemiMonthly(result?.data?.results?.last_page)
        setPerPageSemiMonthly(result?.data?.results?.per_page)
        setItemCountSemiMonthly(result?.data?.results?.total)
        setFromSemiMonthly(result?.data?.results?.from)
      }else{
        setSemiMonthly([])
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getPayrollMonthly = async (page_number: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IPayrollList>>(API_ENDPOINT.GET_PAYROLL_MONTHLY, {
        params: {
          page: page_number,
        },
      })
      const result = await response

      if (result?.data?.results?.data?.length) {
        setMonthly(result?.data?.results?.data)
        setCurrentPageMonthly(result?.data?.results?.current_page)
        setLastPageMonthly(result?.data?.results?.last_page)
        setPerPageMonthly(result?.data?.results?.per_page)
        setItemCountMonthly(result?.data?.results?.total)
        setFromMonthly(result?.data?.results?.from)
      }else{
        setMonthly([])
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const generatePayroll = async (id : number) => {

    setShowLoading(true);
    const clientProfile = localStorage.getItem('clientProfile');

    if(clientProfile == "security_agency"){
      await axios.post(API_ENDPOINT.GENERATE_PAYROLL+"/"+ id).then(response => {
        if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_GENERATED)
          setShowLoading(false)
          getPayrollWeekly(null)
          getPayrollBiWeekly(null)
          getPayrollSemiMonthly(null)
          getPayrollMonthly(null)
        }

      })
    }else if(clientProfile == "construction"){
      await axios.post(API_ENDPOINT.GENERATE_PAYROLL_FOR_JVD+"/"+ id).then(response => {
        if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_GENERATED)
          setShowLoading(false)
          getPayrollWeekly(null)
          getPayrollBiWeekly(null)
          getPayrollSemiMonthly(null)
          getPayrollMonthly(null)
        }

      })
    }else if(clientProfile == "retail" || clientProfile == "architecture"){
      await axios.post(API_ENDPOINT.GENERATE_PAYROLL_FOR_MFBC+"/"+ id).then(response => {
        if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_GENERATED)
          setShowLoading(false)
          getPayrollWeekly(null)
          getPayrollBiWeekly(null)
          getPayrollSemiMonthly(null)
          getPayrollMonthly(null)
        }

      })
    }else if(clientProfile == "standard"){
      await axios.post(API_ENDPOINT.GENERATE_PAYROLL_FOR_STANDARD+"/"+ id).then(response => {
        if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_GENERATED)
          setShowLoading(false)
          getPayrollWeekly(null)
          getPayrollBiWeekly(null)
          getPayrollSemiMonthly(null)
          getPayrollMonthly(null)
        }

      })
    }else{
      await axios.post(API_ENDPOINT.GENERATE_PAYROLL2+"/"+ id).then(response => {
        if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_GENERATED)
          setShowLoading(false)
          getPayrollWeekly(null)
          getPayrollBiWeekly(null)
          getPayrollSemiMonthly(null)
          getPayrollMonthly(null)
        }

      })
    }



    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)

    }, 3000);

  }

  const autoGeneratePayrollRange = async () => {

    setShowLoading(true);

    await axios.post(API_ENDPOINT.AUTO_GENERATE_PAYROLL).then(response => {
      if (response?.data?.status * 1 === 1) {
        // setShowSuccess(true)
        setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_GENERATED_PAYROLL_RANGE)
        setShowLoading(false)
        getPayrollWeekly(null)
        getPayrollBiWeekly(null)
        getPayrollSemiMonthly(null)
        getPayrollMonthly(null)
      }

    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)

    }, 3000);

  }


  useEffect(() => {
    ;(async () => {
      getPayrollWeekly(null)
      getPayrollBiWeekly(null)
      getPayrollSemiMonthly(null)
      getPayrollMonthly(null)
      autoGeneratePayrollRange()
      setDateFormat(localStorage.getItem('date_format') ?? "")

    })()
  }, [])

  return (
    <Container fluid>
      <Row>
        <Col sm={6}>
          <h5 className='fw-bold'>Generate Payroll</h5>
          <Breadcrumb className='breadcrumbs'>
            <Breadcrumb.Item className=' text-decoration-none' href='/home'>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/tax-rule-setup'>
              Payroll
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Generate Payroll</Breadcrumb.Item>
          </Breadcrumb>

          <Alert
              type="success"
              show={showSuccess}
              message={success}
              setShow={() => {
                setShowSuccess(false);
              }
              }
          />

          <Alert
              type="error"
              show={showError}
              message={error}
              setShow={() => {
                setShowError(false);
              }
              }
          />
        </Col>
        <Col sm={6}>
          {/* <Stack className="justify-content-end" direction="row" spacing={1}>*/}
          {/*    /!*<Button variant="outlined" sx={{textTransform: 'none'}} color="inherit">*!/*/}
          {/*    /!*    <AddCircleOutlineOutlined className="mx-1"/> Add new employee*!/*/}
          {/*    /!*</Button>*!/*/}
          {/* </Stack>*/}
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <span className='fw-bold'>&nbsp;</span>
        </Col>
        <Col sm={6} className='d-flex justify-content-end hidden'>
          <Button
              onClick={handleShowCreate}
              className=' add-new-btn rounded mx-2 text-white'
              variant='warning'
          >
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='17'
                height='17'
                viewBox='0 0 20.055 20.207'
            >
              <g id='Edit-Icon' transform='translate(0.5 0.652)'>
                <g id='Icon_feather-edit' data-name='Icon feather-edit'>
                  <path
                      id='Path_44'
                      data-name='Path 44'
                      d='M11.523,6H4.894A1.894,1.894,0,0,0,3,7.894V21.152a1.894,1.894,0,0,0,1.894,1.894H18.152a1.894,1.894,0,0,0,1.894-1.894V14.523'
                      transform='translate(-3 -3.991)'
                      fill='none'
                      stroke='#fff'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1'
                  />
                  <path
                      id='Path_45'
                      data-name='Path 45'
                      d='M21.943,3.406a2.009,2.009,0,1,1,2.841,2.841l-9,9L12,16.191l.947-3.788Z'
                      transform='translate(-6.318 -2.818)'
                      fill='none'
                      stroke='#fff'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='1'
                  />
                </g>
              </g>
            </svg>
            &nbsp;Add Payroll Period
          </Button>{' '}
        </Col>
      </Row>

      <Tabs
        variant={'tabs'}
        className='tabs generate-payroll-tabs'
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k: any) => {
          setKey(k)
        }}
      >
        {
          monthly.length > 0 ?
              <Tab className='p-0' eventKey='monthly-table' title='Monthly'>
                <Card className='border-0  p-0 bordered-card-tabs'>
                  <Card.Body>
                    <Table responsive className="generate-payroll-tables">
                      <thead>
                      <tr className='text-center'>
                        <th style={{ width: '20%' }}>Payroll Period</th>
                        <th style={{ width: '20%' }}>Payroll Name</th>
                        <th style={{ width: '20%' }}>Generated By</th>
                        <th style={{ width: '20%' }}>Date Generated</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                      </thead>
                      <tbody>

                      {monthly.map((pay, index) => (
                          <tr key={index} >
                            <td>{moment(new Date(pay.dayFrom ?? "")).format(dateFormat)} to {moment(new Date(pay.dayTo ?? "")).format(dateFormat)}</td>
                            <td>{pay.payroll_range_name}</td>
                            <td>{pay.isGenerated == 0 ? "" : (pay.first_name ?? "") + (pay.last_name ?? "")}</td>
                            <td className="text-center">{moment(new Date(pay.generated_on ?? "")).format(dateFormat)}</td>
                            <td className='text-center'>
                              {pay.isGenerated == 0 ?
                                  <Button
                                      className=' add-new-btn rounded me-1 text-white'
                                      variant='warning'
                                      onClick={() => generatePayroll(pay.id)}>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='13.565'
                                        height='15.826'
                                        viewBox='0 0 13.565 15.826'
                                    >
                                      <path
                                          id='Icon_metro-file-pdf'
                                          data-name='Icon metro-file-pdf'
                                          d='M15.535,5.559a2.055,2.055,0,0,1,.424.671,2.038,2.038,0,0,1,.177.777V17.182a.844.844,0,0,1-.848.848H3.419a.844.844,0,0,1-.848-.848V3.051A.844.844,0,0,1,3.419,2.2h7.913a2.039,2.039,0,0,1,.777.177,2.055,2.055,0,0,1,.671.424ZM11.614,3.4V6.725h3.321a.965.965,0,0,0-.194-.362L11.976,3.6a.965.965,0,0,0-.362-.194ZM15.005,16.9V7.856H11.331a.844.844,0,0,1-.848-.848V3.334H3.7V16.9h11.3Zm-4.539-5.237a6.719,6.719,0,0,0,.742.495,8.764,8.764,0,0,1,1.033-.062q1.3,0,1.563.433a.427.427,0,0,1,.018.459.025.025,0,0,1-.009.018l-.018.018v.009q-.053.336-.627.336a3.643,3.643,0,0,1-1.016-.177A6.44,6.44,0,0,1,11,12.722a15.9,15.9,0,0,0-3.462.733q-1.351,2.314-2.137,2.314a.514.514,0,0,1-.247-.062L4.946,15.6l-.053-.044a.365.365,0,0,1-.053-.318,1.918,1.918,0,0,1,.495-.808A4.268,4.268,0,0,1,6.5,13.578a.129.129,0,0,1,.2.053.051.051,0,0,1,.018.035q.459-.751.945-1.74a13.479,13.479,0,0,0,.918-2.314A7.139,7.139,0,0,1,8.316,8.2a3.434,3.434,0,0,1,.057-1.126q.1-.353.371-.353h.194a.372.372,0,0,1,.309.132.705.705,0,0,1,.079.6.192.192,0,0,1-.035.071A.23.23,0,0,1,9.3,7.6v.265a11.565,11.565,0,0,1-.124,1.7,4.468,4.468,0,0,0,1.289,2.1Zm-5.087,3.63a3.9,3.9,0,0,0,1.21-1.4,5.034,5.034,0,0,0-.773.742A2.935,2.935,0,0,0,5.379,15.292ZM8.894,7.167a2.626,2.626,0,0,0-.018,1.166q.009-.062.062-.389,0-.026.062-.38a.2.2,0,0,1,.035-.071.025.025,0,0,1-.009-.018.018.018,0,0,0,0-.013.018.018,0,0,1,0-.013A.508.508,0,0,0,8.9,7.131a.025.025,0,0,1-.009.018v.018ZM7.8,13a12.954,12.954,0,0,1,2.508-.715,1.332,1.332,0,0,1-.115-.084,1.582,1.582,0,0,1-.141-.119,4.678,4.678,0,0,1-1.122-1.554,11.8,11.8,0,0,1-.733,1.74q-.265.495-.4.733Zm5.705-.141a2.113,2.113,0,0,0-1.236-.212,3.34,3.34,0,0,0,1.1.247.857.857,0,0,0,.159-.009q0-.009-.018-.026Z'
                                          transform='translate(-2.571 -2.203)'
                                          fill='#fff'
                                      />
                                    </svg>
                                    &nbsp;Generate
                                  </Button>
                                  : <>
                                    <Link
                                      key={`/payroll/generate-payroll/payroll-list/${pay.id}`}
                                      to={`/payroll/generate-payroll/payroll-list/${pay.id}`}
                                      className='links'
                                  >
                                      <Button
                                          variant='transparent'
                                          size={'sm'}
                                      >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18.746'
                                            height='13.906'
                                            viewBox='0 0 18.746 13.906'
                                        >
                                          <g id='View' transform='translate(0.5 0.5)'>
                                            <path
                                                id='Path_21'
                                                data-name='Path 21'
                                                d='M1.5,12.453S4.726,6,10.373,6s8.873,6.453,8.873,6.453-3.226,6.453-8.873,6.453S1.5,12.453,1.5,12.453Z'
                                                transform='translate(-1.5 -6)'
                                                fill='none'
                                                stroke='#a7a9ac'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                            <path
                                                id='Path_22'
                                                data-name='Path 22'
                                                d='M18.34,15.92a2.42,2.42,0,1,1-2.42-2.42A2.42,2.42,0,0,1,18.34,15.92Z'
                                                transform='translate(-7.047 -9.467)'
                                                fill='none'
                                                stroke='#f26930'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                          </g>
                                        </svg>
                                      </Button>
                                    </Link>
                                    {
                                      isRole == "Super Admin" ?
                                          <Button
                                              variant='transparent'
                                              size={'sm'}
                                              title={"Regenerate"}
                                              onClick={() => regeneratePayroll(pay.id)}
                                          >
                                            <Download/>
                                          </Button>
                                          : ""
                                    }

                                  </>
                              }

                            </td>
                          </tr>
                      ))
                      }
                      </tbody>
                    </Table>
                    <Pagination
                        itemsCount={itemsCountMonthly}
                        itemsPerPage={itemsPerPageMonthly}
                        currentPage={currentPageMonthly}
                        lastPage={lastPageMonthly}
                        setCurrentPage={setCurrentPageMonthly}
                        alwaysShown={false}
                        pageClicked={(page: number) => {
                          getPayrollMonthly(page);
                        }
                        }
                    />
                  </Card.Body>
                </Card>
              </Tab> : ""
        }

        {
          semiMonthly.length > 0 ?
              <Tab className='p-0' eventKey='semi-table' title='Semi-Monthly'>
                <Card className='border-0  p-0 bordered-card-tabs'>
                  <Card.Body>
                    <Table responsive className="generate-payroll-tables">
                      <thead>
                      <tr className='text-center'>
                        <th style={{ width: '20%' }}>Payroll Period</th>
                        <th style={{ width: '20%' }}>Payroll Name</th>
                        <th style={{ width: '20%' }}>Generated By</th>
                        <th style={{ width: '20%' }}>Date Generated</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {semiMonthly.map((pay, index) => (
                          <tr key={index} >
                            <td>{moment(new Date(pay.dayFrom ?? "")).format(dateFormat)} to {moment(new Date(pay.dayTo ?? "")).format(dateFormat)}</td>
                            <td>{pay.payroll_range_name}</td>
                            <td>{pay.isGenerated == 0 ? "" : (pay.first_name ?? "") + (pay.last_name ?? "")}</td>
                            <td className="text-center">{moment(new Date(pay.generated_on ?? "")).format(dateFormat)}</td>
                            <td className='text-center'>
                              {pay.isGenerated == 0 ?
                                  <Button
                                      className=' add-new-btn rounded me-1 text-white'
                                      variant='warning'
                                      onClick={() => generatePayroll(pay.id)}>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='13.565'
                                        height='15.826'
                                        viewBox='0 0 13.565 15.826'
                                    >
                                      <path
                                          id='Icon_metro-file-pdf'
                                          data-name='Icon metro-file-pdf'
                                          d='M15.535,5.559a2.055,2.055,0,0,1,.424.671,2.038,2.038,0,0,1,.177.777V17.182a.844.844,0,0,1-.848.848H3.419a.844.844,0,0,1-.848-.848V3.051A.844.844,0,0,1,3.419,2.2h7.913a2.039,2.039,0,0,1,.777.177,2.055,2.055,0,0,1,.671.424ZM11.614,3.4V6.725h3.321a.965.965,0,0,0-.194-.362L11.976,3.6a.965.965,0,0,0-.362-.194ZM15.005,16.9V7.856H11.331a.844.844,0,0,1-.848-.848V3.334H3.7V16.9h11.3Zm-4.539-5.237a6.719,6.719,0,0,0,.742.495,8.764,8.764,0,0,1,1.033-.062q1.3,0,1.563.433a.427.427,0,0,1,.018.459.025.025,0,0,1-.009.018l-.018.018v.009q-.053.336-.627.336a3.643,3.643,0,0,1-1.016-.177A6.44,6.44,0,0,1,11,12.722a15.9,15.9,0,0,0-3.462.733q-1.351,2.314-2.137,2.314a.514.514,0,0,1-.247-.062L4.946,15.6l-.053-.044a.365.365,0,0,1-.053-.318,1.918,1.918,0,0,1,.495-.808A4.268,4.268,0,0,1,6.5,13.578a.129.129,0,0,1,.2.053.051.051,0,0,1,.018.035q.459-.751.945-1.74a13.479,13.479,0,0,0,.918-2.314A7.139,7.139,0,0,1,8.316,8.2a3.434,3.434,0,0,1,.057-1.126q.1-.353.371-.353h.194a.372.372,0,0,1,.309.132.705.705,0,0,1,.079.6.192.192,0,0,1-.035.071A.23.23,0,0,1,9.3,7.6v.265a11.565,11.565,0,0,1-.124,1.7,4.468,4.468,0,0,0,1.289,2.1Zm-5.087,3.63a3.9,3.9,0,0,0,1.21-1.4,5.034,5.034,0,0,0-.773.742A2.935,2.935,0,0,0,5.379,15.292ZM8.894,7.167a2.626,2.626,0,0,0-.018,1.166q.009-.062.062-.389,0-.026.062-.38a.2.2,0,0,1,.035-.071.025.025,0,0,1-.009-.018.018.018,0,0,0,0-.013.018.018,0,0,1,0-.013A.508.508,0,0,0,8.9,7.131a.025.025,0,0,1-.009.018v.018ZM7.8,13a12.954,12.954,0,0,1,2.508-.715,1.332,1.332,0,0,1-.115-.084,1.582,1.582,0,0,1-.141-.119,4.678,4.678,0,0,1-1.122-1.554,11.8,11.8,0,0,1-.733,1.74q-.265.495-.4.733Zm5.705-.141a2.113,2.113,0,0,0-1.236-.212,3.34,3.34,0,0,0,1.1.247.857.857,0,0,0,.159-.009q0-.009-.018-.026Z'
                                          transform='translate(-2.571 -2.203)'
                                          fill='#fff'
                                      />
                                    </svg>
                                    &nbsp;Generate
                                  </Button>
                                  : <>
                                    <Link
                                        key={`/payroll/generate-payroll/payroll-list/${pay.id}`}
                                        to={`/payroll/generate-payroll/payroll-list/${pay.id}`}
                                        className='links'
                                    >
                                      <Button variant='transparent' size={'sm'}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18.746'
                                            height='13.906'
                                            viewBox='0 0 18.746 13.906'
                                        >
                                          <g id='View' transform='translate(0.5 0.5)'>
                                            <path
                                                id='Path_21'
                                                data-name='Path 21'
                                                d='M1.5,12.453S4.726,6,10.373,6s8.873,6.453,8.873,6.453-3.226,6.453-8.873,6.453S1.5,12.453,1.5,12.453Z'
                                                transform='translate(-1.5 -6)'
                                                fill='none'
                                                stroke='#a7a9ac'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                            <path
                                                id='Path_22'
                                                data-name='Path 22'
                                                d='M18.34,15.92a2.42,2.42,0,1,1-2.42-2.42A2.42,2.42,0,0,1,18.34,15.92Z'
                                                transform='translate(-7.047 -9.467)'
                                                fill='none'
                                                stroke='#f26930'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                          </g>
                                        </svg>
                                      </Button>
                                    </Link>
                                    {
                                      isRole == "Super Admin" ?
                                          <Button
                                              variant='transparent'
                                              size={'sm'}
                                              title={"Regenerate"}
                                              onClick={() => regeneratePayroll(pay.id)}
                                          >
                                            <Download/>
                                          </Button>
                                          : ""
                                    }
                                  </>

                              }

                            </td>
                          </tr>
                      ))
                      }
                      </tbody>
                    </Table>
                    <Pagination
                        itemsCount={itemsCountSemiMonthly}
                        itemsPerPage={itemsPerPageSemiMonthly}
                        currentPage={currentPageSemiMonthly}
                        lastPage={lastPageSemiMonthly}
                        setCurrentPage={setCurrentPageSemiMonthly}
                        alwaysShown={false}
                        pageClicked={(page: number) => {
                          getPayrollSemiMonthly(page);
                        }
                        }
                    />
                  </Card.Body>
                </Card>
              </Tab> : ""

        }


        {
          biWeekly.length > 0 ?
              <Tab className='p-0' eventKey='biweekly-table' title='Bi-weekly'>
                <Card className='border-0  p-0 bordered-card-tabs'>
                  <Card.Body>
                    <Table responsive className="generate-payroll-tables">
                      <thead>
                      <tr className='text-center'>
                        <th style={{ width: '20%' }}>Payroll Period</th>
                        <th style={{ width: '20%' }}>Payroll Name</th>
                        <th style={{ width: '20%' }}>Generated By</th>
                        <th style={{ width: '20%' }}>Date Generated</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {biWeekly.map((pay, index) => (
                          <tr key={index} >
                            <td>{moment(new Date(pay.dayFrom ?? "")).format(dateFormat)} to {moment(new Date(pay.dayTo ?? "")).format(dateFormat)}</td>
                            <td>{pay.payroll_range_name}</td>
                            <td>{pay.isGenerated == 0 ? "" : (pay.first_name ?? "") + (pay.last_name ?? "")}</td>
                            <td className="text-center">{moment(new Date(pay.generated_on ?? "")).format(dateFormat)}</td>
                            <td className='text-center'>
                              {pay.isGenerated == 0 ?
                                  <Button
                                      className=' add-new-btn rounded me-1 text-white'
                                      variant='warning'
                                      onClick={() => generatePayroll(pay.id)}>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='13.565'
                                        height='15.826'
                                        viewBox='0 0 13.565 15.826'
                                    >
                                      <path
                                          id='Icon_metro-file-pdf'
                                          data-name='Icon metro-file-pdf'
                                          d='M15.535,5.559a2.055,2.055,0,0,1,.424.671,2.038,2.038,0,0,1,.177.777V17.182a.844.844,0,0,1-.848.848H3.419a.844.844,0,0,1-.848-.848V3.051A.844.844,0,0,1,3.419,2.2h7.913a2.039,2.039,0,0,1,.777.177,2.055,2.055,0,0,1,.671.424ZM11.614,3.4V6.725h3.321a.965.965,0,0,0-.194-.362L11.976,3.6a.965.965,0,0,0-.362-.194ZM15.005,16.9V7.856H11.331a.844.844,0,0,1-.848-.848V3.334H3.7V16.9h11.3Zm-4.539-5.237a6.719,6.719,0,0,0,.742.495,8.764,8.764,0,0,1,1.033-.062q1.3,0,1.563.433a.427.427,0,0,1,.018.459.025.025,0,0,1-.009.018l-.018.018v.009q-.053.336-.627.336a3.643,3.643,0,0,1-1.016-.177A6.44,6.44,0,0,1,11,12.722a15.9,15.9,0,0,0-3.462.733q-1.351,2.314-2.137,2.314a.514.514,0,0,1-.247-.062L4.946,15.6l-.053-.044a.365.365,0,0,1-.053-.318,1.918,1.918,0,0,1,.495-.808A4.268,4.268,0,0,1,6.5,13.578a.129.129,0,0,1,.2.053.051.051,0,0,1,.018.035q.459-.751.945-1.74a13.479,13.479,0,0,0,.918-2.314A7.139,7.139,0,0,1,8.316,8.2a3.434,3.434,0,0,1,.057-1.126q.1-.353.371-.353h.194a.372.372,0,0,1,.309.132.705.705,0,0,1,.079.6.192.192,0,0,1-.035.071A.23.23,0,0,1,9.3,7.6v.265a11.565,11.565,0,0,1-.124,1.7,4.468,4.468,0,0,0,1.289,2.1Zm-5.087,3.63a3.9,3.9,0,0,0,1.21-1.4,5.034,5.034,0,0,0-.773.742A2.935,2.935,0,0,0,5.379,15.292ZM8.894,7.167a2.626,2.626,0,0,0-.018,1.166q.009-.062.062-.389,0-.026.062-.38a.2.2,0,0,1,.035-.071.025.025,0,0,1-.009-.018.018.018,0,0,0,0-.013.018.018,0,0,1,0-.013A.508.508,0,0,0,8.9,7.131a.025.025,0,0,1-.009.018v.018ZM7.8,13a12.954,12.954,0,0,1,2.508-.715,1.332,1.332,0,0,1-.115-.084,1.582,1.582,0,0,1-.141-.119,4.678,4.678,0,0,1-1.122-1.554,11.8,11.8,0,0,1-.733,1.74q-.265.495-.4.733Zm5.705-.141a2.113,2.113,0,0,0-1.236-.212,3.34,3.34,0,0,0,1.1.247.857.857,0,0,0,.159-.009q0-.009-.018-.026Z'
                                          transform='translate(-2.571 -2.203)'
                                          fill='#fff'
                                      />
                                    </svg>
                                    &nbsp;Generate
                                  </Button>
                                  :
                                  <>
                                    <Link
                                        key={`/payroll/generate-payroll/payroll-list/${pay.id}`}
                                        to={`/payroll/generate-payroll/payroll-list/${pay.id}`}
                                        className='links'
                                    >
                                      <Button variant='transparent' size={'sm'}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18.746'
                                            height='13.906'
                                            viewBox='0 0 18.746 13.906'
                                        >
                                          <g id='View' transform='translate(0.5 0.5)'>
                                            <path
                                                id='Path_21'
                                                data-name='Path 21'
                                                d='M1.5,12.453S4.726,6,10.373,6s8.873,6.453,8.873,6.453-3.226,6.453-8.873,6.453S1.5,12.453,1.5,12.453Z'
                                                transform='translate(-1.5 -6)'
                                                fill='none'
                                                stroke='#a7a9ac'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                            <path
                                                id='Path_22'
                                                data-name='Path 22'
                                                d='M18.34,15.92a2.42,2.42,0,1,1-2.42-2.42A2.42,2.42,0,0,1,18.34,15.92Z'
                                                transform='translate(-7.047 -9.467)'
                                                fill='none'
                                                stroke='#f26930'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                          </g>
                                        </svg>
                                      </Button>
                                    </Link>
                                    {
                                      isRole == "Super Admin" ?
                                          <Button
                                              variant='transparent'
                                              size={'sm'}
                                              title={"Regenerate"}
                                              onClick={() => regeneratePayroll(pay.id)}
                                          >
                                            <Download/>
                                          </Button>
                                          : ""
                                    }

                                  </>
                              }

                            </td>
                          </tr>
                      ))
                      }
                      </tbody>
                    </Table>
                    <Pagination
                        itemsCount={itemsCountBiWeekly}
                        itemsPerPage={itemsPerPageBiWeekly}
                        currentPage={currentPageBiWeekly}
                        lastPage={lastPageBiWeekly}
                        setCurrentPage={setCurrentPageBiWeekly}
                        alwaysShown={false}
                        pageClicked={(page: number) => {
                          getPayrollBiWeekly(page);
                        }
                        }
                    />
                  </Card.Body>
                </Card>
              </Tab> : ""

        }

        {
          weekly.length > 0 ?
              <Tab className='p-0' eventKey='weekly-table' title='Weekly'>
                <Card className='border-0  p-0 bordered-card-tabs'>
                  <Card.Body>
                    <Table responsive className="generate-payroll-tables">
                      <thead>
                      <tr className='text-center'>
                        <th style={{ width: '20%' }}>Payroll Period</th>
                        <th style={{ width: '20%' }}>Payroll Name</th>
                        <th style={{ width: '20%' }}>Generated By</th>
                        <th style={{ width: '20%' }}>Date Generated</th>
                        <th className='text-center'>Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {weekly.map((pay, index) => (
                          <tr key={index} >
                            <td>{moment(new Date(pay.dayFrom ?? "")).format(dateFormat)} to {moment(new Date(pay.dayTo ?? "")).format(dateFormat)}</td>
                            <td>{pay.payroll_range_name}</td>
                            <td>{pay.isGenerated == 0 ? "" : (pay.first_name ?? "") + (pay.last_name ?? "")}</td>
                            <td className="text-center">{moment(new Date(pay.generated_on ?? "")).format(dateFormat)}</td>
                            <td className='text-center'>
                              {pay.isGenerated == 0 ?
                                  <Button
                                      className=' add-new-btn rounded me-1 text-white'
                                      variant='warning'
                                      onClick={() => generatePayroll(pay.id)}>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='13.565'
                                        height='15.826'
                                        viewBox='0 0 13.565 15.826'
                                    >
                                      <path
                                          id='Icon_metro-file-pdf'
                                          data-name='Icon metro-file-pdf'
                                          d='M15.535,5.559a2.055,2.055,0,0,1,.424.671,2.038,2.038,0,0,1,.177.777V17.182a.844.844,0,0,1-.848.848H3.419a.844.844,0,0,1-.848-.848V3.051A.844.844,0,0,1,3.419,2.2h7.913a2.039,2.039,0,0,1,.777.177,2.055,2.055,0,0,1,.671.424ZM11.614,3.4V6.725h3.321a.965.965,0,0,0-.194-.362L11.976,3.6a.965.965,0,0,0-.362-.194ZM15.005,16.9V7.856H11.331a.844.844,0,0,1-.848-.848V3.334H3.7V16.9h11.3Zm-4.539-5.237a6.719,6.719,0,0,0,.742.495,8.764,8.764,0,0,1,1.033-.062q1.3,0,1.563.433a.427.427,0,0,1,.018.459.025.025,0,0,1-.009.018l-.018.018v.009q-.053.336-.627.336a3.643,3.643,0,0,1-1.016-.177A6.44,6.44,0,0,1,11,12.722a15.9,15.9,0,0,0-3.462.733q-1.351,2.314-2.137,2.314a.514.514,0,0,1-.247-.062L4.946,15.6l-.053-.044a.365.365,0,0,1-.053-.318,1.918,1.918,0,0,1,.495-.808A4.268,4.268,0,0,1,6.5,13.578a.129.129,0,0,1,.2.053.051.051,0,0,1,.018.035q.459-.751.945-1.74a13.479,13.479,0,0,0,.918-2.314A7.139,7.139,0,0,1,8.316,8.2a3.434,3.434,0,0,1,.057-1.126q.1-.353.371-.353h.194a.372.372,0,0,1,.309.132.705.705,0,0,1,.079.6.192.192,0,0,1-.035.071A.23.23,0,0,1,9.3,7.6v.265a11.565,11.565,0,0,1-.124,1.7,4.468,4.468,0,0,0,1.289,2.1Zm-5.087,3.63a3.9,3.9,0,0,0,1.21-1.4,5.034,5.034,0,0,0-.773.742A2.935,2.935,0,0,0,5.379,15.292ZM8.894,7.167a2.626,2.626,0,0,0-.018,1.166q.009-.062.062-.389,0-.026.062-.38a.2.2,0,0,1,.035-.071.025.025,0,0,1-.009-.018.018.018,0,0,0,0-.013.018.018,0,0,1,0-.013A.508.508,0,0,0,8.9,7.131a.025.025,0,0,1-.009.018v.018ZM7.8,13a12.954,12.954,0,0,1,2.508-.715,1.332,1.332,0,0,1-.115-.084,1.582,1.582,0,0,1-.141-.119,4.678,4.678,0,0,1-1.122-1.554,11.8,11.8,0,0,1-.733,1.74q-.265.495-.4.733Zm5.705-.141a2.113,2.113,0,0,0-1.236-.212,3.34,3.34,0,0,0,1.1.247.857.857,0,0,0,.159-.009q0-.009-.018-.026Z'
                                          transform='translate(-2.571 -2.203)'
                                          fill='#fff'
                                      />
                                    </svg>
                                    &nbsp;Generate
                                  </Button>
                                  : <>
                                    <Link
                                        key={`/payroll/generate-payroll/payroll-list/${pay.id}`}
                                        to={`/payroll/generate-payroll/payroll-list/${pay.id}`}
                                        className='links'
                                    >
                                      <Button variant='transparent' size={'sm'}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='18.746'
                                            height='13.906'
                                            viewBox='0 0 18.746 13.906'
                                        >
                                          <g id='View' transform='translate(0.5 0.5)'>
                                            <path
                                                id='Path_21'
                                                data-name='Path 21'
                                                d='M1.5,12.453S4.726,6,10.373,6s8.873,6.453,8.873,6.453-3.226,6.453-8.873,6.453S1.5,12.453,1.5,12.453Z'
                                                transform='translate(-1.5 -6)'
                                                fill='none'
                                                stroke='#a7a9ac'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                            <path
                                                id='Path_22'
                                                data-name='Path 22'
                                                d='M18.34,15.92a2.42,2.42,0,1,1-2.42-2.42A2.42,2.42,0,0,1,18.34,15.92Z'
                                                transform='translate(-7.047 -9.467)'
                                                fill='none'
                                                stroke='#f26930'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                                strokeWidth='1'
                                            />
                                          </g>
                                        </svg>
                                      </Button>
                                    </Link>
                                    {
                                      isRole == "Super Admin" ?
                                          <Button
                                              variant='transparent'
                                              size={'sm'}
                                              title={"Regenerate"}
                                              onClick={() => regeneratePayroll(pay.id)}
                                          >
                                            <Download/>
                                          </Button>
                                          : ""
                                    }
                                  </>

                              }

                            </td>
                          </tr>
                      ))
                      }
                      </tbody>
                    </Table>
                    <Pagination
                        itemsCount={itemsCountWeekly}
                        itemsPerPage={itemsPerPageWeekly}
                        currentPage={currentPageWeekly}
                        lastPage={lastPageWeekly}
                        setCurrentPage={setCurrentPageWeekly}
                        alwaysShown={false}
                        pageClicked={(page: number) => {
                          getPayrollWeekly(page);
                        }
                        }
                    />

                  </Card.Body>
                </Card>
              </Tab> : ""

        }
      </Tabs>
      <Loader show={showLoading}/>
      <br />

      {/* ADD MODAL*/}
      <Formik
          initialValues={{ payroll_range_name: '', payroll_type: '', payroll_from: '', payroll_to: ''}}
          validationSchema={validationSchema}
          onSubmit={postPayrollPeriod}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="workSiteCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Add Payroll Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Form.Group as={Col} md="6" className="mb-3" controlId='exampleForm.ControlInput1'>
                      <Form.Label className='text-muted required'>Payroll Range Name</Form.Label>
                      <Form.Select
                          size={'sm'}
                          name="payroll_range_name"
                          onChange={handleChange}
                          value={values.payroll_range_name}
                          onBlur={handleBlur}
                          isInvalid={(touched.payroll_range_name && !!errors.payroll_range_name) || !!validation}
                      >
                        <option value="" disabled selected>Select Payroll Range</option>
                        {
                          payrollRangeList.map((payrollRange, index) => (
                              <option key={index} value={payrollRange.id}>{payrollRange.payroll_range_name}</option>
                          ))
                        }


                      </Form.Select>
                      {
                        validation != '' ?
                            (Object.keys(validation).map((message : any, index) => (
                                    <Form.Control.Feedback type="invalid" key={index}>
                                      {validation[message][index]}
                                    </Form.Control.Feedback>
                                ))
                            ) : (
                                <Form.Control.Feedback type="invalid">
                                  {errors.payroll_range_name}
                                </Form.Control.Feedback>
                            )
                      }
                    </Form.Group>

                    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label className='text-muted required'>Payroll Type</Form.Label>
                      <Form.Select
                          size={'sm'}
                          name="payroll_type"
                          onChange={handleChange}
                          value={values.payroll_type}
                          onBlur={handleBlur}
                          isInvalid={(touched.payroll_type && !!errors.payroll_type) || !!validation}
                      >
                        <option value="" disabled selected>Select Type</option>
                        <option value="1">Weekly</option>
                        <option value="2">Bi-weekly</option>
                        <option value="3">Semi-monthly</option>
                        <option value="4">Monthly</option>
                      </Form.Select>
                      {
                        validation != '' ?
                            (Object.keys(validation).map((message : any, index) => (
                                    <Form.Control.Feedback type="invalid" key={index}>
                                      {validation[message][index]}
                                    </Form.Control.Feedback>
                                ))
                            ) : (
                                <Form.Control.Feedback type="invalid">
                                  {errors.payroll_type}
                                </Form.Control.Feedback>
                            )
                      }
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md="6" className="mb-3" controlId='exampleForm.ControlInput1'>
                      <Form.Label className='text-muted required'>Payroll From</Form.Label>
                      <InputGroup size={'sm'}>
                        <InputGroup.Text id='basic-addon1'>
                          <Clock/>
                        </InputGroup.Text>
                        <Form.Control
                            size={'sm'}
                            type='date'
                            placeholder=''
                            name='payroll_from'
                            onChange={handleChange}
                            value={values.payroll_from}
                            onBlur={handleBlur}
                            isInvalid={(touched.payroll_from && !!errors.payroll_from) || !!validation}
                        />
                      </InputGroup>
                      {
                        validation != '' ?
                            (Object.keys(validation).map((message : any, index) => (
                                    <Form.Control.Feedback type="invalid" key={index}>
                                      {validation[message][index]}
                                    </Form.Control.Feedback>
                                ))
                            ) : (
                                <Form.Control.Feedback type="invalid">
                                  {errors.payroll_from}
                                </Form.Control.Feedback>
                            )
                      }
                    </Form.Group>

                    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label className='text-muted required'>Payroll To</Form.Label>
                      <InputGroup size={'sm'}>
                        <InputGroup.Text id='basic-addon1'>
                          <Clock/>
                        </InputGroup.Text>
                        <Form.Control
                            size={'sm'}
                            type='date'
                            placeholder=''
                            name='payroll_to'
                            onChange={handleChange}
                            value={values.payroll_to}
                            onBlur={handleBlur}
                            isInvalid={(touched.payroll_to && !!errors.payroll_to) || !!validation}
                        />
                      </InputGroup>
                      {
                        validation != '' ?
                            (Object.keys(validation).map((message : any, index) => (
                                    <Form.Control.Feedback type="invalid" key={index}>
                                      {validation[message][index]}
                                    </Form.Control.Feedback>
                                ))
                            ) : (
                                <Form.Control.Feedback type="invalid">
                                  {errors.payroll_to}
                                </Form.Control.Feedback>
                            )
                      }
                    </Form.Group>
                  </Row>

                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button
                      type="submit"
                      disabled={isSubmitting}
                      form="workSiteCreate"
                      variant='primary text-white'
                      size={'sm'}
                      className='mx-2'
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
        )}
      </Formik>
    </Container>
  )
}
