import {useState, useEffect} from 'react'
import {
    useMap,
    Marker,
    Tooltip
  } from 'react-leaflet'
import L from "leaflet";

const DefaultIcon = L.icon({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


L.Marker.prototype.options.icon = DefaultIcon;

const LocationMarker = (props: any) => {
    const map = useMap();

    const [position, setPosition] = useState({lat: 0, lng: 0});
    const [radius, setRadius] = useState(null)
    const [locationDetails, setLocationDetails] = useState({
        latitude: '',
        longitude: '',
        address: ''
    })
    const handleChangeStateClick = () => {
        props.latLongGiven("New Some State from Child");
    };

    useEffect(() => {

        if(props.latLongGiven){

            map.locate({setView: true, maxZoom: 20})
                .on('locationfound', function (e:any) {
                    setRadius(e.accuracy);
                    setPosition({lat: props.latLongGiven.latitude, lng: props.latLongGiven.longitude});
                    map.flyTo({lat: props.latLongGiven.latitude, lng: props.latLongGiven.longitude}, map.getZoom());
                });

        }else{
            map.locate({setView: true, maxZoom: 20})
                .on('locationfound', function (e:any) {

                    setPosition(e.latlng);
                    setRadius(e.accuracy);
                    map.flyTo(e.latlng, map.getZoom());

                    const latitude  = e.latlng.lat;
                    const longitude = e.latlng.lng;

                    const url = 'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + latitude + '&lon=' + longitude;
                    const execute = async () => {
                        const data = await (await fetch(url)).json();

                        props.func({
                            latitude: latitude,
                            longitude: longitude,
                            address: data.display_name
                        });
                    };

                    execute();
                });
        }

    }, [map, props.latLongGiven]);
  
    return position === null ? null : (
      <Marker position={position}>
        <Tooltip direction="top" opacity={1} permanent>
            You are within {radius} meters from this point
        </Tooltip>
      </Marker>
    );
}
export default LocationMarker
