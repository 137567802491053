import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

interface LoaderProps {
  show: boolean
}

const LoaderComponent: React.FC<LoaderProps> = ({
  show = true
}) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
    >
      <CircularProgress color='primary' />
    </Backdrop>
  )
}

export default LoaderComponent
