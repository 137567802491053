import React, {useEffect, useState} from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Stack,
    Table,
} from 'react-bootstrap'
import './style.css'
import {
    EditOutlined,
    FilePresentOutlined,
    SaveAsOutlined,
    ViewInArOutlined,
} from '@mui/icons-material'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import axios from "../../../../../api";
import {IAPI_Response} from "../../../../../api/types";
import {
    API_ENDPOINT,
    ERROR_MESSAGE,
    PAYROLL_COMPUTATION,
    ROLE_PERMISSION,
    SUCCESS_MESSAGE,
    TEXT
} from "../../../../../utils/globalConstant";
import {IList, IHoursShift, IShiftName, IBranch} from './types'
import Pagination from 'components/Pagination'
import Alert from "components/Alert";
import moment from "moment/moment";
import Write from "asset/SvgComponent/Write";
import Delete from "asset/SvgComponent/Delete";
import Clock from "asset/SvgComponent/Clock";
import Dialog from "components/Dialog";
import Select from "components/ReactSelect";
import ArrowUp from "asset/SvgComponent/ArrowUp";
import ArrowDown from "asset/SvgComponent/ArrowDown";

export default function Index() {

    const [error, setError] = React.useState('')
    const [showError, setShowError] = React.useState(false)

    const [currentPage, setCurrentPage] = React.useState(0)
    const [lastPage, setLastPage] = React.useState(0)
    const [itemsPerPage, setPerPage] = React.useState(6)
    const [itemsCount, setItemCount] = React.useState(0)
    const [from, setFrom] = React.useState(0)
    const [currentPage2, setCurrentPage2] = React.useState(0)
    const [lastPage2, setLastPage2] = React.useState(0)
    const [itemsPerPage2, setPerPage2] = React.useState(6)
    const [itemsCount2, setItemCount2] = React.useState(0)
    const [from2, setFrom2] = React.useState(0)
    const [currentPage3, setCurrentPage3] = React.useState(0)
    const [lastPage3, setLastPage3] = React.useState(0)
    const [itemsPerPage3, setPerPage3] = React.useState(6)
    const [itemsCount3, setItemCount3] = React.useState(0)
    const [from3, setFrom3] = React.useState(0)
    const [data, setData] = React.useState<IList[]>([])
    const [data2, setData2] = React.useState<IList[]>([])
    const [data3, setData3] = React.useState<IList[]>([])
    const [data4, setData4] = React.useState<IList[]>([])
    const [currentPage4, setCurrentPage4] = React.useState(0)
    const [lastPage4, setLastPage4] = React.useState(0)
    const [itemsPerPage4, setPerPage4] = React.useState(6)
    const [itemsCount4, setItemCount4] = React.useState(0)
    const [from4, setFrom4] = React.useState(0)
    const [data5, setData5] = React.useState<IList[]>([])
    const [currentPage5, setCurrentPage5] = React.useState(0)
    const [lastPage5, setLastPage5] = React.useState(0)
    const [itemsPerPage5, setPerPage5] = React.useState(6)
    const [itemsCount5, setItemCount5] = React.useState(0)
    const [from5, setFrom5] = React.useState(0)
    const [show, setShow] = React.useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => {
        setShow(true)
        setSelectedBranchOption({value: 0, label: "All Branches"})
    }
    const [showLoading, setShowLoading] = React.useState(false)
    const [success, setSuccess] = React.useState('')
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [key, setKey] = useState('8-hour-shift')
    const [addedHoursShift, setAddedHoursShift] = React.useState({
        shift_name: 0,
        type: 0,
        time_from: "",
        time_to : "",
        overtime_in: "",
        overtime_out: "",
        branch_id: 0
    })
    const [selectedHoursShift, setSelectedHoursShift] = React.useState(0)
    const [showConfirm, setShowConfirm] = React.useState(false)
    const handleCloseConfirm = () => {setShowConfirm(false)}
    const [showEdit, setShowEdit] = React.useState(false)
    const handleCloseEdit = () => setShowEdit(false)
    const [selectedData, setSelectedData] = React.useState({
        id: 0,
        time_from: "",
        time_to: "",
        isEightHourShift: 0,
        overtime_in: "",
        overtime_out: "",
        branch_id: 0,
        branch: {
            branch_id: 0,
            branch_name: ""
        },
        shift_name: {
            id: 0,
            shift_name: ""
        }
    })
    const [showOvertimeFields, setShowOvertimeFields] = useState(false)
    const [selectedBranchOption, setSelectedBranchOption] = useState<{value: number, label: string}>({value: 0, label: "All Branches"})
    const [branchOptions, setBranchOptions] = useState<{value: number, label: string}[]>([]);
    const [selectedShiftNameOption, setSelectedShiftNameOption] = useState<{value: number, label: string}>({value: 0, label: "All Shift Name"})
    const [shiftNameOptions, setShiftNameOptions] = useState<{value: number, label: string}[]>([]);
    const [shiftName, setShiftName] = useState<IShiftName[]>([])
    const [sortColumn1, setSortColumn1] = React.useState("shift_name")
    const [sort1, setSort1] = React.useState("asc")
    const [sortColumn2, setSortColumn2] = React.useState("shift_name")
    const [sort2, setSort2] = React.useState("asc")
    const [sortColumn3, setSortColumn3] = React.useState("shift_name")
    const [sort3, setSort3] = React.useState("asc")
    const [sortColumn4, setSortColumn4] = React.useState("shift_name")
    const [sort4, setSort4] = React.useState("asc")
    const [entries1, setEntries1] = React.useState(10)
    const [entries2, setEntries2] = React.useState(10)
    const [entries3, setEntries3] = React.useState(10)
    const [entries4, setEntries4] = React.useState(10)

    const handleChangeEntries1 = (e:any) => {
        setEntries1(e.target.value)
        getEightHoursShift(null, sort1, sortColumn1, e.target.value)
    }

    const handleChangeEntries2 = (e:any) => {
        setEntries2(e.target.value)
        getEightHoursAndOneHoursOvertimeShift(null, sort2, sortColumn2, e.target.value)
    }

    const handleChangeEntries3 = (e:any) => {
        setEntries3(e.target.value)
        getEightHoursAndTwoHoursOvertimeShift(null, sort3, sortColumn3, e.target.value)
    }

    const handleChangeEntries4 = (e:any) => {
        setEntries4(e.target.value)
        getEightHoursAndFourHoursOvertimeShift(null, sort4, sortColumn4, e.target.value)
    }

    const handleSortingChange = (column: string, tab: number) => {
        let sortType = ""
        if(tab == 1){
            setSortColumn1(column)
            if(sort1 == ""){
                setSort1("asc")
                sortType = "asc";
            }else if(sort1 == "asc"){
                setSort1("desc")
                sortType = "desc";
            }else {
                setSort1("asc")
                sortType = "asc";
            }
            getEightHoursShift(null, column, sortType, entries1)
        }else if(tab == 2){
            setSortColumn2(column)
            if(sort2 == ""){
                setSort2("asc")
                sortType = "asc";
            }else if(sort2 == "asc"){
                setSort2("desc")
                sortType = "desc";
            }else {
                setSort2("asc")
                sortType = "asc";
            }
            getEightHoursAndOneHoursOvertimeShift(null, column, sortType, entries2)
        }else if(tab == 3){
            setSortColumn3(column)
            if(sort3 == ""){
                setSort3("asc")
                sortType = "asc";
            }else if(sort3 == "asc"){
                setSort3("desc")
                sortType = "desc";
            }else {
                setSort3("asc")
                sortType = "asc";
            }
            getEightHoursAndTwoHoursOvertimeShift(null, column, sortType, entries3)
        }else if(tab == 4){
            setSortColumn4(column)
            if(sort4 == ""){
                setSort4("asc")
                sortType = "asc";
            }else if(sort4 == "asc"){
                setSort4("desc")
                sortType = "desc";
            }else {
                setSort4("asc")
                sortType = "asc";
            }
            getEightHoursAndFourHoursOvertimeShift(null, column, sortType, entries4)
        }

    }

    const handleChangeDropdownShiftName = (e:any) => {
        // setSelectedShiftNameOption(selectedOption)
        setAddedHoursShift({...addedHoursShift, shift_name: e.target.value})
    }


    const handleChangeDropdownBranch = (selectedOption:any) => {
        setSelectedBranchOption(selectedOption)
        setAddedHoursShift({...addedHoursShift, branch_id: selectedOption.value})
    }

    const getAllShiftName = async () => {
        try {
            const res = await axios.get<IAPI_Response<IShiftName[]>>(API_ENDPOINT.GET_SHIFT_NAME)
            const result = await res
            if (result?.data?.results?.length) {

                const shiftName = result?.data?.results;
                setShiftName(shiftName)
                // setShiftNameOptions([])
                // setShiftNameOptions(current => [...current, {value: 0, label: "All Shift Name"}]);
                // shiftName.map(name => {
                //     setShiftNameOptions(current => [...current, {value: name.id, label: name.shift_name}]);
                // })
            }else{
                setShiftName([])
                // setShiftNameOptions([])
                // setShiftNameOptions(current => [...current, {value: 0, label: "All Shift Name"}]);
            }
        } catch {
            console.log('There is something wrong')
        }
    }

    const getAllBranch = async () => {
        try {
            const res = await axios.get<IAPI_Response<IBranch[]>>(API_ENDPOINT.ALL_BRANCH)
            const result = await res
            if (result?.data?.results?.length) {

                const branches = result?.data?.results;
                setBranchOptions([])
                setBranchOptions(current => [...current, {value: 0, label: "All Branches"}]);
                branches.map(branch => {
                    setBranchOptions(current => [...current, {value: branch.branch_id, label: branch.branch_name}]);
                })
            }else{

                setBranchOptions([])
                setBranchOptions(current => [...current, {value: 0, label: "All Branches"}]);
            }
        } catch {
            console.log('There is something wrong')
        }
    }

    const handleShowEdit = async (id: any) => {
        try {
            const response = await axios.get<IAPI_Response<IList>>(API_ENDPOINT.SHOW_HOURS_SHIFT +'/'+ id)
            const result = await response
            if (result?.data?.results) {
                setSelectedData(result?.data?.results)
                setSelectedBranchOption({value: result?.data?.results.branch.branch_id, label: result?.data?.results.branch.branch_name})
            }
            setShowEdit(true)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const handleShowConfirm = (id: any) => {
        setShowConfirm(true)
        setSelectedHoursShift(id)
    }

    const handleChangeShiftName = (e:any) => {
        setAddedHoursShift({...addedHoursShift, shift_name: e.target.value})
    }

    const handleChangeType = (e:any) => {
        setAddedHoursShift({...addedHoursShift, type: e.target.value})
        if(e.target.value == 2 || e.target.value == 3 || e.target.value == 4){
            setShowOvertimeFields(true)
        }else{
            setShowOvertimeFields(false)
        }
    }

    const handleChangeOvertimeIn = (e:any) => {
        if(addedHoursShift.overtime_out != ""){
            if(addedHoursShift.type == 2){ // 3 hours overtime

                if(addedHoursShift.overtime_out != increase24TimeBy(e.target.value, 3)){
                    setShowError(true)
                    setError("Overtime should not be more than 3 hours.")
                }else{
                    setAddedHoursShift({...addedHoursShift, overtime_in: e.target.value})
                }
            }

            if(addedHoursShift.type == 3){ // 1 hour overtime

                if(addedHoursShift.overtime_out != increase24TimeBy(e.target.value, 1)){
                    setShowError(true)
                    setError("Overtime should not be more than 1 hour.")
                }else{
                    setAddedHoursShift({...addedHoursShift, overtime_in: e.target.value})
                }
            }

            if(addedHoursShift.type == 4){ // 2 hour overtime

                if(addedHoursShift.overtime_out != increase24TimeBy(e.target.value, 2)){
                    setShowError(true)
                    setError("Overtime should not be more than 2 hours.")
                }else{
                    setAddedHoursShift({...addedHoursShift, overtime_in: e.target.value})
                }
            }

        }else{
            setAddedHoursShift({...addedHoursShift, overtime_in: e.target.value})
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);

    }

    const handleChangeOvertimeOut = (e:any) => {
        if(addedHoursShift.overtime_in != ""){

            if(addedHoursShift.type == 2){ // 3 hours overtime
                if(e.target.value != increase24TimeBy(addedHoursShift.overtime_in, 3)){
                    setShowError(true)
                    setError("Overtime should not be more than 3 hours.")
                }else{
                    setAddedHoursShift({...addedHoursShift, overtime_out: e.target.value})
                }
            }

            if(addedHoursShift.type == 3){ // 1 hour overtime
                if(e.target.value != increase24TimeBy(addedHoursShift.overtime_in, 1)){
                    setShowError(true)
                    setError("Overtime should not be more than 1 hour.")
                }else{
                    setAddedHoursShift({...addedHoursShift, overtime_out: e.target.value})
                }
            }

            if(addedHoursShift.type == 4){ // 2 hour overtime
                if(e.target.value != increase24TimeBy(addedHoursShift.overtime_in, 2)){
                    setShowError(true)
                    setError("Overtime should not be more than 2 hours.")
                }else{
                    setAddedHoursShift({...addedHoursShift, overtime_out: e.target.value})
                }
            }
        }else{
            setAddedHoursShift({...addedHoursShift, overtime_out: e.target.value})
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);

    }

    const handleChangeTimeFrom = (e:any) => {
        setAddedHoursShift({...addedHoursShift, time_from: e.target.value})

        if(addedHoursShift.time_to != "" && e.target.value != ""){
            console.log("complete")
        }
    }

    const handleChangeTimeTo = (e:any) => {
        setAddedHoursShift({...addedHoursShift, time_to: e.target.value})

        if(addedHoursShift.time_from != "" && e.target.value != ""){
            console.log("complete")
        }
    }

    const handleChangeEditShiftName = (e:any) => {
        setSelectedData({...selectedData, shift_name: e.target.value})
    }

    const handleChangeEditType = (e:any) => {
        setSelectedData({...selectedData, isEightHourShift: e.target.value})
    }

    const handleChangeEditTimeFrom = (e:any) => {
        setSelectedData({...selectedData, time_from: e.target.value})
    }

    const handleChangeEditTimeTo = (e:any) => {
        setSelectedData({...selectedData, time_to: e.target.value})
    }

    const handleChangeEditOvertimeIn = (e:any) => {
        setSelectedData({...selectedData, overtime_in: e.target.value})
    }

    const handleChangeEditOvertimeOut = (e:any) => {
        setSelectedData({...selectedData, overtime_out: e.target.value})
    }

    const handleChangeEditBranch = (e:any) => {
        setSelectedData({...selectedData, branch_id: e.value})
        setSelectedBranchOption(e)
    }

    const handleUpdateHoursShift = async() => {
        await axios.put(API_ENDPOINT.PUT_HOURS_SHIFT +'/'+ selectedData.id, selectedData).then(response => {
            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.HOURS_SHIFT.UPDATED)
                getEightHoursShift(null, sortColumn1, sort1, entries1);
                getTwelveHoursShift(null);
                getEightHoursAndFourHoursOvertimeShift(null, sortColumn4, sort4, entries4)
                getEightHoursAndOneHoursOvertimeShift(null, sortColumn2, sort2, entries2)
                getEightHoursAndTwoHoursOvertimeShift(null, sortColumn3, sort3, entries3)
            }

            handleCloseEdit();
        }).catch(error => {
            getEightHoursShift(null, sortColumn1, sort1, entries1);
            getTwelveHoursShift(null);
            getEightHoursAndFourHoursOvertimeShift(null, sortColumn4, sort4, entries4)
            getEightHoursAndOneHoursOvertimeShift(null, sortColumn2, sort2, entries2)
            getEightHoursAndTwoHoursOvertimeShift(null, sortColumn3, sort3, entries3)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const handleDeleteHoursShift = async (id : any) => {
        try {
            const response = await axios.delete(API_ENDPOINT.DELETE_HOURS_SHIFT +'/'+ id)
            const result = await response
            if (result?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.DEPARTMENT.DELETED)
                getEightHoursShift(null, sortColumn1, sort1, entries1);
                getTwelveHoursShift(null);
                getEightHoursAndFourHoursOvertimeShift(null, sortColumn4, sort4, entries4)
                setShowConfirm(false)
            }
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
            setShowConfirm(false)
        }

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const addHoursShift = async () => {
        if(addedHoursShift.type == 2){ // 3 hours overtime
            if(addedHoursShift.overtime_out != increase24TimeBy(addedHoursShift.overtime_in, 3)){
                setShowError(true)
                setError("Overtime should not be more than 3 hours.")
                return
            }
        }

        if(addedHoursShift.type == 3){ // 1 hour overtime
            if(addedHoursShift.overtime_out != increase24TimeBy(addedHoursShift.overtime_in, 1)){
                setShowError(true)
                setError("Overtime should not be more than 1 hour.")
                return
            }
        }

        if(addedHoursShift.type == 4){ // 2 hour overtime
            if(addedHoursShift.overtime_out != increase24TimeBy(addedHoursShift.overtime_in, 2)){
                setShowError(true)
                setError("Overtime should not be more than 2 hours.")
                return
            }
        }

        await axios.post(API_ENDPOINT.POST_HOURS_SHIFT, addedHoursShift).then(response => {
            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.HOURS_SHIFT.CREATED)
                getEightHoursShift(null, sortColumn1, sort1, entries1);
                getTwelveHoursShift(null);
                getEightHoursAndFourHoursOvertimeShift(null, sortColumn4, sort4, entries4)
                getEightHoursAndOneHoursOvertimeShift(null, sortColumn2, sort2, entries2)
                getEightHoursAndTwoHoursOvertimeShift(null, sortColumn3, sort3, entries3)
                setShow(false)
            }
        }).catch(error => {
            getEightHoursShift(null, sortColumn1, sort1, entries1);
            getTwelveHoursShift(null);
            getEightHoursAndFourHoursOvertimeShift(null, sortColumn4, sort4, entries4)
            getEightHoursAndOneHoursOvertimeShift(null, sortColumn2, sort2, entries2)
            getEightHoursAndTwoHoursOvertimeShift(null, sortColumn3, sort3, entries3)
            setShow(false)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const getEightHoursShift = async (page_number: null | number, column: string, type: string, entries: number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IHoursShift>>(API_ENDPOINT.ALL_HOURS_SHIFT+"/"+1, {
                params: {
                    page: page_number,
                    sort: type,
                    sort_column: column,
                    entries: entries
                },
            })
            const result = await response

            if (result?.data?.results?.data?.length) {
                setData(result?.data?.results?.data)
                setCurrentPage(result?.data?.results?.current_page)
                setLastPage(result?.data?.results?.last_page)
                setPerPage(result?.data?.results?.per_page)
                setItemCount(result?.data?.results?.total)
                setFrom(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const getTwelveHoursShift = async (page_number: null | number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IHoursShift>>(API_ENDPOINT.ALL_HOURS_SHIFT+"/"+0, {
                params: {
                    page: page_number,
                },
            })
            const result = await response

            if (result?.data?.results?.data?.length) {
                setData2(result?.data?.results?.data)
                setCurrentPage2(result?.data?.results?.current_page)
                setLastPage2(result?.data?.results?.last_page)
                setPerPage2(result?.data?.results?.per_page)
                setItemCount2(result?.data?.results?.total)
                setFrom2(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const getEightHoursAndFourHoursOvertimeShift = async (page_number: null | number, column: string, type: string, entries: number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IHoursShift>>(API_ENDPOINT.ALL_HOURS_SHIFT+"/"+2, {
                params: {
                    page: page_number,
                    sort: type,
                    sort_column: column,
                    entries: entries
                },
            })
            const result = await response

            if (result?.data?.results?.data?.length) {
                setData3(result?.data?.results?.data)
                setCurrentPage3(result?.data?.results?.current_page)
                setLastPage3(result?.data?.results?.last_page)
                setPerPage3(result?.data?.results?.per_page)
                setItemCount3(result?.data?.results?.total)
                setFrom3(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const getEightHoursAndOneHoursOvertimeShift = async (page_number: null | number, column : string, type: string, entries: number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IHoursShift>>(API_ENDPOINT.ALL_HOURS_SHIFT+"/"+3, {
                params: {
                    page: page_number,
                    sort: type,
                    sort_column: column,
                    entries: entries
                },
            })
            const result = await response

            if (result?.data?.results?.data?.length) {
                setData4(result?.data?.results?.data)
                setCurrentPage4(result?.data?.results?.current_page)
                setLastPage4(result?.data?.results?.last_page)
                setPerPage4(result?.data?.results?.per_page)
                setItemCount4(result?.data?.results?.total)
                setFrom4(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const getEightHoursAndTwoHoursOvertimeShift = async (page_number: null | number, column: string, type: string, entries: number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IHoursShift>>(API_ENDPOINT.ALL_HOURS_SHIFT+"/"+4, {
                params: {
                    page: page_number,
                    sort: type,
                    sort_column: column,
                    entries: entries
                },
            })
            const result = await response

            if (result?.data?.results?.data?.length) {
                setData5(result?.data?.results?.data)
                setCurrentPage5(result?.data?.results?.current_page)
                setLastPage5(result?.data?.results?.last_page)
                setPerPage5(result?.data?.results?.per_page)
                setItemCount5(result?.data?.results?.total)
                setFrom5(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const increase24TimeBy = (timeStr: string, increaseBy: number) => {
        const splitedTimeStr = timeStr.split(':');
        const hours = parseInt(splitedTimeStr[0]);
        const minutes = splitedTimeStr[1].substring(0, 2);
        const nextHours = (hours + increaseBy) % 24;
        let nextHoursDisplay = "";
        if (nextHours.toString().length == 1) {
            nextHoursDisplay = "0" + nextHours;
        }else{
            nextHoursDisplay = nextHours.toString()
        }
        return nextHoursDisplay + ":" + minutes;
    }


    useEffect(() => {
        ;(async () => {
            getEightHoursShift(null, sortColumn1, sort1, entries1)
            getTwelveHoursShift(null)
            getEightHoursAndFourHoursOvertimeShift(null, sortColumn4, sort4, entries4)
            getEightHoursAndOneHoursOvertimeShift(null, sortColumn2, sort2, entries2)
            getEightHoursAndTwoHoursOvertimeShift(null, sortColumn3, sort3, entries3)
            getAllBranch()
            getAllShiftName()
        })()
    }, [])


    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Shifts</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none' href='#'>
                        Setup
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className=' text-decoration-none' href='#'>
                        Employee
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Shifts</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col sm={6}>
                        <span className='fw-bold'>&nbsp;</span>
                    </Col>
                    <Col sm={6} className='d-flex justify-content-end hidden'>
                        <Button
                            onClick={handleShow}
                            className=' add-new-btn rounded mx-2 text-white'
                            variant='warning'
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='17'
                                height='17'
                                viewBox='0 0 20.055 20.207'
                            >
                                <g id='Edit-Icon' transform='translate(0.5 0.652)'>
                                    <g id='Icon_feather-edit' data-name='Icon feather-edit'>
                                        <path
                                            id='Path_44'
                                            data-name='Path 44'
                                            d='M11.523,6H4.894A1.894,1.894,0,0,0,3,7.894V21.152a1.894,1.894,0,0,0,1.894,1.894H18.152a1.894,1.894,0,0,0,1.894-1.894V14.523'
                                            transform='translate(-3 -3.991)'
                                            fill='none'
                                            stroke='#fff'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='1'
                                        />
                                        <path
                                            id='Path_45'
                                            data-name='Path 45'
                                            d='M21.943,3.406a2.009,2.009,0,1,1,2.841,2.841l-9,9L12,16.191l.947-3.788Z'
                                            transform='translate(-6.318 -2.818)'
                                            fill='none'
                                            stroke='#fff'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='1'
                                        />
                                    </g>
                                </g>
                            </svg>
                            &nbsp;Add Hours Shift
                        </Button>{' '}
                    </Col>
                </Row>
                <Tabs
                    variant={'tabs'}
                    className='tabs '
                    id='controlled-tab-example'
                    activeKey={key}
                    onSelect={(k: any) => {
                        setKey(k)
                    }}
                >
                    <Tab className='p-0' eventKey='8-hour-shift' title='8 Hours'>
                        <Card className='border-0  p-0 bordered-card-tabs'>
                            <Card.Body>
                                <Table responsive className='bordered-tabs-top mt-3'>
                                    <thead>
                                    <tr className='text-center'>
                                        <th className="table-header" onClick={() => handleSortingChange("shift_name", 1)} style={{ width: '20%' }}>Shift Name {sortColumn1 == "shift_name" ? (sort1 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" onClick={() => handleSortingChange("branch", 1)} style={{ width: '20%' }}>Branch {sortColumn1 == "branch" ? (sort1 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" onClick={() => handleSortingChange("time_from", 1)} style={{ width: '20%' }}>Time From {sortColumn1 == "time_from" ? (sort1 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" onClick={() => handleSortingChange("time_to", 1)} style={{ width: '20%' }}>Time To {sortColumn1 == "time_to" ? (sort1 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data.length > 0 ? (
                                            data.map((payrollComputation) => (
                                                <tr className='mb-2' key={payrollComputation.id}>
                                                    <td className='text-center'>{payrollComputation?.shift_name?.shift_name}</td>
                                                    <td className='text-center'>{payrollComputation?.branch?.branch_name ?? "-"}</td>
                                                    <td className='text-center'>{moment(new Date("2023-01-01 " + payrollComputation.time_from ?? "")).format("h:mm A")}</td>
                                                    <td className='text-center'>{moment(new Date("2023-01-01 " + payrollComputation.time_to ?? "")).format("h:mm A")}</td>
                                                    <td className='text-center'>
                                                        <Button
                                                            onClick={() => handleShowEdit(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Write/>
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleShowConfirm(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Delete/>
                                                        </Button>
                                                    </td>
                                                    <br />
                                                </tr>
                                            ))
                                        ) : (
                                            <tr className='no-item'>
                                                <td colSpan={5}>{TEXT.NOT_FOUND}</td>
                                            </tr>
                                        )
                                    }

                                    </tbody>
                                </Table>
                                <Row>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={5}>
                                                <Pagination
                                                    itemsCount={itemsCount}
                                                    itemsPerPage={itemsPerPage}
                                                    currentPage={currentPage}
                                                    lastPage={lastPage}
                                                    setCurrentPage={setCurrentPage}
                                                    alwaysShown={false}
                                                    pageClicked={(page: number) => {
                                                        getEightHoursShift(page, sortColumn1, sort1, entries1);
                                                    }
                                                    }
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group >
                                                    {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                                                </Form.Group>
                                                <Form.Group className="right-align" >
                                                    <Form.Select
                                                        size={'sm'}
                                                        name="work_shift_id"
                                                        value={entries1}
                                                        onChange={handleChangeEntries1}
                                                        style={{width: '80px'}}
                                                        // onBlur={handleBlur}
                                                        // isInvalid={touched.work_shift_id && !!errors.work_shift_id}
                                                    >
                                                        <option value="10" selected>10</option>
                                                        <option value="25" >25</option>
                                                        <option value="50" >50</option>
                                                        <option value="100" >100</option>

                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab className='p-0' eventKey='8-hour-and-1-hour-overtime-shift' title='8 Hrs. + 1 Hr. OT'>
                        <Card className='border-0  p-0 bordered-card-tabs'>
                            <Card.Body>
                                <Table responsive className='bordered-tabs-top mt-3'>
                                    <thead>
                                    <tr className='text-center'>
                                        <th className="table-header" onClick={() => handleSortingChange("shift_name", 2)} style={{ width: '14%' }}>Shift Name {sortColumn2 == "shift_name" ? (sort2 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" onClick={() => handleSortingChange("branch", 2)} style={{ width: '14%' }}>Branch {sortColumn2 == "branch" ? (sort2 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" onClick={() => handleSortingChange("time_from", 2)} style={{ width: '14%' }}>Time From {sortColumn2 == "time_from" ? (sort2 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" onClick={() => handleSortingChange("time_to", 2)} style={{ width: '14%' }}>Time To {sortColumn2 == "time_to" ? (sort2 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" onClick={() => handleSortingChange("overtime_in", 2)} style={{ width: '14%' }}>Overtime In {sortColumn2 == "overtime_in" ? (sort2 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" onClick={() => handleSortingChange("overtime_out", 2)} style={{ width: '14%' }}>Overtime Out {sortColumn2 == "overtime_out" ? (sort2 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data4.length > 0 ? (
                                            data4.map((payrollComputation) => (
                                                <tr className='mb-2' key={payrollComputation.id}>
                                                    <td className="text-center">{payrollComputation?.shift_name?.shift_name}</td>
                                                    <td className="text-center">{payrollComputation?.branch?.branch_name ?? "-"}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.time_from ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.time_to ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.overtime_in ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.overtime_out ?? "")).format("h:mm A")}</td>

                                                    <td className='text-center'>
                                                        <Button
                                                            onClick={() => handleShowEdit(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Write/>
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleShowConfirm(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Delete/>
                                                        </Button>
                                                    </td>
                                                    <br />
                                                </tr>
                                            ))
                                        ) : (
                                            <tr className='no-item'>
                                                <td colSpan={7}>{TEXT.NOT_FOUND}</td>
                                            </tr>
                                        )
                                    }

                                    </tbody>
                                </Table>
                                <Row>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={5}>
                                                <Pagination
                                                    itemsCount={itemsCount3}
                                                    itemsPerPage={itemsPerPage3}
                                                    currentPage={currentPage3}
                                                    lastPage={lastPage3}
                                                    setCurrentPage={setCurrentPage3}
                                                    alwaysShown={false}
                                                    pageClicked={(page: number) => {
                                                        getEightHoursAndOneHoursOvertimeShift(page, sortColumn2, sort2, entries2);
                                                    }
                                                    }
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group >
                                                    {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                                                </Form.Group>
                                                <Form.Group className="right-align" >
                                                    <Form.Select
                                                        size={'sm'}
                                                        name="work_shift_id"
                                                        value={entries2}
                                                        onChange={handleChangeEntries2}
                                                        style={{width: '80px'}}
                                                        // onBlur={handleBlur}
                                                        // isInvalid={touched.work_shift_id && !!errors.work_shift_id}
                                                    >
                                                        <option value="10" selected>10</option>
                                                        <option value="25" >25</option>
                                                        <option value="50" >50</option>
                                                        <option value="100" >100</option>

                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab className='p-0' eventKey='8-hour-and-2-hours-overtime-shift' title='8 Hrs. + 2 Hrs. OT'>
                        <Card className='border-0  p-0 bordered-card-tabs'>
                            <Card.Body>
                                <Table responsive className='bordered-tabs-top mt-3'>
                                    <thead>
                                    <tr className='text-center'>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("shift_name", 3)}>Shift Name {sortColumn3 == "shift_name" ? (sort3 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("branch", 3)}>Branch {sortColumn3 == "branch" ? (sort3 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("time_from", 3)}>Time From {sortColumn3 == "time_from" ? (sort3 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("time_to", 3)}>Time To {sortColumn3 == "time_to" ? (sort3 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("overtime_in", 3)}>Overtime In {sortColumn3 == "overtime_in" ? (sort3 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("overtime_out", 3)}>Overtime Out {sortColumn3 == "overtime_out" ? (sort3 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data5.length > 0 ? (
                                            data5.map((payrollComputation) => (
                                                <tr className='mb-2' key={payrollComputation.id}>
                                                    <td className="text-center">{payrollComputation?.shift_name?.shift_name}</td>
                                                    <td className="text-center">{payrollComputation?.branch?.branch_name ?? "-"}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.time_from ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.time_to ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.overtime_in ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.overtime_out ?? "")).format("h:mm A")}</td>

                                                    <td className='text-center'>
                                                        <Button
                                                            onClick={() => handleShowEdit(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Write/>
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleShowConfirm(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Delete/>
                                                        </Button>
                                                    </td>
                                                    <br />
                                                </tr>
                                            ))
                                        ) : (
                                            <tr className='no-item'>
                                                <td colSpan={7}>{TEXT.NOT_FOUND}</td>
                                            </tr>
                                        )
                                    }

                                    </tbody>
                                </Table>
                                <Row>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={5}>
                                                <Pagination
                                                    itemsCount={itemsCount3}
                                                    itemsPerPage={itemsPerPage3}
                                                    currentPage={currentPage3}
                                                    lastPage={lastPage3}
                                                    setCurrentPage={setCurrentPage3}
                                                    alwaysShown={false}
                                                    pageClicked={(page: number) => {
                                                        getEightHoursAndTwoHoursOvertimeShift(page, sortColumn3, sort3, entries3);
                                                    }
                                                    }
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group >
                                                    {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                                                </Form.Group>
                                                <Form.Group className="right-align" >
                                                    <Form.Select
                                                        size={'sm'}
                                                        name="work_shift_id"
                                                        value={entries3}
                                                        onChange={handleChangeEntries3}
                                                        style={{width: '80px'}}
                                                        // onBlur={handleBlur}
                                                        // isInvalid={touched.work_shift_id && !!errors.work_shift_id}
                                                    >
                                                        <option value="10" selected>10</option>
                                                        <option value="25" >25</option>
                                                        <option value="50" >50</option>
                                                        <option value="100" >100</option>

                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab className='p-0' eventKey='8-hour-and-3-hours-overtime-shift' title='8 Hrs. + 3 Hrs. OT'>
                        <Card className='border-0  p-0 bordered-card-tabs'>
                            <Card.Body>
                                <Table responsive className='bordered-tabs-top mt-3'>
                                    <thead>
                                    <tr className='text-center'>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("shift_name", 4)}>Shift Name {sortColumn4 == "shift_name" ? (sort4 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("branch", 4)}>Branch {sortColumn4 == "branch" ? (sort4 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("time_from", 4)}>Time From {sortColumn4 == "time_from" ? (sort4 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("time_to", 4)}>Time To {sortColumn4 == "time_to" ? (sort4 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("overtime_in", 4)}>Overtime In {sortColumn4 == "overtime_in" ? (sort4 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                        <th className="table-header" style={{ width: '14%' }} onClick={() => handleSortingChange("overtime_out", 4)}>Overtime Out {sortColumn4 == "overtime_out" ? (sort4 == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>

                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data3.length > 0 ? (
                                            data3.map((payrollComputation) => (
                                                <tr className='mb-2' key={payrollComputation.id}>
                                                    <td className="text-center">{payrollComputation?.shift_name?.shift_name}</td>
                                                    <td className="text-center">{payrollComputation?.branch?.branch_name ?? "-"}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.time_from ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.time_to ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.overtime_in ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.overtime_out ?? "")).format("h:mm A")}</td>

                                                    <td className='text-center'>
                                                        <Button
                                                            onClick={() => handleShowEdit(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Write/>
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleShowConfirm(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Delete/>
                                                        </Button>
                                                    </td>
                                                    <br />
                                                </tr>
                                            ))
                                        ) : (
                                            <tr className='no-item'>
                                                <td colSpan={7}>{TEXT.NOT_FOUND}</td>
                                            </tr>
                                        )
                                    }

                                    </tbody>
                                </Table>
                                <Row>
                                    <Col sm={6}>
                                        <Row>
                                            <Col sm={5}>
                                                <Pagination
                                                    itemsCount={itemsCount3}
                                                    itemsPerPage={itemsPerPage3}
                                                    currentPage={currentPage3}
                                                    lastPage={lastPage3}
                                                    setCurrentPage={setCurrentPage3}
                                                    alwaysShown={false}
                                                    pageClicked={(page: number) => {
                                                        getEightHoursAndFourHoursOvertimeShift(page, sortColumn4, sort4, entries4);
                                                    }
                                                    }
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group >
                                                    {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                                                </Form.Group>
                                                <Form.Group className="right-align" >
                                                    <Form.Select
                                                        size={'sm'}
                                                        name="work_shift_id"
                                                        value={entries4}
                                                        onChange={handleChangeEntries4}
                                                        style={{width: '80px'}}
                                                        // onBlur={handleBlur}
                                                        // isInvalid={touched.work_shift_id && !!errors.work_shift_id}
                                                    >
                                                        <option value="10" selected>10</option>
                                                        <option value="25" >25</option>
                                                        <option value="50" >50</option>
                                                        <option value="100" >100</option>

                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Tab>
                </Tabs>

                 {/*ADD MODAL*/}
                <Modal className='modal' centered show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Add Hours Shift</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Shift Name</Form.Label>
                                    <Form.Select
                                        size={'sm'}
                                        name="shift_name_id"
                                        // value={values.work_site}
                                        onChange={handleChangeDropdownShiftName}
                                        // onBlur={handleBlur}
                                        // isInvalid={touched.work_site && !!errors.work_site}
                                    >
                                        <option value="" selected disabled>Select Shift Name</option>
                                        {
                                            shiftName.map((name, index) => (
                                                <option key={index} value={name.id}>{name.shift_name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    {/*<Select*/}
                                    {/*    name="shift_name_id"*/}
                                    {/*    value={selectedShiftNameOption}*/}
                                    {/*    options={shiftNameOptions}*/}
                                    {/*    onChange={(e: any) => {*/}
                                    {/*        handleChangeDropdownShiftName(e)*/}
                                    {/*    }}*/}
                                    {/*/>*/}

                                </Form.Group>
                            </Row>
                            <br/>
                            <Row>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Select Type</Form.Label>
                                    <Form.Select
                                        size={'sm'}
                                        name="hours_shift"
                                        // value={values.work_site}
                                        onChange={handleChangeType}
                                        // onBlur={handleBlur}
                                        // isInvalid={touched.work_site && !!errors.work_site}
                                    >
                                        <option value="" selected>Select Hours Shift</option>
                                        <option value="1">8 Hours Shift</option>
                                        <option value="3">8 Hours + 1 Hour OT Shift</option>
                                        <option value="4">8 Hours + 2 Hours OT Shift</option>
                                        <option value="2">8 Hours + 3 Hours OT Shift</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Branch</Form.Label>
                                    <Select
                                        name="branch_id"
                                        value={selectedBranchOption}
                                        options={branchOptions}
                                        onChange={(e: any) => {
                                            handleChangeDropdownBranch(e)
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                            <br/>
                            <Row>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Time From</Form.Label>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text id='basic-addon1'>
                                            <Clock/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            size={'sm'}
                                            type='time'
                                            placeholder=''
                                            name={`time_from`}
                                            onChange={handleChangeTimeFrom}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Time To</Form.Label>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text id='basic-addon1'>
                                            <Clock/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            size={'sm'}
                                            type='time'
                                            placeholder=''
                                            name={`time_to`}
                                            onChange={handleChangeTimeTo}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Row>

                            <br/>
                            {
                                showOvertimeFields ?
                                    <Row>
                                        <Form.Group as={Col} md="6" className="position-relative">
                                            <Form.Label column="sm">Overtime In</Form.Label>
                                            <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                    <Clock/>
                                                </InputGroup.Text>
                                                <Form.Control
                                                    size={'sm'}
                                                    type='time'
                                                    placeholder=''
                                                    name={`overtime_in`}
                                                    onChange={handleChangeOvertimeIn}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className="position-relative">
                                            <Form.Label column="sm">Overtime Out</Form.Label>
                                            <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                    <Clock/>
                                                </InputGroup.Text>
                                                <Form.Control
                                                    size={'sm'}
                                                    type='time'
                                                    placeholder=''
                                                    name={`overtime_out`}
                                                    onChange={handleChangeOvertimeOut}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Row> : ""
                            }
                            <br/>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' size={'sm'} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={addHoursShift}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*EDIT MODAL*/}
                <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Add Hours Shift</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Shift Name</Form.Label>
                                    <Form.Select
                                        size={'sm'}
                                        name="shift_name"
                                        value={selectedData?.shift_name?.id}
                                        onChange={handleChangeEditShiftName}
                                    >
                                        <option value="" selected>Select Shift Name</option>
                                        {
                                            shiftName.map((name, index) => (
                                                <option key={index} value={name.id}>{name.shift_name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <br/>
                            <Row>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Select Type</Form.Label>
                                    <Form.Select
                                        size={'sm'}
                                        name="hours_shift"
                                        value={selectedData.isEightHourShift}
                                        onChange={handleChangeEditType}
                                    >
                                        <option value="" selected>Select Hours Shift</option>
                                        <option value="1">8 Hours Shift</option>
                                        <option value="2">8 Hours + 3 Hours OT Shift</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Branch</Form.Label>
                                    <Select
                                        name="branch_id"
                                        value={selectedBranchOption}
                                        options={branchOptions}
                                        onChange={(e: any) => {
                                            handleChangeEditBranch(e)
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                            <br/>
                            <Row>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Time From</Form.Label>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text id='basic-addon1'>
                                            <Clock/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            size={'sm'}
                                            type='time'
                                            placeholder=''
                                            name={`time_from`}
                                            value={selectedData.time_from}
                                            onChange={handleChangeEditTimeFrom}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Time To</Form.Label>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text id='basic-addon1'>
                                            <Clock/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            size={'sm'}
                                            type='time'
                                            placeholder=''
                                            name={`time_to`}
                                            value={selectedData.time_to}
                                            onChange={handleChangeEditTimeTo}
                                            // onBlur={handleBlur}
                                            // isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.start_from : false) && !!ticketTouched.start_from}
                                            // disabled={values.work_shift_id != '0' ? true : false}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                            <br/>
                            {
                                selectedData.isEightHourShift == 2 || selectedData.isEightHourShift == 3 || selectedData.isEightHourShift == 4 ?
                                    <Row>
                                        <Form.Group as={Col} md="6" className="position-relative">
                                            <Form.Label column="sm">Overtime In</Form.Label>
                                            <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                    <Clock/>
                                                </InputGroup.Text>
                                                <Form.Control
                                                    size={'sm'}
                                                    type='time'
                                                    placeholder=''
                                                    name={`overtime_in`}
                                                    value={selectedData.overtime_in}
                                                    onChange={handleChangeEditOvertimeIn}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className="position-relative">
                                            <Form.Label column="sm">Overtime Out</Form.Label>
                                            <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                    <Clock/>
                                                </InputGroup.Text>
                                                <Form.Control
                                                    size={'sm'}
                                                    type='time'
                                                    placeholder=''
                                                    name={`time_to`}
                                                    value={selectedData.overtime_out}
                                                    onChange={handleChangeEditOvertimeOut}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Row> : ""
                            }
                            <br/>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                            Cancel
                        </Button>
                        <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={handleUpdateHoursShift}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Dialog
                    show={showConfirm}
                    setShow={handleCloseConfirm}
                    setEvent={() => {
                        handleDeleteHoursShift(selectedHoursShift)
                    }
                    }
                />

                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <br />
            </Container>
        </>


    )
}
