import React from 'react'

const EditIcon = () => {
  return (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='17'
        viewBox='0 0 20.055 20.207'
      >
        <g id='Edit-Icon' transform='translate(0.5 0.652)'>
          <g id='Icon_feather-edit' data-name='Icon feather-edit'>
            <path
              id='Path_44'
              data-name='Path 44'
              d='M11.523,6H4.894A1.894,1.894,0,0,0,3,7.894V21.152a1.894,1.894,0,0,0,1.894,1.894H18.152a1.894,1.894,0,0,0,1.894-1.894V14.523'
              transform='translate(-3 -3.991)'
              fill='none'
              stroke='#fff'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1'
            />
            <path
              id='Path_45'
              data-name='Path 45'
              d='M21.943,3.406a2.009,2.009,0,1,1,2.841,2.841l-9,9L12,16.191l.947-3.788Z'
              transform='translate(-6.318 -2.818)'
              fill='none'
              stroke='#fff'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1'
            />
          </g>
        </g>
    </svg>
  )
}

export default EditIcon
