import React, { useEffect, useState, useRef, forwardRef } from 'react'
import {Form, Button, Image, InputGroup, Row, Modal, Col} from 'react-bootstrap'
import "leaflet/dist/leaflet.css";
import './style.css'
import moment from 'moment'
import Alert from 'components/Alert'
import Step1 from './Step1'
import Step2 from './Step2'

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};

const Index = () => {
    const [state, setState] = useState(true)
    const [show, setShow] = useState(false);
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [showError, setShowError] = useState(false)
    const [showSuccess, setShowSuccess] =useState(false)
    const [currentStep, setCurrentStep] = useState(1)


    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setDisplayTime(moment().format('LTS'))
    //     }, 1000)
    //     return () => clearInterval(interval)
    // })


    return (
        <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
            <div className='container'>
                <div className='row align-items-center p-0'>
                    <div className='col-md-2' />
                    <div className=' col-md-8 login-body'>
                        <div id='login_container' className='row border-0  rounded-4 shadow-lg'>
                            <div className='col-md-5  p-5' id='left-side'>
                                <div className='text-center mt-3'>
                                    <Image rounded className='w-100 mb-4 logo'  src='/Octaneware1.png' alt='logo'/>
                                </div>
                                <div className='form-group text-center mb-4'>
                                    <div className='col-xs-12'>
                                        <h5 className='fw-bold'>
                                            Optimize HR.
                                        </h5>
                                        <h5 className='fw-bold'>
                                            Simplify Success.
                                        </h5>
                                        <h5 className='fw-bold'>
                                            Register Now!
                                        </h5>
                                    </div>
                                </div>
                                <br />
                            </div>
                            <div className='col-md-7 p-4 bg-primary text-center' id='gradient_div'>
                                <h4 className=' text-white mt-3' id=''>
                                    Register Here!
                                </h4>
                                {(() => {
                                    switch (currentStep) {
                                        case 1:
                                            return <Step1 setCurrentStep={setCurrentStep} />
                                        case 2:
                                            return <Step2 setCurrentStep={setCurrentStep} />
                                        default:
                                            return ""
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2' />
                </div>

                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

            </div>
        </div>
    )
}

export default Index
