import { Button, styled } from '@mui/material'

const SystemButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontStyle: 'capitalize',
  borderRadius: theme.spacing(1),
  '&:first-of-type': { marginRight: theme.spacing(2) },
  '&:focus, &:hover': { boxShadow: 'none' },
}))

export default SystemButton
