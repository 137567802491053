import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  allowance_name: yup
    .string()
    .required(VALIDATION.required)
    .max(250, VALIDATION.max),
  allowance_type: yup
    .string()
    .required(VALIDATION.required)
    .max(100, VALIDATION.max),
  limit_per_month: yup
    .string()
    .required(VALIDATION.required),
})
