import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  payroll_range_name: yup
    .string()
    .required(VALIDATION.required),
  payroll_type: yup
    .string()
    .required(VALIDATION.required),
  payroll_from: yup
    .string()
    .required(VALIDATION.required),
  payroll_to: yup
      .string()
      .required(VALIDATION.required)
})
