import React, {useEffect, useState} from 'react'
import {
  Button,
  Card,
  Col, Form, InputGroup, Modal,
  Row,
  Table
} from "react-bootstrap";
import View from 'asset/SvgComponent/View'
import Location from 'asset/SvgComponent/Location'
import CheckIn from 'asset/SvgComponent/CheckIn'
import Pagination from 'components/Pagination'
import Alert from 'components/Alert'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
  API_ENDPOINT,
  ERROR_MESSAGE, 
  IMAGE_BASE_URL,
  TEXT
} from 'utils/globalConstant'
import { IEmployee, IList, ICheckIn } from './types'
import {MapContainer, TileLayer, Marker, Popup, Tooltip} from "react-leaflet";
import LocationMarker from "components/Marker";
import { Link } from 'react-router-dom';
import moment from "moment";
import Runningtime from 'components/RunningTime'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import useSocketConnnection from "../../../../../hooks/socketconnection";
import ArrowDown from "asset/SvgComponent/ArrowDown";
import ArrowUp from "asset/SvgComponent/ArrowUp";
import "./style.css"
import { Box, ThemeProvider, createTheme } from '@mui/system';
import {Avatar} from "@mui/material";
import Stack from "@mui/material/Stack";

export default function Index() {

  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(6)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)

  const [lists, setData] = React.useState<IList[]>([])

  const [showImage, setShowImage] = React.useState(false);
  const [showLocation, setLocationImage] = React.useState(false);
  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
    address: ''
  })
  const [locationDetails, setLocationDetails] = useState({
    id: 0,
    latitude: 0,
    longitude: 0,
    address: ''
  })
  const [selectedEmployee, setSelectedEmployee] = React.useState(0);
  const [attendanceId, setAttendanceId] = React.useState(0);
  const [showSearchIcon, setShowSearchIcon] = React.useState(false);
  const [searched, setSearched] = React.useState('')
  const [sortColumn, setSortColumn] = React.useState("employee")
  const [sort, setSort] = React.useState("asc")
  // const socket = useSocketConnnection();
  const [gracePeriod, setGracePeriod] = React.useState({
    in: "0",
    out: "0"
  })
  const [paginationClass, setPaginationClass] = React.useState("inherit-position")
  const [checkInSetup, setCheckInSetup] = React.useState("")
  const [showCheckIn, setShowCheckIn] = React.useState(false)
  const handleShowCheckIn = () => setShowCheckIn(true)
  const handleCloseCheckIn = () => setShowCheckIn(false)
  const [checkInItem, setCheckInItem] = React.useState<ICheckIn[]>([])

  const handleClickEmployeeName = (id :any) => {
    localStorage.setItem("tab", "1")
    localStorage.setItem("employee_id_by_user", id)
    window.location.replace('/attendance/attendance-report')
  }

  const handleClickTodaysAttendanceTitle = () => {
    localStorage.setItem("tab", "2")
    window.location.replace('/attendance/attendance-report')
  }

  const handleSortingChange = (column: string) => {
    let sortType = ""
    setSortColumn(column)
    if(sort == ""){
      setSort("asc")
      sortType = "asc";
    }else if(sort == "asc"){
      setSort("desc")
      sortType = "desc";
    }else {
      setSort("asc")
      sortType = "asc";
    }
    getAttendaceToday(null, column, sortType)
  }

  const handleShowSearchField = () => {
    setShowSearchIcon(!showSearchIcon)
  }

  const handleCloseImage = () => setShowImage(false)
  const handleShowImage = (id: any) => {
    setShowImage(true)
    setSelectedEmployee(id);
  }
  const handleCloseLocation = () => setLocationImage(false)
  const handleShowLocation = (id: any) => {
    setSelectedEmployee(id);
    setLocationImage(true)
    setLocationDetails({
      id: 0,
      latitude: 0,
      longitude: 0,
      address: ""
    });
  }
  const getLocation = (data: any) => {
    setLocation(data)
  }

  const handleViewEmployees = () => {
    window.location.replace('/attendance/attendance-report')
  }

  const getAttendaceToday = async (page_number: null | number, column: null | string, sortType: null | string) => {
    try {
      const response = await axios.get<IAPI_Response<IEmployee>>(API_ENDPOINT.GET_ATTENDANCE_TODAY_FOR_MFBC, {
        params: {
          page: page_number,
          searched: searched,
          employee_id: 0,
          worksite: "All Worksites",
          sort: sortType,
          sort_column: column
        },
      })
      const result = await response

      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
        setGracePeriod({
          in: result?.data?.results?.grace_period_in,
          out: result?.data?.results?.grace_period_out
        })

      }else{
        setData([])
        setItemCount(0)
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleTimeStampChange = (e: any) => {
    const id = e.target.value;
    if(id != ""){
      setAttendanceId(id);
      lists.map((employee, index) =>{
        if(employee.employee_id == selectedEmployee){
          employee?.attendancesv2?.map((attendance, index2) => {
            attendance.locations_and_images.map(location => {
              if(parseInt(id) == location.id){
                const newLocation = {
                  id: location.id,
                  latitude: parseFloat(location.latitude),
                  longitude: parseFloat(location.longitude),
                  address: location.address
                }
                setLocationDetails({...newLocation});

              }
            })


          })
        }
      }
      );

    }


  }

  const getCheckInSetup = async () => {

    try {
      const res = await axios.get<IAPI_Response<{checkInAttendance: ""}>>(API_ENDPOINT.GET_CHECKIN_SETUP)
      const result = await res

      if(result.data.results){
        setCheckInSetup(result.data.results.checkInAttendance)
      }

    } catch {
      console.log('There is something wrong')
    }
  }

  const handleViewCheckIn = (check_in: ICheckIn[]) => {
    if(check_in.length > 0){
      setCheckInItem(check_in)
      handleShowCheckIn()
    }
  }

  useEffect(() => {
    ;(async () => {
      getAttendaceToday(null, sortColumn, sort)
      getCheckInSetup()
    })()
  }, [])

  // useEffect(() => {
  //   socket.on('attendance',(data) => {
  //     console.log(data);
  //   });
  // }, [])

  useEffect( () => {
    const timer = setTimeout(() => {
      getAttendaceToday(null, sortColumn, sort)
    }, 300)

    return () => clearTimeout(timer)
  }, [searched])

  useEffect(() => {
    const interval = setInterval(() => {
      getAttendaceToday(null, sortColumn, sort)
    }, 60000)
    return () => clearInterval(interval)
  })


  return (
    <>
      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />
      <Card className="border-0  p-0 bordered-card today-attendance-dashboard" style={{overflowY: 'hidden'}}>
        <Card.Body className="pb-0">
            <Row className="p-1">
              <Col sm={6}>
                <span className="fw-bold table-header" onClick={handleClickTodaysAttendanceTitle}>Today's Attendance</span>
              </Col>
              <Col sm={3}></Col>
              <Col sm={3} className='d-flex justify-content-end hidden'>
                {!showSearchIcon ?
                    <InputGroup size='sm' className={'w-30'}>
                      <Form.Control
                          aria-label='search'
                          aria-describedby='inputGroup-sizing-sm'
                          placeholder='Search...'
                          onChange={e => setSearched(e.target.value)}
                      />
                      <InputGroup.Text className='bg-transparent search-input ' onClick={handleShowSearchField}>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                    : <InputGroup size='sm' className='d-flex justify-content-end hidden'>
                      <InputGroup.Text className='bg-transparent search-input ' onClick={handleShowSearchField}>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                }
              </Col>
            </Row>
            {/*<hr/>*/}
            <Table responsive size="sm" className="bordered-tabs-top mt-2" style={{whiteSpace: 'nowrap'}}>
              <thead className="thead-table">
                <tr className="text-center">
                  <th style={{width: '5%'}} >No.</th>
                  <th className="table-header" style={{width: '17%'}} onClick={() => handleSortingChange("employee")}>
                    Employee {sortColumn == "employee" ? (sort == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}
                  </th>
                  <th className="table-header" style={{width: '10%'}} onClick={() => handleSortingChange("status")}>
                    Status {sortColumn == "status" ? (sort == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}
                  </th>
                  <th className="table-header" style={{width: '13%'}} onClick={() => handleSortingChange("clock_in_am")}>
                    Clock In {sortColumn == "clock_in_am" ? (sort == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}
                  </th>
                  <th className="table-header" style={{width: '13%'}} onClick={() => handleSortingChange("clock_out_am")}>
                    Clock Out {sortColumn == "clock_out_am" ? (sort == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}
                  </th>
                  <th style={{width: '13%'}}>
                    Worked Time
                  </th>
                  <th className="table-header" style={{width: '10%'}} onClick={() => handleSortingChange("late")}>
                    Late {sortColumn == "late" ? (sort == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}
                  </th>
                  {
                    checkInSetup == "1" &&
                      <th style={{width: '10%'}}>
                        Check In
                      </th>
                  }

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {lists.length > 0 ? (
                    lists.map((item, index) => 
                      <tr className="mb-2" key={index}>
                        <td>{from + index}</td>
                        <td className="fw-bold table-header text-decoration-underline" onClick={() => handleClickEmployeeName(item.employee_id)}>
                          <Stack direction='row' spacing={1}>
                            <div>
                              {
                                item.photo != "" ?
                                    <Avatar
                                        sx={{ width: 20, height: 20 }}
                                        src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                        className={"profile-avatar"}
                                    ></Avatar> :
                                    <Avatar
                                        sx={{ width: 20, height: 20}}
                                        src={""}
                                        className={"profile-avatar"}
                                    ></Avatar>
                              }
                            </div>
                            <div>{item.last_name}, {item.first_name}</div>
                          </Stack>
                        </td>
                        <td className="text-center">
                          <div className="d-grid">
                            {(() => {
                              if (item.leaves && item.leaves.length > 0) {
                                return (
                                  <Button 
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="info"
                                    size="sm"
                                    >
                                    Leave
                                  </Button>
                                )
                              } else if(item.attendancesv2 && item.attendancesv2.length > 0) {
                                return (
                                  <Button 
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="success"
                                    size="sm"
                                    >
                                    Present
                                  </Button>
                                )
                              } else if(item.schedules && item.schedules.length > 0){
                                return (
                                    <Button
                                        className=" p-1 pt-0 pb-0"
                                        style={{fontSize: '12px', color: 'white'}}
                                        variant="secondary"
                                        size="sm"
                                    >
                                      Day-Off
                                    </Button>
                                )
                              }

                              return (
                                <Button 
                                  className=" p-1 pt-0 pb-0"
                                  style={{fontSize: '12px', color: 'white'}}
                                  variant='danger'
                                  size="sm"
                                  >
                                  Absent
                                </Button>
                              )
                            })()}
                          </div>
                        </td>
                        <td className="text-center">{item.in_time != null ? item.in_time : '-'}</td>
                        <td className="text-center">{item.out_time != null ? item.out_time : '-'}</td>
                        <Runningtime
                            in_time={item.actual_in_time}
                            out_time={item.actual_out_time}
                            in_time_pm={item.actual_in_time_pm}
                            out_time_pm={item.actual_out_time_pm}
                            worked_time={item.total_worked_time}
                            worked_time_am={item.worked_time}
                            worked_time_pm={item.worked_time_pm}
                            type={'worked_time'}
                            break_in={item.actual_break_in}
                            break_out={item.actual_break_out}
                            start_from={item.start_from}
                            end_to={item.end_to}
                            break_start={item.break_start}
                            break_end={item.break_end}
                            grace_period_in={gracePeriod.in}
                            grace_period_out={gracePeriod.out}
                        />

                        <td className="text-center">{item.total_late_time != null ? item.total_late_time : '-'}</td>
                        {
                            checkInSetup == "1" &&
                            <td
                                className={"text-center" + (item.check_in.length > 0 ? " clickable-check-in": "")}
                                onClick={() => handleViewCheckIn(item.check_in)}
                            >
                              {item.check_in.length} {item.check_in.length <= 1 ? "Check In" : "Check Ins"}
                            </td>
                        }
                        <td className="text-center">
                          <Button 
                            className=" p-1 pt-0 pb-0"
                            style={{fontSize: '12px', color: '#F26930'}}
                            variant=" btn-transparent"
                            size="sm"
                            title="Show Locations"
                            onClick={() => handleShowLocation(item.employee_id)}
                            >
                            <Location/>
                          </Button>
                          <Button 
                            className=" p-1 pt-0 pb-0"
                            style={{fontSize: '12px', color: '#F26930'}}
                            variant=" btn-transparent"
                            size="sm"
                            title="Show Images"
                            onClick={() => handleShowImage(item.employee_id)}
                            >
                            <View/>
                          </Button>
                        </td>
                      </tr>
                    )
                    ) : (
                    <tr className='no-item'>
                      <td colSpan={8 + (checkInSetup == "1" ? 1 : 0)}>{TEXT.NOT_FOUND}</td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>
            <Row style={{
              position: itemsCount >= 6 ? "inherit" : "absolute",
              bottom: itemsCount >= 6 ? "0" : "0%",
              width: "100%",
              overflow: "hidden"
            }} >
              <Col sm={8}>
                <Pagination
                    itemsCount={itemsCount}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getAttendaceToday(page, sortColumn, sort);
                    }
                    }

                />
              </Col>
              <Col sm={4} >
                <div className="text-right text-decoration-underline">
                  <h6 className="fw-bold text-truncate" style={{fontSize: '12px', cursor: 'pointer'}} onClick={handleViewEmployees}>
                    View All Employees ({itemsCount})
                  </h6>
                </div>
              </Col>

            </Row>

    
        </Card.Body>
      </Card>
      {/* IMAGE MODAL*/}
      <Modal className='modal' size="lg" centered show={showImage} onHide={handleCloseImage}>
        <Modal.Header closeButton >
          <Modal.Title className='fw-bold'>CAPTURED IMAGE/S</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {lists.map((employee, index) => (
                employee.employee_id == selectedEmployee?
                    (
                        employee?.attendancesv2.length > 0 ?
                          (employee?.attendancesv2?.map((attendance, index) => (
                              attendance.locations_and_images.map(location => (
                                  <Form.Group as={Col} key={location.id} md="4" style={{padding:"10px"}}>
                                    <a href={IMAGE_BASE_URL + location.photo_url} target="_blank" className="photo-a" rel="noreferrer">
                                      <div className="card" style={{width: "15rem"}}>
                                        <img style={{height: "130px", width: "auto", objectFit: "cover"}} className="card-img-top" alt={"Captured Image"} src={IMAGE_BASE_URL + location.photo_url}/>
                                        <div className="card-body d-flex flex-column align-items-center">
                                          {location.attendance_status == "in_time"? <p className="card-text" style={{color:"#1EA66D"}}>{attendance.in_time} - Clock in</p> :
                                              location.attendance_status == "out_time"? <p className="card-text" style={{color:"#DA2B5D"}}>{attendance.out_time} - Clock out</p> : ""}
                                        </div>
                                      </div>
                                    </a>
                                  </Form.Group>
                              ))
                          ))) :
                            <p>No Attendance yet.</p>

                    ): ""

            ))}
          </Row>


        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={handleCloseImage}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* LOCATION MODAL*/}
      <Modal className='modal' size="lg" centered show={showLocation} onHide={handleCloseLocation}>
        <Modal.Header closeButton style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Modal.Title className='fw-bold'>LOCATIONS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          <Form.Group as={Col} md="5">
            <Form.Label column="sm"></Form.Label>
          </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label column="sm">Timestamps</Form.Label>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="4"></Form.Group>
            <Form.Group as={Col} md="4" className="position-relative">
              <Form.Select
                  size={'sm'}
                  name="timestamps"
                  // onBlur={handleBlur}
                  onChange={handleTimeStampChange}
              >
                <option value="">Select Timestamp</option>
                {lists.map((employee, index) => (
                    employee.employee_id == selectedEmployee?
                        (
                            (employee?.attendancesv2?.map((attendance, index) => (
                                attendance.locations_and_images.map((location, index2) => (
                                    <option value={location.id} key={location.id} style={{
                                      color: location.attendance_status == "out_time" ? "#DA2B5D": location.attendance_status == "in_time" ? "#1EA66D":
                                          location.attendance_status == "break_out" || location.attendance_status == "break_in"? "#FBA91A": ""
                                    }}>
                                      {location.attendance_status == "in_time"? attendance.in_time + " - Clock in":
                                        location.attendance_status == "out_time"?  attendance.out_time + " - Clock out":
                                                    location.attendance_status == "check in"? "Check in":""}
                                    </option>
                                ))

                            )))

                        ): ""

                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid" tooltip>
                {/*{errors.marital_status}*/}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <br/>
          <Row>
            <MapContainer
                style={{height: '400px'}}
                center={[locationDetails.latitude, locationDetails.longitude]}
                zoom={16}
                scrollWheelZoom={false}
                attributionControl={false}
            >
              <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

              />

              {locationDetails.latitude != 0 && locationDetails.longitude != 0 ? <LocationMarker func={getLocation} latLongGiven={locationDetails}/> : "" }

            </MapContainer>
            <div className="text-center">
              Location : <b>{locationDetails.address}</b>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={handleCloseLocation}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* All IMAGE TODAY MODAL*/}
      <Modal className='modal' size="lg" centered show={showCheckIn} onHide={handleCloseCheckIn}>
        <Modal.Header closeButton >
          <Modal.Title className='fw-bold text-center'>CHECKED IN DETAILS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive size='sm'>
            <thead >
            <tr>
              <th className='text-center' style={{width: '20%'}}>Time</th>
              <th className='text-center' style={{width: '40%'}}>Captured Image</th>
              <th className='text-center' style={{width: '40%'}}>Signature</th>
            </tr>
            </thead>
            <tbody>
            {
              checkInItem.map((check, index) => (
                  <tr key={index}>
                    <td style={{verticalAlign: 'middle'}} className='text-center fw-bold'>
                      {moment(check.created_at).format((localStorage.getItem('date_format') ?? "YYYY-MM-DD"))}
                    <br/>
                      {moment(check.created_at).format(localStorage.getItem('time_format') ?? "hh:mm A")}
                    </td>
                    <td>
                      <Row>
                        <Form.Group as={Col} md="3">
                        </Form.Group>
                        <Form.Group as={Col} md="6" style={{padding:"10px"}}>
                          <a href={IMAGE_BASE_URL + check.photo_url} target="_blank" className="photo-a" rel="noreferrer">
                            <div className="card" >
                              <img style={{height: "100px", width: "auto"}} className="card-img-top" alt={"Captured Image"} src={IMAGE_BASE_URL + check.photo_url}/>
                              <div className="card-body d-flex flex-column align-items-center" style={{padding: '5px'}}>
                                <p title={check.address} className="card-text display-address text-center" style={{color:"black", fontSize: '90%'}}>{check.address ?? "-"}</p>
                              </div>
                            </div>
                          </a>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                        </Form.Group>
                      </Row>

                    </td>
                    <td>
                      <Row>
                        <Form.Group as={Col} md="3">
                        </Form.Group>
                        <Form.Group as={Col} md="6" style={{padding:"10px"}}>
                          <a href={IMAGE_BASE_URL + check.signature} target="_blank" className="photo-a" rel="noreferrer">
                            <div className="card" >
                              <img style={{height: "100px", width: "auto"}} className="card-img-top" alt={"Captured Image"} src={IMAGE_BASE_URL + check.signature}/>
                              <div className="card-body d-flex flex-column align-items-center border-top" style={{padding: '5px'}}>
                                <p title={check.notes} className="card-text display-address" style={{color:"black", fontSize: '90%'}}>{check.notes ?? "-"}</p>
                              </div>
                            </div>
                          </a>
                        </Form.Group>
                        <Form.Group as={Col} md="3">
                        </Form.Group>
                      </Row>

                    </td>
                  </tr>
              ))
            }

            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={handleCloseCheckIn}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
