import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  shift_name: yup
    .string()
    .required(VALIDATION.required)
    .max(150, VALIDATION.max),
  shift: yup.array().of(
    yup.object().shape({
      start_time: yup.string().required(VALIDATION.required),
      end_time: yup.string().required(VALIDATION.required),
      break_in: yup.string().required(VALIDATION.required),
      break_out: yup.string().required(VALIDATION.required),
      late_count_time: yup.string().required(VALIDATION.required)
    })
  )
})
