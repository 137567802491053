import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  employee_id: yup.number().required(VALIDATION.required),
  leave_type_id: yup.string().required(VALIDATION.required),
  application_from_date: yup.string().required(VALIDATION.required),
  application_to_date: yup.string().required(VALIDATION.required),
  number_of_day: yup.string().required(VALIDATION.required),
  purpose: yup.string().required(VALIDATION.required),
  isHalfOrWholeDay: yup.string().required(VALIDATION.required),
})
