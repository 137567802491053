import axios from 'axios'

export default axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-with': 'XMLHttpRequest',
    Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('jwt') as string)
  }
})
