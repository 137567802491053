import React from 'react'

const LeavesIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16.205'
      height='15.865'
      viewBox='0 0 16.205 15.865'
    >
      <g id='Leaves' transform='translate(0.67 0.5)'>
        <path
          id='Path_32'
          data-name='Path 32'
          d='M10.432,3,3,6.716l7.432,3.716,7.432-3.716Z'
          transform='translate(-3 -3)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_33'
          data-name='Path 33'
          d='M3,25.5l7.432,3.716L17.864,25.5'
          transform='translate(-3 -14.352)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_34'
          data-name='Path 34'
          d='M3,18l7.432,3.716L17.864,18'
          transform='translate(-3 -10.568)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default LeavesIcon
