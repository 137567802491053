import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {ChartOptions} from "chart.js/dist/types";

// Register the components with Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarChart = (props: {labelName: string, labels: string[], values: number[]}) => {

    const colorList = [
        '#0088FE',
        '#00C4A0',
        '#FFBB28',
        '#FF8043',
        '#8884D8',
        '#82C99D',
        '#A4DE6D',
        '#D2EC54',
        '#FFA07A',
    ];

    // Define your data
    const data = {
        labels: props.labels,
        datasets: [
            {
                label: props.labelName,
                data: props.values,
                backgroundColor: colorList.slice(0, props.values.length), // Use colorList here
                borderColor: colorList.slice(0, props.values.length), // Optional: Set border color
                borderWidth: 1,
                barThickness: 15, // Adjust the width of each bar
            },
        ],
    };

    // Define options for the chart
    const options:ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            datalabels: {
                display: false, // Hide data labels inside the bars
            },
        },
        scales: {
            x: {
                title: {
                    display: false
                },
                ticks: {
                    autoSkip: false, // Display all x-axis labels
                    font: {
                        size: 12, // Adjust font size to fit labels better
                    },
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'No. of Days', // Label for the y-axis
                },
                beginAtZero: true, // Start y-axis at zero
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '250px' }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default BarChart;
