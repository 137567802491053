import React from 'react'

const AttendanceDayoffIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 18 18'>
      <g id='Attendance-Dayoff-Icon' transform='translate(1 1)'>
        <path
          id='Path_74'
          data-name='Path 74'
          d='M19,11a8,8,0,1,1-8-8A8,8,0,0,1,19,11Z'
          transform='translate(-3 -3)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  )
}

export default AttendanceDayoffIcon
