import * as React from "react"
const ScreenshotIcon = (props: {color: string}) => (
    <svg xmlns="http://www.w3.org/2000/svg"
         width='16.607'
         height='16.607'
         viewBox="0 0 24 24">
        <path stroke={props.color}
              strokeWidth={1}
              d="M3 8H2V4.5A2.5 2.5 0 0 1 4.5 2H8v1H4.5A1.5 1.5 0 0 0 3 4.5zm1.5 14A1.5 1.5 0 0 1 3 20.5V17H2v3.5A2.5 2.5 0 0 0 4.5 23H8v-1zM22 20.5a1.5 1.5 0 0 1-1.5 1.5H17v1h3.5a2.5 2.5 0 0 0 2.5-2.5V17h-1zM20.5 2H17v1h3.5A1.5 1.5 0 0 1 22 4.5V8h1V4.5A2.5 2.5 0 0 0 20.5 2zM14 7h4v4h1V6h-5zm-7 4V7h4V6H6v5zm11 3v4h-4v1h5v-5zm-7 4H7v-4H6v5h5z" />
        <path fill="none" d="M0 0h24v24H0z" />
    </svg>
)
export default ScreenshotIcon
