import React from 'react'

const LeavesActiveIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='16.205'
      height='15.365'
      viewBox='0 0 16.205 15.365'
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='0.5'
          x2='0.5'
          y2='1'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#f26930' />
          <stop offset='1' stopColor='#fba91a' />
        </linearGradient>
      </defs>
      <g id='LeavesActive' transform='translate(0.67)'>
        <path
          id='Path_32'
          data-name='Path 32'
          d='M10.432,3,3,6.716l7.432,3.716,7.432-3.716Z'
          transform='translate(-3 -3)'
          fill='url(#linear-gradient)'
        />
        <path
          id='Path_33'
          data-name='Path 33'
          d='M3,25.5l7.432,3.716L17.864,25.5'
          transform='translate(-3 -14.352)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_34'
          data-name='Path 34'
          d='M3,18l7.432,3.716L17.864,18'
          transform='translate(-3 -10.568)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default LeavesActiveIcon
