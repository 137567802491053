import React, {useEffect, useState} from 'react'
import {
  Button,
  Card,
  Col, Form,
  Modal,
  Row,
  Stack,
  Table
} from "react-bootstrap";
import Accept from 'asset/SvgComponent/Accept'
import Reject from 'asset/SvgComponent/Reject'
import View from 'asset/SvgComponent/View'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE, IMAGE_BASE_URL
} from 'utils/globalConstant'
import {
  IList,
  ILeaveRequest,
  IItem,
  IRequest,
  IItemRequestOvertime,
  IItemRequestCertificate,
  IItemRequestOfficial, ILevelApproval, IItemRequestShiftSwap
} from './types'
import './style.css'
import {Avatar} from "@mui/material";
import moment from "moment"
import {IItemRequest} from "../../../../Attendance/OfficialBusiness/Construction/types";
import Pagination from "components/Pagination";
import AcceptDisabled from "asset/SvgComponent/AcceptDisabledIcon";
import RejectDisabled from "asset/SvgComponent/RejectDisabledIcon";
import Download from "asset/SvgComponent/Download";
import SwapIcon from "asset/SvgComponent/SwapIcon";


export default function Index() {
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [lists, setData] = React.useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    leave_application_id: 0,
    application_from_date: '',
    application_to_date: '',
    application_date: '',
    number_of_day: '',
    purpose: '',
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    leave_type: {
      leave_type_id: 0,
      leave_type_name: '',
      leave_description: ''
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      level: "",
      approvers_order: ""
    }],
    request_type: '',
    current: "",
    applied_by_first_name: "",
    applied_by_last_name: "",
    levels_of_approval: [{
      approvers_order: "",
      level: ""
    }],
    created_by_first_name: "",
    created_by_last_name: ""
  })
  const [itemRequestOfficial, setItemRequestOfficial] = useState<IItemRequestOfficial>({
    official_business_id: 0,
    employee_id: 0,
    date_from: '',
    date_to: '',
    time_from: '',
    time_to: '',
    clock_in_pm: '',
    clock_out_pm: '',
    location: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      level: "",
      approvers_order: ""
    }],
    request_type: '',
    current: "",
    applied_by_first_name: "",
    applied_by_last_name: "",
    levels_of_approval: [{
      approvers_order: "",
      level: ""
    }]
  })

  const [itemRequestOvertime, setItemRequestOvertime] = useState<IItemRequestOvertime>({
    id: 0,
    employee_id: 0,
    classification: '',
    overtime_date: '',
    overtime_start: '',
    overtime_end: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      level: "",
      approvers_order: ""
    }],
    created_by_last_name: "",
    created_by_first_name: "",
    request_type: '',
    current: "",
    applied_by_first_name: "",
    applied_by_last_name: "",
    levels_of_approval: [{
      approvers_order: "",
      level: ""
    }]
  })

  const [itemRequestCertificate, setItemRequestCertificate] = useState<IItemRequestCertificate>({
    id: 0,
    employee_id: 0,
    type: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    requested_attendance: [{
      id: 0,
      certificate_of_attendance_id: 0,
      time_in_out: '',
      time_in_out_date: '',
      time_in_out_time: ''
    }],
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      level: "",
      approvers_order: ""
    }],
    request_type: '',
    current: "",
    applied_by_first_name: "",
    applied_by_last_name: "",
    levels_of_approval: [{
      approvers_order: "",
      level: ""
    }],
    documents: []
  })

  const [itemRequestShiftSwap, setItemRequestShiftSwap] = useState<IItemRequestShiftSwap>({
    swap_first_name: "",
    swap_last_name: "",
    swap_photo: "",
    branch_id: 0,
    end_to: "",
    first_name: "",
    is_swap: 0,
    last_name: "",
    photo: "",
    schedule_date: "",
    swap_schedule_date: "",
    start_from: "",
    swap_start_from: "",
    swap_end_to: "",
    is_rest_day: 0,
    swap_is_rest_day: 0,
    swap_employee_id: 0,
    id: 0,
    employee_id: 0,
    type: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    approvers_order: 0,
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    swap_employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      level: '',
      approvers_order: ''
    }],
    approve_by: "0",
    applied_by_first_name: "",
    applied_by_last_name: "",
    levels_of_approval: [
      {
        approvers_order: "",
        level: ""
      }
    ],
    current: ''
  })

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [showOfficial, setShowOfficial] = useState(false)
  const handleCloseOfficial = () => setShowOfficial(false)
  const handleShowOfficial = () => setShowOfficial(true)

  const [showOvertime, setShowOvertime] = useState(false)
  const handleCloseOvertime = () => setShowOvertime(false)
  const handleShowOvertime = () => setShowOvertime(true)

  const [showCertificate, setShowCertificate] = useState(false)
  const handleCloseCertificate = () => setShowCertificate(false)
  const handleShowCertificate = () => setShowCertificate(true)

  const [showShiftSwap, setShowShiftSwap] = useState(false)
  const handleCloseShiftSwap = () => setShowShiftSwap(false)
  const handleShowShiftSwap = () => setShowShiftSwap(true)

  const [requestedType, setRequestedType] = useState({
    requestedType: '',
    id: 0
  })
  const [showAccept, setShowAccept] = useState(false)
  const handleCloseAccept = () => setShowAccept(false)
  const handleShowAccept = (id: any, type: string) => {
    setShowAccept(true)
    setItem({
      leave_application_id: id,
      application_from_date: '',
      application_to_date: '',
      application_date: '',
      number_of_day: '',
      purpose: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      leave_type: {
        leave_type_id: 0,
        leave_type_name: '',
        leave_description: ''
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: "",
        approvers_order: ""
      }],
      request_type: "",
      current: "",
      applied_by_first_name: "",
      applied_by_last_name: "",
      levels_of_approval: [{
        approvers_order: "",
        level: ""
      }],
      created_by_first_name: "",
      created_by_last_name: ""
    })
  }

  const [showReject, setShowReject] = useState(false)
  const handleCloseReject = () => setShowReject(false)
  const handleShowReject = (id: any, type: string) => {
    setShowReject(true)
    setItem({
      leave_application_id: id,
      application_from_date: '',
      application_to_date: '',
      application_date: '',
      number_of_day: '',
      purpose: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      leave_type: {
        leave_type_id: 0,
        leave_type_name: '',
        leave_description: ''
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: "",
        approvers_order: ""
      }],
      request_type:"",
      current: "",
      applied_by_first_name: "",
      applied_by_last_name: "",
      levels_of_approval: [{
        approvers_order: "",
        level: ""
      }],
      created_by_first_name: "",
      created_by_last_name: ""
    })
  }
  const [dateFormat, setDateFormat] = useState('')
  const [updatedOvertime, setUpdatedOvertime] = useState({
    overtime_start: "",
    overtime_end: ""
  })

  const handleDisableAcceptRejectButton = (item: any) => {
    if(item.status == '3' || item.status == '2'){
      return true
    }else if(item.current == '0' || item.current == '2'){
      return true
    }else{
      return false
    }

  }

  const handleChangeOvertimeStart = (e: any) => {
    setUpdatedOvertime({...updatedOvertime, overtime_start: e.target.value})
  }

  const handleChangeOvertimeEnd = (e: any) => {
    setUpdatedOvertime({...updatedOvertime, overtime_end: e.target.value})
  }

  const handleDownloadFile = (fileName: string) => {
    window.location.href = `${IMAGE_BASE_URL}api/downloadFileForCertificate/${fileName}`;
  }

  const getLeaveRequest = async (page_number: number | null) => {
    try {
      const response = await axios.get<IAPI_Response<IRequest>>(API_ENDPOINT.GET_ALL_REQUEST_FOR_MFBC,{
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showRequest = async (id: null | number, type: string) => {
    try {
      if(type == "leave"){
        const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_LEAVE_REQUEST_FOR_MFBC +'/'+ id)
        const result = await response
        if (result?.data?.results) {
          setItem(result?.data?.results)
        }
        handleShow()
      }else if(type == "ob"){
        const response = await axios.get<IAPI_Response<IItemRequestOfficial>>(API_ENDPOINT.SHOW_OFFICIAL_REQUEST_FOR_MFBC +'/'+ id)
        const result = await response
        if (result?.data?.results) {
          setItemRequestOfficial(result?.data?.results)
        }
        handleShowOfficial()
      }else if(type == "overtime"){
        const response = await axios.get<IAPI_Response<IItemRequestOvertime>>(API_ENDPOINT.SHOW_OVERTIME_REQUEST_FOR_MFBC +'/'+ id)
        const result = await response
        if (result?.data?.results) {
          setItemRequestOvertime(result?.data?.results)
        }
        handleShowOvertime()
      }else if(type == "coa"){
        const response = await axios.get<IAPI_Response<IItemRequestCertificate>>(API_ENDPOINT.SHOW_CERTIFICATE_REQUEST_FOR_MFBC +'/'+ id)
        const result = await response
        if (result?.data?.results) {
          setItemRequestCertificate(result?.data?.results)
        }
        handleShowCertificate()
      }else if(type == "shift_swap"){
        const response = await axios.get<IAPI_Response<IItemRequestShiftSwap>>(API_ENDPOINT.SHOW_SHIFT_SWAP_REQUEST +'/'+ id)
        const result = await response
        if (result?.data?.results) {
          setItemRequestShiftSwap(result?.data?.results)
        }
        handleShowShiftSwap()
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const acceptRequest = async (id: null | number, type: string) => {
    try{
      if(type == "leave"){
        await axios.put(API_ENDPOINT.ACCEPT_LEAVE_REQUEST_FOR_MFBC +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.LEAVE_APPROVAL.ACCEPTED)
            getLeaveRequest(null);
          }
          handleCloseAccept();
          handleClose();
        });
      }else if(type == "coa"){
        await axios.put(API_ENDPOINT.ACCEPT_CERTIFICATE_REQUEST_FOR_MFBC +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.CERTIFICATE.ACCEPTED)
            getLeaveRequest(null);
          }
          handleCloseAccept();
          handleCloseCertificate();
        });
      }else if(type == "overtime"){
        await axios.put(API_ENDPOINT.ACCEPT_OVERTIME_REQUEST_FOR_MFBC +'/'+ id, {
          overtime_start: updatedOvertime.overtime_start,
          overtime_end: updatedOvertime.overtime_end
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.OVERTIME.ACCEPTED)
            getLeaveRequest(null);
          }
          handleCloseAccept();
          handleCloseOvertime();
        });
      }else if(type == "ob"){
        await axios.put(API_ENDPOINT.ACCEPT_OFFICIAL_REQUEST_FOR_MFBC +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.OFFICIAL.ACCEPTED)
            getLeaveRequest(null);
          }
          handleCloseAccept();
          handleCloseOfficial();
        });
      }else if(type == "shift_swap"){
        await axios.put(API_ENDPOINT.ACCEPT_SHIFT_SWAP_REQUEST +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.SHIFT_SWAP.ACCEPTED)
            getLeaveRequest(null);
          }
          handleCloseAccept();
          handleCloseShiftSwap();
        });
      }

    }catch{
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const rejectRequest = async (id: null | number, type: string) => {
    try{
      if(type == "leave"){
        await axios.put(API_ENDPOINT.REJECT_LEAVE_REQUEST_FOR_MFBC +'/'+ id, {
        }).then(response => {
           if (response?.data?.status * 1 === 1) {
              setShowSuccess(true)
              setSuccess(SUCCESS_MESSAGE.LEAVE_APPROVAL.REJECTED)
              getLeaveRequest(null);
           }
           handleCloseReject();
           handleClose();
        });
      }else if(type == "coa"){
        await axios.put(API_ENDPOINT.REJECT_CERTIFICATE_REQUEST_FOR_MFBC +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.CERTIFICATE.REJECTED)
            getLeaveRequest(null);
          }
          handleCloseReject();
          handleCloseCertificate();
        });
      }else if(type == "ob"){
        await axios.put(API_ENDPOINT.REJECT_OFFICIAL_REQUEST_FOR_MFBC +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.OFFICIAL.REJECTED)
            getLeaveRequest(null);
          }
          handleCloseReject();
          handleCloseOfficial();
        });
      }else if(type == "overtime"){
        await axios.put(API_ENDPOINT.REJECT_OVERTIME_REQUEST_FOR_MFBC +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.OVERTIME.REJECTED)
            getLeaveRequest(null);
          }
          handleCloseReject();
          handleCloseOvertime();
        });
      }else if(type == "shift_swap"){
        await axios.put(API_ENDPOINT.REJECT_SHIFT_SWAP_REQUEST +'/'+ id, {
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.SHIFT_SWAP.REJECTED)
            getLeaveRequest(null);
          }
          handleCloseReject();
          handleCloseShiftSwap();
        });
      }

    }catch{
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const handleNavigateLeaveRequests = () => {
    window.location.replace('/leaves/leave-approval')
  }

  useEffect(() => {
    ;(async () => {
      getLeaveRequest(null)
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Alert 
        type="success" 
        show={showSuccess} 
        message={success} 
        setShow={() => { 
            setShowSuccess(false);
          }
        }
      />

      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />

      <Card className="border-0 mb-3 p-0 bordered-card " style={{overflowY: 'scroll', height: '311px'}}>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <span className="fw-bold">Action Center</span>
            </Col>
          </Row>

          <Table responsive size={"sm"} className="bordered-tabs-top mt-2">
            <thead className="thead-table">
              <tr className="text-center">
                <th style={{width: '5%'}}>No.</th>
                <th>Employee</th>
                <th>Request Type</th>
                <th style={{width: '18%'}}>Reason</th>
                <th>Date Applied</th>
                <th>Applied By</th>
                <th style={{width: '8%'}}>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <>
                {
                  lists.length > 0 ?
                    lists.map((item, index) =>
                      <tr className="mb-2" key={item.id}>
                        <td>{from + index}</td>
                        <td className="fw-bold">
                          <Stack direction='horizontal'>
                            <div>
                              {
                                item.photo != "" ?
                                    <Avatar
                                        sx={{ width: 20, height: 20 }}
                                        src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                    ></Avatar> :
                                    <Avatar
                                        sx={{ width: 20, height: 20}}
                                        src={""}
                                    ></Avatar>
                              }
                            </div>
                            <div>&nbsp; {item.last_name}, {item.first_name}</div>
                          </Stack>
                        </td>
                        <td>{
                          item.request_type == "leave" ? "Leave" :
                              item.request_type == "overtime" ? "Overtime" :
                                  item.request_type == "coa" ? "Certificate of Attendance" :
                                      item.request_type == "ob" ? "Official Business" :
                                          item.request_type == "shift_swap" ? "Shift Swap" :""
                        }</td>
                        <td className="text-left display-reason" title={item.reason ?? ""}>{item.reason}</td>
                        <td className="text-center">{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                        <td>{item.created_by_first_name} {item.created_by_last_name}</td>
                        <td>
                          <div className="d-grid">
                          {(() => {
                                // Approved:3
                                if (item.status && item.status == 3) {
                                  return (
                                    <Button
                                      className=" p-1 pt-0 pb-0"
                                      style={{fontSize: '12px', color: 'white'}}
                                      variant="success"
                                      size="sm"
                                      >
                                      Approved
                                    </Button>
                                  )
                                // Reject:2
                                } else if(item.status && item.status == 2) {
                                  return (
                                    <Button
                                      className=" p-1 pt-0 pb-0"
                                      style={{fontSize: '12px', color: 'white'}}
                                      variant="danger"
                                      size="sm"
                                      >
                                      Disapproved
                                    </Button>
                                  )
                                }

                                return (
                                  <Button
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="secondary"
                                    size="sm"
                                    >
                                    Pending
                                  </Button>
                                )

                              })()}
                          </div>
                        </td>
                        <td className='text-center'>
                          <Button
                            onClick={() => showRequest(item.id, item.request_type)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant='btn-transparent'
                            size='sm'
                            title={"View Request"}
                            >
                            <View/>
                          </Button>
                          <Button
                              onClick={() => {
                                handleShowAccept(item.id, item.request_type)
                                setRequestedType({requestedType: item.request_type, id: item.id})
                              }}
                              className='p-1 pt-0 pb-0'
                              style={{ fontSize: '12px', color: 'white' }}
                              variant='btn-transparent'
                              size='sm'
                              disabled={handleDisableAcceptRejectButton(item)}
                              title={"Accept Request"}
                          >
                            {
                              handleDisableAcceptRejectButton(item) ?
                                  <AcceptDisabled/> : <Accept/>
                            }
                          </Button>
                          <Button
                            onClick={() => {
                              handleShowReject(item.id, item.request_type)
                              setRequestedType({requestedType: item.request_type, id: item.id})
                            }}
                            className='p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant='btn-transparent'
                            size='sm'
                            disabled={handleDisableAcceptRejectButton(item)}
                            title={"Reject Request"}
                            >
                            {
                              handleDisableAcceptRejectButton(item) ?
                                  <RejectDisabled/> : <Reject/>
                            }
                          </Button>
                        </td>
                      </tr>
                    ) :
                  (
                    <tr>
                      <td className="text-center" colSpan={8}>No Data Available.</td>
                    </tr>
                  )
                }
              </>
            </tbody>
          </Table>
          <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
              pageClicked={(page: number) => {
                getLeaveRequest(page);
              }
              }
          />
          {/*{itemsCount > 4 ? */}
          {/*  (*/}
          {/*  <div className="text-center mt-2">*/}
          {/*    <h6 className="fw-bold text-muted text-truncate" style={{fontSize: '12px'}} onClick={handleNavigateLeaveRequests}>*/}
          {/*      View All({itemsCount}) Leave Requests*/}
          {/*    </h6>*/}
          {/*  </div>*/}
          {/*  ) : ''*/}
          {/*}*/}

          <Dialog 
              show={showAccept} 
              setShow={handleCloseAccept}
              setEvent={() => {
                    acceptRequest(requestedType.id, requestedType.requestedType)

                }
              }
              variant='accept'
              message='Please confirm to accept leave request?'
            />

            <Dialog 
              show={showReject} 
              setShow={handleCloseReject}
              setEvent={() => {

                rejectRequest(requestedType.id, requestedType.requestedType)

                }
              }
              variant='reject'
              message='Do you really want to reject these leave request?'
            />
        </Card.Body>
      </Card>

      {/* DETAIL MODAL*/}
      <Modal className='modal' centered size={'lg'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>
            <Stack direction='horizontal'>
              <div>
                {
                  item.employee.photo != "" ?
                      <Avatar
                          sx={{ width: 65, height: 65 }}
                          src={process.env.REACT_APP_IMAGE_URL+ item.employee.photo}
                      ></Avatar> :
                      <Avatar
                          sx={{ width: 65, height: 65}}
                          src={""}
                      ></Avatar>
                }
              </div>
              <div className='mx-3'>
                &nbsp;{item.employee?.first_name} {item.employee?.last_name}
                <br />
                &nbsp;<span className='text-muted fw-light'>{item.employee?.user_name?.role?.role_name}</span>
              </div>
            </Stack>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <td className='fw-bold' width={120}>
                    Leave Type:
                  </td>
                  <td>{item.leave_type?.leave_description}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={120}>
                    No. of Days:
                  </td>
                  <td >{item.number_of_day} days</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={120}>
                    Date of Leave:
                  </td>
                  <td>{item.application_from_date} - {item.application_to_date}</td>
                </tr>
                </tbody>
              </Table>
            </Col>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <td className='fw-bold' width={120}>
                    Applied By:
                  </td>
                  <td>{item.created_by_first_name} {item.created_by_last_name}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={120}>
                    Applied On:
                  </td>
                  <td>{item.application_date}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={120}>
                    Reason:
                  </td>
                  <td>{item.purpose}</td>
                </tr>
                </tbody>
              </Table>
            </Col>

          </Row>
          <hr/>
          <Row style={{marginTop: '-15px'}}>
            <Table borderless className='mt-0 mb-0'>
              <tbody>
              <tr className='lh-1'>
                <th className='text-center'>Approvals</th>
              </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col sm={12}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <th className='text-center' style={{width: '20%'}}>Levels</th>
                  <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                  <th className='text-center'>Role</th>
                  <th className='text-center' style={{width: '15%'}}>Status</th>
                  <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                </tr>
                {
                  item.request_status.length > 0 ? (
                      item.request_status.map((item1: any, index: any) =>
                          (
                              <tr key={index} className='lh-1'>
                                <td>
                                  {
                                    index != 0 && item.request_status[index - 1].level == item1.level ?
                                        "" : <>
                                          <b>Level {item1.level}</b> : {item1.approvers_order == "0" ? "By Order" : "Anyone"}
                                        </>
                                  }
                                </td>
                                <td>
                                  <Stack direction='horizontal'>
                                    <div>
                                      {
                                        item1.employee?.photo != "" ?
                                            <Avatar
                                                sx={{ width: 20, height: 20 }}
                                                src={process.env.REACT_APP_IMAGE_URL+ item1.employee?.photo}
                                                className={"profile-avatar"}
                                            ></Avatar> :
                                            <Avatar
                                                sx={{ width: 20, height: 20}}
                                                src={""}
                                                className={"profile-avatar"}
                                            ></Avatar>
                                      }
                                    </div>
                                    <div>&nbsp;{item1.employee?.first_name} {item1.employee?.last_name}</div>
                                  </Stack>
                                </td>
                                <td>{item1.employee?.user_name?.role?.role_name}</td>
                                {(() => {
                                  // Approved:2
                                  if (item1.status == '3') {

                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                              Approved
                                            </Button>
                                          </td>
                                          <td className='text-center'>{moment(new Date(item1.approve_date ?? "")).format(dateFormat)}</td>
                                        </>
                                    )

                                    // Reject:3
                                  } else if(item1.status == '2') {
                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                              Disapproved
                                            </Button>
                                          </td>
                                          <td className='text-center'>{moment(new Date(item1.reject_date ?? "")).format(dateFormat)}</td>

                                        </>
                                    )
                                  } else if(item1.status == '4') {
                                    return (
                                        <>
                                          <td className='text-center'>-</td>
                                          <td className='text-center'>-</td>
                                        </>

                                    )
                                  }

                                  return (
                                      <>
                                        <td className='fw-bold text-center'>
                                          <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                            Pending
                                          </Button>
                                        </td>
                                        <td className='text-center'>-</td>
                                      </>
                                  )
                                })()}


                              </tr>

                          )

                      )
                  ) : (
                      <></>
                  )
                }
                </tbody>
              </Table>
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => rejectRequest(item.leave_application_id, 'leave')}
            variant='outline-primary' 
            className=' px-5 pe-5 mx-2'  
            size={'sm'}
            disabled={handleDisableAcceptRejectButton(item)}
            >
            Rejected
          </Button>
          <Button 
            onClick={() => acceptRequest(item.leave_application_id, 'leave')}
            variant='primary' 
            className='text-white px-5 pe-5' 
            size={'sm'}
            disabled={handleDisableAcceptRejectButton(item)}
            >
            Approved
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DETAIL MODAL ON OFFICIAL BUSINESS*/}
      <Modal className='modal' centered size={'lg'} show={showOfficial} onHide={handleCloseOfficial}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>
            <Stack direction='horizontal'>
              <div>
                {
                  itemRequestOfficial.employee.photo != "" ?
                      <Avatar
                          sx={{ width: 65, height: 65 }}
                          src={process.env.REACT_APP_IMAGE_URL+ itemRequestOfficial.employee.photo}
                      ></Avatar> :
                      <Avatar
                          sx={{ width: 65, height: 65}}
                          src={""}
                      ></Avatar>
                }
              </div>
              <div className='mx-3'>
                &nbsp;{itemRequestOfficial.employee?.first_name} {itemRequestOfficial.employee?.last_name}
                <br />
                &nbsp;<span className='text-muted fw-light'>{itemRequestOfficial.employee?.user_name?.role?.role_name}</span>
              </div>
            </Stack>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0' >
                <tbody style={{ padding: 'none' }}>

                <tr className='lh-1'>
                  <td className='fw-bold' width={200}>
                    Date of Official Business:
                  </td>
                  <td >{moment(new Date(itemRequestOfficial.date_from ?? "")).format(dateFormat)} - {moment(new Date(itemRequestOfficial.date_to ?? "")).format(dateFormat)}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={200}>
                    Hours of Official Business:
                  </td>
                  <td >{itemRequestOfficial.time_from} - {itemRequestOfficial.time_to}
                  </td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={200}>
                    Location:
                  </td>
                  <td>{itemRequestOfficial.location}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={200}>
                    Reason:
                  </td>
                  <td>{itemRequestOfficial.reason}</td>
                </tr>
                </tbody>
              </Table>
            </Col>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <td className='fw-bold' width={200}>
                    Applied On:
                  </td>
                  <td >{itemRequestOfficial.application_date}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={200}>
                    Applied By:
                  </td>
                  <td >{itemRequestOfficial.applied_by_first_name} {itemRequestOfficial.applied_by_last_name}</td>
                </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr/>
          <Row style={{marginTop: '-15px'}}>
            <Table borderless className='mt-0 mb-0'>
              <tbody>
              <tr className='lh-1'>
                <th className='text-center'>Approvals</th>
              </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col sm={12}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <th className='text-center' style={{width: '20%'}}>Levels</th>
                  <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                  <th className='text-center'>Role</th>
                  <th className='text-center' style={{width: '15%'}}>Status</th>
                  <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                </tr>
                {
                  itemRequestOfficial.request_status.length > 0 ? (
                      itemRequestOfficial.request_status.map((item: any, index: any) =>
                          (
                              <tr key={index} className='lh-1'>
                                <td>
                                  {
                                    index != 0 && itemRequestOfficial.request_status[index - 1].level == item.level ?
                                        "" : <>
                                          <b>Level {item.level}</b> : {item.approvers_order == "0" ? "By Order" : "Anyone"}
                                        </>
                                  }
                                </td>
                                <td>
                                  <Stack direction='horizontal'>
                                    <div>
                                      {
                                        item.employee?.photo != "" ?
                                            <Avatar
                                                sx={{ width: 20, height: 20 }}
                                                src={process.env.REACT_APP_IMAGE_URL+ item.employee?.photo}
                                                className={"profile-avatar"}
                                            ></Avatar> :
                                            <Avatar
                                                sx={{ width: 20, height: 20}}
                                                src={""}
                                                className={"profile-avatar"}
                                            ></Avatar>
                                      }
                                    </div>
                                    <div>&nbsp;{item.employee?.first_name} {item.employee?.last_name}</div>
                                  </Stack>
                                </td>
                                <td>{item.employee?.user_name?.role?.role_name}</td>
                                {(() => {
                                  // Approved:2
                                  if (item.status == '3') {

                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                              Approved
                                            </Button>
                                          </td>
                                          <td className='text-center'>{moment(new Date(item.approve_date ?? "")).format(dateFormat)}</td>
                                        </>
                                    )

                                    // Reject:3
                                  } else if(item.status == '2') {
                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                              Disapproved
                                            </Button>
                                          </td>
                                          <td className='text-center'>{moment(new Date(item.reject_date ?? "")).format(dateFormat)}</td>

                                        </>
                                    )
                                  } else if(item.status == '4') {
                                    return (
                                        <>
                                          <td className='text-center'>-</td>
                                          <td className='text-center'>-</td>
                                        </>

                                    )
                                  }

                                  return (
                                      <>
                                        <td className='fw-bold text-center'>
                                          <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                            Pending
                                          </Button>
                                        </td>
                                        <td className='text-center'>-</td>
                                      </>
                                  )
                                })()}


                              </tr>

                          )

                      )
                  ) : (
                      <></>
                  )
                }
                </tbody>
              </Table>
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={() => rejectRequest(itemRequestOfficial.official_business_id, "ob")}
              variant='outline-primary'
              className=' px-5 pe-5 mx-2'
              size={'sm'}
              disabled={handleDisableAcceptRejectButton(itemRequestOfficial)}
          >
            Rejected
          </Button>
          <Button
              onClick={() => acceptRequest(itemRequestOfficial.official_business_id, "ob")}
              variant='primary'
              className='text-white px-5 pe-5'
              size={'sm'}
              disabled={handleDisableAcceptRejectButton(itemRequestOfficial)}
          >
            Approved
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DETAIL MODAL FOR OVERTIME*/}
      <Modal className='modal' centered size={'lg'} show={showOvertime} onHide={handleCloseOvertime}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>
            <Stack direction='horizontal'>
              <div>
                {
                  itemRequestOvertime.employee.photo != "" ?
                      <Avatar
                          sx={{ width: 65, height: 65 }}
                          src={process.env.REACT_APP_IMAGE_URL+ itemRequestOvertime.employee.photo}
                      ></Avatar> :
                      <Avatar
                          sx={{ width: 65, height: 65}}
                          src={""}
                      ></Avatar>
                }
              </div>
              <div className='mx-3'>
                &nbsp;{itemRequestOvertime.employee?.first_name} {itemRequestOvertime.employee?.last_name}
                <br />
                &nbsp;<span className='text-muted fw-light'>{itemRequestOvertime.employee?.user_name?.role?.role_name}</span>
              </div>
            </Stack>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <td className='fw-bold' width={120}>
                    Applied By:
                  </td>
                  <td>{itemRequestOvertime.created_by_first_name} {itemRequestOvertime.created_by_last_name}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={120}>
                    Applied On:
                  </td>
                  <td>{itemRequestOvertime.application_date}</td>
                </tr>
                </tbody>
              </Table>
            </Col>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <td className='fw-bold' width={120}>
                    Classification
                  </td>
                  <td>{itemRequestOvertime.classification}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={120}>
                    Reason:
                  </td>
                  <td>{itemRequestOvertime.reason}</td>
                </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr/>
          <Row style={{marginTop: '-15px'}}>
            <Table borderless className='mt-0 mb-0'>
              <tbody>
              <tr className='lh-1'>
                <th className='text-center'>Overtime Request</th>
              </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col sm={12}>
              <Table borderless className='mt-0 mb-0'>
                <tbody>
                <tr>
                  <th>Overtime Date</th>
                  <th>Applied Overtime In</th>
                  <th>Applied Overtime Out</th>
                  <th>Approved Overtime In</th>
                  <th>Approved Overtime Out</th>
                </tr>
                <tr>
                  <td>{itemRequestOvertime.overtime_date}</td>
                  <td>{itemRequestOvertime.overtime_start}</td>
                  <td>{itemRequestOvertime.overtime_end}</td>
                  <td>
                    <Form.Group className="position-relative">
                      <Form.Control
                          size={'sm'}
                          type='time'
                          placeholder='Custom 1'
                          name="custom_1"
                          // value={itemRequest.overtime_start}
                          disabled={itemRequestOvertime.current != "1"}
                          onChange={handleChangeOvertimeStart }
                      />

                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group className="position-relative">
                      <Form.Control
                          size={'sm'}
                          type='time'
                          placeholder='Custom 1'
                          name="custom_1"
                          // value={itemRequest.overtime_end}
                          onChange={handleChangeOvertimeEnd}
                          disabled={itemRequestOvertime.current != "1"}
                      />

                    </Form.Group>
                  </td>
                </tr>
                <tr className='lh-1'>
                  <td colSpan={5} className='text-center' style={{fontSize: '90%'}}>Note: If approved overtime in and out is empty, applied overtime in and out is approved.</td>
                </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr/>
          <Row style={{marginTop: '-15px'}}>
            <Table borderless className='mt-0 mb-0'>
              <tbody>
              <tr className='lh-1'>
                <th className='text-center'>Approvals</th>
              </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col sm={12}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <th className='text-center' style={{width: '20%'}}>Levels</th>
                  <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                  <th className='text-center'>Role</th>
                  <th className='text-center' style={{width: '15%'}}>Status</th>
                  <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                </tr>
                {
                  itemRequestOvertime.request_status.length > 0 ? (
                      itemRequestOvertime.request_status.map((item: any, index: any) =>
                          (
                              <tr key={index} className='lh-1'>
                                <td>
                                  {
                                    index != 0 && itemRequestOvertime.request_status[index - 1].level == item.level ?
                                        "" : <>
                                          <b>Level {item.level}</b> : {item.approvers_order == "0" ? "By Order" : "Anyone"}
                                        </>
                                  }
                                </td>
                                <td>
                                  <Stack direction='horizontal'>
                                    <div>
                                      {
                                        item.employee?.photo != "" ?
                                            <Avatar
                                                sx={{ width: 20, height: 20 }}
                                                src={process.env.REACT_APP_IMAGE_URL+ item.employee?.photo}
                                                className={"profile-avatar"}
                                            ></Avatar> :
                                            <Avatar
                                                sx={{ width: 20, height: 20}}
                                                src={""}
                                                className={"profile-avatar"}
                                            ></Avatar>
                                      }
                                    </div>
                                    <div>&nbsp;{item.employee?.first_name} {item.employee?.last_name}</div>
                                  </Stack>
                                </td>
                                <td>{item.employee?.user_name?.role?.role_name}</td>
                                {(() => {
                                  // Approved:2
                                  if (item.status == '3') {

                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                              Approved
                                            </Button>
                                          </td>
                                          <td className='text-center'>{moment(new Date(item.approve_date ?? "")).format(dateFormat)}</td>
                                        </>
                                    )

                                    // Reject:3
                                  } else if(item.status == '2') {
                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                              Disapproved
                                            </Button>
                                          </td>
                                          <td className='text-center'>{moment(new Date(item.reject_date ?? "")).format(dateFormat)}</td>

                                        </>
                                    )
                                  } else if(item.status == '4') {
                                    return (
                                        <>
                                          <td className='text-center'>-</td>
                                          <td className='text-center'>-</td>
                                        </>

                                    )
                                  }

                                  return (
                                      <>
                                        <td className='fw-bold text-center'>
                                          <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                            Pending
                                          </Button>
                                        </td>
                                        <td className='text-center'>-</td>
                                      </>
                                  )
                                })()}


                              </tr>

                          )

                      )
                  ) : (
                      <></>
                  )
                }
                </tbody>
              </Table>
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={() => rejectRequest(itemRequestOvertime.id, "overtime")}
              variant='outline-primary'
              className=' px-5 pe-5 mx-2'
              size={'sm'}
              disabled={handleDisableAcceptRejectButton(itemRequestOvertime)}
          >
            Rejected
          </Button>
          <Button
              onClick={() => acceptRequest(itemRequestOvertime.id, "overtime")}
              variant='primary'
              className='text-white px-5 pe-5'
              size={'sm'}
              disabled={handleDisableAcceptRejectButton(itemRequestOvertime)}
          >
            Approved
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DETAIL MODAL ON CERTIFICATE OF ATTENDANCE*/}
      <Modal className='modal' centered size={'lg'} show={showCertificate} onHide={handleCloseCertificate}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>
            <Stack direction='horizontal'>
              <div>
                {
                  itemRequestCertificate.employee.photo != "" ?
                      <Avatar
                          sx={{ width: 65, height: 65 }}
                          src={process.env.REACT_APP_IMAGE_URL+ itemRequestCertificate.employee.photo}
                      ></Avatar> :
                      <Avatar
                          sx={{ width: 65, height: 65}}
                          src={""}
                      ></Avatar>
                }
              </div>
              <div className='mx-3'>
                &nbsp;{itemRequestCertificate.employee?.first_name} {itemRequestCertificate.employee?.last_name}
                <br />
                &nbsp;<span className='text-muted fw-light'>{itemRequestCertificate.employee?.user_name?.role?.role_name}</span>
              </div>
            </Stack>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>

                <tr className='lh-1'>
                  <td className='fw-bold' width={90}>
                    Type:
                  </td>
                  <td>{itemRequestCertificate.type}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={90}>
                    Applied On:
                  </td>
                  <td>{itemRequestCertificate.application_date}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={90}>
                    Applied By:
                  </td>
                  <td>{itemRequestCertificate.applied_by_first_name} {itemRequestCertificate.applied_by_last_name}</td>
                </tr>

                </tbody>
              </Table>
            </Col>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <td className='fw-bold' width={90}>
                    Reason:
                  </td>
                  <td>{itemRequestCertificate.reason}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={90}>
                    Documents:
                  </td>
                  <td>

                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {
                      itemRequestCertificate.documents ?
                          itemRequestCertificate.documents.map((doc: string, index) => (
                              <tr key={index}>
                                <a href={IMAGE_BASE_URL + doc} target="_blank"  rel="noreferrer">
                                  <td className="uploaded-documents display-filename" width={250}>{doc.split("/")[2]}</td>
                                </a>
                                <td>
                                  <Button
                                      onClick={() => handleDownloadFile(doc.split("/")[2])}
                                      variant=' btn-transparent'
                                      className=' p-1 pt-0 pb-0'
                                      size={'sm'}
                                  >
                                    <Download/>
                                  </Button>

                                </td>
                              </tr>
                          )) :
                          <tr>
                            <td width={250}>No file/s uploaded.</td>
                            <td></td>
                          </tr>
                    }
                  </td>
                </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr/>
          <Row style={{marginTop: '-15px'}}>
            <Table borderless className='mt-0 mb-0'>
              <tbody>
              <tr className='lh-1'>
                <th className='text-center'>Certificate of Attendance Request</th>
              </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col sm={12}>
              <Table borderless className='mt-0 mb-0'>
                <tbody>
                <tr>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Time</th>
                </tr>
                {itemRequestCertificate?.requested_attendance.length > 0 ? (
                    itemRequestCertificate?.requested_attendance.map((item, index) =>

                        <tr className='lh-1' key={index}>
                          <td>{item.time_in_out == "clock_in"? "Clock In" :
                              item.time_in_out == "clock_out"? "Clock Out" : ""}</td>
                          <td>{item.time_in_out_date}</td>
                          <td>{item.time_in_out_time}</td>
                        </tr>

                    )
                ) : (
                    <></>
                )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <hr/>
          <Row style={{marginTop: '-15px'}}>
            <Table borderless className='mt-0 mb-0'>
              <tbody>
              <tr className='lh-1'>
                <th className='text-center'>Approvals</th>
              </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col sm={12}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <th className='text-center' style={{width: '20%'}}>Levels</th>
                  <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                  <th className='text-center'>Role</th>
                  <th className='text-center' style={{width: '15%'}}>Status</th>
                  <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                </tr>
                {
                  itemRequestCertificate.request_status.length > 0 ? (
                      itemRequestCertificate.request_status.map((item: any, index: any) =>
                          (
                              <tr key={index} className='lh-1'>
                                <td>
                                  {
                                    index != 0 && itemRequestCertificate.request_status[index - 1].level == item.level ?
                                        "" : <>
                                          <b>Level {item.level}</b> : {item.approvers_order == "0" ? "By Order" : "Anyone"}
                                        </>
                                  }
                                </td>
                                <td>
                                  <Stack direction='horizontal'>
                                    <div>
                                      {
                                        item.employee?.photo != "" ?
                                            <Avatar
                                                sx={{ width: 20, height: 20 }}
                                                src={process.env.REACT_APP_IMAGE_URL+ item.employee?.photo}
                                                className={"profile-avatar"}
                                            ></Avatar> :
                                            <Avatar
                                                sx={{ width: 20, height: 20}}
                                                src={""}
                                                className={"profile-avatar"}
                                            ></Avatar>
                                      }
                                    </div>
                                    <div>&nbsp;{item.employee?.first_name} {item.employee?.last_name}</div>
                                  </Stack>
                                </td>
                                <td>{item.employee?.user_name?.role?.role_name}</td>
                                {(() => {
                                  // Approved:2
                                  if (item.status == '3') {

                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                              Approved
                                            </Button>
                                          </td>
                                          <td className='text-center'>{moment(new Date(item.approve_date ?? "")).format(dateFormat)}</td>
                                        </>
                                    )

                                    // Reject:3
                                  } else if(item.status == '2') {
                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                              Disapproved
                                            </Button>
                                          </td>
                                          <td className='text-center'>{moment(new Date(item.reject_date ?? "")).format(dateFormat)}</td>

                                        </>
                                    )
                                  } else if(item.status == '4') {
                                    return (
                                        <>
                                          <td className='text-center'>-</td>
                                          <td className='text-center'>-</td>
                                        </>

                                    )
                                  }

                                  return (
                                      <>
                                        <td className='fw-bold text-center'>
                                          <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                            Pending
                                          </Button>
                                        </td>
                                        <td className='text-center'>-</td>
                                      </>
                                  )
                                })()}


                              </tr>

                          )

                      )
                  ) : (
                      <></>
                  )
                }
                </tbody>
              </Table>
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={() => rejectRequest(itemRequestCertificate.id, 'coa')}
              variant='outline-primary'
              className=' px-5 pe-5 mx-2'
              size={'sm'}
              disabled={handleDisableAcceptRejectButton(itemRequestCertificate)}
          >
            Rejected
          </Button>
          <Button
              onClick={() => acceptRequest(itemRequestCertificate.id, 'coa')}
              variant='primary'
              className='text-white px-5 pe-5'
              size={'sm'}
              disabled={handleDisableAcceptRejectButton(itemRequestCertificate)}
          >
            Approved
          </Button>
        </Modal.Footer>
      </Modal>

      {/*DETAIL MODAL ON SHIFT SWAP*/}
      <Modal className='modal' centered size={'lg'} show={showShiftSwap} onHide={handleCloseShiftSwap}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>

            <div className='mx-3'>
              {(() => {
                switch (itemRequestShiftSwap.status) {
                  case '1':
                    return (
                        <>
                          &nbsp;<span className='text-primary'>{itemRequestShiftSwap.employee?.first_name} {itemRequestShiftSwap.employee?.last_name}</span> wants to swap schedule with <span className='text-primary'>{itemRequestShiftSwap.swap_employee?.first_name} {itemRequestShiftSwap.swap_employee?.last_name}</span>.
                        </>
                    )
                  case '2':
                    return (
                        <>
                          &nbsp;The request to swap schedule between {itemRequestShiftSwap.employee?.first_name} {itemRequestShiftSwap.employee?.last_name} and {itemRequestShiftSwap.swap_employee?.first_name} {itemRequestShiftSwap.swap_employee?.last_name} was <span className='text-danger'>disapproved</span>.
                        </>
                    )
                  case '3':
                    return (
                        <>
                          &nbsp;The request to swap schedule between {itemRequestShiftSwap.employee?.first_name} {itemRequestShiftSwap.employee?.last_name} and {itemRequestShiftSwap.swap_employee?.first_name} {itemRequestShiftSwap.swap_employee?.last_name} was <span className='text-success'>approved</span>.
                        </>
                    )
                }
              })()}

            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>

                <tr className='lh-1'>
                  <td className='fw-bold' width={90}>
                    Applied On:
                  </td>
                  <td>{itemRequestShiftSwap.application_date}</td>
                </tr>
                <tr className='lh-1'>
                  <td className='fw-bold' width={90}>
                    Applied By:
                  </td>
                  <td>{itemRequestShiftSwap.applied_by_first_name} {itemRequestShiftSwap.applied_by_last_name}</td>
                </tr>

                </tbody>
              </Table>
            </Col>
            <Col sm={6}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <td className='fw-bold' width={90}>
                    Reason:
                  </td>
                  <td>{itemRequestShiftSwap.reason}</td>
                </tr>

                </tbody>
              </Table>
            </Col>
          </Row>
          <hr/>
          <Row style={{marginTop: '-15px'}}>
            <Table borderless className='mt-0 mb-0'>
              <tbody>
              <tr className='lh-1'>
                <th className='text-center'>Swap Details</th>
              </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col sm={4}>
              <Stack direction='horizontal'>
                <div>
                  {
                    itemRequestShiftSwap.employee.photo != "" ?
                        <Avatar
                            sx={{ width: 65, height: 65 }}
                            src={process.env.REACT_APP_IMAGE_URL+ itemRequestShiftSwap.employee.photo}
                        ></Avatar> :
                        <Avatar
                            sx={{ width: 65, height: 65}}
                            src={""}
                        ></Avatar>
                  }
                </div>
                <div className='mx-3'>
                  &nbsp;{itemRequestShiftSwap.employee?.first_name} {itemRequestShiftSwap.employee?.last_name}
                  <br />
                  &nbsp;<span className='text-muted fw-light'>{itemRequestShiftSwap.employee?.user_name?.role?.role_name}</span>
                </div>
              </Stack>
            </Col>
            <Col sm={4}></Col>
            <Col sm={4} className='d-flex'>
              <Stack direction='horizontal'>
                <div>
                  {
                    itemRequestShiftSwap.swap_employee.photo != "" ?
                        <Avatar
                            sx={{ width: 65, height: 65 }}
                            src={process.env.REACT_APP_IMAGE_URL+ itemRequestShiftSwap.swap_employee.photo}
                        ></Avatar> :
                        <Avatar
                            sx={{ width: 65, height: 65}}
                            src={""}
                        ></Avatar>
                  }
                </div>
                <div className='mx-3'>
                  &nbsp;{itemRequestShiftSwap.swap_employee?.first_name} {itemRequestShiftSwap.swap_employee?.last_name}
                  <br />
                  &nbsp;<span className='text-muted fw-light'>{itemRequestShiftSwap.swap_employee?.user_name?.role?.role_name}</span>
                </div>
              </Stack>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col sm={4}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                {
                  itemRequestShiftSwap.status == '3' ?
                      <tr className='lh-1'>
                        <td colSpan={2} className='fw-bold text-success text-center' width={90}>
                          New Schedule
                        </td>
                      </tr>
                      : ""
                }

                {
                  itemRequestShiftSwap.status == '3' ?
                      <>
                        <tr className='lh-1'>
                          <td className='fw-bold' width={90}>
                            Date:
                          </td>
                          <td>{moment(new Date(itemRequestShiftSwap.swap_schedule_date ?? "")).format(dateFormat)}</td>
                        </tr>
                        <tr className='lh-1'>
                          <td className='fw-bold' width={90}>
                            Shift:
                          </td>
                          <td>{itemRequestShiftSwap.swap_is_rest_day == 1 ? "Rest Day" : itemRequestShiftSwap.swap_start_from + " - " + itemRequestShiftSwap.swap_end_to}</td>
                        </tr>
                      </>
                      :
                      <>
                        <tr className='lh-1'>
                          <td className='fw-bold' width={90}>
                            Date:
                          </td>
                          <td>{moment(new Date(itemRequestShiftSwap.schedule_date ?? "")).format(dateFormat)}</td>
                        </tr>
                        <tr className='lh-1'>
                          <td className='fw-bold' width={90}>
                            Shift:
                          </td>
                          <td>{itemRequestShiftSwap.is_rest_day == 1 ? "Rest Day" : itemRequestShiftSwap.start_from + " - " + itemRequestShiftSwap.end_to}</td>
                        </tr>
                      </>
                }

                </tbody>
              </Table>
            </Col>
            <Col sm={4} className='text-center'>
              <Form.Group controlId='formHorizontalEmail'>
                <div className='m-auto'>
                  <SwapIcon/>
                </div>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                {
                  itemRequestShiftSwap.status == '3' ?
                      <tr className='lh-1'>
                        <td colSpan={2} className='fw-bold text-success text-center' width={90}>
                          New Schedule
                        </td>
                      </tr>
                      : ""
                }
                {
                  itemRequestShiftSwap.status == '3' ?
                      <>
                        <tr className='lh-1'>
                          <td className='fw-bold' width={90}>
                            Swap Date:
                          </td>
                          <td>{itemRequestShiftSwap.schedule_date}</td>
                        </tr>
                        <tr className='lh-1'>
                          <td className='fw-bold' width={90}>
                            Shift:
                          </td>
                          <td>{itemRequestShiftSwap.is_rest_day == 1 ? "Rest Day" : itemRequestShiftSwap.start_from + " - " + itemRequestShiftSwap.end_to}</td>
                        </tr>
                      </>
                      : <>
                        <tr className='lh-1'>
                          <td className='fw-bold' width={90}>
                            Swap Date:
                          </td>
                          <td>{itemRequestShiftSwap.swap_schedule_date}</td>
                        </tr>
                        <tr className='lh-1'>
                          <td className='fw-bold' width={90}>
                            Shift:
                          </td>
                          <td>{itemRequestShiftSwap.swap_is_rest_day == 1 ? "Rest Day" : itemRequestShiftSwap.swap_start_from + " - " + itemRequestShiftSwap.swap_end_to}</td>
                        </tr>
                      </>
                }

                </tbody>
              </Table>
            </Col>
          </Row>
          <hr/>
          <Row style={{marginTop: '-15px'}}>
            <Table borderless className='mt-0 mb-0'>
              <tbody>
              <tr className='lh-1'>
                <th className='text-center'>Approvals</th>
              </tr>
              </tbody>
            </Table>
          </Row>
          <Row>
            <Col sm={12}>
              <Table borderless className='mt-0 mb-0'>
                <tbody style={{ padding: 'none' }}>
                <tr className='lh-1'>
                  <th className='text-center' style={{width: '20%'}}>Levels</th>
                  <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                  <th className='text-center'>Role</th>
                  <th className='text-center' style={{width: '15%'}}>Status</th>
                  <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                </tr>
                {
                  itemRequestShiftSwap.request_status.length > 0 ? (
                      itemRequestShiftSwap.request_status.map((item: any, index: any) =>
                          (
                              <tr key={index} className='lh-1'>
                                <td>
                                  {
                                    item.level != "0" ?
                                        index != 0 && itemRequestShiftSwap.request_status[index - 1].level == item.level ?
                                            "" : <>
                                              <b>Level {item.level}</b> : {item.approvers_order == "0" ? "By Order" : "Anyone"}
                                            </>
                                        : <b>Swap Employee</b>
                                  }
                                </td>
                                <td>
                                  <Stack direction='horizontal'>
                                    <div>
                                      {
                                        item.employee?.photo != "" ?
                                            <Avatar
                                                sx={{ width: 20, height: 20 }}
                                                src={process.env.REACT_APP_IMAGE_URL+ item.employee?.photo}
                                                className={"profile-avatar"}
                                            ></Avatar> :
                                            <Avatar
                                                sx={{ width: 20, height: 20}}
                                                src={""}
                                                className={"profile-avatar"}
                                            ></Avatar>
                                      }
                                    </div>
                                    <div>&nbsp;{item.employee?.first_name} {item.employee?.last_name}</div>
                                  </Stack>
                                </td>
                                <td>{item.employee?.user_name?.role?.role_name}</td>
                                {(() => {
                                  // Approved:2
                                  if (item.status == '3') {

                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                              Approved
                                            </Button>
                                          </td>
                                          <td className='text-center'>{moment(new Date(item.approve_date ?? "")).format(dateFormat)}</td>
                                        </>
                                    )

                                    // Reject:3
                                  } else if(item.status == '2') {
                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                              Disapproved
                                            </Button>
                                          </td>
                                          <td className='text-center'>{moment(new Date(item.reject_date ?? "")).format(dateFormat)}</td>

                                        </>
                                    )
                                  } else if(item.status == '4') {
                                    return (
                                        <>
                                          <td className='text-center'>-</td>
                                          <td className='text-center'>-</td>
                                        </>

                                    )
                                  }

                                  return (
                                      <>
                                        <td className='fw-bold text-center'>
                                          <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                            Pending
                                          </Button>
                                        </td>
                                        <td className='text-center'>-</td>
                                      </>
                                  )
                                })()}


                              </tr>

                          )

                      )
                  ) : (
                      <></>
                  )
                }
                </tbody>
              </Table>
            </Col>

          </Row>
        </Modal.Body>
        <Modal.Footer>

              <Button
                  onClick={() => rejectRequest(itemRequestShiftSwap.id, 'shift_swap')}
                  variant='outline-primary'
                  className=' px-5 pe-5 mx-2'
                  size={'sm'}
                  disabled={handleDisableAcceptRejectButton(itemRequestShiftSwap)}
              >
                Rejected
              </Button>
              <Button
                  onClick={() => acceptRequest(itemRequestShiftSwap.id, 'shift_swap')}
                  variant='primary'
                  className='text-white px-5 pe-5'
                  size={'sm'}
                  disabled={handleDisableAcceptRejectButton(itemRequestShiftSwap)}
              >
                Approved
              </Button>


        </Modal.Footer>
      </Modal>
    </>
  );
}