import React from 'react'

const ArrowUp = () => {
    return (
        // <svg
        //     xmlns="http://www.w3.org/2000/svg"
        //     width="16"
        //     height="16"
        //     fill="currentColor"
        //     className="bi bi-arrow-up-short"
        //     color="#fba91a"
        //     viewBox="0 0 16 16"
        // >
        //     <path
        //         fillRule="evenodd"
        //         d="M8 12a.5.5 0 00.5-.5V5.707l2.146 2.147a.5.5 0 00.708-.708l-3-3a.5.5 0 00-.708 0l-3 3a.5.5 0 10.708.708L7.5 5.707V11.5a.5.5 0 00.5.5z"
        //     ></path>
        // </svg>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="currentColor"
            color="#fba91a"
            className="bi bi-chevron-up"
            viewBox="0 0 16 16"
        >
            <path
                fillRule="evenodd"
                d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
            ></path>
        </svg>
    )
}

export default ArrowUp
