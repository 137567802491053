import React from 'react'

const User = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18.086'
      height='20.098'
      viewBox='0 0 18.086 20.098'
    >
      <g id='User' transform='translate(1 1)'>
        <path
          id='Path_11'
          data-name='Path 11'
          d='M22.086,28.532V26.522A4.022,4.022,0,0,0,18.065,22.5H10.022A4.022,4.022,0,0,0,6,26.522v2.011'
          transform='translate(-6 -10.436)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_12'
          data-name='Path 12'
          d='M20.043,8.522A4.022,4.022,0,1,1,16.022,4.5,4.022,4.022,0,0,1,20.043,8.522Z'
          transform='translate(-7.979 -4.5)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  )
}

export default User
