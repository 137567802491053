import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './custom.scss'
import './index.scss'
import Main from './pages/Main'
import { store } from './redux/appStore'
import { Provider } from 'react-redux'

export default function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  )
}
