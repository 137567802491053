import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  deduction_name: yup
    .string()
    .required(VALIDATION.required)
    .max(250, VALIDATION.max),
  deduction_type: yup
    .string()
    .required(VALIDATION.required)
    .max(100, VALIDATION.max),
  limit_per_month: yup
    .string()
    .required(VALIDATION.required),
  // reference_table: yup.string().when("deduction_type", {
  //   is: (deduction_type: any) => deduction_type == 'Dynamic',
  //   then: yup.string().required(VALIDATION.required),
  // }),
})
