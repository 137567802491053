import React, { useEffect, useState, useRef } from 'react'
import "./style.css"
import {ISalary, IAllowance, IDeduction} from "./types"
import {Button, Col, Row, Table} from "react-bootstrap";
import {API_ENDPOINT, ERROR_MESSAGE, TEXT} from "../../../../utils/globalConstant";
import Image from "react-bootstrap/Image";
import ReactToPrint from "react-to-print";
import axios from "../../../../api";
import {IAPI_Response} from "../../../../api/types";

// eslint-disable-next-line react/display-name
const PrintPayslipComponent = React.forwardRef((props: {payslipData: ISalary | null, allowances: IAllowance[], deductions: IDeduction[]}, ref:any) => {
    const payslipData = props.payslipData
    const allowances = props.allowances
    const deductions = props.deductions
    const [payslipLogo, setPayslipLogo] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [error, setError] = React.useState('')
    const [showError, setShowError] = React.useState(false)

    const getPayslipLogo = async () => {
        try {
            const response = await axios.get<IAPI_Response<{payslipLogo: string}>>(API_ENDPOINT.GET_PAYSLIP_LOGO)
            const result = await response

            if(result?.data?.results){
                setPayslipLogo(result?.data?.results.payslipLogo)
            }


        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);

    }

    const getCompanyName = async () => {
        try {
            const response = await axios.get<IAPI_Response<{companyName: string}>>(API_ENDPOINT.GET_COMPANY_NAME)
            const result = await response

            if(result?.data?.results){
                setCompanyName(result?.data?.results.companyName)
            }


        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);

    }

    useEffect(() => {
        ;(async () => {
            getPayslipLogo()
            getCompanyName()
        })()
    }, [])

    return (
        <div ref={ref} className="print-element">
            <Row>
                <Col sm={6} style={{ borderRight: 'solid #C0C0C0 1px' }}>
                    <Row>
                        <Col sm={6}>
                            <Row>
                                <Col sm={6} style={{margin: 'auto'}}>
                                    <Image
                                        style={{
                                            width: '90%',
                                        }}
                                        src={payslipLogo != "" ? process.env.REACT_APP_IMAGE_URL+ payslipLogo: "/Octaneware1.png"}
                                    />
                                </Col>
                                <Col sm={6} style={{margin: 'auto'}}>
                                    <h5 className='fw-bold'>{companyName}</h5>
                                </Col>
                            </Row>
                            {/*<p style={{fontSize: '75%'}}>11F Park Centrale Building, Jose Maria Del Mar St. Cebu IT Park, Lahug, Cebu City, Cebu </p>*/}
                        </Col>
                        <Col sm={6} className=' d-grid justify-content-end ' style={{margin: 'auto'}}>
                            <h4 className='fw-bold'>Payslip</h4>
                        </Col>
                    </Row>
                    <hr />
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Employee ID :</h6>
                        </Col>
                        <Col sm={6}>
                            <h6>{payslipData?.employee?.employee_id}</h6>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Employee Name :</h6>
                        </Col>
                        <Col sm={6} >
                            <h6>{payslipData?.employee?.first_name} {payslipData?.employee?.last_name}</h6>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Position :</h6>
                        </Col>
                        <Col sm={6}>
                            <h6>{payslipData?.designation}</h6>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Department :</h6>
                        </Col>
                        <Col sm={6}>
                            <h6>{payslipData?.department}</h6>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Worksite :</h6>
                        </Col>
                        <Col sm={6}>
                            <h6>{payslipData?.worksite}</h6>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Per Cut Off</h6>
                        </Col>
                        <Col sm={6} >
                            <h6>{Number(payslipData?.total_working_days).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} days</h6>

                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Days rendered</h6>
                        </Col>
                        <Col sm={6} >
                            <h6>{Number(payslipData?.total_present).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} days</h6>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Payroll Period :</h6>
                        </Col>
                        <Col sm={6}>
                            <h6>{payslipData?.month_of_salary}</h6>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Basic Rate :</h6>
                        </Col>
                        <Col sm={6}>
                            <h6>{Number(payslipData?.basic_salary).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Payable Date :</h6>
                        </Col>
                        <Col sm={6}>
                            <h6>August 20, 2023</h6>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Payable Type:</h6>
                        </Col>
                        <Col sm={6}>
                            <h6>{payslipData?.payrollType}</h6>
                        </Col>
                    </Row>
                    {/*<Row className='mb-3'>*/}
                    {/*  <Col sm={6}>*/}
                    {/*    <h6 className='fw-bold'>Payment Method :</h6>*/}
                    {/*  </Col>*/}
                    {/*  <Col sm={6}>*/}
                    {/*    <h6>Cash</h6>*/}
                    {/*  </Col>*/}
                    {/*</Row>*/}
                    <br />
                    <Row className='mb-3'>
                        <Col sm={6}>
                            <h5 className='fw-bold'>Net Pay :</h5>
                        </Col>
                        <Col sm={6}>
                            <h5 className='fw-bold'>{Number(payslipData?.net_salary).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <br />

                </Col>
                <Col sm={6}>
                    <Row className={'bg-dark mx-1'}>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Earnings</h6>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <h6 className='fw-bold'>Amount</h6>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Basic Pay</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.net_pay_without_two_hours).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Leave</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.total_leave).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Special Holiday</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.total_special_day_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Regular/Legal Holiday</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.total_regular_holiday_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Overtime</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.total_overtime_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Night Diff Amount</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.night_diff_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    {/*<Row className={' p-0 mx-1'}>*/}
                    {/*    <Col sm={6}>*/}
                    {/*        <small>Sat. 2 Hours Amount</small>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm={6} className='d-grid justify-content-end'>*/}
                    {/*        <small>{Number(payslipData?.saturday_two_hours_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Five Days Incentive</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.five_days_incentive_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Adjustments</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.adjustment).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    {
                        payslipData?.salary_details_to_allowance.map((allowance, index) => (
                            allowances.map(allow => (
                                allowance.allowance_id == allow.allowance_id ?
                                    <Row className={' p-0 mx-1'}>
                                        <Col sm={6}>
                                            <small>{allow.allowance_name}</small>
                                        </Col>
                                        <Col sm={6} className='d-grid justify-content-end'>
                                            <small>{Number(allowance.amount_of_allowance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                                        </Col>
                                    </Row> : ""
                            ))

                        ))
                    }

                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <h5 className='fw-bold'>Total Earnings</h5>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <h5 className='fw-bold'>{Number(payslipData?.total_earnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                        </Col>
                    </Row>
                    <hr />
                    {/* DEDUCTIONS*/}
                    <Row className={'bg-dark mx-1'}>
                        <Col sm={6}>
                            <h6 className='fw-bold'>Deductions</h6>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <h6 className='fw-bold'>Amount</h6>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Absences/Suspensions</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.total_absence_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Late</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.total_late_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Undertime</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.total_undertime_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Undertime</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.total_undertime_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Undertime</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.total_undertime_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Undertime</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>{Number(payslipData?.total_undertime_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                        </Col>
                    </Row>


                    {
                        payslipData?.salary_details_to_deduction.map((deduction, index) => (
                            deductions.map(deduct => (
                                deduction.deduction_id == deduct.deduction_id ?
                                    <Row key={index} className={' p-0 mx-1'}>
                                        <Col sm={6}>
                                            <small>{deduct.deduction_name}</small>
                                        </Col>
                                        <Col sm={6} className='d-grid justify-content-end'>
                                            <small>{Number(deduction.amount_of_deduction).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                                        </Col>
                                    </Row> : ""
                            ))

                        ))
                    }


                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <small>Taxable Salary</small>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <small>0.00</small>
                        </Col>
                    </Row>
                    <Row className={' p-0 mx-1'}>
                        <Col sm={6}>
                            <h5 className='fw-bold'>Total Deductions</h5>
                        </Col>
                        <Col sm={6} className='d-grid justify-content-end'>
                            <h5 className='fw-bold'>{Number(payslipData?.total_deduction).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                        </Col>
                    </Row>
                    <hr />
                </Col>
            </Row>
        </div>
    )

})

export default PrintPayslipComponent;