import React from 'react'

const Reject = () => {
  return (
    <svg
      id='Reject'
      xmlns='http://www.w3.org/2000/svg'
      width='16.098'
      height='16.098'
      viewBox='0 0 14.098 14.098'
    >
      <path
        id='Path_111'
        data-name='Path 111'
        d='M17.549,24.6A7.049,7.049,0,1,0,10.5,17.549,7.049,7.049,0,0,0,17.549,24.6ZM13.2,13.2a6.157,6.157,0,1,1-1.8,4.353A6.157,6.157,0,0,1,13.2,13.2Z'
        transform='translate(-10.5 -10.5)'
        fill='#a7a9ac'
      />
      <path
        id='Path_112'
        data-name='Path 112'
        d='M34.262,39.58,36.444,37.4l2.183,2.183a.446.446,0,1,0,.631-.631l-2.183-2.183,2-2a.446.446,0,0,0-.631-.631l-2,2-2-2a.446.446,0,0,0-.631.631l2,2-2.183,2.183a.446.446,0,1,0,.631.631Z'
        transform='translate(-29.396 -29.807)'
        fill='#f26930'
      />
    </svg>
  )
}

export default Reject
