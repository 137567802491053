import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Stack,
  Table,
  Carousel
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Avatar } from '@mui/material'
import Accept from 'asset/SvgComponent/Accept'
import Reject from 'asset/SvgComponent/Reject'
import View from 'asset/SvgComponent/View'
import Calendar from 'asset/SvgComponent/Calendar'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { IList, IItem, ILeaveRequest } from './types'
import { identical } from 'ramda'
import moment from "moment"

export default function Index() {
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [showAccept, setShowAccept] = useState(false)
  const handleCloseAccept = () => setShowAccept(false)
  const handleShowAccept = (id: any) => {
    setShowAccept(true)
    setItem({
      leave_application_id: id,
      application_from_date: '',
      application_to_date: '',
      application_date: '',
      number_of_day: '',
      purpose: '',
      approvers_order: 0,
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      leave_type: {
        leave_type_id: 0,
        leave_type_name: '',
        leave_description: ''
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        }
      }]
    })
  }

  const [showReject, setShowReject] = useState(false)
  const handleCloseReject = () => setShowReject(false)
  const handleShowReject = (id: any) => {
    setShowReject(true)
    setItem({
      leave_application_id: id,
      application_from_date: '',
      application_to_date: '',
      application_date: '',
      number_of_day: '',
      purpose: '',
      approvers_order: 0,
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      leave_type: {
        leave_type_id: 0,
        leave_type_name: '',
        leave_description: ''
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        }
      }]
    })
  }

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const [requests, setRequests] = useState<IList[]>([])
  const [pendings, setPendings] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    leave_application_id: 0,
    application_from_date: '',
    application_to_date: '',
    application_date: '',
    number_of_day: '',
    purpose: '',
    approvers_order: 0,
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    leave_type: {
      leave_type_id: 0,
      leave_type_name: '',
      leave_description: ''
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      }
    }]
  })
  const [dateFormat, setDateFormat] = useState('')

  const _chunk = (arr: any, n: any) => {
    const group = [];
    for (let i = 0, end = arr.length / n; i < end; ++i)
      group.push(arr.slice(i * n, (i + 1) * n));
    return group;
  }

  const getLeaveRequest = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<ILeaveRequest>>(API_ENDPOINT.GET_LEAVE_REQUEST, {
        params: {
          limit: 10,
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setRequests(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const pendingLeaveRequest = async () => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.PENDING_LEAVE_REQUEST)
      const result = await response
      if (result?.data?.results?.length) {
        setPendings(result?.data?.results)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showLeaveRequest = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_LEAVE_REQUEST +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
      }
      handleShow()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const acceptLeaveRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.ACCEPT_LEAVE_REQUEST +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.LEAVE_APPROVAL.ACCEPTED)
          getLeaveRequest(null);
       }
       handleCloseAccept();
       handleClose();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const rejectLeaveRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.REJECT_LEAVE_REQUEST +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.LEAVE_APPROVAL.REJECTED)
          getLeaveRequest(null);
       }
       handleCloseReject();
       handleClose();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getLeaveRequest(null)
      pendingLeaveRequest()
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Leave Approval</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/leaves/holiday-management'>
            Leaves
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Leave Approval</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <span className='fw-bold'>Leave Approval</span>
              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                <InputGroup size='sm' className={'w-25'}>
                  <Form.Control
                    aria-label='search'
                    aria-describedby='inputGroup-sizing-sm'
                    placeholder='Search...'
                  />
                  <InputGroup.Text className='bg-transparent search-input '>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
 
            {/* <Carousel 
              variant="dark" 
              interval={null} 
              controls={pendings.length > 3 ? true : false}
              indicators={pendings.length > 3 ? true : false}
              >
              {pendings.length > 0 ? (
                _chunk(pendings, 3).map((pending: any, index: any) => 
                  <Carousel.Item key={index}>
                    <Row className='mt-2'>
                      {pending.map((item: any, key: any) => 
                        <Col sm={4} key={key}>
                          <Card className='shadow bordered-card border-0 '>
                            <Card.Body style={{ padding: 0 }}>
                              <Container fluid className='p-2'>
                                <Table size='sm' borderless className='mb-0'>
                                  <tbody style={{ padding: 'none' }}>
                                    <tr>
                                      <td width={10}>
                                        <Avatar src='/Thomas.jpg'></Avatar>
                                      </td>
                                      <td>
                                        <span className='fw-bold'>{item.first_name} {item.last_name}</span>
                                        <br />
                                        <small>{item.role_name}</small>
                                      </td>
                                      <td className='align-content-end text-right'>
                                        <Button
                                          onClick={() => showLeaveRequest(item.leave_application_id)}
                                          className=' text-center add-new-btn rounded  text-white'
                                          variant='warning'
                                          >
                                          View Details
                                        </Button>{' '}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                                <Table size='sm' borderless className='mt-1 mb-0'>
                                  <tbody style={{ padding: 'none' }}>
                                    <tr>
                                      <td className='fw-bold' width={120}>
                                        Leave Type:
                                      </td>
                                      <td className='fw-bold'>{item.leave_type_name}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold' width={120}>
                                        Applied On:
                                      </td>
                                      <td className='fw-bold'>{item.application_date}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold text-center border' colSpan={2}>
                                        <Row>
                                          <Col>
                                            <Calendar/>&nbsp; {item.application_from_date}
                                          </Col>
                                          to
                                          <Col>
                                            <Calendar/>&nbsp; {item.application_to_date}
                                          </Col>
                                        </Row>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold' width={120}>
                                        Number of Days:
                                      </td>
                                      <td className='fw-bold'>{item.number_of_day}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold' width={120}>
                                        Reason:
                                      </td>
                                      <td className='fw-bold'>{item.purpose}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold' width={120}>
                                        Status:
                                      </td>
                                      <td>
                                        <Button
                                          size={'sm'}
                                          className='text-center add-new-btn rounded  text-white'
                                          variant='secondary'
                                        >
                                          Pending
                                        </Button>{' '}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Container>

                              <Table size='sm' bordered className='mt-0 mb-0'>
                                <tbody style={{ padding: 'none' }}>
                                  <tr>
                                    <td className='text-center btn-leave-application'>
                                      <Button
                                        size={'sm'}
                                        onClick={handleShow}
                                        // style={{fontSize: '10px'}}
                                        className=' text-center fw-bold add-new-btn rounded  text-primary'
                                        variant='transparent'
                                      >
                                        Accept
                                      </Button>{' '}
                                    </td>
                                    <td className='text-center btn-leave-application'>
                                      <Button
                                        size={'sm'}
                                        onClick={handleShow}
                                        // style={{fontSize: '10px'}}
                                        className=' text-center fw-bold add-new-btn rounded'
                                        variant='transparent'
                                      >
                                        Decline
                                      </Button>{' '}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </Carousel.Item>
                )
              ):(
                <></>
              )}
            </Carousel> */}

            {/* <br/>
            <span className='fw-bold'>Leaves Summary</span> */}
            <Table responsive size='sm' className='bordered-tabs-top mt-3'>
              <thead>
                <tr className='text-center'>
                  <th>No.</th>
                  <th>Employee Name</th>
                  <th>Leave Type</th>
                  <th>Date Applied</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Days</th>
                  <th>Reason</th>
                  {/* <th>Remarks</th> */}
                  <th style={{ width: '50px' }}>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {requests.length > 0 ? (
                    requests.map((item, index) => 
                      <tr className='mb-2' key={index}>
                        <td>{from + index}</td>
                        <td>
                          <Stack direction='horizontal'>
                            <div>
                              {
                                item.photo != "" ?
                                    <Avatar
                                        sx={{ width: 20, height: 20 }}
                                        src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                        className={"profile-avatar"}
                                    ></Avatar> :
                                    <Avatar
                                        sx={{ width: 20, height: 20}}
                                        src={""}
                                        className={"profile-avatar"}
                                    ></Avatar>
                              }
                            </div>
                            <div>&nbsp;{item.first_name} {item.last_name}</div>
                          </Stack>
                        </td>
                        <td>{item.leave_description}</td>
                        <td className='text-center'>{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                        <td className='text-center'>{moment(new Date(item.application_from_date ?? "")).format(dateFormat)}</td>
                        <td className='text-center'>{moment(new Date(item.application_to_date ?? "")).format(dateFormat)}</td>
                        <td className='text-center'>{item.number_of_day} days</td>
                        <td className='text-center'>{item.purpose}</td>
                        {/* <td className='text-truncate table-responsive'>{item.remarks}</td> */}
                        <td className='text-center'>
                          <div className='d-grid'>
                            <>
                            {(() => {
                              // Approved:2
                              if (item.status && item.status == 3) {
                                return (
                                  <Button 
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="success"
                                    size="sm"
                                    >
                                    Approved
                                  </Button>
                                )
                              // Reject:3
                              } else if(item.status && item.status == 2) {
                                return (
                                  <Button 
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="danger"
                                    size="sm"
                                    >
                                    Disapproved
                                  </Button>
                                )
                              } 

                              return (
                                <Button 
                                  className=" p-1 pt-0 pb-0"
                                  style={{fontSize: '12px', color: 'white'}}
                                  variant="secondary"
                                  size="sm"
                                  >
                                  Pending
                                </Button>
                              )

                            })()}
                            </>
                          </div>
                        </td>
                        <td className='text-center'>
                          <Button
                            onClick={() => showLeaveRequest(item.leave_application_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant='btn-transparent'
                            size='sm'
                            >
                            <View/>
                          </Button>
                          <Button
                            onClick={() => handleShowReject(item.leave_application_id)}
                            className='p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant='btn-transparent'
                            size='sm'
                            >
                            <Reject/>
                          </Button>
                          <Button
                            onClick={() => handleShowAccept(item.leave_application_id)}
                            className='p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant='btn-transparent'
                            size='sm'
                            >
                            <Accept/>
                          </Button>
                        </td>
                      </tr>
                    )
                    ) : (
                    <tr className='no-item'>
                      <td colSpan={10}>{TEXT.NOT_FOUND}</td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>

            <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
              pageClicked={(page: number) => { 
                  getLeaveRequest(page);
                }
              }
              />

            <Dialog 
              show={showAccept} 
              setShow={handleCloseAccept}
              setEvent={() => { 
                  acceptLeaveRequest(item.leave_application_id)
                }
              }
              variant='accept'
              message='Please confirm to accept leave request?'
            />

            <Dialog 
              show={showReject} 
              setShow={handleCloseReject}
              setEvent={() => { 
                  rejectLeaveRequest(item.leave_application_id)
                }
              }
              variant='reject'
              message='Do you really want to reject these leave request?'
            />

            <Loader show={showLoading}/>
          </Card.Body>
        </Card>

        {/* DETAIL MODAL*/}
        <Modal className='modal' centered size={'lg'} show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>
              <Stack direction='horizontal'>
                <div>
                  {
                    item.employee.photo != "" ?
                        <Avatar
                            sx={{ width: 65, height: 65 }}
                            src={process.env.REACT_APP_IMAGE_URL+ item.employee.photo}
                        ></Avatar> :
                        <Avatar
                            sx={{ width: 65, height: 65}}
                            src={""}
                        ></Avatar>
                  }
                </div>
                <div className='mx-3'>
                  &nbsp;{item.employee?.first_name} {item.employee?.last_name}
                  <br />
                  &nbsp;<span className='text-muted fw-light'>{item.employee?.user_name?.role?.role_name}</span>
                </div>
              </Stack>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={7}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                    <tr>
                      <td className='fw-bold' width={120}>
                        Leave Type:
                      </td>
                      <td className='fw-bold'>{item.leave_type?.leave_description}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold' width={120}>
                        Applied On:
                      </td>
                      <td className='fw-bold'>{item.application_date}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold' width={120}>
                        No. of Days:
                      </td>
                      <td className='fw-bold'>{item.number_of_day} days</td>
                    </tr>
                    <tr>
                      <td className='fw-bold' width={120}>
                        Date of Leave:
                      </td>
                      <td className='fw-bold'>{item.application_from_date} - {item.application_to_date}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold' width={120}>
                        Reason:
                      </td>
                      <td className='fw-bold'>{item.purpose}</td>
                    </tr>
                  </tbody>
                </Table>    
              </Col>
              <Col
                sm={5}
                style={{
                  borderLeft: 'solid 1px #A7A9AC',
                }}
                >
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr>
                    <th colSpan={2} className="text-center">
                      {
                        item.approvers_order == 0 ?
                            "By Order" : "Anyone from Approvers list"
                      }
                    </th>
                  </tr>

                    {item.request_status.length > 0 ? (
                      item.request_status.map((item: any, index: any) =>
                        <div key={index}>
                          <tr>
                            <td className='fw-bold' width={180}>
                              Approver's {index+1} Name:
                            </td>
                            <td>{item.employee?.first_name} {item.employee?.last_name}</td>
                          </tr>
                          <tr>
                            <td className='fw-bold' width={180}>
                              Approver's Role:
                            </td>
                            <td>{item.employee?.user_name?.role?.role_name}</td>
                          </tr>
                          <>
                            {(() => {
                              // Approved:2
                              if (item.status == '3') {
                                return (
                                  <>
                                    <tr>
                                      <td className='fw-bold' width={180}>
                                        Date Approved/Rejected:
                                      </td>
                                      <td>{item.approve_date}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold' width={180}>
                                        Status:
                                      </td>
                                      <td className='fw-bold'>
                                        <Button size={'sm'} className=' pe-1 px-1 text-white' variant={'success'}>
                                          Approved
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                )
                              // Reject:3
                              } else if(item.status == '2') {
                                return (
                                  <>
                                    <tr>
                                      <td className='fw-bold' width={180}>
                                        Date Approved/Rejected:
                                      </td>
                                      <td>{item.reject_date}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold' width={180}>
                                        Status:
                                      </td>
                                      <td className='fw-bold'>
                                        <Button size={'sm'} className=' pe-1 px-1 text-white' variant={'danger'}>
                                          Disapproved
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                )
                              } 

                              return (
                                <>
                                  <tr>
                                    <td className='fw-bold' width={180}>
                                      Status:
                                    </td>
                                    <td className='fw-bold'>
                                      <Button size={'sm'} className=' pe-1 px-1 text-white' variant={'secondary'}>
                                        Pending
                                      </Button>
                                    </td>
                                  </tr>
                                </>
                              )
                            })()}
                          </>
                          <br/>
                        </div>
                      )
                      ) : (
                        <></>
                      )
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => rejectLeaveRequest(item.leave_application_id)} 
              variant='outline-primary' 
              className=' px-5 pe-5 mx-2'  
              size={'sm'}
              >
              Rejected
            </Button>
            <Button 
              onClick={() => acceptLeaveRequest(item.leave_application_id)}
              variant='primary' 
              className='text-white px-5 pe-5' 
              size={'sm'}
              >
              Approved
            </Button>
          </Modal.Footer>
        </Modal>

        <br />
      </Container>
    </>
  )
}
