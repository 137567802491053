import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IStatistic } from './types'

const initialState: IStatistic = {
  employee: 0,
  department: 0,
  present: 0,
  absent: 0
}

const StatisticsSlice = createSlice({
  name: 'Statistics',
  initialState,
  reducers: {
    addStatistics: (_state, state: PayloadAction<IStatistic>) => state.payload,
  },
})

export const { addStatistics } = StatisticsSlice.actions
export default StatisticsSlice.reducer
