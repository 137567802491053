import React from 'react'

const AttendanceIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.156'
      height='16.727'
      viewBox='0 0 15.156 16.727'
    >
      <g id='Attendance' transform='translate(0.5 0.5)'>
        <g id='Icon_feather-calendar' data-name='Icon feather-calendar'>
          <path
            id='Path_1'
            data-name='Path 1'
            d='M6.073,6h11.01a1.553,1.553,0,0,1,1.573,1.532V18.259a1.553,1.553,0,0,1-1.573,1.532H6.073A1.553,1.553,0,0,1,4.5,18.259V7.532A1.553,1.553,0,0,1,6.073,6Z'
            transform='translate(-4.5 -4.064)'
            fill='none'
            stroke='#a7a9ac'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
          <path
            id='Path_2'
            data-name='Path 2'
            d='M24,3V6.873'
            transform='translate(-13.777 -3)'
            fill='none'
            stroke='#a7a9ac'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
          <path
            id='Path_3'
            data-name='Path 3'
            d='M12,3V6.873'
            transform='translate(-8.068 -3)'
            fill='none'
            stroke='#a7a9ac'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
          <path
            id='Path_4'
            data-name='Path 4'
            d='M4.5,15H18.655'
            transform='translate(-4.5 -8.709)'
            fill='none'
            stroke='#a7a9ac'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
        </g>
        <path
          id='Icon_feather-check'
          data-name='Icon feather-check'
          d='M11.7,9,7.78,12.916,6,11.136'
          transform='translate(-1.551 -0.264)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default AttendanceIcon
