import React, { Component } from 'react'
import { Container, Row } from 'react-bootstrap'
import AttendanceReport from './AttendanceReport/Bpo'
import AttendanceReport2 from './AttendanceReport/SecurityAgency'
import AttendanceReport3 from './AttendanceReport/Construction'
import AttendanceReport4 from './AttendanceReport/Retail'
import AttendanceReport5 from './AttendanceReport/Architecture'
import {Route} from "react-router-dom";
import Overtime from "./Overtime/Bpo";
import COA from "./COA/Bpo";
import OfficialBusiness from "./OfficialBusiness/Bpo";
import EmployeeList from "../EmployeeManagement/EmployeeList";
import HourShift from "../Setup/Employee/HourShift";
import Overtime3 from "./Overtime/SecurityAgency";
import COA3 from "./COA/SecurityAgency";
import OfficialBusiness3 from "./OfficialBusiness/SecurityAgency";
import Overtime2 from "./Overtime/Construction";
import COA2 from "./COA/Construction";
import OfficialBusiness2 from "./OfficialBusiness/Construction";
import EmployeeList2 from "../EmployeeManagement/EmployeeList/retail";
import HourShift2 from "../Setup/Employee/HourShift/Retail";

class Index extends Component {


  render() {
    return (
      <Container fluid>
        {
          (() => {
            if (localStorage.getItem('clientProfile') == "bpo") {
              return (<AttendanceReport />)

            } else if(localStorage.getItem('clientProfile') == "security_agency"){
              return (<AttendanceReport2 />)

            } else if(localStorage.getItem('clientProfile') == "construction"){
              return (<AttendanceReport3 />)

            }else if(localStorage.getItem('clientProfile') == "retail"){
              return (<AttendanceReport4/>)

            }else if(localStorage.getItem('clientProfile') == "architecture"){
              return (<AttendanceReport5/>)

            }else if(localStorage.getItem('clientProfile') == "standard"){
              return (<AttendanceReport4/>)

            }
          })()
        }
      </Container>
    )
  }
}

export default Index
