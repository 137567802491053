import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
  Stack
} from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Accept from 'asset/SvgComponent/Accept'
import AcceptDisabled from 'asset/SvgComponent/AcceptDisabledIcon'
import Reject from 'asset/SvgComponent/Reject'
import RejectDisabled from 'asset/SvgComponent/RejectDisabledIcon'
import View from 'asset/SvgComponent/View'
import Delete from 'asset/SvgComponent/Delete'
import Write from 'asset/SvgComponent/Write'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Calendar from 'asset/SvgComponent/Calendar'
import Clock from 'asset/SvgComponent/Clock'
import SwapIcon from 'asset/SvgComponent/SwapIcon'
import Download from 'asset/SvgComponent/Download'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Avatar } from '@mui/material'

import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import { Formik, FieldArray} from 'formik';
import { validationSchema } from './validationSchema'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT, IMAGE_BASE_URL
} from 'utils/globalConstant'
import {
  IList,
  IItem,
  IItemRequest,
  IListRequest,
  IEmployee,
  IChangeSchedule,
  IChangeScheduleRequest, ISchedule
} from './types'
import moment from "moment"
import Select from "components/ReactSelect";
import CircleCreate from "asset/SvgComponent/CircleCreate";
import Popover from "react-bootstrap/Popover";
import AttendanceLeaveIcon from "asset/SvgComponent/AttendanceLeaveIcon";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default function Index() {
  const [key, setKey] = useState('shift-swap-application')
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [currentPage1, setCurrentPage1] = useState(0)
  const [lastPage1, setLastPage1] = useState(0)
  const [itemsPerPage1, setPerPage1] = useState(10)
  const [itemsCount1, setItemCount1] = useState(0)
  const [from1, setFrom1] = useState(0)

  const [showCreate, setShowCreate] = useState(false)
  const handleCloseCreate = () => {
    setSwapShiftDetails({is_rest_day: -1, start_from: "", end_to: "", swap_is_rest_day: -1, swap_start_from: "", swap_end_to: ""})
    setSelectedEmployeeOption({value: 0, label: "Select Employee"})
    setSelectedSwapEmployeeOption({value: 0, label: "Select Employee"})
    setShowCreate(false)
  }
  const handleShowCreate = async () => {
    setShowCreate(true)
    getAllEmployee()
  }

  const [showEdit, setShowEdit] = useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {

    setShowConfirm(true)
    setItem({
      id: id,
      employee_id: 0,
      type: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      requested_attendance: [{
        id: 0,
        certificate_of_attendance_id: 0,
        time_in_out: '',
        time_in_out_date: '',
        time_in_out_time: ''
      }],
      employee: {
        employee_id: 0,
        first_name: "",
        last_name: "",
        user_name: {
          role: {
            role_name: ""
          }
        },
        photo: ""
      }
    })
  }

  const [showDetail, setShowDetail] = useState(false)
  const handleCloseDetail = () => setShowDetail(false)
  const handleShowDetail = () => setShowDetail(true)

  const [showAccept, setShowAccept] = useState(false)
  const handleCloseAccept = () => setShowAccept(false)
  const handleShowAccept = (id: any) => {
    setShowAccept(true)
    setItemRequest({
      swap_first_name: "",
      swap_last_name: "",
      swap_photo: "",
      branch_id: 0,
      end_to: "",
      first_name: "",
      is_swap: 0,
      last_name: "",
      photo: "",
      schedule_date: "",
      swap_schedule_date: "",
      start_from: "",
      swap_start_from: "",
      swap_end_to: "",
      is_rest_day: 0,
      swap_is_rest_day: 0,
      swap_employee_id: 0,
      id: id,
      employee_id: 0,
      type: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      swap_employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: '',
        approvers_order: ''
      }],
      approvers_order: 0,
      approve_by: "0",
      applied_by_first_name: "",
      applied_by_last_name: "",
      levels_of_approval: [
        {
          approvers_order: "",
          level: ""
        }
      ],
      current: ''
    })
  }

  const [showReject, setShowReject] = useState(false)
  const handleCloseReject = () => setShowReject(false)
  const handleShowReject = (id: any) => {
    setShowReject(true)
    setItemRequest({
      swap_first_name: "",
      swap_last_name: "",
      swap_photo: "",
      branch_id: 0,
      end_to: "",
      first_name: "",
      is_swap: 0,
      last_name: "",
      photo: "",
      schedule_date: "",
      swap_schedule_date: "",
      start_from: "",
      swap_start_from: "",
      swap_end_to: "",
      is_rest_day: 0,
      swap_is_rest_day: 0,
      swap_employee_id: 0,
      id: id,
      employee_id: 0,
      type: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      swap_employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: '',
        approvers_order: ''
      }],
      approvers_order: 0,
      approve_by:"0",
      applied_by_first_name: "",
      applied_by_last_name: "",
      levels_of_approval: [
        {
          approvers_order: "",
          level: ""
        }
      ],
      current: ''
    })
  }

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const [lists, setData] = useState<IList[]>([])
  const [requests, setRequest] = useState<IListRequest[]>([])
  const [item, setItem] = useState<IItem>({
    id: 0,
    employee_id: 0,
    type: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    requested_attendance: [{
      id: 0,
      certificate_of_attendance_id: 0,
      time_in_out: '',
      time_in_out_date: '',
      time_in_out_time: ''
    }],
    employee: {
      employee_id: 0,
      first_name: "",
      last_name: "",
      user_name: {
        role: {
          role_name: ""
        }
      },
      photo: ""
    }
  })

  const [itemRequest, setItemRequest] = useState<IItemRequest>({
    swap_first_name: "",
    swap_last_name: "",
    swap_photo: "",
    branch_id: 0,
    end_to: "",
    first_name: "",
    is_swap: 0,
    last_name: "",
    photo: "",
    schedule_date: "",
    swap_schedule_date: "",
    start_from: "",
    swap_start_from: "",
    swap_end_to: "",
    is_rest_day: 0,
    swap_is_rest_day: 0,
    swap_employee_id: 0,
    id: 0,
    employee_id: 0,
    type: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    approvers_order: 0,
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    swap_employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      level: '',
      approvers_order: ''
    }],
    approve_by: "0",
    applied_by_first_name: "",
    applied_by_last_name: "",
    levels_of_approval: [
      {
        approvers_order: "",
        level: ""
      }
    ],
    current: ''
  })
  const [dateFormat, setDateFormat] = useState('')
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [selectedSwapEmployeeOption, setSelectedSwapEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [selectedEmployeeOptionForEdit, setSelectedEmployeeOptionForEdit] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
  const [swapEmployeeOptions, setSwapEmployeeOptions] = useState<{value: number, label: string}[]>([]);
  const [disableSelect, setDisableSelect] = useState(false)
  const [addedFiles, setAddedFiles] = useState([])
  const [uploadedFilesDisplay, setUploadedFilesDisplay] = useState([])
  const [showApproveCancelButton, setShowApproveCancelButton] = useState(true)
  const [searchedApplication, setSearchedApplication] = useState("")
  const [searchedApproval, setSearchedApproval] = useState("")
  const [swapShiftDetails, setSwapShiftDetails] = useState({
    is_rest_day: -1,
    start_from: "",
    end_to: "",
    swap_is_rest_day: -1,
    swap_start_from: "",
    swap_end_to: ""
  })


  const handleSearchedApplication = (e: any) => {
    setSearchedApplication(e.target.value)
    getChangeSchedule(null, e.target.value)
  }

  const handleSearchApproval = (e: any) => {
    setSearchedApproval(e.target.value)
    getChangeScheduleRequest(null, e.target.value)
  }


  const handleDisableAcceptRejectButton = (item: any) => {
    if(item.status == '3' || item.status == '2'){
      return true
    }else if(item.current == '0' || item.current == '2'){
      return true
    }else{
      return false
    }

  }

  const handleChangeScheduleDate = async (e: any) => {


    try {
      const res = await axios.get<IAPI_Response<ISchedule>>(API_ENDPOINT.GET_EMPLOYEE_SCHEDULE_BASED_ON_DATE,{
        params: {
          employee_id: selectedEmployeeOption.value,
          date: e.target.value
        }
      })
      const result = await res
      if (result?.data?.results?.id) {
        const schedule = result?.data?.results;
        setSwapShiftDetails({...swapShiftDetails, is_rest_day: schedule.is_rest_day, start_from: schedule.start_from, end_to: schedule.end_to})

      }else{
        setError("Employee has no schedule on the specified date.")
        setShowError(true)
        setSwapShiftDetails({...swapShiftDetails, is_rest_day: -1, start_from: "", end_to: ""})
      }
    } catch {
      console.log('There is something wrong')
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleChangeSwapScheduleDate = async (e: any) => {


    try {
      const res = await axios.get<IAPI_Response<ISchedule>>(API_ENDPOINT.GET_EMPLOYEE_SCHEDULE_BASED_ON_DATE,{
        params: {
          employee_id: selectedSwapEmployeeOption.value,
          date: e.target.value
        }
      })
      const result = await res
      if (result?.data?.results?.id) {

        const schedule = result?.data?.results;
        setSwapShiftDetails({...swapShiftDetails, swap_is_rest_day: schedule.is_rest_day, swap_start_from: schedule.start_from, swap_end_to: schedule.end_to})
      }else{

        setError("Employee has no schedule on the specified date.")
        setShowError(true)
        setSwapShiftDetails({...swapShiftDetails, swap_is_rest_day: -1, swap_start_from: "", swap_end_to: ""})
      }
    } catch {
      console.log('There is something wrong')
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleChangeDropdownEmployee = async (selectedOption: any) => {
    setSelectedEmployeeOption(selectedOption)

    try {
      const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.GET_EMPLOYEE_SAME_BRANCH_DEPARTMENT+"/"+selectedOption.value)
      const result = await res
      if (result?.data?.results?.length) {
        const employees = result?.data?.results;

        setSwapEmployeeOptions([])
        setSwapEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
        employees.map(employee => {
          setSwapEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
        })


      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const handleChangeDropdownSwapEmployee = (selectedOption: any) => {
    setSelectedSwapEmployeeOption(selectedOption)
  }

  const handleChangeDropdownOverviewForEdit = (selectedOption: any) => {
    setSelectedEmployeeOptionForEdit(selectedOption)
  }


  const getAllEmployee = async () => {
    try {
      const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
      const result = await res
      if (result?.data?.results?.length) {
        const employees = result?.data?.results;
        if(JSON.parse(localStorage.getItem('role') ?? "") != "Admin" &&
            JSON.parse(localStorage.getItem('role') ?? "") != "Supervisor"){
          employees.map(employee => {
            if(employee.employee_id == Number(localStorage.getItem('employee_id'))){
              setSelectedEmployeeOption({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
              setDisableSelect(true)
              setEmployeeOptions([])
              setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
              setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
            }
          })

        }else{
          setDisableSelect(false)
          setEmployeeOptions([])
          setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
          employees.map(employee => {
            setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
          })
        }

      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getChangeSchedule = async (page_number: null | number, searched_keyword: string) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IChangeSchedule>>(API_ENDPOINT.GET_CHANGE_SCHEDULE, {
        params: {
          page: page_number,
          searched: searched_keyword
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }else{
        setData([])
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }


  const postShiftSwap = async (value: any, {resetForm} : any) => {
    if(selectedEmployeeOption.value != 0 && selectedSwapEmployeeOption.value != 0){
      if(swapShiftDetails.is_rest_day == -1){ // employee has no schedule
        setError("Employee has no schedule.")
        setShowError(true)
      }else if(swapShiftDetails.swap_is_rest_day == -1){ // swap employee has no schedule
        setError("Swap Employee has no schedule.")
        setShowError(true)
      }else{
        await axios.post(API_ENDPOINT.POST_SHIFT_SWAP, {
          employee_id: selectedEmployeeOption.value,
          swap_employee_id: selectedSwapEmployeeOption.value,
          reason: value.reason,
          date: value.date,
          swap_date: value.swap_date,
          is_rest_day: swapShiftDetails.is_rest_day,
          start_from: swapShiftDetails.start_from,
          end_to: swapShiftDetails.end_to,
          swap_is_rest_day: swapShiftDetails.swap_is_rest_day,
          swap_start_from: swapShiftDetails.swap_start_from,
          swap_end_to: swapShiftDetails.swap_end_to
        }).then(response => {
          if (response?.data?.status * 1 === 1) {
            setShowSuccess(true)
            setSuccess(SUCCESS_MESSAGE.SHIFT_SWAP.CREATED)
            getChangeSchedule(null, searchedApplication);
            getChangeScheduleRequest(null, searchedApproval)

          }
          resetForm({})
          handleCloseCreate();
        });
      }

    }else{
      setError("Please select employee.")
      setShowError(true)
    }


    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }


  const deleteShiftSwap = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_SHIFT_SWAP +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.SHIFT_SWAP.DELETED)
        getChangeSchedule(currentPage, searchedApplication);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getChangeScheduleRequest = async (page_number: null | number, searched_keyword: string) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IChangeScheduleRequest>>(API_ENDPOINT.GET_CHANGE_SCHEDULE_REQUEST, {
        params: {
          page: page_number,
          searched: searched_keyword
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setRequest(result?.data?.results?.data)
        setCurrentPage1(result?.data?.results?.current_page)
        setLastPage1(result?.data?.results?.last_page)
        setPerPage1(result?.data?.results?.per_page)
        setItemCount1(result?.data?.results?.total)
        setFrom1(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showShiftSwapRequest = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItemRequest>>(API_ENDPOINT.SHOW_SHIFT_SWAP_REQUEST +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItemRequest(result?.data?.results)
      }
      handleShowDetail()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const acceptShiftSwapRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.ACCEPT_SHIFT_SWAP_REQUEST +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.SHIFT_SWAP.ACCEPTED)
           getChangeScheduleRequest(currentPage1, searchedApproval);
           getChangeSchedule(currentPage, searchedApplication)
       }
       handleCloseAccept();
       handleCloseDetail();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const rejectShiftSwapRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.REJECT_SHIFT_SWAP_REQUEST +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.SHIFT_SWAP.REJECTED)
         getChangeScheduleRequest(currentPage1, searchedApproval);
         getChangeSchedule(currentPage, searchedApplication)
       }
       handleCloseReject();
       handleCloseDetail();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getChangeSchedule(null, searchedApplication)
      getChangeScheduleRequest(null, searchedApproval)
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Shift Swap</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/tax-rule-setup'>
            Attendance
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Shift Swap</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Tabs
          variant={'tabs'}
          className='tabs '
          id='controlled-tab-example'
          activeKey={key}
          onSelect={(k: any) => {
            setKey(k)
          }}
          >
          <Tab eventKey='shift-swap-application' title='Application'>
            <Card className='border-0 p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Shift Swap Application</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25 mx-2'}>
                      <Form.Control
                        aria-label='search'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder='Search...'
                        onChange={handleSearchedApplication}
                        value={searchedApplication}
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                    <Button
                      onClick={handleShowCreate}
                      className=' add-new-btn rounded text-white'
                      variant='warning'
                      >
                      <EditIcon/>
                      &nbsp;Apply for Shift Swap
                    </Button>{' '}
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table className='bordered-tabs-top mt-3' responsive>
                  <thead>
                    <tr className='text-center'>
                      <th style={{width: '4%'}}>No.</th>
                      <th style={{width: '10%'}}>Application Date</th>
                      <th style={{width: '15%'}}>Employee Name</th>
                      <th style={{width: '9%'}}>Swap To</th>
                      <th style={{width: '15%'}}>Swap Name</th>
                      <th style={{width: '15%'}}>Reason</th>
                      <th>Applied By</th>
                      <th style={{ width: '50px' }}>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {lists.length > 0 ? (
                        lists.map((item, index) => 
                          <tr className='mb-2' key={index}>
                            <td className="text-center">{from + index}</td>
                            <td className="text-center">{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                            <td>
                              <Stack direction='horizontal'>
                                <div>
                                  {
                                    item.photo != "" ?
                                        <Avatar
                                            sx={{ width: 20, height: 20 }}
                                            src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                        ></Avatar> :
                                        <Avatar
                                            sx={{ width: 20, height: 20}}
                                            src={""}
                                        ></Avatar>
                                  }
                                </div>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Popover id={`popover-positioned-right`} style={{fontSize: '10px', width: '200px'}}>
                                        <Popover.Body>
                                          {
                                              item.is_rest_day == 1 ?
                                                  <Row>
                                                    <Col className='text-center' sm={12}><b>Rest Day</b></Col>
                                                  </Row> :
                                                  <>
                                                    <Row>
                                                      <Col sm={6}><b>Shift From</b></Col>
                                                      <Col className='text-center' sm={6}>{item.start_from}</Col>
                                                    </Row>
                                                    <Row>
                                                      <Col sm={6}><b>Shift To</b></Col>
                                                      <Col className='text-center' sm={6}>{item.end_to}</Col>
                                                    </Row>
                                                  </>
                                          }
                                        </Popover.Body>
                                      </Popover>
                                    }
                                >
                                  <div className='employee-profile'>&nbsp;{item.first_name} {item.last_name}</div>
                                </OverlayTrigger>
                              </Stack>
                            </td>
                            <td className='text-center'>{item.is_swap == 1 ? <SwapIcon width={22} height={22}/> : "-"}</td>
                            <td>
                              {
                                item.swap_employee_id != 0 ?
                                    <Stack direction='horizontal' title={"Swap Photo"} >
                                      <div>
                                        {
                                          item.swap_photo != "" ?
                                              <Avatar
                                                  sx={{ width: 20, height: 20 }}
                                                  src={process.env.REACT_APP_IMAGE_URL+ item.swap_photo}
                                              ></Avatar> :
                                              <Avatar
                                                  sx={{ width: 20, height: 20}}
                                                  src={""}
                                              ></Avatar>
                                        }
                                      </div>
                                      <OverlayTrigger
                                          placement="left"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Popover id={`popover-positioned-right`} style={{fontSize: '10px', width: '200px'}}>
                                              <Popover.Body>
                                                {
                                                  item.swap_is_rest_day == 1 ?
                                                      <Row>
                                                        <Col className='text-center' sm={12}><b>Rest Day</b></Col>
                                                      </Row> :
                                                      <>
                                                        <Row>
                                                          <Col sm={6}><b>Shift From</b></Col>
                                                          <Col className='text-center' sm={6}>{item.swap_start_from}</Col>
                                                        </Row>
                                                        <Row>
                                                          <Col sm={6}><b>Shift To</b></Col>
                                                          <Col className='text-center' sm={6}>{item.swap_end_to}</Col>
                                                        </Row>
                                                      </>
                                                }
                                              </Popover.Body>
                                            </Popover>
                                          }
                                      >
                                        <div className='employee-profile'>&nbsp;{item.swap_first_name} {item.swap_last_name}</div>
                                      </OverlayTrigger>

                                    </Stack>
                                    : "-"
                              }

                            </td>
                            <td className="display-reason" title={item.reason ?? ""}>{item.reason}</td>
                            <td>{item.applied_by_first_name} {item.applied_by_last_name}</td>
                            <td className='text-center'>
                              <div className='d-grid'>
                                <>
                                  {(() => {
                                    switch (item.status) {
                                      case '1':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-secondary'
                                            size='sm'
                                            >
                                            Pending
                                          </Button>
                                        )
                                      case '2':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-danger'
                                            size='sm'
                                            >
                                            Disapproved
                                          </Button>
                                        )
                                      case '3':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-success'
                                            size='sm'
                                            >
                                            Approved
                                          </Button>
                                        )
                                    }
                                  })()}
                                </>
                              </div>
                            </td>
                            <td className='text-center'>
                              <Button
                                  onClick={() => {
                                    showShiftSwapRequest(item.id)
                                    setShowApproveCancelButton(false)
                                  }}
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                              >
                                <View/>
                              </Button>
                              {/*<Button*/}
                              {/*  onClick={() => showCertificate(item.id)}*/}
                              {/*  className=' p-1 pt-0 pb-0'*/}
                              {/*  style={{ fontSize: '12px', color: 'white' }}*/}
                              {/*  variant=' btn-transparent'*/}
                              {/*  size='sm'*/}
                              {/*  disabled={item.status == '3' ? true: false}*/}
                              {/*  >*/}
                              {/*  <Write/>*/}
                              {/*</Button>*/}
                              {/*<Button*/}
                              {/*  onClick={() => handleShowConfirm(item.id)}*/}
                              {/*  className=' p-1 pt-0 pb-0'*/}
                              {/*  style={{ fontSize: '12px', color: 'white' }}*/}
                              {/*  variant=' btn-transparent'*/}
                              {/*  size='sm'*/}
                              {/*  disabled={item.status == '3' ? true: false}*/}
                              {/*  >*/}
                              {/*  <Delete/>*/}
                              {/*</Button>*/}
                            </td>
                          </tr>
                        )
                        ) : (
                        <tr className='no-item'>
                          <td colSpan={10}>{TEXT.NOT_FOUND}</td>
                        </tr>
                      )}
                    </>
                  </tbody>
                </Table>

                <Pagination
                  itemsCount={itemsCount}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  lastPage={lastPage}
                  setCurrentPage={setCurrentPage}
                  alwaysShown={false}
                  pageClicked={(page: number) => {
                    getChangeSchedule(page, searchedApplication);
                    }
                  }
                  />

                <Dialog 
                  show={showConfirm} 
                  setShow={handleCloseConfirm}
                  setEvent={() => {
                    deleteShiftSwap(item.id)
                    }
                  }
                />

                <Loader show={showLoading}/>
              </Card.Body>
            </Card>
          </Tab>

          <Tab eventKey='shift-swap-approval' title='Approval'>
            <Card className='border-0 p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Shift Swap Approval</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25'}>
                      <Form.Control
                        aria-label='search'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder='Search...'
                        onChange={handleSearchApproval}
                        value={searchedApproval}
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table className='bordered-tabs-top mt-3' responsive>
                  <thead>
                  <tr className='text-center'>
                    <th style={{width: '4%'}}>No.</th>
                    <th style={{width: '10%'}}>Application Date</th>
                    <th style={{width: '15%'}}>Employee Name</th>
                    <th style={{width: '9%'}}>Swap To</th>
                    <th style={{width: '15%'}}>Swap Name</th>
                    <th style={{width: '15%'}}>Reason</th>
                    <th>Applied By</th>
                    <th style={{ width: '50px' }}>Status</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                    <>
                      {requests.length > 0 ? (
                        requests.map((item, index) => 
                          <tr className='mb-2' key={index}>
                            <td>{from1 + index}</td>
                            <td className="text-center">{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                            <td>
                              <Stack direction='horizontal'>
                                <div>
                                  {
                                    item.photo != "" ?
                                        <Avatar
                                            sx={{ width: 20, height: 20 }}
                                            src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                        ></Avatar> :
                                        <Avatar
                                            sx={{ width: 20, height: 20}}
                                            src={""}
                                        ></Avatar>
                                  }
                                </div>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Popover id={`popover-positioned-right`} style={{fontSize: '10px', width: '200px'}}>
                                        <Popover.Body>
                                          {
                                            item.is_rest_day == 1 ?
                                                <Row>
                                                  <Col className='text-center' sm={12}><b>Rest Day</b></Col>
                                                </Row> :
                                                <>
                                                  <Row>
                                                    <Col sm={6}><b>Shift From</b></Col>
                                                    <Col className='text-center' sm={6}>{item.start_from}</Col>
                                                  </Row>
                                                  <Row>
                                                    <Col sm={6}><b>Shift To</b></Col>
                                                    <Col className='text-center' sm={6}>{item.end_to}</Col>
                                                  </Row>
                                                </>
                                          }
                                        </Popover.Body>
                                      </Popover>
                                    }
                                >
                                  <div className='employee-profile'>&nbsp;{item.first_name} {item.last_name}</div>
                                </OverlayTrigger>
                              </Stack>
                            </td>
                            <td className='text-center'>{item.is_swap == 1 ? <SwapIcon width={22} height={22}/> : "-"}</td>
                            <td>
                              {
                                item.swap_employee_id != 0 ?
                                    <Stack direction='horizontal'>
                                      <div>
                                        {
                                          item.swap_photo != "" ?
                                              <Avatar
                                                  sx={{ width: 20, height: 20 }}
                                                  src={process.env.REACT_APP_IMAGE_URL+ item.swap_photo}
                                              ></Avatar> :
                                              <Avatar
                                                  sx={{ width: 20, height: 20}}
                                                  src={""}
                                              ></Avatar>
                                        }
                                      </div>
                                      <OverlayTrigger
                                          placement="left"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Popover id={`popover-positioned-right`} style={{fontSize: '10px', width: '200px'}}>
                                              <Popover.Body>
                                                {
                                                  item.swap_is_rest_day == 1 ?
                                                      <Row>
                                                        <Col className='text-center' sm={12}><b>Rest Day</b></Col>
                                                      </Row> :
                                                      <>
                                                        <Row>
                                                          <Col sm={6}><b>Shift From</b></Col>
                                                          <Col className='text-center' sm={6}>{item.swap_start_from}</Col>
                                                        </Row>
                                                        <Row>
                                                          <Col sm={6}><b>Shift To</b></Col>
                                                          <Col className='text-center' sm={6}>{item.swap_end_to}</Col>
                                                        </Row>
                                                      </>
                                                }
                                              </Popover.Body>
                                            </Popover>
                                          }
                                      >
                                        <div className='employee-profile'>&nbsp;{item.swap_first_name} {item.swap_last_name}</div>
                                      </OverlayTrigger>
                                    </Stack>
                                    : "-"
                              }

                            </td>
                            <td className="display-reason" title={item.reason ?? ""}>{item.reason}</td>
                            <td>{item.applied_by_first_name} {item.applied_by_last_name}</td>
                            <td className='text-center'>
                              <div className='d-grid'>
                                <>
                                  {(() => {
                                    switch (item.status) {
                                      case '1':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-secondary'
                                            size='sm'
                                            >
                                            Pending
                                          </Button>
                                        )
                                      case '2':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-danger'
                                            size='sm'
                                            >
                                            Disapproved
                                          </Button>
                                        )
                                      case '3':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-success'
                                            size='sm'
                                            >
                                            Approved
                                          </Button>
                                        )
                                      case '4':
                                        return (
                                            <Button
                                                className=' p-1 pt-0 pb-0'
                                                style={{ fontSize: '12px', color: 'white' }}
                                                variant=' btn-danger'
                                                size='sm'
                                            >
                                              Disapproved
                                            </Button>
                                        )
                                    }
                                  })()}
                                </>
                              </div>
                            </td>
                            <td className='text-center'>
                              <Button
                                onClick={() => {
                                  showShiftSwapRequest(item.id)
                                  setShowApproveCancelButton(true)
                                }}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                >
                                <View/>
                              </Button>
                              <Button
                                  onClick={() => handleShowAccept(item.id)}
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                                  disabled={handleDisableAcceptRejectButton(item)}
                              >
                                {
                                  handleDisableAcceptRejectButton(item) ?
                                   <AcceptDisabled/> : <Accept/>
                                }
                              </Button>
                              <Button
                                onClick={() => handleShowReject(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                disabled={handleDisableAcceptRejectButton(item)}
                                >
                                {
                                  handleDisableAcceptRejectButton(item) ?
                                      <RejectDisabled/> : <Reject/>
                                }

                              </Button>
                            </td>
                          </tr>
                        )
                        ) : (
                        <tr className='no-item'>
                          <td colSpan={10}>{TEXT.NOT_FOUND}</td>
                        </tr>
                      )}
                    </>
                  </tbody>
                </Table>
                  <Pagination
                    itemsCount={itemsCount1}
                    itemsPerPage={itemsPerPage1}
                    currentPage={currentPage1}
                    lastPage={lastPage1}
                    setCurrentPage={setCurrentPage1}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getChangeScheduleRequest(page, searchedApproval);
                      }
                    }
                    />

                  <Dialog 
                    show={showAccept} 
                    setShow={handleCloseAccept}
                    setEvent={() => { 
                        acceptShiftSwapRequest(itemRequest.id)
                      }
                    }
                    variant='accept'
                    message='Please confirm to accept certificate request?'
                  />

                  <Dialog 
                    show={showReject} 
                    setShow={handleCloseReject}
                    setEvent={() => {
                      rejectShiftSwapRequest(itemRequest.id)
                      }
                    }
                    variant='reject'
                    message='Do you really want to reject these certificate request?'
                  />

                  <Loader show={showLoading}/>

              </Card.Body>
            </Card>
          </Tab>
        </Tabs>

        {/* ADD MODAL*/}
        <Formik
          initialValues={{
            reason: '',
            employee_id: '',
            date: '',
            start_from: '',
            end_to: '',
            is_rest_day: 0,
            swap_employee_id: '',
            swap_date: '',
            swap_start_from: '',
            swap_end_to: '',
            swap_is_rest_day: 0
          }}
          validationSchema={validationSchema}
          onSubmit={postShiftSwap}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="certificateCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' size='lg' centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Shift Swap Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Row>
                      <Col sm={4}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels required'>Employee</Form.Label>
                          <Select
                              name="employee_id"
                              value={selectedEmployeeOption}
                              options={employeeOptions}
                              onChange={(e:any )=> {
                                handleChangeDropdownEmployee(e)

                              }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.employee_id}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={4} className="text-center">

                      </Col>
                      <Col sm={4}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels required'>Swap Employee</Form.Label>
                          <Select
                              name="swap_employee_id"
                              value={selectedSwapEmployeeOption}
                              options={swapEmployeeOptions}
                              onChange={(e:any )=> {
                                handleChangeDropdownSwapEmployee(e)

                              }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.employee_id}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <Form.Group className="position-relative">
                          <Form.Label column="sm" className="required fw-bold">Date</Form.Label>
                          <InputGroup size={'sm'}>
                            <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                              <Calendar/>
                            </InputGroup.Text>
                            <Form.Control
                                size='sm'
                                type='date'
                                placeholder=''
                                name={'date'}
                                value={values.date}
                                onChange={(e) => {
                                  handleChange(e)
                                  handleChangeScheduleDate(e)
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.date && !!errors.date}
                                min={new Date().toISOString().split('T')[0]}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              { errors.date}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col sm={4} className='text-center'>
                        <Form.Group controlId='formHorizontalEmail'>
                          <Form.Label className='form-labels fw-bold'>SWAP</Form.Label>
                          <div className='m-auto'>
                            <SwapIcon/>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group className="position-relative">
                          <Form.Label column="sm" className="required fw-bold">Swap Date</Form.Label>
                          <InputGroup size={'sm'}>
                            <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                              <Calendar/>
                            </InputGroup.Text>
                            <Form.Control
                                size='sm'
                                type='date'
                                placeholder=''
                                name={'swap_date'}
                                value={values.swap_date}
                                onChange={(e) => {
                                  handleChange(e)
                                  handleChangeSwapScheduleDate(e)
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.swap_date && !!errors.swap_date}
                                min={new Date().toISOString().split('T')[0]}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.swap_date}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-4'>
                      <Col sm={4}>
                        <Form.Group controlId='exampleForm.ControlTextarea1'>
                          <Form.Check
                              inline
                              name='is_rest_day'
                              label='Rest Day'
                              value='rest_day'
                              readOnly={true}  // Making the checkbox read-only
                              onChange={e => e.preventDefault()}  // Preventing any change event
                              // onChange={e => {
                              //   handleChange(e)
                              //   setShowHourly(true)
                              //   values.number_of_day = '0.5';
                              // }}
                              checked={swapShiftDetails.is_rest_day == 1}
                              // isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={4}></Col>
                      <Col sm={4}>
                        <Form.Group controlId='exampleForm.ControlTextarea1'>
                          <Form.Check
                              inline
                              name='swap_is_rest_day'
                              label='Rest Day'
                              value='swap_rest_day'
                              readOnly={true}  // Making the checkbox read-only
                              onChange={e => e.preventDefault()}  // Preventing any change event
                              // onChange={e => {
                              //   handleChange(e)
                              //   setShowHourly(true)
                              //   values.number_of_day = '0.5';
                              // }}
                              checked={swapShiftDetails.swap_is_rest_day == 1}
                              // isInvalid={touched.isHalfOrWholeDay && !!errors.isHalfOrWholeDay}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2}>
                        <Form.Group className="position-relative">
                          <Form.Label column="sm" className='fw-bold'>Start</Form.Label>
                          <InputGroup size={'sm'}>
                            <Form.Control
                                size='sm'
                                type='time'
                                placeholder=''
                                disabled={true}
                                name={'start_from'}
                                value={swapShiftDetails.is_rest_day != 1 ?swapShiftDetails.start_from : ""}
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                // isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.time_in_out_date : false) && !!ticketTouched.time_in_out_date}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {/*{ (typeof ticketErrors === 'object') ? ticketErrors.time_in_out_date : ''}*/}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group className="position-relative">
                          <Form.Label column="sm" className='fw-bold'>End</Form.Label>
                          <InputGroup size={'sm'}>
                            <Form.Control
                                size='sm'
                                type='time'
                                placeholder=''
                                disabled={true}
                                name={'end_to'}
                                value={swapShiftDetails.is_rest_day != 1 ?swapShiftDetails.end_to : ""}
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                // isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.time_in_out_date : false) && !!ticketTouched.time_in_out_date}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {/*{ (typeof ticketErrors === 'object') ? ticketErrors.time_in_out_date : ''}*/}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col sm={4}></Col>
                      <Col sm={2}>
                        <Form.Group className="position-relative">
                          <Form.Label column="sm" className='fw-bold'>Start</Form.Label>
                          <InputGroup size={'sm'}>
                            <Form.Control
                                size='sm'
                                type='time'
                                placeholder=''
                                disabled={true}
                                name={'swap_start_from'}
                                value={swapShiftDetails.swap_is_rest_day != 1 ?swapShiftDetails.swap_start_from : ""}
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                // isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.time_in_out_date : false) && !!ticketTouched.time_in_out_date}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {/*{ (typeof ticketErrors === 'object') ? ticketErrors.time_in_out_date : ''}*/}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group className="position-relative">
                          <Form.Label column="sm" className='fw-bold'>End</Form.Label>
                          <InputGroup size={'sm'}>
                            <Form.Control
                                size='sm'
                                type='time'
                                placeholder=''
                                disabled={true}
                                name={'swap_end_to'}
                                value={swapShiftDetails.swap_is_rest_day != 1 ?swapShiftDetails.swap_end_to : ""}
                                // onChange={handleChange}
                                // onBlur={handleBlur}
                                // isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.time_in_out_date : false) && !!ticketTouched.time_in_out_date}
                            />
                            <Form.Control.Feedback type="invalid" tooltip>
                              {/*{ (typeof ticketErrors === 'object') ? ticketErrors.time_in_out_date : ''}*/}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-4'>
                      <Col sm={12}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                          <Form.Label className='fw-bold required'>
                            Reason
                          </Form.Label>
                          <Form.Control
                              as='textarea'
                              placeholder=''
                              style={{fontSize: '90%'}}
                              rows={3}
                              name="reason"
                              value={values.reason}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.reason && !!errors.reason}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.reason}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                    </Row>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button 
                    variant='primary text-white' 
                    form="certificateCreate"  
                    className='mx-2' 
                    size={'sm'} 
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Send Application
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>


         {/*DETAIL MODAL*/}
        <Modal className='modal' centered size={'lg'} show={showDetail} onHide={handleCloseDetail}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold title-alignment'>

                <div className='mx-3'>
                  {(() => {
                    switch (itemRequest.status) {
                      case '1':
                        return (
                            <>
                              &nbsp;<span className='text-primary'>{itemRequest.employee?.first_name} {itemRequest.employee?.last_name}</span> wants to swap schedule with <span className='text-primary'>{itemRequest.swap_employee?.first_name} {itemRequest.swap_employee?.last_name}</span>.
                            </>
                        )
                      case '2':
                        return (
                            <>
                              &nbsp;The request to swap schedule between {itemRequest.employee?.first_name} {itemRequest.employee?.last_name} and {itemRequest.swap_employee?.first_name} {itemRequest.swap_employee?.last_name} was <span className='text-danger'>disapproved</span>.
                            </>
                        )
                      case '3':
                        return (
                            <>
                              &nbsp;The request to swap schedule between {itemRequest.employee?.first_name} {itemRequest.employee?.last_name} and {itemRequest.swap_employee?.first_name} {itemRequest.swap_employee?.last_name} was <span className='green-text'>approved</span>.
                            </>
                        )
                    }
                  })()}

                </div>

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>

                  <tr className='lh-1'>
                    <td className='fw-bold' width={90}>
                      Applied On:
                    </td>
                    <td>{itemRequest.application_date}</td>
                  </tr>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={90}>
                      Applied By:
                    </td>
                    <td>{itemRequest.applied_by_first_name} {itemRequest.applied_by_last_name}</td>
                  </tr>

                  </tbody>
                </Table>
              </Col>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={90}>
                      Reason:
                    </td>
                    <td>{itemRequest.reason}</td>
                  </tr>

                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr/>
            <Row style={{marginTop: '-15px'}}>
              <Table borderless className='mt-0 mb-0'>
                <tbody>
                <tr className='lh-1'>
                  <th className='text-center'>Swap Details</th>
                </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col sm={5}>
                <Row>
                  <Col sm={2}></Col>
                  <Col sm={8}>
                    <Stack direction='horizontal'>
                      <div>
                        {
                          itemRequest.employee.photo != "" ?
                              <Avatar
                                  sx={{ width: 65, height: 65 }}
                                  src={process.env.REACT_APP_IMAGE_URL+ itemRequest.employee.photo}
                              ></Avatar> :
                              <Avatar
                                  sx={{ width: 65, height: 65}}
                                  src={""}
                              ></Avatar>
                        }
                      </div>
                      <div className='mx-3'>
                        &nbsp;{itemRequest.employee?.first_name} {itemRequest.employee?.last_name}
                        <br />
                        &nbsp;<span className='text-muted fw-light'>{itemRequest.employee?.user_name?.role?.role_name}</span>
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}></Col>
                  <Col sm={8}>
                    <Table borderless className='mt-0 mb-0'>
                      <tbody style={{ padding: 'none' }}>
                      {
                        itemRequest.status == '3' ?
                            <tr className='lh-1'>
                              <td colSpan={2} className='fw-bold text-center green-text' width={90}>
                                New Schedule
                              </td>
                            </tr>
                            : ""
                      }

                      {
                        itemRequest.status == '3' ?
                            <>
                              <tr className='lh-1'>
                                <td className='fw-bold'>
                                  Date:
                                </td>
                                <td>{moment(new Date(itemRequest.swap_schedule_date ?? "")).format(dateFormat)}</td>
                              </tr>
                              <tr className='lh-1'>
                                <td className='fw-bold'>
                                  Shift:
                                </td>
                                <td>{itemRequest.swap_is_rest_day == 1 ? "Rest Day" : itemRequest.swap_start_from + " - " + itemRequest.swap_end_to}</td>
                              </tr>
                            </>
                            :
                            <>
                              <tr className='lh-1'>
                                <td className='fw-bold' width={90}>
                                  Date:
                                </td>
                                <td>{moment(new Date(itemRequest.schedule_date ?? "")).format(dateFormat)}</td>
                              </tr>
                              <tr className='lh-1'>
                                <td className='fw-bold' width={90}>
                                  Shift:
                                </td>
                                <td>{itemRequest.is_rest_day == 1 ? "Rest Day" : itemRequest.start_from + " - " + itemRequest.end_to}</td>
                              </tr>
                            </>
                      }

                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
              <Col sm={2} className='text-center'>
                <Form.Group controlId='formHorizontalEmail'>
                  <div className='m-auto mt-4'>
                    <SwapIcon/>
                  </div>
                </Form.Group>
              </Col>
              <Col sm={5}>
                <Row>
                  <Col sm={2}></Col>
                  <Col sm={8}>
                    <Stack direction='horizontal'>
                      <div>
                        {
                          itemRequest.swap_employee.photo != "" ?
                              <Avatar
                                  sx={{ width: 65, height: 65 }}
                                  src={process.env.REACT_APP_IMAGE_URL+ itemRequest.swap_employee.photo}
                              ></Avatar> :
                              <Avatar
                                  sx={{ width: 65, height: 65}}
                                  src={""}
                              ></Avatar>
                        }
                      </div>
                      <div className='mx-3'>
                        &nbsp;{itemRequest.swap_employee?.first_name} {itemRequest.swap_employee?.last_name}
                        <br />
                        &nbsp;<span className='text-muted fw-light'>{itemRequest.swap_employee?.user_name?.role?.role_name}</span>
                      </div>
                    </Stack>
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}></Col>
                  <Col sm={8}>
                    <Table borderless className='mt-0 mb-0'>
                      <tbody style={{ padding: 'none' }}>
                      {
                        itemRequest.status == '3' ?
                            <tr className='lh-1'>
                              <td colSpan={2} className='fw-bold text-center green-text' width={90}>
                                New Schedule
                              </td>
                            </tr>
                            : ""
                      }
                      {
                        itemRequest.status == '3' ?
                            <>
                              <tr className='lh-1'>
                                <td className='fw-bold'>
                                  Swap Date:
                                </td>
                                <td>{moment(new Date(itemRequest.schedule_date ?? "")).format(dateFormat)}</td>
                              </tr>
                              <tr className='lh-1'>
                                <td className='fw-bold'>
                                  Shift:
                                </td>
                                <td>{itemRequest.is_rest_day == 1 ? "Rest Day" : itemRequest.start_from + " - " + itemRequest.end_to}</td>
                              </tr>
                            </>
                            : <>
                              <tr className='lh-1'>
                                <td className='fw-bold' width={90}>
                                  Swap Date:
                                </td>
                                <td>{moment(new Date(itemRequest.swap_schedule_date ?? "")).format(dateFormat)}</td>
                              </tr>
                              <tr className='lh-1'>
                                <td className='fw-bold' width={90}>
                                  Shift:
                                </td>
                                <td>{itemRequest.swap_is_rest_day == 1 ? "Rest Day" : itemRequest.swap_start_from + " - " + itemRequest.swap_end_to}</td>
                              </tr>
                            </>
                      }

                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/*<Row>*/}
            {/*  <Col sm={4}>*/}
            {/*    <Stack direction='horizontal'>*/}
            {/*      <div>*/}
            {/*        {*/}
            {/*          itemRequest.employee.photo != "" ?*/}
            {/*              <Avatar*/}
            {/*                  sx={{ width: 65, height: 65 }}*/}
            {/*                  src={process.env.REACT_APP_IMAGE_URL+ itemRequest.employee.photo}*/}
            {/*              ></Avatar> :*/}
            {/*              <Avatar*/}
            {/*                  sx={{ width: 65, height: 65}}*/}
            {/*                  src={""}*/}
            {/*              ></Avatar>*/}
            {/*        }*/}
            {/*      </div>*/}
            {/*      <div className='mx-3'>*/}
            {/*        &nbsp;{itemRequest.employee?.first_name} {itemRequest.employee?.last_name}*/}
            {/*        <br />*/}
            {/*        &nbsp;<span className='text-muted fw-light'>{itemRequest.employee?.user_name?.role?.role_name}</span>*/}
            {/*      </div>*/}
            {/*    </Stack>*/}
            {/*  </Col>*/}
            {/*  <Col sm={4}></Col>*/}
            {/*  <Col sm={4} className='d-flex'>*/}
            {/*    <Stack direction='horizontal'>*/}
            {/*      <div>*/}
            {/*        {*/}
            {/*          itemRequest.swap_employee.photo != "" ?*/}
            {/*              <Avatar*/}
            {/*                  sx={{ width: 65, height: 65 }}*/}
            {/*                  src={process.env.REACT_APP_IMAGE_URL+ itemRequest.swap_employee.photo}*/}
            {/*              ></Avatar> :*/}
            {/*              <Avatar*/}
            {/*                  sx={{ width: 65, height: 65}}*/}
            {/*                  src={""}*/}
            {/*              ></Avatar>*/}
            {/*        }*/}
            {/*      </div>*/}
            {/*      <div className='mx-3'>*/}
            {/*        &nbsp;{itemRequest.swap_employee?.first_name} {itemRequest.swap_employee?.last_name}*/}
            {/*        <br />*/}
            {/*        &nbsp;<span className='text-muted fw-light'>{itemRequest.swap_employee?.user_name?.role?.role_name}</span>*/}
            {/*      </div>*/}
            {/*    </Stack>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row className='mt-3'>*/}
            {/*  <Col sm={4}>*/}
            {/*    <Table borderless className='mt-0 mb-0'>*/}
            {/*      <tbody style={{ padding: 'none' }}>*/}
            {/*      {*/}
            {/*        itemRequest.status == '3' ?*/}
            {/*            <tr className='lh-1'>*/}
            {/*              <td colSpan={2} className='fw-bold text-center' style={{color: '#1EA66D'}} width={90}>*/}
            {/*                New Schedule*/}
            {/*              </td>*/}
            {/*            </tr>*/}
            {/*            : ""*/}
            {/*      }*/}

            {/*      {*/}
            {/*        itemRequest.status == '3' ?*/}
            {/*            <>*/}
            {/*              <tr className='lh-1'>*/}
            {/*                <td className='fw-bold' width={90}>*/}
            {/*                  Date:*/}
            {/*                </td>*/}
            {/*                <td>{moment(new Date(itemRequest.swap_schedule_date ?? "")).format(dateFormat)}</td>*/}
            {/*              </tr>*/}
            {/*              <tr className='lh-1'>*/}
            {/*                <td className='fw-bold' width={90}>*/}
            {/*                  Shift:*/}
            {/*                </td>*/}
            {/*                <td>{itemRequest.swap_is_rest_day == 1 ? "Rest Day" : itemRequest.swap_start_from + " - " + itemRequest.swap_end_to}</td>*/}
            {/*              </tr>*/}
            {/*            </>*/}
            {/*            :*/}
            {/*            <>*/}
            {/*              <tr className='lh-1'>*/}
            {/*                <td className='fw-bold' width={90}>*/}
            {/*                  Date:*/}
            {/*                </td>*/}
            {/*                <td>{moment(new Date(itemRequest.schedule_date ?? "")).format(dateFormat)}</td>*/}
            {/*              </tr>*/}
            {/*              <tr className='lh-1'>*/}
            {/*                <td className='fw-bold' width={90}>*/}
            {/*                Shift:*/}
            {/*                </td>*/}
            {/*                <td>{itemRequest.is_rest_day == 1 ? "Rest Day" : itemRequest.start_from + " - " + itemRequest.end_to}</td>*/}
            {/*              </tr>*/}
            {/*            </>*/}
            {/*      }*/}

            {/*      </tbody>*/}
            {/*    </Table>*/}
            {/*  </Col>*/}
            {/*  <Col sm={4} className='text-center'>*/}
            {/*    <Form.Group controlId='formHorizontalEmail'>*/}
            {/*      <div className='m-auto'>*/}
            {/*        <SwapIcon/>*/}
            {/*      </div>*/}
            {/*    </Form.Group>*/}
            {/*  </Col>*/}
            {/*  <Col sm={4}>*/}
            {/*    <Table borderless className='mt-0 mb-0'>*/}
            {/*      <tbody style={{ padding: 'none' }}>*/}
            {/*      {*/}
            {/*        itemRequest.status == '3' ?*/}
            {/*            <tr className='lh-1'>*/}
            {/*              <td colSpan={2} className='fw-bold text-center' style={{color: '#1EA66D'}} width={90}>*/}
            {/*                New Schedule*/}
            {/*              </td>*/}
            {/*            </tr>*/}
            {/*            : ""*/}
            {/*      }*/}
            {/*      {*/}
            {/*        itemRequest.status == '3' ?*/}
            {/*            <>*/}
            {/*              <tr className='lh-1'>*/}
            {/*                <td className='fw-bold' width={90}>*/}
            {/*                  Swap Date:*/}
            {/*                </td>*/}
            {/*                <td>{moment(new Date(itemRequest.schedule_date ?? "")).format(dateFormat)}</td>*/}
            {/*              </tr>*/}
            {/*              <tr className='lh-1'>*/}
            {/*                <td className='fw-bold' width={90}>*/}
            {/*                  Shift:*/}
            {/*                </td>*/}
            {/*                <td>{itemRequest.is_rest_day == 1 ? "Rest Day" : itemRequest.start_from + " - " + itemRequest.end_to}</td>*/}
            {/*              </tr>*/}
            {/*            </>*/}
            {/*            : <>*/}
            {/*              <tr className='lh-1'>*/}
            {/*                <td className='fw-bold' width={90}>*/}
            {/*                  Swap Date:*/}
            {/*                </td>*/}
            {/*                <td>{moment(new Date(itemRequest.swap_schedule_date ?? "")).format(dateFormat)}</td>*/}
            {/*              </tr>*/}
            {/*              <tr className='lh-1'>*/}
            {/*                <td className='fw-bold' width={90}>*/}
            {/*                  Shift:*/}
            {/*                </td>*/}
            {/*                <td>{itemRequest.swap_is_rest_day == 1 ? "Rest Day" : itemRequest.swap_start_from + " - " + itemRequest.swap_end_to}</td>*/}
            {/*              </tr>*/}
            {/*            </>*/}
            {/*      }*/}

            {/*      </tbody>*/}
            {/*    </Table>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <hr/>
            <Row style={{marginTop: '-15px'}}>
              <Table borderless className='mt-0 mb-0'>
                <tbody>
                <tr className='lh-1'>
                  <th className='text-center'>Approvals</th>
                </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col sm={12}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <th className='text-center' style={{width: '20%'}}>Levels</th>
                    <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                    <th className='text-center'>Role</th>
                    <th className='text-center' style={{width: '15%'}}>Status</th>
                    <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                  </tr>
                  {
                    itemRequest.request_status.length > 0 ? (
                        itemRequest.request_status.map((item: any, index: any) =>
                            (
                                <tr key={index} className='lh-1'>
                                  <td>
                                    {
                                      item.level != "0" ?
                                        index != 0 && itemRequest.request_status[index - 1].level == item.level ?
                                            "" : <>
                                              <b>Level {item.level}</b> : {item.approvers_order == "0" ? "By Order" : "Anyone"}
                                            </>
                                          : <b>Swap Employee</b>
                                    }
                                  </td>
                                  <td>
                                    <Stack direction='horizontal'>
                                      <div>
                                        {
                                          item.employee?.photo != "" ?
                                              <Avatar
                                                  sx={{ width: 20, height: 20 }}
                                                  src={process.env.REACT_APP_IMAGE_URL+ item.employee?.photo}
                                                  className={"profile-avatar"}
                                              ></Avatar> :
                                              <Avatar
                                                  sx={{ width: 20, height: 20}}
                                                  src={""}
                                                  className={"profile-avatar"}
                                              ></Avatar>
                                        }
                                      </div>
                                      <div>&nbsp;{item.employee?.first_name} {item.employee?.last_name}</div>
                                    </Stack>
                                  </td>
                                  <td>{item.employee?.user_name?.role?.role_name}</td>
                                  {(() => {
                                    // Approved:2
                                    if (item.status == '3') {

                                      return (
                                          <>
                                            <td className='fw-bold text-center'>
                                              <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                                Approved
                                              </Button>
                                            </td>
                                            <td className='text-center'>{moment(new Date(item.approve_date ?? "")).format(dateFormat)}</td>
                                          </>
                                      )

                                      // Reject:3
                                    } else if(item.status == '2') {
                                      return (
                                          <>
                                            <td className='fw-bold text-center'>
                                              <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                                Disapproved
                                              </Button>
                                            </td>
                                            <td className='text-center'>{moment(new Date(item.reject_date ?? "")).format(dateFormat)}</td>

                                          </>
                                      )
                                    } else if(item.status == '4') {
                                      return (
                                          <>
                                            <td className='text-center'>-</td>
                                            <td className='text-center'>-</td>
                                          </>

                                      )
                                    }

                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                              Pending
                                            </Button>
                                          </td>
                                          <td className='text-center'>-</td>
                                        </>
                                    )
                                  })()}


                                </tr>

                            )

                        )
                    ) : (
                        <></>
                    )
                  }
                  </tbody>
                </Table>
              </Col>

            </Row>
          </Modal.Body>
          <Modal.Footer>
            {
              showApproveCancelButton ?
                  <>
                    <Button
                        onClick={() => rejectShiftSwapRequest(itemRequest.id)}
                        variant='outline-primary'
                        className=' px-5 pe-5 mx-2'
                        size={'sm'}
                        disabled={handleDisableAcceptRejectButton(itemRequest)}
                    >
                      Rejected
                    </Button>
                    <Button
                        onClick={() => acceptShiftSwapRequest(itemRequest.id)}
                        variant='primary'
                        className='text-white px-5 pe-5'
                        size={'sm'}
                        disabled={handleDisableAcceptRejectButton(itemRequest)}
                    >
                      Approved
                    </Button>
                  </>

              : ""
            }

          </Modal.Footer>
        </Modal>
        <br />
      </Container>
    </>
  )
}
