import React from 'react'

const HomeActiveIcon = () => {
  return (
    <svg
      id='HomeActive'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='18'
      height='18'
      viewBox='0 0 18 18'
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='0.5'
          x2='0.5'
          y2='1'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#f26930' />
          <stop offset='1' stopColor='#fba91a' />
        </linearGradient>
      </defs>
      <rect
        id='Rectangle_159'
        data-name='Rectangle 159'
        width='8'
        height='9'
        fill='url(#linear-gradient)'
      />
      <rect
        id='Rectangle_160'
        data-name='Rectangle 160'
        width='7'
        height='9'
        transform='translate(11 9)'
        fill='url(#linear-gradient)'
      />
      <rect
        id='Rectangle_161'
        data-name='Rectangle 161'
        width='7'
        height='7'
        transform='translate(11)'
        fill='url(#linear-gradient)'
      />
      <rect
        id='Rectangle_162'
        data-name='Rectangle 162'
        width='8'
        height='7'
        transform='translate(0 11)'
        fill='url(#linear-gradient)'
      />
    </svg>
  )
}

export default HomeActiveIcon
