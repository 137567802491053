import React, { useEffect } from 'react'
import {
  Card,
  Col,
  Row,
  Table
} from "react-bootstrap";
import {deepOrange} from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Alert from 'components/Alert'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  ERROR_MESSAGE
} from 'utils/globalConstant'
import { IList, IBirthday, IAnniversary, IHoliday } from './types'
import moment from "moment"

export default function Index() {
  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [birthday, setBirthday] = React.useState<IBirthday[]>([])
  const [anniversary, setAnniversary] = React.useState<IAnniversary[]>([])
  const [holiday, setHoliday] = React.useState<IHoliday[]>([])
  const [dateFormat, setDateFormat] = React.useState('')

  const getSpecialEvent = async () => {
    try {
      const response = await axios.get<IAPI_Response<IList>>(API_ENDPOINT.GET_SPECIAL_EVENT)
      const result = await response
      if (result?.data?.results) {
        setBirthday(result?.data?.results?.birthday)
        setAnniversary(result?.data?.results?.anniversary)
        setHoliday(result?.data?.results?.holidays)
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getSpecialEvent()
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />

      <Card className="border-0 p-0 bordered-card" style={{overflowY: 'hidden', height: '311px'}}>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <span className="fw-bold">What's Today?</span>
            </Col>
          </Row>

          <Table size="sm" responsive  className="bordered-tabs-top mt-2">
            <tbody>
              <>
                {
                  (birthday).map((item, index) => 
                    <tr className="mb-2" key={index}>
                      <td style={{width: '30px'}}>
                        <Avatar
                          sx={{bgcolor: deepOrange[500]}}
                          alt="Remy Sharp"
                          src="/broken-image.jpg"
                          variant="rounded"
                          >
                          H
                        </Avatar>
                      </td>
                      <td className="fw-bold">
                        {item.first_name} {item.last_name}
                        <br/>
                        <small 
                          className="fw-light text-muted text-truncate"
                          style={{fontSize: '12px'}}
                          >
                          {moment(new Date(item.date_of_birth ?? "")).format(dateFormat)} | Birthday
                        </small>
                      </td>
                      <td className="text-center mb-2">
                        <small 
                          className="fw-light  text-muted text-truncate"
                          style={{fontSize: '12px'}}
                          >
                          {item.days_left != 0 ? item.days_left + ' days left' : ''}
                        </small>
                      </td>
                    </tr>
                  )
                }

                {
                  (anniversary).map((item, index) => 
                    <tr className="mb-2" key={index}>
                      <td style={{width: '30px'}}>
                        <Avatar
                          sx={{bgcolor: deepOrange[500]}}
                          alt="Remy Sharp"
                          src="/broken-image.jpg"
                          variant="rounded"
                          >
                          H
                        </Avatar>
                      </td>
                      <td className="fw-bold">
                        {item.first_name} {item.last_name}
                        <br/>
                        <small 
                          className="fw-light text-muted text-truncate"
                          style={{fontSize: '12px'}}
                          >
                          {moment(new Date(item.date_of_joining ?? "")).format(dateFormat)} | Work Anniversary
                        </small>
                      </td>
                      <td className="text-center mb-2">
                        <small 
                          className="fw-light  text-muted text-truncate"
                          style={{fontSize: '12px'}}
                          >
                          {item.days_left != 0 ? item.days_left + ' days left' : ''}
                        </small>
                      </td>
                    </tr>
                  )
                }

                {
                  (holiday).map((item, index) => 
                    <tr className="mb-2" key={index}>
                      <td style={{width: '30px'}}>
                        <Avatar
                          className="text-center"
                          alt="Remy Sharp"
                          src="/broken-image.jpg"
                          variant="rounded"
                          >
                          {item.day} {item.month}
                        </Avatar>
                      </td>
                      <td className="fw-bold">
                        {item.holiday_name}
                        <br/>
                        <small 
                          className="fw-light text-muted text-truncate"
                          style={{fontSize: '12px'}}
                          >
                          {item.comment}
                        </small>
                      </td>
                      <td className="text-center mb-2">
                        <small 
                          className="fw-light text-muted text-truncate"
                          style={{fontSize: '12px'}}
                          >
                        </small>
                      </td>
                    </tr>
                  )
                }

                {
                  birthday.length == 0 && anniversary.length == 0 && holiday.length == 0 ?
                      (<tr className="mb-2">
                        <td colSpan={3} className={'text-center'}>
                          No Special Events
                        </td>
                      </tr>) : ""
                }
              </>
            </tbody>
          </Table>
          {
            (birthday.length + anniversary.length + holiday.length) >= 4 ? (
              <div className="text-center">
                <h6 className="fw-bold text-muted text-truncate" style={{fontSize: '12px'}}>
                  View All({birthday.length + anniversary.length + holiday.length}) Upcoming Events
                </h6>
              </div>
            ) : null
          }
        </Card.Body>
      </Card>
    </>
  );
}
