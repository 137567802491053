import React from 'react'

const AttendanceHafhdayIcon = () => {
  return (
    <svg
      id='Attendance-Halfday-Icon'
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 18 18'
      >
      <defs>
        <clipPath id='clip-path'>
          <rect
            id='Rectangle_96'
            data-name='Rectangle 96'
            width='18'
            height='18'
            fill='#f26930'
          />
        </clipPath>
      </defs>
      <g id='Group_413' data-name='Group 413' clipPath='url(#clip-path)'>
        <path
          id='Path_77'
          data-name='Path 77'
          d='M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0M8.929,16.086H8.884a7.088,7.088,0,1,1,0-14.175h.045Z'
          fill='#f26930'
        />
      </g>
    </svg>
  )
}

export default AttendanceHafhdayIcon
