import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ICompanyValue } from './types'

const initialState: ICompanyValue = {
  company_email: '',
  employee_no: '',
  finger_id: '',
  supervisor_id: '',
  department_id: '',
  designation_id: '',
  branch_id: '',
  date_of_joining: '',
  workflow: [{
    approver_employee_id: ''
  }],
  approvers_order: "",
  employee_type: ''
}

export const CompanySlice = createSlice({
  name: 'Company',
  initialState,
  reducers: {
    addCompany: (_state, action: PayloadAction<ICompanyValue>) => action.payload,
  },
})

export const { addCompany } = CompanySlice.actions
export default CompanySlice.reducer
