import React from 'react'
import { Modal, Button} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons/faClose'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'

interface AlertProps {
  show: boolean,
  setShow: any,
  setEvent: any,
  variant?: null | string,
  message?: null | string
}

const DialogComponent: React.FC<AlertProps> = ({
  show,
  setShow,
  setEvent,
  variant = 'delete',
  message = 'Do you really want to delete these record?'
}) => {
  return (
    <Modal className='modal-confirm' centered show={show} onHide={setShow}>
        <Modal.Header closeButton>
        <div className="icon-box">
          {(() => {
            if (variant == 'accept') {
              return (
                <i className="icon-success">
                  <FontAwesomeIcon icon={faCheck} />
                </i>
              )
            } else if(variant == 'reject') {
              return (
                <i className="icon-confirm">
                  <FontAwesomeIcon icon={faClose} />
                </i>
              )
            }
            return (
              <i className="icon-confirm">
                <FontAwesomeIcon icon={faClose} />
              </i>
            )
          })()}
        </div>
        </Modal.Header>
        <Modal.Body>
        {(() => {
          if (variant == 'accept') {
            return (<h5>{ message }</h5>)
          } else if(variant == 'reject') {
            return (<h5>{ message }</h5>)
          }
          return (<h5>{ message }</h5>)
        })()}
        </Modal.Body>
        <Modal.Footer>
          <div className="icon-box">
            <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={setShow}>
              Cancel
            </Button>
            <Button
              onClick={setEvent}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
              >
              {(() => {
                if (variant == 'accept') {
                  return ('Accept')
                } else if(variant == 'reject') {
                  return ('Reject')
                } else if(variant == 'approve') {
                    return ('Approve')
                }
                return ('Delete')
              })()}
            </Button>
          </div>
        </Modal.Footer>
    </Modal>
  )
}

export default DialogComponent
