import React, { useEffect, useState, useRef } from 'react'
import { Button, Modal, Row } from 'react-bootstrap'
import { Grid, styled, Typography } from '@mui/material'
import Webcam from "react-webcam";
import SystemButton from 'components/Button'
import LocationMarker from 'components/Marker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons/faCameraRetro'
import PersonIcon from 'asset/SvgComponent/PersonIcon'
import {
  MapContainer,
  TileLayer
} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import Alert from 'components/Alert'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  ERROR_MESSAGE,
  ATTENDANCE
} from 'utils/globalConstant'
import { IAttendanceList } from './types'
import { IAttendance } from '../../Attendance/SecurityAgency/types'
import { IStatistic } from '../../../types'
import { useDispatch } from 'react-redux'
import { addAttendance } from 'redux/Dashboard/Attendance/AttendanceSlice'
import { addStatistics } from 'redux/Dashboard/Statistics/StatisticsSlice'
import moment from "moment/moment";
import Runningtime from "components/RunningTime";
import { ClientJS } from 'clientjs'
import {browserName, isMobile} from "react-device-detect";

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginRight: '35px',
  fontSize: theme.spacing(3),
  fontWeight: theme.fontStyle.weight.bold,
  lineHeight: `${theme.spacing(4)}px`,
}))

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

const ClockButton = () => {
  const dispatch = useDispatch()

  const [state, setState] = useState(true)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] =useState(false)

  const [type, setType] = useState('')
  const [clocks, setClocks] = useState({
    attendance_clock: '',
    disabled: false
  })
  const [breaks, setBreaks] = useState({
    attendance_break: ''
  })
  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
    address: ''
  })

  const [showSubmit, setSubmit] = useState(true)
  const [showMap, setShowMap] = useState(false)
  const [showCamera, setShowCamera] = useState(false)
  const [showCapture, setShowCapture] = useState(false)
  const handleCloseMap = () => setShowMap(false)
  const handleShowMap = () => setShowMap(true)
  const handleSubmitMap = () => {
    setShowCapture(true)
    handleCloseMap()
    setState(true)
    setCapturedImage(null);
    setShowCamera(false);
  }
  const handleCloseCapture = () => setShowCapture(false)
  const handleStartCapture = () => setShowCamera(true)

  const handleClock = (type: any) => {
    setType(type);
    handleShowMap()
  }

  const webcamRef: any = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [employeeAttendanceDetails, setEmployeeAttendanceDetails] = useState({
    employee_attendance_id: 0,
    in_time: "",
    out_time: "",
    in_time_pm: "",
    out_time_pm: "",
    worked_time: "",
    total_worked_time: "",
    worked_time_pm: ""
  });
  const [worktime, setWorktime] = useState('12:00:00');
  const [deviceInformation, setDeviceInformation] = useState({
    isMobile: 0,
    browserFingerprint: '',
    browser: '',
    device: '',
    height: '',
    width: ''
  })

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setState(!state)
    setSubmit(false)
  };

  const handleReCapture = () => {
    setCapturedImage(null);
    setState(!state)
    setSubmit(true)
  };

  const getLocation = (data: any) => {
    setLocation(data)
  }

  const getStatistics = async () => {
    try {
      const response = await axios.get<IAPI_Response<IStatistic>>(API_ENDPOINT.GET_STATISTICS)
      const result = await response
      if (result?.data?.results) {
        dispatch(addStatistics(result?.data?.results))
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getAttendanceAuth = async () => {
    try {
      const response = await axios.get<IAPI_Response<IAttendance>>(API_ENDPOINT.GET_ATTENDANCE_AUTH)
      const result = await response
      if (result?.data?.results?.data?.length) {
        dispatch(addAttendance(result?.data?.results?.data))
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
  }

  const getAttendanceTime = async (type : any) => {
      const response = await axios.get<IAPI_Response<IAttendanceList>>(API_ENDPOINT.GET_ATTENDANCE_TIME_FOR_MFBC, {
        params: {
          type: type,
        },
      })
      const result = await response
      if (result?.data?.results) {
        setEmployeeAttendanceDetails({
          employee_attendance_id: result?.data?.results.employee_attendance_id,
          in_time: result?.data?.results.in_time,
          out_time: result?.data?.results.out_time,
          in_time_pm: result?.data?.results.in_time_pm,
          out_time_pm: result?.data?.results.out_time_pm,
          worked_time: result?.data?.results.worked_time,
          total_worked_time: result?.data?.results.total_worked_time,
          worked_time_pm: result?.data?.results.worked_time_pm
        })
        const currentTime = moment(result?.data?.results.current_time).format('YYYY-MM-DD HH:mm:ss');

        if (type == 'clock') {
          if(result?.data?.results.out_time == null &&
              result?.data?.results.in_time == null){
            setEmployeeAttendanceDetails({...employeeAttendanceDetails, employee_attendance_id: 0})
            setClocks({
              attendance_clock: "out",
              disabled: false
            })
          }else if(result?.data?.results.out_time == null &&
              result?.data?.results.in_time != null){
            setClocks({
              attendance_clock: "in",
              disabled: false
            })
          }else if(result?.data?.results.out_time != null &&
              result?.data?.results.in_time != null){
            setClocks({
              attendance_clock: "out",
              disabled: false
            })
          }else{
            setClocks({
              attendance_clock: "out",
              disabled: false
            })
          }
        }

      }else{
        console.log("no data")
      }
  }

  const postAttendanceTime = async (e:any) => {
    e.currentTarget.disabled = true;

    let attendanceType = 'time_in';
    if (type == null || type == "") {
      attendanceType = 'am_time_in';
    } else if (type == 'am_in') {
      attendanceType = 'am_time_in';
    } else if (type == 'am_out') {
      attendanceType = 'am_time_out';
    } else if (type == 'pm_in') {
      attendanceType = 'pm_time_in';
    } else if (type == 'pm_out') {
      attendanceType = 'pm_time_out';
    }

    await axios.post(API_ENDPOINT.POST_ATTENDANCE_TIME_FOR_JVD, {
      latitude: location.latitude,
      longitude: location.longitude,
      address: location.address,
      type: attendanceType,
      image: capturedImage,
      employee_attendance_id: employeeAttendanceDetails.employee_attendance_id,
      browser_fingerprint: deviceInformation.browserFingerprint,
      device: deviceInformation.device,
      browser: deviceInformation.browser,
      isMobile: deviceInformation.isMobile,
      height: deviceInformation.height,
      width: deviceInformation.width
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
         if (clocks.attendance_clock == null) {
           setSuccess(ATTENDANCE.TIMEIN)
         } else if (clocks.attendance_clock == 'pm_in' || clocks.attendance_clock == 'am_in') {
           setSuccess(ATTENDANCE.TIMEOUT)
         } else if (clocks.attendance_clock == 'pm_out' || clocks.attendance_clock == 'pm_out') {
           setSuccess(ATTENDANCE.TIMEIN)
         }
          getAttendanceTime('clock')
          getAttendanceTime('break')
          getAttendanceAuth()
          getStatistics()
       }
    }).catch((err) => {
      setShowError(true)
      if(err.response.data.message == "device_not_allowed"){
        setError(ERROR_MESSAGE.device_not_allowed)
      }else if(err.response.data.message == "device_still_pending"){
        setError(ERROR_MESSAGE.device_still_pending)
      }else{
        setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }
    })

    handleCloseCapture();
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getDeviceName = () => {
    // Get the user agent string
    const userAgent = window.navigator.userAgent;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Define some common device keywords to check against
    const deviceKeywords = ['iPhone', 'iPad', 'Android', 'Windows Phone', 'Windows'];

    // Find the matching device keyword
    const matchingKeyword = deviceKeywords.find(keyword => userAgent.includes(keyword));

    // Set the device name based on the matching keyword
    if (matchingKeyword) {
      console.log(matchingKeyword);
    } else {
      console.log('Unknown Device');
    }

    // Create a new ClientJS object
    const client = new ClientJS();
    const fingerprint = client.getFingerprint();

    setDeviceInformation({device: matchingKeyword ?? "",
      browserFingerprint: fingerprint.toString(),
      browser: browserName,
      isMobile: isMobile ? 1 : 0,
      height: viewportHeight.toString(),
      width: viewportWidth.toString()})
  }

  useEffect(() => {
    ;(async () => {
      getAttendanceTime('clock')
      getAttendanceTime('break')

      getDeviceName()

    })()
  }, [])

  return (
    <Grid item container spacing={1}>
      <Grid item xs={12} md={4}>
        {/*<StyledTypography>{'00:00:00'}</StyledTypography>*/}

        {(() => {
          if(employeeAttendanceDetails.in_time == null && employeeAttendanceDetails.out_time == null &&
              employeeAttendanceDetails.in_time_pm == null && employeeAttendanceDetails.out_time_pm == null) {
            return (<StyledTypography className={"text-center"}>{'00:00:00'}</StyledTypography>)
          }else{
            return (<Runningtime
                in_time={employeeAttendanceDetails.in_time}
                out_time={employeeAttendanceDetails.out_time}
                in_time_pm={employeeAttendanceDetails.in_time_pm}
                out_time_pm={employeeAttendanceDetails.out_time_pm}
                worked_time={employeeAttendanceDetails.total_worked_time}
                worked_time_am={employeeAttendanceDetails.worked_time}
                worked_time_pm={employeeAttendanceDetails.worked_time_pm}
                type={'worked_time'}
                break_in={""}
                break_out={""}
                on_dashboard={true}
            />)
          }
        })()}

      </Grid>
      <Grid item xs={12} md={4}>
        <SystemButton
            onClick={() => handleClock('in')}
            color='primary'
            variant='contained'
            sx={{ width: '145px', height: '37px' }}
            // disabled={clocks.disabled}
        >
          Check In
        </SystemButton>
      </Grid>
      <Grid item xs={12} md={4}>
        {(() => {
          if (clocks.attendance_clock == null) {
            return (
                <SystemButton
                    onClick={() => handleClock('in')}
                    color='success'
                    variant='contained'
                    sx={{ width: '145px', height: '37px' }}
                    disabled={clocks.disabled}
                >
                  Clock In
                </SystemButton>
            )
          }else if(clocks.attendance_clock == 'in') {
            return (
                <SystemButton
                    onClick={() => handleClock('out')}
                    color='error'
                    variant='contained'
                    sx={{ width: '145px', height: '37px' }}
                    disabled={clocks.disabled}
                >
                  Clock Out
                </SystemButton>
            )
          }else if(clocks.attendance_clock == 'out') {
            return (
                <SystemButton
                    onClick={() => handleClock('in')}
                    color='success'
                    variant='contained'
                    sx={{ width: '145px', height: '37px' }}
                    disabled={clocks.disabled}
                >
                  Clock In
                </SystemButton>
            )
          }
          return (
            <SystemButton
              color='success'
              variant='contained'
              sx={{ width: '145px', height: '37px' }}
              >
            </SystemButton>
          )
        })()}
      </Grid>

      <Alert 
        type="success" 
        show={showSuccess} 
        message={success} 
        setShow={() => { 
            setShowSuccess(false);
          }
        }
      />

      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />

      {/* MAP MODAL*/}
      <Modal className='modal' size="lg" centered show={showMap} onHide={handleCloseMap}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>CLOCK IN/OUT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <MapContainer
              style={{height: '400px'}}
              center={{ lat: 0, lng: 0 }}
              zoom={13}
              scrollWheelZoom={false}
              attributionControl={false}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker func={getLocation}/>
            </MapContainer>
            <div className="text-center">
              Your current location : <b>{location.address}</b>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseMap}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmitMap}
            variant='primary text-white'
            size={'sm'}
            className='mx-2'
            >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* CAPTURE MODAL*/}
      <Modal className='modal' size="lg" centered show={showCapture} onHide={handleCloseCapture}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold ms-auto'>Please take a photo of yourself for your attendance.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            !showCamera ? (
              <Row style={{margin: "10px"}}>
                <div className="text-center"> 
                  <p className="capture-icon">
                    <PersonIcon/>
                  </p>
                  <Button
                    onClick={handleStartCapture}
                    variant='primary text-white'
                    size={'sm'}
                    className='mx-2'
                    >
                    <FontAwesomeIcon icon={faCameraRetro} />
                    &nbsp;Start Camera
                  </Button>
                </div>
              </Row>
            ) : (
              <Row style={{margin: "10px"}}>
                <div className="text-center"> 
                    <p>
                      {
                        capturedImage ? (
                          <img className="capture" src={capturedImage} alt="Captured Image" width="400" height="300"/>
                        ) : (
                          <Webcam
                            className="capture"
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            width="400" 
                            height="300"
                          />
                        )
                      }
                    </p>
                    
                    <Button
                        onClick={state ? handleCapture : handleReCapture }
                        variant='primary text-white'
                        size={'sm'}
                        className='mx-2'
                        >
                        <FontAwesomeIcon icon={faCameraRetro} />
                        &nbsp;{state ? 'Capture' : 'Recapture'}
                    </Button>
                </div>
            </Row>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCapture}>
            Cancel
          </Button>
          <Button
            disabled={showSubmit}
            onClick={postAttendanceTime}
            variant='primary text-white'
            size={'sm'}
            className='mx-2'
            >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Grid>
  )
}

export default ClockButton
