import React, { createContext, Dispatch, useState } from 'react'

interface IMainContext {
  openDrawer: boolean
  setOpenDrawer: Dispatch<boolean>
}
export const MainContext = createContext<IMainContext | null>(null)

type Props = {
  children: JSX.Element
}

const MainContextContainer: React.FC<Props> = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(true)
  return (
    <MainContext.Provider value={{ openDrawer, setOpenDrawer }}>{children}</MainContext.Provider>
  )
}

export default MainContextContainer
