import React from 'react'

const Delete = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.598'
      height='17.221'
      viewBox='0 0 15.598 17.221'
    >
      <g id='Delete' transform='translate(0.5 0.5)'>
        <path
          id='Path_23'
          data-name='Path 23'
          d='M4.5,9H19.1'
          transform='translate(-4.5 -5.756)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_24'
          data-name='Path 24'
          d='M18.854,6.244V17.6a1.622,1.622,0,0,1-1.622,1.622H9.122A1.622,1.622,0,0,1,7.5,17.6V6.244m2.433,0V4.622A1.622,1.622,0,0,1,11.555,3H14.8a1.622,1.622,0,0,1,1.622,1.622V6.244'
          transform='translate(-5.879 -3)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_25'
          data-name='Path 25'
          d='M15,16.5v4.866'
          transform='translate(-9.322 -9.201)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_26'
          data-name='Path 26'
          d='M21,16.5v4.866'
          transform='translate(-12.078 -9.201)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default Delete
