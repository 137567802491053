import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Formik } from 'formik';
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { validationSchema } from './validationSchema'
import { IDepartment, IList, IItem } from './types'
import moment from "moment"

export default function Index() {
  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [validation, setValidation] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)

  const [showCreate, setShowCreate] = React.useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)

  const [showEdit, setShowEdit] = React.useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)
  
  const [showConfirm, setShowConfirm] = React.useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      department_id: id,
      department_name: ''
    })
  }

  const [lists, setData] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    department_id: 0,
    department_name: ''
  })
  const [dateFormat, setDateFormat] = useState('')

  const getDepartment = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const res = await axios.get<IAPI_Response<IDepartment>>(API_ENDPOINT.GET_DEPARTMENT, {
        params: {
          page: page_number,
        },
      })
      const result = await res
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showDepartment = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_DEPARTMENT+'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const createDepartment = async (value: any, {resetForm} : any) => {
    await axios.post(API_ENDPOINT.POST_DEPARTMENT, {
      department_name: value.department_name
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.DEPARTMENT.CREATED)
          getDepartment(null);
       }
       resetForm({})
       handleCloseCreate();
    }).catch(error => {
       setValidation(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const updateDepartment = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_DEPARTMENT +'/'+ value.department_id, {
      department_name: value.department_name
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.DEPARTMENT.UPDATED)
          getDepartment(null);
       }
       resetForm({})
       handleCloseEdit();
    }).catch(error => {
      setValidation(error.response.data.message)
    })
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const deleteDepartment = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_DEPARTMENT +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.DEPARTMENT.DELETED)
        getDepartment(null);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getDepartment(null)
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Department</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item
            className=' text-decoration-none'
            href='/employeeManagement/employee-list'
          >
            Employees
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Department</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />
        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <span className='fw-bold'>Department Summary</span>
              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                <InputGroup size='sm' className={'w-25'}>
                  <Form.Control
                    aria-label='search'
                    aria-describedby='inputGroup-sizing-sm'
                    placeholder='Search...'
                  />
                  <InputGroup.Text className='bg-transparent search-input '>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
                <Button
                  onClick={handleShowCreate}
                  className=' add-new-btn rounded mx-2 text-white'
                  variant='warning'
                >
                  <EditIcon/>
                  &nbsp;Add New Department
                </Button>
              </Col>
            </Row>
            {/* <hr/>*/}
            <Table responsive className='bordered-tabs-top mt-3'>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>No.</th>
                  <th style={{ width: '15%' }}>Department Name</th>
                  <th style={{ width: '15%' }}>Date</th>
                  <th className={'text-center'}>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                {lists.length > 0 ? (
                  lists.map((item, index) => (
                    <tr className='mb-2' key={index}>
                      <td>{from + index}</td>
                      <td className='fw-bold'>{item.department_name}</td>
                      <td>{moment(new Date(item.created_at ?? "")).format(dateFormat)}</td>
                      <td className='text-center'>
                      <Button
                            onClick={() => showDepartment(item.department_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Write/>
                          </Button>
                          <Button
                            onClick={() => handleShowConfirm(item.department_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Delete/>
                          </Button>
                      </td>
                      <br />
                    </tr>
                  ))
                  ) : (
                    <tr className='no-item'>
                      <td colSpan={4}>{TEXT.NOT_FOUND}</td>
                    </tr>
                )}
                </>
              </tbody>
            </Table>

            <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
              pageClicked={(page: number) => { 
                  getDepartment(page);
                }
              }
              />

            <Dialog 
              show={showConfirm} 
              setShow={handleCloseConfirm}
              setEvent={() => { 
                  deleteDepartment(item.department_id)
                }
              }
            />

            <Loader show={showLoading}/>
          </Card.Body>
        </Card>

        {/* ADD MODAL*/}
        <Formik
            initialValues={{ department_name: ''}}
            validationSchema={validationSchema}
            onSubmit={createDepartment}
            >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (

            <Form id="departmentCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title>Add New Department</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="mb-3">
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label className='text-muted'>Department Name</Form.Label>
                      <Form.Control 
                        size={'sm'} 
                        type='text' 
                        placeholder='Department name' 
                        name="department_name"
                        value={values.department_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.department_name && !!errors.department_name || !!validation}
                        autoFocus />
                        {
                        validation != '' ?
                          (Object.keys(validation).map((message : any, index) => (
                            <Form.Control.Feedback type="invalid" key={index}>
                              {validation[message][index]}
                            </Form.Control.Feedback>
                          ))
                          ) : (
                          <Form.Control.Feedback type="invalid">
                            {errors.department_name}
                          </Form.Control.Feedback>
                          )
                        }
                    </Form.Group>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button 
                    type="submit"
                    disabled={isSubmitting}
                    form="departmentCreate" 
                    variant='primary text-white'
                    size={'sm'}
                    className='mx-2'
                    >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
            initialValues={item}
            validationSchema={validationSchema}
            onSubmit={updateDepartment}
            enableReinitialize
            >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (

            <Form id="departmentEdit" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Department</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="mb-3">
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label className='text-muted'>Department Name</Form.Label>
                      <Form.Control 
                        size={'sm'} 
                        type='text' 
                        placeholder='Department name' 
                        name="department_name"
                        value={values.department_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.department_name && !!errors.department_name || !!validation}
                        autoFocus />
                        {
                        validation != '' ?
                          (Object.keys(validation).map((message : any, index) => (
                            <Form.Control.Feedback type="invalid" key={index}>
                              {validation[message][index]}
                            </Form.Control.Feedback>
                          ))
                          ) : (
                          <Form.Control.Feedback type="invalid">
                            {errors.department_name}
                          </Form.Control.Feedback>
                          )
                        }
                    </Form.Group>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseEdit}>
                    Cancel
                  </Button>
                  <Button 
                    type="submit"
                    disabled={isSubmitting}
                    form="departmentEdit" 
                    variant='primary text-white'
                    size={'sm'}
                    className='mx-2'
                    >
                    Update
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}
