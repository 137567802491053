import React, { useEffect } from 'react'
import AttendanceList from './Components/AttendanceList/SecurityAgency'
import AttendanceList2 from './Components/AttendanceList/Bpo'
import AttendanceList3 from './Components/AttendanceList/Construction'
import AttendanceList4 from './Components/AttendanceList/Retail'
import AttendanceList5 from './Components/AttendanceList/Architecture'
import AttendanceList6 from './Components/AttendanceList/Medicine'
import Attendance from './Components/Attendance/SecurityAgency'
import Attendance2 from './Components/Attendance/Bpo'
import Attendance3 from './Components/Attendance/Construction'
import Attendance4 from './Components/Attendance/Retail'
import Attendance5 from './Components/Attendance/Architecture'
import Attendance6 from './Components/Attendance/Architecture'
import Requests from './Components/Requests/Custom'
import Requests2 from './Components/Requests/Retail'
import NoticeBoard from './Components/NoticeBoard'
import SpecialEvents from './Components/SpecialEvents'
import Team from './Components/Team/Bpo'
import Team2 from './Components/Team/SecurityAgency'
import Team3 from './Components/Team/Construction'
import Team4 from './Components/Team/Retail'
import Team5 from './Components/Team/Architecture'
import CardBadge from 'components/CardBadge'
import { Grid, Box } from '@mui/material'
import ClockButton from './Components/ClockButton/SecurityAgency'
import ClockButton2 from './Components/ClockButton/Construction'
import ClockButton3 from './Components/ClockButton/Bpo'
import ClockButton4 from './Components/ClockButton/Retail'
import ClockButton5 from './Components/ClockButton/Architecture'
import ClockButton6 from './Components/ClockButton/Medicine'
import Alert from 'components/Alert'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  ERROR_MESSAGE
} from 'utils/globalConstant'
import { IStatistic } from './types'
import { useSelector,  useDispatch} from 'react-redux'
import type { RootState } from 'redux/appStore'
import { addStatistics } from 'redux/Dashboard/Statistics/StatisticsSlice'

export default function Index() {
  const isRole = JSON.parse(localStorage.getItem('role') as string);
  const clientProfile = localStorage.getItem('clientProfile');

  const statistic = useSelector((state: RootState) => state.statistics)
  const dispatch = useDispatch()

  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)

  const getStatistics = async () => {
    try {
      const response = await axios.get<IAPI_Response<IStatistic>>(API_ENDPOINT.GET_STATISTICS)
      const result = await response
      if (result?.data?.results) {
        dispatch(addStatistics(result?.data?.results))
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getStatistics()
    })()
  }, [])

  return (
    <Box sx={{ px: '15px' }}>
      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />
      <Grid container direction='column' spacing={1}>
        <Grid item container justifyContent='space-between' spacing={1}>
          <Grid item>
            <h5 className='fw-bold'>Home</h5>
          </Grid>
          <Grid item>
            { isRole != 'Admin' && isRole != 'Super Admin' ? (
                clientProfile == "security_agency" ? (<ClockButton/>):
                    clientProfile == "bpo" ? (<ClockButton3/>) :
                        clientProfile == "construction" ? (<ClockButton2/>) :
                            clientProfile == "retail" ? (<ClockButton4/>) :
                                clientProfile == "standard" ? (<ClockButton4/>) :
                                    clientProfile == "architecture" ? (<ClockButton5/>) :
                                        clientProfile == "medicine" ? (<ClockButton6/>) :"") : null }
          </Grid>
        </Grid>
        {isRole == 'Admin' || isRole == "Super Admin" || isRole == "Supervisor" ? (
          <Grid item container spacing={1}>
            <Grid item xs={12} md={3}>
              <CardBadge
                count={statistic.employee}
                label='Total Employee'
                variant='primary'
                subLabel=''
                />
            </Grid>
            <Grid item xs={12} md={3}>
              <CardBadge
                count={statistic.department}
                label='Departments'
                variant='info'
                subLabel=''
                />
            </Grid>
            <Grid item xs={12} md={3}>
              <CardBadge 
                count={statistic.present} 
                label='Present' 
                variant='success' 
                subLabel=''
                />
            </Grid>
            <Grid item xs={12} md={3}>
              <CardBadge 
                count={statistic.absent} 
                label='Absent' 
                subLabel=''
                />
            </Grid>
          </Grid>
          ) : (<Grid item container></Grid>)
        }

        <Grid item container spacing={1}>
          <Grid item xs={12} md={3}>
            <NoticeBoard />
          </Grid>
          <Grid item xs={12} md={9}>
            { isRole != 'Admin' && isRole !='Super Admin' ? (clientProfile == "security_agency" ? <Attendance/>:
                    clientProfile == "bpo" ? <Attendance2/> :
                        clientProfile == "construction" ? <Attendance3/> :
                            clientProfile == "retail" ? <Attendance4/> :
                                clientProfile == "standard" ? <Attendance4/> :
                                    clientProfile == "architecture" ? <Attendance5/> :
                                        clientProfile == "medicine" ? <Attendance6/> :"") :
                clientProfile == "security_agency" ? (<AttendanceList/>):
                    clientProfile == "bpo" ? (<AttendanceList2/>) :
                        clientProfile == "construction" ? (<AttendanceList3/>) :
                            clientProfile == "retail" ? (<AttendanceList4/>) :
                                clientProfile == "standard" ? (<AttendanceList4/>) :
                                    clientProfile == "architecture" ? (<AttendanceList5/>) :
                                        clientProfile == "medicine" ? (<AttendanceList6/>) : ""
            }
          </Grid>
          <Grid item xs={12} md={3}>
            <SpecialEvents />
          </Grid>
          <Grid item xs={12} md={9}>
            { isRole != 'Admin' && isRole != "Super Admin" ? (clientProfile == "security_agency" ? <Team2/>:
                clientProfile == "bpo" ? <Team/> :
                    clientProfile == "construction" ? <Team3/> :
                        clientProfile == "retail" ? <Team4/> :
                            clientProfile == "standard" ? <Team4/> :
                                clientProfile == "architecture" ? <Team5/> :
                                    clientProfile == "medicine" ? <Team4/> : "") : (clientProfile == "retail" ? <Requests2/> : <Requests/>) }
          </Grid>
          {
            isRole == "Supervisor" || isRole == "Employee" ?
              <>
                <Grid item xs={12} md={3}>
                  {/*<SpecialEvents />*/}
                </Grid>
                <Grid item xs={12} md={9}>
                  {
                    clientProfile == "retail" || clientProfile == "standard" ? <Requests2/> : <Requests/>
                  }
                </Grid>
              </>
              :
              ""
          }

        </Grid>
      </Grid>
    </Box>
  )
}
