import React from 'react'

const Attached = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 25.811 26.805'
      >
      <g id='Attached' transform='translate(1 1)'>
        <path
          id='Icon_feather-paperclip'
          data-name='Icon feather-paperclip'
          d='M26.4,13.711,15.337,24.771A7.225,7.225,0,0,1,5.119,14.553L16.179,3.493A4.817,4.817,0,0,1,22.992,10.3L11.919,21.365a2.408,2.408,0,1,1-3.406-3.406L18.731,7.753'
          transform='translate(-3.002 -2.082)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Icon_feather-paperclip-2'
          data-name='Icon feather-paperclip'
          d='M22.992,10.3,11.919,21.365a2.408,2.408,0,1,1-3.406-3.406L18.731,7.753'
          transform='translate(-3.002 -2.082)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  )
}

export default Attached
