import React, { useEffect, useState } from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Table
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Formik } from 'formik';
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
    API_ENDPOINT,
    SUCCESS_MESSAGE,
    ERROR_MESSAGE
} from 'utils/globalConstant'
import { validationSchema } from './validationSchema'
import {IWorkSite, IList, IItem, IShift, ISubWorksite} from './types'
import moment from "moment"
import {add} from "ramda";

export default function Index() {
    const [currentPage, setCurrentPage] = React.useState(0)
    const [lastPage, setLastPage] = React.useState(0)
    const [itemsPerPage, setPerPage] = React.useState(10)
    const [itemsCount, setItemCount] = React.useState(0)
    const [from, setFrom] = React.useState(0)

    const [validation, setValidation] = React.useState('')
    const [error, setError] = React.useState('')
    const [success, setSuccess] = React.useState('')
    const [showError, setShowError] = React.useState(false)
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [showLoading, setShowLoading] = React.useState(false)

    const [showCreate, setShowCreate] = React.useState(false)
    const handleCloseCreate = () => setShowCreate(false)
    const handleShowCreate = () => setShowCreate(true)

    const [showEdit, setShowEdit] = React.useState(false)
    const handleCloseEdit = () => setShowEdit(false)
    const handleShowEdit = () => setShowEdit(true)

    const [showConfirm, setShowConfirm] = React.useState(false)
    const handleCloseConfirm = () => setShowConfirm(false)
    const handleShowConfirm = (id: any) => {
        setShowConfirm(true)
        setItem({
            id: id,
            work_site_name: '',
            daily_rate: '',
            semi_monthly_rate: '',
            isDailyRate: 0,
            overtime_rate: '',
            night_diff: '',
            five_days_incentive: '',
            sss: '',
            pagibig: '',
            philhealth: '',
            shifts: [],
            subworksites: [],
            status: 0
        })
    }

    const [lists, setData] = useState<IList[]>([])
    const [item, setItem] = useState<IItem>({
        id: 0,
        work_site_name: '',
        daily_rate: '',
        semi_monthly_rate: '',
        isDailyRate: 0,
        overtime_rate: '',
        night_diff: '',
        five_days_incentive: '',
        sss: '',
        pagibig: '',
        philhealth: '',
        shifts: [],
        subworksites: [],
        status: 0
    })
    const [dateFormat, setDateFormat] = useState('')
    const [rateName, setRateName] = useState('Rate')
    const [shifts, setShifts] = useState([{id: 0, time_from: "", time_to: "",isEightHourShift: 0, overtime_in: "", overtime_out: ""}])
    const [addedShifts, setAddedShifts] = useState([{id: 0, time_from: "", time_to: "",isEightHourShift: 0, overtime_in: "", overtime_out: ""}])
    const [selectedShift, setSelectedShift] = useState({id: 0, time_from: "", time_to: "", isEightHourShift: 0, overtime_in: "", overtime_out: ""})
    const [subWorksites, setSubWorksites] = useState([{id: 0, name: ""}])
    const [addedSubworksite, setAddedSubworksite] = useState([{id: 0, name: ""}])
    const [selectedSubWorksite, setSelectedSubWorksite] = useState({id: 0, name: ""})
    const [selectedUpdatedShift, setSelectedUpdatedShift] = useState({id: 0, time_from: "", time_to: "", isEightHourShift: 0, overtime_in: "", overtime_out: ""})
    const [selectedUpdatedSubWorksite, setSelectedUpdatedSubWorksite] = useState({id: 0, name: ""})

    const handleDeleteShift = (id: any) => {
        setAddedShifts((current) =>
            current.filter((shift) => shift.id !== id)
        );
    }

    const handleDeleteSubWorksite = (id: any) => {
        setAddedSubworksite((current) =>
            current.filter((subworksite) => subworksite.id !== id)
        );
    }


    const handleDeleteUpdateShift = (id: any) => {
        const currentItem = item
        const shifts = item.shifts.filter((shift) => shift.id !== id)
        currentItem.shifts = shifts
        setItem({...currentItem})
    }

    const handleDeleteUpdateSubWorksite = (id: any) => {
        const currentItem = item
        const subworksites = item.subworksites.filter((subworksite) => subworksite.id !== id)
        currentItem.subworksites = subworksites
        setItem({...currentItem})
    }

    const handleAddUpdateShift = () => {
        const currentItem = item
        const addedShifts = item.shifts
        addedShifts.push({id: 0, work_site_id: item.id, shift_id:selectedUpdatedShift.id, shifts:{id:selectedUpdatedShift.id, time_from: selectedUpdatedShift.time_from, time_to: selectedUpdatedShift.time_to, isEightHourShift: selectedUpdatedShift.isEightHourShift, overtime_in: selectedUpdatedShift.overtime_in, overtime_out: selectedUpdatedShift.overtime_out}});
        currentItem.shifts = addedShifts
        setItem({...currentItem})
    }

    const handleAddUpdateSubWorksite = () => {
        const currentItem = item
        const addedSubworksites = item.subworksites
        addedSubworksites.push({id: 0, work_site_id: item.id, sub_work_site_id: selectedUpdatedSubWorksite.id, subworksites:{id:selectedUpdatedSubWorksite.id, name: selectedUpdatedSubWorksite.name}});
        currentItem.subworksites = addedSubworksites
        setItem({...currentItem})
    }

    const handleAddSubWorksite = () => {
        setAddedSubworksite((current) =>
            current.filter((subworksite) => subworksite.id !== 0)
        );
        setAddedSubworksite(current => [...current, selectedSubWorksite])
    }

    const handleChangeSubWorksite = (e:any) => {
        subWorksites.map(subworksite => {
            if(subworksite.id == e.target.value){
                setSelectedSubWorksite({id: subworksite.id, name: subworksite.name})
            }
        })
    }

    const handleChangeUpdateSubWorksite = (e:any) => {
        subWorksites.map(subworksite => {
            if(subworksite.id == e.target.value){
                setSelectedUpdatedSubWorksite({id: subworksite.id, name: subworksite.name})
            }
        })
    }

    const handleAddShift = () => {
        setAddedShifts((current) =>
            current.filter((shift) => shift.id !== 0)
        );
        setAddedShifts(current => [...current, selectedShift])
    }

    const handleChangeShift = (e:any) => {
        shifts.map(shift => {
            if(shift.id == e.target.value){
                setSelectedShift({id: shift.id, time_from: shift.time_from, time_to: shift.time_to, isEightHourShift: shift.isEightHourShift, overtime_in: shift.overtime_in, overtime_out: shift.overtime_out})
            }
        })
    }

    const handleChangeUpdateShift = (e:any) => {
        shifts.map(shift => {
            if(shift.id == e.target.value){
                setSelectedUpdatedShift({id: shift.id, time_from: shift.time_from, time_to: shift.time_to, isEightHourShift: shift.isEightHourShift, overtime_in: shift.overtime_in, overtime_out: shift.overtime_out})
            }
        })
    }

    const handleChangeShiftType = async(e:any) => {
        const type = e.target.value;
        try {
            const response = await axios.get<IAPI_Response<IShift[]>>(API_ENDPOINT.SHOW_HOURS_SHIFT_BY_TYPE+"/"+type)
            const result = await response
            if (result?.data?.results?.length) {
                setShifts(result?.data?.results)
            }else{
                setShifts([])
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const handleChangeRate = (e:any) => {
        if(e.target.value == 1){
            setRateName("Daily Rate")
        }else{
            setRateName("Semi-monthly Rate")
        }
    }

    const handleChangeUpdatedRate = (e:any) => {
        if(e.target.value == 1){
            setRateName("Daily Rate")
        }else{
            setRateName("Semi-monthly Rate")
        }
        setItem({...item, isDailyRate: e.target.value})
    }

    const handleChangeStatus = (e:any) => {

        setItem({...item, status: e.target.value})
    }

    const handleChangeUpdatedDailyRate = (e:any) => {
        if(item.isDailyRate == 1){
            setItem({...item, daily_rate: e.target.value, semi_monthly_rate: "0"})
        }else{
            setItem({...item, daily_rate: "0", semi_monthly_rate: e.target.value})
        }
    }

    const getSubWorkSite = async () => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<ISubWorksite[]>>(API_ENDPOINT.GET_SUB_WORK_SITE)
            const result = await response
            if (result?.data?.results?.length) {
                setSubWorksites(result?.data?.results)
            }else{
                setSubWorksites([])
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const getWorkSite = async (page_number: null | number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IWorkSite>>(API_ENDPOINT.GET_WORK_SITE, {
                params: {
                    page: page_number,
                },
            })
            const result = await response
            if (result?.data?.results?.data?.length) {
                setData(result?.data?.results?.data)
                setCurrentPage(result?.data?.results?.current_page)
                setLastPage(result?.data?.results?.last_page)
                setPerPage(result?.data?.results?.per_page)
                setItemCount(result?.data?.results?.total)
                setFrom(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const showWorkSite = async (id: null | number) => {

        try {
            const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_WORK_SITE +'/'+ id)
            const result = await response
            if (result?.data?.results) {
                setItem(result?.data?.results)
            }
            handleShowEdit()
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const postWorkSite = async (value: any, {resetForm} : any) => {

        if(addedShifts.length > 0){
            if(addedShifts[0].id == 0){
                value.shifts = [];
            }else{
                value.shifts = addedShifts;
            }
        }else{
            value.shifts = [];
        }

        if(addedSubworksite.length > 0){
            if(addedSubworksite[0].id == 0){
                value.subworksites = [];
            }else{
                value.subworksites = addedSubworksite;
            }
        }else{
            value.subworksites = [];
        }


        await axios.post(API_ENDPOINT.POST_WORK_SITE, value).then(response => {
            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.BRANCH.CREATED)
                getWorkSite(null);
            }
            resetForm({})
            handleCloseCreate();
        }).catch(error => {
            setValidation(error.response.data.message)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
            setValidation('')
        }, 3000);
    }

    const updateWorkSite = async (value: any, {resetForm} : any) => {
        await axios.put(API_ENDPOINT.PUT_WORK_SITE +'/'+ value.id, value).then(response => {
            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.BRANCH.UPDATED)
                getWorkSite(null);
            }
            resetForm({})
            handleCloseEdit();
        }).catch(error => {
            setValidation(error.response.data.message)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
            setValidation('')
        }, 3000);
    }

    const deleteWorkSite = async (id: null | number) => {
        try {
            const response = await axios.delete(API_ENDPOINT.DELETE_WORK_SITE +'/'+ id)
            const result = await response
            if (result?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.BRANCH.DELETED)
                getWorkSite(null);
            }
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }
        handleCloseConfirm()
        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }


    useEffect(() => {
        ;(async () => {
            getSubWorkSite()
            getWorkSite(null)
            setDateFormat(localStorage.getItem('date_format') ?? "")
        })()
    }, [])

    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Work Site</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none' href='/home'>
                        Setup
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        className=' text-decoration-none'
                        href='/employeeManagement/employee-list'
                    >
                        Employee
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Work Site</Breadcrumb.Item>
                </Breadcrumb>

                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <Card className='border-0  p-0 bordered-card'>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <span className='fw-bold'>Work Site List</span>
                            </Col>
                            <Col sm={6} className='d-flex justify-content-end hidden'>
                                <InputGroup size='sm' className={'w-25'}>
                                    <Form.Control
                                        aria-label='search'
                                        aria-describedby='inputGroup-sizing-sm'
                                        placeholder='Search...'
                                    />
                                    <InputGroup.Text className='bg-transparent search-input '>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>
                                </InputGroup>
                                <Button
                                    onClick={handleShowCreate}
                                    className=' add-new-btn rounded mx-2 text-white'
                                    variant='warning'
                                >
                                    <EditIcon/>
                                    &nbsp;Add Work Site
                                </Button>
                            </Col>
                        </Row>

                        <Table responsive className='bordered-tabs-top mt-3'>
                            <thead>
                            <tr>
                                <th >No.</th>
                                <th >Work Site Name</th>
                                <th className="text-center" style={{ width: '10%' }}>Status</th>
                                <th className={'text-center'}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <>
                                {lists.length > 0 ? (
                                    lists.map((item, index) =>
                                        <tr className='mb-2' key={index}>
                                            <td>{from + index}</td>
                                            <td className='fw-bold'>{item.work_site_name}</td>
                                            {/*<td className="text-center">{item.isDailyRate == 0 ? item.semi_monthly_rate: "-"}</td>*/}
                                            {/*<td className="text-center">{item.isDailyRate == 1 ? item.daily_rate: "-"}</td>*/}
                                            {/*<td className="text-center">{item.overtime_rate}</td>*/}
                                            {/*<td className="text-center">{item.night_diff}</td>*/}
                                            {/*<td className="text-center">{item.five_days_incentive}</td>*/}
                                            <td className="text-center">
                                                <div className='d-grid'>
                                                    {item.status == 1 ? (
                                                        <Button
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-success'
                                                            size='sm'
                                                        > Active
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-secondary'
                                                            size='sm'
                                                        > Inactive
                                                        </Button>
                                                    )}
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                <Button
                                                    onClick={() => showWorkSite(item.id)}
                                                    className=' p-1 pt-0 pb-0'
                                                    style={{ fontSize: '12px', color: 'white' }}
                                                    variant=' btn-transparent'
                                                    size='sm'
                                                >
                                                    <Write/>
                                                </Button>
                                                <Button
                                                    onClick={() => handleShowConfirm(item.id)}
                                                    className=' p-1 pt-0 pb-0'
                                                    style={{ fontSize: '12px', color: 'white' }}
                                                    variant=' btn-transparent'
                                                    size='sm'
                                                >
                                                    <Delete/>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                ) : (
                                    <tr className='no-item'>
                                        <td colSpan={4}>No matching records found</td>
                                    </tr>
                                )}
                            </>
                            </tbody>
                        </Table>

                        <Pagination
                            itemsCount={itemsCount}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            lastPage={lastPage}
                            setCurrentPage={setCurrentPage}
                            alwaysShown={false}
                            pageClicked={(page: number) => {
                                getWorkSite(page);
                            }
                            }
                        />

                        <Dialog
                            show={showConfirm}
                            setShow={handleCloseConfirm}
                            setEvent={() => {
                                deleteWorkSite(item.id)
                            }
                            }
                        />

                        <Loader show={showLoading}/>
                    </Card.Body>
                </Card>

                {/* ADD MODAL*/}
                <Formik
                    initialValues={{ work_site_name: '', rate_type: '1',rate_value: "0", overtime_rate:"0", night_diff: "0", five_days_incentive: "0", sss: "0", philhealth: "0", pagibig: "0"}}
                    validationSchema={validationSchema}
                    onSubmit={postWorkSite}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (

                        <Form id="workSiteCreate" noValidate onSubmit={handleSubmit}>
                            <Modal className='modal' centered show={showCreate} onHide={handleCloseCreate}>
                                <Modal.Header closeButton>
                                    <Modal.Title className='fw-bold'>Add New Work Site</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Form.Group as={Col} md="6" className="mb-3" controlId='exampleForm.ControlInput1'>
                                            <Form.Label className='text-muted required'>Work Site Name</Form.Label>
                                            <Form.Control
                                                size={'sm'}
                                                type='text'
                                                placeholder='Work site name'
                                                name="work_site_name"
                                                value={values.work_site_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={(touched.work_site_name && !!errors.work_site_name) || !!validation}
                                                autoComplete='off'
                                                autoFocus
                                            />
                                            {
                                                validation != '' ?
                                                    (Object.keys(validation).map((message : any, index) => (
                                                            <Form.Control.Feedback type="invalid" key={index}>
                                                                {validation[message][index]}
                                                            </Form.Control.Feedback>
                                                        ))
                                                    ) : (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.work_site_name}
                                                        </Form.Control.Feedback>
                                                    )
                                            }
                                        </Form.Group>
                                        {/*<Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*    <Form.Label className='text-muted required'>Select Rate</Form.Label>*/}
                                        {/*    <Form.Select*/}
                                        {/*        size={'sm'}*/}
                                        {/*        name="rate_type"*/}
                                        {/*        onChange={event => {*/}
                                        {/*            handleChangeRate(event);*/}
                                        {/*            handleChange(event);*/}
                                        {/*        }*/}
                                        {/*        }*/}
                                        {/*        value={values.rate_type}*/}
                                        {/*        onBlur={handleBlur}*/}
                                        {/*        isInvalid={(touched.rate_type && !!errors.rate_type) || !!validation}*/}
                                        {/*    >*/}
                                        {/*        <option value="" disabled selected>Select Rate</option>*/}
                                        {/*        <option value="1">Daily Rate</option>*/}
                                        {/*        <option value="2">Semi-monthly Rate</option>*/}
                                        {/*    </Form.Select>*/}
                                        {/*    {*/}
                                        {/*        validation != '' ?*/}
                                        {/*            (Object.keys(validation).map((message : any, index) => (*/}
                                        {/*                    <Form.Control.Feedback type="invalid" key={index}>*/}
                                        {/*                        {validation[message][index]}*/}
                                        {/*                    </Form.Control.Feedback>*/}
                                        {/*                ))*/}
                                        {/*            ) : (*/}
                                        {/*                <Form.Control.Feedback type="invalid">*/}
                                        {/*                    {errors.rate_type}*/}
                                        {/*                </Form.Control.Feedback>*/}
                                        {/*            )*/}
                                        {/*    }*/}
                                        {/*</Form.Group>*/}
                                    </Row>
                                    {/*<Row>*/}
                                    {/*    <p className="text-center"><strong>Rates</strong></p>*/}
                                    {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                    {/*        <Form.Label className='text-muted required'>{rateName}</Form.Label>*/}
                                    {/*        <Form.Control*/}
                                    {/*            className="text-right"*/}
                                    {/*            size={'sm'}*/}
                                    {/*            type='text'*/}
                                    {/*            placeholder='0'*/}
                                    {/*            name="rate_value"*/}
                                    {/*            value={values.rate_value}*/}
                                    {/*            onChange={handleChange}*/}
                                    {/*            onBlur={handleBlur}*/}
                                    {/*            isInvalid={(touched.rate_value && !!errors.rate_value) || !!validation}*/}
                                    {/*            autoComplete='off'*/}
                                    {/*            autoFocus*/}
                                    {/*        />*/}
                                    {/*        {*/}
                                    {/*            validation != '' ?*/}
                                    {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                    {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                    {/*                            {validation[message][index]}*/}
                                    {/*                        </Form.Control.Feedback>*/}
                                    {/*                    ))*/}
                                    {/*                ) : (*/}
                                    {/*                    <Form.Control.Feedback type="invalid">*/}
                                    {/*                        {errors.rate_value}*/}
                                    {/*                    </Form.Control.Feedback>*/}
                                    {/*                )*/}
                                    {/*        }*/}
                                    {/*    </Form.Group>*/}
                                    {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                    {/*        <Form.Label className='text-muted required'>Overtime Rate</Form.Label>*/}
                                    {/*        <Form.Control*/}
                                    {/*            className="text-right"*/}
                                    {/*            size={'sm'}*/}
                                    {/*            type='text'*/}
                                    {/*            placeholder='0'*/}
                                    {/*            name="overtime_rate"*/}
                                    {/*            value={values.overtime_rate}*/}
                                    {/*            onChange={handleChange}*/}
                                    {/*            onBlur={handleBlur}*/}
                                    {/*            isInvalid={(touched.overtime_rate && !!errors.overtime_rate) || !!validation}*/}
                                    {/*            autoComplete='off'*/}
                                    {/*            autoFocus*/}
                                    {/*        />*/}
                                    {/*        {*/}
                                    {/*            validation != '' ?*/}
                                    {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                    {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                    {/*                            {validation[message][index]}*/}
                                    {/*                        </Form.Control.Feedback>*/}
                                    {/*                    ))*/}
                                    {/*                ) : (*/}
                                    {/*                    <Form.Control.Feedback type="invalid">*/}
                                    {/*                        {errors.overtime_rate}*/}
                                    {/*                    </Form.Control.Feedback>*/}
                                    {/*                )*/}
                                    {/*        }*/}
                                    {/*    </Form.Group>*/}
                                    {/*</Row>*/}
                                    {/*<Row>*/}

                                    {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                    {/*        <Form.Label className='text-muted required'>Night Diff. (per month)</Form.Label>*/}
                                    {/*        <Form.Control*/}
                                    {/*            className="text-right"*/}
                                    {/*            size={'sm'}*/}
                                    {/*            type='text'*/}
                                    {/*            placeholder='0'*/}
                                    {/*            name="night_diff"*/}
                                    {/*            value={values.night_diff}*/}
                                    {/*            onChange={handleChange}*/}
                                    {/*            onBlur={handleBlur}*/}
                                    {/*            isInvalid={(touched.night_diff && !!errors.night_diff) || !!validation}*/}
                                    {/*            autoComplete='off'*/}
                                    {/*            autoFocus*/}
                                    {/*        />*/}
                                    {/*        {*/}
                                    {/*            validation != '' ?*/}
                                    {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                    {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                    {/*                            {validation[message][index]}*/}
                                    {/*                        </Form.Control.Feedback>*/}
                                    {/*                    ))*/}
                                    {/*                ) : (*/}
                                    {/*                    <Form.Control.Feedback type="invalid">*/}
                                    {/*                        {errors.night_diff}*/}
                                    {/*                    </Form.Control.Feedback>*/}
                                    {/*                )*/}
                                    {/*        }*/}
                                    {/*    </Form.Group>*/}
                                    {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                    {/*        <Form.Label className='text-muted required'>5 Days Incentive</Form.Label>*/}
                                    {/*        <Form.Control*/}
                                    {/*            className="text-right"*/}
                                    {/*            size={'sm'}*/}
                                    {/*            type='text'*/}
                                    {/*            placeholder='0'*/}
                                    {/*            name="five_days_incentive"*/}
                                    {/*            value={values.five_days_incentive}*/}
                                    {/*            onChange={handleChange}*/}
                                    {/*            onBlur={handleBlur}*/}
                                    {/*            isInvalid={(touched.five_days_incentive && !!errors.five_days_incentive) || !!validation}*/}
                                    {/*            autoComplete='off'*/}
                                    {/*            autoFocus*/}
                                    {/*        />*/}
                                    {/*        {*/}
                                    {/*            validation != '' ?*/}
                                    {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                    {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                    {/*                            {validation[message][index]}*/}
                                    {/*                        </Form.Control.Feedback>*/}
                                    {/*                    ))*/}
                                    {/*                ) : (*/}
                                    {/*                    <Form.Control.Feedback type="invalid">*/}
                                    {/*                        {errors.five_days_incentive}*/}
                                    {/*                    </Form.Control.Feedback>*/}
                                    {/*                )*/}
                                    {/*        }*/}
                                    {/*    </Form.Group>*/}
                                    {/*</Row>*/}
                                    {/*<br/>*/}
                                    {/*<Row>*/}
                                    {/*    <p className="text-center"><strong>Deductions</strong></p>*/}
                                    {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                    {/*        <Form.Label className='text-muted required'>PhilHealth</Form.Label>*/}
                                    {/*        <Form.Control*/}
                                    {/*            className="text-right"*/}
                                    {/*            size={'sm'}*/}
                                    {/*            type='text'*/}
                                    {/*            placeholder='0'*/}
                                    {/*            name="philhealth"*/}
                                    {/*            value={values.philhealth}*/}
                                    {/*            onChange={handleChange}*/}
                                    {/*            onBlur={handleBlur}*/}
                                    {/*            isInvalid={(touched.philhealth && !!errors.philhealth) || !!validation}*/}
                                    {/*            autoComplete='off'*/}
                                    {/*        />*/}
                                    {/*        {*/}
                                    {/*            validation != '' ?*/}
                                    {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                    {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                    {/*                            {validation[message][index]}*/}
                                    {/*                        </Form.Control.Feedback>*/}
                                    {/*                    ))*/}
                                    {/*                ) : (*/}
                                    {/*                    <Form.Control.Feedback type="invalid">*/}
                                    {/*                        {errors.philhealth}*/}
                                    {/*                    </Form.Control.Feedback>*/}
                                    {/*                )*/}
                                    {/*        }*/}
                                    {/*    </Form.Group>*/}
                                    {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                    {/*        <Form.Label className='text-muted required'>Pagibig</Form.Label>*/}
                                    {/*        <Form.Control*/}
                                    {/*            className="text-right"*/}
                                    {/*            size={'sm'}*/}
                                    {/*            type='text'*/}
                                    {/*            placeholder='0'*/}
                                    {/*            name="pagibig"*/}
                                    {/*            value={values.pagibig}*/}
                                    {/*            onChange={handleChange}*/}
                                    {/*            onBlur={handleBlur}*/}
                                    {/*            isInvalid={(touched.pagibig && !!errors.pagibig) || !!validation}*/}
                                    {/*            autoComplete='off'*/}
                                    {/*        />*/}
                                    {/*        {*/}
                                    {/*            validation != '' ?*/}
                                    {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                    {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                    {/*                            {validation[message][index]}*/}
                                    {/*                        </Form.Control.Feedback>*/}
                                    {/*                    ))*/}
                                    {/*                ) : (*/}
                                    {/*                    <Form.Control.Feedback type="invalid">*/}
                                    {/*                        {errors.pagibig}*/}
                                    {/*                    </Form.Control.Feedback>*/}
                                    {/*                )*/}
                                    {/*        }*/}
                                    {/*    </Form.Group>*/}
                                    {/*</Row>*/}
                                    {/*<Row>*/}
                                    {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                    {/*        <Form.Label className='text-muted required'>SSS</Form.Label>*/}
                                    {/*        <Form.Control*/}
                                    {/*            className="text-right"*/}
                                    {/*            size={'sm'}*/}
                                    {/*            type='text'*/}
                                    {/*            placeholder='0'*/}
                                    {/*            name="sss"*/}
                                    {/*            value={values.sss}*/}
                                    {/*            onChange={handleChange}*/}
                                    {/*            onBlur={handleBlur}*/}
                                    {/*            isInvalid={(touched.sss && !!errors.sss) || !!validation}*/}
                                    {/*            autoComplete='off'*/}
                                    {/*            autoFocus*/}
                                    {/*        />*/}
                                    {/*        {*/}
                                    {/*            validation != '' ?*/}
                                    {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                    {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                    {/*                            {validation[message][index]}*/}
                                    {/*                        </Form.Control.Feedback>*/}
                                    {/*                    ))*/}
                                    {/*                ) : (*/}
                                    {/*                    <Form.Control.Feedback type="invalid">*/}
                                    {/*                        {errors.sss}*/}
                                    {/*                    </Form.Control.Feedback>*/}
                                    {/*                )*/}
                                    {/*        }*/}
                                    {/*    </Form.Group>*/}
                                    {/*</Row>*/}
                                    {/*<Row>*/}
                                    {/*    <p className="text-center"><strong>Shifts</strong></p>*/}
                                    {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                    {/*        <Form.Label className='text-muted required'>Shift Types</Form.Label>*/}
                                    {/*        <Form.Select*/}
                                    {/*            size={'sm'}*/}
                                    {/*            name="shift_type"*/}
                                    {/*            onChange={handleChangeShiftType}*/}
                                    {/*        >*/}
                                    {/*            <option value="" disabled selected>Select Shift Type</option>*/}
                                    {/*            <option value="1">8 Hours Shift</option>*/}
                                    {/*            <option value="2">8 Hours & 4 Hours OT Shift</option>*/}
                                    {/*            <option value="0">12 Hours Shift</option>*/}
                                    {/*        </Form.Select>*/}

                                    {/*    </Form.Group>*/}
                                    {/*    <Form.Group as={Col} md="5" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                    {/*        <Form.Label className='text-muted required'>Shift</Form.Label>*/}
                                    {/*        <Form.Select*/}
                                    {/*            size={'sm'}*/}
                                    {/*            name="shift"*/}
                                    {/*            onChange={handleChangeShift}*/}
                                    {/*        >*/}
                                    {/*            <option value="" disabled selected>Select Shift</option>*/}
                                    {/*            {shifts.length > 0 ?*/}
                                    {/*                (<>*/}
                                    {/*                    {shifts.map((shift, index) => (*/}
                                    {/*                        <option value={shift.id} key={index}>{moment(new Date("2023-01-01 " + shift.time_from ?? "")).format("h:mm A")} - {moment(new Date("2023-01-01 " + (shift.isEightHourShift == 2 ? shift.overtime_out: shift.time_to) ?? "")).format("h:mm A")}</option>*/}
                                    {/*                    ))}*/}
                                    {/*                </>)*/}
                                    {/*                : ""}*/}

                                    {/*        </Form.Select>*/}
                                    {/*    </Form.Group>*/}
                                    {/*    <Form.Group as={Col} md="1" className='mb-3'>*/}
                                    {/*        <Form.Label className='text-muted' style={{color:'white'}}>&nbsp;&nbsp;</Form.Label>*/}
                                    {/*        <Button*/}
                                    {/*            onClick={handleAddShift}*/}
                                    {/*            className='p-1 pt-0 pb-0'*/}
                                    {/*            style={{ fontSize: '15px', color: 'white' }}*/}
                                    {/*            variant=' btn-transparent'*/}
                                    {/*            size='sm'*/}
                                    {/*            title={'Assign Employee'}*/}
                                    {/*        >*/}
                                    {/*            <svg*/}
                                    {/*                xmlns='http://www.w3.org/2000/svg'*/}
                                    {/*                width='16.143'*/}
                                    {/*                height='16.143'*/}
                                    {/*                viewBox='0 0 16.143 16.143'*/}
                                    {/*            >*/}
                                    {/*                <g id='New' transform='translate(0.5 0.5)'>*/}
                                    {/*                    <g*/}
                                    {/*                        id='Icon_feather-plus-circle'*/}
                                    {/*                        data-name='Icon feather-plus-circle'*/}
                                    {/*                        transform='translate(-3 -3)'*/}
                                    {/*                    >*/}
                                    {/*                        <path*/}
                                    {/*                            id='Path_46'*/}
                                    {/*                            data-name='Path 46'*/}
                                    {/*                            d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'*/}
                                    {/*                            fill='none'*/}
                                    {/*                            stroke='#a7a9ac'*/}
                                    {/*                            strokeLinecap='round'*/}
                                    {/*                            strokeLinejoin='round'*/}
                                    {/*                            strokeWidth='1'*/}
                                    {/*                        />*/}
                                    {/*                        <path*/}
                                    {/*                            id='Path_47'*/}
                                    {/*                            data-name='Path 47'*/}
                                    {/*                            d='M18,12v6.057'*/}
                                    {/*                            transform='translate(-7.429 -4.457)'*/}
                                    {/*                            fill='none'*/}
                                    {/*                            stroke='#f26930'*/}
                                    {/*                            strokeLinecap='round'*/}
                                    {/*                            strokeLinejoin='round'*/}
                                    {/*                            strokeWidth='1'*/}
                                    {/*                        />*/}
                                    {/*                        <path*/}
                                    {/*                            id='Path_48'*/}
                                    {/*                            data-name='Path 48'*/}
                                    {/*                            d='M12,18h6.057'*/}
                                    {/*                            transform='translate(-4.457 -7.429)'*/}
                                    {/*                            fill='none'*/}
                                    {/*                            stroke='#f26930'*/}
                                    {/*                            strokeLinecap='round'*/}
                                    {/*                            strokeLinejoin='round'*/}
                                    {/*                            strokeWidth='1'*/}
                                    {/*                        />*/}
                                    {/*                    </g>*/}
                                    {/*                </g>*/}
                                    {/*            </svg>*/}
                                    {/*        </Button>*/}
                                    {/*    </Form.Group>*/}
                                    {/*</Row>*/}
                                    {/*<Row>*/}
                                    {/*    <Table responsive>*/}
                                    {/*        <thead>*/}
                                    {/*            <tr>*/}
                                    {/*                <th style={{width:'35%'}}>Shift</th>*/}
                                    {/*                <th style={{width:'35%'}}>Type</th>*/}
                                    {/*                <th>Action</th>*/}
                                    {/*            </tr>*/}
                                    {/*        </thead>*/}
                                    {/*        <tbody>*/}

                                    {/*            {*/}
                                    {/*                addedShifts.length > 0 && addedShifts[0].id != 0?*/}
                                    {/*                    addedShifts.map((shift,index) => (*/}
                                    {/*                        shift.id != 0 ?*/}
                                    {/*                        <tr key={index}>*/}
                                    {/*                            <td>{moment(new Date("2023-01-01 " + shift.time_from ?? "")).format("h:mm A")} - {moment(new Date("2023-01-01 " + (shift.isEightHourShift == 2 ? shift.overtime_out: shift.time_to) ?? "")).format("h:mm A")}</td>*/}
                                    {/*                            <td>{shift.isEightHourShift == 1 ? "8 Hours Shift": shift.isEightHourShift == 2 ? "8 Hours & 4 Hours OT" :"12 Hours Shift"}</td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <Button*/}
                                    {/*                                    onClick={() => handleDeleteShift(shift.id)}*/}
                                    {/*                                    className=' p-1 pt-0 pb-0'*/}
                                    {/*                                    style={{ fontSize: '12px', color: 'white' }}*/}
                                    {/*                                    variant=' btn-transparent'*/}
                                    {/*                                    size='sm'*/}
                                    {/*                                >*/}
                                    {/*                                    <Delete/>*/}
                                    {/*                                </Button>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr> : ""*/}
                                    {/*                    ))*/}
                                    {/*                    : <tr><td colSpan={3}>No shift yet.</td></tr>*/}
                                    {/*            }*/}

                                    {/*        </tbody>*/}
                                    {/*    </Table>*/}
                                    {/*</Row>*/}
                                    {/*<Row>*/}
                                    {/*    <p className="text-center"><strong>Sub-worksite</strong></p>*/}
                                    {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                    {/*        <Form.Label className='text-muted required'>Sub-worksites</Form.Label>*/}
                                    {/*        <Form.Select*/}
                                    {/*            size={'sm'}*/}
                                    {/*            name="shift_type"*/}
                                    {/*            onChange={handleChangeSubWorksite}*/}
                                    {/*        >*/}
                                    {/*            <option value="" disabled selected>Select Sub-worksite</option>*/}
                                    {/*            {*/}
                                    {/*                subWorksites.length > 0 ?*/}
                                    {/*                    subWorksites.map((subworksite,index) => (*/}
                                    {/*                        <option value={subworksite.id} key={index}>{subworksite.name}</option>*/}
                                    {/*                    ))*/}
                                    {/*                    : ""*/}
                                    {/*            }*/}
                                    {/*        </Form.Select>*/}

                                    {/*    </Form.Group>*/}
                                    {/*    <Form.Group as={Col} md="6" className='mb-3'>*/}
                                    {/*        <Form.Label className='text-muted' style={{color:'white'}}>&nbsp;&nbsp;</Form.Label>*/}
                                    {/*        <Button*/}
                                    {/*            onClick={handleAddSubWorksite}*/}
                                    {/*            className='p-0 pt-4 pb-0'*/}
                                    {/*            style={{ fontSize: '15px', color: 'white' }}*/}
                                    {/*            variant=' btn-transparent'*/}
                                    {/*            size='sm'*/}
                                    {/*            title={'Add sub-worksite'}*/}
                                    {/*        >*/}
                                    {/*            <svg*/}
                                    {/*                xmlns='http://www.w3.org/2000/svg'*/}
                                    {/*                width='16.143'*/}
                                    {/*                height='16.143'*/}
                                    {/*                viewBox='0 0 16.143 16.143'*/}
                                    {/*            >*/}
                                    {/*                <g id='New' transform='translate(0.5 0.5)'>*/}
                                    {/*                    <g*/}
                                    {/*                        id='Icon_feather-plus-circle'*/}
                                    {/*                        data-name='Icon feather-plus-circle'*/}
                                    {/*                        transform='translate(-3 -3)'*/}
                                    {/*                    >*/}
                                    {/*                        <path*/}
                                    {/*                            id='Path_46'*/}
                                    {/*                            data-name='Path 46'*/}
                                    {/*                            d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'*/}
                                    {/*                            fill='none'*/}
                                    {/*                            stroke='#a7a9ac'*/}
                                    {/*                            strokeLinecap='round'*/}
                                    {/*                            strokeLinejoin='round'*/}
                                    {/*                            strokeWidth='1'*/}
                                    {/*                        />*/}
                                    {/*                        <path*/}
                                    {/*                            id='Path_47'*/}
                                    {/*                            data-name='Path 47'*/}
                                    {/*                            d='M18,12v6.057'*/}
                                    {/*                            transform='translate(-7.429 -4.457)'*/}
                                    {/*                            fill='none'*/}
                                    {/*                            stroke='#f26930'*/}
                                    {/*                            strokeLinecap='round'*/}
                                    {/*                            strokeLinejoin='round'*/}
                                    {/*                            strokeWidth='1'*/}
                                    {/*                        />*/}
                                    {/*                        <path*/}
                                    {/*                            id='Path_48'*/}
                                    {/*                            data-name='Path 48'*/}
                                    {/*                            d='M12,18h6.057'*/}
                                    {/*                            transform='translate(-4.457 -7.429)'*/}
                                    {/*                            fill='none'*/}
                                    {/*                            stroke='#f26930'*/}
                                    {/*                            strokeLinecap='round'*/}
                                    {/*                            strokeLinejoin='round'*/}
                                    {/*                            strokeWidth='1'*/}
                                    {/*                        />*/}
                                    {/*                    </g>*/}
                                    {/*                </g>*/}
                                    {/*            </svg>*/}
                                    {/*        </Button>*/}
                                    {/*    </Form.Group>*/}
                                    {/*</Row>*/}
                                    {/*<Row>*/}
                                    {/*    <Table responsive>*/}
                                    {/*        <thead>*/}
                                    {/*        <tr>*/}
                                    {/*            <th style={{width:'70%'}}>Name</th>*/}
                                    {/*            <th>Action</th>*/}
                                    {/*        </tr>*/}
                                    {/*        </thead>*/}
                                    {/*        <tbody>*/}

                                    {/*        {*/}
                                    {/*            addedSubworksite.length > 0 && addedSubworksite[0].id != 0?*/}
                                    {/*                addedSubworksite.map((subworksite,index) => (*/}
                                    {/*                    subworksite.id != 0 ?*/}
                                    {/*                    <tr key={index}>*/}
                                    {/*                        <td>{subworksite.name}</td>*/}
                                    {/*                        <td>*/}
                                    {/*                            <Button*/}
                                    {/*                                onClick={() => handleDeleteSubWorksite(subworksite.id)}*/}
                                    {/*                                className=' p-1 pt-0 pb-0'*/}
                                    {/*                                style={{ fontSize: '12px', color: 'white' }}*/}
                                    {/*                                variant=' btn-transparent'*/}
                                    {/*                                size='sm'*/}
                                    {/*                            >*/}
                                    {/*                                <Delete/>*/}
                                    {/*                            </Button>*/}
                                    {/*                        </td>*/}
                                    {/*                    </tr> : ""*/}
                                    {/*                ))*/}
                                    {/*                : <tr><td colSpan={2}>No Sub-worksite yet.</td></tr>*/}
                                    {/*        }*/}

                                    {/*        </tbody>*/}
                                    {/*    </Table>*/}
                                    {/*</Row>*/}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCreate}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        form="workSiteCreate"
                                        variant='primary text-white'
                                        size={'sm'}
                                        className='mx-2'
                                    >
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                    )}
                </Formik>

                {/* EDIT MODAL*/}
                <Formik
                    initialValues={item}
                    validationSchema={validationSchema}
                    onSubmit={updateWorkSite}
                    enableReinitialize
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (

                        <Form id="workSiteUpdated" noValidate onSubmit={handleSubmit}>
                            <Modal className='modal' show={showEdit} centered onHide={handleCloseEdit}>
                                <Modal.Header closeButton>
                                    <Modal.Title className='fw-bold'>Edit Work Site</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Row>
                                            <Form.Group as={Col} md="6" className="mb-3" controlId='exampleForm.ControlInput1'>
                                                <Form.Label className='text-muted required'>Work Site Name</Form.Label>
                                                <Form.Control
                                                    size={'sm'}
                                                    type='text'
                                                    placeholder='Work site name'
                                                    name="work_site_name"
                                                    value={values.work_site_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.work_site_name && !!errors.work_site_name) || !!validation}
                                                    autoComplete='off'
                                                    autoFocus
                                                />
                                                {
                                                    validation != '' ?
                                                        (Object.keys(validation).map((message : any, index) => (
                                                                <Form.Control.Feedback type="invalid" key={index}>
                                                                    {validation[message][index]}
                                                                </Form.Control.Feedback>
                                                            ))
                                                        ) : (
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.work_site_name}
                                                            </Form.Control.Feedback>
                                                        )
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>
                                                <Form.Label className='text-muted required'>Status</Form.Label>
                                                <Form.Select
                                                    size={'sm'}
                                                    name="status"
                                                    onChange={event => {
                                                        handleChangeStatus(event);
                                                        handleChange(event);
                                                    }
                                                    }
                                                    value={item.status}
                                                    onBlur={handleBlur}
                                                    // isInvalid={(touched.isDailyRate && !!errors.isDailyRate) || !!validation}
                                                >
                                                    <option value="0">Inactive</option>
                                                    <option value="1">Active</option>
                                                </Form.Select>
                                                {
                                                    validation != '' ?
                                                        (Object.keys(validation).map((message : any, index) => (
                                                                <Form.Control.Feedback type="invalid" key={index}>
                                                                    {validation[message][index]}
                                                                </Form.Control.Feedback>
                                                            ))
                                                        ) : (
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.status}
                                                            </Form.Control.Feedback>
                                                        )
                                                }
                                            </Form.Group>
                                            {/*<Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                            {/*    <Form.Label className='text-muted required'>Select Rate</Form.Label>*/}
                                            {/*    <Form.Select*/}
                                            {/*        size={'sm'}*/}
                                            {/*        name="rate_type"*/}
                                            {/*        onChange={event => {*/}
                                            {/*            handleChangeUpdatedRate(event);*/}
                                            {/*            handleChange(event);*/}
                                            {/*        }*/}
                                            {/*        }*/}
                                            {/*        value={item.isDailyRate}*/}
                                            {/*        onBlur={handleBlur}*/}
                                            {/*        // isInvalid={(touched.isDailyRate && !!errors.isDailyRate) || !!validation}*/}
                                            {/*    >*/}
                                            {/*        <option value="" disabled selected>Select Rate</option>*/}
                                            {/*        <option value="1">Daily Rate</option>*/}
                                            {/*        <option value="0">Semi-monthly Rate</option>*/}
                                            {/*    </Form.Select>*/}
                                            {/*    {*/}
                                            {/*        validation != '' ?*/}
                                            {/*            (Object.keys(validation).map((message : any, index) => (*/}
                                            {/*                    <Form.Control.Feedback type="invalid" key={index}>*/}
                                            {/*                        {validation[message][index]}*/}
                                            {/*                    </Form.Control.Feedback>*/}
                                            {/*                ))*/}
                                            {/*            ) : (*/}
                                            {/*                <Form.Control.Feedback type="invalid">*/}
                                            {/*                    {errors.isDailyRate}*/}
                                            {/*                </Form.Control.Feedback>*/}
                                            {/*            )*/}
                                            {/*    }*/}
                                            {/*</Form.Group>*/}
                                        </Row>
                                        {/*<Row>*/}
                                        {/*    <p className="text-center"><strong>Rates</strong></p>*/}

                                        {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted required'>{values.isDailyRate == 0? "Semi-monthly Rate":"Daily Rate"}</Form.Label>*/}
                                        {/*        <Form.Control*/}
                                        {/*            className="text-right"*/}
                                        {/*            size={'sm'}*/}
                                        {/*            type='text'*/}
                                        {/*            placeholder='0'*/}
                                        {/*            name="rate_value"*/}
                                        {/*            value={item.isDailyRate == 0 ? item.semi_monthly_rate: item.daily_rate}*/}
                                        {/*            onChange={handleChangeUpdatedDailyRate}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            // isInvalid={((values.isDailyRate == 0 ? touched.semi_monthly_rate: touched.daily_rate) && (values.isDailyRate == 0 ? !!errors.semi_monthly_rate: !!errors.daily_rate)) || !!validation}*/}
                                        {/*            autoComplete='off'*/}
                                        {/*            autoFocus*/}
                                        {/*        />*/}
                                        {/*        {*/}
                                        {/*            validation != '' ?*/}
                                        {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                        {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                        {/*                            {validation[message][index]}*/}
                                        {/*                        </Form.Control.Feedback>*/}
                                        {/*                    ))*/}
                                        {/*                ) : (*/}
                                        {/*                    <Form.Control.Feedback type="invalid">*/}
                                        {/*                        /!*{errors.rate_value}*!/*/}
                                        {/*                    </Form.Control.Feedback>*/}
                                        {/*                )*/}
                                        {/*        }*/}
                                        {/*    </Form.Group>*/}
                                        {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted required'>Overtime Rate</Form.Label>*/}
                                        {/*        <Form.Control*/}
                                        {/*            className="text-right"*/}
                                        {/*            size={'sm'}*/}
                                        {/*            type='text'*/}
                                        {/*            placeholder='0'*/}
                                        {/*            name="overtime_rate"*/}
                                        {/*            value={values.overtime_rate}*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            isInvalid={(touched.overtime_rate && !!errors.overtime_rate) || !!validation}*/}
                                        {/*            autoComplete='off'*/}
                                        {/*            autoFocus*/}
                                        {/*        />*/}
                                        {/*        {*/}
                                        {/*            validation != '' ?*/}
                                        {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                        {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                        {/*                            {validation[message][index]}*/}
                                        {/*                        </Form.Control.Feedback>*/}
                                        {/*                    ))*/}
                                        {/*                ) : (*/}
                                        {/*                    <Form.Control.Feedback type="invalid">*/}
                                        {/*                        {errors.overtime_rate}*/}
                                        {/*                    </Form.Control.Feedback>*/}
                                        {/*                )*/}
                                        {/*        }*/}
                                        {/*    </Form.Group>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted required'>Night Diff. (per month)</Form.Label>*/}
                                        {/*        <Form.Control*/}
                                        {/*            className="text-right"*/}
                                        {/*            size={'sm'}*/}
                                        {/*            type='text'*/}
                                        {/*            placeholder='0'*/}
                                        {/*            name="night_diff"*/}
                                        {/*            value={values.night_diff}*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            isInvalid={(touched.night_diff && !!errors.night_diff) || !!validation}*/}
                                        {/*            autoComplete='off'*/}
                                        {/*            autoFocus*/}
                                        {/*        />*/}
                                        {/*        {*/}
                                        {/*            validation != '' ?*/}
                                        {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                        {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                        {/*                            {validation[message][index]}*/}
                                        {/*                        </Form.Control.Feedback>*/}
                                        {/*                    ))*/}
                                        {/*                ) : (*/}
                                        {/*                    <Form.Control.Feedback type="invalid">*/}
                                        {/*                        {errors.night_diff}*/}
                                        {/*                    </Form.Control.Feedback>*/}
                                        {/*                )*/}
                                        {/*        }*/}
                                        {/*    </Form.Group>*/}
                                        {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted required'>5 Days Incentive</Form.Label>*/}
                                        {/*        <Form.Control*/}
                                        {/*            className="text-right"*/}
                                        {/*            size={'sm'}*/}
                                        {/*            type='text'*/}
                                        {/*            placeholder='0'*/}
                                        {/*            name="five_days_incentive"*/}
                                        {/*            value={values.five_days_incentive}*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            isInvalid={(touched.five_days_incentive && !!errors.five_days_incentive) || !!validation}*/}
                                        {/*            autoComplete='off'*/}
                                        {/*            autoFocus*/}
                                        {/*        />*/}
                                        {/*        {*/}
                                        {/*            validation != '' ?*/}
                                        {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                        {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                        {/*                            {validation[message][index]}*/}
                                        {/*                        </Form.Control.Feedback>*/}
                                        {/*                    ))*/}
                                        {/*                ) : (*/}
                                        {/*                    <Form.Control.Feedback type="invalid">*/}
                                        {/*                        {errors.five_days_incentive}*/}
                                        {/*                    </Form.Control.Feedback>*/}
                                        {/*                )*/}
                                        {/*        }*/}
                                        {/*    </Form.Group>*/}
                                        {/*</Row>*/}
                                        {/*<br/>*/}
                                        {/*<Row>*/}
                                        {/*    <p className="text-center"><strong>Deductions</strong></p>*/}
                                        {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted required'>PhilHealth</Form.Label>*/}
                                        {/*        <Form.Control*/}
                                        {/*            className="text-right"*/}
                                        {/*            size={'sm'}*/}
                                        {/*            type='text'*/}
                                        {/*            placeholder='0'*/}
                                        {/*            name="philhealth"*/}
                                        {/*            value={values.philhealth}*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            isInvalid={(touched.philhealth && !!errors.philhealth) || !!validation}*/}
                                        {/*            autoComplete='off'*/}
                                        {/*        />*/}
                                        {/*        {*/}
                                        {/*            validation != '' ?*/}
                                        {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                        {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                        {/*                            {validation[message][index]}*/}
                                        {/*                        </Form.Control.Feedback>*/}
                                        {/*                    ))*/}
                                        {/*                ) : (*/}
                                        {/*                    <Form.Control.Feedback type="invalid">*/}
                                        {/*                        {errors.philhealth}*/}
                                        {/*                    </Form.Control.Feedback>*/}
                                        {/*                )*/}
                                        {/*        }*/}
                                        {/*    </Form.Group>*/}
                                        {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted required'>Pagibig</Form.Label>*/}
                                        {/*        <Form.Control*/}
                                        {/*            className="text-right"*/}
                                        {/*            size={'sm'}*/}
                                        {/*            type='text'*/}
                                        {/*            placeholder='0'*/}
                                        {/*            name="pagibig"*/}
                                        {/*            value={values.pagibig}*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            isInvalid={(touched.pagibig && !!errors.pagibig) || !!validation}*/}
                                        {/*            autoComplete='off'*/}
                                        {/*        />*/}
                                        {/*        {*/}
                                        {/*            validation != '' ?*/}
                                        {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                        {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                        {/*                            {validation[message][index]}*/}
                                        {/*                        </Form.Control.Feedback>*/}
                                        {/*                    ))*/}
                                        {/*                ) : (*/}
                                        {/*                    <Form.Control.Feedback type="invalid">*/}
                                        {/*                        {errors.pagibig}*/}
                                        {/*                    </Form.Control.Feedback>*/}
                                        {/*                )*/}
                                        {/*        }*/}
                                        {/*    </Form.Group>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted required'>SSS</Form.Label>*/}
                                        {/*        <Form.Control*/}
                                        {/*            className="text-right"*/}
                                        {/*            size={'sm'}*/}
                                        {/*            type='text'*/}
                                        {/*            placeholder='0'*/}
                                        {/*            name="sss"*/}
                                        {/*            value={values.sss}*/}
                                        {/*            onChange={handleChange}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            isInvalid={(touched.sss && !!errors.sss) || !!validation}*/}
                                        {/*            autoComplete='off'*/}
                                        {/*            autoFocus*/}
                                        {/*        />*/}
                                        {/*        {*/}
                                        {/*            validation != '' ?*/}
                                        {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                        {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                        {/*                            {validation[message][index]}*/}
                                        {/*                        </Form.Control.Feedback>*/}
                                        {/*                    ))*/}
                                        {/*                ) : (*/}
                                        {/*                    <Form.Control.Feedback type="invalid">*/}
                                        {/*                        {errors.sss}*/}
                                        {/*                    </Form.Control.Feedback>*/}
                                        {/*                )*/}
                                        {/*        }*/}
                                        {/*    </Form.Group>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <p className="text-center"><strong>Shifts</strong></p>*/}
                                        {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted required'>Shift Types</Form.Label>*/}
                                        {/*        <Form.Select*/}
                                        {/*            size={'sm'}*/}
                                        {/*            name="shift_type"*/}
                                        {/*            onChange={handleChangeShiftType}*/}
                                        {/*        >*/}
                                        {/*            <option value="" disabled selected>Select Shift Type</option>*/}
                                        {/*            <option value="1">8 Hours Shift</option>*/}
                                        {/*            <option value="2">8 Hours & 4 Hours OT Shift</option>*/}
                                        {/*            <option value="0">12 Hours Shift</option>*/}
                                        {/*        </Form.Select>*/}

                                        {/*    </Form.Group>*/}
                                        {/*    <Form.Group as={Col} md="5" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted required'>Shift</Form.Label>*/}
                                        {/*        <Form.Select*/}
                                        {/*            size={'sm'}*/}
                                        {/*            name="shift"*/}
                                        {/*            onChange={handleChangeUpdateShift}*/}
                                        {/*        >*/}
                                        {/*            <option value="" disabled selected>Select Shift</option>*/}
                                        {/*            {shifts.length > 0 ?*/}
                                        {/*                (<>*/}
                                        {/*                    {shifts.map((shift, index) => (*/}
                                        {/*                        <option value={shift.id} key={index}>{moment(new Date("2023-01-01 " + shift.time_from ?? "")).format("h:mm A")} - {moment(new Date("2023-01-01 " + (shift.isEightHourShift == 2 ? shift.overtime_out: shift.time_to) ?? "")).format("h:mm A")}</option>*/}
                                        {/*                    ))}*/}
                                        {/*                </>)*/}
                                        {/*                : ""}*/}

                                        {/*        </Form.Select>*/}
                                        {/*    </Form.Group>*/}
                                        {/*    <Form.Group as={Col} md="1" className='mb-3'>*/}
                                        {/*        <Form.Label className='text-muted' style={{color:'white'}}>&nbsp;&nbsp;</Form.Label>*/}
                                        {/*        <Button*/}
                                        {/*            onClick={handleAddUpdateShift}*/}
                                        {/*            className='p-1 pt-0 pb-0'*/}
                                        {/*            style={{ fontSize: '15px', color: 'white' }}*/}
                                        {/*            variant=' btn-transparent'*/}
                                        {/*            size='sm'*/}
                                        {/*            title={'Assign Employee'}*/}
                                        {/*        >*/}
                                        {/*            <svg*/}
                                        {/*                xmlns='http://www.w3.org/2000/svg'*/}
                                        {/*                width='16.143'*/}
                                        {/*                height='16.143'*/}
                                        {/*                viewBox='0 0 16.143 16.143'*/}
                                        {/*            >*/}
                                        {/*                <g id='New' transform='translate(0.5 0.5)'>*/}
                                        {/*                    <g*/}
                                        {/*                        id='Icon_feather-plus-circle'*/}
                                        {/*                        data-name='Icon feather-plus-circle'*/}
                                        {/*                        transform='translate(-3 -3)'*/}
                                        {/*                    >*/}
                                        {/*                        <path*/}
                                        {/*                            id='Path_46'*/}
                                        {/*                            data-name='Path 46'*/}
                                        {/*                            d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'*/}
                                        {/*                            fill='none'*/}
                                        {/*                            stroke='#a7a9ac'*/}
                                        {/*                            strokeLinecap='round'*/}
                                        {/*                            strokeLinejoin='round'*/}
                                        {/*                            strokeWidth='1'*/}
                                        {/*                        />*/}
                                        {/*                        <path*/}
                                        {/*                            id='Path_47'*/}
                                        {/*                            data-name='Path 47'*/}
                                        {/*                            d='M18,12v6.057'*/}
                                        {/*                            transform='translate(-7.429 -4.457)'*/}
                                        {/*                            fill='none'*/}
                                        {/*                            stroke='#f26930'*/}
                                        {/*                            strokeLinecap='round'*/}
                                        {/*                            strokeLinejoin='round'*/}
                                        {/*                            strokeWidth='1'*/}
                                        {/*                        />*/}
                                        {/*                        <path*/}
                                        {/*                            id='Path_48'*/}
                                        {/*                            data-name='Path 48'*/}
                                        {/*                            d='M12,18h6.057'*/}
                                        {/*                            transform='translate(-4.457 -7.429)'*/}
                                        {/*                            fill='none'*/}
                                        {/*                            stroke='#f26930'*/}
                                        {/*                            strokeLinecap='round'*/}
                                        {/*                            strokeLinejoin='round'*/}
                                        {/*                            strokeWidth='1'*/}
                                        {/*                        />*/}
                                        {/*                    </g>*/}
                                        {/*                </g>*/}
                                        {/*            </svg>*/}
                                        {/*        </Button>*/}
                                        {/*    </Form.Group>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <Table responsive>*/}
                                        {/*        <thead>*/}
                                        {/*        <tr>*/}
                                        {/*            <th style={{width:'35%'}}>Shift</th>*/}
                                        {/*            <th style={{width:'35%'}}>Type</th>*/}
                                        {/*            <th>Action</th>*/}
                                        {/*        </tr>*/}
                                        {/*        </thead>*/}
                                        {/*        <tbody>*/}

                                        {/*        {*/}
                                        {/*            item.shifts.length > 0 ?*/}
                                        {/*                item.shifts.map((shift,index) => (*/}
                                        {/*                    <tr key={index}>*/}
                                        {/*                        <td>{moment(new Date("2023-01-01 " + shift.shifts.time_from ?? "")).format("h:mm A")} - {moment(new Date("2023-01-01 " + (shift.shifts.isEightHourShift == 2 ? shift.shifts.overtime_out: shift.shifts.time_to) ?? "")).format("h:mm A")}</td>*/}
                                        {/*                        <td>{shift.shifts.isEightHourShift == 1 ? "8 Hours Shift": shift.shifts.isEightHourShift == 2? "8 Hours & 4 Hours OT" :"12 Hours Shift"}</td>*/}
                                        {/*                        <td>*/}
                                        {/*                            <Button*/}
                                        {/*                                onClick={() => handleDeleteUpdateShift(shift.id)}*/}
                                        {/*                                className=' p-1 pt-0 pb-0'*/}
                                        {/*                                style={{ fontSize: '12px', color: 'white' }}*/}
                                        {/*                                variant=' btn-transparent'*/}
                                        {/*                                size='sm'*/}
                                        {/*                            >*/}
                                        {/*                                <Delete/>*/}
                                        {/*                            </Button>*/}
                                        {/*                        </td>*/}
                                        {/*                    </tr>*/}
                                        {/*                ))*/}
                                        {/*                : <tr><td colSpan={3}>No shift yet.</td></tr>*/}
                                        {/*        }*/}

                                        {/*        </tbody>*/}
                                        {/*    </Table>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <p className="text-center"><strong>Sub-worksite</strong></p>*/}
                                        {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted required'>Sub-worksites</Form.Label>*/}
                                        {/*        <Form.Select*/}
                                        {/*            size={'sm'}*/}
                                        {/*            name="shift_type"*/}
                                        {/*            onChange={handleChangeUpdateSubWorksite}*/}
                                        {/*        >*/}
                                        {/*            <option value="" disabled selected>Select Sub-worksite</option>*/}
                                        {/*            {*/}
                                        {/*                subWorksites.length > 0 ?*/}
                                        {/*                    subWorksites.map((subworksite,index) => (*/}
                                        {/*                        <option value={subworksite.id} key={index}>{subworksite.name}</option>*/}
                                        {/*                    ))*/}
                                        {/*                    : ""*/}
                                        {/*            }*/}
                                        {/*        </Form.Select>*/}

                                        {/*    </Form.Group>*/}
                                        {/*    <Form.Group as={Col} md="6" className='mb-3'>*/}
                                        {/*        <Form.Label className='text-muted' style={{color:'white'}}>&nbsp;&nbsp;</Form.Label>*/}
                                        {/*        <Button*/}
                                        {/*            onClick={handleAddUpdateSubWorksite}*/}
                                        {/*            className='p-0 pt-4 pb-0'*/}
                                        {/*            style={{ fontSize: '20px', color: 'white' }}*/}
                                        {/*            variant=' btn-transparent'*/}
                                        {/*            size='sm'*/}
                                        {/*            title={'Add sub-worksite'}*/}
                                        {/*        >*/}
                                        {/*            <svg*/}
                                        {/*                xmlns='http://www.w3.org/2000/svg'*/}
                                        {/*                width='16.143'*/}
                                        {/*                height='16.143'*/}
                                        {/*                viewBox='0 0 16.143 16.143'*/}
                                        {/*            >*/}
                                        {/*                <g id='New' transform='translate(0.5 0.5)'>*/}
                                        {/*                    <g*/}
                                        {/*                        id='Icon_feather-plus-circle'*/}
                                        {/*                        data-name='Icon feather-plus-circle'*/}
                                        {/*                        transform='translate(-3 -3)'*/}
                                        {/*                    >*/}
                                        {/*                        <path*/}
                                        {/*                            id='Path_46'*/}
                                        {/*                            data-name='Path 46'*/}
                                        {/*                            d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'*/}
                                        {/*                            fill='none'*/}
                                        {/*                            stroke='#a7a9ac'*/}
                                        {/*                            strokeLinecap='round'*/}
                                        {/*                            strokeLinejoin='round'*/}
                                        {/*                            strokeWidth='1'*/}
                                        {/*                        />*/}
                                        {/*                        <path*/}
                                        {/*                            id='Path_47'*/}
                                        {/*                            data-name='Path 47'*/}
                                        {/*                            d='M18,12v6.057'*/}
                                        {/*                            transform='translate(-7.429 -4.457)'*/}
                                        {/*                            fill='none'*/}
                                        {/*                            stroke='#f26930'*/}
                                        {/*                            strokeLinecap='round'*/}
                                        {/*                            strokeLinejoin='round'*/}
                                        {/*                            strokeWidth='1'*/}
                                        {/*                        />*/}
                                        {/*                        <path*/}
                                        {/*                            id='Path_48'*/}
                                        {/*                            data-name='Path 48'*/}
                                        {/*                            d='M12,18h6.057'*/}
                                        {/*                            transform='translate(-4.457 -7.429)'*/}
                                        {/*                            fill='none'*/}
                                        {/*                            stroke='#f26930'*/}
                                        {/*                            strokeLinecap='round'*/}
                                        {/*                            strokeLinejoin='round'*/}
                                        {/*                            strokeWidth='1'*/}
                                        {/*                        />*/}
                                        {/*                    </g>*/}
                                        {/*                </g>*/}
                                        {/*            </svg>*/}
                                        {/*        </Button>*/}
                                        {/*    </Form.Group>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <Table responsive>*/}
                                        {/*        <thead>*/}
                                        {/*        <tr>*/}
                                        {/*            <th style={{width:'70%'}}>Name</th>*/}
                                        {/*            <th>Action</th>*/}
                                        {/*        </tr>*/}
                                        {/*        </thead>*/}
                                        {/*        <tbody>*/}

                                        {/*        {*/}
                                        {/*            item.subworksites.length > 0 ?*/}
                                        {/*                item.subworksites.map((subworksite,index) => (*/}
                                        {/*                        <tr key={index}>*/}
                                        {/*                            <td>{subworksite.subworksites.name}</td>*/}
                                        {/*                            <td>*/}
                                        {/*                                <Button*/}
                                        {/*                                    onClick={() => handleDeleteUpdateSubWorksite(subworksite.id)}*/}
                                        {/*                                    className=' p-1 pt-0 pb-0'*/}
                                        {/*                                    style={{ fontSize: '12px', color: 'white' }}*/}
                                        {/*                                    variant=' btn-transparent'*/}
                                        {/*                                    size='sm'*/}
                                        {/*                                >*/}
                                        {/*                                    <Delete/>*/}
                                        {/*                                </Button>*/}
                                        {/*                            </td>*/}
                                        {/*                        </tr>*/}
                                        {/*                ))*/}
                                        {/*                : <tr><td colSpan={2}>No Sub-worksite yet.</td></tr>*/}
                                        {/*        }*/}

                                        {/*        </tbody>*/}
                                        {/*    </Table>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <Form.Group as={Col} md="6" className='mb-3' controlId='exampleForm.ControlInput1'>*/}
                                        {/*        <Form.Label className='text-muted required'>Status</Form.Label>*/}
                                        {/*        <Form.Select*/}
                                        {/*            size={'sm'}*/}
                                        {/*            name="status"*/}
                                        {/*            onChange={event => {*/}
                                        {/*                handleChangeStatus(event);*/}
                                        {/*                handleChange(event);*/}
                                        {/*            }*/}
                                        {/*            }*/}
                                        {/*            value={item.status}*/}
                                        {/*            onBlur={handleBlur}*/}
                                        {/*            // isInvalid={(touched.isDailyRate && !!errors.isDailyRate) || !!validation}*/}
                                        {/*        >*/}
                                        {/*            <option value="0">Inactive</option>*/}
                                        {/*            <option value="1">Active</option>*/}
                                        {/*        </Form.Select>*/}
                                        {/*        {*/}
                                        {/*            validation != '' ?*/}
                                        {/*                (Object.keys(validation).map((message : any, index) => (*/}
                                        {/*                        <Form.Control.Feedback type="invalid" key={index}>*/}
                                        {/*                            {validation[message][index]}*/}
                                        {/*                        </Form.Control.Feedback>*/}
                                        {/*                    ))*/}
                                        {/*                ) : (*/}
                                        {/*                    <Form.Control.Feedback type="invalid">*/}
                                        {/*                        {errors.status}*/}
                                        {/*                    </Form.Control.Feedback>*/}
                                        {/*                )*/}
                                        {/*        }*/}
                                        {/*    </Form.Group>*/}
                                        {/*</Row>*/}
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        form="workSiteUpdated"
                                        variant='primary text-white'
                                        size={'sm'}
                                        className='mx-2'
                                    >
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
}
