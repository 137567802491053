import React, {useEffect, useRef, useState} from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Stack,
    Table,
} from 'react-bootstrap'
import Alert from "components/Alert";
import './style.css'
import CircleCreate from "asset/SvgComponent/CircleCreate";
import Reject from "asset/SvgComponent/Reject";
import axios from "../../../../api";
import {IAPI_Response} from "../../../../api/types";
import {IGeneralSetup} from "../../General/type";
import {API_ENDPOINT, ERROR_MESSAGE, SETUP, SUCCESS_MESSAGE, WHITE_LISTED} from "../../../../utils/globalConstant";
import {IAttendanceSetting, IEmployee, IScreenshotEmployee, IScreenshotEmployeeList} from "./types";
import Select from "components/ReactSelect";
import Delete from "asset/SvgComponent/Delete";
import Dialog from "components/Dialog";
import Pagination from "components/Pagination";


export default function Index() {
    const inputFileUploadRef = useRef<HTMLInputElement>( null );
    const [error, setError] = React.useState('')
    const [showError, setShowError] = React.useState(false)
    const [success, setSuccess] = React.useState('')
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [key, setKey] = React.useState("attendance")
    const [item, setItem] = React.useState<IAttendanceSetting>({
        takeScreenshot: 0,
        noOfScreenshotPerHour: 0,
        allowBlurScreenshot: 0,
        trackMouseKeyboardActivity: 0,
        trackAppUrl: 0,
        autoPause: 0,
        noOfMinutesAutopause: 0})
    const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
    const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
    const [deletedId, setDeletedId] = useState(0)
    const [settingType, setSettingType] = useState("screenshot")

    const [showAddEmployee, setShowAddEmployee] = React.useState(false)
    const handleShowAddEmployee = () => setShowAddEmployee(true)
    const handleCloseAddEmployee = () => {
        setSelectedEmployeeOption({value: 0, label: "Select Employee"})
        setShowAddEmployee(false)
    }

    const [showConfirm, setShowConfirm] = React.useState(false)
    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    const [screenshotEmployees, setScreenshotEmployees] = React.useState<IScreenshotEmployee[]>([])
    const [searchedKeywordScreenshot, setSearchedKeywordScreenshot] = React.useState("")
    const [entriesScreenshot, setEntriesScreenshot] = React.useState(10)
    const [currentPage, setCurrentPage] = React.useState(0)
    const [lastPage, setLastPage] = React.useState(0)
    const [itemsPerPage, setPerPage] = React.useState(10)
    const [itemsCount, setItemCount] = React.useState(0)
    const [from, setFrom] = React.useState(0)

    const [trackMouseKeyboardEmployees, setTrackMouseKeyboardEmployees] = React.useState<IScreenshotEmployee[]>([])
    const [searchedKeywordTrackMouseKeyboard, setSearchedKeywordTrackMouseKeyboard] = React.useState("")
    const [entriesTrackMouseKeyboard, setEntriesTrackMouseKeyboard] = React.useState(10)
    const [currentPage2, setCurrentPage2] = React.useState(0)
    const [lastPage2, setLastPage2] = React.useState(0)
    const [itemsPerPage2, setPerPage2] = React.useState(10)
    const [itemsCount2, setItemCount2] = React.useState(0)
    const [from2, setFrom2] = React.useState(0)

    const [trackAppUrlEmployees, setTrackAppUrlEmployees] = React.useState<IScreenshotEmployee[]>([])
    const [searchedKeywordTrackAppUrl, setSearchedKeywordTrackAppUrl] = React.useState("")
    const [entriesTrackAppUrl, setEntriesTrackAppUrl] = React.useState(10)
    const [currentPage3, setCurrentPage3] = React.useState(0)
    const [lastPage3, setLastPage3] = React.useState(0)
    const [itemsPerPage3, setPerPage3] = React.useState(10)
    const [itemsCount3, setItemCount3] = React.useState(0)
    const [from3, setFrom3] = React.useState(0)

    const [autopauseEmployees, setAutopauseEmployees] = React.useState<IScreenshotEmployee[]>([])
    const [searchedKeywordAutopause, setSearchedKeywordAutopause] = React.useState("")
    const [entriesAutopause, setEntriesAutopause] = React.useState(10)
    const [currentPage4, setCurrentPage4] = React.useState(0)
    const [lastPage4, setLastPage4] = React.useState(0)
    const [itemsPerPage4, setPerPage4] = React.useState(10)
    const [itemsCount4, setItemCount4] = React.useState(0)
    const [from4, setFrom4] = React.useState(0)

    const handleChangeEntriesScreenshot = (e: any) => {
        setEntriesScreenshot(e.target.value)
        getScreenshotEmployees(null, searchedKeywordScreenshot, e.target.value)
    }

    const handleChangeEntriesTrackMouseKeyboard = (e: any) => {
        setEntriesTrackMouseKeyboard(e.target.value)
        getTrackMouseKeyboardEmployees(null, searchedKeywordTrackMouseKeyboard, e.target.value)
    }

    const handleChangeEntriesTrackAppUrl = (e: any) => {
        setEntriesTrackAppUrl(e.target.value)
        getTrackUrlEmployees(null, searchedKeywordTrackAppUrl, e.target.value)
    }

    const handleChangeEntriesAutopause = (e: any) => {
        setEntriesAutopause(e.target.value)
        getAutopauseEmployees(null, searchedKeywordAutopause, e.target.value)
    }

    const handleChangeDropdown = (selectedOption: any) => {
        setSelectedEmployeeOption(selectedOption)
    }

    const handleChangeEmployee = async (e: any, type: string, id: number) => {
        let params = {}
        if(type == "enable"){
            params = {enable: e.target.checked ? 1 : 0}
        }else if(type == "allow_blur"){
            params = {allow_blur: e.target.checked ? 1 : 0}
        }else if(type == "value"){
            params = {value: e.target.value}
        }

        await axios.put(API_ENDPOINT.UPDATE_EMPLOYEE_SCREENSHOT+"/"+id, params).then(response => {

            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SETUP.SUCCESSFULLY_TRANSFERRED)
                getScreenshotEmployees(currentPage, searchedKeywordScreenshot, entriesScreenshot)
            }
        }).catch(error => {
            setShowError(true)
            setError(error.response.data.message)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);


    }


    const handleTakeScreenshot = async (e: any) => {
        setItem({...item, takeScreenshot: e.target.value})

        await updateAttendanceSettings({takeScreenshot: e.target.value,
            noOfScreenshotPerHour: item.noOfScreenshotPerHour,
            allowBlurScreenshot: item.allowBlurScreenshot,
            trackMouseKeyboardActivity: item.trackMouseKeyboardActivity,
            trackAppUrl: item.trackAppUrl,
            autoPause: item.autoPause,
            noOfMinutesAutopause: item.noOfMinutesAutopause
        })
    }

    const handleTrackMouseKeyboardActivity = async (e: any) => {
        setItem({...item, trackMouseKeyboardActivity: e.target.value})

        await updateAttendanceSettings({takeScreenshot: item.takeScreenshot,
            noOfScreenshotPerHour: item.noOfScreenshotPerHour,
            allowBlurScreenshot: item.allowBlurScreenshot,
            trackMouseKeyboardActivity: e.target.value,
            trackAppUrl: item.trackAppUrl,
            autoPause: item.autoPause,
            noOfMinutesAutopause: item.noOfMinutesAutopause
        })
    }

    const handleTrackAppUrl = async (e: any) => {
        setItem({...item, trackAppUrl: e.target.value})

        await updateAttendanceSettings({takeScreenshot: item.takeScreenshot,
            noOfScreenshotPerHour: item.noOfScreenshotPerHour,
            allowBlurScreenshot: item.allowBlurScreenshot,
            trackMouseKeyboardActivity: item.trackMouseKeyboardActivity,
            trackAppUrl: e.target.value,
            autoPause: item.autoPause,
            noOfMinutesAutopause: item.noOfMinutesAutopause
        })
    }

    const handleAutopause = async (e: any) => {
        setItem({...item, autoPause: e.target.value})

        await updateAttendanceSettings({takeScreenshot: item.takeScreenshot,
            noOfScreenshotPerHour: item.noOfScreenshotPerHour,
            allowBlurScreenshot: item.allowBlurScreenshot,
            trackMouseKeyboardActivity: item.trackMouseKeyboardActivity,
            trackAppUrl: item.trackAppUrl,
            autoPause: e.target.value,
            noOfMinutesAutopause: item.noOfMinutesAutopause
        })
    }

    const handleChangeNoOfScreenshot = async (e: any) => {
        setItem({...item, noOfScreenshotPerHour: e.target.value})

        await updateAttendanceSettings({takeScreenshot: item.takeScreenshot,
            noOfScreenshotPerHour: e.target.value,
            allowBlurScreenshot: item.allowBlurScreenshot,
            trackMouseKeyboardActivity: item.trackMouseKeyboardActivity,
            trackAppUrl: item.trackAppUrl,
            autoPause: item.autoPause,
            noOfMinutesAutopause: item.noOfMinutesAutopause
        })
    }

    const handleChangeNoOfMinutesAutopause = async (e: any) => {
        setItem({...item, noOfMinutesAutopause: e.target.value})

        await updateAttendanceSettings({takeScreenshot: item.takeScreenshot,
            noOfScreenshotPerHour: item.noOfScreenshotPerHour,
            allowBlurScreenshot: item.allowBlurScreenshot,
            trackMouseKeyboardActivity: item.trackMouseKeyboardActivity,
            trackAppUrl: item.trackAppUrl,
            autoPause: item.autoPause,
            noOfMinutesAutopause: e.target.value
        })
    }

    const handleAllowBlur = async (e: any) => {
        setItem({...item, allowBlurScreenshot: e.target.value})

        await updateAttendanceSettings({takeScreenshot: item.takeScreenshot,
            noOfScreenshotPerHour: item.noOfScreenshotPerHour,
            allowBlurScreenshot: e.target.value,
            trackMouseKeyboardActivity: item.trackMouseKeyboardActivity,
            trackAppUrl: item.trackAppUrl,
            autoPause: item.autoPause,
            noOfMinutesAutopause: item.noOfMinutesAutopause
        })
    }

    const handleAddEmployee = async () => {
        await axios.post(API_ENDPOINT.POST_EMPLOYEE_SCREENSHOT, {
            employee_id: selectedEmployeeOption.value,
            type: settingType
        }).then(response => {
            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(WHITE_LISTED.SUCCESS)
                if(settingType == "screenshot"){
                    getScreenshotEmployees(null, searchedKeywordScreenshot, entriesScreenshot)
                }else if(settingType == "track-mouse-keyboard"){
                    getTrackMouseKeyboardEmployees(null, searchedKeywordTrackMouseKeyboard, entriesTrackMouseKeyboard)
                }else if(settingType == "track-app-url"){
                    getTrackUrlEmployees(null, searchedKeywordTrackAppUrl, entriesTrackAppUrl)
                }else if(settingType == "auto-pause"){
                    getAutopauseEmployees(null, searchedKeywordAutopause, entriesAutopause)
                }

                handleCloseAddEmployee();
            }else{
                setShowError(true)
                setError("Employee is already added.")
            }

        }).catch(error => {
            setShowError(true)
            setError(error.response.data.message)
            // setValidation(error.response.data.message)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const deleteEmployeeScreenshot = async (id: number) => {
        try {
            const response = await axios.delete(API_ENDPOINT.DELETE_EMPLOYEE_SCREENSHOT +'/'+ id)
            const result = await response
            if (result?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.EMPLOYEE.DELETED)
                if(settingType == "screenshot"){
                    getScreenshotEmployees(null, searchedKeywordScreenshot, entriesScreenshot)
                }else if(settingType == "track-mouse-keyboard"){
                    getTrackMouseKeyboardEmployees(null, searchedKeywordTrackMouseKeyboard, entriesTrackMouseKeyboard)
                }else if(settingType == "track-app-url"){
                    getTrackUrlEmployees(null, searchedKeywordTrackAppUrl, entriesTrackAppUrl)
                }else if(settingType == "auto-pause"){
                    getAutopauseEmployees(null, searchedKeywordAutopause, entriesAutopause)
                }

            }
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }
        handleCloseConfirm()
        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const getScreenshotSettings = async () => {
        try {
            const response = await axios.get<IAPI_Response<IAttendanceSetting>>(API_ENDPOINT.GET_SCREENSHOT_SETTINGS)
            const result = await response
            if (result?.data?.results) {
                const item = result?.data?.results
                setItem(item)
            }
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const updateAttendanceSettings = async (item: IAttendanceSetting) => {

        await axios.put(API_ENDPOINT.UPDATE_SCREENSHOT_SETTINGS, item).then(response => {

            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SETUP.SUCCESSFULLY_TRANSFERRED)

            }
        }).catch(error => {
            setShowError(true)
            setError(error.response.data.message)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const getScreenshotEmployees = async(page_number: number | null, searchedKeyword: string, entriesNumber: number) => {
        const response = await axios.get<IAPI_Response<IScreenshotEmployeeList>>(API_ENDPOINT.GET_EMPLOYEES_SCREENSHOT , {
            params: {
                page: page_number,
                searched: searchedKeyword,
                entries: entriesNumber,
                type: "screenshot"
            },
        })
        const result = await response
        if(result?.data?.results?.data?.length){
            setScreenshotEmployees(result?.data?.results?.data);
            setCurrentPage(result?.data?.results?.current_page)
            setLastPage(result?.data?.results?.last_page)
            setPerPage(result?.data?.results?.per_page)
            setItemCount(result?.data?.results?.total)
            setFrom(result?.data?.results?.from)
        }else{
            setScreenshotEmployees([])
            setLastPage(1)
        }
    }

    const getTrackMouseKeyboardEmployees = async(page_number: number | null, searchedKeyword: string, entriesNumber: number) => {
        const response = await axios.get<IAPI_Response<IScreenshotEmployeeList>>(API_ENDPOINT.GET_EMPLOYEES_SCREENSHOT , {
            params: {
                page: page_number,
                searched: searchedKeyword,
                entries: entriesNumber,
                type: "track-mouse-keyboard"
            },
        })
        const result = await response
        if(result?.data?.results?.data?.length){
            setTrackMouseKeyboardEmployees(result?.data?.results?.data);
            setCurrentPage2(result?.data?.results?.current_page)
            setLastPage2(result?.data?.results?.last_page)
            setPerPage2(result?.data?.results?.per_page)
            setItemCount2(result?.data?.results?.total)
            setFrom2(result?.data?.results?.from)
        }else{
            setTrackMouseKeyboardEmployees([])
            setLastPage2(1)
        }
    }

    const getTrackUrlEmployees = async(page_number: number | null, searchedKeyword: string, entriesNumber: number) => {
        const response = await axios.get<IAPI_Response<IScreenshotEmployeeList>>(API_ENDPOINT.GET_EMPLOYEES_SCREENSHOT , {
            params: {
                page: page_number,
                searched: searchedKeyword,
                entries: entriesNumber,
                type: "track-app-url"
            },
        })
        const result = await response
        if(result?.data?.results?.data?.length){
            setTrackAppUrlEmployees(result?.data?.results?.data);
            setCurrentPage3(result?.data?.results?.current_page)
            setLastPage3(result?.data?.results?.last_page)
            setPerPage3(result?.data?.results?.per_page)
            setItemCount3(result?.data?.results?.total)
            setFrom3(result?.data?.results?.from)
        }else{
            setTrackAppUrlEmployees([])
            setLastPage3(1)
        }
    }

    const getAutopauseEmployees = async(page_number: number | null, searchedKeyword: string, entriesNumber: number) => {
        const response = await axios.get<IAPI_Response<IScreenshotEmployeeList>>(API_ENDPOINT.GET_EMPLOYEES_SCREENSHOT , {
            params: {
                page: page_number,
                searched: searchedKeyword,
                entries: entriesNumber,
                type: "auto-pause"
            },
        })
        const result = await response
        if(result?.data?.results?.data?.length){
            setAutopauseEmployees(result?.data?.results?.data);
            setCurrentPage4(result?.data?.results?.current_page)
            setLastPage4(result?.data?.results?.last_page)
            setPerPage4(result?.data?.results?.per_page)
            setItemCount4(result?.data?.results?.total)
            setFrom4(result?.data?.results?.from)
        }else{
            setAutopauseEmployees([])
            setLastPage4(1)
        }
    }

    const getAllEmployee = async () => {
        try {
            const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
            const result = await res
            if (result?.data?.results?.length) {

                const employees = result?.data?.results;
                setEmployeeOptions([])

                setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
                employees.map(employee => {
                    setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
                })
            }
        } catch {
            console.log('There is something wrong')
        }
    }

    useEffect(() => {
        ;(async () => {
            getScreenshotSettings()
            getAllEmployee()
            getScreenshotEmployees(null, searchedKeywordScreenshot, entriesScreenshot)
            getTrackMouseKeyboardEmployees(null, searchedKeywordTrackMouseKeyboard, entriesTrackMouseKeyboard)
            getTrackUrlEmployees(null, searchedKeywordTrackAppUrl, entriesTrackAppUrl)
            getAutopauseEmployees(null, searchedKeywordAutopause, entriesAutopause)
        })()
    }, [])


    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Attendance Settings</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none'>
                        Setup
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className=' text-decoration-none'>
                        Attendance
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Attendance Settings</Breadcrumb.Item>
                </Breadcrumb>
                <Tabs
                    variant={'tabs'}
                    className='tabs '
                    id='controlled-tab-example'
                    activeKey={key}
                    onSelect={(k: any) => {
                        setKey(k)
                    }}
                    // sx={{
                    //   opacity: open ? 1 : 0,
                    //   fontWeight: openDropdown === 0 ? 700 : 100,
                    // }}
                >
                    <Tab  eventKey='attendance' title='Attendance'>
                        <Card className='border-0  p-0 bordered-card'>
                            <Card.Body>
                                <Row>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3'>
                                            <h5 style={{fontSize: '98%'}}><b>Attendance</b></h5>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <br/>

                                <Form.Group >
                                    <Button
                                        // onClick={handleUpdateGeneralSetup}
                                        type="submit"
                                        variant='primary text-white'
                                        style={{ width: '10%', float:'right' }}
                                        size="sm">
                                        Save Changes
                                    </Button>
                                </Form.Group>

                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab  eventKey='screenshots' title='Screenshots'>
                        <Card className='border-0  p-0 bordered-card'>
                            <Card.Body>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group className='mb-3'>
                                            <h5 ><b>Screenshots</b></h5>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        <span>Allow taking screenshots?</span>
                                    </Col>
                                    <Col sm={4}>
                                        <span>Allow blur screenshots?</span>
                                    </Col>
                                    <Col sm={4}>
                                        <span>How many screenshots will be taken per hour?</span>
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Check
                                                style={{fontSize: '90%'}}
                                                type="radio"
                                                label={"Take Screenshot"}
                                                name={"screenshot"}
                                                value={1}
                                                checked={item.takeScreenshot == 1? true: false}
                                                onClick={handleTakeScreenshot}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check
                                                style={{fontSize: '90%'}}
                                                type="radio"
                                                label="Do not Take"
                                                name="screenshot"
                                                value={0}
                                                checked={item.takeScreenshot == 0? true: false}
                                                onClick={handleTakeScreenshot}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Check
                                                disabled={item.takeScreenshot == 1? false : true}
                                                style={{fontSize: '90%'}}
                                                type="radio"
                                                label={"Blur Screenshot"}
                                                name={"blur"}
                                                value={1}
                                                checked={item.allowBlurScreenshot == 1? true: false}
                                                onClick={handleAllowBlur}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check
                                                disabled={item.takeScreenshot == 1? false : true}
                                                style={{fontSize: '90%'}}
                                                type="radio"
                                                label="Do not Blur"
                                                name="blur"
                                                value={0}
                                                checked={item.allowBlurScreenshot == 0? true: false}
                                                onClick={handleAllowBlur}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group className='mb-3 w-50'>
                                            <Form.Select
                                                disabled={item.takeScreenshot == 1? false : true}
                                                style={{width: '40%'}}
                                                size={'sm'}
                                                name="noOfScreenshot"
                                                onChange={handleChangeNoOfScreenshot}
                                                value={item.noOfScreenshotPerHour}
                                            >
                                                <option value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                                <option value="25">25</option>
                                                <option value="30">30</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row className='mt-4'>
                                    <Col sm={8}>
                                        <h5>Individual Settings</h5>
                                        <span style={{fontSize: '90%'}}>The individual setting will apply individually to each employee added here</span>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group className='mb-1 text-right' controlId='formHorizontalEmail'>
                                            <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                            <br />
                                            <Button
                                                className='add-new-btn rounded text-white'
                                                variant='primary'
                                                type="button"
                                                onClick={() => {
                                                    setSettingType("screenshot")
                                                    handleShowAddEmployee()
                                                }}
                                            >
                                                Add Employee
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Table responsive size='sm' className='bordered-tabs-top'>
                                        <thead>
                                            <tr>
                                                <th className={'text-center'} style={{width: '5%'}}>#</th>
                                                <th className={'text-center'} style={{width: '30%'}}>Employee Name</th>
                                                <th className={'text-center'} style={{width: '15%'}}>Take/Do not Take</th>
                                                <th className={'text-center'} style={{width: '15%'}}>Blur/Do not Blur</th>
                                                <th className={'text-center'} style={{width: '20%'}}>No. of Screenshots/hour</th>
                                                <th className={'text-center'} style={{width: '15%'}}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            screenshotEmployees.length > 0 ?
                                                screenshotEmployees.map((employee, index) => (
                                                    <tr key={index}>
                                                        <td>{from + index}</td>
                                                        <td>{employee.first_name} {employee.last_name}</td>
                                                        <td className={'center-td'}>
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                label=""
                                                                checked={employee.enable == 1? true:false}
                                                                onChange={(e) => {
                                                                    const updatedEmployees = [...screenshotEmployees]; // Make a shallow copy of the array
                                                                    updatedEmployees[index] = {
                                                                        ...employee, // Copy the employee object
                                                                        enable: e.target.checked ? 1 : 0 // Update the enable field
                                                                    };
                                                                    setScreenshotEmployees(updatedEmployees); // Update state with the new array
                                                                    handleChangeEmployee(e, "enable", employee.id)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'center-td'}>
                                                            <Form.Check
                                                                disabled={employee.enable == 1? false : true}
                                                                type="switch"
                                                                id="custom-switch"
                                                                label=""
                                                                checked={employee.allow_blur == 1? true:false}
                                                                onChange={(e) => {
                                                                    const updatedEmployees = [...screenshotEmployees]; // Copy the array
                                                                    updatedEmployees[index] = {
                                                                        ...employee, // Copy the employee object
                                                                        allow_blur: e.target.checked ? 1 : 0 // Update allow_blur
                                                                    };
                                                                    setScreenshotEmployees(updatedEmployees); // Update state
                                                                    handleChangeEmployee(e, "allow_blur", employee.id)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'center-td'}>
                                                            <Form.Group>
                                                                <Form.Select
                                                                    disabled={employee.enable == 1? false : true}
                                                                    style={{width: '30%'}}
                                                                    size={'sm'}
                                                                    name="noOfScreenshot"
                                                                    onChange={(e) => {
                                                                        const updatedEmployees = [...screenshotEmployees]; // Copy array
                                                                        updatedEmployees[index] = {
                                                                            ...employee, // Copy employee object
                                                                            value: parseInt(e.target.value, 10) // Update value
                                                                        };
                                                                        setScreenshotEmployees(updatedEmployees); // Update state
                                                                        handleChangeEmployee(e, "value", employee.id)
                                                                    }}
                                                                    value={employee.value}
                                                                >
                                                                    <option value="0">0</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                    <option value="20">20</option>
                                                                    <option value="25">25</option>
                                                                    <option value="30">30</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </td>
                                                        <td className="text-center">
                                                            <Button
                                                                onClick={() => {
                                                                    setDeletedId(employee.id)
                                                                    handleShowConfirm()
                                                                }}
                                                                className=" p-1 pt-0 pb-0"
                                                                style={{fontSize: '12px'}}
                                                                variant=" btn-transparent"
                                                                size="sm"
                                                            >
                                                                <Delete/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                                : <tr>
                                                    <td className={'text-center'} colSpan={6}>No Data Available.</td>
                                                </tr>
                                        }
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col sm={4}>
                                            <Row>
                                                <Col sm={7}>
                                                    <Pagination
                                                        itemsCount={itemsCount}
                                                        itemsPerPage={itemsPerPage}
                                                        currentPage={currentPage}
                                                        lastPage={lastPage}
                                                        setCurrentPage={setCurrentPage}
                                                        alwaysShown={false}
                                                        pageClicked={(page: number) => {
                                                            getScreenshotEmployees(
                                                                page,
                                                                searchedKeywordScreenshot,
                                                                entriesScreenshot
                                                            );
                                                        }
                                                        }
                                                    />
                                                </Col>
                                                <Col sm={5}>
                                                    <Form.Group >
                                                        {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                                                    </Form.Group>
                                                    <Form.Group className="right-align" >
                                                        <Form.Select
                                                            size={'sm'}
                                                            name="work_shift_id"
                                                            value={entriesScreenshot}
                                                            onChange={handleChangeEntriesScreenshot}
                                                            style={{width: '80px'}}
                                                        >
                                                            <option value="10" selected>10</option>
                                                            <option value="25" >25</option>
                                                            <option value="50" >50</option>
                                                            <option value="100" >100</option>

                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab  eventKey='activity-level' title='Activity Level'>
                        <Card className='border-0  p-0 bordered-card'>
                            <Card.Body>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group className='mb-3'>
                                            <h5><b>Activity Level Tracking</b></h5>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        <span>Track mouse and keyboard activity level?</span>
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Check
                                                style={{fontSize: '90%'}}
                                                type="radio"
                                                label={"Track"}
                                                name={"track_mouse_keyboard"}
                                                value={1}
                                                checked={item.trackMouseKeyboardActivity == 1? true: false}
                                                onClick={handleTrackMouseKeyboardActivity}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check
                                                style={{fontSize: '90%'}}
                                                type="radio"
                                                label="Do not Track"
                                                name="track_mouse_keyboard"
                                                value={0}
                                                checked={item.trackMouseKeyboardActivity == 0? true: false}
                                                onClick={handleTrackMouseKeyboardActivity}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col sm={8}>
                                        <h5>Individual Settings</h5>
                                        <span style={{fontSize: '90%'}}>The individual setting will apply individually to each employee added here</span>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group className='mb-1 text-right' controlId='formHorizontalEmail'>
                                            <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                            <br />
                                            <Button
                                                className='add-new-btn rounded text-white'
                                                variant='primary'
                                                type="button"
                                                onClick={() => {
                                                    setSettingType("track-mouse-keyboard")
                                                    handleShowAddEmployee()
                                                }}
                                            >
                                                Add Employee
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Table responsive size='sm' className='bordered-tabs-top'>
                                        <thead>
                                        <tr>
                                            <th className={'text-center'} style={{width: '5%'}}>#</th>
                                            <th className={'text-center'} style={{width: '35%'}}>Employee Name</th>
                                            <th className={'text-center'} style={{width: '40%'}}>Track/Do not Track</th>
                                            <th className={'text-center'} style={{width: '20%'}}>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            trackMouseKeyboardEmployees.length > 0 ?
                                                trackMouseKeyboardEmployees.map((employee, index) => (
                                                    <tr key={index}>
                                                        <td>{from2 + index}</td>
                                                        <td>{employee.first_name} {employee.last_name}</td>
                                                        <td className={'center-td'}>
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                label=""
                                                                checked={employee.enable == 1? true:false}
                                                                onChange={(e) => {
                                                                    const updatedEmployees = [...trackMouseKeyboardEmployees]; // Make a shallow copy of the array
                                                                    updatedEmployees[index] = {
                                                                        ...employee, // Copy the employee object
                                                                        enable: e.target.checked ? 1 : 0 // Update the enable field
                                                                    };
                                                                    setTrackMouseKeyboardEmployees(updatedEmployees); // Update state with the new array
                                                                    handleChangeEmployee(e, "enable", employee.id)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <Button
                                                                onClick={() => {
                                                                    setDeletedId(employee.id)
                                                                    handleShowConfirm()
                                                                }}
                                                                className=" p-1 pt-0 pb-0"
                                                                style={{fontSize: '12px'}}
                                                                variant=" btn-transparent"
                                                                size="sm"
                                                            >
                                                                <Delete/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                                : <tr>
                                                    <td className={'text-center'} colSpan={4}>No Data Available.</td>
                                                </tr>
                                        }
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col sm={4}>
                                            <Row>
                                                <Col sm={7}>
                                                    <Pagination
                                                        itemsCount={itemsCount2}
                                                        itemsPerPage={itemsPerPage2}
                                                        currentPage={currentPage2}
                                                        lastPage={lastPage2}
                                                        setCurrentPage={setCurrentPage2}
                                                        alwaysShown={false}
                                                        pageClicked={(page: number) => {
                                                            getTrackMouseKeyboardEmployees(
                                                                page,
                                                                searchedKeywordTrackMouseKeyboard,
                                                                entriesTrackMouseKeyboard
                                                            );
                                                        }
                                                        }
                                                    />
                                                </Col>
                                                <Col sm={5}>
                                                    <Form.Group >
                                                        {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                                                    </Form.Group>
                                                    <Form.Group className="right-align" >
                                                        <Form.Select
                                                            size={'sm'}
                                                            name="work_shift_id"
                                                            value={entriesTrackMouseKeyboard}
                                                            onChange={handleChangeEntriesTrackMouseKeyboard}
                                                            style={{width: '80px'}}
                                                        >
                                                            <option value="10" selected>10</option>
                                                            <option value="25" >25</option>
                                                            <option value="50" >50</option>
                                                            <option value="100" >100</option>

                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab  eventKey='apps-and-url' title='App & URL'>
                        <Card className='border-0  p-0 bordered-card'>
                            <Card.Body>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group className='mb-3'>
                                            <h5><b>App & URL Tracking</b></h5>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        <span>Track applications and websites visited?</span>
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Check
                                                style={{fontSize: '90%'}}
                                                type="radio"
                                                label={"Track"}
                                                name={"track_app_url"}
                                                value={1}
                                                checked={item.trackAppUrl == 1? true: false}
                                                onClick={handleTrackAppUrl}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check
                                                style={{fontSize: '90%'}}
                                                type="radio"
                                                label="Do not Track"
                                                name="track_app_url"
                                                value={0}
                                                checked={item.trackAppUrl == 0? true: false}
                                                onClick={handleTrackAppUrl}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col sm={8}>
                                        <h5>Individual Settings</h5>
                                        <span style={{fontSize: '90%'}}>The individual setting will apply individually to each employee added here</span>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group className='mb-1 text-right' controlId='formHorizontalEmail'>
                                            <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                            <br />
                                            <Button
                                                className='add-new-btn rounded text-white'
                                                variant='primary'
                                                type="button"
                                                onClick={() => {
                                                    setSettingType("track-app-url")
                                                    handleShowAddEmployee()
                                                }}
                                            >
                                                Add Employee
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Table responsive size='sm' className='bordered-tabs-top'>
                                        <thead>
                                        <tr>
                                            <th className={'text-center'} style={{width: '5%'}}>#</th>
                                            <th className={'text-center'} style={{width: '35%'}}>Employee Name</th>
                                            <th className={'text-center'} style={{width: '40%'}}>Track/Do not Track</th>
                                            <th className={'text-center'} style={{width: '20%'}}>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            trackAppUrlEmployees.length > 0 ?
                                                trackAppUrlEmployees.map((employee, index) => (
                                                    <tr key={index}>
                                                        <td>{from3 + index}</td>
                                                        <td>{employee.first_name} {employee.last_name}</td>
                                                        <td className={'center-td'}>
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                label=""
                                                                checked={employee.enable == 1? true:false}
                                                                onChange={(e) => {
                                                                    const updatedEmployees = [...trackAppUrlEmployees]; // Make a shallow copy of the array
                                                                    updatedEmployees[index] = {
                                                                        ...employee, // Copy the employee object
                                                                        enable: e.target.checked ? 1 : 0 // Update the enable field
                                                                    };
                                                                    setTrackAppUrlEmployees(updatedEmployees); // Update state with the new array
                                                                    handleChangeEmployee(e, "enable", employee.id)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <Button
                                                                onClick={() => {
                                                                    setDeletedId(employee.id)
                                                                    handleShowConfirm()
                                                                }}
                                                                className=" p-1 pt-0 pb-0"
                                                                style={{fontSize: '12px'}}
                                                                variant=" btn-transparent"
                                                                size="sm"
                                                            >
                                                                <Delete/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                                : <tr>
                                                    <td className={'text-center'} colSpan={4}>No Data Available.</td>
                                                </tr>
                                        }
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col sm={4}>
                                            <Row>
                                                <Col sm={7}>
                                                    <Pagination
                                                        itemsCount={itemsCount3}
                                                        itemsPerPage={itemsPerPage3}
                                                        currentPage={currentPage3}
                                                        lastPage={lastPage3}
                                                        setCurrentPage={setCurrentPage3}
                                                        alwaysShown={false}
                                                        pageClicked={(page: number) => {
                                                            getTrackUrlEmployees(
                                                                page,
                                                                searchedKeywordTrackAppUrl,
                                                                entriesTrackAppUrl
                                                            );
                                                        }
                                                        }
                                                    />
                                                </Col>
                                                <Col sm={5}>
                                                    <Form.Group >
                                                        {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                                                    </Form.Group>
                                                    <Form.Group className="right-align" >
                                                        <Form.Select
                                                            size={'sm'}
                                                            name="work_shift_id"
                                                            value={entriesTrackAppUrl}
                                                            onChange={handleChangeEntriesTrackAppUrl}
                                                            style={{width: '80px'}}
                                                        >
                                                            <option value="10" selected>10</option>
                                                            <option value="25" >25</option>
                                                            <option value="50" >50</option>
                                                            <option value="100" >100</option>

                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Tab>
                    <Tab  eventKey='auto-pause' title='Auto-pause'>
                        <Card className='border-0  p-0 bordered-card'>
                            <Card.Body>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group className='mb-3'>
                                            <h5><b>Auto-pause Tracking</b></h5>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={4}>
                                        <span>Enable auto-pause when user becomes inactive?</span>
                                    </Col>
                                    <Col sm={4}>
                                        <span>No of Minutes of user inactivity</span>
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Check
                                                style={{fontSize: '90%'}}
                                                type="radio"
                                                label={"Pause"}
                                                name={"auto-pause"}
                                                value={1}
                                                checked={item.autoPause == 1? true: false}
                                                onClick={handleAutopause}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check
                                                style={{fontSize: '90%'}}
                                                type="radio"
                                                label="Do not Pause"
                                                name="auto-pause"
                                                value={0}
                                                checked={item.autoPause == 0? true: false}
                                                onClick={handleAutopause}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group className='mb-3 w-50'>
                                            <Form.Select
                                                disabled={item.autoPause == 1? false : true}
                                                style={{width: '40%'}}
                                                size={'sm'}
                                                name="noOfMinutesAutoPause"
                                                onChange={handleChangeNoOfMinutesAutopause}
                                                value={item.noOfMinutesAutopause}
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                                <option value="25">25</option>
                                                <option value="30">30</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col sm={8}>
                                        <h5>Individual Settings</h5>
                                        <span style={{fontSize: '90%'}}>The individual setting will apply individually to each employee added here</span>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group className='mb-1 text-right' controlId='formHorizontalEmail'>
                                            <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                                            <br />
                                            <Button
                                                className='add-new-btn rounded text-white'
                                                variant='primary'
                                                type="button"
                                                onClick={() => {
                                                    setSettingType("auto-pause")
                                                    handleShowAddEmployee()
                                                }}
                                            >
                                                Add Employee
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Table responsive size='sm' className='bordered-tabs-top'>
                                        <thead>
                                        <tr>
                                            <th className={'text-center'} style={{width: '5%'}}>#</th>
                                            <th className={'text-center'} style={{width: '25%'}}>Employee Name</th>
                                            <th className={'text-center'} style={{width: '25%'}}>Pause/Do not Pause</th>
                                            <th className={'text-center'} style={{width: '30%'}}>No. of Minutes</th>
                                            <th className={'text-center'} style={{width: '15%'}}>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            autopauseEmployees.length > 0 ?
                                                autopauseEmployees.map((employee, index) => (
                                                    <tr key={index}>
                                                        <td>{from4 + index}</td>
                                                        <td>{employee.first_name} {employee.last_name}</td>
                                                        <td className={'center-td'}>
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                label=""
                                                                checked={employee.enable == 1? true:false}
                                                                onChange={(e) => {
                                                                    const updatedEmployees = [...autopauseEmployees]; // Make a shallow copy of the array
                                                                    updatedEmployees[index] = {
                                                                        ...employee, // Copy the employee object
                                                                        enable: e.target.checked ? 1 : 0 // Update the enable field
                                                                    };
                                                                    setAutopauseEmployees(updatedEmployees); // Update state with the new array
                                                                    handleChangeEmployee(e, "enable", employee.id)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'center-td'}>
                                                            <Form.Group>
                                                                <Form.Select
                                                                    disabled={employee.enable == 1? false : true}
                                                                    style={{width: '20%'}}
                                                                    size={'sm'}
                                                                    name="noOfScreenshot"
                                                                    onChange={(e) => {
                                                                        const updatedEmployees = [...autopauseEmployees]; // Copy array
                                                                        updatedEmployees[index] = {
                                                                            ...employee, // Copy employee object
                                                                            value: parseInt(e.target.value, 10) // Update value
                                                                        };
                                                                        setAutopauseEmployees(updatedEmployees); // Update state
                                                                        handleChangeEmployee(e, "value", employee.id)
                                                                    }}
                                                                    value={employee.value}
                                                                >
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                    <option value="20">20</option>
                                                                    <option value="25">25</option>
                                                                    <option value="30">30</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </td>
                                                        <td className="text-center">
                                                            <Button
                                                                onClick={() => {
                                                                    setDeletedId(employee.id)
                                                                    handleShowConfirm()
                                                                }}
                                                                className=" p-1 pt-0 pb-0"
                                                                style={{fontSize: '12px'}}
                                                                variant=" btn-transparent"
                                                                size="sm"
                                                            >
                                                                <Delete/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                                : <tr>
                                                    <td className={'text-center'} colSpan={4}>No Data Available.</td>
                                                </tr>
                                        }
                                        </tbody>
                                    </Table>
                                    <Row>
                                        <Col sm={4}>
                                            <Row>
                                                <Col sm={7}>
                                                    <Pagination
                                                        itemsCount={itemsCount4}
                                                        itemsPerPage={itemsPerPage4}
                                                        currentPage={currentPage4}
                                                        lastPage={lastPage4}
                                                        setCurrentPage={setCurrentPage4}
                                                        alwaysShown={false}
                                                        pageClicked={(page: number) => {
                                                            getAutopauseEmployees(
                                                                page,
                                                                searchedKeywordAutopause,
                                                                entriesAutopause
                                                            );
                                                        }
                                                        }
                                                    />
                                                </Col>
                                                <Col sm={5}>
                                                    <Form.Group >
                                                        {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                                                    </Form.Group>
                                                    <Form.Group className="right-align" >
                                                        <Form.Select
                                                            size={'sm'}
                                                            name="work_shift_id"
                                                            value={entriesAutopause}
                                                            onChange={handleChangeEntriesAutopause}
                                                            style={{width: '80px'}}
                                                        >
                                                            <option value="10" selected>10</option>
                                                            <option value="25" >25</option>
                                                            <option value="50" >50</option>
                                                            <option value="100" >100</option>

                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Tab>
                </Tabs>

                {/* ADD WHITELISTED MODAL*/}

                <Modal className='modal' centered show={showAddEmployee} onHide={handleCloseAddEmployee}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Add Employee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Col sm={2}></Col>
                            <Col sm={8}>
                                <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                    <Form.Label className='text-muted required'>Employee</Form.Label>
                                    <Select
                                        name="employee_id"
                                        value={selectedEmployeeOption}
                                        options={employeeOptions}
                                        onChange={(e: any) => {
                                            handleChangeDropdown(e);
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseAddEmployee}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleAddEmployee}
                            type="submit"
                            disabled={selectedEmployeeOption.value == 0}
                            form="branchCreate"
                            variant='primary text-white'
                            size={'sm'}
                            className='mx-2'
                        >
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <Dialog
                    show={showConfirm}
                    setShow={handleCloseConfirm}
                    setEvent={() => {
                        deleteEmployeeScreenshot(deletedId)
                    }
                    }
                />

                <br />
            </Container>
        </>


    )
}
