import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  designation_name: yup
    .string()
    .required(VALIDATION.required)
    .max(150, VALIDATION.max),
  order: yup
      .number()
      .required(VALIDATION.required),
})
