import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  employee_id: yup.string(),
  date_from: yup.string().required(VALIDATION.required),
  date_to: yup.string().required(VALIDATION.required),
})

export const validationSchema2 = yup.object({
  employee_id: yup.string(),
  date_from: yup.string().required(VALIDATION.required)
})
