import React, { useEffect, useState, useRef } from 'react'
import ReactSelect from 'react-select';

const Select = (props: {options: {value: number, label: string}[], value: {value: number, label: string}, name: string, onChange:any} ) => {
    const options = props.options;
    const value = props.value;


    return <ReactSelect
        value={value}
        options={options}
        styles={{ menu: (base) => ({ ...base, fontSize: '13px' }),
            control: (base, state) => ({
                ...base, fontSize: '14px', height:'33px', minHeight: '33px'
            }),}}
        onChange={props.onChange}
    />
}

export default Select;