import React from 'react'

const HomeIconBreadCrumbs = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 17 18.777'>
            <g id='Admin' transform='translate(0.5 0.5)'>
                <path
                    id='Path_30'
                    data-name='Path 30'
                    d='M4.5,9.222,12.5,3l8,6.222V19a1.778,1.778,0,0,1-1.778,1.778H6.278A1.778,1.778,0,0,1,4.5,19Z'
                    transform='translate(-4.5 -3)'
                    fill='none'
                    stroke='#a7a9ac'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1'
                />
                <path
                    id='Path_31'
                    data-name='Path 31'
                    d='M13.5,25.857V18h5.333v7.857'
                    transform='translate(-8.166 -9.111)'
                    fill='none'
                    stroke='#f26930'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1'
                />
            </g>
        </svg>
    )
}

export default HomeIconBreadCrumbs
