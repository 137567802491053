import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import './style.css'
import { Formik } from 'formik';
import View from 'asset/SvgComponent/View'
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Calendar from 'asset/SvgComponent/Calendar'
import Attached from 'asset/SvgComponent/Attached'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { validationSchema } from './validationSchema'
import { INotice, IList, IItem } from './types'
import moment from "moment";

export default function Index() {
  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)

  const [showCreate, setShowCreate] = React.useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)

  const [showEdit, setShowEdit] = React.useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = React.useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      notice_id: id,
      title: '',
      description: '',
      status: '',
      publish_date: '',
      duration_from: '',
      duration_to: '',
      attach_file: ''
    })
  }

  const [lists, setData] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    notice_id: 0,
    title: '',
    description: '',
    status: '',
    publish_date: '',
    duration_from: '',
    duration_to: '',
    attach_file: ''
  })
  const [dateFormat, setDateFormat] = useState("");

  const getDateFormat = async () => {
    try {
      const res = await axios.get<IAPI_Response<{dateFormat:string}>>(API_ENDPOINT.GET_DATE_FORMAT)
      const result = await res
      if (result?.data?.results) {
        setDateFormat(result?.data?.results?.dateFormat)

      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getNotice = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const res = await axios.get<IAPI_Response<INotice>>(API_ENDPOINT.GET_NOTICE, {
        params: {
          page: page_number,
        },
      })
      const result = await res
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showNotice = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_NOTICE+'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postNotice = async (value: any, {resetForm} : any) => {
    await axios.post(API_ENDPOINT.POST_NOTICE, {
      title: value.title,
      description: value.description,
      status: value.status,
      publish_date: value.publish_date,
      duration_from: value.duration_from,
      duration_to: value.duration_to
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.NOTICE.CREATED)
          getNotice(null);
       }
       resetForm({})
       handleCloseCreate();
    }).catch(() => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    })
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const updateNotice = async (value: any, {resetForm} : any) => {
    console.log(value)
    await axios.put(API_ENDPOINT.PUT_NOTICE +'/'+ value.notice_id, {
      title: value.title,
      description: value.description,
      status: value.status,
      publish_date: value.publish_date,
      duration_from: value.duration_from,
      duration_to: value.duration_to,
      attach_file: value.attach_file
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.NOTICE.UPDATED)
          getNotice(null);
       }
       resetForm({})
       handleCloseEdit();
    }).catch(() => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    })
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const deleteNotice = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_NOTICE +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.NOTICE.DELETED)
        getNotice(null);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getNotice(null)
      getDateFormat()
    })()
  }, [])
  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Notice</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='#'>
            Admin
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Notice</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <span className='fw-bold'>Notice List</span>
              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                <Button
                  onClick={handleShowCreate}
                  className=' add-new-btn rounded me-1 text-white'
                  variant='warning'
                  >
                  <EditIcon/>
                  &nbsp;Add New Notice
                </Button>
              </Col>
            </Row>
            <Table responsive className='bordered-tabs-top mt-3'>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>No.</th>
                  <th style={{ width: '20%' }}>Title</th>
                  <th style={{ width: '15%' }}>Published Date</th>
                  <th style={{ width: '15%' }}>Duration From</th>
                  <th style={{ width: '15%' }}>Duration To</th>
                  <th style={{ width: '15px' }}>Status</th>
                  <th className={'text-center'}>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {lists.length > 0 ? (
                    lists.map((item, index) => 
                      <tr className='mb-2' key={index}>
                        <td>{from + index}</td>
                        <td className='fw-bold'>{item.title}</td>
                        <td>{moment(new Date(item.publish_date ?? "")).format(dateFormat)}</td>
                        <td>{moment(new Date(item.duration_from ?? "")).format(dateFormat)}</td>
                        <td>{moment(new Date(item.duration_to ?? "")).format(dateFormat)}</td>
                        <td className='text-center'>
                          <div className='d-grid'>
                            {item.status == 'Published' ? (
                            <Button
                              className=' p-1 pt-0 pb-0'
                              style={{ fontSize: '12px', color: 'white' }}
                              variant=' btn-success'
                              size='sm'
                            > Published
                            </Button>
                            ) : (
                            <Button
                              className=' p-1 pt-0 pb-0'
                              style={{ fontSize: '12px', color: 'white' }}
                              variant=' btn-secondary'
                              size='sm'
                            > Unpublished
                            </Button>
                            )}
                          </div>
                        </td>
                        <td className='text-center'>
                          <Button
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <View/>
                          </Button>
                          <Button
                            onClick={() => showNotice(item.notice_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Write/>
                          </Button>
                          <Button
                            onClick={() => handleShowConfirm(item.notice_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Delete/>
                          </Button>
                        </td>
                        <br />
                      </tr>
                    )
                    ) : (
                    <tr className='no-item'>
                      <td colSpan={7}>{TEXT.NOT_FOUND}</td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>
            <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
              pageClicked={(page: number) => { 
                  getNotice(page);
                }
              }
              />

            <Dialog 
              show={showConfirm} 
              setShow={handleCloseConfirm}
              setEvent={() => { 
                  deleteNotice(item.notice_id)
                }
              }
            />

            <Loader show={showLoading}/>
          </Card.Body>
        </Card>

        {/* ADD MODAL*/}
        <Formik
          initialValues={
            { 
              title: '',
              description: '',
              status: '',
              publish_date: '',
              attach_file: '',
              duration_from: new Date().toISOString().substr(0, 10),
              duration_to:  new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().substr(0, 10)
            }
          }
          validationSchema={validationSchema}
          onSubmit={postNotice}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

          <Form id="noticeCreate" noValidate onSubmit={handleSubmit}>
            <Modal className='modal' show={showCreate} centered onHide={handleCloseCreate}>
              <Modal.Header closeButton>
                <Modal.Title className='fw-bold'>Add New Notice</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label className='text-muted required'>Title</Form.Label>
                    <Form.Control 
                      type='text' 
                      placeholder='Title'
                      size={'sm'} 
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.title && !!errors.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                    <Form.Label className='text-muted required'>Description</Form.Label>
                    <Form.Control 
                      as='textarea'
                      rows={3} 
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.description && !!errors.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label className='text-muted required'>Status</Form.Label>
                    <Form.Select 
                      size={'sm'}
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.status && !!errors.status}
                      >
                      <option value="">Select Status</option>
                      <option value="Published">Published</option>
                      <option value="Unpublished">Unpublished</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-2' controlId='exampleForm.ControlInput1'>
                    <Form.Label className='text-muted required'>Publish Date</Form.Label>
                    <InputGroup className='mb-2 ' size={'sm'}>
                      <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                        <Calendar/>
                      </InputGroup.Text>
                      <Form.Control 
                        size={'sm'}
                        type='date'
                        placeholder='Friday, June 03, 2022'
                        name="publish_date"
                        value={values.publish_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.publish_date && !!errors.publish_date}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.publish_date}
                        </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId='formFile' className='mb-3'>
                    <Form.Label className='text-muted'>Attached file</Form.Label>
                    <InputGroup className='mb-3' hasValidation size={'sm'}>
                      <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                        <Attached/>
                      </InputGroup.Text>
                      <Form.Control type='file' size={'sm'} />
                      <Form.Control.Feedback type='invalid'>
                        Please upload at least one file.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className='mb-2' controlId='exampleForm.ControlInput2'>
                    <Row>
                      <Col>
                        <Form.Label className='text-muted required'>Duration From</Form.Label>
                        <InputGroup className='mb-2 ' size={'sm'}>
                          <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                            <Calendar/>
                          </InputGroup.Text>
                          <Form.Control
                            size={'sm'} 
                            type='date' 
                            placeholder='Friday, June 03, 2022'
                            name="duration_from"
                            value={values.duration_from}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.duration_from && !!errors.duration_from}
                            />
                          <Form.Control.Feedback type="invalid">
                            {errors.duration_from}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                      <Col>
                        <Form.Label className='text-muted required'>Duration To</Form.Label>
                        <InputGroup className='mb-2 ' size={'sm'}>
                          <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                            <Calendar/>
                          </InputGroup.Text>
                          <Form.Control 
                            size={'sm'} 
                            type='date' 
                            placeholder='Friday, June 03, 2022'
                            name="duration_to"
                            value={values.duration_to}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.duration_to && !!errors.duration_to}
                            />
                          <Form.Control.Feedback type="invalid">
                            {errors.duration_to}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button 
                    type="submit"
                    disabled={isSubmitting}
                    form="noticeCreate" 
                    variant='primary text-white'
                    size={'sm'}
                    className='mx-2'
                    >
                    Save
                  </Button>
              </Modal.Footer>
            </Modal>
          </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
          initialValues={item}
          validationSchema={validationSchema}
          onSubmit={updateNotice}
          enableReinitialize
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

          <Form id="noticeUpdated" noValidate onSubmit={handleSubmit}>
            <Modal className='modal' show={showEdit} centered onHide={handleCloseEdit}>
              <Modal.Header closeButton>
                <Modal.Title className='fw-bold'>Edit Notice</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label className='text-muted required'>Title</Form.Label>
                  <Form.Control 
                    type='text' 
                    placeholder='Title'
                    size={'sm'} 
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.title && !!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                  <Form.Label className='text-muted required'>Description</Form.Label>
                  <Form.Control 
                    as='textarea'
                    rows={3} 
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.description && !!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label className='text-muted required'>Status</Form.Label>
                  <Form.Select 
                    size={'sm'}
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.status && !!errors.status}
                    >
                    <option value="">Select Status</option>
                    <option value="Published">Published</option>
                    <option value="Unpublished">Unpublished</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-2' controlId='exampleForm.ControlInput1'>
                  <Form.Label className='text-muted required'>Publish Date</Form.Label>
                  <InputGroup className='mb-2 ' size={'sm'}>
                    <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                      <Calendar/>
                    </InputGroup.Text>
                    <Form.Control 
                      size={'sm'}
                      type='date'
                      placeholder='Friday, June 03, 2022'
                      name="publish_date"
                      value={values.publish_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.publish_date && !!errors.publish_date}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.publish_date}
                      </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId='formFile' className='mb-3'>
                  <Form.Label className='text-muted'>Attached file</Form.Label>
                  <InputGroup className='mb-3' hasValidation size={'sm'}>
                    <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                      <Attached/>
                    </InputGroup.Text>
                    <Form.Control type='file' size={'sm'} />
                    <Form.Control.Feedback type='invalid'>
                      Please upload at least one file.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group className='mb-2' controlId='exampleForm.ControlInput2'>
                  <Row>
                    <Col>
                      <Form.Label className='text-muted required'>Duration From</Form.Label>
                      <InputGroup className='mb-2 ' size={'sm'}>
                        <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                          <Calendar/>
                        </InputGroup.Text>
                        <Form.Control
                          size={'sm'} 
                          type='date' 
                          placeholder='Friday, June 03, 2022'
                          name="duration_from"
                          value={values.duration_from}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.duration_from && !!errors.duration_from}
                          />
                        <Form.Control.Feedback type="invalid">
                          {errors.duration_from}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                    <Col>
                      <Form.Label className='text-muted required'>Duration To</Form.Label>
                      <InputGroup className='mb-2 ' size={'sm'}>
                        <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                          <Calendar/>
                        </InputGroup.Text>
                        <Form.Control 
                          size={'sm'} 
                          type='date' 
                          placeholder='Friday, June 03, 2022'
                          name="duration_to"
                          value={values.duration_to}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.duration_to && !!errors.duration_to}
                          />
                        <Form.Control.Feedback type="invalid">
                          {errors.duration_to}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Row>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseEdit}>
                  Cancel
                </Button>
                <Button 
                  type="submit"
                  disabled={isSubmitting}
                  form="noticeUpdated" 
                  variant='primary text-white'
                  size={'sm'}
                  className='mx-2'
                  >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}
