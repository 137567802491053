import React from 'react'

const Location = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13.237'
      height='15.956'
      viewBox='0 0 13.237 15.956'
    >
      <g id='Pin_Location' data-name='Pin Location' transform='translate(0.5 0.5)'>
        <g
          id='Icon_feather-map-pin'
          data-name='Icon feather-map-pin'
          transform='translate(-4.5 -1.5)'
        >
          <path
            id='Path_15'
            data-name='Path 15'
            d='M16.737,7.619c0,4.759-6.119,8.838-6.119,8.838S4.5,12.377,4.5,7.619a6.119,6.119,0,1,1,12.237,0Z'
            fill='none'
            stroke='#a7a9ac'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
          <path
            id='Path_16'
            data-name='Path 16'
            d='M20.383,13.942A3.442,3.442,0,1,1,16.942,10.5a3.442,3.442,0,0,1,3.442,3.442Z'
            transform='translate(-6.323 -5.973)'
            fill='none'
            stroke='#f26930'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
        </g>
      </g>
    </svg>
  )
}

export default Location
