import * as React from "react"
const PieChartIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox="0 0 512 512"
    >
        <g fill="#a7a9ac">
            <path d="M216.3 45.4C110.3 60.8 31.9 150.8 31.9 259.2c0 119.2 97 216.2 216.2 216.2 52 0 101.7-18.6 141-52.5L225.6 259.4c-6-6-9.3-14-9.3-22.5V45.4zM248 496.2c-130.7 0-237-106.3-237-237 0-123 92.3-224.5 214.7-235.9 2.9-.3 5.8.7 8 2.7s3.4 4.8 3.4 7.7V237c0 2.9 1.1 5.7 3.2 7.7l171.2 171.2c2 2 3.1 4.7 3.1 7.5s-1.2 5.5-3.2 7.4C367 473 309 496.2 248 496.2z" />
            <path d="M286.3 15.8c-3-.3-10.4.9-11.4 10.4v194.4c0 2.8 1.1 5.4 3.1 7.4l162.8 162.8c7.4 6.4 14.1 1.2 15.8-1.3 29-40.5 44.4-88.2 44.4-137.8 0-123-92.3-224.4-214.7-235.9zm160.3 351.4L295.7 216.3V38c105.9 15.5 184.4 105.4 184.4 213.8 0 41.1-11.5 80.7-33.5 115.4z" />
            <path d="M240.3 244.7c-2.1-2.1-3.2-4.8-3.2-7.7V33.6c-.8-9.3-8.5-10.7-11.4-10.4C103.3 34.7 11 136.1 11 259.2c0 130.7 106.3 237 237 237 60.9 0 118.9-23.3 163.3-65.5 2-1.9 6-8.1.2-14.9L240.3 244.7zm7.7 230.7c-119.2 0-216.2-97-216.2-216.2 0-108.4 78.5-198.3 184.4-213.8v191.5c0 8.5 3.3 16.5 9.3 22.5L389 422.8c-39.3 34-89 52.6-141 52.6z" />
        </g>
    </svg>
)
export default PieChartIcon
