import React, {useEffect, useState} from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Stack,
  Table,
} from 'react-bootstrap'
import './style.css'
import {
  EditOutlined,
  FilePresentOutlined,
  SaveAsOutlined,
  ViewInArOutlined,
} from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import axios from "../../../../api";
import {IAPI_Response} from "../../../../api/types";
import {
  IEmployee, IEmployeeList,
  IList,
  IPayrollComputation,
  IPayrollComputationSetting,
  IPayrollRange, IPayrollRangeEmployee,
  IPayrollRangeList
} from "../PayrollComputation/types";
import {API_ENDPOINT, ERROR_MESSAGE, PAYROLL_COMPUTATION, TEXT} from "../../../../utils/globalConstant";
import Pagination from 'components/Pagination'
import Alert from "components/Alert";
import Write from "asset/SvgComponent/Write";
import View from 'asset/SvgComponent/View'
import {IItem} from "../../../Administration/NoticeBoard/types";

export default function Index() {

  const [show, setShow] = React.useState(false)
  const [showUpdate, setShowUpdate] = React.useState(false)
  const [fileName, setFileName] = React.useState('Upload Boundary File')
  const handleClose = () => {
    setShow(false)
    setPayrollRange('');
  }
  const handleShow = () => {
    setPayrollRange('');
    setShow(true)
  }
  const handleUpdateClose = () => {
    setShowUpdate(false)
    setPayrollRange('');
  }
  const handleUpdateShow = () => setShowUpdate(true)
  const [showEdit, setShowEdit] = React.useState(false)
  const [showEmployeeList, setShowEmployeeList] = React.useState(false)
  const [showEmployeeListOnly, setShowEmployeeListOnly] = React.useState(false)
  const [showTransfer, setShowTransfer] = React.useState(false)
  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [success, setSuccess] = React.useState('')
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(6)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)
  const [currentPage2, setCurrentPage2] = React.useState(0)
  const [lastPage2, setLastPage2] = React.useState(0)
  const [itemsPerPage2, setPerPage2] = React.useState(6)
  const [itemsCount2, setItemCount2] = React.useState(0)
  const [from2, setFrom2] = React.useState(0)
  const [data, setData] = React.useState<IPayrollRangeList[]>([])
  const [data2, setData2] = React.useState<IEmployeeList[]>([])
  const [showLoading, setShowLoading] = React.useState(false)
  const weekdays = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
  const [used, setUsed] = React.useState(0)
  const [payrollRange, setPayrollRange] = React.useState('')
  const [payrollRangeData, setPayrollRangeData] = React.useState<IPayrollRangeList>({
    id: 0,
    payroll_schedule_type: "",
    first_payroll_cutoff: 0,
    second_payroll_cutoff: 0,
    cutoff_day: 0,
    isUsed: 0,
    payroll_range_name: "",
    assigned: 0,
    first_payout_date: 0,
    second_payout_date: 0,
    payout_date: 0
  })
  const [employees, setEmployees] = React.useState<IEmployee[]>([
    {
      employee_id: 0,
      first_name: '',
      last_name: '',
      payroll_range: ''
    }
  ])
  const [employeeChosen, setEmployeeChosen] = React.useState(0)
  const [payrollRangeChosen, setPayrollRangeChosen] = React.useState(0)
  const [employeeNewPayrollRange, setEmployeeNewPayrollRange] = React.useState(0)
  const [payrollRangeMemberText, setPayrollRangeMemberText] = React.useState('')
  const [employeeSelected, setEmployeeSelected] = React.useState(0)
  const [selectedEmployee, setSelectedEmployee] = React.useState<IEmployee[]>([])
  const [selectedPayrollRange, setSelectedPayrollRange] = React.useState("");
  const [usedPayrollRangeData, setUsedPayrollRangeData] = React.useState<IPayrollRangeList[]>([])
  const [disableAssign, setDisableAssign] = React.useState(true)
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [showUsedError, setShowUsedError] = React.useState(false)
  const [disableTransfer, setDisableTransfer] = React.useState(true)
  const [disableProceed, setDisableProceed] = React.useState(true)
  const [item, setItem] = useState<IPayrollRangeList>({
    id: 0,
    payroll_schedule_type: "",
    first_payroll_cutoff: 0,
    second_payroll_cutoff: 0,
    cutoff_day: 0,
    isUsed: 0,
    payroll_range_name: "",
    assigned: 0,
    first_payout_date: 0,
    second_payout_date: 0,
    payout_date: 0
  })

  const handleUpdatePayrollRangeName = (e:any) => {
    setItem({...item, payroll_range_name: e.target.value})
  }

  const handleShowUsedError = (id : any) => {
    setShowUsedError(true)
  }

  const handleCloseUsedError = () => {
    setShowUsedError(false)
  }

  const handleShowConfirmation = (id : any) => {
    setShowConfirmation(true)
    setPayrollRangeChosen(id)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmation(false)
  }
  const handleCloseEdit = async () => setShowEdit(false)
  const handleShowEdit = (id : any) => {
    setShowEdit(true)
    handleGetEmployees()
    setPayrollRangeChosen(id)
    handleGetUsedPayrollRange()
  }

  const handleCloseEmployeeList = () => {
    setShowEmployeeList(false)
    setEmployeeSelected(0)
    setSelectedEmployee([])
  }
  const handleShowEmployeeList = async (id : any) => {
    setShowEmployeeList(true)

    setPayrollRangeChosen(id)
    getEmployeesOnPayrollRange(null, id);
  }

  const handleCloseEmployeeListOnly = () => setShowEmployeeListOnly(false)
  const handleShowEmployeeListOnly = async (id : any) => {
    setShowEmployeeListOnly(true)

    setPayrollRangeChosen(id)
    getEmployeesOnPayrollRange(null, id);
  }

  const handleCloseTransfer = () => {
    setShowTransfer(false)
    setSelectedEmployee([])
  }
  const handleShowTransfer = async (id : any) => {
    setShowTransfer(true)

  }

  const handleViewList = async (id : number | null) => {
    setShowEmployeeListOnly(true)
    getEmployeesOnPayrollRange(null, id)

  }

  const showPayrollRange = async (id:number) => {
    try {
      const response = await axios.get<IAPI_Response<IPayrollRangeList>>(API_ENDPOINT.SHOW_PAYROLL_RANGE+'/'+ id)
      const result = await response
      if (result?.data?.results) {
        const item = result?.data?.results
        setItem(item)
        setPayrollRange(item.payroll_schedule_type.toLowerCase())
        setPayrollRangeChosen(id)
      }
      handleUpdateShow();
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);

  }

  const handleGetUsedPayrollRange = async () => {
    try {
      const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_USED_PAYROLL_RANGE)
      const result = await res
      if (result?.data?.results?.length) {
        setUsedPayrollRangeData(result?.data?.results)
      }else{
        setUsedPayrollRangeData([])
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const handleCheckboxChange = (id : any, e:any) => {
    data2.forEach((employee) => {
      if (employee.employee_id == id) {
        if (e.target.checked) {
          setDisableProceed(false)
          setSelectedEmployee(current => [...current, {employee_id: employee.employee_id, first_name: employee.first_name, last_name: employee.last_name, payroll_range: ""}])
          setEmployeeSelected(selectedEmployee.length)

        }else{
          if(employeeSelected == 1){
            setDisableProceed(true)
          }
          setSelectedEmployee(current => current.filter((employee) => employee.employee_id !== id));
          setEmployeeSelected(selectedEmployee.length)

        }


      }
    })

  }

  const handleCheckAllCheckBox = (e:any) => {
    if(e.target.checked){
      setDisableProceed(false)
      data2.forEach((employee) => {
        setSelectedEmployee(current => [...current, {employee_id: employee.employee_id, first_name: employee.first_name, last_name: employee.last_name, payroll_range: ""}])
        setEmployeeSelected(selectedEmployee.length)
      })
    }else{
      setDisableProceed(true)
      setSelectedEmployee([])
      setEmployeeSelected(selectedEmployee.length)
    }
  }

  const handleSubmitTransfer = async () => {
    await axios.put(API_ENDPOINT.TRANSFER_EMPLOYEE_PAYROLL_RANGE, {
      employee_id: selectedEmployee,
      payroll_range_id: employeeNewPayrollRange
    }).then(response => {

      if (response?.data?.status * 1 === 1) {
        setShow(false)
        setShowSuccess(true)
        setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_TRANSFERRED)
        setShowTransfer(false)
        getPayrollRange(null)
        setSelectedEmployee([])
      }
    }).catch(error => {
      setShowError(true)
      setError(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const handleChangeNewPayrollRange = async (e: any) => {
    const value = e.target.value;

    if(value != ""){
      setEmployeeNewPayrollRange(value);
      setDisableTransfer(false)

      try {
        const response = await axios.get<IAPI_Response<IPayrollRangeEmployee>>(API_ENDPOINT.ALL_EMPLOYEES_PAYROLL_RANGE+"/"+value, {
          params: {
            page: null,
          },
        })
        const result = await response

        if (result?.data?.results?.data?.length) {
          const employeesData = result?.data?.results?.data;
          if(employeesData.length != 0){
            setPayrollRangeMemberText(`${employeesData[employeesData.length - 1].first_name} ${employeesData[employeesData.length - 1].last_name} and ${employeesData.length - 1} others are assigned to this payroll range.`)
          }else{
            setPayrollRangeMemberText(`No employee assigned to this payroll range.`)
          }
        }else{
          setPayrollRangeMemberText(`No employee assigned to this payroll range.`)
        }

      }catch {

        setShowError(true)
        setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }
    }else{
      setDisableTransfer(true)
    }

  }

  const handleTransfer = async () => {

    setShowTransfer(true)
    setShowEmployeeList(false);
    setShowUsedError(false)
    handleGetUsedPayrollRange()

    data.forEach(payrollRange => {
      if(payrollRange.id == payrollRangeChosen){
        setSelectedPayrollRange(payrollRange.payroll_range_name!)
      }
    })

    if(selectedEmployee.length == 0){
      try {
        const response = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEES_PAYROLL_RANGE_NO_PAGINATION+"/"+payrollRangeChosen)
        const result = await response


        if (result?.data?.results?.length) {
           setSelectedEmployee(result?.data?.results)
        }
        setShowLoading(false)
      } catch {
        setShowError(true)
        setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }

      setTimeout(() => {
        setShowError(false)
      }, 3000);
    }

  }

  const handleAssignEmployee = async () => {
    await axios.post(API_ENDPOINT.ASSIGN_PAYROLL_RANGE, {
      employee_id: employeeChosen,
      payroll_range_id: payrollRangeChosen
    }).then(response => {

      if (response?.data?.status * 1 === 1) {
        setShow(false)
        setShowSuccess(true)
        setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_ASSIGNED)
        getPayrollRange(null)
        setShowEdit(false)
        // navigate('/employeeManagement/employee-list');
      }
    }).catch(error => {
      setShowError(true)
      setError(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);

  }

  const getEmployeesOnPayrollRange = async (page_number: null | number, id: null | number = 0) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IPayrollRangeEmployee>>(API_ENDPOINT.ALL_EMPLOYEES_PAYROLL_RANGE+"/"+id, {
        params: {
          page: page_number,
        },
      })
      const result = await response

      if (result?.data?.results?.data?.length) {
        setData2(result?.data?.results?.data)
        setCurrentPage2(result?.data?.results?.current_page)
        setLastPage2(result?.data?.results?.last_page)
        setPerPage2(result?.data?.results?.per_page)
        setItemCount2(result?.data?.results?.total)
        setFrom2(result?.data?.results?.from)
      }else{
        setData2([])
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleEmployeeChosen = (e : any) => {
    const value = e.target.value;
    if(value != ""){
      setEmployeeChosen(value)
      setDisableAssign(false)
    }else{
      setDisableAssign(true)
    }
  }

  const handleGetEmployees = async () => {
    try {
      const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_EMPLOYEE_UNASSIGNED)
      const result = await res

      if (result?.data?.results?.length) {
        setEmployees(result?.data?.results)
      }else{
        setEmployees([])
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const handleUpdatePayrollRange = (e : any) => {
    const value = e.target.value;
    if(value !== ""){
      if(value == 1){
        setPayrollRange('weekly');
        setItem({...item, payroll_schedule_type:'Weekly'})
      }else if(value == 2){
        setPayrollRange('bi-weekly');
        setItem({...item, payroll_schedule_type:'Bi-weekly'})
      }else if(value == 3){
        setPayrollRange('semi-monthly');
        setItem({...item, payroll_schedule_type:'Semi-monthly'})
      }else if(value == 4){
        setPayrollRange('monthly');
        setItem({...item, payroll_schedule_type:'Monthly'})
      }

    }else{
      setPayrollRange('')
    }
  }

  const handleChangePayrollRange = (e : any) => {
    const value = e.target.value;
    if(value !== ""){
      if(value == 1){
        setPayrollRange('weekly');
        payrollRangeData.payroll_schedule_type = 'Weekly'
      }else if(value == 2){
        setPayrollRange('bi-weekly');
        payrollRangeData.payroll_schedule_type = 'Bi-weekly'
      }else if(value == 3){
        setPayrollRange('semi-monthly');
        payrollRangeData.payroll_schedule_type = 'Semi-monthly'
      }else if(value == 4){
        setPayrollRange('monthly');
        payrollRangeData.payroll_schedule_type = 'Monthly'
      }


      setPayrollRangeData({...payrollRangeData})

    }else{
      setPayrollRange('')
    }
  }

  const getPayrollRange = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IPayrollRange>>(API_ENDPOINT.ALL_PAYROLL_RANGE, {
        params: {
          page: page_number,
        },
      })
      const result = await response

      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getCurrentPayrollRange = async () => {

    try {
      const response = await axios.get<IAPI_Response<IPayrollComputationSetting>>(API_ENDPOINT.CURRENT_PAYROLL_RANGE)
      const result = await response
      setShowLoading(false)
      setUsed(Number(result?.data?.results?.setting_value))
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const updatePayrollRange = async () => {
    let hasAssigned = false;
    data.forEach((payrollRange) => {
      if(payrollRange.id == payrollRangeChosen){
        if(payrollRange.assigned != 0){
          setShowConfirmation(false)
          setShowUsedError(true)
          hasAssigned = true;
          return false;
        }
      }
    })

    if(!hasAssigned){
      await axios.put(API_ENDPOINT.UPDATE_PAYROLL_RANGE +'/'+ payrollRangeChosen).then(response => {
        if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(PAYROLL_COMPUTATION.SUCCESSFULLY_USED)
          getPayrollRange(null)
          setShowConfirmation(false)

          setTimeout(() => {
            setShowSuccess(false)
          }, 2000);
        }
      })
    }

  }

  const addPayrollRange = async () => {
    await axios.post(API_ENDPOINT.POST_PAYROLL_RANGE, {
      payroll_range_name: payrollRangeData.payroll_range_name,
      payroll_schedule_type: payrollRangeData.payroll_schedule_type,
      first_payroll_cutoff: payrollRangeData.first_payroll_cutoff,
      second_payroll_cutoff: payrollRangeData.second_payroll_cutoff,
      cutoff_day: payrollRangeData.cutoff_day.toString(),
      payout_date: payrollRangeData.payout_date,
      first_payout_date: payrollRangeData.first_payout_date,
      second_payout_date: payrollRangeData.second_payout_date
    }).then(response => {

      if (response?.data?.status * 1 === 1) {
        setShow(false)
        setShowSuccess(true)
        setSuccess(PAYROLL_COMPUTATION.PAYROLL_SUCCESSFULLY_ADDED)
        getPayrollRange(null)
        // navigate('/employeeManagement/employee-list');
      }
    }).catch(error => {
      setShowError(true)
      setError(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);

  }

  const editPayrollRange = async () => {
    item.id = payrollRangeChosen
    await axios.put(API_ENDPOINT.EDIT_PAYROLL_RANGE, item).then(response => {

      if (response?.data?.status * 1 === 1) {
        setShow(false)
        setShowSuccess(true)
        setSuccess(PAYROLL_COMPUTATION.PAYROLL_SUCCESSFULLY_UPDATED)
        getPayrollRange(null)
        handleUpdateClose()
      }
    }).catch(error => {
      setShowError(true)
      setError(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);

  }

  const handleUpdateWeekly = async (e : any) => {
    item.cutoff_day = e.target.value
    item.first_payroll_cutoff = 0
    item.second_payroll_cutoff = 0
    setItem({...item})

  }
  const handleUpdateBiWeekly = async (e : any) => {
    item.cutoff_day = e.target.value
    item.first_payroll_cutoff = 0
    item.second_payroll_cutoff = 0
    setItem({...item})

  }
  const handleUpdateSemiMonthlyFirst = async (e : any) => {
    item.cutoff_day = 0
    item.first_payroll_cutoff = e.target.value
    setItem({...item})

  }
  const handleUpdateSemiMonthlySecond = async (e : any) => {
    item.cutoff_day = 0
    item.second_payroll_cutoff = e.target.value
    setItem({...item})

  }
  const handleUpdateMonthly = async (e : any) => {
    item.cutoff_day = 0
    item.first_payroll_cutoff = e.target.value
    setItem({...item})

  }

  const handleUpdateWeeklyPayout = async (e : any) => {
    item.payout_date = e.target.value
    item.first_payout_date = 0
    item.second_payout_date = 0
    setItem({...item})

  }
  const handleUpdateBiWeeklyPayout = async (e : any) => {
    item.payout_date = e.target.value
    item.first_payout_date = 0
    item.second_payout_date = 0
    setItem({...item})

  }
  const handleUpdateSemiMonthlyFirstPayout = async (e : any) => {
    item.payout_date = 0
    item.first_payout_date = e.target.value
    setItem({...item})

  }
  const handleUpdateSemiMonthlySecondPayout = async (e : any) => {
    item.payout_date = 0
    item.second_payout_date = e.target.value
    setItem({...item})

  }
  const handleUpdateMonthlyPayout = async (e : any) => {
    item.payout_date = 0
    item.first_payout_date = e.target.value
    setItem({...item})

  }

  const handleChangePayrollRangeName = async (e : any) => {
    payrollRangeData.payroll_range_name = e.target.value
    setPayrollRangeData({...payrollRangeData})

  }

  const handleChangeWeekly = async (e : any) => {
    payrollRangeData.cutoff_day = e.target.value
    payrollRangeData.first_payroll_cutoff = 0
    payrollRangeData.second_payroll_cutoff = 0
    setPayrollRangeData({...payrollRangeData})

  }
  const handleChangeBiWeekly = async (e : any) => {
    payrollRangeData.cutoff_day = e.target.value
    payrollRangeData.first_payroll_cutoff = 0
    payrollRangeData.second_payroll_cutoff = 0
    setPayrollRangeData({...payrollRangeData})

  }
  const handleChangeSemiMonthlyFirst = async (e : any) => {
    payrollRangeData.cutoff_day = 0
    payrollRangeData.first_payroll_cutoff = e.target.value
    setPayrollRangeData({...payrollRangeData})

  }
  const handleChangeSemiMonthlySecond = async (e : any) => {
    payrollRangeData.cutoff_day = 0
    payrollRangeData.second_payroll_cutoff = e.target.value
    setPayrollRangeData({...payrollRangeData})

  }
  const handleChangeMonthly = async (e : any) => {
    payrollRangeData.cutoff_day = 0
    payrollRangeData.first_payroll_cutoff = e.target.value
    setPayrollRangeData({...payrollRangeData})

  }

  const handleChangeWeeklyPayout = async (e : any) => {
    payrollRangeData.payout_date = e.target.value
    payrollRangeData.first_payout_date = 0
    payrollRangeData.second_payout_date = 0
    setPayrollRangeData({...payrollRangeData})

  }
  const handleChangeBiWeeklyPayout = async (e : any) => {
    payrollRangeData.payout_date = e.target.value
    payrollRangeData.first_payout_date = 0
    payrollRangeData.second_payout_date = 0
    setPayrollRangeData({...payrollRangeData})

  }
  const handleChangeSemiMonthlyFirstPayout = async (e : any) => {
    payrollRangeData.payout_date = 0
    payrollRangeData.first_payout_date = e.target.value
    setPayrollRangeData({...payrollRangeData})

  }
  const handleChangeSemiMonthlySecondPayout = async (e : any) => {
    payrollRangeData.payout_date = 0
    payrollRangeData.second_payout_date = e.target.value
    setPayrollRangeData({...payrollRangeData})

  }
  const handleChangeMonthlyPayout = async (e : any) => {
    payrollRangeData.payout_date = 0
    payrollRangeData.first_payout_date = e.target.value
    setPayrollRangeData({...payrollRangeData})

  }

  useEffect(() => {
    ;(async () => {
      setEmployeeSelected(selectedEmployee.length)
    })()
  }, [selectedEmployee])

  useEffect(() => {
    ;(async () => {
      getPayrollRange(null)
      getCurrentPayrollRange()
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Payroll Range</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/payroll-range'>
            Payroll
          </Breadcrumb.Item>
          <Breadcrumb.Item href='#'>Setup</Breadcrumb.Item>
          <Breadcrumb.Item active>Payroll Range</Breadcrumb.Item>
        </Breadcrumb>
        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <span className='fw-bold'>&nbsp;</span>
              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                <InputGroup size='sm' className={'w-25'}>
                  <Form.Control
                    aria-label='search'
                    aria-describedby='inputGroup-sizing-sm'
                    placeholder='Search...'
                  />
                  <InputGroup.Text className='bg-transparent search-input '>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
                <Button
                  onClick={handleShow}
                  className=' add-new-btn rounded mx-2 text-white'
                  variant='warning'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='17'
                    viewBox='0 0 20.055 20.207'
                  >
                    <g id='Edit-Icon' transform='translate(0.5 0.652)'>
                      <g id='Icon_feather-edit' data-name='Icon feather-edit'>
                        <path
                          id='Path_44'
                          data-name='Path 44'
                          d='M11.523,6H4.894A1.894,1.894,0,0,0,3,7.894V21.152a1.894,1.894,0,0,0,1.894,1.894H18.152a1.894,1.894,0,0,0,1.894-1.894V14.523'
                          transform='translate(-3 -3.991)'
                          fill='none'
                          stroke='#fff'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='1'
                        />
                        <path
                          id='Path_45'
                          data-name='Path 45'
                          d='M21.943,3.406a2.009,2.009,0,1,1,2.841,2.841l-9,9L12,16.191l.947-3.788Z'
                          transform='translate(-6.318 -2.818)'
                          fill='none'
                          stroke='#fff'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='1'
                        />
                      </g>
                    </g>
                  </svg>
                  &nbsp;Add Payroll Range
                </Button>{' '}
              </Col>
            </Row>
            {/* <hr/>*/}
            <Table responsive className='bordered-tabs-top mt-3'>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: '12%' }}>Payroll Range Name</th>
                  <th style={{ width: '12%' }}>Payroll Cutoff Type</th>
                  <th style={{ width: '22%' }}>Cutoff</th>
                  <th style={{ width: '20%' }}>Payout Date</th>
                  <th>Assigned</th>
                  <th>Actions</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
              {
                data.length > 0 ? (
                  data.map((payrollRange, index) => (
                      <tr className='mb-2' key={payrollRange.id}>
                        <td>{payrollRange.payroll_range_name}</td>
                        <td>{payrollRange.payroll_schedule_type}</td>
                        <td className='fw-bold '>{payrollRange.payroll_schedule_type == 'Monthly'? 'Every ' + payrollRange.first_payroll_cutoff + " of the month." :
                            payrollRange.payroll_schedule_type == 'Semi-monthly'? 'Every '+ payrollRange.first_payroll_cutoff + " and " + (payrollRange.second_payroll_cutoff==30 || payrollRange.second_payroll_cutoff==31 ?"last day":payrollRange.second_payroll_cutoff) + " of the month." :
                                payrollRange.payroll_schedule_type == 'Bi-weekly'? 'Every other ' + weekdays[payrollRange.cutoff_day] + ".":
                                    payrollRange.payroll_schedule_type == 'Weekly'? 'Every ' + weekdays[payrollRange.cutoff_day] + " of the week.": ""}</td>
                        <td>
                          {
                            payrollRange.payroll_schedule_type == 'Monthly'? 'Every ' + payrollRange.first_payout_date + " of the month." :
                              payrollRange.payroll_schedule_type == 'Semi-monthly'? 'Every '+ payrollRange.first_payout_date + " and " + (payrollRange.second_payout_date==30 || payrollRange.second_payout_date==31 ?"last day":payrollRange.second_payout_date) + " of the month." :
                                  payrollRange.payroll_schedule_type == 'Bi-weekly'? 'Every other ' + weekdays[payrollRange.payout_date] + ".":
                                      payrollRange.payroll_schedule_type == 'Weekly'? 'Every ' + weekdays[payrollRange.payout_date] + " of the week.": ""
                          }
                        </td>
                        <td className='text-center'
                            style={{cursor: 'pointer'}}
                            onClick={() => handleViewList(payrollRange.id)}
                            title={'Employee List'}
                        >{payrollRange.assigned}
                        </td>
                        <td className='text-center'>
                          <Button
                            onClick={() => handleShowEdit(payrollRange.id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            title={'Assign Employee'}
                            disabled={payrollRange.isUsed == 1 ? false: true}
                        >
                          <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16.143'
                              height='16.143'
                              viewBox='0 0 16.143 16.143'
                          >
                            <g id='New' transform='translate(0.5 0.5)'>
                              <g
                                  id='Icon_feather-plus-circle'
                                  data-name='Icon feather-plus-circle'
                                  transform='translate(-3 -3)'
                              >
                                <path
                                    id='Path_46'
                                    data-name='Path 46'
                                    d='M18.142,10.571A7.571,7.571,0,1,1,10.571,3,7.571,7.571,0,0,1,18.142,10.571Z'
                                    fill='none'
                                    stroke='#a7a9ac'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='1'
                                />
                                <path
                                    id='Path_47'
                                    data-name='Path 47'
                                    d='M18,12v6.057'
                                    transform='translate(-7.429 -4.457)'
                                    fill='none'
                                    stroke='#f26930'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='1'
                                />
                                <path
                                    id='Path_48'
                                    data-name='Path 48'
                                    d='M12,18h6.057'
                                    transform='translate(-4.457 -7.429)'
                                    fill='none'
                                    stroke='#f26930'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='1'
                                />
                              </g>
                            </g>
                          </svg>
                          </Button>
                          <Button
                              onClick={() => handleShowEmployeeList(payrollRange.id)}
                              className=' p-1 pt-0 pb-0'
                              style={{ fontSize: '12px', color: 'white' }}
                              variant=' btn-transparent'
                              size='sm'
                              title={'Transfer Employee'}
                              disabled={payrollRange.isUsed == 1 ? false: true}
                          >
                            <View/>
                          </Button>
                          <Button
                              onClick={() => showPayrollRange(payrollRange.id)}
                              className=' p-1 pt-0 pb-0'
                              style={{ fontSize: '12px', color: 'white' }}
                              variant=' btn-transparent'
                              size='sm'
                              title={'Edit Payroll Range'}
                          >
                            <Write/>
                          </Button>
                        </td>
                        <td className='text-center'>

                          <Button
                              className='pe-3 px-3 text-white'
                              style={{ fontSize: '12px', width: '70px' }}
                              // variant={used == payrollRange.id? "primary": "secondary"}
                              variant={payrollRange.isUsed == 1? "primary": "secondary"}
                              size='sm'

                              onClick={() => handleShowConfirmation(payrollRange.id)}
                          >
                            {/*{used == payrollRange.id? "Used": "Use"}*/}
                            {payrollRange.isUsed == 1? "Used": "Use"}
                          </Button>
                        </td>
                        <br />
                      </tr>
                  ))
                ) : (
                    <tr className='no-item'>
                      <td colSpan={7}>{TEXT.NOT_FOUND}</td>
                    </tr>
                )

              }


              </tbody>
            </Table>
            <Pagination
                itemsCount={itemsCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                lastPage={lastPage}
                setCurrentPage={setCurrentPage}
                alwaysShown={false}
                pageClicked={(page: number) => {
                  getPayrollRange(page);
                }
                }
            />
          </Card.Body>
        </Card>
        {/* ADD MODAL*/}
        <Modal className='modal' centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Add New Payroll Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className='mb-3'>
                <Form.Label column="sm" className="text-muted fw-bold">Payroll Range Name</Form.Label>
                <Form.Control size={'sm'} onChange={handleChangePayrollRangeName} type='text' placeholder='Payroll Range Name' autoFocus autoComplete={'off'} />
                <Form.Control.Feedback type="invalid" tooltip>
                  {/*{errors.marital_status}*/}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label column="sm" className="text-muted fw-bold">Type of Payroll range</Form.Label>
                <Form.Select
                    size={'sm'}
                    name="marital_status"
                    onChange={handleChangePayrollRange}
                    // onBlur={handleBlur}
                >
                  <option value="">Select Payroll Range</option>
                  <option value="1">Weekly</option>
                  <option value="2">Bi-weekly</option>
                  <option value="3">Semi-monthly</option>
                  <option value="4">Monthly</option>

                </Form.Select>


              </Form.Group>


              {
                payrollRange != "" ?
                    payrollRange == "weekly" ?
                        <Row>
                          <Col sm={6}>
                            <Form.Group >
                              <Form.Label className='text-muted fw-bold'>Select Cutoff</Form.Label>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label >
                                Every nth of the week. (Cutoff)
                              </Form.Label>
                              <Form.Select
                                  size={'sm'}
                                  name="week_day"
                                  onChange={handleChangeWeekly}
                                  // onBlur={handleBlur}
                              >
                                <option value="">Select Day</option>
                                <option value="0">Sunday</option>
                                <option value="1">Monday</option>
                                <option value="2">Tuesday</option>
                                <option value="3">Wednesday</option>
                                <option value="4">Thursday</option>
                                <option value="5">Friday</option>
                                <option value="6">Saturday</option>

                              </Form.Select>
                              <Form.Control.Feedback type="invalid" tooltip>
                                {/*{errors.marital_status}*/}
                              </Form.Control.Feedback>

                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group >
                              <Form.Label className='text-muted fw-bold'>Select Payout</Form.Label>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label >
                                Every nth of the week. (Payout)
                              </Form.Label>
                              <Form.Select
                                  size={'sm'}
                                  name="week_day_payout"
                                  onChange={handleChangeWeeklyPayout}
                                  // onBlur={handleBlur}
                              >
                                <option value="">Select Day</option>
                                <option value="0">Sunday</option>
                                <option value="1">Monday</option>
                                <option value="2">Tuesday</option>
                                <option value="3">Wednesday</option>
                                <option value="4">Thursday</option>
                                <option value="5">Friday</option>
                                <option value="6">Saturday</option>

                              </Form.Select>
                              <Form.Control.Feedback type="invalid" tooltip>
                                {/*{errors.marital_status}*/}
                              </Form.Control.Feedback>

                            </Form.Group>
                          </Col>
                        </Row>
                       :
                    payrollRange == "bi-weekly"?
                        <Row>
                          <Col sm={6}>
                            <Form.Group >
                              <Form.Label className='text-muted fw-bold'>Select Cutoff</Form.Label>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label >
                                Every other nth day. (Cutoff)
                              </Form.Label>
                              <Form.Select
                                  size={'sm'}
                                  name="bi_week_day"
                                  onChange={handleChangeBiWeekly}
                                  // onBlur={handleBlur}
                              >
                                <option value="">Select Day</option>
                                <option value="0">Sunday</option>
                                <option value="1">Monday</option>
                                <option value="2">Tuesday</option>
                                <option value="3">Wednesday</option>
                                <option value="4">Thursday</option>
                                <option value="5">Friday</option>
                                <option value="6">Saturday</option>

                              </Form.Select>
                              <Form.Control.Feedback type="invalid" tooltip>
                                {/*{errors.marital_status}*/}
                              </Form.Control.Feedback>

                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group >
                              <Form.Label className='text-muted fw-bold'>Select Payout</Form.Label>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label >
                                Every other nth day. (Payout)
                              </Form.Label>
                              <Form.Select
                                  size={'sm'}
                                  name="bi_week_day"
                                  onChange={handleChangeBiWeeklyPayout}
                                  // onBlur={handleBlur}
                              >
                                <option value="">Select Day</option>
                                <option value="0">Sunday</option>
                                <option value="1">Monday</option>
                                <option value="2">Tuesday</option>
                                <option value="3">Wednesday</option>
                                <option value="4">Thursday</option>
                                <option value="5">Friday</option>
                                <option value="6">Saturday</option>

                              </Form.Select>
                              <Form.Control.Feedback type="invalid" tooltip>
                                {/*{errors.marital_status}*/}
                              </Form.Control.Feedback>

                            </Form.Group>
                          </Col>
                        </Row>
                         :
                    payrollRange == "semi-monthly" ?
                        <>
                          <Row className='mb-3'>
                            <Col sm={6}>
                              <Form.Group >
                                <Form.Label className='text-muted fw-bold'>Select Cutoff</Form.Label>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label >
                                  First payroll cutoff.
                                </Form.Label>
                                <Form.Select
                                    size={'sm'}
                                    name="semi-monthly-1"
                                    onChange={handleChangeSemiMonthlyFirst}
                                    // onBlur={handleBlur}
                                >
                                  <option value="">Select Date</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                  <option value="28">28</option>
                                  <option value="29">29</option>
                                  <option value="30">30</option>
                                  <option value="31">Last day of the month.</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {/*{errors.marital_status}*/}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col sm={6}>
                              <Form.Group >
                                <Form.Label className='text-muted fw-bold'>&nbsp;</Form.Label>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label >
                                  Second payroll cutoff.
                                </Form.Label>
                                <Form.Select
                                    size={'sm'}
                                    name="semi-monthly-2"
                                    onChange={handleChangeSemiMonthlySecond}
                                    // onBlur={handleBlur}
                                >
                                  <option value="">Select Date</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                  <option value="28">28</option>
                                  <option value="29">29</option>
                                  <option value="30">30</option>
                                  <option value="31">Last day of the month.</option>

                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {/*{errors.marital_status}*/}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <Form.Group >
                                <Form.Label className='text-muted fw-bold'>Select Payout</Form.Label>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label >
                                  First Payout Date.
                                </Form.Label>
                                <Form.Select
                                    size={'sm'}
                                    name="semi-monthly-1"
                                    onChange={handleChangeSemiMonthlyFirstPayout}
                                    // onBlur={handleBlur}
                                >
                                  <option value="">Select Date</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                  <option value="28">28</option>
                                  <option value="29">29</option>
                                  <option value="30">30</option>
                                  <option value="31">Last day of the month.</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {/*{errors.marital_status}*/}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col sm={6}>
                              <Form.Group >
                                <Form.Label className='text-muted fw-bold'>&nbsp;</Form.Label>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label >
                                  Second Payout Date.
                                </Form.Label>
                                <Form.Select
                                    size={'sm'}
                                    name="semi-monthly-2"
                                    onChange={handleChangeSemiMonthlySecondPayout}
                                    // onBlur={handleBlur}
                                >
                                  <option value="">Select Date</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                  <option value="28">28</option>
                                  <option value="29">29</option>
                                  <option value="30">30</option>
                                  <option value="31">Last day of the month.</option>

                                </Form.Select>
                                <Form.Control.Feedback type="invalid" tooltip>
                                  {/*{errors.marital_status}*/}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        </>

                        :
                    payrollRange == "monthly" ?
                        <Row>
                          <Col sm={6}>
                            <Form.Group >
                              <Form.Label className='text-muted fw-bold'>Select Cutoff</Form.Label>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label >
                                Every nth of the month. (Cutoff)
                              </Form.Label>
                              <Form.Select
                                  size={'sm'}
                                  name="monthly"
                                  onChange={handleChangeMonthly}
                                  // onBlur={handleBlur}
                              >
                                <option value="">Select Date</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">Last day of the month.</option>

                              </Form.Select>
                              <Form.Control.Feedback type="invalid" tooltip>
                                {/*{errors.marital_status}*/}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group >
                              <Form.Label className='text-muted fw-bold'>Select Payout</Form.Label>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label >
                                Every nth of the month. (Payout)
                              </Form.Label>
                              <Form.Select
                                  size={'sm'}
                                  name="monthly"
                                  onChange={handleChangeMonthlyPayout}
                                  // onBlur={handleBlur}
                              >
                                <option value="">Select Date</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">Last day of the month.</option>

                              </Form.Select>
                              <Form.Control.Feedback type="invalid" tooltip>
                                {/*{errors.marital_status}*/}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        : "" : ""
              }



            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={addPayrollRange}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* EDIT MODAL*/}
        <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Assign Employee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>

                <Form.Group as={Col} md="8" className="position-relative">
                  <Form.Label column="sm" className="required">Employees</Form.Label>
                  <Form.Select
                      size={'sm'}
                      name="marital_status"
                      onChange={handleEmployeeChosen}
                      // onBlur={handleBlur}
                  >

                    {employees.length > 0 ?
                      (<>
                        <option value="" disabled={true} selected >Select Employee</option>
                        <option value="0" >All Employees</option>
                          {employees.map((employee, index) => (
                          <option value={employee.employee_id??0} key={index}>{employee.first_name} {employee.last_name}</option>
                        ))}
                      </>)
                    : (<option value={0} selected key={1} disabled={true}>No unassigned employees.</option>)}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {/*{errors.marital_status}*/}
                  </Form.Control.Feedback>

                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Button
                      onClick={handleAssignEmployee}
                      type="button"
                      disabled={disableAssign}
                      variant='primary text-white'
                      style={{ width: '50%',marginBottom: '-70px' }}
                      size="sm">
                    Assign
                  </Button>
                </Form.Group>
              </Row>
              <br/>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
              Cancel
            </Button>
            <Button
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
              onClick={handleCloseEdit}
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>

        {/* EMPLOYEE LIST WITH TRANSFER MODAL*/}
        <Modal className='modal' centered show={showEmployeeList} onHide={handleCloseEmployeeList}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Employee List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group style={{textAlign:'right'}}>
              <Form.Label column="sm" >{employeeSelected} selected</Form.Label>
            </Form.Group>

            <Table responsive className='bordered-tabs-top mt-3'>
              <thead>
              <tr className='text-center'>
                <th style={{ width: '10%' }}>
                  <Form.Group>
                    <Form.Check
                        // label={menu.name}
                        name={"all_employees"}
                        value={"all_employees"}
                        id={"all_employees"}
                        // checked={menu.isChecked === 1}
                        onClick={handleCheckAllCheckBox}
                        // isInvalid={touched.gender && !!errors.gender}
                    />
                  </Form.Group>
                </th>
                <th style={{ width: '55%' }}>Name</th>
                <th style={{ width: '35%' }}>Designation</th>
              </tr>
              </thead>
              <tbody>
              {
                data2.length > 0 ? (
                    data2.map((employee, index) => (
                        <tr className='mb-2' key={employee.id}>
                          <td>
                            <Form.Group key={employee.id}>
                              <Form.Check
                                  // label={menu.name}
                                  name={employee?.id?.toString()}
                                  value={employee.id?.toString()}
                                  id={employee?.id?.toString()}
                                  // checked={menu.isChecked === 1}
                                  onClick={(event) => handleCheckboxChange(employee.employee_id, event)}
                                  // isInvalid={touched.gender && !!errors.gender}
                              />
                            </Form.Group>
                          </td>
                          <td >{employee.first_name + " " + employee.last_name}</td>
                          <td>{employee.designation_name}</td>
                        </tr>
                    ))
                ) : (
                    <tr className='no-item'>
                      <td colSpan={3}>{TEXT.NOT_FOUND}</td>
                    </tr>
                )

              }


              </tbody>
            </Table>
            <Pagination
                itemsCount={itemsCount2}
                itemsPerPage={itemsPerPage2}
                currentPage={currentPage2}
                lastPage={lastPage2}
                setCurrentPage={setCurrentPage2}
                alwaysShown={false}
                pageClicked={(page: number) => {
                  getEmployeesOnPayrollRange(page, payrollRangeChosen);
                }
                }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleCloseEmployeeList}>
              Cancel
            </Button>
            <Button
                variant='primary text-white'
                size={'sm'}
                className='mx-2'
                onClick={handleTransfer}
                disabled={disableProceed}
            >
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>

        {/* EMPLOYEE LIST ONLY MODAL*/}
        <Modal className='modal' centered show={showEmployeeListOnly} onHide={handleCloseEmployeeListOnly}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Employee List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive className='bordered-tabs-top mt-3'>
              <thead>
              <tr className='text-center'>
                <th style={{ width: '60%' }}>Name</th>
                <th style={{ width: '40%' }}>Designation</th>
              </tr>
              </thead>
              <tbody>
              {
                data2.length > 0 ? (
                    data2.map((employee, index) => (
                        <tr className='mb-2' key={employee.id}>
                          <td>{employee.first_name + " " + employee.last_name}</td>
                          <td>{employee.designation_name}</td>
                        </tr>
                    ))
                ) : (
                    <tr className='no-item'>
                      <td colSpan={2}>{TEXT.NOT_FOUND}</td>
                    </tr>
                )

              }


              </tbody>
            </Table>
            <Pagination
                itemsCount={itemsCount2}
                itemsPerPage={itemsPerPage2}
                currentPage={currentPage2}
                lastPage={lastPage2}
                setCurrentPage={setCurrentPage2}
                alwaysShown={false}
                pageClicked={(page: number) => {
                  getEmployeesOnPayrollRange(page, payrollRangeChosen);
                }
                }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
                variant='primary text-white'
                size={'sm'}
                className='mx-2'
                onClick={handleCloseEmployeeListOnly}
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>

        {/* TRANSFER MODAL*/}
        <Modal className='modal' centered show={showTransfer} onHide={handleCloseTransfer}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Transfer Employee</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Group className='mb-3'>
              <Form.Label column="sm" className="text-muted fw-bold">From</Form.Label>
              <Form.Control type='text' placeholder='Department name' style={{width: '50%'}} autoFocus value={selectedPayrollRange} disabled={true}/>

            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label column="sm" className="text-muted fw-bold">To</Form.Label>
              <Form.Select
                  size={'sm'}
                  name="marital_status"
                  onChange={handleChangeNewPayrollRange}
                  style={{width: '50%'}}
                  // onBlur={handleBlur}
              >
                <option value="">Select Payroll Range</option>
                {usedPayrollRangeData.map((payroll_range, index) => (
                    <option value={payroll_range.id} key={index}>{payroll_range.payroll_range_name}</option>
                ))}

              </Form.Select>

            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label column="sm" className="text-muted fw-bold">{payrollRangeMemberText}</Form.Label>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label column="sm" className="text-muted fw-bold">Transfer?</Form.Label>
              <Row>
                <Form.Group as={Col} md="6">
                  <ul>
                    {(selectedEmployee.slice(0, Math.ceil(selectedEmployee.length / 2))).map((emp, index) => (
                        <li key={index}>{emp.first_name} {emp.last_name}</li>

                    ))}
                  </ul>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <ul>
                    {(selectedEmployee.slice(Math.ceil(selectedEmployee.length / 2), selectedEmployee.length)).map((emp, index) => (

                        <li key={index}>{emp.first_name} {emp.last_name}</li>
                    ))}
                  </ul>
                </Form.Group>
              </Row>

            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleCloseTransfer}>
              Cancel
            </Button>
            <Button
                variant='primary text-white'
                size={'sm'}
                className='mx-2'
                onClick={handleSubmitTransfer}
                disabled={disableTransfer}
            >
              Transfer
            </Button>
          </Modal.Footer>
        </Modal>

        {/* USED/UNUSED CONFIRMATION MODAL */}
        <Modal className='modal' centered show={showConfirmation} onHide={handleCloseConfirmation}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group style={{textAlign:'center'}}>
              <Form.Label column="sm" >Are you sure you want to proceed?</Form.Label>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleCloseConfirmation}>
              Cancel
            </Button>
            <Button
                variant='primary text-white'
                size={'sm'}
                className='mx-2'
                onClick={updatePayrollRange}
            >
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>

        {/* USED/UNUSED ERROR MODAL */}
        <Modal className='modal' centered show={showUsedError} onHide={handleCloseUsedError}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group style={{textAlign:'center'}}>
              <Form.Label column="sm" >Please transfer all employees to another Payroll Range Name. Do you want to transfer now?</Form.Label>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleCloseUsedError}>
              Cancel
            </Button>
            <Button
                variant='primary text-white'
                size={'sm'}
                className='mx-2'
                onClick={handleTransfer}
            >
              Transfer
            </Button>
          </Modal.Footer>
        </Modal>


        {/* UPDATE PAYROLL RANGE MODAL*/}
        <Modal className='modal' centered show={showUpdate} onHide={handleUpdateClose}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Edit Payroll Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className='mb-3'>
                <Form.Label column="sm" className="text-muted fw-bold">Payroll Range Name</Form.Label>
                <Form.Control size={'sm'} onChange={handleUpdatePayrollRangeName} type='text' placeholder='Payroll Range Name' autoFocus autoComplete={'off'} value={item.payroll_range_name}/>
                <Form.Control.Feedback type="invalid" tooltip>
                  {/*{errors.marital_status}*/}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label column="sm" className="text-muted fw-bold">Type of Payroll range</Form.Label>
                <Form.Select
                    size={'sm'}
                    name="marital_status"
                    onChange={handleUpdatePayrollRange}
                    // onBlur={handleBlur}
                >
                  <option value="">Select Payroll Range</option>
                  <option value="1" selected={item.payroll_schedule_type == 'Weekly'? true : false}>Weekly</option>
                  <option value="2" selected={item.payroll_schedule_type == 'Bi-weekly'? true : false}>Bi-weekly</option>
                  <option value="3" selected={item.payroll_schedule_type == 'Semi-monthly'? true : false}>Semi-monthly</option>
                  <option value="4" selected={item.payroll_schedule_type == 'Monthly'? true : false}>Monthly</option>

                </Form.Select>


              </Form.Group>

              {
                payrollRange != "" ?
                    payrollRange == "weekly" ?
                        <Row>
                          <Col sm={6}>
                            <Form.Group >
                              <Form.Label className='text-muted fw-bold'>Select Cutoff</Form.Label>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label >
                                Every nth of the week. (Cutoff)
                              </Form.Label>
                              <Form.Select
                                  size={'sm'}
                                  name="week_day"
                                  onChange={handleUpdateWeekly}
                                  value={item.cutoff_day}
                                  // onBlur={handleBlur}
                              >
                                <option value="">Select Day</option>
                                <option value="0">Sunday</option>
                                <option value="1">Monday</option>
                                <option value="2">Tuesday</option>
                                <option value="3">Wednesday</option>
                                <option value="4">Thursday</option>
                                <option value="5">Friday</option>
                                <option value="6">Saturday</option>

                              </Form.Select>
                              <Form.Control.Feedback type="invalid" tooltip>
                                {/*{errors.marital_status}*/}
                              </Form.Control.Feedback>

                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group >
                              <Form.Label className='text-muted fw-bold'>Select Payout</Form.Label>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label >
                                Every nth of the week. (Payout)
                              </Form.Label>
                              <Form.Select
                                  size={'sm'}
                                  name="week_day"
                                  onChange={handleUpdateWeeklyPayout}
                                  value={item.payout_date}
                                  // onBlur={handleBlur}
                              >
                                <option value="">Select Day</option>
                                <option value="0">Sunday</option>
                                <option value="1">Monday</option>
                                <option value="2">Tuesday</option>
                                <option value="3">Wednesday</option>
                                <option value="4">Thursday</option>
                                <option value="5">Friday</option>
                                <option value="6">Saturday</option>

                              </Form.Select>
                              <Form.Control.Feedback type="invalid" tooltip>
                                {/*{errors.marital_status}*/}
                              </Form.Control.Feedback>

                            </Form.Group>
                          </Col>
                        </Row>
                        :
                        payrollRange == "bi-weekly"?
                            <Row>
                              <Col sm={6}>
                                <Form.Group >
                                  <Form.Label className='text-muted fw-bold'>Select Cutoff</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label >
                                    Every other nth day. (Cutoff)
                                  </Form.Label>
                                  <Form.Select
                                      size={'sm'}
                                      name="bi_week_day"
                                      onChange={handleUpdateBiWeekly}
                                      value={item.cutoff_day}
                                      // onBlur={handleBlur}
                                  >
                                    <option value="">Select Day</option>
                                    <option value="0">Sunday</option>
                                    <option value="1">Monday</option>
                                    <option value="2">Tuesday</option>
                                    <option value="3">Wednesday</option>
                                    <option value="4">Thursday</option>
                                    <option value="5">Friday</option>
                                    <option value="6">Saturday</option>

                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {/*{errors.marital_status}*/}
                                  </Form.Control.Feedback>

                                </Form.Group>
                              </Col>
                              <Col sm={6}>
                                <Form.Group >
                                  <Form.Label className='text-muted fw-bold'>Select Payout</Form.Label>
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label >
                                    Every other nth day. (Payout)
                                  </Form.Label>
                                  <Form.Select
                                      size={'sm'}
                                      name="bi_week_day"
                                      onChange={handleUpdateBiWeeklyPayout}
                                      value={item.payout_date}
                                      // onBlur={handleBlur}
                                  >
                                    <option value="">Select Day</option>
                                    <option value="0">Sunday</option>
                                    <option value="1">Monday</option>
                                    <option value="2">Tuesday</option>
                                    <option value="3">Wednesday</option>
                                    <option value="4">Thursday</option>
                                    <option value="5">Friday</option>
                                    <option value="6">Saturday</option>

                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {/*{errors.marital_status}*/}
                                  </Form.Control.Feedback>

                                </Form.Group>
                              </Col>
                            </Row>
                            :
                            payrollRange == "semi-monthly" ?
                                <>
                                  <Row className='mb-3'>
                                    <Col sm={6}>
                                      <Form.Group >
                                        <Form.Label className='text-muted fw-bold'>Select Cutoff</Form.Label>
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Label >
                                          First payroll cutoff.
                                        </Form.Label>
                                        <Form.Select
                                            size={'sm'}
                                            name="semi-monthly-1"
                                            onChange={handleUpdateSemiMonthlyFirst}
                                            value={item.first_payroll_cutoff}
                                            // onBlur={handleBlur}
                                        >
                                          <option value="">Select Date</option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                          <option value="9">9</option>
                                          <option value="10">10</option>
                                          <option value="11">11</option>
                                          <option value="12">12</option>
                                          <option value="13">13</option>
                                          <option value="14">14</option>
                                          <option value="15">15</option>
                                          <option value="16">16</option>
                                          <option value="17">17</option>
                                          <option value="18">18</option>
                                          <option value="19">19</option>
                                          <option value="20">20</option>
                                          <option value="21">21</option>
                                          <option value="22">22</option>
                                          <option value="23">23</option>
                                          <option value="24">24</option>
                                          <option value="25">25</option>
                                          <option value="26">26</option>
                                          <option value="27">27</option>
                                          <option value="28">28</option>
                                          <option value="29">29</option>
                                          <option value="30">30</option>
                                          <option value="31">Last day of the month.</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid" tooltip>
                                          {/*{errors.marital_status}*/}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                      <Form.Group >
                                        <Form.Label className='text-muted fw-bold'>&nbsp;</Form.Label>
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Label >
                                          Second payroll cutoff.
                                        </Form.Label>
                                        <Form.Select
                                            size={'sm'}
                                            name="semi-monthly-2"
                                            onChange={handleUpdateSemiMonthlySecond}
                                            value={item.second_payroll_cutoff}
                                            // onBlur={handleBlur}
                                        >
                                          <option value="">Select Date</option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                          <option value="9">9</option>
                                          <option value="10">10</option>
                                          <option value="11">11</option>
                                          <option value="12">12</option>
                                          <option value="13">13</option>
                                          <option value="14">14</option>
                                          <option value="15">15</option>
                                          <option value="16">16</option>
                                          <option value="17">17</option>
                                          <option value="18">18</option>
                                          <option value="19">19</option>
                                          <option value="20">20</option>
                                          <option value="21">21</option>
                                          <option value="22">22</option>
                                          <option value="23">23</option>
                                          <option value="24">24</option>
                                          <option value="25">25</option>
                                          <option value="26">26</option>
                                          <option value="27">27</option>
                                          <option value="28">28</option>
                                          <option value="29">29</option>
                                          <option value="30">30</option>
                                          <option value="31">Last day of the month.</option>

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid" tooltip>
                                          {/*{errors.marital_status}*/}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={6}>
                                      <Form.Group >
                                        <Form.Label className='text-muted fw-bold'>Select Payout</Form.Label>
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Label >
                                          First Payout Date.
                                        </Form.Label>
                                        <Form.Select
                                            size={'sm'}
                                            name="semi-monthly-1"
                                            onChange={handleUpdateSemiMonthlyFirstPayout}
                                            value={item.first_payout_date}
                                            // onBlur={handleBlur}
                                        >
                                          <option value="">Select Date</option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                          <option value="9">9</option>
                                          <option value="10">10</option>
                                          <option value="11">11</option>
                                          <option value="12">12</option>
                                          <option value="13">13</option>
                                          <option value="14">14</option>
                                          <option value="15">15</option>
                                          <option value="16">16</option>
                                          <option value="17">17</option>
                                          <option value="18">18</option>
                                          <option value="19">19</option>
                                          <option value="20">20</option>
                                          <option value="21">21</option>
                                          <option value="22">22</option>
                                          <option value="23">23</option>
                                          <option value="24">24</option>
                                          <option value="25">25</option>
                                          <option value="26">26</option>
                                          <option value="27">27</option>
                                          <option value="28">28</option>
                                          <option value="29">29</option>
                                          <option value="30">30</option>
                                          <option value="31">Last day of the month.</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid" tooltip>
                                          {/*{errors.marital_status}*/}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                      <Form.Group >
                                        <Form.Label className='text-muted fw-bold'>&nbsp;</Form.Label>
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Label >
                                          Second Payout Date.
                                        </Form.Label>
                                        <Form.Select
                                            size={'sm'}
                                            name="semi-monthly-2"
                                            onChange={handleUpdateSemiMonthlySecondPayout}
                                            value={item.second_payout_date}
                                            // onBlur={handleBlur}
                                        >
                                          <option value="">Select Date</option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                          <option value="9">9</option>
                                          <option value="10">10</option>
                                          <option value="11">11</option>
                                          <option value="12">12</option>
                                          <option value="13">13</option>
                                          <option value="14">14</option>
                                          <option value="15">15</option>
                                          <option value="16">16</option>
                                          <option value="17">17</option>
                                          <option value="18">18</option>
                                          <option value="19">19</option>
                                          <option value="20">20</option>
                                          <option value="21">21</option>
                                          <option value="22">22</option>
                                          <option value="23">23</option>
                                          <option value="24">24</option>
                                          <option value="25">25</option>
                                          <option value="26">26</option>
                                          <option value="27">27</option>
                                          <option value="28">28</option>
                                          <option value="29">29</option>
                                          <option value="30">30</option>
                                          <option value="31">Last day of the month.</option>

                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid" tooltip>
                                          {/*{errors.marital_status}*/}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </>


                                :
                                payrollRange == "monthly" ?
                                    <Row>
                                      <Col sm={6}>
                                        <Form.Group >
                                          <Form.Label className='text-muted fw-bold'>Select Cutoff</Form.Label>
                                        </Form.Group>
                                        <Form.Group>
                                          <Form.Label >
                                            Every nth of the month. (Cutoff)
                                          </Form.Label>
                                          <Form.Select
                                              size={'sm'}
                                              name="monthly"
                                              onChange={handleUpdateMonthly}
                                              value={item.first_payroll_cutoff}
                                              // onBlur={handleBlur}
                                          >
                                            <option value="">Select Date</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">Last day of the month.</option>

                                          </Form.Select>
                                          <Form.Control.Feedback type="invalid" tooltip>
                                            {/*{errors.marital_status}*/}
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={6}>
                                        <Form.Group >
                                          <Form.Label className='text-muted fw-bold'>Select Payout</Form.Label>
                                        </Form.Group>
                                        <Form.Group>
                                          <Form.Label >
                                            Every nth of the month. (Payout)
                                          </Form.Label>
                                          <Form.Select
                                              size={'sm'}
                                              name="monthly"
                                              onChange={handleUpdateMonthlyPayout}
                                              value={item.first_payout_date}
                                              // onBlur={handleBlur}
                                          >
                                            <option value="">Select Date</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                            <option value="24">24</option>
                                            <option value="25">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                            <option value="28">28</option>
                                            <option value="29">29</option>
                                            <option value="30">30</option>
                                            <option value="31">Last day of the month.</option>

                                          </Form.Select>
                                          <Form.Control.Feedback type="invalid" tooltip>
                                            {/*{errors.marital_status}*/}
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                    : "" : ""
              }



            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleUpdateClose}>
              Cancel
            </Button>
            <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={editPayrollRange}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Alert
            type="success"
            show={showSuccess}
            message={success}
            setShow={() => {
              setShowSuccess(false);
            }
            }
        />

        <Alert
            type="error"
            show={showError}
            message={error}
            setShow={() => {
              setShowError(false);
            }
            }
        />

        <br />
      </Container>
    </>
  )
}
