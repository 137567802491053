import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ICompanyValue } from './types'

const initialState: ICompanyValue = {
  company_email: '',
  employee_no: '',
  finger_id: '',
  supervisor_id: '',
  department_id: '',
  designation_id: '',
  job_level_id: '',
  branch_id: '',
  date_of_joining: '',
  date_of_leaving: '',
  date_of_regularization: '',
  reason_of_leaving: '',
  status: '',
  approvers_order: "",
  employee_type: '',
  workflow: [{
    level: 1,
    approvers_order: '',
    approvers: [{
      approver_employee_id: ''
    }]
  }],
  leave_credits: [{
    id: 0,
    employee_id: 0,
    leave_type: 0,
    credits: ""
  }],
  company_documents: "",
  custom_1: "",
  custom_2: "",
  custom_3: "",
  custom_fields: [{
    name: "",
    value: ""
  }],
  team_id: ''
}

export const CompanySlice = createSlice({
  name: 'Company',
  initialState,
  reducers: {
    editCompany: (_state, action: PayloadAction<ICompanyValue>) => action.payload,
  },
})

export const { editCompany } = CompanySlice.actions
export default CompanySlice.reducer
