import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IFormInitialValue } from './types'

const initialState: IFormInitialValue = {
    full_name: "",
    email_address: ''
}

export const Step1Slice = createSlice({
    name: 'Step1',
    initialState,
    reducers: {
        addStep1: (_state, action: PayloadAction<IFormInitialValue>) => action.payload,
    },
})

export const { addStep1 } = Step1Slice.actions
export default Step1Slice.reducer
