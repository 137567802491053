import { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
  Stack
} from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Accept from 'asset/SvgComponent/Accept'
import Reject from 'asset/SvgComponent/Reject'
import View from 'asset/SvgComponent/View'
import Delete from 'asset/SvgComponent/Delete'
import Write from 'asset/SvgComponent/Write'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Calendar from 'asset/SvgComponent/Calendar'
import Clock from 'asset/SvgComponent/Clock'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Avatar } from '@mui/material'

import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import { Formik } from 'formik';
import { validationSchema } from './validationSchema'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { IOvertime, IList, IItem, IItemRequest, IOvertimeRequest, IListRequest } from './types'
import moment from "moment"

export default function Index() {
  const [key, setKey] = useState('overtime-application')
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [currentPage1, setCurrentPage1] = useState(0)
  const [lastPage1, setLastPage1] = useState(0)
  const [itemsPerPage1, setPerPage1] = useState(10)
  const [itemsCount1, setItemCount1] = useState(0)
  const [from1, setFrom1] = useState(0)

  const [showCreate, setShowCreate] = useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)

  const [showEdit, setShowEdit] = useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      id: id,
      employee_id: 0,
      classification: '',
      overtime_date: '',
      overtime_start: '',
      overtime_end: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: ''
    })
  }

  const [showDetail, setShowDetail] = useState(false)
  const handleCloseDetail = () => setShowDetail(false)
  const handleShowDetail = () => setShowDetail(true)

  const [showAccept, setShowAccept] = useState(false)
  const handleCloseAccept = () => setShowAccept(false)
  const handleShowAccept = (id: any) => {
    setShowAccept(true)
    setItemRequest({
      id: id,
      employee_id: 0,
      classification: '',
      overtime_date: '',
      overtime_start: '',
      overtime_end: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        }
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          }
        }
      }]
    })
  }

  const [showReject, setShowReject] = useState(false)
  const handleCloseReject = () => setShowReject(false)
  const handleShowReject = (id: any) => {
    setShowReject(true)
    setItemRequest({
      id: id,
      employee_id: 0,
      classification: '',
      overtime_date: '',
      overtime_start: '',
      overtime_end: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        }
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          }
        }
      }]
    })
  }

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const [lists, setData] = useState<IList[]>([])
  const [requests, setRequest] = useState<IListRequest[]>([])
  const [item, setItem] = useState<IItem>({
    id: 0,
    employee_id: 0,
    classification: '',
    overtime_date: '',
    overtime_start: '',
    overtime_end: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: ''
  })
  const [itemRequest, setItemRequest] = useState<IItemRequest>({
    id: 0,
    employee_id: 0,
    classification: '',
    overtime_date: '',
    overtime_start: '',
    overtime_end: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      }
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        }
      }
    }]
  })
  const [dateFormat, setDateFormat] = useState('')

  const getOvertime = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IOvertime>>(API_ENDPOINT.GET_OVERTIME, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showOvertime = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_OVERTIME +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postOvertime = async (value: any, {resetForm} : any) => {
    await axios.post(API_ENDPOINT.POST_OVERTIME, {
      overtime_date: value.overtime_date,
      overtime_start: value.overtime_start,
      overtime_end: value.overtime_end,
      classification: value.classification,
      reason: value.reason,
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OVERTIME.CREATED)
          getOvertime(null);
       }
       resetForm({})
       handleCloseCreate();
    });

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const updateOvertime = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_OVERTIME +'/'+ value.id, {
      overtime_date: value.overtime_date,
      overtime_start: value.overtime_start,
      overtime_end: value.overtime_end,
      classification: value.classification,
      reason: value.reason,
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OVERTIME.UPDATED)
          getOvertime(null);
       }
       resetForm({})
       handleCloseEdit();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const deleteOvertime = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_OVERTIME +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.OVERTIME.DELETED)
        getOvertime(null);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getOvertimeRequest = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IOvertimeRequest>>(API_ENDPOINT.GET_OVERTIME_REQUEST, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setRequest(result?.data?.results?.data)
        setCurrentPage1(result?.data?.results?.current_page)
        setLastPage1(result?.data?.results?.last_page)
        setPerPage1(result?.data?.results?.per_page)
        setItemCount1(result?.data?.results?.total)
        setFrom1(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showOvertimeRequest = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItemRequest>>(API_ENDPOINT.SHOW_OVERTIME_REQUEST +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItemRequest(result?.data?.results)
      }
      handleShowDetail()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const acceptOvertimeRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.ACCEPT_OVERTIME_REQUEST +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OVERTIME.ACCEPTED)
          getOvertimeRequest(null);
       }
       handleCloseAccept();
       handleCloseDetail();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const rejectOvertimeRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.REJECT_OVERTIME_REQUEST +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OVERTIME.REJECTED)
          getOvertimeRequest(null);
       }
       handleCloseReject();
       handleCloseDetail();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getOvertime(null)
      getOvertimeRequest(null)
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Overtime</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/tax-rule-setup'>
            Attendance
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Overtime</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Tabs
          variant={'tabs'}
          className='tabs '
          id='controlled-tab-example'
          activeKey={key}
          onSelect={(k: any) => {
            setKey(k)
          }}
          >
          <Tab eventKey='overtime-application' title='Application'>
            <Card className='border-0 p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Overtime Application</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25 mx-2'}>
                      <Form.Control
                        aria-label='search'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                    <Button
                      onClick={handleShowCreate}
                      className=' add-new-btn rounded text-white'
                      variant='warning'
                      >
                      <EditIcon/>
                      &nbsp;Apply for Overtime
                    </Button>{' '}
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table className='bordered-tabs-top mt-3' responsive>
                  <thead>
                    <tr className='text-center'>
                      <th>No.</th>
                      <th>OT Classification</th>
                      <th>Overtime Date</th>
                      <th>Overtime Start/End</th>
                      <th>Reason</th>
                      <th style={{ width: '50px' }}>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {lists.length > 0 ? (
                        lists.map((item, index) => 
                          <tr className='mb-2' key={index}>
                            <td>{from + index}</td>
                            <td className='fw-bold'>{item.classification}</td>
                            <td>{moment(new Date(item.overtime_date ?? "")).format(dateFormat)}</td>
                            <td>{item.overtime_start} to {item.overtime_end}</td>
                            <td>{item.reason}</td>
                            <td className='text-center'>
                              <div className='d-grid'>
                                <>
                                  {(() => {
                                    switch (item.status) {
                                      case '1':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-secondary'
                                            size='sm'
                                            >
                                            Pending
                                          </Button>
                                        )
                                      case '2':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-danger'
                                            size='sm'
                                            >
                                            Disapproved
                                          </Button>
                                        )
                                      case '3':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-success'
                                            size='sm'
                                            >
                                            Approved
                                          </Button>
                                        )
                                    }
                                  })()}
                                </>
                              </div>
                            </td>
                            <td className='text-center'>
                              <Button
                                onClick={() => showOvertime(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                >
                                <Write/>
                              </Button>
                              <Button
                                onClick={() => handleShowConfirm(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                >
                                <Delete/>
                              </Button>
                            </td>
                          </tr>
                        )
                        ) : (
                        <tr className='no-item'>
                          <td colSpan={7}>{TEXT.NOT_FOUND}</td>
                        </tr>
                      )}
                    </>
                  </tbody>
                </Table>

                <Pagination
                  itemsCount={itemsCount}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  lastPage={lastPage}
                  setCurrentPage={setCurrentPage}
                  alwaysShown={false}
                  pageClicked={(page: number) => { 
                      getOvertime(page);
                    }
                  }
                  />

                <Dialog 
                  show={showConfirm} 
                  setShow={handleCloseConfirm}
                  setEvent={() => { 
                      deleteOvertime(item.id)
                    }
                  }
                />

                <Loader show={showLoading}/>
              </Card.Body>
            </Card>
          </Tab>

          <Tab eventKey='overtime-approval' title='Approval'>
            <Card className='border-0 p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Overtime Approval</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25'}>
                      <Form.Control
                        aria-label='search'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table className='bordered-tabs-top mt-3' responsive>
                  <thead>
                    <tr className='text-center'>
                      <th>No.</th>
                      <th>Employee Name</th>
                      <th>OT Classification</th>
                      <th>Overtime Date</th>
                      <th>Overtime Start/End</th>
                      <th>Reason</th>
                      <th style={{ width: '50px' }}>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {requests.length > 0 ? (
                        requests.map((item, index) => 
                          <tr className='mb-2' key={index}>
                            <td>{from1 + index}</td>
                            <td>
                              <Stack direction='horizontal'>
                                <div>
                                  <Avatar
                                    alt='Remy Sharp'
                                    src='/Thomas.jpg'
                                    sx={{ width: 20, height: 20 }}
                                  ></Avatar>
                                </div>
                                <div>&nbsp;{item.first_name} {item.last_name}</div>
                              </Stack>
                            </td>
                            <td>{item.classification}</td>
                            <td>{moment(new Date(item.overtime_date ?? "")).format(dateFormat)}</td>
                            <td>{item.overtime_start} to {item.overtime_end}</td>
                            <td>{item.reason}</td>
                            <td className='text-center'>
                              <div className='d-grid'>
                                <>
                                  {(() => {
                                    switch (item.status) {
                                      case '1':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-secondary'
                                            size='sm'
                                            >
                                            Pending
                                          </Button>
                                        )
                                      case '2':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-danger'
                                            size='sm'
                                            >
                                            Disapproved
                                          </Button>
                                        )
                                      case '3':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-success'
                                            size='sm'
                                            >
                                            Approved
                                          </Button>
                                        )
                                    }
                                  })()}
                                </>
                              </div>
                            </td>
                            <td className='text-center'>
                              <Button
                                onClick={() => showOvertimeRequest(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                >
                                <View/>
                              </Button>
                              <Button
                                onClick={() => handleShowReject(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                >
                                <Reject/>
                              </Button>
                              <Button
                                onClick={() => handleShowAccept(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                >
                                <Accept/>
                              </Button>
                            </td>
                          </tr>
                        )
                        ) : (
                        <tr className='no-item'>
                          <td colSpan={8}>{TEXT.NOT_FOUND}</td>
                        </tr>
                      )}
                    </>
                  </tbody>

                  <Pagination
                    itemsCount={itemsCount1}
                    itemsPerPage={itemsPerPage1}
                    currentPage={currentPage1}
                    lastPage={lastPage1}
                    setCurrentPage={setCurrentPage1}
                    alwaysShown={false}
                    pageClicked={(page: number) => { 
                        getOvertimeRequest(page);
                      }
                    }
                    />

                  <Dialog 
                    show={showAccept} 
                    setShow={handleCloseAccept}
                    setEvent={() => { 
                        acceptOvertimeRequest(itemRequest.id)
                      }
                    }
                    variant='accept'
                    message='Please confirm to accept overtime request?'
                  />

                  <Dialog 
                    show={showReject} 
                    setShow={handleCloseReject}
                    setEvent={() => { 
                        rejectOvertimeRequest(itemRequest.id)
                      }
                    }
                    variant='reject'
                    message='Do you really want to reject these overtime request?'
                  />

                  <Loader show={showLoading}/>
                </Table>
              </Card.Body>
            </Card>
          </Tab>
        </Tabs>

        {/* ADD MODAL*/}
        <Formik
          initialValues={{
            overtime_date: '',
            shift_from: '',
            shift_to: '',
            overtime_start: '',
            overtime_end: '',
            classification: '',
            reason: '',
          }}
          validationSchema={validationSchema}
          onSubmit={postOvertime}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="overtimeCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' size='lg' centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Overtime Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={5}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Shift Date </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="overtime_date"
                                value={values.overtime_date}
                                onChange={e => {
                                  handleChange(e)
   
                                  const shifts = JSON.parse(localStorage.getItem('shifts') ?? '');
                                  if (shifts.length > 0) {
                                    shifts.some((element: any) => {
                                      if (element.day == (new Date(e.target.value)).getDay()) {
                                        values.shift_from = element.start_from;
                                        values.shift_to = element.end_to
                                      }
                                    })
                                  }
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.overtime_date && !!errors.overtime_date}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.overtime_date}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={1}>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Shift From </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1'>
                                <Clock/>
                              </InputGroup.Text>
                                <Form.Control
                                  size={'sm'} 
                                  type='time' 
                                  placeholder=''
                                  value={values.shift_from}
                                  disabled={true}
                                  />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Shift To </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='time' 
                                placeholder=''
                                value={values.shift_to}
                                disabled={true}
                                />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label className='fw-bold'>
                        Pre-Approved Overtime Hours
                      </Form.Label>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={5}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels required'>OT Classification</Form.Label>
                            <Form.Select
                              size='sm'
                              name="classification"
                              value={values.classification}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.classification && !!errors.classification} 
                              >
                              <option value="" disabled>Select Classification</option>
                              <option value="Normal OT">Normal OT</option>
                              <option value="Early OT">Early OT</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {errors.classification}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={1}>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Start</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='time' 
                                placeholder=''
                                name="overtime_start"
                                value={values.overtime_start}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.overtime_start && !!errors.overtime_start} 
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.overtime_start}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>End</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='time' 
                                placeholder=''
                                name="overtime_end"
                                value={values.overtime_end}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.overtime_end && !!errors.overtime_end}  
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.overtime_end}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
            
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label className='fw-bold required'>
                        Indicate Ticket Number (if applicable) and Reason
                      </Form.Label>
                      <Form.Control 
                        as='textarea' 
                        placeholder='Type your reason here...' 
                        rows={3} 
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.reason && !!errors.reason}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.reason}
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button 
                    variant='primary text-white' 
                    form="overtimeCreate"  
                    className='mx-2' 
                    size={'sm'} 
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Send Application
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
          initialValues={{
            overtime_date: item.overtime_date,
            shift_from: '',
            shift_to: '',
            overtime_start: item.overtime_start,
            overtime_end: item.overtime_end,
            classification: item.classification,
            reason: item.reason,
          }}
          validationSchema={validationSchema}
          onSubmit={updateOvertime}
          enableReinitialize
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="overtimeEdit" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' size='lg' centered show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Edit Overtime</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={5}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Shift Date </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="overtime_date"
                                value={values.overtime_date}
                                onChange={e => {
                                  handleChange(e)
   
                                  const shifts = JSON.parse(localStorage.getItem('shifts') ?? '');
                                  if (shifts.length > 0) {
                                    shifts.some((element: any) => {
                                      if (element.day == (new Date(e.target.value)).getDay()) {
                                        values.shift_from = element.start_from;
                                        values.shift_to = element.end_to
                                      }
                                    })
                                  }
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.overtime_date && !!errors.overtime_date}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.overtime_date}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={1}>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Shift From </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1'>
                                <Clock/>
                              </InputGroup.Text>
                                <Form.Control
                                  size={'sm'} 
                                  type='time' 
                                  placeholder=''
                                  value={values.shift_from}
                                  disabled={true}
                                  />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Shift To </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='time' 
                                placeholder=''
                                value={values.shift_to}
                                disabled={true}
                                />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label className='fw-bold'>
                        Pre-Approved Overtime Hours
                      </Form.Label>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={5}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels required'>OT Classification</Form.Label>
                            <Form.Select
                              size='sm'
                              name="classification"
                              value={values.classification}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.classification && !!errors.classification} 
                              >
                              <option value="" disabled>Select Classification</option>
                              <option value="Normal OT">Normal OT</option>
                              <option value="Early OT">Early OT</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {errors.classification}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={1}>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Start</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='time' 
                                placeholder=''
                                name="overtime_start"
                                value={values.overtime_start}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.overtime_start && !!errors.overtime_start} 
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.overtime_start}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>End</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='time' 
                                placeholder=''
                                name="overtime_end"
                                value={values.overtime_end}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.overtime_end && !!errors.overtime_end}  
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.overtime_end}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
            
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label className='fw-bold required'>
                        Indicate Ticket Number (if applicable) and Reason
                      </Form.Label>
                      <Form.Control 
                        as='textarea' 
                        placeholder='Type your reason here...' 
                        rows={3} 
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.reason && !!errors.reason}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.reason}
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                    Cancel
                  </Button>
                  <Button 
                    variant='primary text-white' 
                    form="overtimeEdit"  
                    className='mx-2' 
                    size={'sm'} 
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Send Application
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* DETAIL MODAL*/} 
        <Modal className='modal' centered size={'lg'} show={showDetail} onHide={handleCloseDetail}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>
              <Stack direction='horizontal'>
                <div>
                  <Avatar
                    alt='Remy Sharp'
                    src='/Thomas.jpg'
                    sx={{ width: 65, height: 65 }}
                  ></Avatar>
                </div>
                <div className='mx-3'>
                  &nbsp;{itemRequest.employee?.first_name} {itemRequest.employee?.last_name}
                  <br />
                  &nbsp;<span className='text-muted fw-light'>{itemRequest.employee?.user_name?.role?.role_name}</span>
                </div>
              </Stack>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={7}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                    <tr>
                      <td className='fw-bold' width={120}>
                        Applied On:
                      </td>
                      <td className='fw-bold'>{itemRequest.application_date}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold' width={120}>
                        Classification
                      </td>
                      <td className='fw-bold'>{itemRequest.classification}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold' width={120}>
                        Hours of Overtime:
                      </td>
                      <td className='fw-bold'>{itemRequest.overtime_start} - {itemRequest.overtime_end}</td>
                    </tr>
                    <tr>
                      <td className='fw-bold' width={120}>
                        Reason:
                      </td>
                      <td className='fw-bold'>{itemRequest.reason}</td>
                    </tr>
                  </tbody>
                </Table>    
              </Col>
              <Col
                sm={5}
                style={{
                  borderLeft: 'solid 1px #A7A9AC',
                }}
                >
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                    {itemRequest.request_status.length > 0 ? (
                      itemRequest.request_status.map((item: any, index: any) =>
                        <div key={index}>
                          <tr>
                            <td className='fw-bold' width={180}>
                              Approver's {index+1} Name:
                            </td>
                            <td>{item.employee?.first_name} {item.employee?.last_name}</td>
                          </tr>
                          <tr>
                            <td className='fw-bold' width={180}>
                              Approver's Role:
                            </td>
                            <td>{item.employee?.user_name?.role?.role_name}</td>
                          </tr>
                          <>
                            {(() => {
                              // Approved:2
                              if (item.status == '3') {
                                return (
                                  <>
                                    <tr>
                                      <td className='fw-bold' width={180}>
                                        Date Approved/Rejected:
                                      </td>
                                      <td>{item.approve_date}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold' width={180}>
                                        Status:
                                      </td>
                                      <td className='fw-bold'>
                                        <Button size={'sm'} className=' pe-1 px-1 text-white' variant={'success'}>
                                          Approved
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                )
                              // Reject:3
                              } else if(item.status == '2') {
                                return (
                                  <>
                                    <tr>
                                      <td className='fw-bold' width={180}>
                                        Date Approved/Rejected:
                                      </td>
                                      <td>{item.reject_date}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold' width={180}>
                                        Status:
                                      </td>
                                      <td className='fw-bold'>
                                        <Button size={'sm'} className=' pe-1 px-1 text-white' variant={'danger'}>
                                          Disapproved
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                )
                              } 

                              return (
                                <>
                                  <tr>
                                    <td className='fw-bold' width={180}>
                                      Status:
                                    </td>
                                    <td className='fw-bold'>
                                      <Button size={'sm'} className=' pe-1 px-1 text-white' variant={'secondary'}>
                                        Pending
                                      </Button>
                                    </td>
                                  </tr>
                                </>
                              )
                            })()}
                          </>
                          <br/>
                        </div>
                      )
                      ) : (
                        <></>
                      )
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => rejectOvertimeRequest(itemRequest.id)} 
              variant='outline-primary' 
              className=' px-5 pe-5 mx-2'  
              size={'sm'}
              >
              Rejected
            </Button>
            <Button 
              onClick={() => acceptOvertimeRequest(itemRequest.id)}
              variant='primary' 
              className='text-white px-5 pe-5' 
              size={'sm'}
              >
              Approved
            </Button>
          </Modal.Footer>
        </Modal>
        <br />
      </Container>
    </>
  )
}
