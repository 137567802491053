import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
  Stack
} from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Accept from 'asset/SvgComponent/Accept'
import AcceptDisabled from 'asset/SvgComponent/AcceptDisabledIcon'
import Reject from 'asset/SvgComponent/Reject'
import RejectDisabled from 'asset/SvgComponent/RejectDisabledIcon'
import View from 'asset/SvgComponent/View'
import Delete from 'asset/SvgComponent/Delete'
import Write from 'asset/SvgComponent/Write'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Calendar from 'asset/SvgComponent/Calendar'
import Clock from 'asset/SvgComponent/Clock'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Avatar } from '@mui/material'

import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import { Formik } from 'formik';
import { validationSchema } from './validationSchema'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { IOfficial, IList, IItem, IItemRequest, IOfficialRequest, IListRequest } from './types'
import moment from "moment"
import Select from "components/ReactSelect";
import {IEmployee} from "../../AttendanceReport/Construction/types";

export default function Index() {
  const [key, setKey] = useState('official-application')
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [currentPage1, setCurrentPage1] = useState(0)
  const [lastPage1, setLastPage1] = useState(0)
  const [itemsPerPage1, setPerPage1] = useState(10)
  const [itemsCount1, setItemCount1] = useState(0)
  const [from1, setFrom1] = useState(0)

  const [showCreate, setShowCreate] = useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = async () => {
    setShowCreate(true)
    getAllEmployee()
  }

  const [showEdit, setShowEdit] = useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      official_business_id: id,
      employee_id: 0,
      date_from: '',
      date_to: '',
      time_from: '',
      time_to: '',
      clock_in_pm: '',
      clock_out_pm: '',
      location: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      }
    })
  }

  const [showDetail, setShowDetail] = useState(false)
  const handleCloseDetail = () => setShowDetail(false)
  const handleShowDetail = () => setShowDetail(true)

  const [showAccept, setShowAccept] = useState(false)
  const handleCloseAccept = () => setShowAccept(false)
  const handleShowAccept = (id: any) => {
    setShowAccept(true)
    setItemRequest({
      official_business_id: id,
      employee_id: 0,
      date_from: '',
      date_to: '',
      time_from: '',
      time_to: '',
      clock_in_pm: '',
      clock_out_pm: '',
      location: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      current: '',
      approvers_order: 0,
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: '',
        approvers_order: ''
      }],
      applied_by_first_name: "",
      applied_by_last_name : "",
      levels_of_approval: [
        {
          approvers_order: "",
          level: ""
        }
      ]
    })
  }

  const [showReject, setShowReject] = useState(false)
  const handleCloseReject = () => setShowReject(false)
  const handleShowReject = (id: any) => {
    setShowReject(true)
    setItemRequest({
      official_business_id: id,
      employee_id: 0,
      date_from: '',
      date_to: '',
      time_from: '',
      time_to: '',
      clock_in_pm: '',
      clock_out_pm: '',
      location: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      current: '',
      approvers_order: 0,
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: '',
        approvers_order: ''
      }],
      applied_by_first_name: "",
      applied_by_last_name : "",
      levels_of_approval: [
        {
          approvers_order: "",
          level: ""
        }
      ]
    })
  }

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const [lists, setData] = useState<IList[]>([])
  const [requests, setRequest] = useState<IListRequest[]>([])
  const [item, setItem] = useState<IItem>({
    official_business_id: 0,
    employee_id: 0,
    date_from: '',
    date_to: '',
    time_from: '',
    time_to: '',
    clock_in_pm: '',
    clock_out_pm: '',
    location: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    }
  })

  const [itemRequest, setItemRequest] = useState<IItemRequest>({
    official_business_id: 0,
    employee_id: 0,
    date_from: '',
    date_to: '',
    time_from: '',
    time_to: '',
    clock_in_pm: '',
    clock_out_pm: '',
    location: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    current: '',
    approvers_order: 0,
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      level: '',
      approvers_order: ''
    }],
    applied_by_first_name: "",
    applied_by_last_name : "",
    levels_of_approval: [
      {
        approvers_order: "",
        level: ""
      }
    ]
  })
  const [dateFormat, setDateFormat] = useState('')
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [selectedEmployeeOptionForEdit, setSelectedEmployeeOptionForEdit] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
  const [showAcceptRejectButton, setShowAcceptRejectButton] = useState(true)

  const handleDisableAcceptRejectButton = (item: any) => {
    if(item.status == '3' || item.status == '2'){
      return true
    }else if(item.current == '0' || item.current == '2'){
      return true
    }else{
      return false
    }

  }

  const handleChangeDropdownEmployee = (selectedOption: any) => {
    setSelectedEmployeeOption(selectedOption)
  }

  const getAllEmployee = async () => {
    try {
      const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
      const result = await res
      if (result?.data?.results?.length) {
        const employees = result?.data?.results;
        if(JSON.parse(localStorage.getItem('role') ?? "") != "Admin" &&
            JSON.parse(localStorage.getItem('role') ?? "") != "Supervisor"){
          employees.map(employee => {
            if(employee.employee_id == Number(localStorage.getItem('employee_id'))){
              setSelectedEmployeeOption({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
              setEmployeeOptions([])
              setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
              setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
            }
          })
        }else{
          setEmployeeOptions([])
          setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
          employees.map(employee => {
            setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
          })
        }

      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getOfficial = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IOfficial>>(API_ENDPOINT.GET_OFFICIAL, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showOfficial = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_OFFICIAL +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
        setSelectedEmployeeOptionForEdit({label: result?.data?.results?.employee?.first_name + " " + result?.data?.results?.employee?.last_name, value: result?.data?.results?.employee_id})
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postOfficial = async (value: any, {resetForm} : any) => {
    if(selectedEmployeeOption.value != 0){
      await axios.post(API_ENDPOINT.POST_OFFICIAL_FOR_MFBC, {
        employee_id: selectedEmployeeOption.value,
        date_from: value.date_from,
        date_to: value.date_to,
        clock_in: value.clock_in,
        clock_out: value.clock_out,
        location: value.location,
        reason: value.reason,
      }).then(response => {
        if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OFFICIAL.CREATED)
          getOfficial(null);
          getOfficialRequest(null)
        }
        resetForm({})
        handleCloseCreate();
      });
    }else{
      setError("Please select employee.")
      setShowError(true)
    }


    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const updateOfficial = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_OFFICIAL +'/'+ value.official_business_id, {
      employee_id: value.employee_id,
      date_from: value.date_from,
      date_to: value.date_to,
      time_from: value.time_from,
      time_to: value.time_to,
      location: value.location,
      reason: value.reason,
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OFFICIAL.UPDATED)
          getOfficial(currentPage);
       }
       resetForm({})
       handleCloseEdit();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const deleteOfficial = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_OFFICIAL +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.OFFICIAL.DELETED)
        getOfficial(currentPage);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getOfficialRequest = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IOfficialRequest>>(API_ENDPOINT.GET_OFFICIAL_REQUEST_FOR_MFBC, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setRequest(result?.data?.results?.data)
        setCurrentPage1(result?.data?.results?.current_page)
        setLastPage1(result?.data?.results?.last_page)
        setPerPage1(result?.data?.results?.per_page)
        setItemCount1(result?.data?.results?.total)
        setFrom1(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showOfficialRequest = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItemRequest>>(API_ENDPOINT.SHOW_OFFICIAL_REQUEST_FOR_MFBC +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItemRequest(result?.data?.results)
      }
      handleShowDetail()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const acceptOfficialRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.ACCEPT_OFFICIAL_REQUEST_FOR_MFBC +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OFFICIAL.ACCEPTED)
          getOfficialRequest(currentPage1);
          getOfficial(currentPage)
       }
       handleCloseAccept();
       handleCloseDetail();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const rejectOfficialRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.REJECT_OFFICIAL_REQUEST_FOR_MFBC +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OFFICIAL.REJECTED)
          getOfficialRequest(currentPage1);
          getOfficial(currentPage)
       }
       handleCloseReject();
       handleCloseDetail();
    });
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getOfficial(null)
      getOfficialRequest(null)
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Official Business</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/tax-rule-setup'>
            Attendance
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Official Business</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Tabs
          variant={'tabs'}
          className='tabs '
          id='controlled-tab-example'
          activeKey={key}
          onSelect={(k: any) => {
            setKey(k)
          }}
          >
          <Tab eventKey='official-application' title='Application'>
            <Card className='border-0 p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Official Business Application</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25 mx-2'}>
                      <Form.Control
                        aria-label='search'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                    <Button
                      onClick={handleShowCreate}
                      className=' add-new-btn rounded text-white'
                      variant='warning'
                      >
                      <EditIcon/>
                      &nbsp;Apply for Official Business
                    </Button>{' '}
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table className='bordered-tabs-top mt-3' responsive>
                  <thead>
                    <tr className='text-center'>
                      <th>No.</th>
                      <th>Application Date</th>
                      <th style={{width: '12%'}}>Employee Name</th>
                      <th>Location</th>
                      <th>Date From</th>
                      <th>Date To</th>
                      <th>In - Out</th>
                      <th style={{width: '15%'}}>Reason</th>
                      <th style={{width: '12%'}}>Applied By</th>
                      <th style={{ width: '50px' }}>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {lists.length > 0 ? (
                        lists.map((item, index) => 
                          <tr className='mb-2' key={index}>
                            <td>{from + index}</td>
                            <td className="text-center">{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                            <td>
                              <Stack direction='horizontal'>
                                <div>
                                  {
                                    item.photo != "" ?
                                        <Avatar
                                            sx={{ width: 20, height: 20 }}
                                            src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                        ></Avatar> :
                                        <Avatar
                                            sx={{ width: 20, height: 20}}
                                            src={""}
                                        ></Avatar>
                                  }
                                </div>
                                <div>&nbsp;{item.first_name} {item.last_name}</div>
                              </Stack>
                            </td>
                            <td>{item.location}</td>
                            <td className="text-center">{moment(new Date(item.date_from ?? "")).format(dateFormat)}</td>
                            <td className="text-center">{moment(new Date(item.date_to ?? "")).format(dateFormat)}</td>
                            <td className="text-center">{item.time_from ?? "-"} - {item.time_to ?? "-"}</td>
                            <td className="display-reason" title={item.reason ?? ""}>{item.reason}</td>
                            <td>{item.applied_by_first_name} {item.applied_by_last_name}</td>
                            <td className='text-center'>
                              <div className='d-grid'>
                                <>
                                  {(() => {
                                    switch (item.status) {
                                      case '1':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-secondary'
                                            size='sm'
                                            >
                                            Pending
                                          </Button>
                                        )
                                      case '2':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-danger'
                                            size='sm'
                                            >
                                            Disapproved
                                          </Button>
                                        )
                                      case '3':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-success'
                                            size='sm'
                                            >
                                            Approved
                                          </Button>
                                        )
                                    }
                                  })()}
                                </>
                              </div>
                            </td>
                            <td className='text-center'>
                              <Button
                                  onClick={() => {
                                    showOfficialRequest(item.official_business_id)
                                    setShowAcceptRejectButton(false)
                                  }}
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                              >
                                <View/>
                              </Button>
                              <Button
                                onClick={() => showOfficial(item.official_business_id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                disabled={item.status == '3'? true: false}
                                >
                                <Write/>
                              </Button>
                              <Button
                                onClick={() => handleShowConfirm(item.official_business_id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                disabled={item.status == '3'? true: false}
                                >
                                <Delete/>
                              </Button>
                            </td>
                          </tr>
                        )
                        ) : (
                        <tr className='no-item'>
                          <td colSpan={11}>{TEXT.NOT_FOUND}</td>
                        </tr>
                      )}
                    </>
                  </tbody>
                </Table>

                <Pagination
                  itemsCount={itemsCount}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  lastPage={lastPage}
                  setCurrentPage={setCurrentPage}
                  alwaysShown={false}
                  pageClicked={(page: number) => { 
                      getOfficial(page);
                    }
                  }
                  />

                <Dialog 
                  show={showConfirm} 
                  setShow={handleCloseConfirm}
                  setEvent={() => { 
                      deleteOfficial(item.official_business_id)
                    }
                  }
                />

                <Loader show={showLoading}/>
              </Card.Body>
            </Card>
          </Tab>

          <Tab eventKey='official-approval' title='Approval'>
            <Card className='border-0 p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Official Business Approval</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25'}>
                      <Form.Control
                        aria-label='search'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table className='bordered-tabs-top mt-3' responsive>
                  <thead>
                    <tr className='text-center'>
                      <th>No.</th>
                      <th>Application Date</th>
                      <th style={{width: '12%'}}>Employee Name</th>
                      <th>Location</th>
                      <th>Date From</th>
                      <th>Date To</th>
                      <th>In - Out</th>
                      <th style={{width: '15%'}}>Reason</th>
                      <th style={{width: '12%'}}>Applied By</th>
                      <th style={{ width: '50px' }}>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {requests.length > 0 ? (
                        requests.map((item, index) =>
                          <tr className='mb-2' key={index}>
                            <td>{from1 + index}</td>
                            <td>{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                            <td>
                              <Stack direction='horizontal'>
                                <div>
                                  {
                                    item.photo != "" ?
                                        <Avatar
                                            sx={{ width: 20, height: 20 }}
                                            src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                        ></Avatar> :
                                        <Avatar
                                            sx={{ width: 20, height: 20}}
                                            src={""}
                                        ></Avatar>
                                  }
                                </div>
                                <div>&nbsp;{item.first_name} {item.last_name}</div>
                              </Stack>
                            </td>
                            <td>{item.location}</td>
                            <td>{moment(new Date(item.date_from ?? "")).format(dateFormat)}</td>
                            <td>{moment(new Date(item.date_to ?? "")).format(dateFormat)}</td>
                            <td>{item.time_from} - {item.time_to}</td>
                            <td className="display-reason" title={item.reason ?? ""}>{item.reason}</td>
                            <td>{item.applied_by_first_name} {item.applied_by_last_name}</td>
                            <td className='text-center'>
                              <div className='d-grid'>
                                <>
                                  {(() => {
                                    switch (item.status) {
                                      case '1':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-secondary'
                                            size='sm'
                                            >
                                            Pending
                                          </Button>
                                        )
                                      case '2':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-danger'
                                            size='sm'
                                            >
                                            Disapproved
                                          </Button>
                                        )
                                      case '3':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-success'
                                            size='sm'
                                            >
                                            Approved
                                          </Button>
                                        )
                                    }
                                  })()}
                                </>
                              </div>
                            </td>
                            <td className='text-center'>
                              <Button
                                onClick={() => {
                                  showOfficialRequest(item.official_business_id)
                                  setShowAcceptRejectButton( true)
                                }}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                >
                                <View/>
                              </Button>
                              <Button
                                  onClick={() => handleShowAccept(item.official_business_id)}
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                                  disabled={handleDisableAcceptRejectButton(item)}
                              >
                                {
                                  handleDisableAcceptRejectButton(item) ?
                                      <AcceptDisabled/> : <Accept/>
                                }
                              </Button>
                              <Button
                                onClick={() => handleShowReject(item.official_business_id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                disabled={handleDisableAcceptRejectButton(item)}
                                >
                                {
                                  handleDisableAcceptRejectButton(item) ?
                                      <RejectDisabled/> : <Reject/>
                                }
                              </Button>
                            </td>
                          </tr>
                        )) : (
                        <tr className='no-item'>
                          <td colSpan={11}>{TEXT.NOT_FOUND}</td>
                        </tr>
                      )}
                    </>
                  </tbody>
                </Table>
                  <Pagination
                    itemsCount={itemsCount1}
                    itemsPerPage={itemsPerPage1}
                    currentPage={currentPage1}
                    lastPage={lastPage1}
                    setCurrentPage={setCurrentPage1}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                        getOfficialRequest(page);
                      }
                    }
                  />

                  <Dialog 
                    show={showAccept} 
                    setShow={handleCloseAccept}
                    setEvent={() => { 
                        acceptOfficialRequest(itemRequest.official_business_id)
                      }
                    }
                    variant='accept'
                    message='Please confirm to accept official business request?'
                  />

                  <Dialog 
                    show={showReject} 
                    setShow={handleCloseReject}
                    setEvent={() => { 
                        rejectOfficialRequest(itemRequest.official_business_id)
                      }
                    }
                    variant='reject'
                    message='Do you really want to reject these official business request?'
                  />

                  <Loader show={showLoading}/>

              </Card.Body>
            </Card>
          </Tab>
        </Tabs>

        {/* ADD MODAL*/}
        <Formik
          initialValues={{
            employee_id: '',
            date_from: '',
            date_to: '',
            clock_in: '',
            clock_out: '',
            location: '',
            reason: '',
          }}
          validationSchema={validationSchema}
          onSubmit={postOfficial}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="officialCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Official Business Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold form-labels required'>Employees</Form.Label>
                            <Select
                                name="employee_id"
                                value={selectedEmployeeOption}
                                options={employeeOptions}
                                onChange={(e:any )=> {
                                  handleChangeDropdownEmployee(e)

                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.employee_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Date From</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="date_from"
                                value={values.date_from}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.date_from && !!errors.date_from}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.date_from}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Date To</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="date_to"
                                value={values.date_to}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.date_to && !!errors.date_to}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.date_to}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Clock In</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='time' 
                                placeholder=''
                                name="clock_in"
                                value={values.clock_in}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.clock_in && !!errors.clock_in}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.clock_in}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Clock Out</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='time' 
                                placeholder=''
                                name="clock_out"
                                value={values.clock_out}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.clock_out && !!errors.clock_out}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.clock_out}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

            
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label className='fw-bold required'>
                        Location
                      </Form.Label>
                      <Form.Control 
                        as='textarea' 
                        placeholder='' 
                        rows={3} 
                        name="location"
                        value={values.location}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.location && !!errors.location}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.location}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label className='fw-bold required'>
                        Reason
                      </Form.Label>
                      <Form.Control 
                        as='textarea' 
                        placeholder='' 
                        rows={3} 
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.reason && !!errors.reason}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.reason}
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button 
                    variant='primary text-white' 
                    form="officialCreate"  
                    className='mx-2' 
                    size={'sm'} 
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Send Application
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
          initialValues={item}
          validationSchema={validationSchema}
          onSubmit={updateOfficial}
          enableReinitialize
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="officialEdit" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Edit Official Business</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold form-labels required'>Employees</Form.Label>
                            <Select
                                name="employee_id"
                                value={selectedEmployeeOptionForEdit}
                                options={employeeOptions}
                                onChange={(e:any )=> {
                                  handleChangeDropdownEmployee(e)
                                  values.employee_id = e.value
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.employee_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Date From</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="date_from"
                                value={values.date_from}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.date_from && !!errors.date_from}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.date_from}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Date To</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='date' 
                                placeholder=''
                                name="date_to"
                                value={values.date_to}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.date_to && !!errors.date_to}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.date_to}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Clock In</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='time' 
                                placeholder=''
                                name="time_from"
                                value={values.time_from}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.time_from && !!errors.time_from} 
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.time_from}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Clock Out</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control 
                                size='sm' 
                                type='time' 
                                placeholder=''
                                name="time_to"
                                value={values.time_to}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.time_to && !!errors.time_to}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.time_to}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label className='fw-bold required'>
                        Location
                      </Form.Label>
                      <Form.Control 
                        as='textarea' 
                        placeholder='' 
                        rows={3} 
                        name="location"
                        value={values.location}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.location && !!errors.location}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.location}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label className='fw-bold required'>
                        Reason
                      </Form.Label>
                      <Form.Control 
                        as='textarea' 
                        placeholder='' 
                        rows={3} 
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.reason && !!errors.reason}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.reason}
                        </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                    Cancel
                  </Button>
                  <Button 
                    variant='primary text-white' 
                    form="officialEdit"  
                    className='mx-2' 
                    size={'sm'} 
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Send Application
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* DETAIL MODAL*/} 
        <Modal className='modal' centered size={'lg'} show={showDetail} onHide={handleCloseDetail}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>
              <Stack direction='horizontal'>
                <div>
                  {
                    itemRequest.employee.photo != "" ?
                        <Avatar
                            sx={{ width: 65, height: 65 }}
                            src={process.env.REACT_APP_IMAGE_URL+ itemRequest.employee.photo}
                        ></Avatar> :
                        <Avatar
                            sx={{ width: 65, height: 65}}
                            src={""}
                        ></Avatar>
                  }
                </div>
                <div className='mx-3'>
                  &nbsp;{itemRequest.employee?.first_name} {itemRequest.employee?.last_name}
                  <br />
                  &nbsp;<span className='text-muted fw-light'>{itemRequest.employee?.user_name?.role?.role_name}</span>
                </div>
              </Stack>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0' >
                  <tbody style={{ padding: 'none' }}>

                    <tr className='lh-1'>
                      <td className='fw-bold' width={200}>
                        Date of Official Business:
                      </td>
                      <td >{moment(new Date(itemRequest.date_from ?? "")).format(dateFormat)} - {moment(new Date(itemRequest.date_to ?? "")).format(dateFormat)}</td>
                    </tr>
                    <tr className='lh-1'>
                      <td className='fw-bold' width={200}>
                        Hours of Official Business:
                      </td>
                      <td >{itemRequest.time_from} - {itemRequest.time_to}
                      </td>
                    </tr>
                    <tr className='lh-1'>
                      <td className='fw-bold' width={200}>
                        Location:
                      </td>
                      <td>{itemRequest.location}</td>
                    </tr>
                    <tr className='lh-1'>
                      <td className='fw-bold' width={200}>
                        Reason:
                      </td>
                      <td>{itemRequest.reason}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                    <tr className='lh-1'>
                      <td className='fw-bold' width={200}>
                        Applied On:
                      </td>
                      <td >{itemRequest.application_date}</td>
                    </tr>
                    <tr className='lh-1'>
                      <td className='fw-bold' width={200}>
                        Applied By:
                      </td>
                      <td >{itemRequest.applied_by_first_name} {itemRequest.applied_by_last_name}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr/>
            <Row style={{marginTop: '-15px'}}>
              <Table borderless className='mt-0 mb-0'>
                <tbody>
                  <tr className='lh-1'>
                    <th className='text-center'>Approvals</th>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col sm={12}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <th className='text-center' style={{width: '20%'}}>Levels</th>
                    <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                    <th className='text-center'>Role</th>
                    <th className='text-center' style={{width: '15%'}}>Status</th>
                    <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                  </tr>
                    {
                      itemRequest.request_status.length > 0 ? (
                          itemRequest.request_status.map((item: any, index: any) =>
                              (
                                  <tr key={index} className='lh-1'>
                                      <td>
                                        {
                                        index != 0 && itemRequest.request_status[index - 1].level == item.level ?
                                            "" : <>
                                              <b>Level {item.level}</b> : {item.approvers_order == "0" ? "By Order" : "Anyone"}
                                            </>
                                        }
                                      </td>
                                      <td>
                                        <Stack direction='horizontal'>
                                          <div>
                                            {
                                              item.employee?.photo != "" ?
                                                  <Avatar
                                                      sx={{ width: 20, height: 20 }}
                                                      src={process.env.REACT_APP_IMAGE_URL+ item.employee?.photo}
                                                      className={"profile-avatar"}
                                                  ></Avatar> :
                                                  <Avatar
                                                      sx={{ width: 20, height: 20}}
                                                      src={""}
                                                      className={"profile-avatar"}
                                                  ></Avatar>
                                            }
                                          </div>
                                          <div>&nbsp;{item.employee?.first_name} {item.employee?.last_name}</div>
                                        </Stack>
                                      </td>
                                      <td>{item.employee?.user_name?.role?.role_name}</td>
                                       {(() => {
                                          // Approved:2
                                          if (item.status == '3') {

                                            return (
                                                <>
                                                  <td className='fw-bold text-center'>
                                                    <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                                      Approved
                                                    </Button>
                                                  </td>
                                                  <td className='text-center'>{moment(new Date(item.approve_date ?? "")).format(dateFormat)}</td>
                                                </>
                                            )

                                            // Reject:3
                                          } else if(item.status == '2') {
                                            return (
                                                <>
                                                  <td className='fw-bold text-center'>
                                                    <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                                      Disapproved
                                                    </Button>
                                                  </td>
                                                  <td className='text-center'>{moment(new Date(item.reject_date ?? "")).format(dateFormat)}</td>

                                                </>
                                            )
                                          } else if(item.status == '4') {
                                            return (
                                                <>
                                                  <td className='text-center'>-</td>
                                                  <td className='text-center'>-</td>
                                                </>

                                            )
                                          }

                                          return (
                                              <>
                                                <td className='fw-bold text-center'>
                                                  <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                                    Pending
                                                  </Button>
                                                </td>
                                                <td className='text-center'>-</td>
                                              </>
                                          )
                                      })()}


                                  </tr>

                              )

                          )
                      ) : (
                          <></>
                      )
                    }
                  </tbody>
                </Table>
              </Col>

            </Row>

          </Modal.Body>
          <Modal.Footer>
            {
              showAcceptRejectButton ?
                  <>
                    <Button
                        onClick={() => rejectOfficialRequest(itemRequest.official_business_id)}
                        variant='outline-primary'
                        className=' px-5 pe-5 mx-2'
                        size={'sm'}
                        disabled={handleDisableAcceptRejectButton(itemRequest)}
                    >
                      Rejected
                    </Button>
                    <Button
                        onClick={() => acceptOfficialRequest(itemRequest.official_business_id)}
                        variant='primary'
                        className='text-white px-5 pe-5'
                        size={'sm'}
                        disabled={handleDisableAcceptRejectButton(itemRequest)}
                    >
                      Approved
                    </Button>
                  </> : ""
            }

          </Modal.Footer>
        </Modal>
        <br />
      </Container>
    </>
  )
}
