import React from 'react'

const Calendar = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22.344'
      height='24.605'
      viewBox='0 0 22.344 24.605'
    >
      <g id='Calendar' transform='translate(1 1)'>
        <path
          id='Path_4'
          data-name='Path 4'
          d='M4.5,15H24.844'
          transform='translate(-4.5 -5.959)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_1'
          data-name='Path 1'
          d='M6.76,6H22.583a2.232,2.232,0,0,1,2.26,2.2V23.619a2.232,2.232,0,0,1-2.26,2.2H6.76a2.232,2.232,0,0,1-2.26-2.2V8.2A2.232,2.232,0,0,1,6.76,6Z'
          transform='translate(-4.5 -3.217)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_2'
          data-name='Path 2'
          d='M24,3V8.566'
          transform='translate(-9.307 -3)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
        <path
          id='Path_3'
          data-name='Path 3'
          d='M12,3V8.566'
          transform='translate(-6.35 -3)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
        />
      </g>
    </svg>
  )
}

export default Calendar
