import React, {useEffect, useRef, useState} from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Formik } from 'formik';
import View from 'asset/SvgComponent/View'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Pagination from 'components/Pagination'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import {IList, IPayslip, ISalary, IAllowance, IDeduction, IEmployee, IEmployeeDetails} from './types'
import moment from "moment/moment";
import Image from "react-bootstrap/Image";
import ReactToPrint from "react-to-print";
import PrintPayslipComponent from "../PayrollList/Retail/printPayslipComponent";
import Select from "components/ReactSelect";
import {validationSchema} from "../../Attendance/AttendanceReport/Retail/validationSchema";
import Calendar from "asset/SvgComponent/Calendar";
import Stack from "@mui/material/Stack";
import {Avatar} from "@mui/material";
import {IPayrollTabs} from "../PayrollList/Retail/types";

export default function Index() {
  const [dateFormat, setDateFormat] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)
  const [lists, setLists] = React.useState<IPayslip[]>([])

  const [validation, setValidation] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)

  const [showPayslip, setShowPayslip] = React.useState(false)
  const handleShowPayslip = () => {
    setShowPayslip(true)
  }
  const handleClosePayslip = () => {
    setShowPayslip(false)
  }
  const [payslipData, setPayslipData] = React.useState<ISalary | null>(null)
  const componentRef2 = useRef<HTMLDivElement>(null);
  const [allowances, setAllowances] = React.useState([{allowance_id: 0,
    allowance_name: "",
    limit_per_month: ""}])
  const [deductions, setDeductions] = React.useState([{deduction_id: 0,
    deduction_name: "",
    limit_per_month: ""}])
  const [searched, setSearched] = React.useState("")
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
  const [dateFilter, setDateFilter] = useState({date_from: '', date_to: ''})
  const [employees, setEmployees] = useState<IEmployeeDetails[]>([
    {
      employee_id: 0,
      first_name: '',
      last_name: '',
      photo: '',
      designation: {
        designation_name: ''
      },
      branch: {
        branch_name: ''
      },
      user_name: {
        role: {
          role_name: ''
        }
      }
    }
  ])
  const [payslipLogo, setPayslipLogo] = useState("")
  const [companyName, setCompanyName] = useState("")


  const formatNumber = (num: string) => {
    return Number(num).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  const handleSearch = (e:any) => {
    setSearched(e.target.value)
    getPayslips(null, e.target.value, dateFilter.date_from, dateFilter.date_to)
  }

  const getPayslipLogo = async () => {
    try {
      const response = await axios.get<IAPI_Response<{payslipLogo: string}>>(API_ENDPOINT.GET_PAYSLIP_LOGO)
      const result = await response

      if(result?.data?.results){
        setPayslipLogo(result?.data?.results.payslipLogo)
      }


    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);

  }

  const getCompanyName = async () => {
    try {
      const response = await axios.get<IAPI_Response<{companyName: string}>>(API_ENDPOINT.GET_COMPANY_NAME)
      const result = await response

      if(result?.data?.results){
        setCompanyName(result?.data?.results.companyName)
      }


    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);

  }

  const getPayslips = async (page_number: number | null, searched: string, date_from: string, date_to: string) => {
    try {
      const response = await axios.get<IAPI_Response<IList>>(API_ENDPOINT.GET_PAYSLIPS, {
        params: {
          page: page_number,
          searched: searched,
          employee_id: selectedEmployeeOption.value
        },

      })
      const result = await response

      if (result?.data?.results?.data?.length) {
        setLists(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }else{
        setLists([])
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getAllowances = async () => {
    try {
      const response = await axios.get<IAPI_Response<IAllowance[]>>(API_ENDPOINT.ALL_ALLOWANCE)
      const result = await response

      if (result?.data?.results?.length) {
        setAllowances(result?.data?.results)
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getDeductions = async () => {
    try {
      const response = await axios.get<IAPI_Response<IDeduction[]>>(API_ENDPOINT.ALL_DEDUCTION)
      const result = await response

      if (result?.data?.results?.length) {
        setDeductions(result?.data?.results)
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleViewPayslip = async (salary_details_id: number) => {
    try {
      const response = await axios.get<IAPI_Response<ISalary>>(API_ENDPOINT.SHOW_PAYSLIP+"/"+salary_details_id)
      const result = await response

      if (result?.data?.results) {
        setPayslipData(result?.data?.results)
        handleShowPayslip()
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);

  }

  const getAllEmployee = async () => {
    try {
      const res = await axios.get<IAPI_Response<IEmployeeDetails[]>>(API_ENDPOINT.ALL_EMPLOYEE)
      const result = await res
      if (result?.data?.results?.length) {
        const employees = result?.data?.results;
        setEmployeeOptions([])
        setEmployees(employees)
        if(JSON.parse(localStorage.getItem('role') ?? "") == "Admin"){
          setEmployeeOptions(current => [...current, {value: 0, label: "All Employees"}]);
          employees.map(employee => {
            setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);

          })


        }else{
          employees.map(employee => {
            if(employee.employee_id == Number(localStorage.getItem('employee_id'))){
              setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
              setSelectedEmployeeOption({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
            }

          })
        }

      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const handleChangeEmployeeDropdown = (selectedOption: any) => {

    setSelectedEmployeeOption(selectedOption)

  }

  const filterPayslip = (value: any) => {
    setDateFilter({date_from: value.date_from, date_to: value.date_to})
    getPayslips(null, searched, value.date_from, value.date_to)
  }

  useEffect(() => {
    ;(async () => {
      getAllEmployee()
      setDateFormat(localStorage.getItem('date_format') ?? "")
      getAllowances()
      getDeductions()
      getPayslipLogo()
      getCompanyName()
      if(JSON.parse(localStorage.getItem('role') ?? "") != "Admin"){
        getPayslips(null, searched, dateFilter.date_from, dateFilter.date_to)
      }

    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Payslips</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/payslips'>
            Payroll
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Payslips</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={12}>
                <Formik
                    initialValues={
                      {
                        employee_id: ''
                      }
                    }
                    // validationSchema={validationSchema}
                    onSubmit={filterPayslip}
                >
                  {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                          <Col sm={2}>
                            {
                              JSON.parse(localStorage.getItem('role') ?? "") == "Admin" ?
                                  <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                    <Form.Label className='fw-bold form-labels'>Employee Name</Form.Label>
                                    <Select
                                        name="employee_id"
                                        value={selectedEmployeeOption}
                                        options={employeeOptions}
                                        onChange={(e: any) => {
                                          handleChangeEmployeeDropdown(e);
                                          handleSubmit();
                                        }}
                                    />

                                    <Form.Control.Feedback type="invalid">
                                      {errors.employee_id}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                : ""
                            }

                          </Col>
                          <Col sm={4}>

                          </Col>
                          <Col sm={6} className='d-flex justify-content-end hidden'>
                            <InputGroup size='sm' className={'w-25'} style={{height: '30px', margin: 'auto 0 auto 0'}}>
                              <Form.Control
                                  onChange={handleSearch}
                                  aria-label='search'
                                  aria-describedby='inputGroup-sizing-sm'
                                  placeholder='Search...'
                              />
                              <InputGroup.Text className='bg-transparent search-input '>
                                <FontAwesomeIcon icon={faSearch} />
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                        </Row>
                      </Form>
                  )}
                </Formik>

              </Col>

            </Row>
            <>
              {selectedEmployeeOption.value !== 0 && (
                  <Stack direction='row' spacing={2}>
                    {employees.map((employee : any, index : any) => (
                        employee.employee_id == selectedEmployeeOption.value ?
                            <>
                              <div>
                                {
                                  employee.photo != "" ?
                                      (<Avatar
                                          sx={{width: 33, height: 33}}
                                          src={process.env.REACT_APP_IMAGE_URL + employee.photo}
                                      ></Avatar>) :
                                      (<Avatar
                                          sx={{width: 33, height: 33}}
                                          src={""}
                                      ></Avatar>)
                                }
                              </div>

                              <div>
                                <h5 style={{ fontSize: '18px!important' }} className='fw-bold' key={index}>
                                  {employee.first_name} {employee.last_name}
                                </h5>
                                <span className='text-muted fw-light'>{employee?.designation?.designation_name ?? ""} | {employee?.branch?.branch_name ?? ""}</span>
                              </div>
                            </>

                            : ''
                    ))}
                  </Stack>
              )}
            </>

            <Table responsive className='bordered-tabs-top mt-3'>
              <thead>
                <tr>
                  <th style={{ width: '5%' }} className='text-center'>
                    No.
                  </th>
                  <th className='text-center'>
                    Payroll Period
                  </th>
                  <th className='text-center'>
                    Payroll Name
                  </th>
                  <th className='text-center'>
                    Net Pay
                  </th>
                  <th className='text-center'>
                    Date Generated
                  </th>
                  <th className={'text-center'}>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {lists.length > 0 ? (
                    lists.map((item, index) =>
                      <tr className='mb-2' key={index}>
                        <td className="text-center">{from + index}</td>
                        <td className="text-center">{moment(new Date(item.dayFrom ?? "")).format(dateFormat)} to {moment(new Date(item.dayTo ?? "")).format(dateFormat)}</td>
                        <td className="text-center">{item.payroll_range_name}</td>
                        <td className="text-right">{formatNumber(item.net_salary)}</td>
                        <td className="text-center">{moment(new Date(item.generated_on ?? "")).format(dateFormat)}</td>
                        <td className='text-center'>

                          <Button
                            onClick={() => handleViewPayslip(item.salary_details_id)}
                            className='p-1 pt-0 pb-0'
                            style={{ fontSize: '12px' }}
                            variant='transparent'
                            size='sm'
                            >
                            <View/>
                          </Button>
                        </td>
                      </tr>
                    )
                    ) : (
                    <tr className='no-item'>
                      <td colSpan={6}>{TEXT.NOT_FOUND}</td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>

            <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
              pageClicked={(page: number) => {
                  getPayslips(page, searched, dateFilter.date_from, dateFilter.date_to);
                }
              }
              />


            <Loader show={showLoading}/>
          </Card.Body>
        </Card>
        {/* PAYSLIP MODAL*/}
        <Modal className='modal' show={showPayslip} size={'lg'} onHide={handleClosePayslip}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>
              {/* <Image*/}
              {/*    style={{*/}
              {/*        width: '10%'*/}
              {/*    }}*/}
              {/*    src=*/}
              {/*        "/Octaneware1.png"*/}

              {/* />*/}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row>
                <Col sm={6} style={{ borderRight: 'solid #C0C0C0 1px' }}>
                  <Row>
                    <Col sm={6}>
                      <Row>
                        <Col sm={6} style={{margin: 'auto'}}>
                          <Image
                              style={{
                                width: '90%',
                              }}
                              src={payslipLogo != "" ? process.env.REACT_APP_IMAGE_URL+ payslipLogo: "/Octaneware1.png"}
                          />
                        </Col>
                        <Col sm={6} style={{margin: 'auto'}}>
                          <h5 className='fw-bold'>{companyName}</h5>
                        </Col>
                      </Row>

                    </Col>
                    <Col sm={6} className=' d-grid justify-content-end ' style={{margin: 'auto'}}>
                      <h5 className='fw-bold'>Payslip</h5>
                    </Col>
                  </Row>
                  <hr />
                  {/*<Row className='mb-3'>*/}
                  {/*  <Col sm={6}>*/}
                  {/*    <h6 className='fw-bold'>Employee ID :</h6>*/}
                  {/*  </Col>*/}
                  {/*  <Col sm={6}>*/}
                  {/*    <h6>{payslipData?.employee.employee_id}</h6>*/}
                  {/*  </Col>*/}
                  {/*</Row>*/}
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Employee Name :</h6>
                    </Col>
                    <Col sm={6} >
                      <h6>{payslipData?.employee?.first_name} {payslipData?.employee.last_name}</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Position :</h6>
                    </Col>
                    <Col sm={6}>
                      <h6>{payslipData?.designation}</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Department :</h6>
                    </Col>
                    <Col sm={6}>
                      <h6>{payslipData?.department}</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Worksite :</h6>
                    </Col>
                    <Col sm={6}>
                      <h6>{payslipData?.worksite}</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Per Cut Off</h6>
                    </Col>
                    <Col sm={6} >
                      <h6>{Number(payslipData?.total_working_days).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} days</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Days rendered</h6>
                    </Col>
                    <Col sm={6} >
                      <h6>{Number(payslipData?.total_present).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} days</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Payroll Period :</h6>
                    </Col>
                    <Col sm={6}>
                      <h6>{payslipData?.month_of_salary}</h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Payable Date :</h6>
                    </Col>
                    <Col sm={6}>
                      <h6></h6>
                    </Col>
                  </Row>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Payable Type:</h6>
                    </Col>
                    <Col sm={6}>
                      <h6>{payslipData?.payrollType}</h6>
                    </Col>
                  </Row>
                  {/*<Row className='mb-3'>*/}
                  {/*  <Col sm={6}>*/}
                  {/*    <h6 className='fw-bold'>Payment Method :</h6>*/}
                  {/*  </Col>*/}
                  {/*  <Col sm={6}>*/}
                  {/*    <h6>Cash</h6>*/}
                  {/*  </Col>*/}
                  {/*</Row>*/}
                  <br/>
                  <Row className='mb-1'>
                    <Col sm={6}>
                      <h5 className='fw-bold'>Net Pay :</h5>
                    </Col>
                    <Col sm={6}>
                      <h5 className='fw-bold'>{formatNumber(payslipData?.net_salary ? payslipData?.net_salary.toString() : "0")}</h5>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <ReactToPrint
                      trigger={() =>
                          <Button
                              className=' mb-2 add-new-btn rounded me-1 text-white'
                              variant='info'

                          >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16.14'
                                height='15.14'
                                viewBox='0 0 16.14 15.14'
                            >
                              <g
                                  id='Icon_feather-printer'
                                  data-name='Icon feather-printer'
                                  transform='translate(-2.5 -3.5)'
                              >
                                <path
                                    id='Path_62'
                                    data-name='Path 62'
                                    d='M9,7.572V3h9.915V7.572'
                                    transform='translate(-3.388 1)'
                                    fill='none'
                                    stroke='#fff'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='1'
                                />
                                <path
                                    id='Path_63'
                                    data-name='Path 63'
                                    d='M6.028,19.378H4.514A1.422,1.422,0,0,1,3,18.072V14.806A1.422,1.422,0,0,1,4.514,13.5H16.626a1.422,1.422,0,0,1,1.514,1.306v3.266a1.422,1.422,0,0,1-1.514,1.306H15.112'
                                    transform='translate(0 -4.606)'
                                    fill='none'
                                    stroke='#fff'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='1'
                                />
                                <path
                                    id='Path_64'
                                    data-name='Path 64'
                                    d='M9,21h8.88v5.225H9Z'
                                    transform='translate(-2.87 -8.085)'
                                    fill='none'
                                    stroke='#fff'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='1'
                                />
                              </g>
                            </svg>
                            &nbsp;Print
                          </Button>
                      }
                      content={() => componentRef2.current}
                  />

                  <Button className=' mb-2 add-new-btn rounded me-1 text-white' variant='warning'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16.14'
                        height='16.14'
                        viewBox='0 0 16.14 16.14'
                    >
                      <g
                          id='Icon_feather-download'
                          data-name='Icon feather-download'
                          transform='translate(-4 -4)'
                      >
                        <path
                            id='Path_59'
                            data-name='Path 59'
                            d='M19.64,22.5v2.9a1.58,1.58,0,0,1-1.682,1.451H6.182A1.58,1.58,0,0,1,4.5,25.4V22.5'
                            transform='translate(0 -7.214)'
                            fill='none'
                            stroke='#fff'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                        />
                        <path
                            id='Path_60'
                            data-name='Path 60'
                            d='M10.5,15l4.667,3.628L19.834,15'
                            transform='translate(-3.097 -4.301)'
                            fill='none'
                            stroke='#fff'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                        />
                        <path
                            id='Path_61'
                            data-name='Path 61'
                            d='M18,13.208V4.5'
                            transform='translate(-5.93)'
                            fill='none'
                            stroke='#fff'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='1'
                        />
                      </g>
                    </svg>
                    &nbsp;Download
                  </Button>{' '}
                </Col>
                <Col sm={6}>
                  <Row className={'bg-dark mx-1'}>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Earnings</h6>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <h6 className='fw-bold'></h6>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Basic Pay</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.net_pay_without_two_hours).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Leave</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_leave).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Special Holiday</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_special_day_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Regular/Legal Holiday</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_regular_holiday_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Overtime</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_overtime_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Night Diff Amount</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.night_diff_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Sat. 2 Hours Amount</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.saturday_two_hours_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Five Days Incentive</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.five_days_incentive_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Adjustments</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.adjustment).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  {
                    payslipData?.salary_details_to_allowance.map((allowance, index) => (
                        allowances.map(allow => (
                            allowance.allowance_id == allow.allowance_id ?
                                <Row className={' p-0 mx-1'}>
                                  <Col sm={6}>
                                    <small>{allow.allowance_name}</small>
                                  </Col>
                                  <Col sm={6} className='d-grid justify-content-end'>
                                    <small>{Number(allowance.amount_of_allowance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                                  </Col>
                                </Row> : ""
                        ))

                    ))
                  }

                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Total Earnings</h6>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <h6 className='fw-bold'>{Number(payslipData?.total_earnings).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                    </Col>
                  </Row>
                  <hr />
                  {/* DEDUCTIONS*/}
                  <Row className={'bg-dark mx-1'}>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Deductions</h6>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <h6 className='fw-bold'></h6>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Absences/Suspensions</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_absence_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Late</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_late_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Undertime</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>{Number(payslipData?.total_undertime_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                    </Col>
                  </Row>
                  {
                    payslipData?.salary_details_to_deduction.map((deduction, index) => (
                        deductions.map(deduct => (
                            deduction.deduction_id == deduct.deduction_id ?
                                <Row key={index} className={' p-0 mx-1'}>
                                  <Col sm={6}>
                                    <small>{deduct.deduction_name}</small>
                                  </Col>
                                  <Col sm={6} className='d-grid justify-content-end'>
                                    <small>{Number(deduction.amount_of_deduction).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</small>
                                  </Col>
                                </Row> : ""
                        ))

                    ))
                  }


                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <small>Taxable Salary</small>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <small>0.00</small>
                    </Col>
                  </Row>
                  <Row className={' p-0 mx-1'}>
                    <Col sm={6}>
                      <h6 className='fw-bold'>Total Deductions</h6>
                    </Col>
                    <Col sm={6} className='d-grid justify-content-end'>
                      <h6 className='fw-bold'>{Number(payslipData?.total_deduction).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                    </Col>
                  </Row>
                  <hr />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
           {/*<Modal.Footer>*/}

           {/*   <Button variant="outline-primary" size={"sm"} onClick={handleClosePayslip}>*/}
           {/*       Close*/}
           {/*   </Button>*/}
           {/*</Modal.Footer>*/}
        </Modal>
        <br />
        <PrintPayslipComponent
            ref={componentRef2}
            payslipData={payslipData}
            allowances={allowances}
            deductions={deductions}
        />
      </Container>
    </>
  )
}
