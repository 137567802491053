import React, {useEffect, useState} from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Stack,
    Table,
} from 'react-bootstrap'
import './style.css'
import {
    EditOutlined,
    FilePresentOutlined,
    SaveAsOutlined,
    ViewInArOutlined,
} from '@mui/icons-material'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import axios from "../../../../api";
import {IAPI_Response} from "../../../../api/types";
import {
    API_ENDPOINT,
    ERROR_MESSAGE,
    PAYROLL_COMPUTATION,
    ROLE_PERMISSION,
    SUCCESS_MESSAGE,
    TEXT
} from "../../../../utils/globalConstant";
import {IList, IHoursShift} from './types'
import Pagination from 'components/Pagination'
import Alert from "components/Alert";
import moment from "moment/moment";
import Write from "asset/SvgComponent/Write";
import Delete from "asset/SvgComponent/Delete";
import Clock from "asset/SvgComponent/Clock";
import Dialog from "components/Dialog";

export default function Index() {

    const [error, setError] = React.useState('')
    const [showError, setShowError] = React.useState(false)

    const [currentPage, setCurrentPage] = React.useState(0)
    const [lastPage, setLastPage] = React.useState(0)
    const [itemsPerPage, setPerPage] = React.useState(6)
    const [itemsCount, setItemCount] = React.useState(0)
    const [from, setFrom] = React.useState(0)
    const [currentPage2, setCurrentPage2] = React.useState(0)
    const [lastPage2, setLastPage2] = React.useState(0)
    const [itemsPerPage2, setPerPage2] = React.useState(6)
    const [itemsCount2, setItemCount2] = React.useState(0)
    const [from2, setFrom2] = React.useState(0)
    const [currentPage3, setCurrentPage3] = React.useState(0)
    const [lastPage3, setLastPage3] = React.useState(0)
    const [itemsPerPage3, setPerPage3] = React.useState(6)
    const [itemsCount3, setItemCount3] = React.useState(0)
    const [from3, setFrom3] = React.useState(0)
    const [data, setData] = React.useState<IList[]>([])
    const [data2, setData2] = React.useState<IList[]>([])
    const [data3, setData3] = React.useState<IList[]>([])
    const [show, setShow] = React.useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [showLoading, setShowLoading] = React.useState(false)
    const [success, setSuccess] = React.useState('')
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [key, setKey] = useState('8-hour-shift')
    const [addedHoursShift, setAddedHoursShift] = React.useState({
        type: 0,
        time_from: "",
        time_to : "",
        overtime_in: "",
        overtime_out: ""
    })
    const [selectedHoursShift, setSelectedHoursShift] = React.useState(0)
    const [showConfirm, setShowConfirm] = React.useState(false)
    const handleCloseConfirm = () => {setShowConfirm(false)}
    const [showEdit, setShowEdit] = React.useState(false)
    const handleCloseEdit = () => setShowEdit(false)
    const [selectedData, setSelectedData] = React.useState({
        id: 0,
        time_from: "",
        time_to: "",
        isEightHourShift: 0,
        overtime_in: "",
        overtime_out: ""
    })
    const [showOvertimeFields, setShowOvertimeFields] = useState(false)


    const handleShowEdit = async (id: any) => {
        try {
            const response = await axios.get<IAPI_Response<IList>>(API_ENDPOINT.SHOW_HOURS_SHIFT +'/'+ id)
            const result = await response
            if (result?.data?.results) {
                setSelectedData(result?.data?.results)
            }
            setShowEdit(true)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const handleShowConfirm = (id: any) => {
        setShowConfirm(true)
        setSelectedHoursShift(id)
    }

    const handleChangeType = (e:any) => {
        setAddedHoursShift({...addedHoursShift, type: e.target.value})
        if(e.target.value == 2){
            setShowOvertimeFields(true)
        }else{
            setShowOvertimeFields(false)
        }
    }

    const handleChangeOvertimeIn = (e:any) => {
        setAddedHoursShift({...addedHoursShift, overtime_in: e.target.value})
    }

    const handleChangeOvertimeOut = (e:any) => {
        setAddedHoursShift({...addedHoursShift, overtime_out: e.target.value})
    }

    const handleChangeTimeFrom = (e:any) => {
        setAddedHoursShift({...addedHoursShift, time_from: e.target.value})
    }

    const handleChangeTimeTo = (e:any) => {
        setAddedHoursShift({...addedHoursShift, time_to: e.target.value})
    }

    const handleChangeEditType = (e:any) => {
        setSelectedData({...selectedData, isEightHourShift: e.target.value})
    }

    const handleChangeEditTimeFrom = (e:any) => {
        setSelectedData({...selectedData, time_from: e.target.value})
    }

    const handleChangeEditTimeTo = (e:any) => {
        setSelectedData({...selectedData, time_to: e.target.value})
    }

    const handleChangeEditOvertimeIn = (e:any) => {
        setSelectedData({...selectedData, overtime_in: e.target.value})
    }

    const handleChangeEditOvertimeOut = (e:any) => {
        setSelectedData({...selectedData, overtime_out: e.target.value})
    }

    const handleUpdateHoursShift = async() => {
        await axios.put(API_ENDPOINT.PUT_HOURS_SHIFT +'/'+ selectedData.id, selectedData).then(response => {
            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.HOURS_SHIFT.UPDATED)
                getEightHoursShift(null);
                getTwelveHoursShift(null);
                getEightHoursAndFourHoursOvertimeShift(null)
            }

            handleCloseEdit();
        }).catch(error => {
            getEightHoursShift(null);
            getTwelveHoursShift(null);
            getEightHoursAndFourHoursOvertimeShift(null)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const handleDeleteHoursShift = async (id : any) => {
        try {
            const response = await axios.delete(API_ENDPOINT.DELETE_HOURS_SHIFT +'/'+ id)
            const result = await response
            if (result?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.DEPARTMENT.DELETED)
                getEightHoursShift(null);
                getTwelveHoursShift(null);
                getEightHoursAndFourHoursOvertimeShift(null)
                setShowConfirm(false)
            }
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
            setShowConfirm(false)
        }

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const addHoursShift = async () => {
        await axios.post(API_ENDPOINT.POST_HOURS_SHIFT, addedHoursShift).then(response => {
            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.HOURS_SHIFT.CREATED)
                getEightHoursShift(null);
                getTwelveHoursShift(null);
                getEightHoursAndFourHoursOvertimeShift(null)
                setShow(false)
            }
        }).catch(error => {
            getEightHoursShift(null);
            getTwelveHoursShift(null);
            getEightHoursAndFourHoursOvertimeShift(null)
            setShow(false)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const getEightHoursShift = async (page_number: null | number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IHoursShift>>(API_ENDPOINT.ALL_HOURS_SHIFT+"/"+1, {
                params: {
                    page: page_number,
                },
            })
            const result = await response

            if (result?.data?.results?.data?.length) {
                setData(result?.data?.results?.data)
                setCurrentPage(result?.data?.results?.current_page)
                setLastPage(result?.data?.results?.last_page)
                setPerPage(result?.data?.results?.per_page)
                setItemCount(result?.data?.results?.total)
                setFrom(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const getTwelveHoursShift = async (page_number: null | number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IHoursShift>>(API_ENDPOINT.ALL_HOURS_SHIFT+"/"+0, {
                params: {
                    page: page_number,
                },
            })
            const result = await response

            if (result?.data?.results?.data?.length) {
                setData2(result?.data?.results?.data)
                setCurrentPage(result?.data?.results?.current_page)
                setLastPage(result?.data?.results?.last_page)
                setPerPage(result?.data?.results?.per_page)
                setItemCount(result?.data?.results?.total)
                setFrom(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const getEightHoursAndFourHoursOvertimeShift = async (page_number: null | number) => {
        setShowLoading(true)
        try {
            const response = await axios.get<IAPI_Response<IHoursShift>>(API_ENDPOINT.ALL_HOURS_SHIFT+"/"+2, {
                params: {
                    page: page_number,
                },
            })
            const result = await response

            if (result?.data?.results?.data?.length) {
                setData3(result?.data?.results?.data)
                setCurrentPage3(result?.data?.results?.current_page)
                setLastPage3(result?.data?.results?.last_page)
                setPerPage3(result?.data?.results?.per_page)
                setItemCount3(result?.data?.results?.total)
                setFrom3(result?.data?.results?.from)
            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }


    useEffect(() => {
        ;(async () => {
            getEightHoursShift(null)
            getTwelveHoursShift(null)
            getEightHoursAndFourHoursOvertimeShift(null)
        })()
    }, [])


    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Shifts</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none' href='#'>
                        Setup
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className=' text-decoration-none' href='#'>
                        Employee
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Shifts</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col sm={6}>
                        <span className='fw-bold'>&nbsp;</span>
                    </Col>
                    <Col sm={6} className='d-flex justify-content-end hidden'>
                        <Button
                            onClick={handleShow}
                            className=' add-new-btn rounded mx-2 text-white'
                            variant='warning'
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='17'
                                height='17'
                                viewBox='0 0 20.055 20.207'
                            >
                                <g id='Edit-Icon' transform='translate(0.5 0.652)'>
                                    <g id='Icon_feather-edit' data-name='Icon feather-edit'>
                                        <path
                                            id='Path_44'
                                            data-name='Path 44'
                                            d='M11.523,6H4.894A1.894,1.894,0,0,0,3,7.894V21.152a1.894,1.894,0,0,0,1.894,1.894H18.152a1.894,1.894,0,0,0,1.894-1.894V14.523'
                                            transform='translate(-3 -3.991)'
                                            fill='none'
                                            stroke='#fff'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='1'
                                        />
                                        <path
                                            id='Path_45'
                                            data-name='Path 45'
                                            d='M21.943,3.406a2.009,2.009,0,1,1,2.841,2.841l-9,9L12,16.191l.947-3.788Z'
                                            transform='translate(-6.318 -2.818)'
                                            fill='none'
                                            stroke='#fff'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            strokeWidth='1'
                                        />
                                    </g>
                                </g>
                            </svg>
                            &nbsp;Add Hours Shift
                        </Button>{' '}
                    </Col>
                </Row>
                <Tabs
                    variant={'tabs'}
                    className='tabs '
                    id='controlled-tab-example'
                    activeKey={key}
                    onSelect={(k: any) => {
                        setKey(k)
                    }}
                >
                    <Tab className='p-0' eventKey='8-hour-shift' title='8 Hours'>
                        <Card className='border-0  p-0 bordered-card-tabs'>
                            <Card.Body>
                                <Table responsive className='bordered-tabs-top mt-3'>
                                    <thead>
                                    <tr className='text-center'>
                                        <th style={{ width: '30%' }}>Time From</th>
                                        <th style={{ width: '30%' }}>Time To</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data.length > 0 ? (
                                            data.map((payrollComputation) => (
                                                <tr className='mb-2' key={payrollComputation.id}>
                                                    <td className='text-center'>{moment(new Date("2023-01-01 " + payrollComputation.time_from ?? "")).format("h:mm A")}</td>
                                                    <td className='text-center'>{moment(new Date("2023-01-01 " + payrollComputation.time_to ?? "")).format("h:mm A")}</td>
                                                    <td className='text-center'>
                                                        <Button
                                                            onClick={() => handleShowEdit(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Write/>
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleShowConfirm(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Delete/>
                                                        </Button>
                                                    </td>
                                                    <br />
                                                </tr>
                                            ))
                                        ) : (
                                            <tr className='no-item'>
                                                <td colSpan={3}>{TEXT.NOT_FOUND}</td>
                                            </tr>
                                        )
                                    }

                                    </tbody>
                                </Table>
                                <Pagination
                                    itemsCount={itemsCount}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    lastPage={lastPage}
                                    setCurrentPage={setCurrentPage}
                                    alwaysShown={false}
                                    pageClicked={(page: number) => {
                                        getEightHoursShift(page);
                                    }
                                    }
                                />
                            </Card.Body>
                        </Card>
                    </Tab>

                    <Tab className='p-0' eventKey='8-hour-and-4-hours-overtime-shift' title='8 Hrs. & 4 Hrs. OT'>
                        <Card className='border-0  p-0 bordered-card-tabs'>
                            <Card.Body>
                                <Table responsive className='bordered-tabs-top mt-3'>
                                    <thead>
                                    <tr className='text-center'>
                                        <th style={{ width: '18%' }}>Time From</th>
                                        <th style={{ width: '18%' }}>Time To</th>
                                        <th style={{ width: '18%' }}>Overtime In</th>
                                        <th style={{ width: '18%' }}>Overtime Out</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data3.length > 0 ? (
                                            data3.map((payrollComputation) => (
                                                <tr className='mb-2' key={payrollComputation.id}>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.time_from ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.time_to ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.overtime_in ?? "")).format("h:mm A")}</td>
                                                    <td className="text-center">{moment(new Date("2023-01-01 " + payrollComputation.overtime_out ?? "")).format("h:mm A")}</td>
                                                    <td className='text-center'>
                                                        <Button
                                                            onClick={() => handleShowEdit(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Write/>
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleShowConfirm(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Delete/>
                                                        </Button>
                                                    </td>
                                                    <br />
                                                </tr>
                                            ))
                                        ) : (
                                            <tr className='no-item'>
                                                <td colSpan={5}>{TEXT.NOT_FOUND}</td>
                                            </tr>
                                        )
                                    }

                                    </tbody>
                                </Table>
                                <Pagination
                                    itemsCount={itemsCount3}
                                    itemsPerPage={itemsPerPage3}
                                    currentPage={currentPage3}
                                    lastPage={lastPage3}
                                    setCurrentPage={setCurrentPage3}
                                    alwaysShown={false}
                                    pageClicked={(page: number) => {
                                        getEightHoursAndFourHoursOvertimeShift(page);
                                    }
                                    }
                                />
                            </Card.Body>
                        </Card>
                    </Tab>

                    <Tab className='p-0' eventKey='12-hour-shift' title='12 Hours'>
                        <Card className='border-0  p-0 bordered-card-tabs'>
                            <Card.Body>
                                <Table responsive className='bordered-tabs-top mt-3'>
                                    <thead>
                                    <tr className='text-center'>
                                        <th style={{ width: '30%' }}>Time From</th>
                                        <th style={{ width: '30%' }}>Time To</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data.length > 0 ? (
                                            data2.map((payrollComputation) => (
                                                <tr className='mb-2' key={payrollComputation.id}>
                                                    <td className='text-center'>{moment(new Date("2023-01-01 " + payrollComputation.time_from ?? "")).format("h:mm A")}</td>
                                                    <td className='text-center'>{moment(new Date("2023-01-01 " + payrollComputation.time_to ?? "")).format("h:mm A")}</td>
                                                    <td className='text-center'>
                                                        <Button
                                                            onClick={() => handleShowEdit(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Write/>
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleShowConfirm(payrollComputation.id)}
                                                            className=' p-1 pt-0 pb-0'
                                                            style={{ fontSize: '12px', color: 'white' }}
                                                            variant=' btn-transparent'
                                                            size='sm'
                                                        >
                                                            <Delete/>
                                                        </Button>
                                                    </td>
                                                    <br />
                                                </tr>
                                            ))
                                        ) : (
                                            <tr className='no-item'>
                                                <td colSpan={3}>{TEXT.NOT_FOUND}</td>
                                            </tr>
                                        )
                                    }

                                    </tbody>
                                </Table>
                                <Pagination
                                    itemsCount={itemsCount2}
                                    itemsPerPage={itemsPerPage2}
                                    currentPage={currentPage2}
                                    lastPage={lastPage2}
                                    setCurrentPage={setCurrentPage2}
                                    alwaysShown={false}
                                    pageClicked={(page: number) => {
                                        getTwelveHoursShift(page);
                                    }
                                    }
                                />
                            </Card.Body>
                        </Card>
                    </Tab>

                </Tabs>

                 {/*ADD MODAL*/}
                <Modal className='modal' centered show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Add Hours Shift</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group as={Col} md="12" className="position-relative">
                                    <Form.Label column="sm">Select Type</Form.Label>
                                    <Form.Select
                                        size={'sm'}
                                        name="hours_shift"
                                        // value={values.work_site}
                                        onChange={handleChangeType}
                                        // onBlur={handleBlur}
                                        // isInvalid={touched.work_site && !!errors.work_site}
                                    >
                                        <option value="" selected>Select Hours Shift</option>
                                        <option value="1">8 Hours Shift</option>
                                        <option value="2">8 Hours Shift & 4 Hours OT</option>
                                        <option value="0">12 Hours Shift</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <br/>
                            <Row>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Time From</Form.Label>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text id='basic-addon1'>
                                            <Clock/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            size={'sm'}
                                            type='time'
                                            placeholder=''
                                            name={`time_from`}
                                            onChange={handleChangeTimeFrom}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Time To</Form.Label>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text id='basic-addon1'>
                                            <Clock/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            size={'sm'}
                                            type='time'
                                            placeholder=''
                                            name={`time_to`}
                                            onChange={handleChangeTimeTo}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                            <br/>
                            {
                                showOvertimeFields ?
                                    <Row>
                                        <Form.Group as={Col} md="6" className="position-relative">
                                            <Form.Label column="sm">Overtime In</Form.Label>
                                            <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                    <Clock/>
                                                </InputGroup.Text>
                                                <Form.Control
                                                    size={'sm'}
                                                    type='time'
                                                    placeholder=''
                                                    name={`overtime_in`}
                                                    onChange={handleChangeOvertimeIn}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className="position-relative">
                                            <Form.Label column="sm">Overtime Out</Form.Label>
                                            <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                    <Clock/>
                                                </InputGroup.Text>
                                                <Form.Control
                                                    size={'sm'}
                                                    type='time'
                                                    placeholder=''
                                                    name={`overtime_out`}
                                                    onChange={handleChangeOvertimeOut}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Row> : ""
                            }
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' size={'sm'} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={addHoursShift}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/*EDIT MODAL*/}
                <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fw-bold'>Add Hours Shift</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Form.Group as={Col} md="12" className="position-relative">
                                    <Form.Label column="sm">Select Type</Form.Label>
                                    <Form.Select
                                        size={'sm'}
                                        name="hours_shift"
                                        value={selectedData.isEightHourShift}
                                        onChange={handleChangeEditType}
                                    >
                                        <option value="" selected>Select Hours Shift</option>
                                        <option value="0">12 Hours Shift</option>
                                        <option value="1">8 Hours Shift</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <br/>
                            <Row>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Time From</Form.Label>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text id='basic-addon1'>
                                            <Clock/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            size={'sm'}
                                            type='time'
                                            placeholder=''
                                            name={`time_from`}
                                            value={selectedData.time_from}
                                            onChange={handleChangeEditTimeFrom}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="position-relative">
                                    <Form.Label column="sm">Time To</Form.Label>
                                    <InputGroup size={'sm'}>
                                        <InputGroup.Text id='basic-addon1'>
                                            <Clock/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            size={'sm'}
                                            type='time'
                                            placeholder=''
                                            name={`time_to`}
                                            value={selectedData.time_to}
                                            onChange={handleChangeEditTimeTo}
                                            // onBlur={handleBlur}
                                            // isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.start_from : false) && !!ticketTouched.start_from}
                                            // disabled={values.work_shift_id != '0' ? true : false}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Row>
                            <br/>
                            {
                                selectedData.isEightHourShift == 2 ?
                                    <Row>
                                        <Form.Group as={Col} md="6" className="position-relative">
                                            <Form.Label column="sm">Overtime In</Form.Label>
                                            <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                    <Clock/>
                                                </InputGroup.Text>
                                                <Form.Control
                                                    size={'sm'}
                                                    type='time'
                                                    placeholder=''
                                                    name={`overtime_in`}
                                                    value={selectedData.overtime_in}
                                                    onChange={handleChangeEditOvertimeIn}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className="position-relative">
                                            <Form.Label column="sm">Overtime Out</Form.Label>
                                            <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                    <Clock/>
                                                </InputGroup.Text>
                                                <Form.Control
                                                    size={'sm'}
                                                    type='time'
                                                    placeholder=''
                                                    name={`time_to`}
                                                    value={selectedData.overtime_out}
                                                    onChange={handleChangeEditOvertimeOut}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Row> : ""
                            }

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                            Cancel
                        </Button>
                        <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={handleUpdateHoursShift}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Dialog
                    show={showConfirm}
                    setShow={handleCloseConfirm}
                    setEvent={() => {
                        handleDeleteHoursShift(selectedHoursShift)
                    }
                    }
                />

                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <br />
            </Container>
        </>


    )
}
