import React, { Component } from 'react'
import moment from 'moment'

class Index extends Component {
  render() {
    return (
      <footer className='footer text-center bottom-0'>
        <h6>
          <a
            className='text-muted text-decoration-none'
            href='https://octaneware.com'
            target='_blank'
            rel='noreferrer'
          >
            © Copyright {moment().year()} Octaneware. All rights reserved.
          </a>
        </h6>
      </footer>
    )
  }
}
export default Index
