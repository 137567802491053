import React from 'react'

const Password = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='23.172'
            height='22.828'
            viewBox='0 0 24.172 22.828'
        >
            <g id='Password' transform='translate(1 1.414)'>
                <g id='Icon_feather-eye' data-name='Icon feather-eye' transform='translate(-1.5 -3.912)'>
                    <path
                        id='Path_21'
                        data-name='Path 21'
                        d='M1.5,13.913S5.456,6,12.38,6s10.88,7.913,10.88,7.913S19.3,21.825,12.38,21.825,1.5,13.913,1.5,13.913Z'
                        fill='none'
                        stroke='#a7a9ac'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                    />
                    <path
                        id='Path_22'
                        data-name='Path 22'
                        d='M19.434,16.467A2.967,2.967,0,1,1,16.467,13.5,2.967,2.967,0,0,1,19.434,16.467Z'
                        transform='translate(-4.087 -2.555)'
                        fill='none'
                        stroke='#f26930'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                    />
                </g>
            </g>
        </svg>
    )
}

export default Password
