import React from 'react'

const DepartmentIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36.182'
      height='39.948'
      viewBox='0 0 36.182 39.948'
    >
      <g id='Icon_feather-box' data-name='Icon feather-box' transform='translate(-2.947 -1.449)'>
        <path
          id='Path_17'
          data-name='Path 17'
          d='M37.575,28.724v-14.7a3.675,3.675,0,0,0-1.837-3.179L22.875,3.5a3.675,3.675,0,0,0-3.675,0L6.337,10.845A3.675,3.675,0,0,0,4.5,14.024v14.7A3.675,3.675,0,0,0,6.337,31.9L19.2,39.253a3.675,3.675,0,0,0,3.675,0L35.737,31.9a3.675,3.675,0,0,0,1.838-3.179Z'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          id='Path_18'
          data-name='Path 18'
          d='M4.905,10.44l16.041,9.279L36.988,10.44'
          transform='translate(0.091 1.673)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
        <path
          id='Path_19'
          data-name='Path 19'
          d='M18,36.522V18'
          transform='translate(3.037 3.374)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}

export default DepartmentIcon
