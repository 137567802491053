import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IEducationValue } from './types'

const initialState: IEducationValue[] = [{
  board_university: '',
  degree: '',
  cgpa: '',
  institute: '',
  passing_year: '',
  result: '',
}]

const EducationSlice = createSlice({
  name: 'Education',
  initialState,
  reducers: { editEducation: (_state, action: PayloadAction<IEducationValue[]>) => action.payload },
})

export const { editEducation } = EducationSlice.actions
export default EducationSlice.reducer
