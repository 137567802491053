import React, { useEffect, useState, useRef } from 'react'
import moment from "moment";
import {styled, Typography} from "@mui/material";

const StyledTypography = styled(Typography)(({ theme }) => ({
    marginRight: '35px',
    fontSize: theme.spacing(3),
    fontWeight: theme.fontStyle.weight.bold,
    lineHeight: `${theme.spacing(4)}px`,
}))

const Runningtime = (props: {in_time: string,
    out_time: string,
    worked_time: string,
    type: string,
    break_in: string,
    break_out: string,
    in_time_pm?: string,
    out_time_pm?: string,
    worked_time_am?: string,
    worked_time_pm?: string,
    on_dashboard?: boolean,
    start_from?: string,
    end_to?: string,
    break_start?: string,
    break_end?: string,
    grace_period_in?: string,
    grace_period_out?: string} ) => {
    const in_time = props.in_time;
    const out_time = props.out_time;
    const in_time_pm = props.in_time_pm;
    const out_time_pm = props.out_time_pm;
    const worked_time = props.worked_time;
    const worked_time_am = props.worked_time_am;
    const worked_time_pm = props.worked_time_pm;
    const type = props.type;
    const break_in = props.break_in;
    const break_out = props.break_out;
    const onDashboard = props.on_dashboard;
    const start_from = props.start_from;
    const end_to = props.end_to;
    const break_start = props.break_start;
    const break_end = props.break_end;
    const grace_period_in = props.grace_period_in;
    const grace_period_out = props.grace_period_out;


    const [running ,setRunning] = useState(false)
    const [value, setValue] = useState('');

    useEffect(() => {
        const clientProfile = localStorage.getItem('clientProfile');

        if(clientProfile == "construction"){
            if(in_time != null && out_time == null){
                if(start_from){
                    const in_time_format = moment(moment(in_time).format("HH:mm:00"), "HH:mm:00");
                    const start_from_format = moment(start_from, "HH:mm:ss")
                    const start_with_grace_period_am = moment(start_from, "HH:mm:00").add(grace_period_in, 'minutes')

                    if(in_time_format.isBefore(start_from_format)){  // if time in before the start of the shift

                        const timer = setInterval(() => {
                            const now_format = moment(moment().format("HH:mm:ss"),'HH:mm:ss');

                            if(now_format.isBefore(start_from_format)){
                                setRunning(false);
                                setValue("00:00:00")
                            }else{
                                const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                                const then = moment(moment(in_time).format("YYYY-MM-DD") + " " + start_from).format("YYYY-MM-DD HH:mm:ss");

                                setRunning(true);

                                setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                            }

                        }, 1000);

                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);

                    }else if(in_time_format.isBefore(start_with_grace_period_am) || in_time_format == start_with_grace_period_am){   // if time in on the grace period

                        const timer = setInterval(() => {

                            const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                            const then = moment(start_from_format).format("YYYY-MM-DD HH:mm:ss");

                            setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                        }, 1000);
                        setRunning(true);
                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);

                    }else{   // time in after the shift start

                        const timer = setInterval(() => {

                            const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                            const then = moment(in_time).format("YYYY-MM-DD HH:mm:ss");

                            setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                        }, 1000);
                        setRunning(true);
                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);
                    }
                }else{
                    const timer = setInterval(() => {

                        const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                        const then = moment(in_time).format("YYYY-MM-DD HH:mm:ss");

                        setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                    }, 1000);
                    setRunning(true);
                    // Cleanup the timer when the component unmounts
                    return () => clearInterval(timer);
                }



            }else if(in_time != null && out_time != null && in_time_pm == null && out_time_pm == null){
                setRunning(false)
                setValue(worked_time);
            }else if(in_time != null && out_time != null && in_time_pm != null && out_time_pm == null){

                if(break_end){
                    const in_time_pm_format = moment(moment(in_time_pm).format("HH:mm:00"), "HH:mm:00");
                    // const break_end_format = moment(break_end, "HH:mm:ss")
                    const break_end_format = moment(break_end, "HH:mm:ss")
                    const start_with_grace_period_pm = moment(break_end, "HH:mm:ss").add(grace_period_out, 'minutes')

                    if(in_time_pm_format.isBefore(break_end_format) || in_time_pm_format == break_end_format) {  // if time in before the start of the shift on PM

                        const timer = setInterval(() => {
                            const now_format = moment(moment().format("HH:mm:ss"),'HH:mm:ss');

                            if(now_format.isBefore(break_end_format)){
                                setRunning(false);
                                setValue(worked_time)
                            }else{
                                const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                                const then = moment(moment(in_time_pm).format("YYYY-MM-DD") + " " + break_end).format("YYYY-MM-DD HH:mm:ss");
                                const differenceTimePM = moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss");

                                const de = moment.duration(worked_time_am).add(moment.duration(differenceTimePM))
                                setValue(moment.utc(de.as('milliseconds')).format("HH:mm:ss"))
                                setRunning(true);
                            }

                        }, 1000);

                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);

                    }else if(in_time_pm_format.isBefore(start_with_grace_period_pm) || in_time_pm_format == start_with_grace_period_pm){   // if time in on the grace period
                        const timer = setInterval(() => {

                            const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                            const then = moment(break_end_format).format("YYYY-MM-DD HH:mm:ss");
                            const differenceTimePM = moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss");

                            const de = moment.duration(worked_time_am).add(moment.duration(differenceTimePM))
                            setValue(moment.utc(de.as('milliseconds')).format("HH:mm:ss"))
                        }, 1000);
                        setRunning(true);
                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);

                    }else{
                        const timer = setInterval(() => {

                            const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                            const then = moment(in_time_pm).format("YYYY-MM-DD HH:mm:ss");
                            const differenceTimePM = moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss");

                            const de = moment.duration(worked_time_am).add(moment.duration(differenceTimePM))
                            setValue(moment.utc(de.as('milliseconds')).format("HH:mm:ss"))
                        }, 1000);
                        setRunning(true);
                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);
                    }

                }else{
                    const timer = setInterval(() => {

                        const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                        const then = moment(in_time_pm).format("YYYY-MM-DD HH:mm:ss");
                        const differenceTimePM = moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss");

                        const de = moment.duration(worked_time_am).add(moment.duration(differenceTimePM))
                        setValue(moment.utc(de.as('milliseconds')).format("HH:mm:ss"))
                    }, 1000);
                    setRunning(true);
                    // Cleanup the timer when the component unmounts
                    return () => clearInterval(timer);
                }

            }else if(in_time != null && out_time != null && in_time_pm != null && out_time_pm != null){
                setValue(worked_time);
                setRunning(false);
            }else if(in_time == null && out_time == null && in_time_pm != null && out_time_pm == null){
                const in_time_format_pm = moment(moment(in_time_pm).format("HH:mm:00"), "HH:mm:00");
                const break_end_format = moment(break_end, "HH:mm:ss")
                const start_with_grace_period_pm = moment(break_end, "HH:mm:ss").add(grace_period_out, 'minutes')

                if(in_time_format_pm.isBefore(start_with_grace_period_pm) || in_time_format_pm == start_with_grace_period_pm){   // if time in on the grace period
                    const timer = setInterval(() => {

                        const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                        const then = moment(break_end_format).format("YYYY-MM-DD HH:mm:ss");

                        setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                    }, 1000);
                    setRunning(true);
                    // Cleanup the timer when the component unmounts
                    return () => clearInterval(timer);

                }else{
                    const timer = setInterval(() => {
                        const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                        const then = moment(in_time_pm).format("YYYY-MM-DD HH:mm:ss");
                        setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                    }, 1000);
                    setRunning(true);
                    // Cleanup the timer when the component unmounts
                    return () => clearInterval(timer);
                }


            }else if(in_time == null && out_time == null && in_time_pm != null && out_time_pm != null){
                setValue(worked_time);
                setRunning(false)
            }else{
                if(onDashboard){
                    setValue("00:00:00");
                }else{
                    setValue("-");
                }

            }

        }else if(clientProfile == "retail" || clientProfile == "standard"){

            if(in_time != null && out_time == null){

                if(start_from){
                    const in_time_format = moment(moment(in_time).format("HH:mm:00"), "HH:mm:00");
                    const start_from_format = moment(start_from, "HH:mm:ss")
                    const start_with_grace_period_am = moment(start_from, "HH:mm:00").add(grace_period_in, 'minutes')

                    if(in_time_format.isBefore(start_from_format)){  // if time in before the start of the shift

                        const timer = setInterval(() => {
                            const now_format = moment(moment().format("HH:mm:ss"),'HH:mm:ss');

                            if(now_format.isBefore(start_from_format)){
                                setRunning(false);
                                setValue("00:00:00")
                            }else{
                                const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                                const then = moment(moment(in_time).format("YYYY-MM-DD") + " " + start_from).format("YYYY-MM-DD HH:mm:ss");

                                setRunning(true);

                                setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                            }

                        }, 1000);

                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);

                    }else if(in_time_format.isBefore(start_with_grace_period_am) || in_time_format == start_with_grace_period_am){   // if time in on the grace period

                        const timer = setInterval(() => {

                            const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                            const then = moment(start_from_format).format("YYYY-MM-DD HH:mm:ss");

                            setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                        }, 1000);
                        setRunning(true);
                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);

                    }else{   // time in after the shift start

                        const timer = setInterval(() => {

                            const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                            const then = moment(in_time).format("YYYY-MM-DD HH:mm:ss");

                            setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                        }, 1000);
                        setRunning(true);
                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);
                    }
                }else{
                    const timer = setInterval(() => {

                        const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                        const then = moment(in_time).format("YYYY-MM-DD HH:mm:ss");

                        setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                    }, 1000);
                    setRunning(true);
                    // Cleanup the timer when the component unmounts
                    return () => clearInterval(timer);
                }



            }else if(in_time != null && out_time != null){
                setRunning(false)
                setValue(worked_time);
            }else{
                if(onDashboard){

                    setValue("00:00:00");
                }else{
                    setValue("-");
                }

            }
        }else if(clientProfile == "architecture"){

            if(in_time != null && out_time == null){
                if(start_from){
                    const in_time_format = moment(moment(in_time).format("HH:mm:00"), "HH:mm:00");
                    const start_from_format = moment(start_from, "HH:mm:ss")
                    const start_with_grace_period_am = moment(start_from, "HH:mm:00").add(grace_period_in, 'minutes')

                    if(in_time_format.isBefore(start_from_format)){  // if time in before the start of the shift

                        const timer = setInterval(() => {
                            const now_format = moment(moment().format("HH:mm:ss"),'HH:mm:ss');

                            if(now_format.isBefore(start_from_format)){
                                setRunning(false);
                                setValue("00:00:00")
                            }else{
                                const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                                const then = moment(moment(in_time).format("YYYY-MM-DD") + " " + start_from).format("YYYY-MM-DD HH:mm:ss");

                                setRunning(true);

                                setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                            }

                        }, 1000);

                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);

                    }else if(in_time_format.isBefore(start_with_grace_period_am) || in_time_format == start_with_grace_period_am){   // if time in on the grace period

                        const timer = setInterval(() => {

                            const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                            const then = moment(start_from_format).format("YYYY-MM-DD HH:mm:ss");

                            setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                        }, 1000);
                        setRunning(true);
                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);

                    }else{   // time in after the shift start

                        const timer = setInterval(() => {

                            const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                            const then = moment(in_time).format("YYYY-MM-DD HH:mm:ss");

                            setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                        }, 1000);
                        setRunning(true);
                        // Cleanup the timer when the component unmounts
                        return () => clearInterval(timer);
                    }
                }else{
                    const timer = setInterval(() => {

                        const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                        const then = moment(in_time).format("YYYY-MM-DD HH:mm:ss");

                        setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                    }, 1000);
                    setRunning(true);
                    // Cleanup the timer when the component unmounts
                    return () => clearInterval(timer);
                }



            }else if(in_time != null && out_time != null){
                setValue(worked_time);
            }else{
                if(onDashboard){
                    setValue("00:00:00");
                }else{
                    setValue("-");
                }

            }
        }else{
            if(type == "worked_time"){
                if(in_time != null && out_time == null && break_in == null){
                    const timer = setInterval(() => {

                        const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                        const then = moment(in_time).format("YYYY-MM-DD HH:mm:ss");

                        setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                    }, 1000);
                    setRunning(true);
                    // Cleanup the timer when the component unmounts
                    return () => clearInterval(timer);
                }else if(in_time != null && out_time == null && break_in != null && break_out == null){

                    const now  = moment(break_in).format("YYYY-MM-DD HH:mm:ss");
                    const then = moment(in_time).format("YYYY-MM-DD HH:mm:ss");

                    setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                }else if(in_time != null && out_time == null && break_in != null && break_out != null){

                    const timer = setInterval(() => {
                        //time in
                        const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                        const then = moment(in_time).format("YYYY-MM-DD HH:mm:ss");
                        const worked_time = moment(moment(now).diff(moment(then))).format("HH:mm:ss");

                        //breaks
                        const actual_break_out  = moment(break_out).format("YYYY-MM-DD HH:mm:ss");
                        const actual_break_in = moment(break_in).format("YYYY-MM-DD HH:mm:ss");
                        const breaked_time = moment(moment(actual_break_out).diff(moment(actual_break_in))).format("HH:mm:ss")

                        const diff = moment(moment(worked_time, "HH:mm:ss").subtract(breaked_time)).format("HH:mm:ss")
                        setValue(diff)
                    }, 1000);
                    setRunning(true);
                    // Cleanup the timer when the component unmounts
                    return () => clearInterval(timer);
                }else if(in_time != null && out_time != null){
                    setValue(worked_time);
                }else{
                    setValue('-')
                }
            }else{
                if(break_in != null && break_out == null){
                    const timer = setInterval(() => {

                        const now  = moment().format("YYYY-MM-DD HH:mm:ss");
                        const then = moment(break_in).format("YYYY-MM-DD HH:mm:ss");

                        setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                    }, 1000);
                    setRunning(true);
                    // Cleanup the timer when the component unmounts
                    return () => clearInterval(timer);
                }else if(break_in != null && break_out != null){
                    const now  = moment(break_out).format("YYYY-MM-DD HH:mm:ss");
                    const then = moment(break_in).format("YYYY-MM-DD HH:mm:ss");

                    setValue(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
                }else{
                    setValue('-')
                }

            }
        }


    }, [in_time, out_time, in_time_pm, out_time_pm, worked_time, worked_time_am, worked_time_pm, start_from, end_to]);

    return !onDashboard ? <td className={running ? "text-center text-success": "text-center"}>{value}</td> : <StyledTypography className={running ? "text-center text-success": "text-center"}>{value}</StyledTypography>
}

export default Runningtime;