import React from 'react'

const AttendanceActiveIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='15.156'
      height='16.229'
      viewBox='0 0 15.156 16.229'
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='0.5'
          x2='0.5'
          y2='1'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#f26930' />
          <stop offset='1' stopColor='#fba91a' />
        </linearGradient>
      </defs>
      <g id='AttendanceActive' transform='translate(0.5 0.5)'>
        <g id='Icon_feather-calendar' data-name='Icon feather-calendar'>
          <path
            id='Path_1'
            data-name='Path 1'
            d='M6.073,6h11.01a1.553,1.553,0,0,1,1.573,1.532V18.259a1.553,1.553,0,0,1-1.573,1.532H6.073A1.553,1.553,0,0,1,4.5,18.259V7.532A1.553,1.553,0,0,1,6.073,6Z'
            transform='translate(-4.5 -4.064)'
            fill='url(#linear-gradient)'
          />
          <path
            id='Path_2'
            data-name='Path 2'
            d='M24,3V6.873'
            transform='translate(-13.777 -3)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
          <path
            id='Path_3'
            data-name='Path 3'
            d='M12,3V6.873'
            transform='translate(-8.068 -3)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
          <path
            id='Path_4'
            data-name='Path 4'
            d='M4.5,15H18.655'
            transform='translate(-4.5 -8.709)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
          />
        </g>
        <path
          id='Icon_feather-check'
          data-name='Icon feather-check'
          d='M11.7,9,7.78,12.916,6,11.136'
          transform='translate(-1.551 -0.263)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default AttendanceActiveIcon
