import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  date_from: yup.string().required(VALIDATION.required),
  date_to: yup.string().required(VALIDATION.required),
  time_from: yup.string().required(VALIDATION.required),
  time_to: yup.string().required(VALIDATION.required),
  location: yup.string().required(VALIDATION.required),
  reason: yup.string().required(VALIDATION.required),
})
