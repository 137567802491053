import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  undertime_date: yup.string().required(VALIDATION.required),
  undertime_start: yup.string().required(VALIDATION.required),
  undertime_end: yup.string().required(VALIDATION.required),
  no_of_hours: yup.string().required(VALIDATION.required),
  reason: yup.string().required(VALIDATION.required),
})
