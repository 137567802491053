import * as React from "react"
const QuestionMarkIcon = () => (
    // <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     imageRendering="optimizeQuality"
    //     shapeRendering="geometricPrecision"
    //     textRendering="geometricPrecision"
    //     viewBox="0 0 301 511.26"
    //     width='17'
    //     height='17'
    // >
    //     <path
    //         fill="#707070"
    //         fillRule="nonzero"
    //         d="M102.05 362.83v-4.57c.35-30.36 3.33-54.41 9.17-72.28 5.73-17.86 13.97-32.4 24.73-43.4 10.77-11.11 23.72-21.19 38.84-30.36 9.73-6.18 18.55-13.05 26.34-20.72 7.78-7.57 13.96-16.39 18.55-26.35 4.58-9.85 6.87-20.84 6.87-32.98 0-14.43-3.44-26.92-10.31-37.57-6.76-10.54-15.92-18.67-27.15-24.5-11.34-5.73-23.93-8.59-37.79-8.59-12.48 0-24.39 2.51-35.84 7.78-11.34 5.16-20.85 13.28-28.3 24.28-7.44 10.99-11.79 25.09-12.94 42.27H0c1.15-29.22 8.59-53.95 22.22-74.22 13.75-20.27 31.85-35.63 54.29-46.04C99.08 5.16 124.05 0 151.3 0c30 0 56.12 5.49 78.57 16.72 22.45 11.11 39.97 26.69 52.46 46.61C294.69 83.26 301 106.4 301 132.98c0 18.2-2.86 34.58-8.59 49.13-5.73 14.54-13.75 27.6-24.28 38.93-10.55 11.46-23.03 21.54-37.68 30.24-13.86 8.71-25.09 17.64-33.56 26.92-8.59 9.27-14.78 20.27-18.67 32.87-3.9 12.71-6.08 28.4-6.41 47.19v4.57h-69.76zm36.88 148.43c-13.39-.1-24.85-4.8-34.47-14.31-9.62-9.5-14.32-21.07-14.32-34.59 0-13.28 4.7-24.73 14.32-34.24 9.62-9.51 21.08-14.32 34.47-14.32 13.17 0 24.62 4.81 34.25 14.32 9.73 9.51 14.54 20.96 14.54 34.24 0 8.93-2.28 17.18-6.75 24.52-4.47 7.44-10.32 13.4-17.64 17.75-7.33 4.35-15.47 6.53-24.4 6.63z"
    //     />
    // </svg>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width='23'
        height='23'
        fill="#F46F2E"
        viewBox="0 0 520 520"
    >
        <path
            d="M256 0C114.616 0 0 114.612 0 256s114.616 256 256 256 256-114.612 256-256S397.385 0 256 0zm-48.322 378.794c0-17.612 14.281-31.893 31.893-31.893 17.599 0 31.88 14.281 31.88 31.893 0 17.595-14.281 31.884-31.88 31.884-17.612 0-31.893-14.289-31.893-31.884zm135.947-159.942c-3.596 9.793-8.802 18.289-14.695 25.356-11.847 14.148-25.888 22.718-37.442 29.041-7.719 4.174-14.533 7.389-18.769 9.769-2.905 1.604-4.479 2.95-5.256 3.826-.768.926-1.029 1.306-1.496 2.826-.273 1.009-.558 2.612-.558 5.091v12.512c0 6.472-5.248 11.728-11.723 11.728h-28.252c-6.475 0-11.732-5.256-11.732-11.728v-12.512c0-6.438.752-12.744 2.405-18.777 1.636-6.008 4.215-11.718 7.508-16.694 6.599-10.083 15.542-16.802 23.984-21.48 7.401-4.074 14.723-7.455 21.516-11.281 6.789-3.793 12.843-7.91 17.302-12.372 2.988-2.975 5.31-6.05 7.087-9.52 2.335-4.628 3.955-10.067 3.992-18.389.012-2.463-.698-5.702-2.632-9.405-1.926-3.686-5.066-7.694-9.264-11.29-8.45-7.248-20.843-12.545-35.054-12.521-16.285.058-27.186 3.876-35.587 8.62-8.36 4.776-11.029 9.595-11.029 9.595a11.736 11.736 0 0 1-15.025.314l-21.71-17.397a11.726 11.726 0 0 1-4.396-8.926 11.773 11.773 0 0 1 4.061-9.099s6.765-10.43 22.451-19.38c15.62-8.992 36.322-15.488 61.236-15.429 20.215 0 38.839 5.562 54.268 14.661 15.434 9.148 27.897 21.744 35.851 36.876 5.281 10.074 8.525 21.43 8.533 33.38.012 11.795-1.951 22.811-5.574 32.605z"
            style={{
                fill: "#F46F2E",
            }}
        />
    </svg>
)
export default QuestionMarkIcon
