import * as yup from 'yup'
import {ERROR_MESSAGE, VALIDATION} from 'utils/globalConstant'

export const validationSchema = yup.object({
  old_password: yup.string().required(ERROR_MESSAGE.required),
  new_password: yup
      .string()
      .min(8, "Password must have at least 8 characters")
      // .when("role_id", {
      //   is: (role_id: any) => role_id == '1' || role_id == '10',
      //   then: yup.string().matches(/[A-Z]/, "Password must have at least 1 upper case character")
      //       .matches(/[!@#$%^&*(),.?":{}|<>]/,'Password must have at least 1 special character')
      //       .matches(/\d+/g, "Password must have at least numerical value"),
      // })
      .required(ERROR_MESSAGE.required),
  confirm_new_password: yup
      .string()
      .required(ERROR_MESSAGE.required)
      .oneOf([yup.ref('new_password')], ERROR_MESSAGE.password_confirmation),
})
