import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ICustomField } from './types'

const initialState: ICustomField[] = [{
    name: '',
    value: '',
    category: '',
    type: '',
    length: 0
    }]

const CustomFieldSlice = createSlice({
    name: 'CustomField',
    initialState,
    reducers: {
        customField: (_state, state: PayloadAction<ICustomField[]>) => state.payload,
    },
})

export const { customField } = CustomFieldSlice.actions
export default CustomFieldSlice.reducer
