import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import { Formik } from 'formik';
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { validationSchema } from './validationSchema'
import { IRole, IList, IItem } from './types'
import moment from 'moment'

export default function Index() {
  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [validation, setValidation] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)

  const [showCreate, setShowCreate] = React.useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)

  const [showEdit, setShowEdit] = React.useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = React.useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      role_id: id,
      role_name: ''
    })
  }

  const [lists, setData] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    role_id: 0,
    role_name: ''
  })
  const [dateFormat, setDateFormat] = useState('')

  const getRole = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const res = await axios.get<IAPI_Response<IRole>>(API_ENDPOINT.GET_ROLE, {
        params: {
          page: page_number,
        },
      })
      const result = await res
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showRole = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_ROLE +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postRole = async (value: any, {resetForm} : any) => {
    await axios.post(API_ENDPOINT.POST_ROLE, {
      role_name: value.role_name,
      order: lists.length
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.ROLE.CREATED)
          getRole(null);
       }
       resetForm({})
       handleCloseCreate();
    }).catch(error => {
      setValidation(error.response.data.message)
    })
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const updateRole = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_ROLE +'/'+ value.role_id, {
      role_name: value.role_name
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.ROLE.UPDATED)
          getRole(null);
       }
       resetForm({})
       handleCloseEdit();
    }).catch(error => {
      setValidation(error.response.data.message)
    })
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const deleteRole = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_ROLE +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.ROLE.DELETED)
        getRole(null);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getRole(null)
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])
  return (
    <>
      <Container>
        <h5 className='fw-bold'>Manage Roles</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='#'>
            Admin
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Manage Roles</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Card className='border-0  p-0 border-start'>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <span className='fw-bold'>Roles List</span>
              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                <Button
                  onClick={handleShowCreate}
                  className=' add-new-btn rounded me-1 text-white'
                  variant='warning'
                  >
                  <EditIcon/>
                  &nbsp;Add New Role
                </Button>
              </Col>
            </Row>
            <Table responsive className='bordered-tabs-top mt-3'>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>No.</th>
                  <th style={{ width: '15%' }}>Role Name</th>
                  <th style={{ width: '15%' }}>Date</th>
                  <th className={'text-center'}>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {lists.length > 0 ? (
                    lists.map((item, index) => 
                      <tr className='mb-2' key={index}>
                        <td>{from + index}</td>
                        <td className='fw-bold'>{item.role_name}</td>
                        <td>{moment(new Date(item.created_at ?? "")).format(dateFormat)}</td>
                        <td className='text-center'>
                          <Button
                            onClick={() => showRole(item.role_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Write/>
                          </Button>
                          <Button
                            onClick={() => handleShowConfirm(item.role_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Delete/>
                          </Button>
                        </td>
                        <br />
                      </tr>
                    )
                    ) : (
                    <tr className='no-item'>
                      <td colSpan={4}>{TEXT.NOT_FOUND}</td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>

            <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
              pageClicked={(page: number) => { 
                  getRole(page);
                }
              }
              />

            <Dialog 
              show={showConfirm} 
              setShow={handleCloseConfirm}
              setEvent={() => { 
                  deleteRole(item.role_id)
                }
              }
            />

            <Loader show={showLoading}/>
          </Card.Body>
        </Card>

        {/* ADD MODAL*/}
        <Formik
          initialValues={{ role_name: ''}}
          validationSchema={validationSchema}
          onSubmit={postRole}
          >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
          }) => (
            <Form id="roleCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title>Add New Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="mb-3">
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label className='text-muted'>Role Name</Form.Label>
                      <Form.Control 
                        size={'sm'} 
                        type='text' 
                        placeholder='Role name' 
                        name="role_name"
                        value={values.role_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.role_name && !!errors.role_name || !!validation}
                        autoComplete='off'
                        autoFocus 
                        />
                        {
                        validation != '' ?
                          (Object.keys(validation).map((message : any, index) => (
                            <Form.Control.Feedback type="invalid" key={index}>
                              {validation[message][index]}
                            </Form.Control.Feedback>
                          ))
                          ) : (
                          <Form.Control.Feedback type="invalid">
                            {errors.role_name}
                          </Form.Control.Feedback>
                          )
                        }
                    </Form.Group>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button 
                    type="submit"
                    disabled={isSubmitting}
                    form="roleCreate" 
                    variant='primary text-white'
                    size={'sm'}
                    className='mx-2'
                    >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
          initialValues={item}
          validationSchema={validationSchema}
          onSubmit={updateRole}
          enableReinitialize
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

          <Form id="roleUpdated" noValidate onSubmit={handleSubmit}>
            <Modal className='modal' show={showEdit} centered onHide={handleCloseEdit}>
              <Modal.Header closeButton>
                <Modal.Title className='fw-bold'>Edit Role</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label className='text-muted required'>Role Name</Form.Label>
                    <Form.Control 
                      size={'sm'}
                      type='text' 
                      placeholder='Role name'
                      name='role_name'
                      value={values.role_name} 
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.role_name && !!errors.role_name || !!validation}
                      autoComplete='off'
                      autoFocus
                      />
                      {
                        validation != '' ?
                          (Object.keys(validation).map((message : any, index) => (
                            <Form.Control.Feedback type="invalid" key={index}>
                              {validation[message][index]}
                            </Form.Control.Feedback>
                          ))
                          ) : (
                          <Form.Control.Feedback type="invalid">
                            {errors.role_name}
                          </Form.Control.Feedback>
                          )
                      }
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                  Cancel
                </Button>
                <Button
                  type="submit" 
                  disabled={isSubmitting} 
                  form="roleUpdated"
                  variant='primary text-white'
                  size={'sm'}
                  className='mx-2'
                  >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>
            </Form>
            )}
        </Formik>
      </Container>
    </>
  )
}
