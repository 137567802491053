import React from 'react'
import { Grid, Box } from '@material-ui/core'
import { CardBadgeProps } from './Types'
import { StyledCard, StyledText } from './component'
import EmployeeWhite from 'asset/SvgComponent/EmployeeWhite'
import DepartmentIcon from 'asset/SvgComponent/DepartmentIcon'
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PresentIcon from 'asset/SvgComponent/PresentIcon'
import AbsentIcon from 'asset/SvgComponent/AbsentIcon'

const CardBage: React.FC<CardBadgeProps> = ({ label, count, subLabel, variant = 'default' }) => {
  const rightSideIcon = (variant: string) => {
    switch (variant) {
      case 'primary':
        return <EmployeeWhite />
      case 'info':
        return <DepartmentIcon />
      case 'success':
        return <PresentIcon />
      default:
        return <AbsentIcon />
    }
  }

  return (
    <StyledCard userVariant={variant}>
      <Grid container>
        <Grid item xs={6}>
          <StyledText variant='body1' lineHeight={21}>
            {label}
          </StyledText>
          <StyledText size={24} lineHeight={32}>
            {count}
          </StyledText>
          <Box style={{ display: 'flex', paddingTop: '10px' }}>
            <FontAwesomeIcon className='me-1' icon={faArrowCircleUp} color='#fff' />
            <StyledText isBold lineHeight={16} size={12}>
              {subLabel}
            </StyledText>
          </Box>
        </Grid>
        <Grid item container xs={6} alignItems='center' justifyContent='flex-end'>
          {rightSideIcon(variant)}
        </Grid>
      </Grid>
    </StyledCard>
  )
}

export default CardBage
