import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table
} from 'react-bootstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Formik } from 'formik';
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { validationSchema } from './validationSchema'
import moment from "moment"
import PasswordIcon from "asset/SvgComponent/PasswordIcon";
import PasswordShow from "asset/SvgComponent/PasswordShow";
import Password from "asset/SvgComponent/Password";

export default function Index() {


  const [validation, setValidation] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)
  const [dateFormat, setDateFormat] = React.useState("")
  const [showOldPassword, setShowOldPassword] = React.useState(false)
  const [showNewPassword, setShowNewPassword] = React.useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false)


  const putPassword = async( value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.UPDATE_PASSWORD, {
      old_password: value.old_password,
      new_password: value.new_password,
      confirm_new_password: value.confirm_new_password
    }).then(response => {

      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.PASSWORD.UPDATED)
        resetForm({})
      }

    }).catch(error => {
      setValidation(error.response.data.message)
      setShowError(true)
      setError("Old Password is incorrect.")
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const handleChangeShowOldPassword = () => {
    setShowOldPassword(!showOldPassword)
  }

  const handleChangeShowNewPassword = () => {
    setShowNewPassword(!showNewPassword)
  }

  const handleChangeShowConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword)
  }

  useEffect(() => {
    ;(async () => {
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Change Password</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row className='mb-4'>
              <Col sm={6}>
                <span className='fw-bold'>Change Password</span>
              </Col>

            </Row>
            <Formik
                initialValues={{ old_password: '', new_password: '', confirm_new_password: ''}}
                validationSchema={validationSchema}
                onSubmit={putPassword}
            >
              {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
                }) => (

                  <Form id="passwordUpdate" noValidate onSubmit={handleSubmit}>
                    <Row className='mb-4'>
                      <Col sm={3} ></Col>
                      <Col sm={2} >
                        <Form.Group className='mb-3'>
                          <Form.Label column="sm" className="text-muted fw-bold">Old Password</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                          <InputGroup hasValidation className='mb-3'>
                            <InputGroup.Text id='basic-addon1' className='bg-white'>
                              <PasswordIcon/>
                            </InputGroup.Text>
                            <Form.Control
                                name="old_password"
                                type={showOldPassword ? "text" : "password"}
                                placeholder='Old Password'
                                aria-label='password'
                                aria-describedby="inputGroupPrepend"
                                value={values.old_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className='form_inputs'
                                isInvalid={touched.old_password && !!errors.old_password}
                                style={{borderRight: 'none'}}
                            />
                            <InputGroup.Text
                                id='basic-addon1'
                                className='bg-white'
                                style={{cursor: 'pointer'}}
                                onClick={handleChangeShowOldPassword}
                                title={showOldPassword ? "Hide password" : "Show password"}
                            >
                              {showOldPassword ? <PasswordShow/> : <Password/>}
                            </InputGroup.Text>
                            <Form.Control.Feedback className='mt-2' type="invalid" tooltip>
                              {errors.old_password}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col sm={3} ></Col>
                    </Row>
                    <Row className='mb-4'>
                      <Col sm={3} ></Col>
                      <Col sm={2} >
                        <Form.Group className='mb-3'>
                          <Form.Label column="sm" className="text-muted fw-bold">New Password</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                          <InputGroup hasValidation className='mb-3'>
                            <InputGroup.Text id='basic-addon1' className='bg-white'>
                              <PasswordIcon/>
                            </InputGroup.Text>
                            <Form.Control
                                name="new_password"
                                type={showNewPassword ? "text" : "password"}
                                placeholder='New Password'
                                aria-label='password'
                                aria-describedby="inputGroupPrepend"
                                value={values.new_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className='form_inputs'
                                isInvalid={touched.new_password && !!errors.new_password}
                                style={{borderRight: 'none'}}
                            />
                            <InputGroup.Text
                                id='basic-addon1'
                                className='bg-white'
                                style={{cursor: 'pointer'}}
                                onClick={handleChangeShowNewPassword}
                                title={showNewPassword ? "Hide password" : "Show password"}
                            >
                              {showNewPassword ? <PasswordShow/> : <Password/>}
                            </InputGroup.Text>
                            <Form.Control.Feedback className='mt-2' type="invalid" tooltip>
                              {errors.new_password}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col sm={3} ></Col>
                    </Row>
                    <Row className='mb-4'>
                      <Col sm={3} ></Col>
                      <Col sm={2} >
                        <Form.Group className='mb-3'>
                          <Form.Label column="sm" className="text-muted fw-bold">Confirm New Password</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                          <InputGroup hasValidation className='mb-3'>
                            <InputGroup.Text id='basic-addon1' className='bg-white'>
                              <PasswordIcon/>
                            </InputGroup.Text>
                            <Form.Control
                                name="confirm_new_password"
                                type={showConfirmNewPassword ? "text" : "password"}
                                placeholder='Confirm New Password'
                                aria-label='password'
                                aria-describedby="inputGroupPrepend"
                                value={values.confirm_new_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className='form_inputs'
                                isInvalid={touched.confirm_new_password && !!errors.confirm_new_password}
                                style={{borderRight: 'none'}}
                            />
                            <InputGroup.Text
                                id='basic-addon1'
                                className='bg-white'
                                style={{cursor: 'pointer'}}
                                onClick={handleChangeShowConfirmNewPassword}
                                title={showConfirmNewPassword ? "Hide password" : "Show password"}
                            >
                              {showConfirmNewPassword ? <PasswordShow/> : <Password/>}
                            </InputGroup.Text>
                            <Form.Control.Feedback className='mt-2' type="invalid" tooltip>
                              {errors.confirm_new_password}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col sm={3} ></Col>
                    </Row>
                    <Row>
                      <Col sm={12} className='text-center'>
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            form="passwordUpdate"
                            variant='primary text-white'
                            size={'sm'}
                            className='mx-2'
                        >
                          Save Password
                        </Button>
                      </Col>
                    </Row>
                  </Form>
              )}
            </Formik>

            <Loader show={showLoading}/>
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}
