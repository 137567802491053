import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { Form, Button, Image, InputGroup, Row, Modal, Container} from 'react-bootstrap'
import Box from '@mui/material/Box'
import "leaflet/dist/leaflet.css";
import './style.css'
import moment from 'moment'
import Appbar from "./Appbar";
import RegisterBody from "./RegisterBody"
import Footer from "components/Footer";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

const Index = () => {
  const [state, setState] = useState(true)
  const [show, setShow] = useState(false);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] =useState(false)
  const [invalidLogin, setInvalidLogin] = useState<string>('')
  const [displayTime, setDisplayTime] = useState<string>('')
  const [token, setToken] = useState<string>('')

  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
    address: ''
  })

  const [clock, setClock] = useState({
    attendance_status: '',
    disabled: false
  })
  const [breaks, setBreaks] = useState({
    attendance_break: ''
  })

  const [showSubmit, setSubmit] = useState(true)
  const [showMap, setShowMap] = useState(false)
  const [showCamera, setShowCamera] = useState(false)
  const [showCapture, setShowCapture] = useState(false)
  const handleCloseMap = () => setShowMap(false)
  const [capturedImage, setCapturedImage] = useState(null);
  const [type, setType] = useState('')
  const [employeeAttendanceId, setEmployeeAttendanceId] = useState(0);

  const [showPassword, setShowPassword] = useState(false)
  const [deviceInformation, setDeviceInformation] = useState({
    isMobile: 0,
    browserFingerprint: '',
    browser: '',
    device: '',
    height: '',
    width: ''
  })


  const handleChangeShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleShowMap = () => {
    setShowMap(true)

  }
  const handleSubmitMap = () => {
    setShowCapture(true)
    handleCloseMap()
    setState(true)
    setCapturedImage(null);
    setShowCamera(false);
  }
  const handleCloseCapture = () => setShowCapture(false)
  const handleStartCapture = () => {
    setShowCamera(true)
    setState(state)
  }


  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayTime(moment().format('LTS'))
    }, 1000)
    return () => clearInterval(interval)
  })



  return (
      <Box sx={{ display: 'flex' }}>
        <Appbar />
        <Box
            component='main'
            id='main'
            sx={{ flexGrow: 1, pt: 1, minHeight: 'calc(100vh - 65px)' }}
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
        >
          <RegisterBody/>
          <Footer />
        </Box>

      </Box>

  )
}

export default Index
