import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup, Modal,
  Row,
  Stack,
  Table,
  Dropdown
} from 'react-bootstrap'
import './style.css'
import Avatar from '@mui/material/Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import View from 'asset/SvgComponent/View'
import Write from 'asset/SvgComponent/Write'
import Delete from 'asset/SvgComponent/Delete'
import EditIcon from 'asset/SvgComponent/EditIcon'
import DownloadOrange from 'asset/SvgComponent/DownloadOrange'
import Download from 'asset/SvgComponent/Download'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import axios from 'api/index'
import {
  API_ENDPOINT,
  PAYROLL_COMPUTATION, 
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { IAPI_Response } from 'api/types'
import {ICandidateList, ICandidate} from './types'
import moment from "moment"
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import ArrowUp from "asset/SvgComponent/ArrowUp";
import ArrowDown from "asset/SvgComponent/ArrowDown";
import Dialog from "components/Dialog";
import {IItem} from "../../EmployeeManagement/Branch/types";


export default function Index() {

  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [success, setSuccess] = React.useState('')
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)
  const [showConfirm, setShowConfirm] = React.useState(false)

  const [lists, setData] = useState<ICandidate[]>([])
  const [dateFormat, setDateFormat] = useState('')

  const [showUpload, setShowUpload] = React.useState(false)
  const [uploadFile, setUploadFile] = React.useState({});
  const [searched, setSearched] = React.useState('');
  const [status, setStatus] = React.useState(0);
  const [branch, setBranch] = React.useState(0);

  const [entries, setEntries] = React.useState(10);
  const [sortColumn, setSortColumn] = React.useState("employee_name")
  const [sort, setSort] = React.useState("asc")
  const [candidateId, setCandidateId] = useState(0)

  const handleShowConfirm = (candidate_id : number | null) => {
    setShowConfirm(true)
    setCandidateId(candidate_id ?? 0)
  }
  const handleCloseConfirm = () => setShowConfirm(false)

  const handleShowUpload = () => {
    setShowUpload(true);
  }
  const handleCloseUpload = () => {
    setShowUpload(false);
  }

  const handleChangeEntries = (e: any) => {
    setEntries(e.target.value)
    getCandidates(null, status, e.target.value, sortColumn, sort, branch)
  }

  const handleSortingChange = (column: string) => {
    let sortType = ""
    setSortColumn(column)
    if(sort == ""){
      setSort("asc")
      sortType = "asc";
    }else if(sort == "asc"){
      setSort("desc")
      sortType = "desc";
    }else {
      setSort("asc")
      sortType = "asc";
    }
    getCandidates(null, status, entries, column, sortType, branch)
  }

  const handleDownloadSampleFormat = async () => {


    try {
      const response = await axios.get(API_ENDPOINT.DOWNLOAD_CANDIDATE_SAMPLE_FORMAT, {
        headers: {
          'Content-Type': 'blob'
        },
        responseType: 'arraybuffer'
      })
      const outputFilename = `Candidate_Upload_Sample_Format.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();

      FileSaver.writeFileSync(outputFilename, response.data);

    } catch {
      // setShowError(true)
      // setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
  }

  const handleDownload = async() => {
    try {
      const response = await axios.get(API_ENDPOINT.GET_ALL_CANDIDATES,{
        params: {
          status: status
        }
      })
      const result = await response

      if (result?.data?.results.length) {

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

        const ws = XLSX.utils.json_to_sheet(result?.data?.results);

        const wb = { Sheets: {'data' : ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, "Candidates List" + ".xlsx");

      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }


  const uploadEmployees = async () => {
    await axios.post(API_ENDPOINT.BULK_UPLOAD_CANDIDATE, uploadFile).then(response => {

      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.EMPLOYEE_SUCCESSFULLY_UPLOAD)
        getCandidates(null, status, entries, sortColumn, sort, branch)
      }
    }).catch(error => {
      setShowError(true)
      setError(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);

    setShowUpload(false);
  }

  const handleChangeUploadFile = (e : any) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt?.target?.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {range:1, raw: false});
      setUploadFile(data);
    };
    reader.readAsBinaryString(file);


  }

  const getCandidates = async (page_number: null | number, status: null | number, entries_number: null | number, column: string, sortType: string, branch: number) => {
    setShowLoading(true)
    try {
      const res = await axios.get<IAPI_Response<ICandidateList>>(API_ENDPOINT.GET_CANDIDATES, {
        params: {
          page: page_number,
          status: status,
          entries: entries_number,
          sort: sortType,
          sort_column: column,
          searched: searched
        },
      })
      const result = await res
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }else{
        setData([])
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleChangeStatus = (e:any) => {
    setStatus(e.target.value)
    getCandidates(null, e.target.value, entries, sortColumn, sort, branch)
  }

  const deleteCandidate = async(candidate_id: number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_CANDIDATE +'/'+ candidate_id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.BRANCH.DELETED)
        getCandidates(null, status, entries, sortColumn, sort, branch);
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }


  useEffect( () => {
    const timer = setTimeout(() => {
      // handleOnSearch()
      getCandidates(null, status, entries, sortColumn, sort, branch)
    }, 300)

    return () => clearTimeout(timer)
  }, [searched])

  useEffect(() => {
    ;(async () => {
      getCandidates(null, status, entries, sortColumn, sort, branch)
      setDateFormat(localStorage.getItem('date_format') ?? "")

    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Candidate List</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='#'>
            Employees
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Candidate List</Breadcrumb.Item>
        </Breadcrumb>

        <Alert
            type="success"
            show={showSuccess}
            message={success}
            setShow={() => {
              setShowSuccess(false);
            }
            }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={4}>
                <span className='fw-bold'>Candidate List</span>
              </Col>
              <Col sm={8} className='d-flex justify-content-end hidden'>
                {/*<Button*/}
                {/*    className=' add-new-btn rounded mx-2 text-white'*/}
                {/*    variant='primary'*/}
                {/*    onClick={handleShowUpload}*/}
                {/*>*/}
                {/*  <DownloadOrange/> <span className="d-none d-md-inline"> &nbsp; Bulk Upload</span>*/}
                {/*</Button>*/}
                <Link
                  key='/employeeManagement/add-candidate'
                  to='/employeeManagement/add-candidate'
                  className='links'
                >
                  <Button
                    className=' add-new-btn rounded mx-2 text-white'
                    variant='warning'
                    title={"Add New Employee"}
                    >
                    <EditIcon/><span className="d-none d-md-inline"> &nbsp; Add Candidate</span>
                  </Button>
                </Link>
                <Dropdown >
                  <Dropdown.Toggle variant="primary" id="dropdown-basic" className="text-white" style={{fontSize: '80%'}}>
                    Candidates Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleShowUpload}><Download/> <span className="d-none d-md-inline"> &nbsp; Bulk Upload</span></Dropdown.Item>
                    <Dropdown.Item onClick={handleDownload}><Download/><span className="d-none d-md-inline"> &nbsp; Download Candidates</span></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            <Row className={"mt-2"}>

              <Col sm={2}>
                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                  <Form.Label className='fw-bold form-labels'>Status</Form.Label>
                  <Form.Select
                      size={'sm'}
                      name="status"
                      onChange={handleChangeStatus}
                  >
                    <option value="">All Status</option>
                    <option value="1">Candidate</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={2}>

              </Col>
              <Col sm={2}>

              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                  <InputGroup size='sm' className={'w-25 mx-2'} style={{height: '30px', margin: 'auto 0 auto 0'}}>
                    <Form.Control
                        aria-label='search'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder='Search...'
                        onChange={e => setSearched(e.target.value)}
                    />
                    <InputGroup.Text className='bg-transparent search-input '>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                  </InputGroup>
              </Col>
              {/*<Col sm={2}>*/}
              {/*  /!*<Form.Group className="position-relative w-25 d-flex justify-content-end hidden">*!/*/}
              {/*  /!*  <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>*!/*/}
              {/*    <InputGroup size='sm' className={'w-25 mx-2'}>*/}
              {/*      <Form.Control*/}
              {/*          aria-label='search'*/}
              {/*          aria-describedby='inputGroup-sizing-sm'*/}
              {/*          placeholder='Search...'*/}
              {/*          onChange={e => setSearched(e.target.value)}*/}
              {/*      />*/}
              {/*      <InputGroup.Text className='bg-transparent search-input '>*/}
              {/*        <FontAwesomeIcon icon={faSearch} />*/}
              {/*      </InputGroup.Text>*/}
              {/*    </InputGroup>*/}
              {/*  /!*</Form.Group>*!/*/}
              {/*</Col>*/}

            </Row>
            {/* <hr/>*/}
            <Table responsive className='bordered-tabs-top mt-3 employee-list'>
              <thead>
                <tr className='text-center'>
                  <th>No.</th>
                  <th className="table-header" onClick={() => handleSortingChange("employee_name")}>Employee Name {sortColumn == "employee_name" ? (sort == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                  <th className="table-header" onClick={() => handleSortingChange("email_address")}>Email Address {sortColumn == "email_address" ? (sort == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                  <th className="table-header" onClick={() => handleSortingChange("date_of_birth")}>Date of Birth {sortColumn == "date_of_birth" ? (sort == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                  <th className="table-header" onClick={() => handleSortingChange("status")}>Status {sortColumn == "status" ? (sort == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                {lists.length > 0 ? (
                  lists.map((item, index) => (
                    <tr className='mb-2' key={index}>
                      <td>{from + index}</td>
                      <td>
                        <Stack direction='horizontal' style={{cursor: 'pointer'}}>
                          <div>
                            {
                              item.photo != "" ?
                                  <Avatar
                                      sx={{ width: 20, height: 20 }}
                                      src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                      className={"profile-avatar"}
                                  ></Avatar> :
                                  <Avatar
                                      sx={{ width: 20, height: 20}}
                                      src={""}
                                      className={"profile-avatar"}
                                  ></Avatar>
                            }
                          </div>
                          <div>&nbsp;{item.last_name}, {item.first_name}</div>
                        </Stack>
                      </td>
                      <td>{item.email != null ? item.email : ''}</td>
                      <td>{item.date_of_birth ? moment(new Date(item.date_of_birth)).format(dateFormat) : "N/A"}</td>
                      <td className='text-center'>
                        <div className='d-grid'>
                          {item.status == 1 ? (
                          <Button
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-success'
                            size='sm'
                          > Reserved
                          </Button>
                          ) : (
                          <Button
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-secondary'
                            size='sm'
                          > Inactive
                          </Button>
                          )}
                        </div>
                      </td>
                      <td className='text-center'>
                        {/*<Link to={`/employeeManagement/show-employee/${item.candidate_id}`}>*/}
                        {/*  <Button*/}
                        {/*    className=' p-1 pt-0 pb-0'*/}
                        {/*    style={{ fontSize: '12px' }}*/}
                        {/*    variant=' btn-transparent'*/}
                        {/*    size='sm'*/}
                        {/*    >*/}
                        {/*    <View/>*/}
                        {/*  </Button>*/}
                        {/*</Link>*/}
                        <Link to={`/employeeManagement/edit-candidate/${item.candidate_id}`}>
                          <Button
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Write/>
                          </Button>
                        </Link>
                        <Button
                            onClick={() => handleShowConfirm(item.candidate_id)}
                          className=' p-1 pt-0 pb-0'
                          style={{ fontSize: '12px', color: 'white' }}
                          variant=' btn-transparent'
                          size='sm'
                          >
                          <Delete/>
                        </Button>
                      </td>
                      <br />
                    </tr>
                  ))
                  ) : (
                    <tr className='no-item'>
                      <td colSpan={6}>{TEXT.NOT_FOUND}</td>
                    </tr>
                )}
                </>
              </tbody>
            </Table>
            <Row>
              <Col sm={6}>
                <Pagination
                    itemsCount={itemsCount}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    lastPage={lastPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                      getCandidates(page, status, entries, sortColumn, sort, branch);
                    }
                    }
                />
              </Col>
              <Col sm={6} className='right-align d-flex justify-content-end hidden'>
                  <Form.Group >
                    <Form.Select
                        size={'sm'}
                        name="work_shift_id"
                        value={entries}
                        onChange={handleChangeEntries}
                        style={{width: '100%'}}
                    >
                      <option value="10" selected>10</option>
                      <option value="25" >25</option>
                      <option value="50" >50</option>
                      <option value="100" >100</option>

                    </Form.Select>
                  </Form.Group>
              </Col>
            </Row>


            <Dialog
                show={showConfirm}
                setShow={handleCloseConfirm}
                setEvent={() => {
                  deleteCandidate(candidateId)
                }
                }
            />

          <Loader show={showLoading}/>
          </Card.Body>
        </Card>
        <br />

        {/* UPLOAD CANDIDATES MODAL*/}
        <Modal className='modal' centered show={showUpload} onHide={handleCloseUpload}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Upload Candidates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
              <Form.Group as={Col} md="4">
                <Form.Label column="sm">Upload Excel File</Form.Label>
              </Form.Group>
              <Form.Group as={Col} md="8">
                <Form.Control
                    type='file'
                    size={'sm'}
                    name="photo"
                    onChange={handleChangeUploadFile}
                    id={'fileUpload'}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4">
              </Form.Group>
              <Form.Group as={Col} md="8">
                <Form.Label onClick={handleDownloadSampleFormat}  column="sm"><Download/> &nbsp; &nbsp; <span className="sample-format">Sample Excel File Format</span></Form.Label>
              </Form.Group>
            </Row>
            <Row className="mt-2">
              <Form.Group as={Col} md="12">
                <Form.Label column="sm">Note: Please follow and don't delete the sample format for each column.</Form.Label>
              </Form.Group>
            </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={uploadEmployees}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  )
}
