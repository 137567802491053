import React, {useEffect, useRef, useState} from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Stack,
    Table,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import axios from "../../../api";
import {IAPI_Response} from "../../../api/types";
import {
    API_ENDPOINT,
    ERROR_MESSAGE,
    PAYROLL_COMPUTATION,
    ROLE_PERMISSION,
    SUCCESS_MESSAGE,
    SETUP
} from "../../../utils/globalConstant";
import {IGeneralSetup} from './type'
import Pagination from 'components/Pagination'
import Alert from "components/Alert";
import {IPayrollRangeList} from "../Payroll/PayrollComputation/types";
import Select from 'react-select';
import Image from "react-bootstrap/Image";
import Camera from "asset/SvgComponent/Camera";
import './style.css'
import CircleCreate from "asset/SvgComponent/CircleCreate";
import Reject from "asset/SvgComponent/Reject";

export default function Index() {
    const inputFileUploadRef = useRef<HTMLInputElement>( null );
    const [error, setError] = React.useState('')
    const [showError, setShowError] = React.useState(false)
    const [success, setSuccess] = React.useState('')
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [item, setItem] = React.useState({
        dateFormat: '',
        showSpecialEvent: '',
        generatePayrollRange: '',
        timeFormat: '',
        hoursConsideredHalfDayAbsent: '',
        clientProfile: '',
        graceTimePeriod: '',
        graceTimePeriodOut: '',
        cutoffTab: '',
        ratesTab: '',
        earningsTab: '',
        allowancesTab: '',
        deductionsTab: '',
        deviceAuthentication: '',
        noOfAllowedDevices: '',
        nameFormat: '',
        payrollActualData: '',
        passwordRequirements: [''],
        payslipLogo: '',
        companyName: '',
        withholdingTaxSchedule: '',
        editAttendance: '',
        checkInAttendance: '',
        teamSetup: '',
        customFields: [{
            category: "",
            name: "",
            type: "",
            length: 0
        }],
        checkInSignature: '',
        checkInLocation: '',
        checkInCamera: '',
        attendanceLocation: '',
        attendanceCamera: '',
        activityTimeFormat: ''
    })

    const passwordRequirementOptions = [
        { value: "8characters", label: "8 Characters" },
        { value: "uppercase", label: "1 Upper Case" },
        { value: "numerical", label: "1 Numerical" },
        { value: "special", label: "1 Special Characters" }

    ];
    const [selectedOptions, setSelectedOptions] = useState<string[]>(['']);
    const [selectedOptionsValue, setSelectedOptionsValue] = useState<{value: string, label: string}[]>([]);
    const [showText, setShowText] = useState('none')
    const [pictureOpacity, setPictureOpacity] = useState(1)
    const [uploadedProfilePicture, setUploadedProfilePicture] = useState("");
    const [uploadedProfilePictureBase64, setUploadedProfilePictureBase64] = useState("");

    const handleChangeTimeFormat = (e : any) => {
        setItem({...item, timeFormat: e.target.value})
    }

    const handleChangeDateFormat = (e : any) => {
        setItem({...item, dateFormat: e.target.value})
    }

    const handleChangeSpecialEvent = (e : any) => {
        setItem({...item, showSpecialEvent: e.target.value})
    }

    const handleChangeGeneratePayrollRange = (e : any) => {
        setItem({...item, generatePayrollRange: e.target.value})
    }

    const handleChangeHoursConsideredHalfDayAbsent = (e : any) => {
        setItem({...item, hoursConsideredHalfDayAbsent: e.target.value})
    }

    const handleChangeClientProfile = (e : any) => {
        setItem({...item, clientProfile: e.target.value})
    }

    const handleChangeGraceTimePeriod = (e:any) => {
        setItem({...item, graceTimePeriod: e.target.value})
    }

    const handleChangeGraceTimePeriodOut = (e:any) => {
        setItem({...item, graceTimePeriodOut: e.target.value})
    }

    const handleChangeDeviceAuthentication = (e:any) => {
        setItem({...item, deviceAuthentication: e.target.value})
    }

    const handleChangeNoOfAllowedDevices = (e:any) => {
        setItem({...item, noOfAllowedDevices: e.target.value})
    }

    const handleChangeNameFormat = (e:any) => {
        setItem({...item, nameFormat: e.target.value})
    }

    const handleChangePayrollActualData = (e:any) => {
        setItem({...item, payrollActualData: e.target.value})
    }

    const handleChangeCompanyName = (e:any) => {
        setItem({...item, companyName: e.target.value})
    }

    const handleChangeWithholdingTaxSchedule = (e:any) => {
        setItem({...item, withholdingTaxSchedule: e.target.value})
    }

    const handleChangeEditAttendance = (e : any) => {
        setItem({...item, editAttendance: e.target.value})
    }

    const handleChangeActivityTimeFormat = (e : any) => {
        setItem({...item, activityTimeFormat: e.target.value})
    }

    const handleChangeCheckInAttendance = (e : any) => {

        if(e.target.value == "0"){
            setItem({...item, checkInAttendance: e.target.value, checkInSignature: e.target.value, checkInLocation: e.target.value, checkInCamera: e.target.value})
        }else{
            setItem({...item, checkInAttendance: e.target.value})
        }
    }

        const handleChangeCheckInSignature = (e : any) => {
        setItem({...item, checkInSignature: e.target.value})
    }

    const handleChangeCheckInLocation = (e : any) => {
        setItem({...item, checkInLocation: e.target.value})
    }

    const handleChangeCheckInCamera = (e : any) => {
        setItem({...item, checkInCamera: e.target.value})
    }

    const handleChangeAttendanceLocation = (e : any) => {
        setItem({...item, attendanceLocation: e.target.value})
    }

    const handleChangeAttendanceCamera = (e : any) => {
        setItem({...item, attendanceCamera: e.target.value})
    }

    const handleChangeTeamSetup = (e : any) => {
        setItem({...item, teamSetup: e.target.value})
    }

    const handleChangeCustomFieldName = (e: any, index: number) => {
        const newState = item.customFields.map((field, i) => {
            if(index == i){
                return {
                    ...field, name: e.target.value
                };
            }else{
                return field
            }

        });

        setItem({...item, customFields: newState});
    }

    const handleChangeCustomType = (e: any, index: number) => {
        const newState = item.customFields.map((field, i) => {
            if(index == i){
                return {
                    ...field, type: e.target.value
                };
            }else{
                return field
            }

        });

        setItem({...item, customFields: newState});
    }

    const handleChangeCustomCategory = (e: any, index: number) => {
        const newState = item.customFields.map((field, i) => {
            if(index == i){
                return {
                    ...field, category: e.target.value
                };
            }else{
                return field
            }

        });

        setItem({...item, customFields: newState});
    }

    const handleChangeCustomLength = (e: any, index: number) => {
        const newState = item.customFields.map((field, i) => {
            if(index == i){
                return {
                    ...field, length: e.target.value
                };
            }else{
                return field
            }

        });

        setItem({...item, customFields: newState});
    }

    const handleClick = async() => {
        inputFileUploadRef?.current?.click()
    }

    const handleOnMouseEnter = () => {

        setPictureOpacity(0.5)
        setShowText("")
    }

    const handleOnMouseLeave = () => {

        setPictureOpacity(1)
        setShowText("none")
    }

    const handleClickTab = (e:any, tab: string) => {
        let tabValue = "1";
        if(!e.target.checked){
            tabValue = "0";
        }

        if(tab == "cutoff"){
            setItem({...item, cutoffTab: tabValue})
        }else if(tab == "rates"){
            setItem({...item, ratesTab: tabValue})
        }else if(tab == "earnings"){
            setItem({...item, earningsTab: tabValue})
        }else if(tab == "allowances"){
            setItem({...item, allowancesTab: tabValue})
        }else if(tab == "deductions"){
            setItem({...item, deductionsTab: tabValue})
        }

    }

    const handleUploadPayslipLogo = async(e: any) => {
        setUploadedProfilePicture(URL.createObjectURL(e.target.files[0]));

        const reader = new FileReader()

        reader.readAsDataURL(e.target.files[0])

        reader.onload = () => {
            // @ts-ignore
            setUploadedProfilePictureBase64(reader.result)

        }

    }

    const getGeneralSetup = async () => {
        try {
            const response = await axios.get<IAPI_Response<IGeneralSetup>>(API_ENDPOINT.SHOW_GENERAL_SETUP)
            const result = await response
            if (result?.data?.results) {
                const item = result?.data?.results
                setItem(item)

                setSelectedOptionsValue([])
                setSelectedOptions([])
                const defaultVal :{value: string, label: string}[] = []
                item.passwordRequirements.map((requirement: string) => {
                    passwordRequirementOptions.map((opt: {value: string, label: string}) => {

                        if(opt.value == requirement){
                            defaultVal.push({value: opt.value, label: opt.label});
                            setSelectedOptions(current2 => [...current2, opt.value])
                        }

                    })
                })

                setSelectedOptionsValue([{label:"8 Characters",value:"8characters"}])
                console.log("default val ", defaultVal)

            }
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const handleUpdateGeneralSetup = async () => {
        item.passwordRequirements = selectedOptions
        item.payslipLogo = uploadedProfilePictureBase64
        await axios.put(API_ENDPOINT.UPDATE_GENERAL_SETUP, item).then(response => {

            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SETUP.SUCCESSFULLY_TRANSFERRED)
                localStorage.setItem('date_format', item.dateFormat)
                getGeneralSetup()
            }
        }).catch(error => {
            setShowError(true)
            setError(error.response.data.message)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    useEffect(() => {
        ;(async () => {
            getGeneralSetup()
        })()
    }, [])


    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>General Setup</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none'>
                        Setup
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>General</Breadcrumb.Item>
                </Breadcrumb>
                <Card className='border-0  p-0 bordered-card'>
                    <Card.Body>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <h5 style={{fontSize: '98%'}}><b>General</b></h5>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Show Special Events</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="marital_status"
                                        onChange={handleChangeSpecialEvent}
                                        value={item.showSpecialEvent}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="">Select Days</option>
                                        <option value="1">1 day before</option>
                                        <option value="2">2 days before</option>
                                        <option value="3">3 days before</option>
                                        <option value="4">4 days before</option>
                                        <option value="5">5 days before</option>
                                        <option value="6">6 days before</option>
                                        <option value="7">7 days before</option>
                                        <option value="8">8 days before</option>
                                        <option value="9">9 days before</option>
                                        <option value="10">10 days before</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            {
                                JSON.parse(localStorage.getItem('role') ?? "") == "Super Admin" ?
                                    <>
                                        <Col sm={2} >
                                            <Form.Group className='mb-3'>
                                                <Form.Label column="sm" className="text-muted fw-bold">Client Profile</Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={3}>
                                            <Form.Group className='mb-3'>
                                                <Form.Select
                                                    size={'sm'}
                                                    name="clientProfile"
                                                    onChange={handleChangeClientProfile}
                                                    value={item.clientProfile}
                                                    // onBlur={handleBlur}
                                                >
                                                    <option value="">Select Profile</option>
                                                    <option value="standard">Standard</option>
                                                    <option value="security_agency">Security Agency</option>
                                                    <option value="bpo">BPO</option>
                                                    <option value="construction">Construction</option>
                                                    <option value="retail">Retail</option>
                                                    <option value="architecture">Architecture</option>
                                                    <option value="medicine">Medicine</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </> : ""
                            }

                        </Row>

                        <Row>
                            <Col sm={2} >
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Device Authentication</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="deviceAuthentication"
                                        onChange={handleChangeDeviceAuthentication}
                                        value={item.deviceAuthentication}
                                    >
                                        <option value="0">Disabled</option>
                                        <option value="1">Enabled</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={2} >
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">No. of Allowed Devices</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="noOfAllowedDevices"
                                        onChange={handleChangeNoOfAllowedDevices}
                                        value={item.noOfAllowedDevices}
                                    >
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Hours Considered as Half Day Absent</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="hoursConsideredLate"
                                        onChange={handleChangeHoursConsideredHalfDayAbsent}
                                        value={item.hoursConsideredHalfDayAbsent}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="">Select Hours</option>
                                        <option value="1">1 hour</option>
                                        <option value="2">2 hours</option>
                                        <option value="3">3 hours</option>
                                        <option value="4">4 hours</option>
                                        <option value="5">5 hours</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Password Requirements</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Select
                                        placeholder={"Select Requirements"}
                                        defaultValue={selectedOptionsValue}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        onChange={(options : any) => {
                                            if (Array.isArray(options)) {
                                                const passwordRequirementsArr :string[] = [];
                                                options.map(opt => {
                                                    passwordRequirementsArr.push(opt.value)
                                                })
                                                setSelectedOptions(passwordRequirementsArr)
                                            }

                                        }}
                                        // @ts-ignore
                                        options={passwordRequirementOptions}
                                        id="my-simple-select"
                                        styles={{
                                            option: (base) => ({
                                                ...base,
                                                height: '80%',
                                                fontSize: '12px'
                                            }),
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Team Setup</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="teamSetup"
                                        onChange={handleChangeTeamSetup}
                                        value={item.teamSetup}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="0">Disable</option>
                                        <option value="1">Enable</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            {/*<Col sm={2}>*/}
                            {/*    <Form.Group className='mb-3'>*/}
                            {/*        <Form.Label column="sm" className="text-muted fw-bold">Password Requirements</Form.Label>*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                            {/*<Col sm={3}>*/}
                            {/*    <Form.Group className='mb-3'>*/}
                            {/*        <Select*/}
                            {/*            placeholder={"Select Filter"}*/}
                            {/*            defaultValue={selectedOptionsValue}*/}
                            {/*            isMulti*/}
                            {/*            closeMenuOnSelect={false}*/}
                            {/*            hideSelectedOptions={false}*/}
                            {/*            onChange={(options : any) => {*/}
                            {/*                if (Array.isArray(options)) {*/}
                            {/*                    const passwordRequirementsArr :string[] = [];*/}
                            {/*                    options.map(opt => {*/}
                            {/*                        passwordRequirementsArr.push(opt.value)*/}
                            {/*                    })*/}
                            {/*                    setSelectedOptions(passwordRequirementsArr)*/}
                            {/*                }*/}

                            {/*            }}*/}
                            {/*            // @ts-ignore*/}
                            {/*            options={passwordRequirementOptions}*/}
                            {/*            id="my-simple-select"*/}
                            {/*            styles={{*/}
                            {/*                option: (base) => ({*/}
                            {/*                    ...base,*/}
                            {/*                    height: '80%',*/}
                            {/*                    fontSize: '12px'*/}
                            {/*                }),*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    </Form.Group>*/}
                            {/*</Col>*/}
                        </Row>
                        <Row>

                            <Col sm={1}>
                            </Col>
                            <Col sm={6} className='d-flex justify-content-end hidden'>

                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <h5 style={{fontSize: '98%'}}><b>Attendance</b></h5>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Location</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="attendanceLocation"
                                        onChange={handleChangeAttendanceLocation}
                                        value={item.attendanceLocation}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="0">Disabled</option>
                                        <option value="1">Enabled</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Camera</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="attendanceCamera"
                                        onChange={handleChangeAttendanceCamera}
                                        value={item.attendanceCamera}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="0">Disabled</option>
                                        <option value="1">Enabled</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2} >
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Grace Time Period In</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="graceTimePeriod"
                                        onChange={handleChangeGraceTimePeriod}
                                        value={item.graceTimePeriod}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="">Select Time</option>
                                        <option value="0">No Grace Period</option>
                                        <option value="5">5 Mins.</option>
                                        <option value="10">10 Mins.</option>
                                        <option value="15">15 Mins.</option>
                                        <option value="20">20 Mins.</option>
                                        <option value="25">25 Mins.</option>
                                        <option value="30">30 Mins.</option>

                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={2} >
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Grace Time Period Out</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="graceTimePeriodOut"
                                        onChange={handleChangeGraceTimePeriodOut}
                                        value={item.graceTimePeriodOut}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="">Select Time</option>
                                        <option value="0">No Grace Period</option>
                                        <option value="5">5 Mins.</option>
                                        <option value="10">10 Mins.</option>
                                        <option value="15">15 Mins.</option>
                                        <option value="20">20 Mins.</option>
                                        <option value="25">25 Mins.</option>
                                        <option value="30">30 Mins.</option>

                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Attendance Edit</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="editAttendance"
                                        onChange={handleChangeEditAttendance}
                                        value={item.editAttendance}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="0">Disabled</option>
                                        <option value="1">Enabled</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Activity Time Format</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="activityTimeFormat"
                                        onChange={handleChangeActivityTimeFormat}
                                        value={item.activityTimeFormat}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="" disabled>Select Format</option>
                                        <option value="1_hour">Every Hour</option>
                                        <option value="2_hour">Every 2 Hours</option>
                                        <option value="half_shift">Half Shift</option>
                                        <option value="full_shift">Full Shift</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        {

                            JSON.parse(localStorage.getItem('role') ?? "") == "Super Admin" ?
                            <>

                                <hr/>
                                <Row>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3'>
                                            <h5 style={{fontSize: '98%'}}><b>Check in</b></h5>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3'>
                                            <Form.Label column="sm" className="text-muted fw-bold">Check In</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group className='mb-3'>
                                            <Form.Select
                                                size={'sm'}
                                                name="checkInAttendance"
                                                onChange={handleChangeCheckInAttendance}
                                                value={item.checkInAttendance}
                                                // onBlur={handleBlur}
                                            >
                                                <option value="0">Disabled</option>
                                                <option value="1">Enabled</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                    </Col>
                                    <Col sm={1}>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3'>
                                            <Form.Label column="sm" className="text-muted fw-bold">Signature</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group className='mb-3'>
                                            <Form.Select
                                                size={'sm'}
                                                name="checkInSignature"
                                                onChange={handleChangeCheckInSignature}
                                                value={item.checkInSignature}
                                                // onBlur={handleBlur}
                                            >
                                                <option value="0">Disabled</option>
                                                <option value="1">Enabled</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3'>
                                            <Form.Label column="sm" className="text-muted fw-bold">Location</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group className='mb-3'>
                                            <Form.Select
                                                size={'sm'}
                                                name="checkInLocation"
                                                onChange={handleChangeCheckInLocation}
                                                value={item.checkInLocation}
                                                // onBlur={handleBlur}
                                            >
                                                <option value="0">Disabled</option>
                                                <option value="1">Enabled</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                    </Col>
                                    <Col sm={1}>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3'>
                                            <Form.Label column="sm" className="text-muted fw-bold">Camera</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group className='mb-3'>
                                            <Form.Select
                                                size={'sm'}
                                                name="checkInCamera"
                                                onChange={handleChangeCheckInCamera}
                                                value={item.checkInCamera}
                                                // onBlur={handleBlur}
                                            >
                                                <option value="0">Disabled</option>
                                                <option value="1">Enabled</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                            : ""
                        }

                            <Row>

                                <Col sm={1}>
                                </Col>
                                <Col sm={6} className='d-flex justify-content-end hidden'>

                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col sm={2}>
                                    <Form.Group className='mb-3'>
                                        <h5 style={{fontSize: '98%'}}><b>Company Setup</b></h5>
                                    </Form.Group>
                                </Col>
                            </Row>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Company Name</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Control
                                        size={'sm'}
                                        type='text'
                                        placeholder='Company Name'
                                        name="company_name"
                                        value={item.companyName}
                                        onChange={handleChangeCompanyName}

                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Logo for Payslip</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3} style={{display: 'flex', justifyContent: 'center'}}>
                                <div style={{display: 'block'}}>
                                    <div className="profile-box"
                                         onMouseEnter={handleOnMouseEnter}
                                         onMouseLeave={handleOnMouseLeave}
                                         onClick={handleClick}
                                    >
                                        <Image className='profile-picture' style={{opacity: pictureOpacity}} src={uploadedProfilePicture != "" ? uploadedProfilePicture : item.payslipLogo && item.payslipLogo != "" ? process.env.REACT_APP_IMAGE_URL+ item.payslipLogo : process.env.REACT_APP_IMAGE_URL+ "uploads/companyLogo/octaneware-logo.png"} />
                                        <p className="upload-icon" style={{display: showText}}><Camera/></p>
                                        {/*<div className="upload-icon"><Camera/></div>*/}
                                        <Form.Group style={{display: 'none'}}>
                                            <Form.Control
                                                type='file'
                                                size={'sm'}
                                                name="photo"
                                                accept="image/*"
                                                // value={values.photo}
                                                onChange={handleUploadPayslipLogo}
                                                ref={inputFileUploadRef}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div style={{position: 'relative', fontSize: '12px', textAlign: 'center'}}>
                                        <span>Click to change logo</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>

                            <Col sm={1}>
                            </Col>
                            <Col sm={6} className='d-flex justify-content-end hidden'>

                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <h5 style={{fontSize: '98%'}}><b>Formats</b></h5>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Date Format</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="marital_status"
                                        onChange={handleChangeDateFormat}
                                        value={item.dateFormat}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="">Select Date Format</option>
                                        <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                                        <option value="YYYY/MM/DD">YYYY/MM/DD</option>
                                        <option value="MM-DD-YYYY">MM-DD-YYYY</option>
                                        <option value="MMM-DD-YYYY">MMM-DD-YYYY</option>

                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={2} >
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Name Format</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="nameFormat"
                                        onChange={handleChangeNameFormat}
                                        value={item.nameFormat}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="">Select Name Format</option>
                                        <option value="first_last">ex. John Doe</option>
                                        <option value="first_middle_last">ex. John A. Doe</option>
                                        <option value="last_first_middle">ex. Doe, John A.</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Time Format</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="marital_status"
                                        onChange={handleChangeTimeFormat}
                                        value={item.timeFormat}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="">Select Time Format</option>
                                        <option value="h:mm a">h:mm a</option>
                                        <option value="h:mm:ss a">h:mm:ss a</option>
                                        <option value="h:mm A">h:mm A</option>
                                        <option value="hh:mm A">hh:mm A</option>
                                        <option value="h:mm:ss A">h:mm:ss A</option>
                                        <option value="hh:mm:ss A">hh:mm:ss A</option>
                                        <option value="H:mm A">H:mm A</option>
                                        <option value="HH:mm A">HH:mm A</option>
                                        <option value="H:mm a">H:mm a</option>
                                        <option value="HH:mm a">HH:mm a</option>
                                        <option value="HH:mm">HH:mm</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={2} >
                                {/*<Form.Group className='mb-3'>*/}
                                {/*    <Form.Label column="sm" className="text-muted fw-bold">Grace Time Period In</Form.Label>*/}
                                {/*</Form.Group>*/}
                            </Col>
                            <Col sm={3}>
                                {/*<Form.Group className='mb-3'>*/}
                                {/*    <Form.Select*/}
                                {/*        size={'sm'}*/}
                                {/*        name="graceTimePeriod"*/}
                                {/*        onChange={handleChangeGraceTimePeriod}*/}
                                {/*        value={item.graceTimePeriod}*/}
                                {/*        // onBlur={handleBlur}*/}
                                {/*    >*/}
                                {/*        <option value="">Select Time</option>*/}
                                {/*        <option value="0">No Grace Period</option>*/}
                                {/*        <option value="5">5 Mins.</option>*/}
                                {/*        <option value="10">10 Mins.</option>*/}
                                {/*        <option value="15">15 Mins.</option>*/}
                                {/*        <option value="20">20 Mins.</option>*/}
                                {/*        <option value="25">25 Mins.</option>*/}
                                {/*        <option value="30">30 Mins.</option>*/}

                                {/*    </Form.Select>*/}
                                {/*</Form.Group>*/}
                            </Col>
                        </Row>


                        <Row>

                            <Col sm={1}>
                            </Col>
                            <Col sm={6} className='d-flex justify-content-end hidden'>

                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <h5 style={{fontSize: '98%'}}><b>Payroll</b></h5>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Generate Payroll Range</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="marital_status"
                                        onChange={handleChangeGeneratePayrollRange}
                                        value={item.generatePayrollRange}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="">Select Days</option>
                                        <option value="1">1 day after</option>
                                        <option value="2">2 days after</option>
                                        <option value="3">3 days after</option>
                                        <option value="4">4 days after</option>
                                        <option value="5">5 days after</option>
                                        <option value="6">6 days after</option>
                                        <option value="7">7 days after</option>
                                        <option value="8">8 days after</option>
                                        <option value="9">9 days after</option>
                                        <option value="10">10 days after</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={2} >
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Payroll Actual Data</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="payrollActualData"
                                        onChange={handleChangePayrollActualData}
                                        value={item.payrollActualData}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="0">Disable</option>
                                        <option value="1">Enable</option>

                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Withholding Tax Schedule</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={3}>
                                <Form.Group className='mb-3'>
                                    <Form.Select
                                        size={'sm'}
                                        name="withholdingTaxSchedule"
                                        onChange={handleChangeWithholdingTaxSchedule}
                                        value={item.withholdingTaxSchedule}
                                        // onBlur={handleBlur}
                                    >
                                        <option value="">Select When</option>
                                        <option value='semi_monthly'>Semi-Monthly</option>
                                        <option value='monthly_first_payroll'>Monthly First Payroll</option>
                                        <option value='monthly_second_payroll'>Monthly Second Payroll</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={1}>
                            </Col>
                        </Row>
                        <br/>
                        <Row>

                            <Col sm={1}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Payroll Tabs</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group  style={{marginLeft: '50%'}}>
                                    <Form.Label column="sm" className="text-muted fw-bold">Cutoff</Form.Label>
                                    <Form.Check
                                        label={""}
                                        name={"cutoff"}
                                        value={"cutoff"}
                                        id={"cutoff"}
                                        checked={item.cutoffTab == "1"? true: false}
                                        onClick={(event) => handleClickTab(event, "cutoff")}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group  style={{marginLeft: '50%'}}>
                                    <Form.Label column="sm" className="text-muted fw-bold">Rates</Form.Label>
                                    <Form.Check
                                        label={""}
                                        name={"rates"}
                                        value={"rates"}
                                        id={"rates"}
                                        checked={item.ratesTab == "1"? true: false}
                                        onClick={(event) => handleClickTab(event, "rates")}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group  style={{marginLeft: '50%'}}>
                                    <Form.Label column="sm" className="text-muted fw-bold">Earnings</Form.Label>
                                    <Form.Check
                                        label={""}
                                        name={"earnings"}
                                        value={"earnings"}
                                        id={"earnings"}
                                        checked={item.earningsTab == "1"? true: false}
                                        onClick={(event) => handleClickTab(event, "earnings")}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group  style={{marginLeft: '50%'}}>
                                    <Form.Label column="sm" className="text-muted fw-bold">Allowances</Form.Label>
                                    <Form.Check
                                        label={""}
                                        name={"allowances"}
                                        value={"allowances"}
                                        id={"allowances"}
                                        checked={item.allowancesTab == "1"? true: false}
                                        onClick={(event) => handleClickTab(event, "allowances")}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={2} className='d-flex justify-content-end hidden'>
                                <Form.Group  style={{marginLeft: '50%'}}>
                                    <Form.Label column="sm" className="text-muted fw-bold">Deductions</Form.Label>
                                    <Form.Check
                                        label={""}
                                        name={"deductions"}
                                        value={"deductions"}
                                        id={"deductions"}
                                        checked={item.deductionsTab == "1"? true: false}
                                        onClick={(event) => handleClickTab(event, "deductions")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>

                            <Col sm={1}>
                            </Col>
                            <Col sm={6} className='d-flex justify-content-end hidden'>

                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <h5 style={{fontSize: '98%'}}><b>Employees Custom Fields</b></h5>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Category</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Field Name</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Field Type</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={2}>
                                <Form.Group className='mb-3'>
                                    <Form.Label column="sm" className="text-muted fw-bold">Field Length</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            item.customFields.map((field, index) => (
                                <Row key={index}>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3'>
                                            <Form.Label column="sm" className="text-muted fw-bold">Custom {index + 1}</Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3'>
                                            <Form.Select
                                                size={'sm'}
                                                name={field.name +"-"+field.category +index}
                                                onChange={(e: any) => handleChangeCustomCategory(e,index)}
                                                value={field.category}
                                            >
                                                <option value="" disabled>Select Category</option>
                                                <option value="personal">Personal</option>
                                                <option value="company">Company</option>
                                                <option value="salary">Salary</option>
                                                <option value="shift">Shift</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3'>
                                            <Form.Control
                                                size={'sm'}
                                                type='text'
                                                placeholder='Field Name'
                                                name={field.name + index}
                                                value={field.name}
                                                onChange={(e: any) => handleChangeCustomFieldName(e,index)}

                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3'>
                                            <Form.Select
                                                size={'sm'}
                                                name={field.name +"-"+field.type +index}
                                                onChange={(e: any) => handleChangeCustomType(e,index)}
                                                value={field.type}
                                            >
                                                <option value="" disabled>Select Type</option>
                                                <option value="text">Text</option>
                                                <option value="number">Number</option>
                                                <option value="date">Date</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className='mb-3'>
                                            <Form.Control
                                                className={'text-right'}
                                                size={'sm'}
                                                type='number'
                                                placeholder='Length'
                                                name={field.name +"-"+field.length +index}
                                                disabled={field.type == "text" ? false : true}
                                                value={field.length}
                                                onChange={(e: any) => handleChangeCustomLength(e,index)}

                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Group className='d-flex'>
                                            <Button
                                                onClick={() => {
                                                    setItem({...item, customFields: [...item.customFields, {category: '',name: '',type: '', length: 0}]})
                                                }}
                                                className='rounded text-white'
                                                variant='transparent'
                                                size="sm"
                                                disabled={item.customFields.length > index+1 ? true : false}
                                            >
                                                <CircleCreate/>
                                            </Button>
                                            <Button
                                                onClick={() => {

                                                    const filteredArray = item.customFields.filter((field, i) => i !== index)
                                                    setItem({...item, customFields: filteredArray})
                                                }}
                                                className='rounded text-white'
                                                variant='transparent'
                                                size="sm"
                                                disabled={item.customFields.length == 1}
                                                style={{marginLeft: '-0.5rem'}}
                                            >
                                                <Reject/>
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            ))
                        }


                        <br/>

                        <Form.Group >
                            <Button
                                onClick={handleUpdateGeneralSetup}
                                type="submit"
                                variant='primary text-white'
                                style={{ width: '10%', float:'right' }}
                                size="sm">
                                Save Changes
                            </Button>
                        </Form.Group>

                    </Card.Body>
                </Card>



                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <br />
            </Container>
        </>


    )
}
