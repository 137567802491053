import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ISalaryValue } from './types'

const initialState: ISalaryValue = {
  pay_grade_id: 0,
  pay_grade_name: '',
  basic_salary: '',
  gross_salary: '',
  isCustom: '',
  is_daily_rate: 0,
  daily_rate: '',
  allowances: [{
    allowance_id: 0,
    amount: ""
  }],
  deductions: [{
    deduction_id: 0
  }],
}

export const SalarySlice = createSlice({
  name: 'Salary',
  initialState,
  reducers: {
    addSalary: (_state, action: PayloadAction<ISalaryValue>) => action.payload,
  },
})

export const { addSalary } = SalarySlice.actions
export default SalarySlice.reducer
