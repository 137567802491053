import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IShiftValue } from './types'

const initialState: IShiftValue = {
  no_of_hours_work_per_day: '',
  schedule_type: '',
  work_shift_id: '',
  work_site: '',
  grace_time_period: '',
  grace_period_minutes: 0,
  grace_time_period_out: '',
  grace_period_minutes_out: 0,
  shift_type: 0,
  shift: [
    {
      id: 0,
      employee_id: 0,
      day: 0,
      start_from: '',
      end_to: '',
      break_in: '',
      break_out: '',
      is_rest_day: '',
      late_count_time: '',
      created_at: '',
      updated_at: ''
    },
    {
      id: 0,
      employee_id: 0,
      day: 1,
      start_from: '',
      end_to: '',
      break_in: '',
      break_out: '',
      is_rest_day: '',
      late_count_time: '',
      created_at: '',
      updated_at: ''
    },
    {
      id: 0,
      employee_id: 0,
      day: 2,
      start_from: '',
      end_to: '',
      break_in: '',
      break_out: '',
      is_rest_day: '',
      late_count_time: '',
      created_at: '',
      updated_at: ''
    },
    {
      id: 0,
      employee_id: 0,
      day: 3,
      start_from: '',
      end_to: '',
      break_in: '',
      break_out: '',
      is_rest_day: '',
      late_count_time: '',
      created_at: '',
      updated_at: ''
    },
    {
      id: 0,
      employee_id: 0,
      day: 4,
      start_from: '',
      end_to: '',
      break_in: '',
      break_out: '',
      is_rest_day: '',
      late_count_time: '',
      created_at: '',
      updated_at: ''
    },
    {
      id: 0,
      employee_id: 0,
      day: 5,
      start_from: '',
      end_to: '',
      break_in: '',
      break_out: '',
      is_rest_day: '',
      late_count_time: '',
      created_at: '',
      updated_at: ''
    },
    {
      id: 0,
      employee_id: 0,
      day: 6,
      start_from: '',
      end_to: '',
      break_in: '',
      break_out: '',
      is_rest_day: '',
      late_count_time: '',
      created_at: '',
      updated_at: ''
    },
  ]
}

export const ShiftSlice = createSlice({
  name: 'Shift',
  initialState,
  reducers: {
    editShift: (_state, action: PayloadAction<IShiftValue>) => action.payload,
  },
})

export const { editShift } = ShiftSlice.actions
export default ShiftSlice.reducer
