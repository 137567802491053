import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {
  Breadcrumb,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Button, Modal, InputGroup
} from 'react-bootstrap'
import Accept from 'asset/SvgComponent/Accept'
import Reject from 'asset/SvgComponent/Reject'
import Delete from 'asset/SvgComponent/Delete'
import Dialog from 'components/Dialog'
import axios from "../../../../api";
import {IAPI_Response} from "../../../../api/types";
import {API_ENDPOINT, ERROR_MESSAGE, SUCCESS_MESSAGE, WHITE_LISTED} from "../../../../utils/globalConstant";
import moment from "moment/moment";
import {IDevice, IDeviceList, IBranch, IDepartment, IWhiteListed, IWhiteListedList} from "./types";
import Pagination from "components/Pagination";
import EditIcon from "asset/SvgComponent/EditIcon";
import {Formik} from "formik";
import {validationSchema} from './validationSchema';
import Select from "components/ReactSelect";
import {IEmployee, IWorksite} from "../../../Attendance/AttendanceReport/SecurityAgency/types";
import Alert from "components/Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";

export default function Index() {
  const [key, setKey] = useState('pending')
  const [validation, setValidation] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)

  const [showAllow, setShowAllow] = useState(false)
  const handleCloseAllow = () => setShowAllow(false)
  const handleShowAllow = () => setShowAllow(true)

  const [showReject, setShowReject] = useState(false)
  const handleCloseReject = () => setShowReject(false)
  const handleShowReject = () => setShowReject(true)

  const [deleteId, setDeleteId] = useState();
  const [showDelete, setShowDelete] = useState(false)
  const handleCloseDelete = () => setShowDelete(false)
  const handleShowDelete = (id: any) => {
    setShowDelete(true)
    setDeleteId(id)
  }
  const [showDeleteWhiteListed, setShowDeleteWhiteListed] = useState(false)
  const handleCloseDeleteWhiteListed = () => setShowDeleteWhiteListed(false)
  const handleShowDeleteWhiteListed = (id: any) => {
    setShowDeleteWhiteListed(true)
    setDeleteId(id)
  }
  const [allowedDevices, setAllowedDevices] = useState<IDevice[]>([]);
  const [notAllowedDevices, setNotAllowedDevices] = useState<IDevice[]>([]);
  const [whiteListed, setWhiteListed] = useState<IWhiteListed[]>([]);

  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [currentPage2, setCurrentPage2] = React.useState(0)
  const [lastPage2, setLastPage2] = React.useState(0)
  const [itemsPerPage2, setPerPage2] = React.useState(10)
  const [itemsCount2, setItemCount2] = React.useState(0)
  const [from2, setFrom2] = React.useState(0)

  const [currentPage3, setCurrentPage3] = React.useState(0)
  const [lastPage3, setLastPage3] = React.useState(0)
  const [itemsPerPage3, setPerPage3] = React.useState(10)
  const [itemsCount3, setItemCount3] = React.useState(0)
  const [from3, setFrom3] = React.useState(0)

  const [showCreate, setShowCreate] = React.useState(false)
  const [showCreateWhiteListed, setShowCreateWhiteListed] = React.useState(false)
  const [label, setLabel] = React.useState('Employees')
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "All Options"})
  const [selectedDeviceId, setSelectedDeviceId] = useState(0);
  const [selectedEmployeeOptionPending, setSelectedEmployeeOptionPending] = useState<{value: number, label: string}>({value: 0, label: "All Options"})
  const [selectedEmployeeOptionAllowed, setSelectedEmployeeOptionAllowed] = useState<{value: number, label: string}>({value: 0, label: "All Options"})
  const [selectedEmployeeOptionWhiteListed, setSelectedEmployeeOptionWhiteListed] = useState<{value: number, label: string}>({value: 0, label: "All Options"})
  const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
  const [worksiteOptions, setWorksiteOptions] = useState<{value: number, label: string}[]>([]);
  const [type, setType] = useState(-1)
  const [typeAllowed, setTypeAllowed] = useState(-1)
  const [optionIdAllowed, setOptionIdAllowed] = useState(0)
  const [dateFormat, setDateFormat] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [searchedPending, setSearchedPending] = useState("")
  const [searchedAllowed, setSearchedAllowed] = useState("")
  const [searchedWhiteListed, setSearchedWhiteListed] = useState("")
  const [entriesPending, setEntriesPending] = useState(10)
  const [entriesAllowed, setEntriesAllowed] = useState(10)
  const [entriesWhiteListed, setEntriesWhiteListed] = useState(10)

  const handleChangeEntriesPending = (e:any) => {
    setEntriesPending(e.target.value)
    getDevicesNotAllowed(null, searchedPending, e.target.value)
  }

  const handleChangeEntriesAllowed = (e: any) => {
    setEntriesAllowed(e.target.value)
    getDevicesAllowed(null, searchedAllowed, e.target.value)
  }

  const handleChangeEntriesWhiteListed = (e: any) => {
    setEntriesWhiteListed(e.target.value)
    getWhiteListed(null, searchedWhiteListed, e.target.value)
  }

  const handleShowCreate = () => {
    setShowCreate(true)
  }
  const handleCloseCreate = () => {
    setShowCreate(false)
  }

  const handleShowCreateWhiteListed = () => {
    setShowCreateWhiteListed(true)
  }
  const handleCloseCreateWhiteListed = () => {
    setShowCreateWhiteListed(false)
    setSelectedEmployeeOptionWhiteListed({value: 0, label: "All Options"})
  }

  const handleChangeDropdownAllowed = (selectedOption: any) => {
    setSelectedEmployeeOptionAllowed(selectedOption)
  }

  const handleChangeDropdownPending = (selectedOption: any) => {
    setSelectedEmployeeOptionPending(selectedOption)
  }

  const handleChangeDropdownWhiteListed = (selectedOption: any) => {
    setSelectedEmployeeOptionWhiteListed(selectedOption)
  }

  const handleChangeEmployeeDropdown = (selectedOption: any) => {
    setSelectedEmployeeOption(selectedOption)
  }

  const handleClickApproveDisapprove = (id: number) => {
    setSelectedDeviceId(id)
  }

  const handleChangeTypeDropdownAllowed = (e: any) => {
    setTypeAllowed(e.target.value)
    if(e.target.value == 1){  // Branches
      getBranches()
    }else if(e.target.value == 2){   // Departments
      getDepartments()
    }else if(e.target.value == -1){
      setWorksiteOptions([])
    }

  }

  const handleChangeTypeDropdown = (e: any) => {
    setType(e.target.value)
    if(e.target.value == 1){  // Branches
      getBranches()
    }else if(e.target.value == 2){   // Departments
      getDepartments()
    }else if(e.target.value == -1){
      setWorksiteOptions([])
    }

  }

  const handleChangeSearchedKeywordPending = (e:any) => {
    setSearchedPending(e.target.value)
    getDevicesNotAllowed(null, e.target.value, entriesPending)
  }

  const handleChangeSearchedKeywordAllowed = (e:any) => {
    setSearchedAllowed(e.target.value)
    getDevicesAllowed(null, e.target.value, entriesAllowed)
  }

  const handleChangeSearchedKeywordWhiteListed = (e:any) => {
    setSearchedWhiteListed(e.target.value)
    getWhiteListed(null, e.target.value, entriesWhiteListed)
  }

  const getDateFormat = async () => {
    try {
      const res = await axios.get<IAPI_Response<{dateFormat:string}>>(API_ENDPOINT.GET_DATE_FORMAT)
      const result = await res
      if (result?.data?.results) {
        setDateFormat(result?.data?.results?.dateFormat)

      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getTimeFormat = async () => {
    try {
      const res = await axios.get<IAPI_Response<{timeFormat:string}>>(API_ENDPOINT.GET_TIME_FORMAT)
      const result = await res
      if (result?.data?.results) {
        setTimeFormat(result?.data?.results?.timeFormat)

      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleDeleteDevice = async (id: any) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_DEVICE +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.DEVICE.DELETED)
        getDevicesAllowed(currentPage, searchedAllowed, entriesAllowed);
        getDevicesNotAllowed(currentPage2, searchedPending, entriesPending);
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseDelete()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const handleDeleteWhiteListed = async (id: any) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_WHITE_LISTED +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.WHITE_LISTED.DELETED)
        getWhiteListed(currentPage3, searchedWhiteListed, entriesWhiteListed);
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseDelete()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getWorkSite = async () => {

    try {
      const response = await axios.get<IAPI_Response<IWorksite[]>>(API_ENDPOINT.ALL_WORK_SITE)
      const result = await response
      if (result?.data?.results?.length) {
        const worksites = result?.data?.results;
        setWorksiteOptions([])
        setWorksiteOptions(current => [...current, {value: 0, label: "All Worksites"}]);
        worksites.map(worksite => {
          setWorksiteOptions(current => [...current, {value: worksite.id, label: worksite.work_site_name}]);
        })
      }else{
        setWorksiteOptions([])
        setWorksiteOptions(current => [...current, {value: 0, label: "All Worksites"}]);
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getBranches = async () => {

    try {
      const response = await axios.get<IAPI_Response<IBranch[]>>(API_ENDPOINT.ALL_BRANCH)
      const result = await response
      if (result?.data?.results?.length) {
        const worksites = result?.data?.results;
        setWorksiteOptions([])
        setWorksiteOptions(current => [...current, {value: 0, label: "All Branches"}]);
        worksites.map(worksite => {
          setWorksiteOptions(current => [...current, {value: worksite.branch_id, label: worksite.branch_name}]);
        })
      }else{
        setWorksiteOptions([])
        setWorksiteOptions(current => [...current, {value: 0, label: "All Branches"}]);
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getDepartments = async () => {

    try {
      const response = await axios.get<IAPI_Response<IDepartment[]>>(API_ENDPOINT.ALL_DEPARTMENT)
      const result = await response
      if (result?.data?.results?.length) {
        const worksites = result?.data?.results;
        setWorksiteOptions([])
        setWorksiteOptions(current => [...current, {value: 0, label: "All Departments"}]);
        worksites.map(worksite => {
          setWorksiteOptions(current => [...current, {value: worksite.department_id, label: worksite.department_name}]);
        })
      }else{
        setWorksiteOptions([])
        setWorksiteOptions(current => [...current, {value: 0, label: "All Departments"}]);
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getAllEmployee = async () => {
    try {
      const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
      const result = await res
      if (result?.data?.results?.length) {

        const employees = result?.data?.results;
        setEmployeeOptions([])

        setEmployeeOptions(current => [...current, {value: 0, label: "All Employees"}]);
        employees.map(employee => {
          setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
        })
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const handleChangeType = async(e:any) => {
    if(e.target.value != ""){
      if(e.target.value == "0"){
        setLabel("Employees")
        try {
          const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
          const result = await res
          if (result?.data?.results?.length) {
            const employees = result?.data?.results;
            setEmployeeOptions([])
            setEmployeeOptions(current => [...current, {value: 0, label: "All Employees"}]);
            employees.map(employee => {
              setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
            })
          }
        } catch {
          console.log('There is something wrong')
        }

      }else{
        setLabel("Worksites")
        try {
          const response = await axios.get<IAPI_Response<IWorksite[]>>(API_ENDPOINT.ALL_WORK_SITE)
          const result = await response
          if (result?.data?.results?.length) {
            const worksites = result?.data?.results
            setEmployeeOptions([])
            setEmployeeOptions(current => [...current, {value: 0, label: "All Worksites"}]);
            worksites.map(worksite => {
              setEmployeeOptions(current => [...current, {value: worksite.id, label: worksite.work_site_name}]);
            })
          }else{
            setEmployeeOptions([])
            setEmployeeOptions(current => [...current, {value: 0, label: "All Worksites"}]);
          }
        } catch {
          console.log('There is something wrong')
        }

      }
    }

  }

  const handleSaveWhiteListed = async () => {
    await axios.post(API_ENDPOINT.POST_WHITE_LISTED, {
      employee_id: selectedEmployeeOptionWhiteListed.value
    }).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(WHITE_LISTED.SUCCESS)
        getWhiteListed(null, searchedWhiteListed, entriesWhiteListed)
      }
      handleCloseCreateWhiteListed();
    }).catch(error => {
      setValidation(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const postDevice = async(value: any, {resetForm} : any) => {

    if(selectedEmployeeOption.value == 0){
      setError("Employee or worksite is required.")
      setShowError(true)
    }

    await axios.post(API_ENDPOINT.POST_DEVICE, {
      type: value.type,
      employee_worksite_id: selectedEmployeeOption.value,
      name: selectedEmployeeOption.label,
      device: value.device_name,
      browser: value.browser
    }).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.DEVICE.CREATED)
        getDevicesAllowed(null, searchedAllowed, entriesAllowed)
        getDevicesNotAllowed(null, searchedPending, entriesPending)
      }
      resetForm({})
      handleCloseCreate();
    }).catch(error => {
      setValidation(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const approveDisapproveDevice = async(isApprove: number) => {

    await axios.put(API_ENDPOINT.APPROVE_DISAPPROVE_DEVICE + "/"+selectedDeviceId, {
      is_approved: isApprove
    }).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.DEVICE.UPDATED)
        getDevicesAllowed(currentPage, searchedAllowed, entriesAllowed)
        getDevicesNotAllowed(currentPage2, searchedPending, entriesPending)
      }

    }).catch(error => {
      setError(error)
      setShowError(true)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const getDevicesAllowed = async(page_number: number | null, searchedKeyword: string, entriesNumber: number) => {
    const response = await axios.get<IAPI_Response<IDeviceList>>(API_ENDPOINT.GET_DEVICES_ALLLOWED_FOR_STANDARD + "/" + typeAllowed + "/" + selectedEmployeeOptionAllowed.value , {
      params: {
        page: page_number,
        searched: searchedKeyword,
        entries: entriesNumber
      },
    })
    const result = await response
    if(result?.data?.results?.data?.length){
      setAllowedDevices(result?.data?.results?.data);
      setCurrentPage(result?.data?.results?.current_page)
      setLastPage(result?.data?.results?.last_page)
      setPerPage(result?.data?.results?.per_page)
      setItemCount(result?.data?.results?.total)
      setFrom(result?.data?.results?.from)
    }else{
      setAllowedDevices([])
      setLastPage(1)
    }
  }

  const getDevicesNotAllowed = async(page_number: number | null, searchedKeyword: string, entriesNumber: number) => {

    const response = await axios.get<IAPI_Response<IDeviceList>>(API_ENDPOINT.GET_DEVICES_NOT_ALLLOWED_FOR_STANDARD + "/" + type + "/" + selectedEmployeeOptionPending.value, {
      params: {
        page: page_number,
        searched: searchedKeyword,
        entries: entriesNumber
      },
    })
    const result = await response
    if(result?.data?.results?.data?.length){
      setNotAllowedDevices(result?.data?.results?.data);
      setCurrentPage2(result?.data?.results?.current_page)
      setLastPage2(result?.data?.results?.last_page)
      setPerPage2(result?.data?.results?.per_page)
      setItemCount2(result?.data?.results?.total)
      setFrom2(result?.data?.results?.from)
    }else{
      setNotAllowedDevices([])
      setLastPage2(1)
    }

  }

  const getWhiteListed = async(page_number: number | null, searchedKeyword: string, entriesNumber: number) => {

    const response = await axios.get<IAPI_Response<IWhiteListedList>>(API_ENDPOINT.GET_WHITE_LISTED, {
      params: {
        page: page_number,
        searched: searchedKeyword,
        entries: entriesNumber
      },
    })
    const result = await response
    if(result?.data?.results?.data?.length){
      setWhiteListed(result?.data?.results?.data);
      setCurrentPage3(result?.data?.results?.current_page)
      setLastPage3(result?.data?.results?.last_page)
      setPerPage3(result?.data?.results?.per_page)
      setItemCount3(result?.data?.results?.total)
      setFrom3(result?.data?.results?.from)
    }else{
      setWhiteListed([])
      setLastPage3(1)
    }

  }

  useEffect(() => {
    ;(async () => {
      getDevicesAllowed(null, searchedAllowed, entriesAllowed)
      getDevicesNotAllowed(null, searchedPending, entriesPending)
      getWhiteListed(null, searchedWhiteListed, entriesWhiteListed)
      getAllEmployee()
      // getWorkSite()

      getDateFormat()
      getTimeFormat()
    })()
  }, [])

  return (
    <Container fluid>
      <Row>
        <Col sm={6}>
          <h5 className='fw-bold'>Allowed Devices</h5>
          <Breadcrumb className='breadcrumbs'>
            <Breadcrumb.Item className=' text-decoration-none ' href='/home'>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item className=' text-decoration-none ' href='#'>
              Admin
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Allowed Devices</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Dialog
        variant={'approve'}
        show={showAllow} 
        setShow={handleCloseAllow}
        setEvent={() => {
          handleCloseAllow()
          approveDisapproveDevice(1)
        }
        }
        message={"Do you really want to approve this device?"}
      />

      <Dialog
        variant={'reject'}
        show={showReject} 
        setShow={handleCloseReject}
        setEvent={() => {
          handleCloseReject()
          approveDisapproveDevice(2)
        }
        }
        message={"Do you really want to reject this device?"}
      />

      <Dialog
        variant={'delete'}
        show={showDelete || showDeleteWhiteListed}
        setShow={showDelete ? handleCloseDelete :
                  showDelete ? handleCloseDeleteWhiteListed : handleCloseDelete}
        setEvent={() => {
          if(showDelete){
            handleCloseDelete
            handleDeleteDevice(deleteId)
          }
          if(showDeleteWhiteListed){
            handleCloseDeleteWhiteListed()
            handleDeleteWhiteListed(deleteId)
          }

        }
        }
      />

      {/*<Dialog*/}
      {/*    variant={'delete'}*/}
      {/*    show={showDeleteWhiteListed}*/}
      {/*    setShow={handleCloseDeleteWhiteListed()}*/}
      {/*    setEvent={() => {*/}
      {/*      handleCloseDeleteWhiteListed()*/}
      {/*      handleDeleteWhiteListed(deleteId)*/}
      {/*    }*/}
      {/*    }*/}
      {/*    message={"Do you really want to delete this whitelisted?"}*/}
      {/*/>*/}

      <Tabs
        variant={'tabs'}
        className='tabs '
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k: any) => {
          setKey(k)
        }}
      >
        <Tab className='p-0' eventKey='pending' title='Pending'>
          <Card className='border-0  p-0 bordered-card-tabs'>
            <Card.Body>
              <Row>
                <Col sm={8}>
                  <Form noValidate>
                    <Row>
                      <Col sm={3}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels'>Type</Form.Label>
                          <Form.Select
                              name="type"
                              size='sm'
                              onChange={handleChangeTypeDropdown}
                          >
                            <option value="-1">All</option>
                            <option value="0">Employee</option>
                            <option value="1">Branch</option>
                            {/*<option value="2">Department</option>*/}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels'>{type == 0 ? "Employees" :
                              type == 1 ? "Branches" :
                                  type == 2 ? "Departments": "Options"}</Form.Label>
                          {/*<Form.Select*/}
                          {/*    name="employee_id"*/}
                          {/*    size='sm'*/}
                          {/*>*/}
                          {/*  <option value="">Select Employee</option>*/}
                          {/*  <option value="1">Apply to all employees</option>*/}
                          {/*  <option value="2">Employee 1</option>*/}
                          {/*  <option value="3">Employee 2</option>*/}
                          {/*</Form.Select>*/}
                          <Select
                              name="employee_id"
                              value={selectedEmployeeOptionPending}
                              options={type == 0 ? employeeOptions : worksiteOptions}
                              onChange={(e: any) => {
                                handleChangeDropdownPending(e);
                              }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                          <br />
                          <Button
                              className='add-new-btn rounded me-1 text-white'
                              variant='primary'
                              type="button"
                              onClick={() => getDevicesNotAllowed(null, searchedPending, entriesPending)}
                          >
                            Search
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col sm={4} className='d-flex justify-content-end hidden'>
                  <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                    <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                    <br />
                    <InputGroup size='sm' className={'w-100'}>
                      <Form.Control
                          aria-label='search'
                          aria-describedby='inputGroup-sizing-sm'
                          placeholder='Search...'
                          onChange={handleChangeSearchedKeywordPending}
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Table responsive size={'sm'} className='mt-3'>
                <thead>
                  <tr>
                    <th className={'text-center'} style={{ width: '5%' }}>No.</th>
                    <th className={'text-center'} style={{ width: '20%' }}>Name</th>
                    <th className={'text-center'} style={{ width: '12%' }}>Access Type</th>
                    <th className={'text-center'} style={{ width: '12%' }}>Device</th>
                    <th className={'text-center'} style={{ width: '12%' }}>Browser</th>
                    <th className={'text-center'} style={{ width: '20%' }}>Requested By</th>
                    <th className={'text-center'} style={{ width: '12%' }}>Requested Date</th>
                    <th className={'text-center'}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {
                      notAllowedDevices.length > 0 ?
                        notAllowedDevices.map((device, index) => (
                            <tr className="mb-2" key={index}>
                              <td>{index + from2}</td>
                              <td className="fw-bold">{device.name}</td>
                              <td>{device.type == 0? "User":
                                  device.type == 1 ? "Branch" :
                                  device.type == 2 ? "Department" : ""}</td>
                              <td>{device.device}</td>
                              <td>{device.browser}-{device.browser_fingerprint.slice(-4)}</td>
                              <td>{device.created_by ?? "-"}</td>
                              <td>{moment(new Date(device.created_at ?? "")).format(dateFormat)} {moment(new Date(device.created_at ?? "")).format(timeFormat)}</td>
                              <td className="text-center">
                                <Button
                                    onClick={() => {
                                      handleShowAllow()
                                      handleClickApproveDisapprove(device.id)
                                    }}
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px'}}
                                    variant=" btn-transparent"
                                    size="sm"
                                >
                                  <Accept/>
                                </Button>
                                <Button
                                    onClick={() => {
                                      handleShowReject()
                                      handleClickApproveDisapprove(device.id)
                                    }}
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px'}}
                                    variant=" btn-transparent"
                                    size="sm"
                                >
                                  <Reject/>
                                </Button>
                              </td>
                            </tr>
                        ))
                          : <tr className="mb-2">
                              <td colSpan={8} className="text-center">No Pending Devices.</td>
                          </tr>
                    }

                  </>
                </tbody>
              </Table>
              <Row>
                <Col sm={4}>
                  <Row>
                    <Col sm={7}>
                      <Pagination
                          itemsCount={itemsCount2}
                          itemsPerPage={itemsPerPage2}
                          currentPage={currentPage2}
                          lastPage={lastPage2}
                          setCurrentPage={setCurrentPage2}
                          alwaysShown={false}
                          pageClicked={(page: number) => {
                            getDevicesNotAllowed(page, searchedPending, entriesPending);
                          }
                          }
                      />
                    </Col>
                    <Col sm={5}>
                      <Form.Group >
                        {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                      </Form.Group>
                      <Form.Group >
                        <Form.Select
                            size={'sm'}
                            name="entries_pending"
                            value={entriesPending}
                            onChange={handleChangeEntriesPending}
                            style={{width: '80px'}}
                            // onBlur={handleBlur}
                            // isInvalid={touched.work_shift_id && !!errors.work_shift_id}
                        >
                          <option value="10" selected>10</option>
                          <option value="25" >25</option>
                          <option value="50" >50</option>
                          <option value="100" >100</option>

                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </Card.Body>
          </Card>
        </Tab>

        <Tab className='p-0' eventKey='allowed' title='Allowed' tabClassName='tabs'>
          <Card className='border-0  p-0 bordered-card-tabs'>
            <Card.Body>
              <Row>
                <Col sm={8}>
                  <Form noValidate>
                    <Row>
                      <Col sm={3}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels'>Type</Form.Label>
                          <Form.Select
                              name="type"
                              size='sm'
                              onChange={handleChangeTypeDropdownAllowed}
                          >
                            <option value="-1">All</option>
                            <option value="0">Employee</option>
                            <option value="1">Branch</option>
                            {/*<option value="2">Department</option>*/}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels'>{typeAllowed == 0 ? "Employees" :
                              typeAllowed == 1 ? "Branches" :
                                  typeAllowed == 2 ? "Departments" : "Options"}</Form.Label>
                          {/*<Form.Select*/}
                          {/*    name="employee_id"*/}
                          {/*    size='sm'*/}
                          {/*>*/}
                          {/*  <option value="">Select Employee</option>*/}
                          {/*  <option value="1">Apply to all employees</option>*/}
                          {/*  <option value="2">Employee 1</option>*/}
                          {/*  <option value="3">Employee 2</option>*/}
                          {/*</Form.Select>*/}
                          <Select
                              name="employee_id"
                              value={selectedEmployeeOptionAllowed}
                              options={typeAllowed == 0 ? employeeOptions : worksiteOptions}
                              onChange={(e: any) => {
                                handleChangeDropdownAllowed(e);
                              }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                          <br />
                          <Button
                              className='add-new-btn rounded me-1 text-white'
                              variant='primary'
                              type="button"
                              onClick={() => getDevicesAllowed(null, searchedAllowed, entriesAllowed)}
                          >
                            Search
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col sm={4} className='d-flex justify-content-end hidden'>
                  <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                    <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                    <br />
                    <InputGroup size='sm' className={'w-100'}>
                      <Form.Control
                          aria-label='search'
                          aria-describedby='inputGroup-sizing-sm'
                          placeholder='Search...'
                          onChange={handleChangeSearchedKeywordAllowed}
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Table responsive size={'sm'} className='mt-3'>
                <thead>
                  <tr>
                    <th className={'text-center'} style={{ width: '5%' }}>No.</th>
                    <th className={'text-center'} style={{ width: '20%' }}>Name</th>
                    <th className={'text-center'} style={{ width: '10%' }}>Access Type</th>
                    <th className={'text-center'} style={{ width: '10%' }}>Device</th>
                    <th className={'text-center'} style={{ width: '10%' }}>Browser</th>
                    <th className={'text-center'} style={{ width: '20%' }}>Requested By</th>
                    <th className={'text-center'} style={{ width: '12%' }}>Requested Date</th>
                    <th className={'text-center'} style={{ width: '12%' }}>Approved Date</th>
                    <th className={'text-center'}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {
                      allowedDevices.length > 0 ?
                          allowedDevices.map((device, index) => (
                              <tr className="mb-2" key={index}>
                                <td>{index + from}</td>
                                <td className="fw-bold">{device.name}</td>
                                <td>{device.type == 0? "User":
                                    device.type == 1 ? "Branch" :
                                        device.type == 2 ? "Department" : ""}</td>
                                <td>{device.device}</td>
                                <td>{device.browser}-{device.browser_fingerprint.slice(-4)}</td>
                                <td>{device.created_by ?? "-"}</td>
                                <td>{moment(new Date(device.created_at ?? "")).format(dateFormat)} {moment(new Date(device.created_at ?? "")).format(timeFormat)}</td>
                                <td>{moment(new Date(device.updated_at ?? "")).format(dateFormat)} {moment(new Date(device.updated_at ?? "")).format(timeFormat)}</td>
                                <td className="text-center">
                                  <Button
                                      onClick={() => handleShowDelete(device.id)}
                                      className=" p-1 pt-0 pb-0"
                                      style={{fontSize: '12px'}}
                                      variant=" btn-transparent"
                                      size="sm"
                                  >
                                    <Delete/>
                                  </Button>
                                  {/*<Button*/}
                                  {/*    onClick={() => handleShowReject()}*/}
                                  {/*    className=" p-1 pt-0 pb-0"*/}
                                  {/*    style={{fontSize: '12px'}}*/}
                                  {/*    variant=" btn-transparent"*/}
                                  {/*    size="sm"*/}
                                  {/*>*/}
                                  {/*  <Reject/>*/}
                                  {/*</Button>*/}
                                </td>
                              </tr>
                          ))
                          : <tr className="mb-2">
                            <td colSpan={9} className="text-center">No Allowed Devices.</td>
                          </tr>
                    }
                  </>
                </tbody>
              </Table>
              <Row>
                <Col sm={4}>
                  <Row>
                    <Col sm={7}>
                      <Pagination
                          itemsCount={itemsCount}
                          itemsPerPage={itemsPerPage}
                          currentPage={currentPage}
                          lastPage={lastPage}
                          setCurrentPage={setCurrentPage}
                          alwaysShown={false}
                          pageClicked={(page: number) => {
                            getDevicesAllowed(page, searchedAllowed, entriesAllowed);
                          }
                          }
                      />
                    </Col>
                    <Col sm={5}>
                      <Form.Group >
                        {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                      </Form.Group>
                      <Form.Group >
                        <Form.Select
                            size={'sm'}
                            name="entries_allowed"
                            value={entriesAllowed}
                            onChange={handleChangeEntriesAllowed}
                            style={{width: '80px'}}
                            // onBlur={handleBlur}
                            // isInvalid={touched.work_shift_id && !!errors.work_shift_id}
                        >
                          <option value="10" selected>10</option>
                          <option value="25" >25</option>
                          <option value="50" >50</option>
                          <option value="100" >100</option>

                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </Card.Body>
          </Card>
        </Tab>

        <Tab className='p-0' eventKey='whitelisted' title='Whitelisted' tabClassName='tabs'>
          <Card className='border-0  p-0 bordered-card-tabs'>
            <Card.Body>
              <Row>
                <Col sm={8}>
                </Col>
                <Col sm={4} className='d-flex justify-content-end hidden'>
                  <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                    <InputGroup size='sm' className={'w-100'}>
                      <Form.Control
                          aria-label='search'
                          aria-describedby='inputGroup-sizing-sm'
                          placeholder='Search...'
                          onChange={handleChangeSearchedKeywordWhiteListed}
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                    <Button
                        onClick={handleShowCreateWhiteListed}
                        className=' add-new-btn rounded mx-2 text-white'
                        variant='warning'
                    >
                      &nbsp;Add Whitelisted
                    </Button>{' '}
                  </Form.Group>
                </Col>
              </Row>
              <Table responsive size={'sm'} className='mt-3'>
                <thead>
                <tr>
                  <th className={'text-center'} style={{ width: '10%' }}>No.</th>
                  <th className={'text-center'} style={{ width: '40%' }}>Employee Name</th>
                  <th className={'text-center'} style={{ width: '30%' }}>Created At</th>
                  <th className={'text-center'}>Actions</th>
                </tr>
                </thead>
                <tbody>
                <>
                  {
                    whiteListed.length > 0 ?
                        whiteListed.map((employee, index) => (
                            <tr className="mb-2" key={index}>
                              <td className="text-center">{index + from3}</td>
                              <td className="fw-bold">{employee.last_name}, {employee.first_name}</td>
                              <td className="fw-bold text-center">{moment(new Date(employee.created_at ?? "")).format(dateFormat)}</td>
                              <td className="text-center">
                                <Button
                                    onClick={() => handleShowDeleteWhiteListed(employee.id)}
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px'}}
                                    variant=" btn-transparent"
                                    size="sm"
                                >
                                  <Delete/>
                                </Button>
                              </td>
                            </tr>
                        ))
                        : <tr className="mb-2">
                          <td colSpan={9} className="text-center">No Whitelisted List.</td>
                        </tr>
                  }
                </>
                </tbody>
              </Table>
              <Row>
                <Col sm={4}>
                  <Row>
                    <Col sm={7}>
                      <Pagination
                          itemsCount={itemsCount3}
                          itemsPerPage={itemsPerPage3}
                          currentPage={currentPage3}
                          lastPage={lastPage3}
                          setCurrentPage={setCurrentPage3}
                          alwaysShown={false}
                          pageClicked={(page: number) => {
                            getWhiteListed(page, searchedWhiteListed, entriesWhiteListed);
                          }
                          }
                      />
                    </Col>
                    <Col sm={5}>
                      <Form.Group >
                        {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                      </Form.Group>
                      <Form.Group >
                        <Form.Select
                            size={'sm'}
                            name="entries_whitelisted"
                            value={entriesWhiteListed}
                            onChange={handleChangeEntriesWhiteListed}
                            style={{width: '80px'}}
                            // onBlur={handleBlur}
                            // isInvalid={touched.work_shift_id && !!errors.work_shift_id}
                        >
                          <option value="10" selected>10</option>
                          <option value="25" >25</option>
                          <option value="50" >50</option>
                          <option value="100" >100</option>

                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </Card.Body>
          </Card>
        </Tab>
      </Tabs>
      <Alert
          type="success"
          show={showSuccess}
          message={success}
          setShow={() => {
            setShowSuccess(false);
          }
          }
      />

      <Alert
          type="error"
          show={showError}
          message={error}
          setShow={() => {
            setShowError(false);
          }
          }
      />

      {/* ADD MODAL*/}
      <Formik
          initialValues={{ type: '', device_name: '', browser: ''}}
          validationSchema={validationSchema}
          onSubmit={postDevice}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="branchCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Add New Device</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="mb-3">
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='text-muted required'>Type</Form.Label>
                        <Form.Select
                            size={'sm'}
                            name="type"
                            onChange={ (event) => {
                              handleChange(event)
                              handleChangeType(event)
                            }}
                            onBlur={handleBlur}
                            isInvalid={touched.type && !!errors.type}
                        >
                          <option value="" disabled selected>Select Type</option>
                          <option value="0">User</option>
                          <option value="1">Worksite</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.type}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='text-muted required'>{label}</Form.Label>
                        {/*<Form.Select*/}
                        {/*    size={'sm'}*/}
                        {/*    name="employee_worksite_id"*/}
                        {/*    onChange={handleChange}*/}
                        {/*    onBlur={handleBlur}*/}
                        {/*    isInvalid={touched.employee_worksite_id && !!errors.employee_worksite_id}*/}
                        {/*>*/}
                        {/*  <option value="" disabled selected>Select Type</option>*/}
                        {/*  <option value="">Select type...</option>*/}
                        {/*  <option value="0">User</option>*/}
                        {/*  <option value="1">Worksite</option>*/}
                        {/*</Form.Select>*/}
                        <Select
                            name="employee_worksite_id"
                            value={selectedEmployeeOption}
                            options={employeeOptions}
                            onChange={handleChangeEmployeeDropdown}
                        />
                        {/*<Form.Control.Feedback type="invalid">*/}
                        {/*  {errors.employee_worksite_id}*/}
                        {/*</Form.Control.Feedback>*/}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='text-muted required'>Device Name</Form.Label>
                        <Form.Control
                            size={'sm'}
                            type='text'
                            placeholder='Device'
                            name="device_name"
                            value={values.device_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={(touched.device_name && !!errors.device_name) || !!validation}
                            autoComplete='off'
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.device_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                        <Form.Label className='text-muted required'>Browser</Form.Label>
                        <Form.Control
                            size={'sm'}
                            type='text'
                            placeholder='Browser'
                            name="browser"
                            value={values.browser}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={(touched.browser && !!errors.browser) || !!validation}
                            autoComplete='off'
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.device_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button
                      type="submit"
                      disabled={isSubmitting}
                      form="branchCreate"
                      variant='primary text-white'
                      size={'sm'}
                      className='mx-2'
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
        )}
      </Formik>

      {/* ADD WHITELISTED MODAL*/}

        <Modal className='modal' centered show={showCreateWhiteListed} onHide={handleCloseCreateWhiteListed}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Add WhiteListed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col sm={2}></Col>
              <Col sm={8}>
                <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                  <Form.Label className='text-muted required'>Employee</Form.Label>
                  <Select
                      name="employee_id"
                      value={selectedEmployeeOptionWhiteListed}
                      options={employeeOptions}
                      onChange={(e: any) => {
                        handleChangeDropdownWhiteListed(e);
                      }}
                  />
                </Form.Group>
              </Col>

            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCreateWhiteListed}>
              Cancel
            </Button>
            <Button
                onClick={handleSaveWhiteListed}
                type="submit"
                disabled={selectedEmployeeOptionWhiteListed.value == 0}
                form="branchCreate"
                variant='primary text-white'
                size={'sm'}
                className='mx-2'
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

    </Container>
  )
}
