import React from 'react'

const PayrollActiveIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13.846'
      height='9.563'
      viewBox='0 0 13.846 9.563'
    >
      <g id='PayrollActive' transform='translate(0.5 0.5)'>
        <path
          id='Path_35'
          data-name='Path 35'
          d='M12,9h9.276'
          transform='translate(-8.432 -9)'
          fill='none'
          stroke='#f78d24'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_36'
          data-name='Path 36'
          d='M12,18h9.276'
          transform='translate(-8.432 -13.719)'
          fill='none'
          stroke='#f78d24'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_37'
          data-name='Path 37'
          d='M12,27h9.276'
          transform='translate(-8.432 -18.438)'
          fill='none'
          stroke='#f78d24'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_38'
          data-name='Path 38'
          d='M4.5,9h0'
          transform='translate(-4.5 -9)'
          fill='none'
          stroke='#f78d24'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_39'
          data-name='Path 39'
          d='M4.5,18h0'
          transform='translate(-4.5 -13.719)'
          fill='none'
          stroke='#f78d24'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_40'
          data-name='Path 40'
          d='M4.5,27h0'
          transform='translate(-4.5 -18.438)'
          fill='none'
          stroke='#f78d24'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default PayrollActiveIcon
