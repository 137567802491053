import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { Form, Button, Image, InputGroup, Row, Modal} from 'react-bootstrap'
import Webcam from "react-webcam";
import LocationMarker from 'components/Marker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons/faCameraRetro'
import PersonIcon from 'asset/SvgComponent/PersonIcon'
import {
  MapContainer,
  TileLayer
} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import './style.css'
import moment from 'moment'
import { validationSchema } from './validationSchema'
import User from 'asset/SvgComponent/User'
import Password from 'asset/SvgComponent/Password'
import { Formik } from 'formik';
import Alert from 'components/Alert'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  ERROR_MESSAGE,
  ATTENDANCE,
  LOGIN
} from 'utils/globalConstant'
import { IAttendanceList } from './types'
import PasswordShow from "asset/SvgComponent/PasswordShow";
import PasswordIcon from 'asset/SvgComponent/PasswordIcon'
import { browserName, isMobile } from 'react-device-detect';
import { ClientJS } from 'clientjs'

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

const Index = () => {
  const [state, setState] = useState(true)
  const [show, setShow] = useState(false);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] =useState(false)
  const [invalidLogin, setInvalidLogin] = useState<string>('')
  const [displayTime, setDisplayTime] = useState<string>('')
  const [token, setToken] = useState<string>('')

  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
    address: ''
  })

  const [clock, setClock] = useState({
    attendance_status: '',
    disabled: false
  })
  const [breaks, setBreaks] = useState({
    attendance_break: ''
  })

  const [showSubmit, setSubmit] = useState(true)
  const [showMap, setShowMap] = useState(false)
  const [showCamera, setShowCamera] = useState(false)
  const [showCapture, setShowCapture] = useState(false)
  const handleCloseMap = () => setShowMap(false)
  const [capturedImage, setCapturedImage] = useState(null);
  const [type, setType] = useState('')
  const [employeeAttendanceId, setEmployeeAttendanceId] = useState(0);

  const [showPassword, setShowPassword] = useState(false)
  const [deviceInformation, setDeviceInformation] = useState({
    isMobile: 0,
    browserFingerprint: '',
    browser: '',
    device: '',
    height: '',
    width: ''
  })
  const [attendanceEnable, setAttendanceEnabled] = useState({attendanceLocation: "", attendanceCamera: ""})


  const handleChangeShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleShowMap = () => {
    setShowMap(true)

  }
  const handleSubmitMap = () => {
    setShowCapture(true)
    handleCloseMap()
    setState(true)
    setCapturedImage(null);
    setShowCamera(false);
  }
  const handleCloseCapture = () => setShowCapture(false)
  const handleStartCapture = () => {
    setShowCamera(true)
    setState(state)
  }

  const getDeviceName = () => {
    // Get the user agent string
    const userAgent = window.navigator.userAgent;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Define some common device keywords to check against
    const deviceKeywords = ['iPhone', 'iPad', 'Android', 'Windows Phone', 'Windows'];

    // Find the matching device keyword
    const matchingKeyword = deviceKeywords.find(keyword => userAgent.includes(keyword));

    // Set the device name based on the matching keyword
    if (matchingKeyword) {
      console.log(matchingKeyword);
    } else {
      console.log('Unknown Device');
    }

    console.log("os ", window.navigator.platform)

    // Create a new ClientJS object
    const client = new ClientJS();
    const fingerprint = client.getFingerprint();

    setDeviceInformation({device: matchingKeyword ?? "Mac",
      browserFingerprint: fingerprint.toString(),
      browser: browserName,
      isMobile: isMobile ? 1 : 0,
      height: viewportHeight.toString(),
      width: viewportWidth.toString()
    })
  }

  const webcamRef: any = useRef(null);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setState(!state)
    setSubmit(false)
  };

  const handleReCapture = () => {
    setCapturedImage(null);
    setState(!state)
    setSubmit(true)
  };

  const getLocation = (data: any) => {
    setLocation(data)
  }

  const onSubmit = async () => {
    handleShowMap()
  }

  const getAttendanceSetup = async () => {

    try {
      const res = await axios.get<IAPI_Response<{attendanceLocation: "", attendanceCamera: ""}>>(API_ENDPOINT.GET_ATTENDANCE_ENABLED)
      const result = await res

      if(result.data.results){
        setAttendanceEnabled(result.data.results)
      }

    } catch {
      console.log('There is something wrong')
    }
  }

  const getAttendanceTime = async (token : any, attendanceType : any) => {

    const response = await axios.get<IAPI_Response<IAttendanceList>>(API_ENDPOINT.GET_ATTENDANCE_TIME_FOR_JVD, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-with': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`
      },
      params: {
        type: attendanceType,
      },
    })
    const result = await response
    if (result?.data?.results) {
      setEmployeeAttendanceId(result?.data?.results.employee_attendance_id)
      const currentTime = moment(result?.data?.results.current_time).format('YYYY-MM-DD HH:mm:ss');
      const startAM = moment(moment(result?.data?.results.current_time).format('YYYY-MM-DD') + " 00:00:00").format("YYYY-MM-DD HH:mm:ss");
      const endAM = moment(moment(result?.data?.results.current_time).format('YYYY-MM-DD') + " 11:59:59").format("YYYY-MM-DD HH:mm:ss");
      const startPM = moment(moment(result?.data?.results.current_time).format('YYYY-MM-DD') + " 12:00:00").format("YYYY-MM-DD HH:mm:ss");
      const endPM = moment(moment(result?.data?.results.current_time).format('YYYY-MM-DD') + " 23:59:59").format("YYYY-MM-DD HH:mm:ss");

      if(attendanceType == 'clock'){
        if(result?.data?.results.out_time == null &&
            result?.data?.results.in_time == null &&
            moment(currentTime).isBetween(startAM, endAM)){
          setEmployeeAttendanceId(0)
          setClock({
            attendance_status: "pm_out",
            disabled: false
          })
        }else if(result?.data?.results.out_time == null &&
            result?.data?.results.in_time != null &&
            moment(currentTime).isBetween(startAM, endAM)){
          setClock({
            attendance_status: "am_in",
            disabled: false
          })
        }else if(result?.data?.results.out_time == null &&
            result?.data?.results.in_time != null &&
            moment(currentTime).isBetween(startPM, endPM)){
          setClock({
            attendance_status: "am_in",
            disabled: false
          })
        }else if(result?.data?.results.out_time != null &&
            result?.data?.results.in_time != null &&
            moment(currentTime).isBetween(startAM, endAM)){
          setClock({
            attendance_status: "am_out",
            disabled: true
          })
        }else if(result?.data?.results.out_time_pm == null &&
            result?.data?.results.in_time_pm == null &&
            moment(currentTime).isBetween(startPM, endPM)){
          setClock({
            attendance_status: "am_out",
            disabled: false
          })
        }else if(result?.data?.results.out_time_pm == null &&
            result?.data?.results.in_time_pm != null &&
            moment(currentTime).isBetween(startPM, endPM)){
          setClock({
            attendance_status: "pm_in",
            disabled: false
          })
        }else if(result?.data?.results.out_time_pm != null &&
            result?.data?.results.in_time_pm != null &&
            moment(currentTime).isBetween(startPM, endPM)){
          setClock({
            attendance_status: "pm_out",
            disabled: true
          })
        }else{
          setClock({
            attendance_status: "pm_out",
            disabled: false
          })
        }

      }

    }
  }

  const getLogin = async (value: any) => {
    if (value.username != '' && value.password != '') {
      await axios.get(API_ENDPOINT.LOGIN, {
        params: {
          username: value.username,
          password: value.password,
          browser_fingerprint: deviceInformation.browserFingerprint,
          device: deviceInformation.device,
          browser: deviceInformation.browser,
          isMobile: deviceInformation.isMobile,
          height: deviceInformation.height,
          width: deviceInformation.width
        },
      }).then(response => {
        if (response?.data?.status * 1 === 1) {
          getAttendanceTime(response.data.results.token, 'clock')
          getAttendanceTime(response.data.results.token, 'break')
          setToken(response.data.results.token)
        }
      }).catch(error => {
        setShow(true)
        setInvalidLogin(error.response.data.message)
        setClock({attendance_status: '', disabled: false})
        setToken('')
      })
  
      setTimeout(() => {
        setShow(false)
      }, 3000);
    }
  }

  const isValidBase64 = (str: string) => {
    const base64Regex = /^(data:image\/(png|jpeg|jpg|gif);base64,)?[A-Za-z0-9+/]+={0,2}$/;
    return base64Regex.test(str);
  }

  const postAttendanceTime = async (e:any) => {

    if(attendanceEnable.attendanceLocation == "1"){
      if(location.address == "" || !location){
        setError(ERROR_MESSAGE.required_address)
        setShowError(true)

        setTimeout(() => {
          setShowError(false)
        }, 3000);
        return;
      }
    }

    if(attendanceEnable.attendanceCamera == "1"){
      if(!capturedImage || !isValidBase64(capturedImage)){
        setError(ERROR_MESSAGE.required_image)
        setShowError(true)

        setTimeout(() => {
          setShowError(false)
        }, 3000);
        return;
      }
    }
    e.currentTarget.disabled = true;
    let attendanceType = 'time_in';
    // if (clock.attendance_status == null) {
    //   type = 'time_in';
    // } else if (clock.attendance_status == 'in') {
    //   type = 'time_out';
    // } else if (clock.attendance_status == 'out') {
    //   type = 'time_in';
    // }

    if (type == null || type == "") {
      attendanceType = 'am_time_in';
    } else if (type == 'am_in') {
      attendanceType = 'am_time_in';
    } else if (type == 'am_out') {
      attendanceType = 'am_time_out';
    } else if (type == 'pm_in') {
      attendanceType = 'pm_time_in';
    } else if (type == 'pm_out') {
      attendanceType = 'pm_time_out';
    }

    await axios.post(API_ENDPOINT.POST_ATTENDANCE_TIME_FOR_JVD, {
      latitude: location.latitude,
      longitude: location.longitude,
      address: location.address == "" ? "Cannot be found." : location.address,
      type: attendanceType,
      image: capturedImage,
      employee_attendance_id: employeeAttendanceId,
      browser_fingerprint: deviceInformation.browserFingerprint,
      device: deviceInformation.device,
      browser: deviceInformation.browser,
      isMobile: deviceInformation.isMobile,
      height: deviceInformation.height,
      width: deviceInformation.width,
      fromKiosk: 1
    }, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-with': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          if (clock.attendance_status == null) {
            setSuccess(ATTENDANCE.TIMEIN)
          } else if (clock.attendance_status == 'pm_in' || clock.attendance_status == 'am_in') {
            setSuccess(ATTENDANCE.TIMEOUT)
          } else if (clock.attendance_status == 'pm_out' || clock.attendance_status == 'pm_out') {
            setSuccess(ATTENDANCE.TIMEIN)
          }
       }
    }).catch((err) => {

      setShowError(true)
      if(err.response.data.message == "device_not_allowed"){
        setError(ERROR_MESSAGE.device_not_allowed)
      }else if(err.response.data.message == "device_still_pending"){
        setError(ERROR_MESSAGE.device_still_pending)
      }else{
        setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }

    })

    handleCloseCapture();
    setClock({attendance_status: '', disabled: false})
    setToken('')

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const handleClock = (type: any) => {
    setType(type);
    handleShowMap()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayTime(moment().format('LTS'))
    }, 1000)
    return () => clearInterval(interval)
  })


  useEffect(() => {
    getDeviceName()
    getAttendanceSetup()
  }, [])


  // eslint-disable-next-line no-constant-condition
  const formJSX =  (
    <Formik
      initialValues={
        { 
          username: '',
          password: '' 
        }
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group controlId="validationFormikUsername">
              <InputGroup hasValidation>
                <InputGroup.Text id='basic-addon1' className='bg-white'>
                  <User/>
                  &nbsp;
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  aria-describedby="inputGroupPrepend"
                  name="username"
                  value={values.username}
                  onChange={e => {
                    handleChange(e)
                    getLogin({...values, username: e.target.value})
                  }}
                  onBlur={e => {
                    // call the built-in handleBur
                    handleBlur(e)
                    getLogin(values)
                  }}
                  className='form_inputs'
                  isInvalid={touched.username && !!errors.username}
                  style={{textTransform: 'lowercase'}}
                />
                <Form.Control.Feedback>
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="validationFormikPassword">
              <InputGroup hasValidation className='mb-3'>
                <InputGroup.Text id='basic-addon1' className='bg-white'>
                  <PasswordIcon/>
                </InputGroup.Text>
                <Form.Control
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder='Password'
                  aria-label='password'
                  aria-describedby="inputGroupPrepend"
                  value={values.password}
                  onChange={e => {
                    handleChange(e)
                    values.password =  e.target.value
                    getLogin({...values, password: e.target.value})
                  }}
                  onBlur={e => {
                    // call the built-in handleBur
                    handleBlur(e)
                    getLogin(values)
                  }}
                  className='form_inputs'
                  isInvalid={touched.password && !!errors.password}
                  style={{borderRight: 'none'}}
                  />
                <InputGroup.Text
                    id='basic-addon1'
                    className='bg-white'
                    style={{cursor: 'pointer'}}
                    onClick={handleChangeShowPassword}
                    title={showPassword ? "Hide password" : "Show password"}
                >
                  {showPassword ? <PasswordShow/> : <Password/>}
                </InputGroup.Text>
                  <Form.Control.Feedback>
                  </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <p className="login-error" style={{position: "fixed"}}>
            {
              ((touched.username && !!errors.username) || (touched.password && !!errors.password)) ?
                  LOGIN.error
                  : (
                      !show? "" :
                          invalidLogin
                  )
            }
          </p>
          <div className='d-grid gap-2 pt-2'>
              {(() => {
                if (clock.attendance_status == null) {
                  return (
                    <Button 
                      type="submit"
                      className='btn text-white'
                      variant='warning'
                      onClick={() => setType("am_in")}
                      disabled={clock.disabled}
                      >
                      Clock In AM
                    </Button>
                  )
                } else if(clock.attendance_status == 'am_in') {
                  return (
                    <Button 
                      type="submit"
                      className='btn text-white'
                      variant='danger'
                      onClick={() => setType("am_out")}
                      disabled={clock.disabled}
                      >
                      Clock Out AM
                    </Button>
                  )
                } else if(clock.attendance_status == 'am_out') {
                  return (
                    <Button 
                      type="submit"
                      className='btn text-white'
                      variant='warning'
                      onClick={() => setType("pm_in")}
                      disabled={clock.disabled}
                      >
                      Clock In PM
                    </Button>
                  )
                }else if(clock.attendance_status == 'pm_in') {
                  return (
                      <Button
                          type="submit"
                          className='btn text-white'
                          variant='danger'
                          onClick={() => setType("pm_out")}
                          disabled={clock.disabled}
                      >
                        Clock Out PM
                      </Button>
                  )
                }else if(clock.attendance_status == 'pm_out') {
                  return (
                      <Button
                          type="submit"
                          className='btn text-white'
                          variant='warning'
                          onClick={() => setType("am_in")}
                          disabled={clock.disabled}
                      >
                        Clock In AM
                      </Button>
                  )
                }
                return (
                  <Button 
                      type="submit"
                      disabled={clock.attendance_status == ''} 
                      className='btn btn-dark text-white custom-orange'
                      >
                      Clock In/Out AM
                    </Button>
                )
              })()}
          </div>

          {/*<div className='d-grid gap-2' style={{paddingTop: "10px"}}>*/}

          {/*          <Button*/}
          {/*              onClick={() => handleClock('break_in')}*/}
          {/*              className='btn text-white'*/}
          {/*              variant='warning'*/}
          {/*          >*/}
          {/*            Fingerprint*/}
          {/*          </Button>*/}

          {/*</div>*/}
        </Form>
    )}
    </Formik>
  )

  return (
    <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
      <div className='container'>
        <div className='row align-items-center p-0'>
          <div className='col-md-3' />
          <div className=' col-md-6 login-body'>
            <div id='login_container' className='row border-0  rounded-4 shadow-lg'>
              <div className='col-md-5  p-5' id='left-side'>
                <div className='text-center mt-5'>
                  <Image rounded className='w-100 mb-4 logo'  src='/Octaneware1.png' alt='logo'/>
                </div>
                <div className='form-group text-center mb-4'>
                  <div className='col-xs-12'>
                    <h6 className='fw-bold' id=''>
                      {displayTime}
                    </h6>
                    <h6 className='fw-bold mt-4' id=''>
                      {moment().format('dddd')}
                    </h6>
                    <h6 className='fw-bold' id=''>
                      {moment().format('LL')}
                    </h6>
                  </div>
                </div>
                <br />
              </div>
              <div className='col-md-7 p-4 bg-primary text-center' id='gradient_div'>
                <h4 className=' text-white mt-3' id=''>
                  Kiosk
                </h4>
                <h6 id='sign_in_label' className=' text-white'>
                  Sign in to your account
                </h6>
                <br />
                <br />
                {formJSX}
              </div>
            </div>
          </div>
          <div className='col-md-3' />
        </div>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        {/* MAP MODAL*/}
        <Modal className='modal' size="lg" centered show={showMap} onHide={handleCloseMap}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>CLOCK IN/OUT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <MapContainer
                style={{height: '400px'}}
                center={{ lat: 0, lng: 0 }}
                zoom={13}
                scrollWheelZoom={false}
                attributionControl={false}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

                />
                <LocationMarker func={getLocation}/>
              </MapContainer>
              <div className="text-center">
                Your current location : <b>{location.address}</b>
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseMap}>
              Cancel
            </Button>
            <Button 
              onClick={handleSubmitMap}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
              >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* CAPTURE MODAL*/}
        <Modal className='modal' size="lg" centered show={showCapture} onHide={handleCloseCapture}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold ms-auto'>Please take a photo of yourself for your attendance.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              !showCamera ? (
                <Row style={{margin: "10px"}}>
                  <div className="text-center"> 
                    <p className="capture-icon">
                      <PersonIcon/>
                    </p>
                    <Button
                      onClick={handleStartCapture}
                      variant='primary text-white'
                      size={'sm'}
                      className='mx-2'
                      >
                      <FontAwesomeIcon icon={faCameraRetro} />
                      &nbsp;Start Camera
                    </Button>
                  </div>
                </Row>
              ) : (
                <Row style={{margin: "10px"}}>
                  <div className="text-center"> 
                      <p>
                        {
                          capturedImage ? (
                            <img className="capture" src={capturedImage} alt="Captured Image" width="400" height="300"/>
                          ) : (
                            <Webcam
                              className="capture"
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              videoConstraints={videoConstraints}
                              width="400" 
                              height="300"
                            />
                          )
                        }
                      </p>
                      
                      <Button
                          onClick={state ? handleCapture : handleReCapture }
                          variant='primary text-white'
                          size={'sm'}
                          className='mx-2'
                          >
                          <FontAwesomeIcon icon={faCameraRetro} />
                          &nbsp;{state ? 'Capture' : 'Recapture'}
                      </Button>
                  </div>
              </Row>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCapture}>
              Cancel
            </Button>
            <Button
              disabled={showSubmit}
              onClick={postAttendanceTime}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
              >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  )
}

export default Index
