import React, { useEffect, useState, useRef } from 'react'
import "./style.css"
import {IAttendanceList} from "./types"
import {Button, Col, Row, Table} from "react-bootstrap";
import {TEXT} from "../../../utils/globalConstant";

// eslint-disable-next-line react/display-name
const PrintAttendancePayrollComponent = React.forwardRef((props: {attendance?: IAttendanceList[], employeeName: string, attendanceRange: string}, ref:any) => {
    const attendance = props.attendance
    const employeeName = props.employeeName
    const attendanceRange = props.attendanceRange

    return (
        <div ref={ref} className="print-element">
            <br/>
            <Row>
                <Col sm={5}><b>Employee Name:</b> &nbsp; {employeeName}</Col>
                <Col sm={7}><b>Attendance Range:</b> &nbsp; {attendanceRange}</Col>
            </Row>
            <br/>
            <Table responsive size='sm' className='bordered-tabs-top mt-3'>
                <thead>
                <tr>
                    <th style={{ width: '70px' }}>Date</th>
                    <th>Day</th>
                    <th style={{ width: '60px' }} className='text-center'>
                        Status
                    </th>
                    <th className='text-center'>Clock In AM</th>
                    <th className='text-center'>Clock Out AM</th>
                    <th className='text-center'>Clock In PM</th>
                    <th className='text-center'>Clock Out PM</th>
                    <th className='text-center'>Worked Time</th>
                    <th className='text-center'>Late</th>
                    <th className='text-center'>OT In</th>
                    <th className='text-center'>OT Out</th>
                    <th className='text-center'>Total OT</th>
                    <th className='text-center'>Worksite</th>
                </tr>
                </thead>
                <tbody>
                <>
                    {attendance ?
                        attendance?.length > 0 ? (
                            attendance?.map((byUser, index) =>
                                <tr className='mb-2' key={index}>
                                    <td className="table-row-middle-align">{byUser.date}</td>
                                    <td className="table-row-middle-align">{byUser.day}</td>
                                    <td className='text-center table-row-middle-align'>
                                        <div className='d-grid'>
                                            {(() => {
                                                if (byUser.status == 'Leave') {
                                                    return (
                                                        <Button
                                                            className=" p-1 pt-0 pb-0"
                                                            style={{fontSize: '10px', color: 'white'}}
                                                            variant="info"
                                                            size="sm"
                                                        >
                                                            Leave
                                                        </Button>
                                                    )
                                                } else if(byUser.status == 'Present') {
                                                    return (
                                                        <Button
                                                            className=" p-1 pt-0 pb-0"
                                                            style={{fontSize: '10px', color: 'white'}}
                                                            variant="success"
                                                            size="sm"
                                                        >
                                                            Present
                                                        </Button>
                                                    )
                                                } else if(byUser.status == 'Rest') {
                                                    return (
                                                        <Button
                                                            className=" p-1 pt-0 pb-0"
                                                            style={{fontSize: '10px', color: 'white'}}
                                                            variant="secondary"
                                                            size="sm"
                                                        >
                                                            Rest Day
                                                        </Button>
                                                    )
                                                }

                                                return (
                                                    <Button
                                                        className=" p-1 pt-0 pb-0"
                                                        style={{fontSize: '10px', color: 'white'}}
                                                        variant='danger'
                                                        size="sm"
                                                    >
                                                        Absent
                                                    </Button>
                                                )
                                            })()}
                                        </div>
                                    </td>
                                    <td className="text-center table-row-middle-align">{byUser.in_time != null ? byUser.in_time : '-'}</td>
                                    <td className="text-center table-row-middle-align">{byUser.out_time != null ? byUser.out_time : '-'}</td>
                                    <td className="text-center table-row-middle-align">{byUser.in_time_pm != null ? byUser.in_time_pm : '-'}</td>
                                    <td className="text-center table-row-middle-align">{byUser.out_time_pm != null ? byUser.out_time_pm : '-'}</td>

                                    {(() => {
                                        if(byUser.status == 'Rest') {
                                            return (<td className="text-center table-row-middle-align">-</td>)
                                        }else{
                                            return (<td className="text-center table-row-middle-align">{byUser.total_worked_time != null ? byUser.total_worked_time : '-'}</td>)
                                        }
                                    })()}

                                    <td className="text-center table-row-middle-align">{byUser.total_late}</td>
                                    <td className="text-center table-row-middle-align">
                                        {
                                            byUser?.attendance_overtime?.length > 0 ?
                                                byUser?.attendance_overtime.map((overtime, index) => (
                                                    <>{index > 0 ? <br/>: ""} {overtime.overtime_in}</>
                                                )) :
                                                '-'
                                        }
                                    </td>
                                    <td className="text-center table-row-middle-align">
                                        {
                                            byUser?.attendance_overtime?.length > 0 ?
                                                byUser?.attendance_overtime.map((overtime, index) => (
                                                    <>{index > 0 ? <br/>: ""} {overtime.overtime_out}</>
                                                )) :
                                                '-'
                                        }
                                    </td>
                                    <td className="text-center table-row-middle-align">{byUser.total_attendance_overtime != null ? byUser.total_attendance_overtime : '-'}</td>
                                    <td className="text-center table-row-middle-align">
                                        {byUser.worksite}
                                    </td>
                                </tr>
                            )
                        ) : (
                            <tr className='no-item'>
                                <td colSpan={10}>{TEXT.NOT_FOUND}</td>
                            </tr>
                        )
                        : (
                            <tr className='no-item'>
                                <td colSpan={10}>{TEXT.NOT_FOUND}</td>
                            </tr>
                        )
                    }
                </>
                </tbody>
            </Table>
        </div>
        )

})

export default PrintAttendancePayrollComponent;