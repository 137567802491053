import React from 'react'

const AttendanceLeaveIcon = () => {
  return (
    <svg
      id='Attendance-Leave-Icon'
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 18 18'
      >
      <defs>
        <clipPath id='clip-path'>
          <rect
            id='Rectangle_96'
            data-name='Rectangle 96'
            width='15'
            height='15'
            fill='#27699c'
          />
        </clipPath>
      </defs>
      <g id='Group_413' data-name='Group 413' clipPath='url(#clip-path)'>
        <path
          id='Path_77'
          data-name='Path 77'
          d='M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0'
          fill='#27699c'
        />
      </g>
    </svg>
  )
}

export default AttendanceLeaveIcon
