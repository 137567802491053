import React, {useEffect, useState} from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {
  Breadcrumb,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Button,
  Stack, Modal,
} from 'react-bootstrap'
import Pagination from 'components/Pagination'
import Write from 'asset/SvgComponent/Write'
import { Box, Paper } from '@mui/material'
import { AddCircleOutlineOutlined } from '@mui/icons-material'
import './style.css'
import axios from "../../../api";
import {IAPI_Response} from "../../../api/types";
import {
  IPagibig,
  IPagibigList,
  IPhilhealth,
  IWithholdingTaxSetup,
  IWithholdingTaxSetupList,
  IPhilhealthList,
  ISSSList, ISSS
} from "./types";
import {API_ENDPOINT, ERROR_MESSAGE, SUCCESS_MESSAGE} from "../../../utils/globalConstant";

export default function Index() {
  const [key, setKey] = useState('sss-table')
  const [show, setShow] = React.useState(false)

  const [fileName, setFileName] = React.useState('Upload Boundary File')

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [showEdit, setShowEdit] = React.useState(false)

  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)
  const [success, setSuccess] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const [error, setError] = useState("")
  const [showError, setShowError] = useState(false)

  /*Withholding tax*/
  const [data, setData] = useState<IWithholdingTaxSetup[]>([])
  const [item, setItem] = useState<IWithholdingTaxSetup>({
    id: 0,
    payroll_cutoff_type: 0,
    amount_from: "",
    amount_to: "",
    base_amount_deduction: "",
    percentage_over: "",
    amount_over: "",
  })
  const [currentPageWithholding, setCurrentPageWithholding] = React.useState(0)
  const [lastPageWithholding, setLastPageWithholding] = React.useState(0)
  const [itemsPerPageWithholding, setPerPageWithholding] = React.useState(10)
  const [itemsCountWithholding, setItemCountWithholding] = React.useState(0)
  const [fromWithholding, setFromWithholding] = React.useState(0)
  const [showUpdate, setShowUpdate] = useState(false)
  const handleCloseUpdate = () => setShowUpdate(false)
  const handleOpenUpdate = async() => {
    setShowUpdate(true)
  }

  /*Pagibig*/
  const [pagibigData, setPagibigData] = useState<IPagibig[]>([])
  const [pagibigItem, setPagibigItem] = useState<IPagibig>({
    id: 0,
    minimum: "",
    maximum: "",
    employee_share: "",
    employer_share: "",
    employee_maximum_contribution: "",
    employer_maximum_contribution: ""
  })
  const [currentPagePagibig, setCurrentPagePagibig] = React.useState(0)
  const [lastPagePagibig, setLastPagePagibig] = React.useState(0)
  const [itemsPerPagePagibig, setPerPagePagibig] = React.useState(10)
  const [itemsCountPagibig, setItemCountPagibig] = React.useState(0)
  const [fromPagibig, setFromPagibig] = React.useState(0)

  /*PhilHealth*/
  const [philhealthData, setPhilhealthData] = useState<IPhilhealth[]>([])
  const [philhealthItem, setPhilhealthItem] = useState<IPhilhealth>({
    id: 0,
    minimum: "",
    maximum: "",
    premium_rate: "",
    monthly_premium: "",
    is_fixed: 0
  })
  const [currentPagePhilhealth, setCurrentPagePhilhealth] = React.useState(0)
  const [lastPagePhilhealth, setLastPagePhilhealth] = React.useState(0)
  const [itemsPerPagePhilhealth, setPerPagePhilhealth] = React.useState(10)
  const [itemsCountPhilhealth, setItemCountPhilhealth] = React.useState(0)
  const [fromPhilhealth, setFromPhilhealth] = React.useState(0)

  /*SSS*/
  const [sssData, setSSSData] = useState<ISSS[]>([])
  const [sssItem, setSSSItem] = useState<ISSS>({
    id: 0,
    minimum: "",
    maximum: "",
    monthly_salary_credit_employees_compensation: "",
    monthly_salary_credit_wisp: "",
    monthly_salary_credit_total: "",
    regular_ss_employer_contribution: "",
    regular_ss_employee_contribution: "",
    regular_ss_total: "",
    employees_compensation_employer_contribution: "",
    employees_compensation_employee_contribution: "",
    employees_compensation_total: "",
    wisp_employer_contribution: "",
    wisp_employee_contribution: "",
    wisp_total: "",
    total_employer_contribution: "",
    total_employee_contribution: "",
    total_contribution: ""
  })
  const [currentPageSSS, setCurrentPageSSS] = React.useState(0)
  const [lastPageSSS, setLastPageSSS] = React.useState(0)
  const [itemsPerPageSSS, setPerPageSSS] = React.useState(10)
  const [itemsCountSSS, setItemCountSSS] = React.useState(0)
  const [fromSSS, setFromSSS] = React.useState(0)

  const handleChangeWithholding = (e: any, type: string) => {
    if(type == "payroll_cutoff_type"){
      setItem({...item, payroll_cutoff_type: e.target.value})
    }else if(type == "amount_from"){
      setItem({...item, amount_from: e.target.value})
    }else if(type == "amount_to"){
      setItem({...item, amount_to: e.target.value})
    }else if(type == "base_amount_deduction"){
      setItem({...item, base_amount_deduction: e.target.value})
    }else if(type == "percentage_over"){
      setItem({...item, percentage_over: e.target.value})
    }else if(type == "amount_over"){
      setItem({...item, amount_over: e.target.value})
    }


  }

  const handleUpdate = async() => {
    await axios.put(API_ENDPOINT.UPDATE_WITHHOLDING_TAX_SETUP +'/'+ item.id, item).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.BRANCH.UPDATED)
        getWithholdingTaxSetup(null);
      }
      handleCloseUpdate();
    }).catch(error => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const showWithholdingTaxSetup = async(id: number) => {
    try {
      const response = await axios.get<IAPI_Response<IWithholdingTaxSetup>>(API_ENDPOINT.GET_WITHHOLDING_TAX_SETUP_BY_ID+"/"+id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
        handleOpenUpdate()
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
  }

  const getWithholdingTaxSetup = async(page_number: number | null) => {
    try {
      const response = await axios.get<IAPI_Response<IWithholdingTaxSetupList>>(API_ENDPOINT.GET_WITHHOLDING_TAX_SETUP, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data.length) {
        setData(result?.data?.results?.data)
        setCurrentPageWithholding(result?.data?.results?.current_page)
        setLastPageWithholding(result?.data?.results?.last_page)
        setPerPageWithholding(result?.data?.results?.per_page)
        setItemCountWithholding(result?.data?.results?.total)
        setFromWithholding(result?.data?.results?.from)
      }else{
        setData([])
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getPagibig = async(page_number: number | null) => {
    try {
      const response = await axios.get<IAPI_Response<IPagibigList>>(API_ENDPOINT.GET_PAGIBIG, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data.length) {
        setPagibigData(result?.data?.results?.data)
        setCurrentPagePagibig(result?.data?.results?.current_page)
        setLastPagePagibig(result?.data?.results?.last_page)
        setPerPagePagibig(result?.data?.results?.per_page)
        setItemCountPagibig(result?.data?.results?.total)
        setFromPagibig(result?.data?.results?.from)
      }else{
        setPagibigData([])
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getPhilhealth = async(page_number: number | null) => {
    try {
      const response = await axios.get<IAPI_Response<IPhilhealthList>>(API_ENDPOINT.GET_PHILHEALTH, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data.length) {
        setPhilhealthData(result?.data?.results?.data)
        setCurrentPagePhilhealth(result?.data?.results?.current_page)
        setLastPagePhilhealth(result?.data?.results?.last_page)
        setPerPagePhilhealth(result?.data?.results?.per_page)
        setItemCountPhilhealth(result?.data?.results?.total)
        setFromPhilhealth(result?.data?.results?.from)
      }else{
        setPhilhealthData([])
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getSSS = async(page_number: number | null) => {
    try {
      const response = await axios.get<IAPI_Response<ISSSList>>(API_ENDPOINT.GET_SSS, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data.length) {
        setSSSData(result?.data?.results?.data)
        setCurrentPageSSS(result?.data?.results?.current_page)
        setLastPageSSS(result?.data?.results?.last_page)
        setPerPageSSS(result?.data?.results?.per_page)
        setItemCountSSS(result?.data?.results?.total)
        setFromSSS(result?.data?.results?.from)
      }else{
        setSSSData([])
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getWithholdingTaxSetup(null)
      getPagibig(null)
      getPhilhealth(null)
      getSSS(null)
    })()
  }, [])

  return (
    <Container fluid>
      <Row>
        <Col sm={6}>
          <h5 className='fw-bold'>Government Deductions</h5>
          <Breadcrumb className='breadcrumbs'>
            <Breadcrumb.Item className=' text-decoration-none' href='#'>
              Setup
            </Breadcrumb.Item>
            <Breadcrumb.Item className=' text-decoration-none' href='#'>
              Payroll
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Government Deductions</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col sm={6}>
          {/* <Stack className="justify-content-end" direction="row" spacing={1}>*/}
          {/*    /!*<Button variant="outlined" sx={{textTransform: 'none'}} color="inherit">*!/*/}
          {/*    /!*    <AddCircleOutlineOutlined className="mx-1"/> Add new employee*!/*/}
          {/*    /!*</Button>*!/*/}
          {/* </Stack>*/}
        </Col>
      </Row>
      <br />
      <Tabs
        variant={'tabs'}
        className='tabs '
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k: any) => {
          setKey(k)
        }}
      >
        <Tab eventKey='sss-table' title='SSS'>
          <Card className='border-0  p-0 bordered-card-tabs'>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th className='border'></th>
                    <th colSpan={3} className='border'>Monthly Salary Credit</th>
                    <th colSpan={12} className='border'>Amount of Contributions</th>
                    <th className='border'></th>
                  </tr>
                  <tr className='text-center'>
                    <th className='border'></th>
                    <th className='border'>Regular SS</th>
                    <th className='border' colSpan={2}></th>
                    <th className='border' colSpan={3}>Regular SS</th>
                    <th className='border' colSpan={3}>EC</th>
                    <th className='border' colSpan={3}>WISP</th>
                    <th className='border' colSpan={3}>Total</th>
                    <th className='border'></th>
                  </tr>
                  <tr className='text-center'>
                    <th className='border'>Range of Compensation</th>
                    <th className='border'>EC</th>
                    <th className='border'>WISP</th>
                    <th className='border'>Total</th>
                    <th className='border'>ER</th>
                    <th className='border'>EE</th>
                    <th className='border'>Total</th>
                    <th className='border'>ER</th>
                    <th className='border'>EE</th>
                    <th className='border'>Total</th>
                    <th className='border'>ER</th>
                    <th className='border'>EE</th>
                    <th className='border'>Total</th>
                    <th className='border'>ER</th>
                    <th className='border'>EE</th>
                    <th className='border'>Total</th>
                    <th className='border' style={{width: '3%'}}>Action</th>
                  </tr>
                </thead>
                <tbody >
                  {
                    sssData.length > 0 ?
                        sssData.map((sss, index) => (
                          <tr key={index} className='text-center' style={{fontSize: '90%'}}>
                            <td>
                              {(() => {
                                if(sss.minimum == "0.00"){
                                  return "₱ " + Number(sss.maximum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " and below"
                                }else if(sss.maximum == "0.00"){
                                  return "₱ " + Number(sss.minimum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " and above"
                                }else{
                                  return "₱ " + Number(sss.minimum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " - ₱ " + Number(sss.maximum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>{Number(sss.monthly_salary_credit_employees_compensation).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            <td>
                              {(() => {
                                if(sss.monthly_salary_credit_wisp == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.monthly_salary_credit_wisp).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.monthly_salary_credit_total == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.monthly_salary_credit_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.regular_ss_employer_contribution == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.regular_ss_employer_contribution).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.regular_ss_employee_contribution == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.regular_ss_employee_contribution).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.regular_ss_total == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.regular_ss_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.employees_compensation_employer_contribution == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.employees_compensation_employer_contribution).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.employees_compensation_employee_contribution == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.employees_compensation_employee_contribution).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.employees_compensation_total == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.employees_compensation_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.wisp_employer_contribution == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.wisp_employer_contribution).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.wisp_employee_contribution == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.wisp_employee_contribution).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.wisp_total == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.wisp_total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.total_employer_contribution == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.total_employer_contribution).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.total_employee_contribution == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.total_employee_contribution).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              {(() => {
                                if(sss.total_contribution == "0.00"){
                                  return "-"
                                }else{
                                  return "₱ " + Number(sss.total_contribution).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td>
                              <Button variant=' btn-transparent' size={'sm'} className=' add-new-btn rounded mx-2 text-white'>
                                <Write/>
                              </Button>
                            </td>
                          </tr>
                        ))
                        : <tr>
                      <td colSpan={17}>No Data Available.</td>
                        </tr>
                  }
                </tbody>
              </Table>
              <Pagination
                  itemsCount={itemsCountSSS}
                  itemsPerPage={itemsPerPageSSS}
                  currentPage={currentPageSSS}
                  lastPage={lastPageSSS}
                  setCurrentPage={setCurrentPageSSS}
                  alwaysShown={false}
                  pageClicked={(page: number) => {
                    getSSS(page);
                  }
                  }
              />
            </Card.Body>
          </Card>
        </Tab>
        <Tab eventKey='pagibig-table' title='Pagibig '>
          <Card className='border-0  p-0 bordered-card-tabs'>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th className="text-center">Fund Salary</th>
                    <th className="text-center">Employee Share</th>
                    <th className="text-center">Employeer Share</th>
                    <th className="text-center">Employee Maximum Contribution</th>
                    <th className="text-center">Employer Maximum Contribution</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    pagibigData.length > 0 ?
                        pagibigData.map((pagibig, index) => (
                            <tr key={index}>
                              <td className='text-center'>
                                {(() => {
                                  if(pagibig.minimum == "0.00"){
                                    return "₱ " + Number(pagibig.maximum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " and below"
                                  }else if(pagibig.maximum == "0.00"){
                                    return "₱ " + Number(pagibig.minimum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " and above"
                                  }else{
                                    return "₱ " + Number(pagibig.minimum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " - ₱ " + Number(pagibig.maximum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                })()}
                              </td>
                              <td className='text-center'>
                                {
                                  Number(pagibig.employee_share).toFixed(2)  + " %"
                                }
                              </td>
                              <td className='text-center'>
                                {
                                    Number(pagibig.employer_share).toFixed(2) + " %"
                                }
                              </td>
                              <td className='text-center'>
                                {
                                    "₱ " + Number(pagibig.employee_maximum_contribution).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              </td>
                              <td className='text-center'>
                                {
                                  "₱ " + Number(pagibig.employer_maximum_contribution).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              </td>
                              <td className='text-center'>
                                <Button variant=' btn-transparent' size={'sm'} className=' add-new-btn rounded mx-2 text-white'>
                                  <Write/>
                                </Button>
                              </td>
                            </tr>
                        ))

                        : <tr>
                        <td colSpan={6}>No Data Available.</td>
                        </tr>
                  }
                </tbody>
              </Table>
              <Pagination
                  itemsCount={itemsCountPagibig}
                  itemsPerPage={itemsPerPagePagibig}
                  currentPage={currentPagePagibig}
                  lastPage={lastPagePagibig}
                  setCurrentPage={setCurrentPagePagibig}
                  alwaysShown={false}
                  pageClicked={(page: number) => {
                    getPagibig(page);
                  }
                  }
              />
            </Card.Body>
          </Card>
        </Tab>
        <Tab eventKey='philhealth-table' title='PhilHealth '>
          <Card className='border-0  p-0 bordered-card-tabs'>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th className='text-center'>Monthly Basic Salary</th>
                    <th className='text-center'>Premium Rate</th>
                    <th className='text-center'>Monthly Rate</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    philhealthData.length > 0 ?
                        philhealthData.map((philhealth, index) => (
                            <tr key={index}>
                              <td className='text-center'>
                                {(() => {
                                  if(philhealth.minimum == "0.00"){
                                    return "₱ " + Number(philhealth.maximum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " and below"
                                  }else if(philhealth.maximum == "0.00"){
                                    return "₱ " + Number(philhealth.minimum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " and above"
                                  }else{
                                    return "₱ " + Number(philhealth.minimum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " to ₱ " + Number(philhealth.maximum).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                })()}
                              </td>
                              <td className='text-center'>
                                {
                                  Number(philhealth.premium_rate).toFixed(2) + " %"
                                }
                              </td>
                              <td className='text-center'>
                                {(() => {
                                  if(philhealth.is_fixed == 1 ){
                                    return "₱ " + Number(philhealth.monthly_premium).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }else{
                                    return "₱ " + (Number(philhealth.minimum) * (Number(philhealth.premium_rate) / 100)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " to ₱ " + (Number(philhealth.maximum) * (Number(philhealth.premium_rate)  / 100)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                })()}
                              </td>
                              <td className='text-center'>
                                <Button variant=' btn-transparent' size={'sm'} className=' add-new-btn rounded mx-2 text-white'>
                                  <Write/>
                                </Button>
                              </td>
                            </tr>
                        ))
                        : <tr>
                      <td colSpan={4}></td>
                        </tr>
                  }
                </tbody>
              </Table>
              <Pagination
                  itemsCount={itemsCountPhilhealth}
                  itemsPerPage={itemsPerPagePhilhealth}
                  currentPage={currentPagePhilhealth}
                  lastPage={lastPagePhilhealth}
                  setCurrentPage={setCurrentPagePhilhealth}
                  alwaysShown={false}
                  pageClicked={(page: number) => {
                    getPhilhealth(page);
                  }
                  }
              />
            </Card.Body>
          </Card>
        </Tab>
        <Tab eventKey='withholding-tax-table' title='Withholding Tax '>
          <Card className='border-0  p-0 bordered-card-tabs'>
            <Card.Body>
              <Table className={'mt-2'}>
                <thead>
                <tr>
                  <th className='text-center'>Payroll Cutoff Type</th>
                  <th className='text-center'>Compensation Range</th>
                  <th className='text-center'>Prescribed Withholding Tax</th>
                  <th className='text-center'>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                  data.length > 0 ?
                      data.map((tax, index) => (
                          <tr key={index}>
                            <td className={'text-center'}>
                              {(() => {
                                if(tax.payroll_cutoff_type == 3){
                                  return "Semi-monthly"
                                }else if(tax.payroll_cutoff_type == 4){
                                  return "Monthly"
                                }
                              })()}
                            </td>
                            <td className={'text-center'}>
                              {(() => {
                                if(tax.amount_from == "0.00"){
                                  return "₱ " + Number(tax.amount_to).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " and below"
                                }else if(tax.amount_to == "0.00"){
                                  return "₱ " + Number(tax.amount_from).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " and above"
                                }else{
                                  return "₱ " + Number(tax.amount_from).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " - ₱ " + Number(tax.amount_to).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td className={'text-center'}>
                              {(() => {
                                if(tax.percentage_over == "0.00" && tax.amount_over == "0.00"){
                                  return "₱ 0.00"
                                }else{
                                  return "₱ " + Number(tax.base_amount_deduction).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " + " + Number(tax.percentage_over).toFixed(0)+"% over " + "₱ " + Number(tax.amount_over).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              })()}
                            </td>
                            <td className='text-center'>
                              <Button variant=' btn-transparent' size={'sm'} className=' add-new-btn rounded mx-2 text-white' onClick={() => showWithholdingTaxSetup(tax.id)}>
                                <Write/>
                              </Button>
                            </td>
                          </tr>
                      ))
                      : ""
                }


                </tbody>
              </Table>
              <Pagination
                  itemsCount={itemsCountWithholding}
                  itemsPerPage={itemsPerPageWithholding}
                  currentPage={currentPageWithholding}
                  lastPage={lastPageWithholding}
                  setCurrentPage={setCurrentPageWithholding}
                  alwaysShown={false}
                  pageClicked={(page: number) => {
                    getWithholdingTaxSetup(page);
                  }
                  }
              />
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>
      <br />

      {/* UPDATE WITHHOLDING MODAL*/}
      <Modal className='modal' centered show={showUpdate} onHide={handleCloseUpdate}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>Edit Withholding Tax Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Payroll Cutoff Type</Form.Label>
                <Form.Select
                    size={'sm'}
                    name="payroll_cutoff_type"
                    onChange={event => {
                      handleChangeWithholding(event, "payroll_cutoff_type");
                    }}
                    value={item.payroll_cutoff_type}
                >
                  <option value="0" disabled>Select Cutoff Type</option>
                  <option value="1">Daily</option>
                  <option value="2">Weekly</option>
                  <option value="3">Semi-Monthly</option>
                  <option value="4">Monthly</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Base Amount Deduction</Form.Label>
                <Form.Control
                    className={"text-right"}
                    size={'sm'}
                    type='text'
                    placeholder='0'
                    name={'base_amount_deduction'}
                    value={item.base_amount_deduction}
                    onChange={event => {
                      handleChangeWithholding(event, "base_amount_deduction");
                    }}

                />
              </Form.Group>
            </Row>
            <Row className={"mt-4"}>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Range From</Form.Label>
                <Form.Control
                    className={"text-right"}
                    size={'sm'}
                    type='text'
                    placeholder='0'
                    name={'amount_from'}
                    value={item.amount_from}
                    onChange={event => {
                      handleChangeWithholding(event, "amount_from");
                    }}

                />
              </Form.Group>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Range To</Form.Label>
                <Form.Control
                    className={"text-right"}
                    size={'sm'}
                    type='text'
                    placeholder='0'
                    name={'amount_to'}
                    value={item.amount_to}
                    onChange={event => {
                      handleChangeWithholding(event, "amount_to");
                    }}

                />
              </Form.Group>
            </Row>
            <Row className={"mt-4"}>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Percentage Over</Form.Label>
                <Form.Control
                    className={"text-right"}
                    size={'sm'}
                    type='text'
                    placeholder='0'
                    name={'percentage_over'}
                    value={item.percentage_over}
                    onChange={event => {
                      handleChangeWithholding(event, "percentage_over");
                    }}

                />
              </Form.Group>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Amount Over</Form.Label>
                <Form.Control
                    className={"text-right"}
                    size={'sm'}
                    type='text'
                    placeholder='0'
                    name={'amount_over'}
                    value={item.amount_over}
                    onChange={event => {
                      handleChangeWithholding(event, "amount_over");
                    }}

                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-primary' size={'sm'} onClick={handleCloseUpdate}
          >
            Cancel
          </Button>
          <Button
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
              onClick={handleUpdate}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}
