import React from 'react'

const PasteIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={14.141} height={14.141}>
            <path
                d="M12 1a2.99 2.99 0 0 0-2.816 2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-4.184A2.99 2.99 0 0 0 12 1zm0 2a1 1 0 0 1 .389 1.922L12 5l-.389-.078A1.002 1.002 0 0 1 12 3zM5 5h2v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V5h2v14H5V5z"
                style={{
                    stroke: "none",
                    strokeWidth: 1,
                    strokeDasharray: "none",
                    strokeLinecap: "butt",
                    strokeDashoffset: 0,
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 4,
                    fill: "#000",
                    fillRule: "nonzero",
                    opacity: 1,
                }}
                transform="matrix(.6 0 0 .6 .8 1.4)"
            />
        </svg>
    )
}

export default PasteIcon
