import React from 'react'

const Download = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16.141'
      height='16.139'
      viewBox='0 0 16.141 16.139'
    >
      <g id='Download' transform='translate(0.5 0.5)'>
        <path
          id='Path_59'
          data-name='Path 59'
          d='M19.64,22.5v2.9a1.58,1.58,0,0,1-1.682,1.451H6.182A1.58,1.58,0,0,1,4.5,25.4V22.5'
          transform='translate(-4.5 -11.714)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_60'
          data-name='Path 60'
          d='M10.5,15l4.667,3.628L19.834,15'
          transform='translate(-7.598 -8.802)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_61'
          data-name='Path 61'
          d='M18,13.208V4.5'
          transform='translate(-10.43 -4.5)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default Download
