import React from 'react'

const Accept = () => {
  return (
    <svg
      id='Accept'
      xmlns='http://www.w3.org/2000/svg'
      width='16.287'
      height='16.287'
      viewBox='0 0 14.287 14.287'
    >
      <path
        id='Path_113'
        data-name='Path 113'
        d='M17.791,12.869l-.6-.622a.13.13,0,0,0-.1-.041h0a.125.125,0,0,0-.1.041L12.8,16.468l-1.525-1.525a.133.133,0,0,0-.192,0l-.611.611a.137.137,0,0,0,0,.2L12.4,17.674a.608.608,0,0,0,.4.2.637.637,0,0,0,.4-.189h0l4.592-4.616A.147.147,0,0,0,17.791,12.869Z'
        transform='translate(-6.986 -7.893)'
        fill='#f26930'
      />
      <path
        id='Path_114'
        data-name='Path 114'
        d='M10.519,4.337a6.179,6.179,0,1,1-4.372,1.81,6.141,6.141,0,0,1,4.372-1.81m0-.962a7.144,7.144,0,1,0,7.144,7.144,7.142,7.142,0,0,0-7.144-7.144Z'
        transform='translate(-3.375 -3.375)'
        fill='#a7a9ac'
      />
    </svg>
  )
}

export default Accept
