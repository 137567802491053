import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ICompanyValue } from './types'

const initialState: ICompanyValue = {
  company_email: '',
  employee_no: '',
  finger_id: '',
  supervisor_id: '',
  department_id: '',
  designation_id: '',
  branch_id: '',
  date_of_joining: '',
  date_of_leaving: '',
  status: '',
  approvers_order: "",
  employee_type: '',
  workflow: [{
    approver_employee_id: ''
  }],
  leave_credits: [{
    id: 0,
    employee_id: 0,
    leave_type: 0,
    credits: ""
  }]
}

export const CompanySlice = createSlice({
  name: 'Company',
  initialState,
  reducers: {
    editCompany: (_state, action: PayloadAction<ICompanyValue>) => action.payload,
  },
})

export const { editCompany } = CompanySlice.actions
export default CompanySlice.reducer
