import React from 'react'

const ClockBlackIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='13.605'
            height='13.605'
            viewBox='0 0 24.605 24.605'
        >
            <g id='Clock' transform='translate(1 1)'>
                <path
                    id='Path_5'
                    data-name='Path 5'
                    d='M25.6,14.3A11.3,11.3,0,1,1,14.3,3,11.3,11.3,0,0,1,25.6,14.3Z'
                    transform='translate(-3 -3)'
                    fill='none'
                    stroke='black'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                />
                <path
                    id='Path_6'
                    data-name='Path 6'
                    d='M18,9v6.781l4.521,2.26'
                    transform='translate(-6.697 -4.479)'
                    fill='none'
                    stroke='black'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                />
            </g>
        </svg>
    )
}

export default ClockBlackIcon
