import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { Form, Button, Image, InputGroup, Row, Modal} from 'react-bootstrap'
import Webcam from "react-webcam";
import LocationMarker from 'components/Marker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons/faCameraRetro'
import PersonIcon from 'asset/SvgComponent/PersonIcon'
import {
  MapContainer,
  TileLayer
} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import './style.css'
import moment from 'moment'
import { validationSchema } from './validationSchema'
import User from 'asset/SvgComponent/User'
import Password from 'asset/SvgComponent/Password'
import { Formik } from 'formik';
import Alert from 'components/Alert'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  ERROR_MESSAGE,
  ATTENDANCE,
  LOGIN
} from 'utils/globalConstant'
import {IAttendanceList, IShift, IWorksite, IWorksiteShift} from './types'
import {
  useSearchParams ,
} from "react-router-dom";
import PasswordShow from "asset/SvgComponent/PasswordShow";
import PasswordIcon from 'asset/SvgComponent/PasswordIcon'



const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

const Index = () => {
  const [state, setState] = useState(true)
  const [show, setShow] = useState(false);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] =useState(false)
  const [invalidLogin, setInvalidLogin] = useState<string>('')
  const [displayTime, setDisplayTime] = useState<string>('')
  const [token, setToken] = useState<string>('')

  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
    address: ''
  })

  const [clock, setClock] = useState({
    attendance_status: ''
  })
  const [breaks, setBreaks] = useState({
    attendance_break: ''
  })

  const [showSubmit, setSubmit] = useState(true)
  const [showMap, setShowMap] = useState(false)
  const [showCamera, setShowCamera] = useState(false)
  const [showCapture, setShowCapture] = useState(false)
  const handleCloseMap = () => setShowMap(false)
  const [capturedImage, setCapturedImage] = useState(null);
  const [type, setType] = useState('')
  const [employeeAttendanceId, setEmployeeAttendanceId] = useState(0);
  const [worksites, setWorksites] = useState<IWorksite[]>([]);
  const [selectedWorksite, setSelectedWorksite] = useState(0);
  const [shifts, setShifts] = useState<IWorksiteShift[]>([]);
  const [selectedShift, setSelectedShift] = useState(0);

  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false)

  const handleChangeShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleShowMap = () => {
    setShowMap(true)
  }
  const handleSubmitMap = () => {
    setShowCapture(true)
    handleCloseMap()
    setState(true)
    setCapturedImage(null);
    setShowCamera(false);
  }
  const handleCloseCapture = () => setShowCapture(false)
  const handleStartCapture = () => {
    setShowCamera(true)
    setState(state)
  }



  const webcamRef: any = useRef(null);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setState(!state)
    setSubmit(false)
  };

  const handleReCapture = () => {
    setCapturedImage(null);
    setState(!state)
    setSubmit(true)
  };

  const getLocation = (data: any) => {
    setLocation(data)
  }

  const onSubmit = async () => {

    if(selectedWorksite == 0){
      setShowError(true);
      setError("Please select worksite.")
    }else if(selectedShift == 0){
      setShowError(true);
      setError("Please select shift.")
    }else{
      handleShowMap()
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleChangeWorksite = (e:any) => {
    setSelectedWorksite(e.target.value)
    getShifts(e.target.value)
  }

  const handleChangeShift = (e:any) => {
    setSelectedShift(e.target.value)
  }


  const getWorksite = async () => {

    await axios.get(API_ENDPOINT.GET_WORK_SITE_NO_AUTH).then(response => {
      if (response?.data?.status * 1 === 1) {
        setWorksites(response.data.results)
      }
    }).catch(error => {
      setShow(true)
    })

    setTimeout(() => {
      setShow(false)
    }, 3000);

  }

  const getShifts = async (worksite_id: number) => {

    await axios.get(API_ENDPOINT.GET_SHIFT_BASED_ON_WORKSITE_NO_AUTH+"/"+worksite_id).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShifts(response.data.results)
      }
    }).catch(error => {
      setShow(true)
    })

    setTimeout(() => {
      setShow(false)
    }, 3000);

  }


  const getAttendanceTime = async (token : any, attendanceType : any) => {

    const response = await axios.get<IAPI_Response<IAttendanceList>>(API_ENDPOINT.GET_ATTENDANCE_TIME, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-with': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`
      },
      params: {
        type: attendanceType,
      },
    })
    const result = await response
    if (result?.data?.results) {
      setEmployeeAttendanceId(result?.data?.results.employee_attendance_id)

      if(attendanceType == 'clock'){
        if(result?.data?.results.out_time != null && result?.data?.results.in_time != null){
          setClock({
            attendance_status: "out"
          })
        }else if(result?.data?.results.out_time == null && result?.data?.results.in_time != null){

          const nextShift = moment(moment(result?.data?.results.in_time).add(1, 'd').format('YYYY-MM-DD') + " " + result?.data?.results.start_from).format("YYYY-MM-DD HH:mm:ss");

          // check if date today exceeds on the next shift
          if(moment().isAfter(nextShift)){    // no clock out
            setClock({
              attendance_status: "out"
            })
          }else{
            setSelectedWorksite(result?.data?.results?.worksite_id)
            setClock({
              attendance_status: "in"
            })
          }

        }else{
          setClock({
            attendance_status: "out"
          })
        }

      }else if(attendanceType == 'break'){
        if(result?.data?.results.break_out != null && result?.data?.results.break_in != null) {
          setBreaks({
            attendance_break: "break out"
          })
        }else if(result?.data?.results.break_out == null && result?.data?.results.break_in != null){
          setBreaks({
            attendance_break: "break in"
          })
        }else{
          setBreaks({
            attendance_break: "break out"
          })
        }
      }

    }
  }

  const getLogin = async (value: any) => {
    if (value.username != '' && value.password != '') {
      await axios.get(API_ENDPOINT.LOGIN, {
        params: {
          username: value.username,
          password: value.password 
        },
      }).then(response => {
        if (response?.data?.status * 1 === 1) {
          getAttendanceTime(response.data.results.token, 'clock')
          getAttendanceTime(response.data.results.token, 'break')
          setToken(response.data.results.token)
        }
      }).catch(error => {
        setShow(true)
        setInvalidLogin(error.response.data.message)
        setClock({attendance_status: ''})
        setToken('')
      })
  
      setTimeout(() => {
        setShow(false)
      }, 3000);
    }
  }

  const postAttendanceTime = async (e:any) => {
    e.currentTarget.disabled = true;

    let attendanceType = 'time_in';

    if (type == null || type == "") {
      attendanceType = 'time_in';
    } else if (type == 'in') {
      attendanceType = 'time_in';
    } else if (type == 'out') {
      attendanceType = 'time_out';
    } else if(type == 'break_in'){
      attendanceType = 'break_in'
    } else if(type == 'break_out'){
      attendanceType = 'break_out'
    }

    await axios.post(API_ENDPOINT.POST_ATTENDANCE_TIME_FOR_ROPISA, {
      latitude: location.latitude,
      longitude: location.longitude,
      address: location.address == "" ? "Cannot be found." : location.address,
      type: attendanceType,
      image: capturedImage,
      employee_attendance_id: employeeAttendanceId,
      worksite_id: selectedWorksite,
      shift_id: selectedShift
    }, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-with': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          if (clock.attendance_status == null) {
            setSuccess(ATTENDANCE.TIMEIN)
          } else if (clock.attendance_status == 'in') {
            setSuccess(ATTENDANCE.TIMEOUT)
          } else if (clock.attendance_status == 'out') {
            setSuccess(ATTENDANCE.TIMEIN)
          }
       }
    }).catch(() => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    })

    handleCloseCapture();
    setClock({attendance_status: ''})
    setToken('')

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const handleClock = (type: any) => {
    setType(type);
    handleShowMap()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayTime(moment().format('LTS'))
    }, 1000)
    return () => clearInterval(interval)
  })

  useEffect(() => {
    ;(async () => {
      getWorksite()
      if(searchParams.get("worksite")){
        setSelectedWorksite(Number(searchParams.get("worksite")))
      }
    })()
  }, [])

  // eslint-disable-next-line no-constant-condition
  const formJSX =  (
    <Formik
      initialValues={
        { 
          username: '',
          password: '',
          worksite_id: ''
        }
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
      >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group controlId="validationFormikUsername">
              <InputGroup hasValidation>
                <InputGroup.Text id='basic-addon1' className='bg-white'>
                  <User/>
                  &nbsp;
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  aria-describedby="inputGroupPrepend"
                  name="username"
                  value={values.username}
                  onChange={e => {
                    handleChange(e)
                    getLogin({...values, username: e.target.value})
                  }}
                  onBlur={e => {
                    // call the built-in handleBur
                    handleBlur(e)
                    getLogin(values)
                  }}
                  className='form_inputs'
                  isInvalid={touched.username && !!errors.username}
                  style={{textTransform: 'lowercase'}}
                />
                <Form.Control.Feedback>
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group controlId="validationFormikPassword">
              <InputGroup hasValidation className='mb-3'>
                <InputGroup.Text id='basic-addon1' className='bg-white'>
                  <PasswordIcon/>
                </InputGroup.Text>
                <Form.Control
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder='Password'
                  aria-label='password'
                  aria-describedby="inputGroupPrepend"
                  value={values.password}
                  onChange={e => {
                    handleChange(e)
                    values.password =  e.target.value
                    getLogin({...values, password: e.target.value})
                  }}
                  onBlur={e => {
                    // call the built-in handleBur
                    handleBlur(e)
                    getLogin(values)
                  }}
                  className='form_inputs'
                  isInvalid={touched.password && !!errors.password}
                  style={{borderRight: 'none'}}
                  />
                <InputGroup.Text
                    id='basic-addon1'
                    className="bg-white"
                    style={{cursor: 'pointer'}}
                    onClick={handleChangeShowPassword}
                    title={showPassword ? "Hide password" : "Show password"}
                >
                  {showPassword ? <PasswordShow/> : <Password/>}
                </InputGroup.Text>
                  <Form.Control.Feedback>
                  </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <p className="login-error" style={{position: "fixed"}}>
            {
              ((touched.username && !!errors.username) || (touched.password && !!errors.password)) ?
                  LOGIN.error
                  : (
                      !show? "" :
                          invalidLogin
                  )
            }
          </p>
          <Row className='mb-1 pt-2' >
            <Form.Group controlId='formHorizontalEmail'>
              <Form.Select
                  className="worksite_dropdown"
                  name="worksite_id"
                  size='sm'
                  value={selectedWorksite}
                  onChange={handleChangeWorksite}

              >
                <option value="">Select Worksite</option>
                {worksites.map((worksite, index) => (
                    <option value={worksite.id} key={index}>{worksite.work_site_name}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback>
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className='mb-3 pt-2' >
            <Form.Group controlId='formHorizontalEmail'>
              <Form.Select
                  className="shift_dropdown"
                  name="shift_id"
                  size='sm'
                  value={selectedShift}
                  onChange={handleChangeShift}

              >
                <option value="">Select Shift</option>
                {shifts.map((shift, index) => (
                    shift.shifts.isEightHourShift == 2 ?
                    <option value={shift.shifts.id} key={index}>{moment(new Date("2023-01-01 " + shift.shifts.time_from ?? "")).format("h:mm A")} - {moment(new Date("2023-01-01 " + shift.shifts.overtime_out ?? "")).format("h:mm A")}</option>
                        :
                        <option value={shift.shifts.id} key={index}>{moment(new Date("2023-01-01 " + shift.shifts.time_from ?? "")).format("h:mm A")} - {moment(new Date("2023-01-01 " + shift.shifts.time_to ?? "")).format("h:mm A")}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback>
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div className='d-grid gap-2'>
              {(() => {
                if (clock.attendance_status == null) {
                  return (
                    <Button 
                      type="submit"
                      className='btn text-white'
                      variant='warning'
                      onClick={() => setType("in")}
                      >
                      Clock In
                    </Button>
                  )
                } else if(clock.attendance_status == 'in') {
                  return (
                    <Button 
                      type="submit"
                      className='btn text-white'
                      variant='danger'
                      onClick={() => setType("out")}
                      >
                      Clock Out
                    </Button>
                  )
                } else if(clock.attendance_status == 'out') {
                  return (
                    <Button 
                      type="submit"
                      className='btn text-white'
                      variant='warning'
                      onClick={() => setType("in")}
                      >
                      Clock In
                    </Button>
                  )
                }
                return (
                  <Button 
                      type="submit"
                      disabled={clock.attendance_status == ''} 
                      className='btn btn-dark text-white custom-orange'
                      >
                      Clock In/Out
                    </Button>
                )
              })()}
          </div>
        </Form>
    )}
    </Formik>
  )

  return (
    <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
      <div className='container'>
        <div className='row align-items-center p-0'>
          <div className='col-md-3' />
          <div className=' col-md-6 login-body'>
            <div id='login_container' className='row border-0  rounded-4 shadow-lg'>
              <div className='col-md-5  p-5' id='left-side'>
                <div className='text-center mt-5'>
                  <Image rounded className='w-100 mb-4 logo'  src='/Octaneware1.png' alt='logo'/>
                </div>
                <div className='form-group text-center mb-4'>
                  <div className='col-xs-12'>
                    <h6 className='fw-bold' id=''>
                      {displayTime}
                    </h6>
                    <h6 className='fw-bold mt-4' id=''>
                      {moment().format('dddd')}
                    </h6>
                    <h6 className='fw-bold' id=''>
                      {moment().format('LL')}
                    </h6>
                  </div>
                </div>
                <br />
              </div>
              <div className='col-md-7 p-4 bg-primary text-center' id='gradient_div'>
                <h4 className=' text-white mt-3' id=''>
                  Kiosk
                </h4>
                <h6 id='sign_in_label' className=' text-white'>
                  Sign in to your account
                </h6>
                <br />
                <br />
                {formJSX}
              </div>
            </div>
          </div>
          <div className='col-md-3' />
        </div>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        {/* MAP MODAL*/}
        <Modal className='modal' size="lg" centered show={showMap} onHide={handleCloseMap}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>CLOCK IN/OUT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <MapContainer
                style={{height: '400px'}}
                center={{ lat: 0, lng: 0 }}
                zoom={13}
                scrollWheelZoom={false}
                attributionControl={false}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

                />
                <LocationMarker func={getLocation}/>
              </MapContainer>
              <div className="text-center">
                Your current location : <b>{location.address}</b>
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseMap}>
              Cancel
            </Button>
            <Button 
              onClick={handleSubmitMap}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
              >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* CAPTURE MODAL*/}
        <Modal className='modal' size="lg" centered show={showCapture} onHide={handleCloseCapture}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold ms-auto'>Please take a photo of yourself for your attendance.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              !showCamera ? (
                <Row style={{margin: "10px"}}>
                  <div className="text-center"> 
                    <p className="capture-icon">
                      <PersonIcon/>
                    </p>
                    <Button
                      onClick={handleStartCapture}
                      variant='primary text-white'
                      size={'sm'}
                      className='mx-2'
                      >
                      <FontAwesomeIcon icon={faCameraRetro} />
                      &nbsp;Start Camera
                    </Button>
                  </div>
                </Row>
              ) : (
                <Row style={{margin: "10px"}}>
                  <div className="text-center"> 
                      <p>
                        {
                          capturedImage ? (
                            <img className="capture" src={capturedImage} alt="Captured Image" width="400" height="300"/>
                          ) : (
                            <Webcam
                              className="capture"
                              audio={false}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              videoConstraints={videoConstraints}
                              width="400" 
                              height="300"
                            />
                          )
                        }
                      </p>
                      
                      <Button
                          onClick={state ? handleCapture : handleReCapture }
                          variant='primary text-white'
                          size={'sm'}
                          className='mx-2'
                          >
                          <FontAwesomeIcon icon={faCameraRetro} />
                          &nbsp;{state ? 'Capture' : 'Recapture'}
                      </Button>
                  </div>
              </Row>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCapture}>
              Cancel
            </Button>
            <Button
              disabled={showSubmit}
              onClick={postAttendanceTime}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
              >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </div>
  )
}

export default Index
