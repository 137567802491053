import { useEffect, useState, useRef } from 'react'
import { Button, Modal, Row } from 'react-bootstrap'
import { Grid, styled, Typography } from '@mui/material'
import Webcam from "react-webcam";
import SystemButton from 'components/Button'
import LocationMarker from 'components/Marker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons/faCameraRetro'
import PersonIcon from 'asset/SvgComponent/PersonIcon'
import {
  MapContainer,
  TileLayer
} from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import Alert from 'components/Alert'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  ERROR_MESSAGE,
  ATTENDANCE
} from 'utils/globalConstant'
import { IAttendanceList } from './types'
import { IAttendance } from '../../Attendance/SecurityAgency/types'
import { IStatistic } from '../../../types'
import { useDispatch } from 'react-redux'
import { addAttendance } from 'redux/Dashboard/Attendance/AttendanceSlice'
import { addStatistics } from 'redux/Dashboard/Statistics/StatisticsSlice'
import moment from "moment/moment";

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginRight: '35px',
  fontSize: theme.spacing(3),
  fontWeight: theme.fontStyle.weight.bold,
  lineHeight: `${theme.spacing(4)}px`,
}))

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

const ClockButton = () => {
  const dispatch = useDispatch()

  const [state, setState] = useState(true)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] =useState(false)

  const [type, setType] = useState('')
  const [clocks, setClocks] = useState({
    attendance_clock: '',
  })
  const [breaks, setBreaks] = useState({
    attendance_break: ''
  })
  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
    address: ''
  })

  const [showSubmit, setSubmit] = useState(true)
  const [showMap, setShowMap] = useState(false)
  const [showCamera, setShowCamera] = useState(false)
  const [showCapture, setShowCapture] = useState(false)
  const handleCloseMap = () => setShowMap(false)
  const handleShowMap = () => setShowMap(true)
  const handleSubmitMap = () => {
    setShowCapture(true)
    handleCloseMap()
    setState(true)
    setCapturedImage(null);
    setShowCamera(false);
  }
  const handleCloseCapture = () => setShowCapture(false)
  const handleStartCapture = () => setShowCamera(true)

  const handleClock = (type: any) => {
    setType(type);
    handleShowMap()
  }

  const webcamRef: any = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [employeeAttendanceDetails, setEmployeeAttendanceDetails] = useState({
    employee_attendance_id: 0,
    in_time: "",
    out_time: "",
    break_in: "",
    break_out: "",
    worked_time: "",
  });
  const [worktime, setWorktime] = useState('12:00:00');

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setState(!state)
    setSubmit(false)
  };

  const handleReCapture = () => {
    setCapturedImage(null);
    setState(!state)
    setSubmit(true)
  };

  const getLocation = (data: any) => {
    setLocation(data)
  }

  const getStatistics = async () => {
    try {
      const response = await axios.get<IAPI_Response<IStatistic>>(API_ENDPOINT.GET_STATISTICS)
      const result = await response
      if (result?.data?.results) {
        dispatch(addStatistics(result?.data?.results))
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getAttendanceAuth = async () => {
    try {
      const response = await axios.get<IAPI_Response<IAttendance>>(API_ENDPOINT.GET_ATTENDANCE_AUTH)
      const result = await response
      if (result?.data?.results?.data?.length) {
        dispatch(addAttendance(result?.data?.results?.data))
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
  }

  const getAttendanceTime = async (type : any) => {
      const response = await axios.get<IAPI_Response<IAttendanceList>>(API_ENDPOINT.GET_ATTENDANCE_TIME, {
        params: {
          type: type,
        },
      })
      const result = await response
      if (result?.data?.results) {
        setEmployeeAttendanceDetails({
          employee_attendance_id: result?.data?.results.employee_attendance_id,
          in_time: result?.data?.results.in_time,
          out_time: result?.data?.results.out_time,
          break_in: result?.data?.results.break_in,
          break_out: result?.data?.results.break_out,
          worked_time: "10:00:00"
        })
        if (type == 'clock') {
          if(result?.data?.results.out_time != null && result?.data?.results.in_time != null){
            setClocks({
              attendance_clock: "out"
            })
          }else if(result?.data?.results.out_time == null && result?.data?.results.in_time != null){
            const nextShift = moment(moment(result?.data?.results.in_time).add(1, 'd').format('YYYY-MM-DD') + " " + result?.data?.results.start_from).format("YYYY-MM-DD HH:mm:ss");
            // check if date today exceeds on the next shift
            if(moment().isAfter(nextShift)) {    // no clock out
              setClocks({
                attendance_clock: "out"
              })
            }else{
              setClocks({
                attendance_clock: "in"
              })
            }

          }else{
            setClocks({
              attendance_clock: "out"
            })
          }
        } else if (type == 'break') {
          if(result?.data?.results.break_out != null && result?.data?.results.break_in != null) {
            setBreaks({
              attendance_break: "break out"
            })
          }else if(result?.data?.results.break_out == null && result?.data?.results.break_in != null){
            setBreaks({
              attendance_break: "break in"
            })
          }else{
            setBreaks({
              attendance_break: "break out"
            })
          }

        }


      }
  }

  const postAttendanceTime = async () => {
    await axios.post(API_ENDPOINT.POST_ATTENDANCE_TIME, {
      latitude: location.latitude,
      longitude: location.longitude,
      address: location.address,
      type: type,
      image: capturedImage,
      employee_attendance_id: employeeAttendanceDetails.employee_attendance_id
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          if (type == 'time_in') {
            setSuccess(ATTENDANCE.TIMEIN)
          } else if (type == 'time_out') {
            setSuccess(ATTENDANCE.TIMEOUT)
          } else if (type == 'break_in') {
            setSuccess(ATTENDANCE.BREAKIN)
          } else if (type == 'break_out') {
            setSuccess(ATTENDANCE.BREAKOUT)
          }
          getAttendanceTime('clock')
          getAttendanceTime('break')
          getAttendanceAuth()
          getStatistics()
       }
    }).catch(() => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    })

    handleCloseCapture();
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getAttendanceTime('clock')
      getAttendanceTime('break')

      // if(employeeAttendanceDetails.out_time == null){
      //   const interval = setInterval(() => {
      //     const now  = moment().format("YYYY-MM-DD HH:mm:ss");
      //     const then = moment(employeeAttendanceDetails.in_time).format("YYYY-MM-DD HH:mm:ss");
      //
      //     setWorktime(moment.utc(moment(now).diff(moment(then))).format("HH:mm:ss"))
      //   }, 1000)
      //   return () => clearInterval(interval)
      // }


    })()
  }, [])

  return (
    <Grid item container spacing={1}>
      <Grid item xs={12} md={4}>
        <StyledTypography>{clocks.attendance_clock == 'in' ? worktime : '00:00:00'}</StyledTypography>
      </Grid>
      <Grid item xs={12} md={4}>
        {(() => {
          if (clocks.attendance_clock == null) {
            return (
              <SystemButton
                onClick={() => handleClock('time_in')}
                color='success'
                variant='contained'
                sx={{ width: '145px', height: '37px' }}
                >
                Clock In
              </SystemButton>
            )
          } else if(clocks.attendance_clock == 'in') {
            return (
              <SystemButton
                onClick={() => handleClock('time_out')}
                color='error'
                variant='contained'
                sx={{ width: '145px', height: '37px' }}
                >
                Clock Out
              </SystemButton>
            )
          } else if(clocks.attendance_clock == 'out') {
            return (
              <SystemButton
                onClick={() => handleClock('time_in')}
                color='success'
                variant='contained'
                sx={{ width: '145px', height: '37px' }}
                >
                Clock In
              </SystemButton>
            )
          }
          return (
            <SystemButton
              color='success'
              variant='contained'
              sx={{ width: '145px', height: '37px' }}
              >
            </SystemButton>
          )
        })()}
      </Grid>
      <Grid item xs={12} md={4}>
        {(() => {
          if(breaks.attendance_break == 'break in') {
            return (
              <SystemButton
                onClick={() => handleClock('break_out')}
                color='error' 
                variant='outlined' 
                sx={{ width: '145px', height: '37px' }}
                >
                Break Out
              </SystemButton>
            )
          } else if(breaks.attendance_break == 'break out') {
            return (
              <SystemButton
                onClick={() => handleClock('break_in')}
                color='success' 
                variant='outlined' 
                sx={{ width: '145px', height: '37px' }}
                >
                Break In
              </SystemButton>
            )
          }
          return (
            <SystemButton
              disabled={clocks.attendance_clock == null}
              onClick={() => handleClock('break_in')}
              color='success' 
              variant='outlined' 
              sx={{ width: '145px', height: '37px' }}
              >
              Break In
            </SystemButton>
          )
        })()}
      </Grid>

      <Alert 
        type="success" 
        show={showSuccess} 
        message={success} 
        setShow={() => { 
            setShowSuccess(false);
          }
        }
      />

      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />

      {/* MAP MODAL*/}
      <Modal className='modal' size="lg" centered show={showMap} onHide={handleCloseMap}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>CLOCK IN/OUT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <MapContainer
              style={{height: '400px'}}
              center={{ lat: 0, lng: 0 }}
              zoom={13}
              scrollWheelZoom={false}
              attributionControl={false}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker func={getLocation}/>
            </MapContainer>
            <div className="text-center">
              Your current location : <b>{location.address}</b>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseMap}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmitMap}
            variant='primary text-white'
            size={'sm'}
            className='mx-2'
            >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* CAPTURE MODAL*/}
      <Modal className='modal' size="lg" centered show={showCapture} onHide={handleCloseCapture}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold ms-auto'>Please take a photo of yourself for your attendance.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            !showCamera ? (
              <Row style={{margin: "10px"}}>
                <div className="text-center"> 
                  <p className="capture-icon">
                    <PersonIcon/>
                  </p>
                  <Button
                    onClick={handleStartCapture}
                    variant='primary text-white'
                    size={'sm'}
                    className='mx-2'
                    >
                    <FontAwesomeIcon icon={faCameraRetro} />
                    &nbsp;Start Camera
                  </Button>
                </div>
              </Row>
            ) : (
              <Row style={{margin: "10px"}}>
                <div className="text-center"> 
                    <p>
                      {
                        capturedImage ? (
                          <img className="capture" src={capturedImage} alt="Captured Image" width="400" height="300"/>
                        ) : (
                          <Webcam
                            className="capture"
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            width="400" 
                            height="300"
                          />
                        )
                      }
                    </p>
                    
                    <Button
                        onClick={state ? handleCapture : handleReCapture }
                        variant='primary text-white'
                        size={'sm'}
                        className='mx-2'
                        >
                        <FontAwesomeIcon icon={faCameraRetro} />
                        &nbsp;{state ? 'Capture' : 'Recapture'}
                    </Button>
                </div>
            </Row>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCapture}>
            Cancel
          </Button>
          <Button
            disabled={showSubmit}
            onClick={postAttendanceTime}
            variant='primary text-white'
            size={'sm'}
            className='mx-2'
            >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Grid>
  )
}

export default ClockButton
