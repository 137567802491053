import React, {lazy, Suspense, useRef, useState} from 'react'
import { useNavigate } from "react-router-dom";
import {Container, Breadcrumb, Row, Col, Form, Card, InputGroup, Button} from 'react-bootstrap'
import './style.css'
import ProgressLoading from 'components/ProgressLoading'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import {validationSchema} from "./validationSchema"
import Image from "react-bootstrap/Image";
import Camera from "asset/SvgComponent/Camera";
import { Formik } from 'formik';
import {addPersonal} from "../../../redux/EmployeeManagement/AddEmployee/Retail/Personal/PersonalSlice";
import axios from "../../../api";
import {API_ENDPOINT, SUCCESS_MESSAGE} from "../../../utils/globalConstant";
import Alert from "components/Alert";

export default function Index() {
    const navigate = useNavigate();
    const inputFileUploadRef = useRef<HTMLInputElement>( null );
    const [uploadedProfilePicture, setUploadedProfilePicture] = useState("");
    const [uploadedProfilePictureBase64, setUploadedProfilePictureBase64] = useState("");
    const [pictureOpacity, setPictureOpacity] = useState(1)
    const [showText, setShowText] = useState('none')
    const [success, setSuccess] = React.useState('')
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [error, setError] = React.useState('')
    const [showError, setShowError] = React.useState(false)

    const onSubmit = async (value: any) => {
        postCandidate(value)
    }

    const renderComponent = (comp: JSX.Element) => (
        <Suspense fallback={<ProgressLoading />}>{comp}</Suspense>
    )

    const [key, setKey] = useState("personal")

    const handleClick = async() => {
        inputFileUploadRef?.current?.click()
    }

    const handleOnMouseEnter = () => {

        setPictureOpacity(0.5)
        setShowText("")
    }

    const handleOnMouseLeave = () => {

        setPictureOpacity(1)
        setShowText("none")
    }

    const handleUploadProfilePicture = async(e: any) => {
        setUploadedProfilePicture(URL.createObjectURL(e.target.files[0]));

        const reader = new FileReader()

        reader.readAsDataURL(e.target.files[0])

        reader.onload = () => {
            // @ts-ignore
            setUploadedProfilePictureBase64(reader.result)
        }

    }

    const postCandidate = async(values: any) => {
        await axios.post(API_ENDPOINT.POST_CANDIDATE, values).then(response => {
            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.CANDIDATE.CREATED)
                navigate('/employeeManagement/candidate-list');
            }else{
                setShowError(true)
                setError("Error. Please check lacking fields.")
            }
        }).catch(error => {

            setShowError(true)
            setError("Error. Please check lacking fields.")
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    return (
        <>
            <Alert
                type="success"
                show={showSuccess}
                message={success}
                setShow={() => {
                    setShowSuccess(false);
                }
                }
            />

            <Alert
                type="error"
                show={showError}
                message={error}
                setShow={() => {
                    setShowError(false);
                }
                }
            />

            <Container fluid>
                <h5 className='fw-bold'>Add Candidate</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className='text-decoration-none' href='/home'>
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className='text-decoration-none' href='#'>
                        Employees
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href='/employeeManagement/candidate-list'>Candidate List</Breadcrumb.Item>
                    <Breadcrumb.Item active>Add Candidate</Breadcrumb.Item>
                </Breadcrumb>

                <Tabs
                    variant={'tabs'}
                    className='tabs '
                    id='controlled-tab-example'
                    activeKey={key}
                    onSelect={(k: any) => {
                        setKey(k)
                    }}
                >
                    <Tab className='p-0' eventKey='personal' title='Personal'>
                        <Card className='border-0  p-0 bordered-card-tabs'>
                            <Card.Body>
                                <Formik
                                    initialValues={{
                                        first_name: '',
                                        middle_name: '',
                                        last_name: '',
                                        photo: '',
                                        email: '',
                                        date_of_birth: '',
                                        gender: '',
                                        religion: '',
                                        marital_status: '',
                                        address: '',
                                        emergency_contacts: '',
                                        contact_number: '',
                                        status: '',
                                        photo_url: ''}}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => {
                                        console.log("submit")
                                        values = {
                                            first_name: values.first_name,
                                            last_name: values.last_name,
                                            middle_name: values.middle_name,
                                            contact_number: values.contact_number,
                                            emergency_contacts: values.emergency_contacts,
                                            date_of_birth: values.date_of_birth,
                                            marital_status: values.marital_status,
                                            address: values.address,
                                            email: values.email,
                                            gender: values.gender,
                                            photo: uploadedProfilePictureBase64,
                                            photo_url: uploadedProfilePicture,
                                            religion: values.religion,
                                            status: values.status
                                        }
                                        onSubmit(values)
                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting
                                      }) => (

                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Row className="mb-3">
                                                <span className='fw-bold'>Basic</span>
                                            </Row>

                                            <Row>
                                                <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
                                                    <Row className="mb-4">
                                                        <Form.Group as={Col} md="4" >
                                                            <Form.Label column="sm" className="required">First Name</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="8" className="position-relative">
                                                            <Form.Control
                                                                size={'sm'}
                                                                type='text'
                                                                placeholder='First Name'
                                                                name="first_name"
                                                                value={values.first_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isInvalid={touched.first_name && !!errors.first_name}
                                                            />
                                                            <Form.Control.Feedback type="invalid" tooltip>
                                                                {errors.first_name}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-4">
                                                        <Form.Group as={Col} md="4" >
                                                            <Form.Label column="sm" className="required">Middle Name</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="8" className="position-relative">
                                                            <Form.Control
                                                                size={'sm'}
                                                                type='text'
                                                                placeholder='Middle Name'
                                                                name="middle_name"
                                                                value={values.middle_name}
                                                                onChange={handleChange}
                                                                isInvalid={touched.middle_name && !!errors.middle_name}
                                                            />
                                                            <Form.Control.Feedback type="invalid" tooltip>
                                                                {errors.middle_name}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row className="mb-4">
                                                        <Form.Group as={Col} md="4" >
                                                            <Form.Label column="sm" className="required">Last Name</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="8" className="position-relative">
                                                            <Form.Control
                                                                size={'sm'}
                                                                type='text'
                                                                placeholder='Last Name'
                                                                name="last_name"
                                                                value={values.last_name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                isInvalid={touched.last_name && !!errors.last_name}
                                                            />
                                                            <Form.Control.Feedback type="invalid" tooltip>
                                                                {errors.last_name}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>
                                                </Col>

                                                <Col
                                                    xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}
                                                    style={{display: 'flex', justifyContent: 'center'}}

                                                >
                                                    <div style={{display: 'block'}}>
                                                        <div className="profile-box"
                                                             onMouseEnter={handleOnMouseEnter}
                                                             onMouseLeave={handleOnMouseLeave}
                                                             onClick={handleClick}
                                                        >
                                                            <Image className='profile-picture' style={{opacity: pictureOpacity}} src={uploadedProfilePicture != "" ? uploadedProfilePicture : values.photo && values.photo != "" ? process.env.REACT_APP_IMAGE_URL+ values.photo : process.env.REACT_APP_IMAGE_URL+ "uploads/employeePhoto/profile-transparent.png"} />
                                                            <p className="upload-icon" style={{display: showText}}><Camera/></p>
                                                            {/*<div className="upload-icon"><Camera/></div>*/}
                                                            <Form.Group style={{display: 'none'}}>
                                                                <Form.Control
                                                                    type='file'
                                                                    size={'sm'}
                                                                    name="photo"
                                                                    accept="image/*"
                                                                    // value={values.photo}
                                                                    onChange={(e: any) => {
                                                                        handleUploadProfilePicture(e)
                                                                    }}
                                                                    ref={inputFileUploadRef}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{position: 'relative', fontSize: '12px', textAlign: 'center'}}>
                                                            <span>Click to upload profile picture.</span>
                                                        </div>
                                                    </div>


                                                </Col>
                                            </Row>
                                            <Row className="mb-4">
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label column="sm" className="required">Contact Number</Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" className="position-relative">
                                                    <Form.Control
                                                        size={'sm'}
                                                        type='text'
                                                        placeholder='Contact Number'
                                                        name="contact_number"
                                                        value={values.contact_number}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.contact_number && !!errors.contact_number}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {errors.contact_number}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label column="sm" className="required">Emergency Number</Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" className="position-relative">
                                                    <Form.Control
                                                        size={'sm'}
                                                        type='text'
                                                        placeholder='Emergency Number'
                                                        name="emergency_contacts"
                                                        value={values.emergency_contacts}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.emergency_contacts && !!errors.emergency_contacts}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {errors.emergency_contacts}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mb-4">
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label column="sm" className="required">Date of Birth</Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" className="position-relative">
                                                    <InputGroup size={'sm'}>
                                                        <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                width='22.344'
                                                                height='24.605'
                                                                viewBox='0 0 22.344 24.605'
                                                            >
                                                                <g id='Calendar' transform='translate(1 1)'>
                                                                    <path
                                                                        id='Path_4'
                                                                        data-name='Path 4'
                                                                        d='M4.5,15H24.844'
                                                                        transform='translate(-4.5 -5.959)'
                                                                        fill='none'
                                                                        stroke='#f26930'
                                                                        strokeLinecap='round'
                                                                        strokeLinejoin='round'
                                                                        strokeWidth='2'
                                                                    />
                                                                    <path
                                                                        id='Path_1'
                                                                        data-name='Path 1'
                                                                        d='M6.76,6H22.583a2.232,2.232,0,0,1,2.26,2.2V23.619a2.232,2.232,0,0,1-2.26,2.2H6.76a2.232,2.232,0,0,1-2.26-2.2V8.2A2.232,2.232,0,0,1,6.76,6Z'
                                                                        transform='translate(-4.5 -3.217)'
                                                                        fill='none'
                                                                        stroke='#a7a9ac'
                                                                        strokeLinecap='round'
                                                                        strokeLinejoin='round'
                                                                        strokeWidth='2'
                                                                    />
                                                                    <path
                                                                        id='Path_2'
                                                                        data-name='Path 2'
                                                                        d='M24,3V8.566'
                                                                        transform='translate(-9.307 -3)'
                                                                        fill='none'
                                                                        stroke='#f26930'
                                                                        strokeLinecap='round'
                                                                        strokeLinejoin='round'
                                                                        strokeWidth='2'
                                                                    />
                                                                    <path
                                                                        id='Path_3'
                                                                        data-name='Path 3'
                                                                        d='M12,3V8.566'
                                                                        transform='translate(-6.35 -3)'
                                                                        fill='none'
                                                                        stroke='#f26930'
                                                                        strokeLinecap='round'
                                                                        strokeLinejoin='round'
                                                                        strokeWidth='2'
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            size={'sm'}
                                                            type='date'
                                                            placeholder='Friday, June 03, 2022'
                                                            name="date_of_birth"
                                                            value={values.date_of_birth}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={touched.date_of_birth && !!errors.date_of_birth}
                                                        />
                                                        <Form.Control.Feedback type="invalid" tooltip>
                                                            {errors.date_of_birth}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label column="sm" className="required">Gender</Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4">
                                                    <Form.Check
                                                        inline
                                                        label="Male"
                                                        name="gender"
                                                        value="Male"
                                                        type="radio"
                                                        id="inline-radio-0"
                                                        checked={values.gender === 'Male'}
                                                        onChange={handleChange}
                                                        isInvalid={touched.gender && !!errors.gender}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Female"
                                                        name="gender"
                                                        value="Female"
                                                        type="radio"
                                                        id="inline-radio-1"
                                                        checked={values.gender === 'Female'}
                                                        onChange={handleChange}
                                                        isInvalid={touched.gender && !!errors.gender}
                                                    />
                                                    <span style={{ color: '#dc3545', fontSize: '0.875em' }}>
                                                    {touched.gender && errors.gender}
                                                  </span>

                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-4">
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label column="sm" className="required">Marital Status</Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" className="position-relative">
                                                    <Form.Select
                                                        size={'sm'}
                                                        name="marital_status"
                                                        value={values.marital_status}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.marital_status && !!errors.marital_status}
                                                    >
                                                        <option value="">Select Marital Status</option>
                                                        <option value="Single">Single</option>
                                                        <option value="Married">Married</option>
                                                        <option value="Widow">Widow</option>
                                                        <option value="Divorce">Divorce</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {errors.marital_status}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label column="sm" className="required">Religion</Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" className="position-relative">
                                                    <Form.Control
                                                        size={'sm'}
                                                        type='text'
                                                        placeholder='Religion'
                                                        name="religion"
                                                        value={values.religion}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.religion && !!errors.religion}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {errors.religion}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-4">
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label column="sm" className="required">Present Address</Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} md="10" className="position-relative">
                                                    <Form.Control
                                                        size={'sm'}
                                                        type='text'
                                                        placeholder='Address'
                                                        name="address"
                                                        value={values.address}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.address && !!errors.address}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {errors.address}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-4">
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label column="sm" className="required">Personal Email</Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" className="position-relative">
                                                    <Form.Control
                                                        size={'sm'}
                                                        type='text'
                                                        placeholder='Email'
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.email && !!errors.email}
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label column="sm" className="required">Status</Form.Label>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" className="position-relative">
                                                    <Form.Select
                                                        size={'sm'}
                                                        name="status"
                                                        value={values.status}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.status && !!errors.status}
                                                    >
                                                        <option value="">Select Status</option>
                                                        <option value="1">Candidate</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {errors.status}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>

                                            <Row className="mt-5 text-right">
                                                <Form.Group as={Col} md="6">
                                                    <Button
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                        variant='primary text-white'
                                                        style={{ width: '25%' }}
                                                        size="sm">
                                                        Save
                                                    </Button>
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </Card.Body>
                        </Card>

                    </Tab>
                </Tabs>
                <br/>
            </Container>
        </>

    )
}
