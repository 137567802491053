const projectThemeStyle = {
  color: {
    white: '#fff',
    black: '#000',
  },
  fontStyle: {
    weight: {
      light: 300,
      normal: 'normal',
      bold: 'bold',
      heavyBold: 900,
    },
    size: {
      seventeen: 17,
      fourteen: 14,
      twelve: 12,
    },
    lineHeight: {
      twentyone: '21px',
      nineteen: '19px',
      sixteen: '16px',
      thirteen: '13px',
    },
  },
}

export const pallete = {
  primary: {
    light: '#fba91a',
    main: '#F26930',
    contrastText: '#fff',
  },
  secondary: {
    light: '#dbd8d8',
    main: '#a7a9ac',
    dark: '#231f20',
    contrastText: '#fff',
  },
  success: {
    light: '#82C341',
    main: '#1EA66D',
    dark: '#0C9E86',
    contrastText: '#fff',
  },
  info: {
    light: '#27699c',
    main: '#2254a4',
    dark: '#453d98',
    contrastText: '#fff',
  },
  error: {
    light: '#E74F7A',
    main: '#da2b5d',
    contrastText: '#fff',
  },
}

export default projectThemeStyle
