import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from 'react-bootstrap'
import Delete from 'asset/SvgComponent/Delete'
import Write from 'asset/SvgComponent/Write'
import Clock from 'asset/SvgComponent/Clock'
import EditIcon from 'asset/SvgComponent/EditIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import { Formik, FieldArray } from 'formik';
import { validationSchema } from './validationSchema'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import { 
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import { IShift, IList, IItem } from './types'

export default function Index() {
  const [currentPage, setCurrentPage] = React.useState(0)
  const [lastPage, setLastPage] = React.useState(0)
  const [itemsPerPage, setPerPage] = React.useState(10)
  const [itemsCount, setItemCount] = React.useState(0)
  const [from, setFrom] = React.useState(0)

  const [showCreate, setShowCreate] = React.useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)

  const [showEdit, setShowEdit] = React.useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = React.useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      work_shift_id: id,
      shift_name: '',
      shift_details: [
        {
          id: 0,
          work_shift_id: 0,
          day: '',
          start_time: '',
          end_time: '',
          break_in: '',
          break_out: '',
          late_count_time: '',
          is_rest_day: '',
        }
      ]
    })
  }

  const [validation, setValidation] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showLoading, setShowLoading] = React.useState(false)

  const [lists, setData] = useState<IList[]>([])
  const [item, setItem] = useState<IItem>({
    work_shift_id: 0,
    shift_name: '',
    shift_details: [
      {
        id: 0,
        work_shift_id: 0,
        day: '',
        start_time: '',
        end_time: '',
        break_in: '',
        break_out: '',
        late_count_time: '',
        is_rest_day: '',
      }
    ]
  })

  const getShift = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IShift>>(API_ENDPOINT.GET_SHIFT, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showShift = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_SHIFT +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postShift = async (value: any, {resetForm} : any) => {
    await axios.post(API_ENDPOINT.POST_SHIFT, {
      shift_name: value.shift_name,
      shift: value.shift
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.WORK_SHIFT.CREATED)
          getShift(null);
       }
       resetForm({})
       handleCloseCreate();
    }).catch(error => {
      setValidation(error.response.data.message)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const updateShift = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_SHIFT +'/'+ value.work_shift_id, {
      shift_name: value.shift_name,
      shift: value.shift
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.WORK_SHIFT.UPDATED)
          getShift(null);
       }
       resetForm({})
       handleCloseEdit();
    }).catch(error => {
      setValidation(error.response.data.message)
    })
    
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
      setValidation('')
    }, 3000);
  }

  const deleteShift = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_SHIFT +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.WORK_SHIFT.DELETED)
        getShift(null);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getShift(null)
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Work Shift</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/tax-rule-setup'>
            Attendance
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Work Shift</Breadcrumb.Item>
        </Breadcrumb>

        <Alert 
          type="success" 
          show={showSuccess} 
          message={success} 
          setShow={() => { 
              setShowSuccess(false);
            }
          }
        />

        <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <span className='fw-bold'>Work Shift Summary</span>
              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                <InputGroup size='sm' className={'w-25'}>
                  <Form.Control
                    aria-label='search'
                    aria-describedby='inputGroup-sizing-sm'
                    placeholder='Search...'
                    />
                  <InputGroup.Text className='bg-transparent search-input '>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
                <Button
                  onClick={handleShowCreate}
                  className=' add-new-btn rounded mx-2 text-white'
                  variant='warning'
                  >
                  <EditIcon/>
                  &nbsp;Add Work Shift
                </Button>{' '}
              </Col>
            </Row>
            {/* <hr/>*/}
            <Table className='bordered-tabs-top mt-3' responsive>
              <thead>
                <tr className='text-center'>
                  <th style={{ width: '5%' }}>No.</th>
                  <th style={{ width: '20%' }}>Work Shift Name</th>
                  <th className={'text-center'}>Actions</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {lists.length > 0 ? (
                    lists.map((item, index) => 
                      <tr className='mb-2' key={index}>
                        <td>{from + index}</td>
                        <td className='fw-bold'>{item.shift_name}</td>
                        <td className='text-center'>
                          <Button
                            onClick={() => showShift(item.work_shift_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Write/>
                          </Button>
                          <Button
                            onClick={() => handleShowConfirm(item.work_shift_id)}
                            className=' p-1 pt-0 pb-0'
                            style={{ fontSize: '12px', color: 'white' }}
                            variant=' btn-transparent'
                            size='sm'
                            >
                            <Delete/>
                          </Button>
                        </td>
                      </tr>
                    )
                    ) : (
                    <tr className='no-item'>
                      <td colSpan={3}>{TEXT.NOT_FOUND}</td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>

            <Pagination
              itemsCount={itemsCount}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              lastPage={lastPage}
              setCurrentPage={setCurrentPage}
              alwaysShown={false}
              pageClicked={(page: number) => { 
                  getShift(page);
                }
              }
              />

            <Dialog 
              show={showConfirm} 
              setShow={handleCloseConfirm}
              setEvent={() => { 
                  deleteShift(item.work_shift_id)
                }
              }
            />

            <Loader show={showLoading}/>
          </Card.Body>
        </Card>

        {/* ADD MODAL*/}
        <Formik
          initialValues={{
            shift_name: '',
            shift: [
              {
                day: 0,
                start_time: '',
                end_time: '',
                break_in: '',
                break_out: '',
                late_count_time: '',
                is_rest_day: []
              },
              {
                day: 1,
                start_time: '',
                end_time: '',
                break_in: '',
                break_out: '',
                late_count_time: '',
                is_rest_day: []
              },
              {
                day: 2,
                start_time: '',
                end_time: '',
                break_in: '',
                break_out: '',
                late_count_time: '',
                is_rest_day: []
              },
              {
                day: 3,
                start_time: '',
                end_time: '',
                break_in: '',
                break_out: '',
                late_count_time: '',
                is_rest_day: []
              },
              {
                day: 4,
                start_time: '',
                end_time: '',
                break_in: '',
                break_out: '',
                late_count_time: '',
                is_rest_day: []
              },
              {
                day: 5,
                start_time: '',
                end_time: '',
                break_in: '',
                break_out: '',
                late_count_time: '',
                is_rest_day: []
              },
              {
                day: 6,
                start_time: '',
                end_time: '',
                break_in: '',
                break_out: '',
                late_count_time: '',
                is_rest_day: []
              },
            ]
          }}
          validationSchema={validationSchema}
          onSubmit={postShift}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="shiftCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' size="xl" centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Add Work Shift</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Row>
                      <Col sm={5}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold'>Work Shift Name</Form.Label>
                          <Form.Control
                            size='sm' 
                            type='text' 
                            placeholder='Work shift name' 
                            name="shift_name"
                            value={values.shift_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={(touched.shift_name && !!errors.shift_name) || !!validation}
                            autoComplete='off'
                            autoFocus
                            />
                            {
                            validation != '' ?
                              (Object.keys(validation).map((message : any, index) => (
                                <Form.Control.Feedback type="invalid" key={index}>
                                  {validation[message][index]}
                                </Form.Control.Feedback>
                              ))
                              ) : (
                              <Form.Control.Feedback type="invalid">
                                {errors.shift_name}
                              </Form.Control.Feedback>
                              )
                            }
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                    <Form.Label className='fw-bold'>
                      Shift Schedule
                    </Form.Label>
                    <Table className='bordered-tabs-top mt-1' responsive>
                        <thead>
                          <tr className='text-center'>
                            <th>Day</th>
                            <th>Shift From</th>
                            <th>Shift To</th>
                            <th>Break Start</th>
                            <th>Break End</th>
                            <th>Late Count Time</th>
                            <th>Rest Day</th>
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name="shift"
                            render={() => (
                              <>
                                {values.shift.map((item, index) => (
                                  <tr className='mb-2' key={index}>
                                    {(() => {
                                      const ticketErrors = errors.shift?.length && errors.shift[index] || {};
                                      const ticketTouched = touched.shift?.length && touched.shift[index] || {};
                                      return(
                                        <>
                                          <td>
                                            {(() => {
                                              switch (item.day) {
                                                case 0:
                                                  return ('Sunday')
                                                case 1:
                                                  return ('Monday')
                                                case 2:
                                                  return ('Tuesday')
                                                case 3:
                                                  return ('Wednesday')
                                                case 4:
                                                  return ('Thursday')
                                                case 5:
                                                  return ('Friday')
                                                case 6:
                                                  return ('Saturday')
                                              }
                                            })()}
                                          </td>
                                          <td>
                                            <Form.Group className="position-relative">
                                              <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                  <Clock/>
                                                </InputGroup.Text>
                                                  <Form.Control
                                                    size={'sm'} 
                                                    type='time' 
                                                    placeholder='' 
                                                    name={`shift.${index}.start_time`}
                                                    value={item.start_time}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.start_time : false) && !!ticketTouched.start_time}
                                                    />
                                              </InputGroup>
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group className="position-relative">
                                              <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                  <Clock/>
                                                </InputGroup.Text>
                                                  <Form.Control
                                                    size={'sm'} 
                                                    type='time' 
                                                    placeholder='' 
                                                    name={`shift.${index}.end_time`}
                                                    value={item.end_time}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.end_time : false) && !!ticketTouched.end_time}
                                                    />
                                              </InputGroup>
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group className="position-relative">
                                              <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                  <Clock/>
                                                </InputGroup.Text>
                                                  <Form.Control
                                                    size={'sm'} 
                                                    type='time' 
                                                    placeholder='' 
                                                    name={`shift.${index}.break_in`}
                                                    value={item.break_in}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.break_in : false) && !!ticketTouched.break_in}
                                                    />
                                              </InputGroup>
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group className="position-relative">
                                              <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                  <Clock/>
                                                </InputGroup.Text>
                                                  <Form.Control
                                                    size={'sm'} 
                                                    type='time' 
                                                    placeholder='' 
                                                    name={`shift.${index}.break_out`}
                                                    value={item.break_out}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.break_out : false) && !!ticketTouched.break_out}
                                                    />
                                              </InputGroup>
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group className="position-relative">
                                              <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                  <Clock/>
                                                </InputGroup.Text>
                                                  <Form.Control
                                                    size={'sm'} 
                                                    type='time' 
                                                    placeholder='' 
                                                    name={`shift.${index}.late_count_time`}
                                                    value={item.late_count_time}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.late_count_time : false) && !!ticketTouched.late_count_time}
                                                    />
                                              </InputGroup>
                                            </Form.Group>
                                          </td>
                                          <td className='text-center'>
                                            <Form.Check 
                                              type="switch"
                                              id="custom-switch"
                                              name={`shift.${index}.is_rest_day`}
                                              onChange={handleChange}
                                              value="1"
                                              />
                                          </td>
                                        </>
                                      )
                                    })()}
                                  </tr>
                                ))}
                              </>
                            )}
                          />
                        </tbody>
                    </Table>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button 
                    variant='primary text-white' 
                    form="shiftCreate"  
                    className='mx-2' 
                    size={'sm'} 
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
          initialValues={{
            work_shift_id: item.work_shift_id,
            shift_name: item.shift_name,
            shift: item.shift_details
          }}
          validationSchema={validationSchema}
          onSubmit={updateShift}
          enableReinitialize
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="shiftEdit" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' size="xl" centered show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Edit Work Shift</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Row>
                      <Col sm={5}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold'>Work Shift Name</Form.Label>
                          <Form.Control
                            size='sm' 
                            type='text' 
                            placeholder='Work shift name' 
                            name="shift_name"
                            value={values.shift_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={(touched.shift_name && !!errors.shift_name) || !!validation}
                            autoComplete='off'
                            autoFocus
                            />
                            {
                            validation != '' ?
                              (Object.keys(validation).map((message : any, index) => (
                                <Form.Control.Feedback type="invalid" key={index}>
                                  {validation[message][index]}
                                </Form.Control.Feedback>
                              ))
                              ) : (
                              <Form.Control.Feedback type="invalid">
                                {errors.shift_name}
                              </Form.Control.Feedback>
                              )
                            }
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                    <Form.Label className='fw-bold'>
                      Shift Schedule
                    </Form.Label>
                    <Table className='bordered-tabs-top mt-1' responsive>
                        <thead>
                          <tr className='text-center'>
                            <th>Day</th>
                            <th>Shift From</th>
                            <th>Shift To</th>
                            <th>Break Start</th>
                            <th>Break End</th>
                            <th>Late Count Time</th>
                            <th>Rest Day</th>
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name="shift"
                            render={() => (
                              <>
                                {values.shift.map((item, index) => (
                                  <tr className='mb-2' key={index}>
                                    {(() => {
                                      const ticketErrors = errors.shift?.length && errors.shift[index] || {};
                                      const ticketTouched = touched.shift?.length && touched.shift[index] || {};
                                      return(
                                        <>
                                          <td>
                                            {(() => {
                                              switch (parseInt(item.day, 10)) {
                                                case 0:
                                                  return ('Sunday')
                                                case 1:
                                                  return ('Monday')
                                                case 2:
                                                  return ('Tuesday')
                                                case 3:
                                                  return ('Wednesday')
                                                case 4:
                                                  return ('Thursday')
                                                case 5:
                                                  return ('Friday')
                                                case 6:
                                                  return ('Saturday')
                                              }
                                            })()}
                                          </td>
                                          <td>
                                            <Form.Group className="position-relative">
                                              <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                  <Clock/>
                                                </InputGroup.Text>
                                                  <Form.Control
                                                    size={'sm'} 
                                                    type='time' 
                                                    placeholder='' 
                                                    name={`shift.${index}.start_time`}
                                                    value={item.start_time}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.start_time : false) && !!ticketTouched.start_time}
                                                    />
                                              </InputGroup>
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group className="position-relative">
                                              <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                  <Clock/>
                                                </InputGroup.Text>
                                                  <Form.Control
                                                    size={'sm'} 
                                                    type='time' 
                                                    placeholder='' 
                                                    name={`shift.${index}.end_time`}
                                                    value={item.end_time}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.end_time : false) && !!ticketTouched.end_time}
                                                    />
                                              </InputGroup>
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group className="position-relative">
                                              <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                  <Clock/>
                                                </InputGroup.Text>
                                                  <Form.Control
                                                    size={'sm'} 
                                                    type='time' 
                                                    placeholder='' 
                                                    name={`shift.${index}.break_in`}
                                                    value={item.break_in}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.break_in : false) && !!ticketTouched.break_in}
                                                    />
                                              </InputGroup>
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group className="position-relative">
                                              <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                  <Clock/>
                                                </InputGroup.Text>
                                                  <Form.Control
                                                    size={'sm'} 
                                                    type='time' 
                                                    placeholder='' 
                                                    name={`shift.${index}.break_out`}
                                                    value={item.break_out}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.break_out : false) && !!ticketTouched.break_out}
                                                    />
                                              </InputGroup>
                                            </Form.Group>
                                          </td>
                                          <td>
                                            <Form.Group className="position-relative">
                                              <InputGroup size={'sm'}>
                                                <InputGroup.Text id='basic-addon1'>
                                                  <Clock/>
                                                </InputGroup.Text>
                                                  <Form.Control
                                                    size={'sm'} 
                                                    type='time' 
                                                    placeholder='' 
                                                    name={`shift.${index}.late_count_time`}
                                                    value={item.late_count_time}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.late_count_time : false) && !!ticketTouched.late_count_time}
                                                    />
                                              </InputGroup>
                                            </Form.Group>
                                          </td>
                                          <td className='text-center'>
                                            <Form.Check 
                                              type="switch"
                                              id="custom-switch"
                                              name={`shift.${index}.is_rest_day`}
                                              onChange={handleChange}
                                              value="1"
                                              checked={item.is_rest_day == "1" ? true : false}
                                              />
                                          </td>
                                        </>
                                      )
                                    })()}
                                  </tr>
                                ))}
                              </>
                            )}
                          />
                        </tbody>
                    </Table>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                    Cancel
                  </Button>
                  <Button 
                    variant='primary text-white' 
                    form="shiftEdit"  
                    className='mx-2' 
                    size={'sm'} 
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>
        <br />
      </Container>
    </>
  )
}
