import * as yup from 'yup'
import { ERROR_MESSAGE } from 'utils/globalConstant'

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const validationSchema = yup.object({
    first_name: yup
        .string()
        .required(ERROR_MESSAGE.required)
        .max(30, ERROR_MESSAGE.max),
    last_name: yup
        .string()
        .required(ERROR_MESSAGE.required)
        .max(30, ERROR_MESSAGE.max),
    middle_name: yup
        .string()
        .max(30, ERROR_MESSAGE.max),
    contact_number: yup
        .string()
        .required(ERROR_MESSAGE.required)
        .matches(phoneRegExp, ERROR_MESSAGE.phone)
        .max(11, ERROR_MESSAGE.max),
    emergency_contacts: yup
        .string()
        .required(ERROR_MESSAGE.required)
        .matches(phoneRegExp, ERROR_MESSAGE.phone)
        .max(11, ERROR_MESSAGE.max),
    date_of_birth: yup
        .string()
        .required(ERROR_MESSAGE.required),
    gender: yup
        .string()
        .required(ERROR_MESSAGE.required),
    marital_status: yup
        .string()
        .required(ERROR_MESSAGE.required),
    religion: yup
        .string()
        .required(ERROR_MESSAGE.required)
        .max(50, ERROR_MESSAGE.max),
    address: yup
        .string()
        .required(ERROR_MESSAGE.required),
    email: yup
        .string()
        .required(ERROR_MESSAGE.required)
        .email(ERROR_MESSAGE.email)
})
