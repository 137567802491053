import React, {useEffect, useState} from 'react'
import {
  Button,
  Card,
  Col, Form, InputGroup, Modal,
  Row,
  Table
} from "react-bootstrap";
import CircleCreate from 'asset/SvgComponent/CircleCreate'
import Write from 'asset/SvgComponent/Write'
import Alert from 'components/Alert'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
  API_ENDPOINT,
  ERROR_MESSAGE, SUCCESS_MESSAGE
} from 'utils/globalConstant'
import { INotice, IList } from './types'
import {Formik} from "formik";
import {validationSchema} from "../../../Administration/NoticeBoard/validationSchema";
import Calendar from "asset/SvgComponent/Calendar";
import Attached from "asset/SvgComponent/Attached";
import {IItem} from "../../../Administration/NoticeBoard/types";

export default function Index() {
  const isRole = JSON.parse(localStorage.getItem('role') as string);
  const [error, setError] = React.useState('')
  const [showError, setShowError] = React.useState(false)
  const [lists, setData] = React.useState<IList[]>([])
  const [itemsCount, setItemCount] = React.useState(0)
  const [success, setSuccess] = React.useState('')
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showCreate, setShowCreate] = React.useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = () => setShowCreate(true)
  const [showEdit, setShowEdit] = React.useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)
  const [item, setItem] = useState<IItem>({
    notice_id: 0,
    title: '',
    description: '',
    status: '',
    publish_date: '',
    duration_from: '',
    duration_to: '',
    attach_file: ''
  })


  const handleNavigateNoticePage = () => {
    window.location.replace('/administration/notice')
  }

  const getNoticePublish = async () => {
    try {
      const response = await axios.get<IAPI_Response<INotice>>(API_ENDPOINT.GET_NOTICE_PUBLISH)
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setItemCount(result?.data?.results?.total)
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postNotice = async (value: any, {resetForm} : any) => {
    await axios.post(API_ENDPOINT.POST_NOTICE, {
      title: value.title,
      description: value.description,
      status: value.status,
      publish_date: value.publish_date,
      duration_from: value.duration_from,
      duration_to: value.duration_to
    }).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.NOTICE.CREATED)
        getNoticePublish();
      }
      resetForm({})
      handleCloseCreate();
    }).catch(() => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const updateNotice = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_NOTICE +'/'+ value.notice_id, {
      title: value.title,
      description: value.description,
      status: value.status,
      publish_date: value.publish_date,
      duration_from: value.duration_from,
      duration_to: value.duration_to,
      attach_file: value.attach_file
    }).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.NOTICE.UPDATED)
        getNoticePublish();
      }
      resetForm({})
      handleCloseEdit();
    }).catch(() => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const showNotice = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_NOTICE+'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getNoticePublish()
    })()
  }, [])

  return (
    <>
      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />

      <Card className="border-0 p-0 bordered-card" style={{overflowY: 'hidden', height: '325px'}}>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <span className="fw-bold">Notice Board</span>
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
              {
                isRole == "Admin" ? <Button className={"rounded me-0"} variant="transparent" size={"sm"} onClick={handleShowCreate}>
                  <CircleCreate/>
                </Button> : ""
              }

            </Col>
          </Row>

          <Table size="sm" responsive className="bordered-tabs-top mt-2">
            <tbody>
              <>
                {
                  lists.length > 0 ?
                    lists.map((item, index) =>
                      <tr className="text-truncate" key={index}>
                        <td className="fw-bold" colSpan={2}>
                          {item.title}
                          <br/>
                          <small
                            className="fw-light text-muted text-truncate"
                            style={{fontSize: '12px'}}
                            >
                            {item.description}
                          </small>
                        </td>
                        <td className="text-center text-truncate">
                          <Button
                            className="p-1 mt-2 pb-0"
                            style={{fontSize: '12px'}}
                            variant=" btn-transparent"
                            size="sm"
                            onClick={() => showNotice(item.notice_id)}
                            >
                            <Write/>
                          </Button>
                        </td>
                      </tr>
                    ) :
                      <tr>
                        <td colSpan={2} className="text-center">No notice yet.</td>
                      </tr>

                }
              </>
            </tbody>
          </Table>
          {itemsCount > 4 ? 
            (
            <div className="text-center mt-2">
              <h6 className="fw-bold text-muted text-truncate" style={{fontSize: '12px', cursor: 'pointer'}} onClick={handleNavigateNoticePage}>
                View All({itemsCount}) Notices
              </h6>
            </div>
            ) : ''
          }

        </Card.Body>
      </Card>

      {/* ADD MODAL*/}
      <Formik
          initialValues={
            {
              title: 'sfsfd',
              description: 'dasdas',
              status: '',
              publish_date: '',
              attach_file: '',
              duration_from: new Date().toISOString().substr(0, 10),
              duration_to:  new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().substr(0, 10)
            }
          }
          validationSchema={validationSchema}
          onSubmit={postNotice}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="noticeCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' show={showCreate} centered onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Add New Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label className='text-muted required'>Title</Form.Label>
                      <Form.Control
                          type='text'
                          placeholder='Title'
                          size={'sm'}
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.title && !!errors.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label className='text-muted required'>Description</Form.Label>
                      <Form.Control
                          as='textarea'
                          rows={3}
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.description && !!errors.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label className='text-muted required'>Status</Form.Label>
                      <Form.Select
                          size={'sm'}
                          name="status"
                          value={values.status}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.status && !!errors.status}
                      >
                        <option value="">Select Status</option>
                        <option value="Published">Published</option>
                        <option value="Unpublished">Unpublished</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-2' controlId='exampleForm.ControlInput1'>
                      <Form.Label className='text-muted required'>Publish Date</Form.Label>
                      <InputGroup className='mb-2 ' size={'sm'}>
                        <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                          <Calendar/>
                        </InputGroup.Text>
                        <Form.Control
                            size={'sm'}
                            type='date'
                            placeholder='Friday, June 03, 2022'
                            name="publish_date"
                            value={values.publish_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.publish_date && !!errors.publish_date}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.publish_date}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group controlId='formFile' className='mb-3'>
                      <Form.Label className='text-muted'>Attached file</Form.Label>
                      <InputGroup className='mb-3' hasValidation size={'sm'}>
                        <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                          <Attached/>
                        </InputGroup.Text>
                        <Form.Control type='file' size={'sm'} />
                        <Form.Control.Feedback type='invalid'>
                          Please upload at least one file.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className='mb-2' controlId='exampleForm.ControlInput2'>
                      <Row>
                        <Col>
                          <Form.Label className='text-muted required'>Duration From</Form.Label>
                          <InputGroup className='mb-2 ' size={'sm'}>
                            <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                              <Calendar/>
                            </InputGroup.Text>
                            <Form.Control
                                size={'sm'}
                                type='date'
                                placeholder='Friday, June 03, 2022'
                                name="duration_from"
                                value={values.duration_from}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.duration_from && !!errors.duration_from}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.duration_from}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                        <Col>
                          <Form.Label className='text-muted required'>Duration To</Form.Label>
                          <InputGroup className='mb-2 ' size={'sm'}>
                            <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                              <Calendar/>
                            </InputGroup.Text>
                            <Form.Control
                                size={'sm'}
                                type='date'
                                placeholder='Friday, June 03, 2022'
                                name="duration_to"
                                value={values.duration_to}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.duration_to && !!errors.duration_to}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.duration_to}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button
                      type="submit"
                      disabled={isSubmitting}
                      form="noticeCreate"
                      variant='primary text-white'
                      size={'sm'}
                      className='mx-2'
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
        )}
      </Formik>

      {/* EDIT MODAL*/}
      <Formik
          initialValues={item}
          validationSchema={validationSchema}
          onSubmit={updateNotice}
          enableReinitialize
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="noticeUpdated" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' show={showEdit} centered onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Edit Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label className='text-muted required'>Title</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Title'
                        size={'sm'}
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.title && !!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                    <Form.Label className='text-muted required'>Description</Form.Label>
                    <Form.Control
                        as='textarea'
                        rows={3}
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.description && !!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label className='text-muted required'>Status</Form.Label>
                    <Form.Select
                        size={'sm'}
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.status && !!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value="Published">Published</option>
                      <option value="Unpublished">Unpublished</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-2' controlId='exampleForm.ControlInput1'>
                    <Form.Label className='text-muted required'>Publish Date</Form.Label>
                    <InputGroup className='mb-2 ' size={'sm'}>
                      <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                        <Calendar/>
                      </InputGroup.Text>
                      <Form.Control
                          size={'sm'}
                          type='date'
                          placeholder='Friday, June 03, 2022'
                          name="publish_date"
                          value={values.publish_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.publish_date && !!errors.publish_date}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.publish_date}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId='formFile' className='mb-3'>
                    <Form.Label className='text-muted'>Attached file</Form.Label>
                    <InputGroup className='mb-3' hasValidation size={'sm'}>
                      <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                        <Attached/>
                      </InputGroup.Text>
                      <Form.Control type='file' size={'sm'} />
                      <Form.Control.Feedback type='invalid'>
                        Please upload at least one file.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className='mb-2' controlId='exampleForm.ControlInput2'>
                    <Row>
                      <Col>
                        <Form.Label className='text-muted required'>Duration From</Form.Label>
                        <InputGroup className='mb-2 ' size={'sm'}>
                          <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                            <Calendar/>
                          </InputGroup.Text>
                          <Form.Control
                              size={'sm'}
                              type='date'
                              placeholder='Friday, June 03, 2022'
                              name="duration_from"
                              value={values.duration_from}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.duration_from && !!errors.duration_from}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.duration_from}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                      <Col>
                        <Form.Label className='text-muted required'>Duration To</Form.Label>
                        <InputGroup className='mb-2 ' size={'sm'}>
                          <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                            <Calendar/>
                          </InputGroup.Text>
                          <Form.Control
                              size={'sm'}
                              type='date'
                              placeholder='Friday, June 03, 2022'
                              name="duration_to"
                              value={values.duration_to}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.duration_to && !!errors.duration_to}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.duration_to}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseEdit}>
                    Cancel
                  </Button>
                  <Button
                      type="submit"
                      disabled={isSubmitting}
                      form="noticeUpdated"
                      variant='primary text-white'
                      size={'sm'}
                      className='mx-2'
                  >
                    Update
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
        )}
      </Formik>

      <Alert
          type="success"
          show={showSuccess}
          message={success}
          setShow={() => {
            setShowSuccess(false);
          }
          }
      />

      <Alert
          type="error"
          show={showError}
          message={error}
          setShow={() => {
            setShowError(false);
          }
          }
      />

    </>
  );
}
