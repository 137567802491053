import React, {useEffect, useState} from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {Breadcrumb, Button, Card, Col, Container, Form, InputGroup, Modal, Row, Table} from 'react-bootstrap'
import Stack from '@mui/material/Stack'
import { Box, Paper } from '@mui/material'
import { AddCircleOutlineOutlined } from '@mui/icons-material'
import './style.css'
import axios from "../../../../api";
import {IAPI_Response} from "../../../../api/types";
import {API_ENDPOINT, ERROR_MESSAGE, SUCCESS_MESSAGE} from "../../../../utils/globalConstant";
import {IITem, IWithholdingTaxSetup} from "./types";
import Step1 from "../../../Register/RegisterBody/Step1";
import Step2 from "../../../Register/RegisterBody/Step2";
import moment from "moment/moment";
import Clock from "asset/SvgComponent/Clock";

export default function Index() {
  const [success, setSuccess] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)
  const [error, setError] = useState("")
  const [showError, setShowError] = useState(false)
  const [data, setData] = useState<IWithholdingTaxSetup[]>([])
  const [item, setItem] = useState<IWithholdingTaxSetup>({
    id: 0,
    payroll_cutoff_type: 0,
    amount_from: "",
    amount_to: "",
    base_amount_deduction: "",
    percentage_over: "",
    amount_over: "",
  })
  const [showUpdate, setShowUpdate] = useState(false)

  const handleCloseUpdate = () => setShowUpdate(false)

  const handleOpenUpdate = async() => {
    setShowUpdate(true)
  }

  const handleChangeWithholding = (e: any, type: string) => {
    if(type == "payroll_cutoff_type"){
      setItem({...item, payroll_cutoff_type: e.target.value})
    }else if(type == "amount_from"){
      setItem({...item, amount_from: e.target.value})
    }else if(type == "amount_to"){
      setItem({...item, amount_to: e.target.value})
    }else if(type == "base_amount_deduction"){
      setItem({...item, base_amount_deduction: e.target.value})
    }else if(type == "percentage_over"){
      setItem({...item, percentage_over: e.target.value})
    }else if(type == "amount_over"){
      setItem({...item, amount_over: e.target.value})
    }


  }

  const handleUpdate = async() => {
    await axios.put(API_ENDPOINT.UPDATE_WITHHOLDING_TAX_SETUP +'/'+ item.id, item).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.BRANCH.UPDATED)
        getWithholdingTaxSetup();
      }
      handleCloseUpdate();
    }).catch(error => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const showWithholdingTaxSetup = async(id: number) => {
    try {
      const response = await axios.get<IAPI_Response<IWithholdingTaxSetup>>(API_ENDPOINT.GET_WITHHOLDING_TAX_SETUP_BY_ID+"/"+id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
        handleOpenUpdate()
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
  }

  const getWithholdingTaxSetup = async() => {
    try {
      const response = await axios.get<IAPI_Response<IWithholdingTaxSetup[]>>(API_ENDPOINT.GET_WITHHOLDING_TAX_SETUP)
      const result = await response
      if (result?.data?.results?.length) {
        setData(result?.data?.results)
      }else{
        setData([])
      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getWithholdingTaxSetup()
    })()
  }, [])

  // const [key, setKey] = useState('personal-details');
  return (
    <Container fluid>
      <Row>
        <Col sm={6}>
          <h5 className='fw-bold'>Withholding Tax Setup</h5>
          <Breadcrumb className='breadcrumbs'>
            <Breadcrumb.Item className=' text-decoration-none ' href='#'>
              Setup
            </Breadcrumb.Item>
            <Breadcrumb.Item className=' text-decoration-none ' href='#'>
              Payroll
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Withholding Tax Setup</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col sm={6}>
          <Stack className='justify-content-end' direction='row' spacing={1}>
            {/* <Button variant="outlined" sx={{textTransform: 'none'}} color="inherit">*/}
            {/*    <AddCircleOutlineOutlined className="mx-1"/> Add new employee*/}
            {/* </Button>*/}
          </Stack>
        </Col>
      </Row>
      <br />

          <Card className='border-0  p-0 bordered-card'>
            <Card.Body>
              <span className='fw-bold'>Withholding Tax Table</span>
              <Table className={'mt-2'}>
                <thead>
                  <tr>
                    <th className='text-center'>Payroll Cutoff Type</th>
                    <th className='text-center'>Compensation Range</th>
                    <th className='text-center'>Prescribed Withholding Tax</th>
                    <th className='text-center'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.length > 0 ?
                        data.map((tax, index) => (
                            <tr key={index}>
                              <td className={'text-center'}>
                                {(() => {
                                  if(tax.payroll_cutoff_type == 3){
                                    return "Semi-monthly"
                                  }else if(tax.payroll_cutoff_type == 4){
                                    return "Monthly"
                                  }
                                })()}
                              </td>
                              <td className={'text-center'}>
                                {(() => {
                                  if(tax.amount_from == "0.00"){
                                    return "₱ " + Number(tax.amount_to).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " and below"
                                  }else if(tax.amount_to == "0.00"){
                                    return "₱ " + Number(tax.amount_from).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " and above"
                                  }else{
                                    return "₱ " + Number(tax.amount_from).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " - ₱ " + Number(tax.amount_to).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                })()}
                              </td>
                              <td className={'text-center'}>
                                {(() => {
                                  if(tax.percentage_over == "0.00" && tax.amount_over == "0.00"){
                                    return "₱ 0.00"
                                  }else{
                                    return "₱ " + Number(tax.base_amount_deduction).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " + " + Number(tax.percentage_over).toFixed(0)+"% over " + "₱ " + Number(tax.amount_over).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                })()}
                              </td>
                              <td className='text-center'>
                                <Button variant={'warning'} size={'sm'} className='text-white update-btn' onClick={() => showWithholdingTaxSetup(tax.id)}>
                                  Update
                                </Button>
                              </td>
                            </tr>
                        ))
                        : ""
                  }


                </tbody>
              </Table>
            </Card.Body>
          </Card>

      {/* UPDATE MODAL*/}
      <Modal className='modal' centered show={showUpdate} onHide={handleCloseUpdate}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>Edit Withholding Tax Setup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Payroll Cutoff Type</Form.Label>
                <Form.Select
                    size={'sm'}
                    name="payroll_cutoff_type"
                    onChange={event => {
                      handleChangeWithholding(event, "payroll_cutoff_type");
                    }}
                    value={item.payroll_cutoff_type}
                >
                  <option value="0" disabled>Select Cutoff Type</option>
                  <option value="1">Daily</option>
                  <option value="2">Weekly</option>
                  <option value="3">Semi-Monthly</option>
                  <option value="4">Monthly</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Base Amount Deduction</Form.Label>
                <Form.Control
                    className={"text-right"}
                    size={'sm'}
                    type='text'
                    placeholder='0'
                    name={'base_amount_deduction'}
                    value={item.base_amount_deduction}
                    onChange={event => {
                      handleChangeWithholding(event, "base_amount_deduction");
                    }}

                />
              </Form.Group>
            </Row>
            <Row className={"mt-4"}>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Range From</Form.Label>
                <Form.Control
                    className={"text-right"}
                    size={'sm'}
                    type='text'
                    placeholder='0'
                    name={'amount_from'}
                    value={item.amount_from}
                    onChange={event => {
                      handleChangeWithholding(event, "amount_from");
                    }}

                />
              </Form.Group>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Range To</Form.Label>
                <Form.Control
                    className={"text-right"}
                    size={'sm'}
                    type='text'
                    placeholder='0'
                    name={'amount_to'}
                    value={item.amount_to}
                    onChange={event => {
                      handleChangeWithholding(event, "amount_to");
                    }}

                />
              </Form.Group>
            </Row>
            <Row className={"mt-4"}>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Percentage Over</Form.Label>
                <Form.Control
                    className={"text-right"}
                    size={'sm'}
                    type='text'
                    placeholder='0'
                    name={'percentage_over'}
                    value={item.percentage_over}
                    onChange={event => {
                      handleChangeWithholding(event, "percentage_over");
                    }}

                />
              </Form.Group>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Amount Over</Form.Label>
                <Form.Control
                    className={"text-right"}
                    size={'sm'}
                    type='text'
                    placeholder='0'
                    name={'amount_over'}
                    value={item.amount_over}
                    onChange={event => {
                      handleChangeWithholding(event, "amount_over");
                    }}

                />
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-primary' size={'sm'} onClick={handleCloseUpdate}
          >
            Cancel
          </Button>
          <Button
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
              onClick={handleUpdate}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}
