

import React from 'react'

const PersonIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58.533" height="58.352" viewBox="0 0 18.533 15.352">
        <g id="Employee" transform="translate(0.5 0.506)">
            <path id="Path_7" data-name="Path 7" d="M14.252,27.282V25.688A3.188,3.188,0,0,0,11.064,22.5H4.688A3.188,3.188,0,0,0,1.5,25.688v1.594" transform="translate(-1.5 -12.936)" fill="none" stroke="#F26930" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path><path id="Path_8" data-name="Path 8" d="M13.876,7.688A3.188,3.188,0,1,1,10.688,4.5,3.188,3.188,0,0,1,13.876,7.688Z" transform="translate(-4.313 -4.5)" fill="none" stroke="#F26930" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path>
        </g>
    </svg>
  )
}

export default PersonIcon
