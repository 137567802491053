import React, { useEffect, useState } from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
  Stack
} from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Accept from 'asset/SvgComponent/Accept'
import Reject from 'asset/SvgComponent/Reject'
import View from 'asset/SvgComponent/View'
import Delete from 'asset/SvgComponent/Delete'
import Write from 'asset/SvgComponent/Write'
import EditIcon from 'asset/SvgComponent/EditIcon'
import Calendar from 'asset/SvgComponent/Calendar'
import Clock from 'asset/SvgComponent/Clock'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { Avatar } from '@mui/material'

import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import { Formik } from 'formik';
import { validationSchema } from './validationSchema'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
  API_ENDPOINT,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  TEXT
} from 'utils/globalConstant'
import {IOvertime, IList, IItem, IItemRequest, IOvertimeRequest, IListRequest, IShift} from './types'
import moment from "moment"
import Select from "components/ReactSelect";
import {IEmployee} from "../../AttendanceReport/Construction/types";
import AcceptDisabled from "asset/SvgComponent/AcceptDisabledIcon";
import RejectDisabled from "asset/SvgComponent/RejectDisabledIcon";

export default function Index() {
  const [key, setKey] = useState('overtime-application')
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [currentPage1, setCurrentPage1] = useState(0)
  const [lastPage1, setLastPage1] = useState(0)
  const [itemsPerPage1, setPerPage1] = useState(10)
  const [itemsCount1, setItemCount1] = useState(0)
  const [from1, setFrom1] = useState(0)

  const [showCreate, setShowCreate] = useState(false)
  const handleCloseCreate = () => setShowCreate(false)
  const handleShowCreate = async () => {
    setShowCreate(true)
    getAllEmployee()
  }

  const [showEdit, setShowEdit] = useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const [showConfirm, setShowConfirm] = useState(false)
  const handleCloseConfirm = () => setShowConfirm(false)
  const handleShowConfirm = (id: any) => {
    setShowConfirm(true)
    setItem({
      id: id,
      employee_id: 0,
      classification: '',
      overtime_date: '',
      overtime_start: '',
      overtime_end: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      first_name: '',
      last_name: ''
    })
  }

  const [showDetail, setShowDetail] = useState(false)
  const handleCloseDetail = () => setShowDetail(false)
  const handleShowDetail = () => setShowDetail(true)

  const [showAccept, setShowAccept] = useState(false)
  const handleCloseAccept = () => setShowAccept(false)
  const handleShowAccept = (id: any) => {
    setShowAccept(true)
    setItemRequest({
      id: id,
      employee_id: 0,
      classification: '',
      overtime_date: '',
      overtime_start: '',
      overtime_end: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: '',
        approvers_order: ''
      }],
      created_by_last_name: "",
      created_by_first_name: "",
      approvers_order: 0,
      levels_of_approval: [
        {
          approvers_order: "",
          level: ""
        }
      ],
      current: ''
    })
  }

  const [showReject, setShowReject] = useState(false)
  const handleCloseReject = () => setShowReject(false)
  const handleShowReject = (id: any) => {
    setShowReject(true)
    setItemRequest({
      id: id,
      employee_id: 0,
      classification: '',
      overtime_date: '',
      overtime_start: '',
      overtime_end: '',
      reason: '',
      application_date: '',
      remarks: '',
      status: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      request_status: [{
        status: '',
        approve_date: '',
        reject_date: '',
        employee: {
          employee_id: 0,
          first_name: '',
          last_name: '',
          user_name: {
            role: {
              role_name: ''
            }
          },
          photo: ""
        },
        level: '',
        approvers_order: ''
      }],
      created_by_last_name: "",
      created_by_first_name: "",
      approvers_order: 0,
      levels_of_approval: [
        {
          approvers_order: "",
          level: ""
        }
      ],
      current: ''
    })
  }

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const [lists, setData] = useState<IList[]>([])
  const [requests, setRequest] = useState<IListRequest[]>([])
  const [item, setItem] = useState<IItem>({
    id: 0,
    employee_id: 0,
    classification: '',
    overtime_date: '',
    overtime_start: '',
    overtime_end: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    first_name: '',
    last_name: ''
  })
  const [itemRequest, setItemRequest] = useState<IItemRequest>({
    id: 0,
    employee_id: 0,
    classification: '',
    overtime_date: '',
    overtime_start: '',
    overtime_end: '',
    reason: '',
    application_date: '',
    remarks: '',
    status: '',
    employee: {
      employee_id: 0,
      first_name: '',
      last_name: '',
      user_name: {
        role: {
          role_name: ''
        }
      },
      photo: ""
    },
    request_status: [{
      status: '',
      approve_date: '',
      reject_date: '',
      employee: {
        employee_id: 0,
        first_name: '',
        last_name: '',
        user_name: {
          role: {
            role_name: ''
          }
        },
        photo: ""
      },
      level: '',
      approvers_order: ''
    }],
    created_by_last_name: "",
    created_by_first_name: "",
    approvers_order: 0,
    levels_of_approval: [
      {
        approvers_order: "",
        level: ""
      }
    ],
    current: ''
  })
  const [dateFormat, setDateFormat] = useState('')
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [selectedEmployeeOptionEditOvertime, setSelectedEmployeeOptionEditOvertime] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
  const [disableSelect, setDisableSelect] = useState(false)
  const [addOvertimeInitialValues, setAddOvertimeInitialValues] = useState({overtime_date: '',
    shift_from: "",
    shift_to: "",
    overtime_start: '',
    overtime_end: '',
    employee_id: '',
    reason: ''
  })
  const [updatedOvertime, setUpdatedOvertime] = useState({
    overtime_start: "",
    overtime_end: ""
  })
  const [showAcceptRejectButton, setShowAcceptRejectButton] = useState(true)

  const handleDisableAcceptRejectButton = (item: any) => {
    if(item.status == '3' || item.status == '2'){
      return true
    }else if(item.current == '0' || item.current == '2'){
      return true
    }else{
      return false
    }

  }

  const handleChangeOvertimeStart = (e: any) => {
    setUpdatedOvertime({...updatedOvertime, overtime_start: e.target.value})
  }

  const handleChangeOvertimeEnd = (e: any) => {
    setUpdatedOvertime({...updatedOvertime, overtime_end: e.target.value})
  }

  const handleChangeDropdownOverview = (selectedOption: any) => {
    setSelectedEmployeeOption(selectedOption)
  }

  const getEmployeeShift = async (employee_id: number, date: string, values: any) => {

    if(employee_id != 0 && date != ""){
      try {
        const response = await axios.get<IAPI_Response<IShift>>(API_ENDPOINT.GET_EMPLOYEE_SHIFT_FOR_MFBC + "/" +employee_id,{
          params: {
            date: date
          },
        } )
        const result = await response
        const shifts = result?.data?.results

        if(shifts?.id){

          setAddOvertimeInitialValues({
            overtime_date: date,
            shift_from: shifts?.start_from,
            shift_to: shifts?.end_to,
            overtime_start: values.overtime_start,
            overtime_end: values.overtime_end,
            employee_id: values.employee_id,
            reason: values.reason
          })
        }else{

          setAddOvertimeInitialValues({
            overtime_date: date,
            shift_from: "",
            shift_to: "",
            overtime_start: values.overtime_start,
            overtime_end: values.overtime_end,
            employee_id: values.employee_id,
            reason: values.reason
          })
        }

      } catch {
        setShowError(true)
        setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }
    }


    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getAllEmployee = async () => {
    try {
      const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
      const result = await res
      if (result?.data?.results?.length) {
        const employees = result?.data?.results;
        if(JSON.parse(localStorage.getItem('role') ?? "") != "Admin" &&
            JSON.parse(localStorage.getItem('role') ?? "") != "Supervisor"){

          employees.map(employee => {
            if(employee.employee_id == Number(localStorage.getItem('employee_id'))){
              setSelectedEmployeeOption({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
              setDisableSelect(true)
              setEmployeeOptions([])
              setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
              setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
            }
          })
        }else{
          setDisableSelect(false)
          setEmployeeOptions([])
          setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
          employees.map(employee => {
            setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
          })

        }

      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getOvertime = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IOvertime>>(API_ENDPOINT.GET_OVERTIME, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setData(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showOvertime = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_OVERTIME +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
        setSelectedEmployeeOptionEditOvertime({label: result?.data?.results?.first_name + " " + result?.data?.results?.last_name, value: result?.data?.results?.employee_id})
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const postOvertime = async (value: any, {resetForm} : any) => {
    if(selectedEmployeeOption.value != 0){
      await axios.post(API_ENDPOINT.POST_OVERTIME_FOR_MFBC, {
        employee_id: selectedEmployeeOption.value,
        overtime_date: value.overtime_date,
        overtime_start: value.overtime_start,
        overtime_end: value.overtime_end,
        classification: "Normal OT",
        reason: value.reason,
      }).then(response => {
        if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OVERTIME.CREATED)
          getOvertime(null);
          getOvertimeRequest(null)
        }
        resetForm({})
        handleCloseCreate();
      });
    }else{
      setError("Please select employee.")
      setShowError(true)
    }


    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const updateOvertime = async (value: any, {resetForm} : any) => {
    await axios.put(API_ENDPOINT.PUT_OVERTIME +'/'+ value.id, {
      overtime_date: value.overtime_date,
      overtime_start: value.overtime_start,
      overtime_end: value.overtime_end,
      reason: value.reason,
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OVERTIME.UPDATED)
          getOvertime(currentPage);
       }
       resetForm({})
       handleCloseEdit();
    });

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const deleteOvertime = async (id: null | number) => {
    try {
      const response = await axios.delete(API_ENDPOINT.DELETE_OVERTIME +'/'+ id)
      const result = await response
      if (result?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.OVERTIME.DELETED)
        getOvertime(currentPage);
     }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }
    handleCloseConfirm()
    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getOvertimeRequest = async (page_number: null | number) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IOvertimeRequest>>(API_ENDPOINT.GET_OVERTIME_REQUEST_FOR_MFBC, {
        params: {
          page: page_number,
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setRequest(result?.data?.results?.data)
        setCurrentPage1(result?.data?.results?.current_page)
        setLastPage1(result?.data?.results?.last_page)
        setPerPage1(result?.data?.results?.per_page)
        setItemCount1(result?.data?.results?.total)
        setFrom1(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const showOvertimeRequest = async (id: null | number) => {
    try {
      const response = await axios.get<IAPI_Response<IItemRequest>>(API_ENDPOINT.SHOW_OVERTIME_REQUEST_FOR_MFBC +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItemRequest(result?.data?.results)
      }
      handleShowDetail()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const acceptOvertimeRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.ACCEPT_OVERTIME_REQUEST_FOR_MFBC +'/'+ id, {
      overtime_start: updatedOvertime.overtime_start,
      overtime_end: updatedOvertime.overtime_end
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OVERTIME.ACCEPTED)
          getOvertimeRequest(currentPage1);
          getOvertime(currentPage)
       }
       handleCloseAccept();
       handleCloseDetail();
    });

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const rejectOvertimeRequest = async (id: null | number) => {
    await axios.put(API_ENDPOINT.REJECT_OVERTIME_REQUEST_FOR_MFBC +'/'+ id, {
    }).then(response => {
       if (response?.data?.status * 1 === 1) {
          setShowSuccess(true)
          setSuccess(SUCCESS_MESSAGE.OVERTIME.REJECTED)
          getOvertimeRequest(currentPage1);
          getOvertime(currentPage)
       }
       handleCloseReject();
       handleCloseDetail();
    });

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getOvertime(null)
      getOvertimeRequest(null)
      setDateFormat(localStorage.getItem('date_format') ?? "")
    })()
  }, [])

  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Overtime</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/tax-rule-setup'>
            Attendance
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Overtime</Breadcrumb.Item>
        </Breadcrumb>

        <Alert
          type="success"
          show={showSuccess}
          message={success}
          setShow={() => {
              setShowSuccess(false);
            }
          }
        />

        <Alert
          type="error"
          show={showError}
          message={error}
          setShow={() => {
            setShowError(false);
            }
          }
        />

        <Tabs
          variant={'tabs'}
          className='tabs '
          id='controlled-tab-example'
          activeKey={key}
          onSelect={(k: any) => {
            setKey(k)
          }}
          >
          <Tab eventKey='overtime-application' title='Application'>
            <Card className='border-0 p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Overtime Application</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25 mx-2'}>
                      <Form.Control
                        aria-label='search'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                    <Button
                      onClick={handleShowCreate}
                      className=' add-new-btn rounded text-white'
                      variant='warning'
                      >
                      <EditIcon/>
                      &nbsp;Apply for Overtime
                    </Button>{' '}
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table className='bordered-tabs-top mt-3' responsive>
                  <thead>
                    <tr className='text-center'>
                      <th style={{width:'4%'}}>No.</th>
                      <th>Application Date</th>
                      <th style={{width: '12%'}}>Employee</th>
                      <th>Overtime Date</th>
                      <th>Overtime In</th>
                      <th>Overtime Out</th>
                      <th style={{width: '15%'}}>Reason</th>
                      <th style={{width: '12%'}}>Applied By</th>
                      <th style={{ width: '50px' }}>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {lists.length > 0 ? (
                        lists.map((item, index) =>
                          <tr className='mb-2' key={index}>
                            <td>{from + index}</td>
                            <td className="text-center">{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                            <td className='fw-bold'>{item.first_name + " " + item.last_name}</td>
                            <td className="text-center">{moment(new Date(item.overtime_date ?? "")).format(dateFormat)}</td>
                            <td className="text-center">{item.overtime_start}</td>
                            <td className="text-center">{item.overtime_end}</td>
                            <td className="display-reason" title={item.reason ?? ""}>{item.reason}</td>
                            <td className='fw-bold'>{item.created_by_first_name + " " + item.created_by_last_name}</td>
                            <td className='text-center'>
                              <div className='d-grid'>
                                <>
                                  {(() => {
                                    switch (item.status) {
                                      case '1':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-secondary'
                                            size='sm'
                                            >
                                            Pending
                                          </Button>
                                        )
                                      case '2':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-danger'
                                            size='sm'
                                            >
                                            Disapproved
                                          </Button>
                                        )
                                      case '3':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-success'
                                            size='sm'
                                            >
                                            Approved
                                          </Button>
                                        )
                                    }
                                  })()}
                                </>
                              </div>
                            </td>
                            <td className='text-center'>
                              <Button
                                  onClick={() => {
                                    showOvertimeRequest(item.id)
                                    setShowAcceptRejectButton(false)
                                  }}
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                              >
                                <View/>
                              </Button>
                              <Button
                                onClick={() => showOvertime(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                disabled={item.status == '3' ? true: false}
                                >
                                <Write/>
                              </Button>
                              <Button
                                onClick={() => handleShowConfirm(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                disabled={item.status == '3' ? true: false}
                                >
                                <Delete/>
                              </Button>
                            </td>
                          </tr>
                        )
                        ) : (
                        <tr className='no-item'>
                          <td colSpan={10}>{TEXT.NOT_FOUND}</td>
                        </tr>
                      )}
                    </>
                  </tbody>
                </Table>

                <Pagination
                  itemsCount={itemsCount}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  lastPage={lastPage}
                  setCurrentPage={setCurrentPage}
                  alwaysShown={false}
                  pageClicked={(page: number) => {
                      getOvertime(page);
                    }
                  }
                  />

                <Dialog
                  show={showConfirm}
                  setShow={handleCloseConfirm}
                  setEvent={() => {
                      deleteOvertime(item.id)
                    }
                  }
                />

                <Loader show={showLoading}/>
              </Card.Body>
            </Card>
          </Tab>

          <Tab eventKey='overtime-approval' title='Approval'>
            <Card className='border-0 p-0 bordered-card-tabs'>
              <Card.Body>
                <Row>
                  <Col sm={6}>
                    <span className='fw-bold'>Overtime Approval</span>
                  </Col>
                  <Col sm={6} className='d-flex justify-content-end hidden'>
                    <InputGroup size='sm' className={'w-25'}>
                      <Form.Control
                        aria-label='search'
                        aria-describedby='inputGroup-sizing-sm'
                        placeholder='Search...'
                      />
                      <InputGroup.Text className='bg-transparent search-input '>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Row>
                {/* <hr/>*/}
                <Table className='bordered-tabs-top mt-3' responsive>
                  <thead>
                    <tr className='text-center'>
                      <th>No.</th>
                      <th>Application Date</th>
                      <th style={{ width: '12%' }}>Employee</th>
                      <th>Overtime Date</th>
                      <th>Overtime In</th>
                      <th>Overtime Out</th>
                      <th style={{ width: '15%' }}>Reason</th>
                      <th style={{ width: '12%' }}>Applied By</th>
                      <th style={{ width: '50px' }}>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {requests.length > 0 ? (
                        requests.map((item, index) =>
                          <tr className='mb-2' key={index}>
                            <td>{from1 + index}</td>
                            <td className="text-center">{moment(new Date(item.application_date ?? "")).format(dateFormat)}</td>
                            <td>
                              <Stack direction='horizontal'>
                                <div>
                                  {
                                    item.photo != "" ?
                                        <Avatar
                                            sx={{ width: 20, height: 20 }}
                                            src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                        ></Avatar> :
                                        <Avatar
                                            sx={{ width: 20, height: 20}}
                                            src={""}
                                        ></Avatar>
                                  }
                                </div>
                                <div>&nbsp;{item.first_name} {item.last_name}</div>
                              </Stack>
                            </td>
                            <td className="text-center">{moment(new Date(item.overtime_date ?? "")).format(dateFormat)}</td>
                            <td className="text-center">{item.overtime_start}</td>
                            <td className="text-center">{item.overtime_end}</td>
                            <td className="display-reason" title={item.reason ?? ""}>{item.reason}</td>
                            <td>{item.created_by_first_name} {item.created_by_last_name}</td>
                            <td className='text-center'>
                              <div className='d-grid'>
                                <>
                                  {(() => {
                                    switch (item.status) {
                                      case '1':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-secondary'
                                            size='sm'
                                            >
                                            Pending
                                          </Button>
                                        )
                                      case '2':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-danger'
                                            size='sm'
                                            >
                                            Disapproved
                                          </Button>
                                        )
                                      case '3':
                                        return (
                                          <Button
                                            className=' p-1 pt-0 pb-0'
                                            style={{ fontSize: '12px', color: 'white' }}
                                            variant=' btn-success'
                                            size='sm'
                                            >
                                            Approved
                                          </Button>
                                        )
                                    }
                                  })()}
                                </>
                              </div>
                            </td>
                            <td className='text-center'>
                              <Button
                                onClick={() => {
                                  showOvertimeRequest(item.id)
                                  setShowAcceptRejectButton(true)
                                }}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                >
                                <View/>
                              </Button>
                              <Button
                                  onClick={() => handleShowAccept(item.id)}
                                  className=' p-1 pt-0 pb-0'
                                  style={{ fontSize: '12px', color: 'white' }}
                                  variant=' btn-transparent'
                                  size='sm'
                                  disabled={handleDisableAcceptRejectButton(item)}
                              >
                                {
                                  handleDisableAcceptRejectButton(item) ?
                                      <AcceptDisabled/> : <Accept/>
                                }
                              </Button>
                              <Button
                                onClick={() => handleShowReject(item.id)}
                                className=' p-1 pt-0 pb-0'
                                style={{ fontSize: '12px', color: 'white' }}
                                variant=' btn-transparent'
                                size='sm'
                                disabled={handleDisableAcceptRejectButton(item)}
                                >
                                {
                                  handleDisableAcceptRejectButton(item) ?
                                      <RejectDisabled/> : <Reject/>
                                }
                              </Button>
                            </td>
                          </tr>
                        )
                        ) : (
                        <tr className='no-item'>
                          <td colSpan={10}>{TEXT.NOT_FOUND}</td>
                        </tr>
                      )}
                    </>
                  </tbody>
                </Table>
                  <Pagination
                    itemsCount={itemsCount1}
                    itemsPerPage={itemsPerPage1}
                    currentPage={currentPage1}
                    lastPage={lastPage1}
                    setCurrentPage={setCurrentPage1}
                    alwaysShown={false}
                    pageClicked={(page: number) => {
                        getOvertimeRequest(page);
                      }
                    }
                    />

                  <Dialog
                    show={showAccept}
                    setShow={handleCloseAccept}
                    setEvent={() => {
                        acceptOvertimeRequest(itemRequest.id)
                      }
                    }
                    variant='accept'
                    message='Please confirm to accept overtime request?'
                  />

                  <Dialog
                    show={showReject}
                    setShow={handleCloseReject}
                    setEvent={() => {
                        rejectOvertimeRequest(itemRequest.id)
                      }
                    }
                    variant='reject'
                    message='Do you really want to reject these overtime request?'
                  />

                  <Loader show={showLoading}/>

              </Card.Body>
            </Card>
          </Tab>
        </Tabs>

        {/* ADD MODAL*/}
        <Formik
            enableReinitialize
          initialValues={addOvertimeInitialValues}
          validationSchema={validationSchema}
          onSubmit={postOvertime}
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="overtimeCreate" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' size='lg' centered show={showCreate} onHide={handleCloseCreate}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Overtime Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold form-labels required'>Employees</Form.Label>
                            <Select
                                name="employee_id"
                                value={selectedEmployeeOption}
                                options={employeeOptions}
                                onChange={(e:any )=> {
                                  handleChangeDropdownOverview(e)
                                  getEmployeeShift(e.value, values.overtime_date, values)

                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.employee_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Shift Date </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control
                                size='sm'
                                type='date'
                                placeholder=''
                                name="overtime_date"
                                value={values.overtime_date}
                                onChange={e => {
                                  handleChange(e)
                                  getEmployeeShift(selectedEmployeeOption.value, e.target.value, values)

                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.overtime_date && !!errors.overtime_date}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.overtime_date}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>

                      </Row>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label className='fw-bold'>
                        Pre-Approved Overtime Hours
                      </Form.Label>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Shift From </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control
                                  size={'sm'}
                                  type='time'
                                  placeholder=''
                                  value={values.shift_from}
                                  disabled={true}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Shift To </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control
                                  size='sm'
                                  type='time'
                                  placeholder=''
                                  value={values.shift_to}
                                  disabled={true}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Start</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control
                                size='sm'
                                type='time'
                                placeholder=''
                                name="overtime_start"
                                value={values.overtime_start}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.overtime_start && !!errors.overtime_start}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.overtime_start}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>End</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control
                                size='sm'
                                type='time'
                                placeholder=''
                                name="overtime_end"
                                value={values.overtime_end}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.overtime_end && !!errors.overtime_end}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.overtime_end}
                                </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Label className='fw-bold required'>
                            Reason
                          </Form.Label>
                          <Form.Control
                              as='textarea'
                              placeholder='Type your reason here...'
                              style={{fontSize: '90%'}}
                              rows={3}
                              name="reason"
                              value={values.reason}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.reason && !!errors.reason}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.reason}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseCreate}>
                    Cancel
                  </Button>
                  <Button
                    variant='primary text-white'
                    form="overtimeCreate"
                    className='mx-2'
                    size={'sm'}
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Send Application
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* EDIT MODAL*/}
        <Formik
          initialValues={{
            overtime_date: item.overtime_date,
            shift_from: '',
            shift_to: '',
            overtime_start: item.overtime_start,
            overtime_end: item.overtime_end,
            classification: item.classification,
            reason: item.reason,
            employee_id: selectedEmployeeOptionEditOvertime.value,
            id: item.id
          }}
          validationSchema={validationSchema}
          onSubmit={updateOvertime}
          enableReinitialize
          >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (

            <Form id="overtimeEdit" noValidate onSubmit={handleSubmit}>
              <Modal className='modal' size='lg' centered show={showEdit} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                  <Modal.Title className='fw-bold'>Edit Overtime</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold form-labels required'>Employees</Form.Label>
                            <Select
                                name="employee_id"
                                value={selectedEmployeeOptionEditOvertime}
                                options={employeeOptions}
                                onChange={(e:any )=> {
                                  handleChangeDropdownOverview(e)
                                  getEmployeeShift(e.value, values.overtime_date, values)

                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.employee_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Shift Date </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Calendar/>
                              </InputGroup.Text>
                              <Form.Control
                                  size='sm'
                                  type='date'
                                  placeholder=''
                                  name="overtime_date"
                                  value={values.overtime_date}
                                  onChange={e => {
                                    handleChange(e)
                                    getEmployeeShift(selectedEmployeeOption.value, e.target.value, values)

                                  }}
                                  onBlur={handleBlur}
                                  isInvalid={touched.overtime_date && !!errors.overtime_date}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.overtime_date}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label className='fw-bold'>
                        Pre-Approved Overtime Hours
                      </Form.Label>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Row>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Shift From </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control
                                  size={'sm'}
                                  type='time'
                                  placeholder=''
                                  value={values.shift_from}
                                  disabled={true}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Shift To </Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control
                                  size='sm'
                                  type='time'
                                  placeholder=''
                                  value={values.shift_to}
                                  disabled={true}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>Start</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control
                                  size='sm'
                                  type='time'
                                  placeholder=''
                                  name="overtime_start"
                                  value={values.overtime_start}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={touched.overtime_start && !!errors.overtime_start}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.overtime_start}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold required'>End</Form.Label>
                            <InputGroup size={'sm'}>
                              <InputGroup.Text id='basic-addon1' className='bg-transparent'>
                                <Clock/>
                              </InputGroup.Text>
                              <Form.Control
                                  size='sm'
                                  type='time'
                                  placeholder=''
                                  name="overtime_end"
                                  value={values.overtime_end}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={touched.overtime_end && !!errors.overtime_end}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.overtime_end}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Row>
                        <Col sm={6}>
                          <Form.Label className='fw-bold required'>
                            Reason
                          </Form.Label>
                          <Form.Control
                              as='textarea'
                              placeholder='Type your reason here...'
                              style={{fontSize: '90%'}}
                              rows={3}
                              name="reason"
                              value={values.reason}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.reason && !!errors.reason}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.reason}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>

                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
                    Cancel
                  </Button>
                  <Button
                    variant='primary text-white'
                    form="overtimeEdit"
                    className='mx-2'
                    size={'sm'}
                    type="submit"
                    disabled={isSubmitting}
                    >
                    Send Application
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>

        {/* DETAIL MODAL*/}
        <Modal className='modal' centered size={'lg'} show={showDetail} onHide={handleCloseDetail}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>
              <Stack direction='horizontal'>
                <div>
                  {
                    itemRequest.employee.photo != "" ?
                        <Avatar
                            sx={{ width: 65, height: 65 }}
                            src={process.env.REACT_APP_IMAGE_URL+ itemRequest.employee.photo}
                        ></Avatar> :
                        <Avatar
                            sx={{ width: 65, height: 65}}
                            src={""}
                        ></Avatar>
                  }
                </div>
                <div className='mx-3'>
                  &nbsp;{itemRequest.employee?.first_name} {itemRequest.employee?.last_name}
                  <br />
                  &nbsp;<span className='text-muted fw-light'>{itemRequest.employee?.user_name?.role?.role_name}</span>
                </div>
              </Stack>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                    <tr className='lh-1'>
                      <td className='fw-bold' width={120}>
                        Applied By:
                      </td>
                      <td>{itemRequest.created_by_first_name} {itemRequest.created_by_last_name}</td>
                    </tr>
                    <tr className='lh-1'>
                      <td className='fw-bold' width={120}>
                        Applied On:
                      </td>
                      <td>{itemRequest.application_date}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col sm={6}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <td className='fw-bold' width={120}>
                      Classification
                    </td>
                    <td>{itemRequest.classification}</td>
                  </tr>
                    <tr className='lh-1'>
                      <td className='fw-bold' width={120}>
                        Reason:
                      </td>
                      <td>{itemRequest.reason}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr/>
            <Row style={{marginTop: '-15px'}}>
              <Table borderless className='mt-0 mb-0'>
                <tbody>
                <tr className='lh-1'>
                  <th className='text-center'>Overtime Request</th>
                </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col sm={12}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody>
                    <tr>
                      <th>Overtime Date</th>
                      <th>Applied Overtime In</th>
                      <th>Applied Overtime Out</th>
                      <th>Approved Overtime In</th>
                      <th>Approved Overtime Out</th>
                    </tr>
                    <tr>
                      <td>{itemRequest.overtime_date}</td>
                      <td>{itemRequest.overtime_start}</td>
                      <td>{itemRequest.overtime_end}</td>
                      <td>
                        <Form.Group className="position-relative">
                          <Form.Control
                              size={'sm'}
                              type='time'
                              placeholder='Custom 1'
                              name="custom_1"
                              // value={itemRequest.overtime_start}
                              disabled={itemRequest.status == "1" && showAcceptRejectButton ? false : true}
                              onChange={handleChangeOvertimeStart}
                          />

                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className="position-relative">
                          <Form.Control
                              size={'sm'}
                              type='time'
                              placeholder='Custom 1'
                              name="custom_1"
                              // value={itemRequest.overtime_end}
                              onChange={handleChangeOvertimeEnd}
                              disabled={itemRequest.status == "1" && showAcceptRejectButton ? false : true}
                          />

                        </Form.Group>
                      </td>
                    </tr>
                    <tr className='lh-1'>
                      <td colSpan={5} className='text-center' style={{fontSize: '90%'}}>Note: If approved overtime in and out is empty, applied overtime in and out is approved.</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <hr/>
            <Row style={{marginTop: '-15px'}}>
              <Table borderless className='mt-0 mb-0'>
                <tbody>
                <tr className='lh-1'>
                  <th className='text-center'>Approvals</th>
                </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col sm={12}>
                <Table borderless className='mt-0 mb-0'>
                  <tbody style={{ padding: 'none' }}>
                  <tr className='lh-1'>
                    <th className='text-center' style={{width: '20%'}}>Levels</th>
                    <th className='text-center' style={{width: '30%'}}>Approver's Name</th>
                    <th className='text-center'>Role</th>
                    <th className='text-center' style={{width: '15%'}}>Status</th>
                    <th className='text-center' style={{width: '20%'}}>Approved/Rejected Date</th>
                  </tr>
                  {
                    itemRequest.request_status.length > 0 ? (
                        itemRequest.request_status.map((item: any, index: any) =>
                            (
                                <tr key={index} className='lh-1'>
                                  <td>
                                    {
                                      index != 0 && itemRequest.request_status[index - 1].level == item.level ?
                                          "" : <>
                                            <b>Level {item.level}</b> : {item.approvers_order == "0" ? "By Order" : "Anyone"}
                                          </>
                                    }
                                  </td>
                                  <td>
                                    <Stack direction='horizontal'>
                                      <div>
                                        {
                                          item.employee?.photo != "" ?
                                              <Avatar
                                                  sx={{ width: 20, height: 20 }}
                                                  src={process.env.REACT_APP_IMAGE_URL+ item.employee?.photo}
                                                  className={"profile-avatar"}
                                              ></Avatar> :
                                              <Avatar
                                                  sx={{ width: 20, height: 20}}
                                                  src={""}
                                                  className={"profile-avatar"}
                                              ></Avatar>
                                        }
                                      </div>
                                      <div>&nbsp;{item.employee?.first_name} {item.employee?.last_name}</div>
                                    </Stack>
                                  </td>
                                  <td>{item.employee?.user_name?.role?.role_name}</td>
                                  {(() => {
                                    // Approved:2
                                    if (item.status == '3') {

                                      return (
                                          <>
                                            <td className='fw-bold text-center'>
                                              <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'success'}>
                                                Approved
                                              </Button>
                                            </td>
                                            <td className='text-center'>{moment(new Date(item.approve_date ?? "")).format(dateFormat)}</td>
                                          </>
                                      )

                                      // Reject:3
                                    } else if(item.status == '2') {
                                      return (
                                          <>
                                            <td className='fw-bold text-center'>
                                              <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'danger'}>
                                                Disapproved
                                              </Button>
                                            </td>
                                            <td className='text-center'>{moment(new Date(item.reject_date ?? "")).format(dateFormat)}</td>

                                          </>
                                      )
                                    } else if(item.status == '4') {
                                      return (
                                          <>
                                            <td className='text-center'>-</td>
                                            <td className='text-center'>-</td>
                                          </>

                                      )
                                    }

                                    return (
                                        <>
                                          <td className='fw-bold text-center'>
                                            <Button size={'sm'} className=' pe-1 px-1 pt-0 pb-0 text-white' style={{ fontSize: '12px'}} variant={'secondary'}>
                                              Pending
                                            </Button>
                                          </td>
                                          <td className='text-center'>-</td>
                                        </>
                                    )
                                  })()}


                                </tr>

                            )

                        )
                    ) : (
                        <></>
                    )
                  }
                  </tbody>
                </Table>
              </Col>

            </Row>
          </Modal.Body>
          <Modal.Footer>
            {
              showAcceptRejectButton ?
                  <>
                    <Button
                        onClick={() => rejectOvertimeRequest(itemRequest.id)}
                        variant='outline-primary'
                        className=' px-5 pe-5 mx-2'
                        size={'sm'}
                        disabled={handleDisableAcceptRejectButton(itemRequest)}
                    >
                      Rejected
                    </Button>
                    <Button
                        onClick={() => acceptOvertimeRequest(itemRequest.id)}
                        variant='primary'
                        className='text-white px-5 pe-5'
                        size={'sm'}
                        disabled={handleDisableAcceptRejectButton(itemRequest)}
                    >
                      Approved
                    </Button>
                  </> : ""
            }

          </Modal.Footer>
        </Modal>
        <br />
      </Container>
    </>
  )
}
