import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Breadcrumb, Button, Card, Col, Container, Form, Row, Table } from 'react-bootstrap'
import Stack from '@mui/material/Stack'
import { Box, Paper } from '@mui/material'
import { AddCircleOutlineOutlined } from '@mui/icons-material'
import './style.css'

export default function Index() {
  // const [key, setKey] = useState('personal-details');
  return (
    <Container fluid>
      <Row>
        <Col sm={6}>
          <h5 className='fw-bold'>Rate of Income Tax</h5>
          <Breadcrumb className='breadcrumbs'>
            <Breadcrumb.Item className=' text-decoration-none ' href='/home'>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item className=' text-decoration-none ' href='#'>
              Payroll
            </Breadcrumb.Item>
            <Breadcrumb.Item href='#'>Setup</Breadcrumb.Item>
            <Breadcrumb.Item active>Rate of Income Tax</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col sm={6}>
          <Stack className='justify-content-end' direction='row' spacing={1}>
            {/* <Button variant="outlined" sx={{textTransform: 'none'}} color="inherit">*/}
            {/*    <AddCircleOutlineOutlined className="mx-1"/> Add new employee*/}
            {/* </Button>*/}
          </Stack>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={6}>
          <Card className='border-0  p-0 bordered-card'>
            <Card.Body>
              <span className='fw-bold'>Tax Rule (Male)</span>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th className='text-center'>Total Income</th>
                    <th className='text-center'>Tax Rate %</th>
                    <th className='text-center'>Taxable Amount</th>
                    <th className='text-center'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='fw-bold'>First</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Next</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Next</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>First</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>First</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>First</td>
                    <td>remaining total income---</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <td>remaining taxable amount---</td>
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <br />
        </Col>

        <Col sm={6}>
          <Card className='border-0  p-0 bordered-card'>
            <Card.Body>
              <span className='fw-bold'>Tax Rule (Female)</span>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th className='text-center'>Total Income</th>
                    <th className='text-center'>Tax Rate %</th>
                    <th className='text-center'>Taxable Amount</th>
                    <th className='text-center'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='fw-bold'>First</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Next</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Next</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>First</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>First</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>First</td>
                    <td>remaining total income---</td>
                    <td>
                      <Form.Control
                        className='text-right'
                        type='number'
                        step={0.01}
                        aria-label='Small'
                        size={'sm'}
                        aria-describedby='inputGroup-sizing-sm'
                      />
                    </td>
                    <td>
                      <td>remaining taxable amount---</td>
                    </td>
                    <td>
                      <Button variant={'warning'} size={'sm'} className='text-white update-btn'>
                        Update
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
