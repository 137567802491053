import React from 'react'

const View = (props : {width?: number, height?: number}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width? props.width :'18.746'}
      height={props.height? props.height :'13.906'}
      viewBox='0 0 18.746 13.906'
    >
      <g id='View' transform='translate(0.5 0.5)'>
        <path
          id='Path_21'
          data-name='Path 21'
          d='M1.5,12.453S4.726,6,10.373,6s8.873,6.453,8.873,6.453-3.226,6.453-8.873,6.453S1.5,12.453,1.5,12.453Z'
          transform='translate(-1.5 -6)'
          fill='none'
          stroke='#a7a9ac'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
        <path
          id='Path_22'
          data-name='Path 22'
          d='M18.34,15.92a2.42,2.42,0,1,1-2.42-2.42A2.42,2.42,0,0,1,18.34,15.92Z'
          transform='translate(-7.047 -9.467)'
          fill='none'
          stroke='#f26930'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  )
}

export default View
