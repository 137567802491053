import { styled, Tab, Box, Tabs } from '@mui/material'

export const StyledTab = styled(Tab)(({ theme }) => ({
  marginRight: 5,
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
  textTransform: 'initial',
  backgroundColor: '#E3E3E3',
  fontWeight: theme.fontStyle.weight.bold,
  color: theme.color.black,
  paddingLeft: 30,
  paddingRight: 30,
  '&:first-of-type': {
    marginLeft: 0,
  },
  '&.Mui-selected': { backgroundColor: theme.color.white },
}))

export const StyleTabPanel = styled(Box)(({ theme }) => ({
  backgroundColor: theme.color.white,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 15,
  borderBottomLeftRadius: 15,
  borderBottomRightRadius: 15,
}))

export const StyleTabsContainer = styled(Tabs)(() => ({ 
  '& .MuiTabs-indicator': { display: 'none' },
}))
