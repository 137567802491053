import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  title: yup.string().required(VALIDATION.required).max(300, VALIDATION.max),
  description: yup.string().required(VALIDATION.required),
  status: yup.string().required(VALIDATION.required),
  publish_date: yup.string().required(VALIDATION.required),
  duration_from: yup.string().required(VALIDATION.required),
  duration_to: yup.string().required(VALIDATION.required)
})

export const validationSchema2 = yup.object({
  branch_id: yup.number(),
  date_from: yup.string().required(VALIDATION.required),
  date_to: yup.string().required(VALIDATION.required),
})
