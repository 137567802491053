import React from 'react'

const PresentIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='34.3' height='37.778' viewBox='0 0 34.3 37.778'>
      <g id='Group_26' data-name='Group 26' transform='translate(-1281.35 -238.611)'>
        <g
          id='Icon_feather-calendar'
          data-name='Icon feather-calendar'
          transform='translate(1278.35 237.111)'
        >
          <path
            id='Path_1'
            data-name='Path 1'
            d='M7.978,6H32.322A3.434,3.434,0,0,1,35.8,9.388V33.108A3.434,3.434,0,0,1,32.322,36.5H7.978A3.434,3.434,0,0,1,4.5,33.108V9.388A3.434,3.434,0,0,1,7.978,6Z'
            transform='translate(0 1.282)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
          <path
            id='Path_2'
            data-name='Path 2'
            d='M24,3v8.563'
            transform='translate(3.106)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
          <path
            id='Path_3'
            data-name='Path 3'
            d='M12,3v8.563'
            transform='translate(1.194)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
          <path
            id='Path_4'
            data-name='Path 4'
            d='M4.5,15H35.8'
            transform='translate(0 1.911)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
          />
        </g>
        <path
          id='Icon_feather-check'
          data-name='Icon feather-check'
          d='M18.594,9,9.936,17.659,6,13.723'
          transform='translate(1286.688 250.431)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        />
      </g>
    </svg>
  )
}

export default PresentIcon
