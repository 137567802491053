import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

import projectThemeStyle, { pallete } from './projectThemeStyle'

const theme = createTheme({
  ...projectThemeStyle,
  palette: {
    ...pallete,
  },
  typography: {
    body1: {
      fontSize: '16px',
    },
    body2: {
      fontSize: '14px',
    },
  },
})

export default responsiveFontSizes(theme)
