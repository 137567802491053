import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  employee_id: yup.number(),
  date_from: yup.string().required(VALIDATION.required),
  date_to: yup.string().required(VALIDATION.required),
  clock_in: yup.string(),
  clock_out: yup.string(),
  location: yup.string().required(VALIDATION.required),
  reason: yup.string().required(VALIDATION.required),
})
