import * as React from "react"
const SwapIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 50}
        height={props.height ?? 50}
        viewBox="0 0 48 48"
    >
        <title>{"Swap To"}</title>
        <g data-name="Layer 2">
            <path fill="none" d="M0 0h48v48H0z" data-name="invisible box" />
            <g data-name="icons Q2">
                <path fill="#f26930" d="m36.4 28.6-4.9-5a2.1 2.1 0 0 0-2.7-.2 1.9 1.9 0 0 0-.2 3l1.6 1.6H15a2 2 0 0 0 0 4h15.2l-1.6 1.6a1.9 1.9 0 0 0 .2 3 2.1 2.1 0 0 0 2.7-.2l4.9-5a1.9 1.9 0 0 0 0-2.8ZM33 16H17.8l1.6-1.6a1.9 1.9 0 0 0-.2-3 2.1 2.1 0 0 0-2.7.2l-4.9 5a1.9 1.9 0 0 0 0 2.8l4.9 5a2.1 2.1 0 0 0 2.7.2 1.9 1.9 0 0 0 .2-3L17.8 20H33a2 2 0 0 0 0-4Z" />
                <path fill="#a7a9ac" d="M42 24A18 18 0 1 1 24 6a18.1 18.1 0 0 1 18 18m4 0a22 22 0 1 0-22 22 21.9 21.9 0 0 0 22-22Z" />
            </g>
        </g>
    </svg>
)
export default SwapIcon
