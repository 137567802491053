import { lazy, Suspense, useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Container, Breadcrumb } from 'react-bootstrap'
import './style.css'
import TabList from 'components/TabList'
import ProgressLoading from 'components/ProgressLoading'
import Alert from 'components/Alert'
const Personal = lazy(() => import('./Personal'))
const Company = lazy(() => import('./Company'))
const Education = lazy(() => import('./Education'))
const Work = lazy(() => import('./Work'))
const Government = lazy(() => import('./Government'))
const Shift = lazy(() => import('./Shift'))
const Salary = lazy(() => import('./Salary'))

import axios from 'api/index'
import { 
  API_ENDPOINT,
  ERROR_MESSAGE
} from 'utils/globalConstant'
import { IAPI_Response } from 'api/types'
import { IItem } from './types'

import { useDispatch } from 'react-redux'
import { editPersonal } from 'redux/EmployeeManagement/EditEmployee/Retail/Personal/PersonalSlice'
import { editCompany } from 'redux/EmployeeManagement/EditEmployee/Retail/Company/CompanySlice'
import { editEducation } from 'redux/EmployeeManagement/EditEmployee/Retail/Education/EducationSlice'
import { editWork } from 'redux/EmployeeManagement/EditEmployee/Retail/Work/WorkSlice'
import { editGovernment } from 'redux/EmployeeManagement/EditEmployee/Retail/Government/GovernmentSlice'
import { editShift } from 'redux/EmployeeManagement/EditEmployee/Retail/Shift/ShiftSlice'
import { editSalary } from 'redux/EmployeeManagement/EditEmployee/Retail/Salary/SalarySlice'

export default function Index() {
  const renderComponent = (comp: JSX.Element) => (
    <Suspense fallback={<ProgressLoading />}>{comp}</Suspense>
  )
  const dispatch = useDispatch()
  const {id} = useParams();

  const [currentTab, setCurrentTab] = useState(0)
  const [error, setError] = useState('')
  const [showError, setShowError] = useState(false)
  const [showTab, setTab] = useState(false)

  const showEmployee = async () => {
    try {
      const response = await axios.get<IAPI_Response<IItem>>(API_ENDPOINT.SHOW_EMPLOYEE +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        dispatch(editPersonal({
          employee_id: result?.data?.results?.employee_id,
          first_name: result?.data?.results?.first_name,
          last_name: result?.data?.results?.last_name,
          middle_name: result?.data?.results?.middle_name != null? result?.data?.results?.middle_name : '' ,
          phone: result?.data?.results?.phone,
          emergency_contacts: result?.data?.results?.emergency_contacts,
          date_of_birth: result?.data?.results?.date_of_birth,
          marital_status: result?.data?.results?.marital_status,
          address: result?.data?.results?.address,
          email: result?.data?.results?.email,
          gender: result?.data?.results?.gender,
          photo: result?.data?.results?.photo,
          religion: result?.data?.results?.religion,
          username: result?.data?.results?.user_name?.user_name,
          role_id: result?.data?.results?.user_name?.role_id,
          password: '',
          password_confirmation: '',
          bank_name: result?.data?.results?.bank_name,
          bank_account_number: result?.data?.results?.bank_account_number,
          custom_fields: result?.data?.results?.custom_fields
        }))

        dispatch(editCompany({
          company_email: result?.data?.results?.company_email,
          employee_no: result?.data?.results?.employee_no,
          finger_id: result?.data?.results?.finger_id,
          supervisor_id: result?.data?.results?.supervisor_id,
          department_id: result?.data?.results?.department_id,
          designation_id: result?.data?.results?.designation_id,
          branch_id: result?.data?.results?.branch_id,
          date_of_joining: result?.data?.results?.date_of_joining,
          date_of_leaving: result?.data?.results?.date_of_leaving,
          date_of_regularization: result?.data?.results?.date_of_regularization,
          status: result?.data?.results?.status,
          workflow: result?.data?.results?.workflows_level.length == 0 ? [{
            level: 1,
            approvers_order: '',
            approvers: [{
              approver_employee_id: ''
            }]
          }] : result?.data?.results?.workflows_level,
          approvers_order: result?.data?.results?.approvers_order,
          leave_credits: result?.data?.results?.leaveCredits,
          employee_type: result?.data?.results?.employee_type,
          job_level_id: result?.data?.results?.job_level_id ?? "",
          reason_of_leaving: result?.data?.results?.reason_of_leaving,
          company_documents: result?.data?.results?.company_documents,
          custom_1: result?.data?.results?.custom_1,
          custom_2: result?.data?.results?.custom_2,
          custom_3: result?.data?.results?.custom_3,
          custom_fields: result?.data?.results?.custom_fields,
          team_id: result?.data?.results?.team_id
        }))

        dispatch(editSalary({
          pay_grade_id: result?.data?.results?.pay_grade?.pay_grade_id,
          pay_grade_name: result?.data?.results?.pay_grade?.pay_grade_name,
          basic_salary: result?.data?.results?.pay_grade?.basic_salary,
          gross_salary: result?.data?.results?.pay_grade?.gross_salary,
          isCustom: result?.data?.results?.pay_grade?.isCustom,
          is_daily_rate: result?.data?.results?.pay_grade?.is_daily_rate,
          daily_rate: result?.data?.results?.pay_grade?.daily_rate,
          total_monthly_rate: result?.data?.results?.pay_grade?.total_monthly_rate,
          allowances: result?.data?.results?.pay_grade?.allowances.map(function (allowance) {
            return {
              allowance_id: parseInt(allowance.allowance_id.toString()),
              amount: allowance.amount.toString()
            };
          }),
          deductions: result?.data?.results?.pay_grade?.deductions.map(function (deduction) {
            return {
              deduction_id: parseInt(deduction.deduction_id.toString())
            };
          }),
          custom_fields: result?.data?.results?.custom_fields
        }))

        if (result?.data?.results?.educations.length >= 1) {
          dispatch(editEducation(
            result?.data?.results?.educations
          ))
        } else {
          dispatch(editEducation([{
            board_university: '',
            degree: '',
            cgpa: '',
            institute: '',
            passing_year: '',
            result: '',
          }]))
        }

        if (result?.data?.results?.experiences.length >= 1) {
          dispatch(editWork(
            result?.data?.results?.experiences
          ))
        } else {
          dispatch(editWork([{
            organization_name: '',
            designation: '',
            from_date: '',
            to_date: '',
          }]))
        }

        dispatch(editGovernment({
          nbi_url: result?.data?.results?.government?.nbi_url,
          pag_ibig: result?.data?.results?.government?.pag_ibig,
          phil_health: result?.data?.results?.government?.phil_health,
          sss: result?.data?.results?.government?.sss,
          tin: result?.data?.results?.government?.tin,
        }))

        if (result?.data?.results?.shifts.length >= 1) {
          dispatch(editShift({
            no_of_hours_work_per_day: result?.data?.results?.no_of_hours_work_per_day,
            schedule_type: result?.data?.results?.schedule_type,
            work_shift_id: result?.data?.results?.work_shift_id,
            shift: result?.data?.results?.shifts,
            work_site: result?.data?.results?.work_site,
            grace_time_period: result?.data?.results?.grace_time_period,
            grace_period_minutes: result?.data?.results?.grace_period_minutes,
            grace_time_period_out: result?.data?.results?.grace_time_period_out,
            grace_period_minutes_out: result?.data?.results?.grace_period_minutes_out,
            shift_type: result?.data?.results?.shift_type,
            custom_fields: result?.data?.results?.custom_fields
          }))
        } else {
          dispatch(editShift({
            no_of_hours_work_per_day: '',
            schedule_type: '',
            work_shift_id: '0',
            work_site: '',
            grace_time_period: '',
            grace_period_minutes: 0,
            grace_time_period_out: '',
            grace_period_minutes_out: 0,
            shift_type: 0,
            custom_fields: [
              {
                name: '',
                value: ''
              }
            ],
            shift: [
              {
                id: 0,
                employee_id: 0,
                day: 0,
                start_from: '',
                end_to: '',
                break_in: '',
                break_out: '',
                is_rest_day: '',
                late_count_time: '',
                created_at: '',
                updated_at: ''
              },
              {
                id: 0,
                employee_id: 0,
                day: 1,
                start_from: '',
                end_to: '',
                break_in: '',
                break_out: '',
                is_rest_day: '',
                late_count_time: '',
                created_at: '',
                updated_at: ''
              },
              {
                id: 0,
                employee_id: 0,
                day: 2,
                start_from: '',
                end_to: '',
                break_in: '',
                break_out: '',
                is_rest_day: '',
                late_count_time: '',
                created_at: '',
                updated_at: ''
              },
              {
                id: 0,
                employee_id: 0,
                day: 3,
                start_from: '',
                end_to: '',
                break_in: '',
                break_out: '',
                is_rest_day: '',
                late_count_time: '',
                created_at: '',
                updated_at: ''
              },
              {
                id: 0,
                employee_id: 0,
                day: 4,
                start_from: '',
                end_to: '',
                break_in: '',
                break_out: '',
                is_rest_day: '',
                late_count_time: '',
                created_at: '',
                updated_at: ''
              },
              {
                id: 0,
                employee_id: 0,
                day: 5,
                start_from: '',
                end_to: '',
                break_in: '',
                break_out: '',
                is_rest_day: '',
                late_count_time: '',
                created_at: '',
                updated_at: ''
              },
              {
                id: 0,
                employee_id: 0,
                day: 6,
                start_from: '',
                end_to: '',
                break_in: '',
                break_out: '',
                is_rest_day: '',
                late_count_time: '',
                created_at: '',
                updated_at: ''
              },
            ]
          }))
        }

        setTab(true)
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
        showEmployee()
    })()
  }, [])

  return (
    <Container fluid>
      <h5 className='fw-bold'>Edit Employee</h5>
      <Breadcrumb className='breadcrumbs'>
        <Breadcrumb.Item className='text-decoration-none' href='/home'>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item className='text-decoration-none' href='#'>
          Employees
        </Breadcrumb.Item>
        <Breadcrumb.Item href='/employeeManagement/employee-list'>Employee List</Breadcrumb.Item>
        <Breadcrumb.Item active>Edit Employee</Breadcrumb.Item>
      </Breadcrumb>

      <Alert 
          type="error" 
          show={showError} 
          message={error} 
          setShow={() => { 
            setShowError(false);
            }
          }
        />

        <TabList
          lists={[
            {
              label: 'Personal',
              component: renderComponent(showTab ? (<Personal setCurrentTab={setCurrentTab} />) : <></>),
              disabled: false,
            },
            {
              label: 'Company',
              component: renderComponent(showTab ? (<Company setCurrentTab={setCurrentTab} />) : <></>),
              disabled: false,
            },
            {
              label: 'Salary',
              component: renderComponent(showTab ? (<Salary setCurrentTab={setCurrentTab} />) : <></>),
              disabled: false,
            },
            {
              label: 'Shift',
              component: renderComponent(showTab ? (<Shift setCurrentTab={setCurrentTab} />) : <></>),
              disabled: false,
            },
            {
              label: 'Education',
              component: renderComponent(showTab ? (<Education setCurrentTab={setCurrentTab} />) : <></>),
              disabled: false,
            },
            {
              label: 'Work Experience',
              component: renderComponent(showTab ? (<Work setCurrentTab={setCurrentTab} />) : <></>),
              disabled: false,
            },
            {
              label: 'Government',
              component: renderComponent(showTab ? (<Government setCurrentTab={setCurrentTab} />) : <></>),
              disabled: false,
            },
          ]}
          currentTab={currentTab}
          />
          
        <br/>
    </Container>
  )
}
