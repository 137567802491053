import React, { useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import Stack from "react-bootstrap/Stack";
import "./style.css"

interface PaginationProps {
  itemsCount: number,
  itemsPerPage: number,
  currentPage: number,
  lastPage: number,
  setCurrentPage: any,
  pageClicked: any,
  alwaysShown: boolean
}

const PaginationComponent: React.FC<PaginationProps> = ({
  itemsCount,
  itemsPerPage,
  currentPage,
  lastPage,
  setCurrentPage,
  pageClicked,
  alwaysShown = true
}) => {
  const pagesCount = Math.ceil(itemsCount / itemsPerPage);
  // const isPaginationShown = alwaysShown ? true : pagesCount > 1;
  const isPaginationShown = true;
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pagesCount;

  const changePage = (number : number) => {
    if (currentPage === number) return;
    pageClicked(number);
    setCurrentPage(number);
  };

  const onFirstPageNumberClick = () => {
    changePage(1);
  };

  const onLastPageNumberClick = () => {
    changePage(lastPage);
  };

  const onPageNumberClick = (pageNumber: number) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => {
    changePage(currentPage - 1);
  };

  const onNextPageClick = () => {
    changePage(currentPage + 1);
  };

  const setLastPageAsCurrent = () => {
    if (currentPage > pagesCount) {
      setCurrentPage(pagesCount);
    }
  };

  const onPageNumberChange = (e: any) => {
    if(e.target.value > pagesCount){
      changePage(pagesCount);
    }else{
      changePage(e.target.value);
    }


  };

  let isPageNumberOutOfRange : boolean;

  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    const isCurrentPageWithinTwoPageNumbers =
      Math.abs(pageNumber - currentPage) <= 1;

    if (
      isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinTwoPageNumbers
    ) {
      isPageNumberOutOfRange = false;
      return (
        <Pagination.Item
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === currentPage}
        >
          {pageNumber}
        </Pagination.Item>
      );

    }

    // if (!isPageNumberOutOfRange) {
    //   isPageNumberOutOfRange = true;
    //   return <Pagination.Ellipsis key={pageNumber} className="muted" />;
    // }

    return null;
  });


  useEffect(setLastPageAsCurrent, [pagesCount]);

  return (
    <>
      {isPaginationShown && (
        <Pagination size={'sm'}>
        <Stack direction={'horizontal'} gap={2}>
          {/*<Pagination.First */}
          {/*  onClick={onFirstPageNumberClick}*/}
          {/*  disabled={isCurrentPageFirst}*/}
          {/*/>*/}
          <Pagination.Prev
            onClick={onPreviousPageClick}
            disabled={isCurrentPageFirst}
            style={{width: '30px', textAlign: 'center'}}
          />

          <input
              className="form-control text-right"
              onChange={onPageNumberChange}
              value={currentPage}
              style={{width:'15%', height: '90%', fontSize:'80%'}}
          />
          <label
              style={{fontSize:'80%'}}
          > of {pagesCount} &nbsp;</label>

          <Pagination.Next
            onClick={onNextPageClick}
            disabled={isCurrentPageLast}
            style={{width: '30px', textAlign: 'center'}}
          />
          {/*<Pagination.Last */}
          {/*  onClick={onLastPageNumberClick}*/}
          {/*  disabled={isCurrentPageLast}*/}
          {/*/>*/}
          </Stack>
        </Pagination>
        
      )}
    </>
  );
}

export default PaginationComponent;
