import { CSSObject, styled, Theme } from '@mui/material/styles'
import { Typography, Box } from '@material-ui/core'
import MuiDrawer from '@mui/material/Drawer'
import { drawerWidth } from '../../utils/globalConstant'
import { TypographyProps as MUITypographyProps } from '@mui/material/Typography'

export const StyledEmployeeName = styled(Typography)(({ theme }) => ({
  fontSize: `${theme.fontStyle.size.seventeen}px !important`,
  fontWeight: `${theme.fontStyle.weight.heavyBold} !important`,
  lineHeight: '23px',
  color: theme.palette.secondary.dark,
}))

export const StyledPosition = styled(Typography)(({ theme }) => ({
  fontSize: theme.fontStyle.size.seventeen,
  fontWeight: theme.fontStyle.weight.normal,
  lineHeight: '23px',
  color: theme.palette.secondary.main,
}))

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth.open,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)}px + 1px)`,
  // width: 150,
  [theme.breakpoints.down('sm')]: {
    // width: `calc(${theme.spacing(8)}px + 1px)`,
    width: 200,
    // zIndex: '3'
  },
  // position: 'fixed',

})

export const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth.open,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}))

export const DrawerClose = styled('div')(({ theme }) => ({
  alignItems: 'right',
  justifyContent: 'flex-end',
  display: 'none',
  float: 'right',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  [theme.breakpoints.down('sm')]: {
    display: 'block'
  },
  minHeight: '30px'
}))


export const StyledEmployeeContainer = styled(Box)(() => ({
  marginTop: 39,
  textAlign: 'center',
}))

interface TypographyProps extends MUITypographyProps {
  active?: boolean
}
export const StyledTypoListItemText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'active',
})<TypographyProps>(({ theme, active }) => ({
  color: active ? theme.palette.secondary.dark : theme.palette.secondary.main,
  fontWeight: `${active ? theme.fontStyle.weight.bold : theme.fontStyle.weight.normal} !important`,
}))

export const StyledTypoSubNav = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'active',
})<TypographyProps>(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : theme.palette.secondary.main,
  fontWeight: theme.fontStyle.weight.light,
}))
