import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'


const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const validationSchema = yup.object({
  reason: yup.string().required(VALIDATION.required),
    date: yup.string().required(VALIDATION.required),
    swap_date: yup.string().required(VALIDATION.required)
})

