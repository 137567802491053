import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IGovernmentValue } from './types'

const initialState: IGovernmentValue = {
  nbi_url: '',
  pag_ibig: '',
  phil_health: '',
  sss: '',
  tin: '',
}

export const GovernmentSlice = createSlice({
  name: 'Government',
  initialState,
  reducers: {
    addGorvernment: (_state, action: PayloadAction<IGovernmentValue>) => action.payload,
  },
})

export const { addGorvernment } = GovernmentSlice.actions
export default GovernmentSlice.reducer
