import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IList } from './types'

const initialState: IList[] = [{
  date: '',
  in_time: '',
  out_time: '',
  in_time_pm: '',
  out_time_pm: '',
  break_in: '',
  break_out: '',
  overtime_out: '',
  overtime_in: '',
  worked_time: '',
  late_time: '',
  status: ''
}]

const AttendanceSlice = createSlice({
  name: 'Attendace',
  initialState,
  reducers: {
    addAttendance: (_state, state: PayloadAction<IList[]>) => state.payload,
  },
})

export const { addAttendance } = AttendanceSlice.actions
export default AttendanceSlice.reducer
