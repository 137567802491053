import React from 'react'

const AttendanceWorkingHolidayIcon = () => {
    return (
        <svg
            id='Attendance-Holiday-Icon'
            xmlns='http://www.w3.org/2000/svg'
            width='15'
            height='15'
            viewBox='0 0 19.385 18'
        >
            <path
                id='Icon_ionic-ios-star'
                data-name='Icon ionic-ios-star'
                d='M20.9,9.606H14.534L12.6,3.834a.7.7,0,0,0-1.315,0L9.35,9.606H2.942a.694.694,0,0,0-.692.692.509.509,0,0,0,.013.117.665.665,0,0,0,.29.489L7.784,14.59,5.776,20.427a.694.694,0,0,0,.238.779.67.67,0,0,0,.389.169.848.848,0,0,0,.433-.156l5.106-3.639,5.106,3.639a.811.811,0,0,0,.433.156.622.622,0,0,0,.385-.169.686.686,0,0,0,.238-.779L16.1,14.59l5.188-3.721.125-.108a.726.726,0,0,0,.225-.463A.733.733,0,0,0,20.9,9.606Z'
                transform='translate(-2.25 -3.375)'
                fill='#82C341'
            />
        </svg>
    )
}

export default AttendanceWorkingHolidayIcon
