import * as React from "react"
const AddEmployee = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="-2.5 0 32 32"
        fill={"#ff7043"}
        strokeWidth={0.96}
    >
        <path d="M18.723 21.788c-1.15-.48-3.884-1.423-5.565-1.919-.143-.045-.166-.052-.166-.649 0-.493.203-.989.401-1.409a7.22 7.22 0 0 0 .559-1.912c.255-.296.602-.88.826-1.993.196-.981.104-1.338-.026-1.673-.013-.035-.028-.07-.038-.105-.049-.23.018-1.425.186-2.352.116-.636-.03-1.989-.906-3.108-.553-.707-1.611-1.576-3.544-1.696l-1.06.001c-1.9.12-2.96.988-3.513 1.695-.876 1.119-1.021 2.472-.906 3.108.169.928.236 2.123.187 2.348-.01.039-.025.074-.039.11-.129.335-.221.692-.025 1.673.222 1.113.57 1.697.826 1.993.09.688.344 1.456.559 1.912.157.334.23.788.23 1.431 0 .597-.023.604-.157.646-1.738.513-4.505 1.513-5.537 1.965-.818.351-1.017.98-1.017 1.548v2.623c0 .371.22 1.006 1.017 1.006H18.913c.796 0 1.017-.634 1.017-1.006v-2.623c0-.568-.392-1.262-1.209-1.613zm.153 4.192H1.049v-2.579c0-.318.092-.46.388-.587.994-.435 3.741-1.426 5.434-1.926.889-.282.889-1.07.889-1.646 0-.801-.106-1.397-.331-1.878a6.095 6.095 0 0 1-.468-1.601l-.041-.312-.206-.238c-.113-.13-.396-.538-.59-1.513-.153-.759-.085-.935-.031-1.076.031-.076.058-.152.081-.237l.005-.022.005-.022c.105-.495-.037-1.962-.181-2.755-.067-.365.017-1.401.7-2.273.418-.534 1.229-1.19 2.722-1.293l.992-.001c1.219.083 2.145.518 2.752 1.294.682.872.766 1.909.7 2.275-.148.814-.287 2.257-.18 2.758l.008.039.011.038c.016.054.036.108.056.161l.009.026.001.002c.059.153.127.326-.024 1.087-.196.974-.479 1.384-.592 1.515l-.204.237-.042.31a6.123 6.123 0 0 1-.468 1.603c-.247.525-.5 1.157-.5 1.856 0 .577 0 1.367.918 1.655 1.641.485 4.345 1.416 5.448 1.877.418.179.574.493.574.649l-.006 2.579zM23.078 14.441v-4.185h-1.049v4.185h-4.186v1.049h4.186v4.185h1.049V15.49h4.185v-1.049z" />
    </svg>
)
export default AddEmployee
