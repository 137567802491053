import React from 'react'
import './notification.scss'
import Collapse from 'react-bootstrap/Collapse';
import CloseButton from 'react-bootstrap/CloseButton';

interface AlertProps {
  type: string,
  show: boolean,
  message: string,
  setShow: any
}

const AlertComponent: React.FC<AlertProps> = ({
  type,
  show,
  message,
  setShow,
}) => {
  return (
    <Collapse in={show} dimension="width">
        <div className='notification-container'>
            {(() => {
                switch (type) {
                    case 'success':
                        return (
                            <div className='notification notification-success'>
                                <div className="notification-message" role="alert">
                                    <div className="message">{message}</div>
                                </div>
                                <CloseButton 
                                    variant="white"
                                    className="notification-close"
                                    onClick={setShow}
                                    />
                            </div>
                        )
                    case 'error':
                        return (
                            <div className='notification notification-error'>
                                <div className="notification-message" role="alert">
                                    <div className="message">{message}</div>
                                </div>
                                <CloseButton 
                                    variant="white"
                                    className="notification-close"
                                    onClick={setShow}
                                    />
                            </div>
                        )
                  case 'copy':
                    return (
                        <div className='notification notification-warning'>
                          <div className="notification-message" role="alert">
                            <div className="message" style={{color: 'black'}}>{message}</div>
                          </div>
                          <CloseButton
                              variant="black"
                              className="notification-close"
                              onClick={setShow}
                          />
                        </div>
                    )
                }
            })()}            
        </div>
    </Collapse>
  )
}

export default AlertComponent
