import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'


const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const validationSchema = yup.object({
  type: yup.string().required(VALIDATION.required),
  reason: yup.string().required(VALIDATION.required),
  requested: yup.array().of(
    yup.object().shape({
      time_in_out: yup.string().required(VALIDATION.required),
      time_in_out_date: yup.string().required(VALIDATION.required),
      time_in_out_time: yup.string().required(VALIDATION.required),
    })
  ),
    documents: yup.mixed()
        // .test("fileType", "Unsupported File Format", (value) =>{
        //         if(value && value.length>0){
        //             for (let i=0;i<value.length;i++){
        //                 if(value[i].type!= "image/png" && value[i].type!= "image/jpg" && value[i].type!= "image/jpeg"){
        //                     return false;
        //                 }
        //             }
        //         }
        //         return true;
        //     }
        // )
})

