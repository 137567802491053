// import React, {useEffect, useState} from 'react';
// import { Pie } from 'react-chartjs-2';
// import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
//
// // Register the components and plugins
// Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);
//
// const PieChart = (props: {applications: string[], percentage: number[]}) => {
//     const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);
//     const [backgroundColors, setBackgroundColors] = useState<string[]>([]);
//
//     const baseOffset = 0;
//     const hoverOffset = 10;
//
//     const colorList = [
//         '#3266CC',
//         '#DC3812',
//         '#FE9900',
//         '#0D9916',
//         '#95019A',
//         '#0099C5',
//         '#D9DCD1',
//         '#69A900',
//         '#FFBB28'
//         // '#2D882D',
//         // '#AA9739',
//         // '#403075',
//         // '#AA6A39',
//         // '#5D2971',
//         // '#E2E04F', //red
//         // '#862D63',
//         // '#4B676B',
//         // '#7A296B'
//     ];
//
//     // Generate the colors once and store them in state
//     useEffect(() => {
//         const colors = props.percentage.map((value, index) => colorList[index]);
//         setBackgroundColors(colors);
//     }, [props.percentage]); // Recalculate colors only when the percentage data changes
//
//     // Calculate the offset for each segment based on hover
//     const offsets = Array(props.applications.length).fill(baseOffset); // Assuming 6 segments, replace with your actual dataset size
//     if (hoveredIndex !== null) {
//         offsets[hoveredIndex] = hoverOffset;
//     }
//
//     // Generate random colors for each data point
//     // const backgroundColors = props.percentage.map(() => getRandomColor());
//
//     const data = {
//         labels: props.applications,
//         datasets: [
//             {
//                 // label: 'Percentage',
//                 data: props.percentage,
//                 backgroundColor: backgroundColors,
//                 borderColor: backgroundColors,
//                 borderWidth: 1,
//                 hoverOffset: 35,
//                 offset: offsets,
//             },
//         ],
//
//     };
//
//     const options = {
//         plugins: {
//             tooltip: {
//                 enabled: false, // Disable tooltips to hide text when hovered
//             },
//             legend: {
//                 display: false, // Hide the legend
//             },
//             datalabels: {
//                 color: function (context: any) {
//                     // When no segment is hovered, all labels are fully opaque.
//                     if (hoveredIndex === null) {
//                         return 'black';
//                     }
//                     // Reduce opacity of labels on non-hovered segments.
//                     return hoveredIndex === context.dataIndex ? 'black' : 'rgba(0, 0, 0, 0.2)';
//                 },
//                 font: (context: any) => {
//                     // When a segment is hovered, increase the font size for that label.
//                     if (hoveredIndex === context.dataIndex) {
//                         return {
//                             size: 15, // Larger font size when hovered
//                             // weight: 'bold',
//                         };
//                     }
//                     return {
//                         size: 10, // Normal font size when not hovered
//                         // weight: 'normal',
//                     };
//                 },
//                 anchor: 'end' as const,
//                 align: 'end' as const,
//                 offset: 10,
//                 borderRadius: 4,
//                 backgroundColor: 'transparent',
//                 borderColor: 'transparent',
//                 borderWidth: 1,
//                 clip: false, // Prevent labels from being cut off
//                 formatter: (value: any, context: any) => {
//                     const total = context.dataset.data.reduce((acc: number, val: number) => acc + val, 0);
//                     const percentage = ((value / total) * 100).toFixed(2);
//                     return `${percentage}%`; // Display the percentage value
//                 },
//                 // Position labels outside
//                 labels: {
//                     name: {
//                         align: 'end' as const,
//                         anchor: 'end' as const,
//                         formatter: (value: any, context: any) => {
//                             return `${value}% - `+context.chart.data.labels[context.dataIndex];
//                         },
//                     },
//                 },
//             },
//             outlabels: {
//                 text: '%l %p',
//                 color: 'black',
//                 stretch: 30,
//                 font: {
//                     resizable: true,
//                     minSize: 12,
//                     maxSize: 18,
//                 },
//                 lineColor: 'black',
//                 lineWidth: 2,
//                 padding: 4,
//             },
//         },
//         maintainAspectRatio: false, // Allow flexibility in chart dimensions
//         layout: {
//             padding: {
//                 top: 40,
//                 bottom: 40,
//                 left: 120,  // Increase left padding
//                 right: 120, // Increase right padding
//             },
//         },
//         cutout: '40%', // This creates the hole in the center
//         onHover: (event: any, chartElement: any) => {
//             if (chartElement.length) {
//                 setHoveredIndex(chartElement[0].index);
//             } else {
//                 setHoveredIndex(null);
//             }
//         },
//     };
//
//     return <div style={{ width: '100%', height: '200px' }}>
//         <Pie data={data} options={options}/>
//     </div>;
// };
//
// export default PieChart;
//
// // Helper function to generate random color
// const getRandomColor = () => {
//     // const letters = '0123456789ABCDEF';
//     // let color = '#';
//     // for (let i = 0; i < 6; i++) {
//     //     color += letters[Math.floor(Math.random() * 16)];
//     // }
//     // return color;
//
//     const colors = [
//         '#AA3939',
//         '#AA9739',
//         '#403075',
//         '#2D882D',
//         '#2A7E43',
//         '#2C4770',
//         '#257059',
//         '#AA5F39',
//         '#7A296B'
//     ];
//     // Create a copy of the colors array to keep track of remaining colors
//     let remainingColors = [...colors];
//
//
//         // If all colors have been used, reset the remainingColors array
//         if (remainingColors.length === 0) {
//             remainingColors = [...colors];
//         }
//         // Select a random index from the remaining colors
//         const randomIndex = Math.floor(Math.random() * remainingColors.length);
//         // Remove the color from the remaining colors and return it
//         const color = remainingColors.splice(randomIndex, 1)[0];
//         return color;
//
// };
//


// import React, { useEffect, useState } from 'react';
// import { Pie } from 'react-chartjs-2';
// import {
//     Chart,
//     ArcElement,
//     Tooltip,
//     Legend,
//     ChartOptions,
// } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
//
// // Register the components and plugins
// Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);
//
// const PieChart = (props: { applications: string[]; percentage: number[]; activityLevel: string }) => {
//     const [backgroundColors, setBackgroundColors] = useState<string[]>([]);
//
//     const colorList = [
//         '#0088FE',
//         '#00C4A0',
//         '#FFBB28',
//         '#FF8043',
//         '#8884D8',
//         '#82C99D',
//         '#A4DE6D',
//         '#D2EC54',
//         '#FFA07A',
//     ];
//
//     // Generate the colors once and store them in state
//     useEffect(() => {
//         const colors = props.percentage.map(
//             (value, index) => colorList[index % colorList.length]
//         );
//         setBackgroundColors(colors);
//     }, [props.percentage]);
//
//     // Function to break long labels into multiple lines
//     const splitLabelIntoLines = (label: string, maxLineLength: number) => {
//         const words = label.split(' ');
//         let currentLine = '';
//         const lines = [];
//
//         words.forEach((word) => {
//             // If adding the next word exceeds the max length, start a new line
//             if ((currentLine + word).length > maxLineLength) {
//                 lines.push(currentLine.trim());
//                 currentLine = word + ' '; // Start the next line
//             } else {
//                 currentLine += word + ' ';
//             }
//         });
//         lines.push(currentLine.trim()); // Add the remaining text
//
//         return lines;
//     };
//
//     const data = {
//         labels: props.applications,
//         datasets: [
//             {
//                 data: props.percentage,
//                 backgroundColor: backgroundColors,
//                 borderColor: 'white',
//                 borderWidth: 1,
//                 hoverOffset: 10,
//             },
//         ],
//     };
//
//     const options: ChartOptions<'pie'> = {
//         plugins: {
//             tooltip: {
//                 enabled: false, // Disable tooltips
//             },
//             legend: {
//                 display: false, // Hide the legend
//             },
//             datalabels: {
//                 clip: false, // Do not clip labels outside the chart area
//                 color: 'black',
//                 align: 'end',
//                 anchor: 'end',
//                 offset: 20, // Offset labels from pie chart
//                 padding: {
//                     top: 10,
//                     bottom: 10,
//                     left: 5,
//                     right: 5,
//                 },
//                 formatter: function (value: any, context: any) {
//                     const total = context.dataset.data.reduce(
//                         (acc: number, val: number) => acc + val,
//                         0
//                     );
//                     const percentage = ((value / total) * 100).toFixed(1);
//
//                     // Split the label into multiple lines if necessary
//                     const label = context.chart.data.labels[context.dataIndex] + ` (${percentage}%)` as string;
//                     const lines = splitLabelIntoLines(label, 20); // Max characters per line
//
//                     // Return the multi-line label with the percentage on a new line
//                     return [...lines];
//                 },
//                 font: {
//                     size: 9,
//                 },
//                 // clip: false, // Do not clip labels outside the chart area
//                 // color: 'black',
//                 // labels: {
//                 //     // Label for outside (application names)
//                 //     outside: {
//                 //         align: 'end' as const,
//                 //         anchor: 'end' as const,
//                 //         offset: 20,
//                 //         borderRadius: 4,
//                 //         backgroundColor: 'transparent',
//                 //         borderColor: 'transparent',
//                 //         borderWidth: 1,
//                 //         clip: false,
//                 //         color: 'black',
//                 //         formatter: function (value: any, context: any) {
//                 //             const total = context.dataset.data.reduce(
//                 //                 (acc: number, val: number) => acc + val,
//                 //                 0
//                 //             );
//                 //             const percentage = ((value / total) * 100).toFixed(1);
//                 //
//                 //             // Split the label into multiple lines if necessary
//                 //             const label = context.chart.data.labels[context.dataIndex] + ` (${percentage}%)` as string;
//                 //             const lines = splitLabelIntoLines(label, 20); // Max characters per line
//                 //
//                 //             // Return the multi-line label with the percentage on a new line
//                 //             return [...lines];
//                 //         },
//                 //         font: {
//                 //             size: 10,
//                 //         },
//                 //     },
//                 //     // // Label for inside (percentage values)
//                 //     // inside: {
//                 //     //     align: 'center' as const,
//                 //     //     anchor: 'center' as const,
//                 //     //     color: 'black',
//                 //     //     formatter: function (value: any, context: any) {
//                 //
//                 //     //     },
//                 //     //     font: {
//                 //     //         size: 9,
//                 //     //     },
//                 //     // },
//                 // },
//                 // padding: {
//                 //     top: 20, // Increase padding to avoid label overlap
//                 // },
//             },
//         },
//         maintainAspectRatio: false,
//         layout: {
//             padding: {
//                 top: 55,
//                 bottom: 55,
//                 left: 100,  // Increase left padding
//                 right: 100, // Increase right padding
//             },
//         },
//         cutout: '45%', // This creates the hole in the center
//     };
//
//     return (
//         <div style={{ width: '100%', height: '250px', position: 'relative' }}>
//             <Pie data={data} options={options} />
//             <div
//                 style={{
//                     position: 'absolute',
//                     top: '50%',
//                     left: '50%',
//                     transform: 'translate(-50%, -50%)',
//                     textAlign: 'center',
//                 }}
//             >
//                 <div style={{ fontSize: '9px', fontWeight: 'bold' }}>Activity Level</div>
//                 <div style={{ fontSize: '10px', fontWeight: 'bold' }}>{props.activityLevel}</div>
//             </div>
//         </div>
//     );
// };
//
// export default PieChart;


import React from 'react';
import { ResponsivePie, ComputedDatum } from '@nivo/pie';

interface MyPieChartProps {
    applications: string[];
    percentage: number[];
    activityLevel: string;
}

interface PieChartDatum {
    id: string;
    label: string;
    value: number;
    color: string;
}

const MyPieChart: React.FC<MyPieChartProps> = ({ applications, percentage, activityLevel }) => {
    const colors = [
        '#0088FE', '#00C4A0', '#FFBB28', '#FF8043', '#8884D8',
        '#82C99D', '#A4DF6B', '#D1EC59', '#FFA07A', '#B1C3DD'
    ];

    const data: PieChartDatum[] = applications.map((label, index) => ({
        id: label,
        label: label,
        value: percentage[index],
        color: colors[index % colors.length],
    }));

    const CenteredMetric = ({ centerX, centerY }: { centerX: number; centerY: number }) => (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: '12px',
                fontWeight: 'bold',
            }}
        >
            <tspan x={centerX} dy="-0.6em">Activity Level</tspan>
            <tspan x={centerX} dy="1.2em">{activityLevel}</tspan>
        </text>
    );

    // Define the props type for CustomLabel
    interface CustomLabelProps<Datum> {
        datum: ComputedDatum<Datum>;
        label: string;
        style: {
            x: number;
            y: number;
            [key: string]: any;
        };
    }

    const CustomLabel = ({
                             datum,
                             label,
                             style,
                         }: CustomLabelProps<PieChartDatum>) => (
        <g transform={`translate(${style.x}, ${style.y})`} style={{ pointerEvents: 'none' }}>
            <rect
                x={-20}
                y={-7}
                width={14}
                height={14}
                fill={datum.data.color}
                rx={2}
                ry={2}
            />
            <text
                x={0}
                y={0}
                textAnchor="start"
                dominantBaseline="central"
                style={{
                    fill: '#333333',
                    fontSize: 10,
                    fontWeight: 'bold',
                }}
            >
                {` ${label} (${datum.value}%)`}
            </text>
        </g>
    );

    return (
        <div style={{ height: 250, width: '100%' }}>
            <ResponsivePie
                data={data}
                margin={{ top: 0, right: 170, bottom: 0, left: 170 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                colors={(d) => d.data.color}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                arcLinkLabel="label"
                arcLinkLabelsSkipAngle={5}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLinkLabelsOffset={10}
                arcLinkLabelsDiagonalLength={16}
                arcLinkLabelsStraightLength={24}
                arcLinkLabelsTextOffset={4}
                enableArcLabels={false}
                // arcLinkLabelsComponent={CustomLabel}
                layers={['arcs', 'arcLinkLabels', CenteredMetric]}
            />
        </div>
    );
};

export default MyPieChart;



// import React from 'react';
// import { ResponsivePie } from '@nivo/pie';
//
//
// // Define the props interface for CenteredMetric
// interface CenteredMetricProps {
//     centerX: number;
//     centerY: number;
// }
//
//
//
// const MyPieChart = (props: { applications: string[]; percentage: number[]; activityLevel: string }) => {
//
//     const colors = [
//         '#0088FE', '#00C4A0', '#FFBB28', '#FF8043', '#8884D8',
//         '#82C99D', '#A4DF6B', '#D1EC59', '#FFA07A', '#B1C3DD'
//     ];
//
//     const data = props.applications.map((label, index) => ({
//         id: label,
//         label: label,
//         value: props.percentage[index],
//     }));
//
//     const CenteredMetric: React.FC<CenteredMetricProps> = ({ centerX, centerY }) => (
//         <text
//             x={centerX}
//             y={centerY}
//             textAnchor="middle"
//             dominantBaseline="central"
//             style={{
//                 fontSize: '10px',
//                 fontWeight: 'bold',
//             }}
//         >
//             <tspan x={centerX} dy="-0.6em">Activity Level</tspan>
//             <tspan x={centerX} dy="1.2em">{props.activityLevel}</tspan>
//         </text>
//     );
//     return (
//         <div style={{ height: 250 }}>
//             <ResponsivePie
//                 data={data}
//                 margin={{ top: 0, right: 160, bottom: 30, left: 160 }}
//                 innerRadius={0.5}
//                 padAngle={1}
//                 cornerRadius={2}
//                 activeOuterRadiusOffset={10}
//                 // Use the custom colors array here
//                 colors={colors}
//                 borderWidth={1}
//                 borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
//                 arcLinkLabelsSkipAngle={8}
//                 arcLinkLabelsTextColor="#333333"
//                 arcLinkLabelsThickness={2}
//                 arcLinkLabelsColor={{ from: 'color' }}
//                 arcLinkLabel={(e) => `${e.id} (${e.value}%)`}
//                 arcLinkLabelsTextOffset={20}
//                 arcLinkLabelsDiagonalLength={0} // Shorter diagonal lines
//                 arcLinkLabelsStraightLength={0} // Shorter straight lines
//                 // Use arcLabelsComponent to show a color square next to the label
//                 // arcLabelsComponent={({ label, style }) => (
//                 //     <g transform={style.transform.toString()}>
//                 //         {/* Color square */}
//                 //         <rect width={12} height={12} fill={colors[data.findIndex(d => d.label === label)]} rx={2} ry={2} />
//                 //         {/* Label text */}
//                 //         <text
//                 //             dominantBaseline="central"
//                 //             style={{
//                 //                 fill: style.textColor,
//                 //                 fontSize: '12px',
//                 //                 fontWeight: 'bold',
//                 //                 marginLeft: '15px'
//                 //             }}
//                 //             x={16} // Adjust space between square and text
//                 //         >
//                 //             {label}
//                 //         </text>
//                 //     </g>
//                 // )}
//                 layers={['arcs', 'arcLinkLabels', CenteredMetric]}
//             />
//         </div>
//     )
// }
//
//
//
// export default MyPieChart;




// import React from 'react';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import highchartsMore from 'highcharts/highcharts-more';
//
// // Initialize Highcharts modules
// highchartsMore(Highcharts);
//
// interface PieChartProps {
//     applications: string[];
//     percentage: number[];
//     activityLevel: string;
// }
//
// const PieChart: React.FC<PieChartProps> = ({ applications, percentage, activityLevel }) => {
//     const colors = [
//         '#3266CC', '#DC3812', '#FE9900', '#0D9916', '#95019A',
//         '#0099C5', '#D9DCD1', '#69A900', '#FFBB28'
//     ];
//
//     const options: Highcharts.Options = {
//         chart: {
//             type: 'pie',
//             height: '230px',
//         },
//         title: {
//             text: undefined,
//         },
//         plotOptions: {
//             pie: {
//                 allowPointSelect: true,
//                 cursor: 'pointer',
//                 colors: colors,
//                 states: {
//                     hover: {
//                         enabled: true,
//                         shadow: false
//                     }
//                 },
//                 dataLabels: {
//                     enabled: true,
//                     format: '{point.name}: {point.percentage:.1f}%',
//                     // formatter: function () {
//                     //     const point = this.point as any;
//                     //     // Inside label: percentage
//                     //     if (this.distance < 0) {
//                     //         return point.percentage.toFixed(1) + '%';
//                     //     }
//                     //     // Outside label: application name
//                     //     return point.name;
//                     // },
//                     distance: 20,
//                     connectorPadding: 5,
//                     connectorWidth: 1,
//                     connectorColor: 'black',
//                     alignTo: 'plotEdges',
//                     style: {
//                         fontWeight: 'normal',
//                         fontSize: '9px',
//                         textOutline: 'none',
//                     }
//                 },
//                 showInLegend: false,
//                 size: '70%',
//                 center: ['50%', '50%'],
//                 borderWidth: 0,
//                 innerSize: '50%', // This creates the hole for a doughnut chart
//             },
//         },
//         series: [{
//             type: 'pie',
//             name: 'Activity Level',
//             data: applications.map((app, index) => ({
//                 name: app,
//                 y: percentage[index],
//             })),
//         }],
//         tooltip: {
//             pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
//             style: {
//                 zIndex: 1000, // Ensure the tooltip has a high z-index
//             }
//         },
//         credits: {
//             enabled: false,
//         },
//     };
//
//     return (
//         <div style={{ position: 'relative', width: '100%', height: '230px' }}>
//             <HighchartsReact highcharts={Highcharts} options={options} />
//             <div style={{
//                 position: 'absolute',
//                 top: '50%',
//                 left: '50%',
//                 transform: 'translate(-50%, -50%)',
//                 textAlign: 'center',
//                 pointerEvents: 'none'
//             }}>
//                 <div style={{ fontSize: '9px', fontWeight: 'bold' }}>Activity Level</div>
//                 <div style={{ fontSize: '12px', fontWeight: 'bold' }}>{activityLevel}</div>
//             </div>
//         </div>
//     );
// };
//
// export default PieChart;







