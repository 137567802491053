import * as yup from 'yup'
import { VALIDATION } from 'utils/globalConstant'

export const validationSchema = yup.object({
  type: yup.string().required(VALIDATION.required),
  reason: yup.string().required(VALIDATION.required),
  requested: yup.array().of(
    yup.object().shape({
      time_in_out: yup.string().required(VALIDATION.required),
      time_in_out_date: yup.string().required(VALIDATION.required),
      time_in_out_time: yup.string().required(VALIDATION.required),
    })
  )
})
