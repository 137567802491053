import * as React from "react"
const SvgComponent = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#ff7043"
            d="M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"
            opacity={0.15}
        />
        <path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m20 20-5.05-5.05m0 0a7 7 0 1 0-9.9-9.9 7 7 0 0 0 9.9 9.9ZM7 10h6"
        />
    </svg>
)
export default SvgComponent
