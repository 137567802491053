import React, {useEffect, useState} from 'react'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Stack,
  Table,
} from 'react-bootstrap'
import './style.css'
import {
  EditOutlined,
  FilePresentOutlined,
  SaveAsOutlined,
  ViewInArOutlined,
} from '@mui/icons-material'
import Pagination from 'components/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import {IAdjustment, IAdjustmentList, IAdjustmentRequest, IEmployee, IPayroll, IPayrollRange, IRole} from "./types";
import axios from "../../../api";
import {IAPI_Response} from "../../../api/types";
import {API_ENDPOINT, ERROR_MESSAGE, SUCCESS_MESSAGE} from "../../../utils/globalConstant";
import Select from "components/ReactSelect";
import moment from "moment/moment";
import {validationSchema} from "./validationSchema";
import { Formik, FieldArray} from 'formik';
import Calendar from "asset/SvgComponent/Calendar";
import Clock from "asset/SvgComponent/Clock";
import CircleCreate from "asset/SvgComponent/CircleCreate";
import Reject from "asset/SvgComponent/Reject";
import Delete from "asset/SvgComponent/Delete";
import Loader from "components/Loader";
import View from "asset/SvgComponent/View";
import EditIcon from "asset/SvgComponent/EditIcon";
import EditActive from "asset/SvgComponent/EditActive";

export default function Index() {
  const [fileName, setFileName] = React.useState('Upload Boundary File')
  const [roles, setRoles] = React.useState<IRole[]>([])
  const [employees, setEmployees] = React.useState<IEmployee[]>([])
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "Select Employee"})
  const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
  const [payrollRanges, setPayrollRanges] = useState<IPayrollRange[]>([])
  const [item, setItem] = useState<IAdjustment>({
    id: 0,
    employee_id: 0,
    payroll_id: 0,
    payroll: {
      id: 0,
      dayFrom: "",
      dayTo: "",
      payroll_cutoff_type: ""
    },
    total_amount: 0,
    list: [{
      id: 0,
      adjustment_id: 0,
      name_of_adjustment: "",
      amount: 0,
      description: ""
    }],
    employee: {
      employee_id: 0,
      first_name: "",
      last_name: ""
    }
  })

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)

  const [adjustments, setAdjustments] = useState<IAdjustment[]>([])
  const [searched, setSearched] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [show, setShow] = React.useState(false)
  const handleClose = () => {
    setShow(false)
    setSelectedEmployeeOption({value: 0, label: "Select Employee"})
  }
  const handleShow = () => setShow(true)

  const [showEdit, setShowEdit] = React.useState(false)
  const handleCloseEdit = () => setShowEdit(false)
  const handleShowEdit = () => setShowEdit(true)

  const handleChangeRole = async (e: any) => {
    getEmployeesByRole(e.target.value)
  }

  const handleChangeEmployeeDropdown = (selectedOption: any) => {
    setSelectedEmployeeOption(selectedOption)
  }

  const handleDisplayTotalAdjustment = (adjustments: any) => {
    let totalAdjustment = 0;
    adjustments.map((adj: any) => {
      if(adj.adjustment_amount != ""){
        console.log("amont", adj.adjustment_amount)
        totalAdjustment += adj.adjustment_amount;
      }

    })

    return <span>{totalAdjustment}</span>;
  }

  const calculateTotalAdjustment = (adjustments: any) => {
    let totalAdjustment = 0;
    adjustments.map((adj: any) => {
      if(adj.adjustment_amount != ""){
        console.log("amont", adj.adjustment_amount)
        totalAdjustment += adj.adjustment_amount;
      }

    })
    return totalAdjustment;
  }


  const handleViewAdjustment = async (id: number) => {
    try {
      const response = await axios.get<IAPI_Response<IAdjustment>>(API_ENDPOINT.SHOW_ADJUSTMENT +'/'+ id)
      const result = await response
      if (result?.data?.results) {
        setItem(result?.data?.results)
        setSelectedEmployeeOption({label: result?.data?.results?.employee?.first_name + " " + result?.data?.results?.employee?.last_name, value: result?.data?.results?.employee_id})
      }
      handleShowEdit()
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getAllRoles = async () => {
    try {
      const res = await axios.get<IAPI_Response<IRole[]>>(API_ENDPOINT.ALL_ROLE)
      const result = await res
      if (result?.data?.results?.length) {
        setRoles(result?.data?.results)

      }else{
        setRoles([])
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getEmployeesByRole = async (role_id: number) => {
    try {
      const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.GET_EMPLOYEES_BY_ROLE+"/"+role_id)
      const result = await res
      if (result?.data?.results?.length) {
        setEmployees(result?.data?.results)
        setEmployeeOptions([])
        setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
        result.data.results.map(employee => {
          setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
        })
      }else{
        setEmployees([])
        setEmployeeOptions([])
        setEmployeeOptions(current => [...current, {value: 0, label: "Select Employee"}]);
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getAllPayrollRange = async () => {
    try {
      const res = await axios.get<IAPI_Response<IPayrollRange[]>>(API_ENDPOINT.GET_ALL_PAYROLL_RANGE)
      const result = await res
      if (result?.data?.results?.length) {
        setPayrollRanges(result?.data?.results)

      }else{
        setPayrollRanges([])
      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const postAdjustment = async(values: any, {resetForm} : any) => {

    await axios.post(API_ENDPOINT.POST_ADJUSTMENT, {
      employee_id: selectedEmployeeOption.value,
      adjustments: values.adjustments,
      payroll_id: values.payroll_period,
      total_amount: calculateTotalAdjustment(values.adjustments)
    }).then(response => {
      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.ADJUSTMENT.CREATED)
        getAllAdjustments(null, searched);
      }
      resetForm({})
      handleClose();
    }).catch((err) => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    });

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const getAllAdjustments = async (page_number: null | number, searched: string) => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IAdjustmentRequest>>(API_ENDPOINT.GET_ADJUSTMENTS, {
        params: {
          page: page_number,
          searched: searched
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setAdjustments(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }else{
        setAdjustments([])
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  useEffect( () => {
    (async () => {
      getAllRoles()
      getAllPayrollRange()
      getAllAdjustments(null, searched)
    })()
  }, []);


  return (
    <>
      <Container fluid>
        <h5 className='fw-bold'>Adjustments</h5>
        <Breadcrumb className='breadcrumbs'>
          <Breadcrumb.Item className=' text-decoration-none' href='/home'>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/payroll-range'>
            Payroll
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Adjustments</Breadcrumb.Item>
        </Breadcrumb>
        <Card className='border-0  p-0 bordered-card'>
          <Card.Body>
            <Row>
              <Col sm={6}>
                <span className='fw-bold'>&nbsp;</span>
              </Col>
              <Col sm={6} className='d-flex justify-content-end hidden'>
                <InputGroup size='sm' className={'w-25'}>
                  <Form.Control
                    aria-label='search'
                    aria-describedby='inputGroup-sizing-sm'
                    placeholder='Search...'
                    onChange={(e:any) => {
                      setSearched(e.target.value)
                      getAllAdjustments(null, e.target.value)
                    }}
                  />
                  <InputGroup.Text className='bg-transparent search-input '>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
                <Button
                  onClick={handleShow}
                  className=' add-new-btn rounded mx-2 text-white'
                  variant='warning'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='17'
                    height='17'
                    viewBox='0 0 20.055 20.207'
                  >
                    <g id='Edit-Icon' transform='translate(0.5 0.652)'>
                      <g id='Icon_feather-edit' data-name='Icon feather-edit'>
                        <path
                          id='Path_44'
                          data-name='Path 44'
                          d='M11.523,6H4.894A1.894,1.894,0,0,0,3,7.894V21.152a1.894,1.894,0,0,0,1.894,1.894H18.152a1.894,1.894,0,0,0,1.894-1.894V14.523'
                          transform='translate(-3 -3.991)'
                          fill='none'
                          stroke='#fff'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='1'
                        />
                        <path
                          id='Path_45'
                          data-name='Path 45'
                          d='M21.943,3.406a2.009,2.009,0,1,1,2.841,2.841l-9,9L12,16.191l.947-3.788Z'
                          transform='translate(-6.318 -2.818)'
                          fill='none'
                          stroke='#fff'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='1'
                        />
                      </g>
                    </g>
                  </svg>
                  &nbsp;Add New Adjustments
                </Button>{' '}
              </Col>
            </Row>
            {/* <hr/>*/}
            <Table responsive className='bordered-tabs-top mt-3'>
              <thead>
                <tr className='text-center'>
                  <th style={{width: '5%'}}>#</th>
                  <th style={{ width: '20%' }}>Employee Name</th>
                  <th style={{ width: '20%' }}>Payroll Period</th>
                  <th style={{ width: '20%' }}>Payroll Type</th>
                  <th style={{ width: '20%' }}>Total</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                    adjustments.length > 1 ?
                        adjustments.map((adjustment, index) => (
                            <tr key={index}>
                              <td className={'text-center'}>{from + index}</td>
                              <td>{adjustment?.employee?.first_name} {adjustment?.employee?.last_name}</td>
                              <td className={'text-center'}>{moment(adjustment?.payroll?.dayFrom).format((localStorage.getItem('date_format') ?? "YYYY-MM-DD"))} to {moment(adjustment?.payroll?.dayTo).format((localStorage.getItem('date_format') ?? "YYYY-MM-DD"))}</td>
                              <td className={'text-center'}>{adjustment?.payroll?.payroll_cutoff_type}</td>
                              <td className={"text-right"}>{adjustment?.total_amount > 0 ? "+ " + adjustment?.total_amount : "- " + adjustment?.total_amount}</td>
                              <td className={'text-center'}>
                                <Button
                                    className=' p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                    onClick={() => handleViewAdjustment(adjustment.id)}
                                >
                                  <View/>
                                </Button>
                                {/*<Button*/}
                                {/*    className=' p-1 pt-0 pb-0'*/}
                                {/*    style={{ fontSize: '12px', color: 'white' }}*/}
                                {/*    variant=' btn-transparent'*/}
                                {/*    size='sm'*/}
                                {/*    onClick={handleEdit}*/}
                                {/*>*/}
                                {/*  <EditActive/>*/}
                                {/*</Button>*/}
                                <Button
                                    className=' p-1 pt-0 pb-0'
                                    style={{ fontSize: '12px', color: 'white' }}
                                    variant=' btn-transparent'
                                    size='sm'
                                >
                                  <Delete/>
                                </Button>
                              </td>
                            </tr>
                        ))
                        :
                        <tr>
                          <td className={'text-center'} colSpan={5}>No Data Available.</td>
                        </tr>
                }
              </tbody>
            </Table>
            <Pagination
                itemsCount={itemsCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                lastPage={lastPage}
                setCurrentPage={setCurrentPage}
                alwaysShown={false}
                pageClicked={(page: number) => {
                  getAllAdjustments(page, searched);
                }
                }
            />
            <Loader show={showLoading}/>
          </Card.Body>
        </Card>
        {/* ADD MODAL*/}
        <Formik
            initialValues={{
              role_id: '',
              // employee_id: '',
              payroll_period: '',
              adjustments: [{
                adjustment_name: '',
                adjustment_description: '',
                adjustment_amount: 0
              }]
            }}
            validationSchema={validationSchema}
            onSubmit={postAdjustment}
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (

              <Form id="adjustmentCreate" noValidate onSubmit={handleSubmit}>
                <Modal className='modal' centered size={'lg'} show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>Add New Adjustments</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Row>
                        <Col sm={4}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Role</Form.Label>
                            <Form.Select
                                size='sm'
                                name="role_id"
                                value={values.role_id}
                                onChange={(e: any) => {
                                  handleChange(e)
                                  handleChangeRole(e)
                                }}
                                onBlur={handleBlur}
                                isInvalid={touched.role_id && !!errors.role_id}
                            >
                              <option value="" disabled={true} selected>Please Select</option>
                              {
                                roles.map(role => (
                                    <option key={role.role_id} value={role.role_id}>{role.role_name}</option>
                                ))
                              }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {errors.role_id}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Employee</Form.Label>
                            <Select
                                name="employee_id"
                                value={selectedEmployeeOption}
                                options={employeeOptions}
                                onChange={(e: any) => {
                                  handleChangeEmployeeDropdown(e)
                                  // handleChangeDropdown(e);
                                  // handleSubmit();
                                }}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                            <Form.Label className='fw-bold'>Payroll Period</Form.Label>
                            <Form.Select
                                size='sm'
                                name="payroll_period"
                                value={values.payroll_period}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.payroll_period && !!errors.payroll_period}
                            >
                              <option value="" disabled={true} selected>Please Select</option>

                              {
                                payrollRanges.map(range => (
                                    <option key={range.id} value={range.id}>{moment(range.dayFrom).format((localStorage.getItem('date_format') ?? "YYYY-MM-DD"))} to {moment(range.dayTo).format((localStorage.getItem('date_format') ?? "YYYY-MM-DD"))}</option>
                                ))
                              }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {errors.payroll_period}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      {/*<Row>*/}
                      {/*  <Col sm={4}>*/}
                      {/*    <Form.Group className='mb-3' controlId='formHorizontalEmail'>*/}
                      {/*      <Form.Label className='fw-bold'>Adjustment Name</Form.Label>*/}
                      {/*      <Form.Control size='sm' type='text' placeholder=' Adjustment Name' />*/}
                      {/*    </Form.Group>*/}
                      {/*  </Col>*/}
                      {/*  <Col sm={4}>*/}
                      {/*    <Form.Group className='mb-3' controlId='formHorizontalEmail'>*/}
                      {/*      <Form.Label className='fw-bold'>Adjustment Description</Form.Label>*/}
                      {/*      <Form.Control size='sm' type='text' placeholder='Adjustment Description' />*/}
                      {/*    </Form.Group>*/}
                      {/*  </Col>*/}
                      {/*  <Col sm={4}>*/}
                      {/*    <Form.Group className='mb-3' controlId='formHorizontalEmail'>*/}
                      {/*      <Form.Label className='fw-bold'>Amount ( +/- )</Form.Label>*/}
                      {/*      <Form.Control size='sm' type='number' placeholder='0.00' />*/}
                      {/*    </Form.Group>*/}
                      {/*  </Col>*/}
                      {/*</Row>*/}
                      {/*<Row>*/}
                      {/*  <Col sm={6}>*/}
                      {/*    <span className='fw-bold'>&nbsp;</span>*/}
                      {/*  </Col>*/}
                      {/*  <Col sm={6} className='d-flex justify-content-end hidden'>*/}
                      {/*    <Button*/}
                      {/*        variant='warning'*/}
                      {/*        className=' text-white align-content-end '*/}
                      {/*        size={'sm'}*/}
                      {/*        // onClick={handleAddAdjustment}*/}
                      {/*    >*/}
                      {/*      <FontAwesomeIcon icon={faPlus} /> Add Adjustment*/}
                      {/*    </Button>*/}
                      {/*  </Col>*/}
                      {/*</Row>*/}
                      <Table className={'mt-3'} responsive>
                        <thead>
                        <tr>
                          <th  className={'text-center'}>Adjustment</th>
                          <th className={'text-center'}>Description</th>
                          <th className={'text-center'}>Amount ( +/- )</th>
                          <th style={{width: '10%'}} className={'text-center'}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                              name="adjustments"
                              render={arrayHelpers => (
                                  <>
                                    {values.adjustments.map((item: any, index: any) => (
                                        <tr key={index}>
                                          {(() => {
                                            const ticketErrors = errors.adjustments?.length && errors.adjustments[index] || {};
                                            const ticketTouched = touched.adjustments?.length && touched.adjustments[index] || {};
                                            return(
                                                <>
                                                  <td>
                                                    <Form.Group className="position-relative">
                                                      {/*<Form.Label column="sm" className="fw-bold required">Name</Form.Label>*/}
                                                      <InputGroup size={'sm'}>
                                                        <Form.Control
                                                            style={{width: '120%'}}
                                                            size='sm'
                                                            type='text'
                                                            autoComplete={"off"}
                                                            placeholder='Name'
                                                            name={`adjustments.${index}.adjustment_name`}
                                                            value={item.adjustment_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.adjustment_name : false) && !!ticketTouched.adjustment_name}
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </td>
                                                  <td>
                                                    <Form.Group className="position-relative">
                                                      {/*<Form.Label column="sm" className="fw-bold required">Description</Form.Label>*/}
                                                      <InputGroup size={'sm'}>
                                                        <Form.Control
                                                            size='sm'
                                                            type='text'
                                                            as='textarea'
                                                            rows={2}
                                                            autoComplete={"off"}
                                                            placeholder='Description'
                                                            name={`adjustments.${index}.adjustment_description`}
                                                            value={item.adjustment_description}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.adjustment_description : false) && !!ticketTouched.adjustment_description}
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </td>
                                                  <td>
                                                    <Form.Group className="position-relative">
                                                      {/*<Form.Label column="sm" className="fw-bold required">Amount</Form.Label>*/}
                                                      <InputGroup size={'sm'}>
                                                        <Form.Control
                                                            className={'text-right'}
                                                            size='sm'
                                                            type='number'
                                                            autoComplete={"off"}
                                                            placeholder='0'
                                                            name={`adjustments.${index}.adjustment_amount`}
                                                            value={item.adjustment_amount}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={((typeof ticketErrors === 'object') ? !!ticketErrors.adjustment_amount : false) && !!ticketTouched.adjustment_amount}
                                                        />
                                                      </InputGroup>
                                                    </Form.Group>
                                                  </td>
                                                  <td>
                                                    <Col sm={12} className='d-flex justify-content-end hidden'>
                                                      <Button
                                                          className=' p-1 pt-0 pb-0'
                                                          style={{ fontSize: '12px', color: 'white' }}
                                                          variant=' btn-transparent'
                                                          size='sm'
                                                          title={'Add Adjustment'}
                                                          onClick={() => arrayHelpers.push({
                                                            adjustment_name: '',
                                                            adjustment_description: '',
                                                            adjustment_amount: 0,
                                                          })}
                                                      >
                                                        <CircleCreate />
                                                      </Button>
                                                      <Button
                                                          className='p-1 pt-0 pb-0'
                                                          style={{ fontSize: '12px' }}
                                                          variant='transparent'
                                                          size='sm'
                                                          title={'Delete Adjustment'}
                                                          disabled={values.adjustments.length == 1}
                                                          onClick={() => arrayHelpers.remove(index)}
                                                      >
                                                        <Delete/>
                                                      </Button>
                                                    </Col>
                                                  </td>
                                                </>
                                            )
                                          })()}

                                        </tr>
                                    ))}
                                  </>
                              )}
                          />
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colSpan={2} className={'text-right'}>Total Adjustment</th>
                            <th className={'text-right'}>{handleDisplayTotalAdjustment(values.adjustments)}</th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </Table>
                      <br/>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='outline-primary' size={'sm'} onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                        variant='primary text-white'
                        form="adjustmentCreate"
                        className='mx-2'
                        size={'sm'}
                        type="submit"
                        disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
          )}
        </Formik>
        {/* DETAIL MODAL*/}
        <Modal className='modal' size={'lg'} centered show={showEdit} onHide={handleCloseEdit}>
          <Modal.Header closeButton>
            <Modal.Title className='fw-bold'>Adjustment Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={4}>
                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                  <Form.Label className='fw-bold'>Role</Form.Label>
                  <Form.Select
                      size='sm'
                      name="role_id"
                      // value={item.role_id}
                      disabled={true}
                  >
                    <option value="" disabled={true} selected>Please Select</option>
                    {
                      roles.map(role => (
                          <option key={role.role_id} value={role.role_id}>{role.role_name}</option>
                      ))
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                  <Form.Label className='fw-bold'>Employee</Form.Label>
                  <Select
                      name="employee_id"
                      value={selectedEmployeeOption}
                      options={employeeOptions}
                      onChange={(e: any) => {
                        handleChangeEmployeeDropdown(e)
                        // handleChangeDropdown(e);
                        // handleSubmit();
                      }}
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                  <Form.Label className='fw-bold'>Payroll Period</Form.Label>
                  <Form.Select
                      size='sm'
                      name="payroll_period"
                      value={item.payroll_id}
                      disabled={true}
                  >
                    <option value="" disabled={true} selected>Please Select</option>

                    {
                      payrollRanges.map(range => (
                          <option key={range.id} value={range.id}>{moment(range.dayFrom).format((localStorage.getItem('date_format') ?? "YYYY-MM-DD"))} to {moment(range.dayTo).format((localStorage.getItem('date_format') ?? "YYYY-MM-DD"))}</option>
                      ))
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Table className={'mt-3'} responsive>
              <thead>
              <tr>
                <th  className={'text-center'}>Adjustment</th>
                <th className={'text-center'}>Description</th>
                <th className={'text-center'}>Amount ( +/- )</th>
              </tr>
              </thead>
              <tbody>
                {
                    item.list.length > 0 ?
                        item.list.map((adjustment, index) => (
                            <tr key={index}>
                              <td>{adjustment.name_of_adjustment}</td>
                              <td>{adjustment.description}</td>
                              <td>{adjustment.amount > 0 ? "+" : ""}{adjustment.amount}</td>
                            </tr>
                        ))
                        :
                        <tr>
                          <td colSpan={3} className={'text-center'}>No Data Available.</td>
                        </tr>
                }
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={2} className={'text-right'}>Total Adjustment</th>
                  <th>{item.total_amount > 0 ? "+" : ""}{item.total_amount}</th>
                </tr>
              </tfoot>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <br />
      </Container>
    </>
  )
}
