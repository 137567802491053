import React, { useEffect, useState, useRef } from 'react'
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Container,
    Form,
    InputGroup,
    Modal,
    Row,
    Table,
    ButtonToolbar,
    ToggleButtonGroup,
    ToggleButton
} from 'react-bootstrap'
import './style.css'
import { Formik } from 'formik';
import View from 'asset/SvgComponent/View'
import Delete from 'asset/SvgComponent/Delete'
import WriteBlackIcon from 'asset/SvgComponent/WriteBlackIcon'
import Calendar from 'asset/SvgComponent/Calendar'
import CalendarWhite from 'asset/SvgComponent/CalendarWhite'
import Attached from 'asset/SvgComponent/Attached'
import Download from 'asset/SvgComponent/Download'
import CopyIcon from 'asset/SvgComponent/CopyIcon'
import PasteIcon from 'asset/SvgComponent/PasteIcon'
import ResetIcon from 'asset/SvgComponent/ResetIcon'
import BlackLocationIcon from 'asset/SvgComponent/BlackLocationIcon'
import ClockBlackIcon from 'asset/SvgComponent/ClockBlackIcon'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Dialog from 'components/Dialog'
import axios from 'api/index'
import { IAPI_Response } from 'api/types'
import {
    API_ENDPOINT,
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    TEXT
} from 'utils/globalConstant'
import { validationSchema, validationSchema2 } from './validationSchema'
import {
    INotice,
    IList,
    IItem,
    IBranch,
    IEmployee,
    IEmployeeList,
    IHoursShift,
    SchedulingData,
    ILeaveDetails, IEmployeeScheduleList, IEmployeeSchedule, IEmployeeLeaveList, IEmployeeLeave, ICalendarDataForDay
} from './types'
import moment from "moment";
import Select from "components/ReactSelect";
import {Avatar} from "@mui/material";
import Stack from "@mui/material/Stack";
import ArrowUp from "asset/SvgComponent/ArrowUp";
import ArrowDown from "asset/SvgComponent/ArrowDown";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeIcon from 'asset/SvgComponent/HomeIcon';
import HomeIconWhite from 'asset/SvgComponent/HomeIconWhite';
import DefaultView from 'asset/SvgComponent/DefaultView'
import DefaultViewWhite from 'asset/SvgComponent/DefaultViewWhite'
import CircleCreate from "asset/SvgComponent/CircleCreate";

export default function Index() {
    const [view, setView] = useState("default");
    const tableContainerRef = useRef<HTMLInputElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    const [buttonPosition, setButtonPosition] = useState({ top: '50%' });
    const [showRightButton, setShowRightButton] = useState(true)
    const [showLeftButton, setShowLeftButton] = useState(false)

    const [currentPage, setCurrentPage] = React.useState(0)
    const [lastPage, setLastPage] = React.useState(0)
    const [itemsPerPage, setPerPage] = React.useState(10)
    const [itemsCount, setItemCount] = React.useState(0)
    const [from, setFrom] = React.useState(0)

    const [currentPage1, setCurrentPage1] = React.useState(0)
    const [lastPage1, setLastPage1] = React.useState(0)
    const [itemsPerPage1, setPerPage1] = React.useState(10)
    const [itemsCount1, setItemCount1] = React.useState(0)
    const [from1, setFrom1] = React.useState(0)

    const [currentPage2, setCurrentPage2] = React.useState(0)
    const [lastPage2, setLastPage2] = React.useState(0)
    const [itemsPerPage2, setPerPage2] = React.useState(10)
    const [itemsCount2, setItemCount2] = React.useState(0)
    const [from2, setFrom2] = React.useState(0)

    const [error, setError] = React.useState('')
    const [success, setSuccess] = React.useState('')
    const [showError, setShowError] = React.useState(false)
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [showLoading, setShowLoading] = React.useState(false)
    const [showCopy, setShowCopy] = useState(false)

    const [showCreate, setShowCreate] = React.useState(false)
    const handleCloseCreate = () => setShowCreate(false)
    const handleShowCreate = () => setShowCreate(true)

    const [showSelectBranch, setShowSelectBranch] = React.useState(false)
    const handleCloseSelectBranch = () => setShowSelectBranch(false)
    const handleShowSelectBranch = () => setShowSelectBranch(true)

    const [showSelectShift, setShowSelectShift] = React.useState(false)
    const handleCloseSelectShift = () => setShowSelectShift(false)
    const handleShowSelectShift = () => setShowSelectShift(true)

    const [showAddNote, setShowAddNote] = React.useState(false)
    const handleCloseAddNote = () => setShowAddNote(false)
    const handleShowAddNote = () => setShowAddNote(true)

    const [showEdit, setShowEdit] = React.useState(false)
    const handleCloseEdit = () => setShowEdit(false)
    const handleShowEdit = () => setShowEdit(true)

    const [status, setStatus] = React.useState(0)
    const [entries, setEntries] = React.useState(10)
    const [entriesEmployeesAssigned, setEntriesEmployeesAssigned] = React.useState(10)
    const [entriesEmployeesLeave, setEntriesEmployeesLeave] = React.useState(10)

    const [showConfirm, setShowConfirm] = React.useState(false)
    const handleCloseConfirm = () => setShowConfirm(false)
    const handleShowConfirm = (id: any) => {
        setShowConfirm(true)
    }
    const [branches, setBranch] = useState<IBranch[]>([
        {
            branch_id: 0,
            branch_name: '',
            created_at: '',
            updated_at: ''
        }
    ])
    const [employees, setEmployees] = useState<IEmployee[]>([
        {
            employee_id: 0,
            first_name: '',
            last_name: '',
            branch_id: 0,
            branch: {
                branch_id: 0,
                branch_name: '',
                created_at: '',
                updated_at: ''
            },
            schedule: [{
                id: 0,
                employee_id: 0,
                schedule_date: "",
                branch_id: 0,
                branch_name: "",
                shift_id: 0,
                shift_name: "",
                start_from: "",
                end_to: "",
                is_rest_day: 0,
                notes: "",
                hours_shift_name: "",
                leave_details: {
                    isHalfOrWholeDay: "",
                    purpose: ""
                }
            }],
            photo: ""
        }
    ])
    const [dateFormat, setDateFormat] = useState("");
    // @ts-ignore
    const [days, setDays] = useState<moment[]>([])
    const [branchId, setBranchId] = useState(0);
    const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "All Employees"})
    const [selectedEmployeeOptionAssignSchedule, setSelectedEmployeeOptionAssignSchedule] = useState<{value: number, label: string}>({value: 0, label: "All Employees"})
    const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
    const [hoursShift, setHoursShift] = useState<IHoursShift[]>([])
    const [data, setData] = useState<SchedulingData[]>([])
    const [range, setRange] = useState(1);
    const [sortColumn, setSortColumn] = React.useState("employee_name")
    const [sort, setSort] = React.useState("asc")
    const [scheduleData, setScheduleData] = useState({
        employee_id: 0,
        date: ""
    })
    const [selectedShift, setSelectedShift] = useState({
        shift_id: 0,
        shift_name: "",
        hours_shift_name: ""
    })
    const [selectedBranch, setSelectedBranch] = useState({
        branch_id: 0,
        branch_name: ""
    })
    const [copiedData, setCopiedData] = useState({
        shift_id: 0,
        shift_name: "",
        branch_id: 0,
        branch_name: "",
        copy: false,
        notes: ""
    })
    const [dateRange, setDateRange] = useState({
        date_from: moment().format("YYYY-MM-DD"),
        date_to: moment().add(6, 'days').format("YYYY-MM-DD")
    })
    const [notes, setNotes] = useState("")
    const [draggedData, setDraggedData] = useState({
        shift_id: 0,
        shift_name: "",
        branch_id: 0,
        branch_name: "",
        copy: false,
        notes: ""
    })
    const [calendarData, setCalendarData] = useState<any>([])
    const [calendarDataForDay, setCalendarDataForDay] = useState<ICalendarDataForDay>({
        actual_date: "",
        dayNumber: "",
        employees_assigned: [],
        employees_leave: [],
        includedOnTheMonth: false
    })
    const [calendarType, setCalendarType] = useState("month")
    const [showEmployeeAssignedList, setShowEmployeeAssignedList] = useState(false)
    const handleShowEmployeeAssignedList = () => setShowEmployeeAssignedList(true)
    const handleCloseEmployeeAssignedList = () => setShowEmployeeAssignedList(false)
    const [showEmployeeLeaveList, setShowEmployeeLeaveList] = useState(false)
    const handleShowEmployeeLeaveList = () => setShowEmployeeLeaveList(true)
    const handleCloseEmployeeLeaveList = () => setShowEmployeeLeaveList(false)
    const [showAssignedEmployeeForm, setShowAssignedEmployeeForm] = useState(false)
    const handleShowAssignedEmployeeForm = () => setShowAssignedEmployeeForm(true)
    const handleCloseAssignedEmployeeForm = () => {
        setShowAssignedEmployeeForm(false)
        setSelectedEmployeeOptionAssignSchedule({value: 0, label: "All Employees"})
        setNewScheduleData({
            actual_date: "",
            employee_id: 0,
            branch_id: 0,
            shift_id: 0,
            notes: ""
        })
    }
    const [employeesAssigned, setEmployeesAssigned] = useState<IEmployeeSchedule[]>([])
    const [employeesLeave, setEmployeesLeave] = useState<IEmployeeLeave[]>([])
    const [actualDate, setActualDate] = useState("")
    const [newScheduleData, setNewScheduleData] = useState<{actual_date: string, employee_id: number, branch_id: number, shift_id: number, notes: string}>({
        actual_date: "",
        employee_id: 0,
        branch_id: 0,
        shift_id: 0,
        notes: ""
    })
    const [currentMonthYear, setCurrentMonthYear] = useState({
        month: moment().format('M'),
        year: moment().format('YYYY'),
        start_week: moment().startOf('week').format('YYYY-MM-DD'),
        end_week: moment().endOf('week').format('YYYY-MM-DD'),
        current_date: moment().format('YYYY-MM-DD')
    })



    const handlePreviousMonth = () => {

        if(calendarType == "month"){
            // Create a moment object with the given month and year
            const date = moment(`${currentMonthYear.year}-${currentMonthYear.month}`, 'YYYY-MM');

            // Subtract one month
            const previousDate = date.subtract(1, 'months');

            setCurrentMonthYear({...currentMonthYear, month: previousDate.format('MM'), year: previousDate.format('YYYY')})

            getEmployeeCalendarView(status, branchId, calendarType, {month: previousDate.format('MM'), year: previousDate.format('YYYY'), start_week: currentMonthYear.start_week, end_week :currentMonthYear.end_week, current_date: currentMonthYear.current_date})

        }else if(calendarType == "week"){
            const previousEndWeek = moment(currentMonthYear.start_week).subtract(1, 'days').format("YYYY-MM-DD");
            const previousStartWeek = moment(currentMonthYear.start_week).subtract(7, 'days').format("YYYY-MM-DD");

            setCurrentMonthYear({...currentMonthYear, start_week: previousStartWeek, end_week: previousEndWeek})
            getEmployeeCalendarView(status, branchId, calendarType, {start_week: previousStartWeek, end_week: previousEndWeek, month: currentMonthYear.month, year :currentMonthYear.year, current_date: currentMonthYear.current_date})

        }else if(calendarType == "day"){

            const previousDate = moment(currentMonthYear.current_date).subtract(1, 'days').format("YYYY-MM-DD");
            setCurrentMonthYear({...currentMonthYear, current_date: previousDate})
            getEmployeeCalendarView(status, branchId, calendarType, {current_date: previousDate, start_week: currentMonthYear.start_week, end_week: currentMonthYear.end_week, month: currentMonthYear.month, year :currentMonthYear.year})
        }

    }

    const handleNextMonth = () => {

        if(calendarType == "month"){
            // Create a moment object with the given month and year
            const date = moment(`${currentMonthYear.year}-${currentMonthYear.month}`, 'YYYY-MM');

            // Subtract one month
            const nextDate = date.add(1, 'months');

            setCurrentMonthYear({...currentMonthYear, month: nextDate.format('MM'), year: nextDate.format('YYYY')})

            getEmployeeCalendarView(status, branchId, calendarType, {month: nextDate.format('MM'), year: nextDate.format('YYYY'), start_week: currentMonthYear.start_week, end_week :currentMonthYear.end_week, current_date: currentMonthYear.current_date})

        }else if(calendarType == "week"){

            const nextEndWeek = moment(currentMonthYear.end_week).add(7, 'days').format("YYYY-MM-DD");
            const nextStartWeek = moment(currentMonthYear.end_week).add(1, 'days').format("YYYY-MM-DD");

            setCurrentMonthYear({...currentMonthYear, start_week: nextStartWeek, end_week: nextEndWeek})
            getEmployeeCalendarView(status, branchId, calendarType, {start_week: nextStartWeek, end_week: nextEndWeek, month: currentMonthYear.month, year :currentMonthYear.year, current_date: currentMonthYear.current_date})

        }else if(calendarType == "day"){

            const nextDate = moment(currentMonthYear.current_date).add(1, 'days').format("YYYY-MM-DD");
            setCurrentMonthYear({...currentMonthYear, current_date: nextDate})
            getEmployeeCalendarView(status, branchId, calendarType, {current_date: nextDate, start_week: currentMonthYear.start_week, end_week: currentMonthYear.end_week, month: currentMonthYear.month, year :currentMonthYear.year})
        }

    }

    const handleAssignedEmployee = (actual_date: string) => {
        setNewScheduleData({...newScheduleData, actual_date: actual_date})
        handleShowAssignedEmployeeForm()
    }

    const handleChangeCalendarType = (e: any) => {
        setCalendarType(e.target.value)
        getEmployeeCalendarView(status, branchId, e.target.value, currentMonthYear)
    }

    const handleScrollRight = () => {

        if (tableContainerRef.current) {
            tableContainerRef.current.scrollBy({
                top: 0,
                left: 200, // Adjust the value as needed
                behavior: 'smooth'
            });

        }

    }

    const handleScrollLeft = () => {

        if (tableContainerRef.current) {
            tableContainerRef.current.scrollBy({
                top: 0,
                left: -200, // Adjust the value as needed
                behavior: 'smooth'
            });

        }

    }

    const checkScrollPosition = () => {
        if (tableContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = tableContainerRef.current;
            if(scrollWidth - clientWidth != 0){
                setShowLeftButton(scrollLeft > 0);
                setShowRightButton(Math.round(scrollLeft + 1) < scrollWidth - clientWidth);
            }


        }
    };


    const handleChangeEntries = (e: any) => {
        setEntries(e.target.value)
        getAllEmployees(null, branchId, dateRange.date_from, dateRange.date_to, sortColumn, sort, status, e.target.value);
    }

    const handleChangeEntriesEmployeesAssigned = (e: any) => {
        setEntriesEmployeesAssigned(e.target.value)
        handleShowEmployeesAssigned(null, actualDate, e.target.value);
    }

    const handleChangeEntriesEmployeesLeave = (e: any) => {
        setEntriesEmployeesLeave(e.target.value)
        handleShowEmployeeLeave(null, actualDate, e.target.value);
    }

    const handleSortingChange = (column: string) => {
        let sortType = ""
        setSortColumn(column)
        if(sort == ""){
            setSort("asc")
            sortType = "asc";
        }else if(sort == "asc"){
            setSort("desc")
            sortType = "desc";
        }else {
            setSort("asc")
            sortType = "asc";
        }
        getAllEmployees(null,0, dateRange.date_from, dateRange.date_to, column, sortType, status, entries)
    }

    const handleDragStart = (e: any, shift_id: number, shift_name: string, branch_id: number, branch_name: string, notes: string) => {
        setDraggedData({
            shift_id: shift_id,
            shift_name: shift_name,
            branch_id: branch_id,
            branch_name: branch_name,
            copy: !copiedData,
            notes: notes
        })
    }

    const handleDragOver = (e: any) => {
        e.preventDefault();

    }

    const handleDrop = (e: any, employeeId: number, date: string) => {


        if(draggedData.branch_id != 0 || draggedData.shift_id != 0){
            const employeeData = employees;
            employeeData.map(employee => {
                if(employee.employee_id == employeeId){
                    employee.schedule.map(sched => {
                        if(sched.schedule_date == date){
                            sched.shift_id = draggedData.shift_id
                            sched.shift_name = draggedData.shift_name
                            sched.branch_id = draggedData.branch_id
                            sched.branch_name = draggedData.branch_name
                            sched.notes = draggedData.notes

                            autoSave(employeeId, date, draggedData.branch_id, draggedData.shift_id, draggedData.notes)
                        }
                    })
                }
            })

        }

        setDraggedData({
            shift_id: 0,
            shift_name: "",
            branch_id: 0,
            branch_name: "",
            copy: false,
            notes: ""
        })
    }

    const displayDayDataAssignedEmployees = () => {
        if(calendarDataForDay.employees_assigned.length >= 4){
            const dataPerRow = calendarDataForDay.employees_assigned.length / 4;
            const firstIndex = dataPerRow - 1;
            const secondIndex = firstIndex + dataPerRow;
            const thirdIndex = secondIndex + dataPerRow;
            const fourthIndex = thirdIndex + dataPerRow;

            return (
                <>
                    <Col sm={3} className='text-left'>
                        {
                            calendarDataForDay.employees_assigned.map((employee, index) => (
                                index <= firstIndex?
                                    <Stack key={index} direction='row' style={{cursor: 'pointer'}}>
                                        <div>{index + 1}. &nbsp;</div>
                                        <div>
                                            {
                                                employee.photo != "" ?
                                                    <Avatar
                                                        sx={{width: 20, height: 20}}
                                                        src={process.env.REACT_APP_IMAGE_URL + employee.photo}
                                                        className={"profile-avatar"}
                                                    ></Avatar> :
                                                    <Avatar
                                                        sx={{width: 20, height: 20}}
                                                        src={""}
                                                        className={"profile-avatar"}
                                                    ></Avatar>
                                            }
                                        </div>
                                        &nbsp;
                                        <Button
                                            className="mb-1 p-1 pt-0 pb-0"
                                            style={{fontSize: '12px', color: 'white'}}
                                            variant="success"
                                            size="sm"
                                        >
                                            {employee.last_name}, {employee.first_name}
                                        </Button>
                                    </Stack>
                                    : ""


                            ))
                        }
                    </Col>
                    <Col sm={3} className='text-left'>
                        {
                            calendarDataForDay.employees_assigned.map((employee, index) => (
                                index <= secondIndex && index > firstIndex ?
                                    <Stack key={index} direction='row' style={{cursor: 'pointer'}}>
                                        <div>{index + 1}. &nbsp;</div>
                                        <div>
                                            {
                                                employee.photo != "" ?
                                                    <Avatar
                                                        sx={{width: 20, height: 20}}
                                                        src={process.env.REACT_APP_IMAGE_URL + employee.photo}
                                                        className={"profile-avatar"}
                                                    ></Avatar> :
                                                    <Avatar
                                                        sx={{width: 20, height: 20}}
                                                        src={""}
                                                        className={"profile-avatar"}
                                                    ></Avatar>
                                            }
                                        </div>
                                        &nbsp;
                                        <Button
                                            className="mb-1 p-1 pt-0 pb-0"
                                            style={{fontSize: '12px', color: 'white'}}
                                            variant="success"
                                            size="sm"
                                        >
                                            {employee.last_name}, {employee.first_name}
                                        </Button>
                                    </Stack>
                                    : ""


                            ))
                        }
                    </Col>
                    <Col sm={3} className='text-left'>
                        {
                            calendarDataForDay.employees_assigned.map((employee, index) => (
                                index <= thirdIndex && index > secondIndex?
                                    <Stack key={index} direction='row' style={{cursor: 'pointer'}}>
                                        <div>{index + 1}. &nbsp;</div>
                                        <div>
                                            {
                                                employee.photo != "" ?
                                                    <Avatar
                                                        sx={{width: 20, height: 20}}
                                                        src={process.env.REACT_APP_IMAGE_URL + employee.photo}
                                                        className={"profile-avatar"}
                                                    ></Avatar> :
                                                    <Avatar
                                                        sx={{width: 20, height: 20}}
                                                        src={""}
                                                        className={"profile-avatar"}
                                                    ></Avatar>
                                            }
                                        </div>
                                        &nbsp;
                                        <Button
                                            className="mb-1 p-1 pt-0 pb-0"
                                            style={{fontSize: '12px', color: 'white'}}
                                            variant="success"
                                            size="sm"
                                        >
                                            {employee.last_name}, {employee.first_name}
                                        </Button>
                                    </Stack>
                                    : ""


                            ))
                        }
                    </Col>
                    <Col sm={3} className='text-left'>
                        {
                            calendarDataForDay.employees_assigned.map((employee, index) => (
                                index <= fourthIndex && index > thirdIndex?
                                    <Stack key={index} direction='row' style={{cursor: 'pointer'}}>
                                        <div>{index + 1}. &nbsp;</div>
                                        <div>
                                            {
                                                employee.photo != "" ?
                                                    <Avatar
                                                        sx={{width: 20, height: 20}}
                                                        src={process.env.REACT_APP_IMAGE_URL + employee.photo}
                                                        className={"profile-avatar"}
                                                    ></Avatar> :
                                                    <Avatar
                                                        sx={{width: 20, height: 20}}
                                                        src={""}
                                                        className={"profile-avatar"}
                                                    ></Avatar>
                                            }
                                        </div>
                                        &nbsp;
                                        <Button
                                            className="mb-1 p-1 pt-0 pb-0"
                                            style={{fontSize: '12px', color: 'white'}}
                                            variant="success"
                                            size="sm"
                                        >
                                            {employee.last_name}, {employee.first_name}
                                        </Button>
                                    </Stack>
                                    : ""


                            ))
                        }
                    </Col>
                </>

            )
        }else if(calendarDataForDay.employees_assigned.length > 0 && calendarDataForDay.employees_assigned.length < 4){
            return (
                <>
                    <Col sm={5}></Col>
                    <Col sm={2}  className='text-center'>
                        {
                            calendarDataForDay.employees_assigned.map((employee, index) => (

                                <Stack key={index} direction='row' style={{cursor: 'pointer'}}>
                                    <div>{index + 1}. &nbsp;</div>
                                    <div>
                                        {
                                            employee.photo != "" ?
                                                <Avatar
                                                    sx={{width: 20, height: 20}}
                                                    src={process.env.REACT_APP_IMAGE_URL + employee.photo}
                                                    className={"profile-avatar"}
                                                ></Avatar> :
                                                <Avatar
                                                    sx={{width: 20, height: 20}}
                                                    src={""}
                                                    className={"profile-avatar"}
                                                ></Avatar>
                                        }
                                    </div>
                                    &nbsp;
                                    <Button
                                        className="mb-1 p-1 pt-0 pb-0"
                                        style={{fontSize: '12px', color: 'white'}}
                                        variant="success"
                                        size="sm"
                                    >
                                        {employee.last_name}, {employee.first_name}
                                    </Button>
                                </Stack>

                            ))
                        }
                    </Col>
                </>


            )
        }else if(calendarDataForDay.employees_assigned.length == 0){
            return (
                <>
                    <Col sm={5}></Col>
                    <Col sm={2} >
                        <Button
                            className="mb-1 p-1 pt-0 pb-0"
                            style={{fontSize: '12px', color: 'white'}}
                            variant="secondary"
                            size="sm"
                        >
                            No Employee
                        </Button>
                    </Col>
                </>
            )
        }


    }

    const handleDisplay = (name: string, type: string, shiftId: number, leaveDetails: ILeaveDetails) => {
        if(type != "hours_shift_name"){
            if(name == ""){
                return <span>{type == "shift" ? "Select Shift" : "Select Branch"}</span>
            }else{
                if(name == "Rest Day"){
                    return <Button
                        className=" p-1 pt-0 pb-0"
                        style={{fontSize: '10px', color: 'white'}}
                        variant="secondary"
                        size="sm"
                    >{name}</Button>
                }else if(name.includes("Leave")){
                    return (
                        <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover id={`popover-positioned-right`} style={{fontSize: '10px', width: '200px'}}>
                                    <Popover.Body>
                                        <Row>
                                            <Col sm={4}><b>Day:</b></Col>
                                            <Col>{leaveDetails.isHalfOrWholeDay == "first_half" ? "First Half" :
                                            leaveDetails.isHalfOrWholeDay == "second_half" ? "Second Half" :
                                            "Whole Day"}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={4}><b>Reason:</b></Col>
                                            <Col className="display-purpose">{leaveDetails.purpose}</Col>
                                        </Row>

                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <Button
                                className=" p-1 pt-0 pb-0"
                                style={{fontSize: '10px', color: 'white'}}
                                variant="info"
                                size="sm"
                            >{name}
                            </Button>
                        </OverlayTrigger>
                    )
                }else{
                    return <Button
                        className=" p-1 pt-0 pb-0"
                        style={{fontSize: '10px', color: 'white'}}
                        variant={shiftId == -1 ? "secondary" :"success"}
                        size="sm"
                    >{name}</Button>
                }

            }
        }else{
            if(name == "" || name == null){
                return <span>-</span>
            }else{
                return <span>( {name} )</span>
            }

        }

    }

    const handleDisplayNotes = (notes: string) => {
        if(notes == ""){
            return <span title={"Add Note"}>{"Add Note"}</span>
        }else{
            return <span title={notes}>{notes}</span>
        }
    }


    const handleSelectedShift = (e: any) => {
        const index = e.nativeEvent.target.selectedIndex;
        const label = e.nativeEvent.target[index].text
        let hours_shift_name = "";
        hoursShift.map(shift => {
            if(shift.id == e.target.value){
                hours_shift_name = shift.shift_name.shift_name

            }
        })

        setSelectedShift({
            shift_id: e.target.value,
            shift_name: label,
            hours_shift_name: hours_shift_name
        })

        handleSaveShift(e.target.value, label, hours_shift_name)
    }

    const handleSelectedBranch = (e: any) => {
        const index = e.nativeEvent.target.selectedIndex;
        const label = e.nativeEvent.target[index].text
        setSelectedBranch({
            branch_id: e.target.value,
            branch_name: label
        })

        handleSaveBranch(e.target.value, label)
    }


    const handleSaveShift = (shiftId: number, shiftName: string, hoursShiftName: string) => {
        const employeeData = employees;
        employeeData.map(employee => {
            if(employee.employee_id == scheduleData.employee_id){
                employee.schedule.map(sched => {
                    if(sched.schedule_date == scheduleData.date){
                        sched.shift_id = shiftId
                        sched.shift_name = shiftName
                        sched.hours_shift_name = hoursShiftName

                    }
                })
            }
        })

        setEmployees(employeeData)
        handleCloseSelectShift()
        autoSave(scheduleData.employee_id, scheduleData.date, 0, shiftId, "")
    }

    const handleSaveBranch = (branchId: number, branchName: string) => {
        const employeeData = employees;
        employeeData.map(employee => {
            if(employee.employee_id == scheduleData.employee_id){
                employee.schedule.map(sched => {
                    if(sched.schedule_date == scheduleData.date){
                        console.log("Save branch")
                        sched.branch_id = branchId
                        sched.branch_name = branchName
                        sched.shift_id = 0
                        sched.shift_name = ""
                    }
                })
            }
        })

        setEmployees(employeeData)
        handleCloseSelectBranch()
        autoSave(scheduleData.employee_id, scheduleData.date, branchId, 0, "")
    }

    const handleSaveNotes = () => {
        const employeeData = employees;
        employeeData.map(employee => {
            if(employee.employee_id == scheduleData.employee_id){
                employee.schedule.map(sched => {
                    if(sched.schedule_date == scheduleData.date){
                        sched.notes = notes
                    }
                })
            }
        })

        setEmployees(employeeData)
        handleCloseAddNote()
        autoSave(scheduleData.employee_id, scheduleData.date, 0, selectedShift.shift_id, notes)
    }

    const handleClickShift = (date: string, employeeId: number, branchId: number) => {
        getAllShifts(branchId)
        handleShowSelectShift()
        setScheduleData({
            employee_id: employeeId,
            date: date
        })
        setSelectedShift({
            shift_id: 0,
            shift_name: "",
            hours_shift_name: ""
        })
    }

    const handleClickBranch = async (date: string, employeeId: number) => {
        handleShowSelectBranch()
        setScheduleData({
            employee_id: employeeId,
            date: date
        })
        setSelectedBranch({
            branch_id: 0,
            branch_name: ""
        })

    }

    const handleClickNotes = (date: string, employeeId: number, shiftId: number, branchId: number) => {
        if(shiftId != 0 || branchId != 0){
            handleShowAddNote()
            setScheduleData({
                employee_id: employeeId,
                date: date
            })
            setNotes("")
        }else{
            setShowError(true)
            setError("Select Branch first before adding note.")

            setTimeout(() => {
                setShowSuccess(false)
                setShowError(false)
            }, 3000);
        }

    }

    const handleChangeNotes = (e: any) => {
        setNotes(e.target.value)
    }

    const copyData = (shift_id: number, shift_name: string, branch_id: number, branch_name: string, notes: string) => {
        setShowCopy(true)

        setCopiedData({
            shift_id: shift_id,
            shift_name: shift_name,
            branch_id: branch_id,
            branch_name: branch_name,
            copy: !copiedData,
            notes: notes
        })

        setTimeout(() => {
            setShowCopy(false)
        }, 3000);
    }

    const pasteData = (employeeId: number, date: string) => {

        if(copiedData.branch_id != 0 || copiedData.shift_id != 0){
            const employeeData = employees;
            employeeData.map(employee => {
                if(employee.employee_id == employeeId){
                    employee.schedule.map(sched => {
                        if(sched.schedule_date == date){
                            sched.shift_id = copiedData.shift_id
                            sched.shift_name = copiedData.shift_name
                            sched.branch_id = copiedData.branch_id
                            sched.branch_name = copiedData.branch_name
                            sched.notes = copiedData.notes

                            autoSave(employeeId, date, copiedData.branch_id, copiedData.shift_id, copiedData.notes)
                        }
                    })
                }
            })

            setEmployees(employeeData)
            setCopiedData({...copiedData, copy: !copiedData.copy})
        }

    }

    const resetData = async (schedule_id: number, employee_id: number) => {

        if(schedule_id != 0){
            await axios.post(API_ENDPOINT.RESET_SCHEDULE+"/"+schedule_id).then(response => {
                if (response?.data?.status * 1 === 1) {
                    setShowSuccess(true)
                    setSuccess(SUCCESS_MESSAGE.SCHEDULE.UPDATED)

                    const employeeData = employees;

                    employeeData.map(employee => {
                        if(employee.employee_id == employee_id){
                            employee.schedule.map(sched => {
                                if(sched.id == schedule_id){
                                    sched.id = 0
                                    sched.shift_id = 0
                                    sched.shift_name = ""
                                    sched.branch_id = 0
                                    sched.branch_name = ""
                                    sched.notes = ""
                                    sched.is_rest_day = 0
                                    sched.hours_shift_name = ""
                                }
                            })
                        }
                    })

                    setEmployees(employeeData)

                }
            }).catch(error => {
                setShowError(true)
                setError(ERROR_MESSAGE.SYSTEM_ERROR)
            })

            setTimeout(() => {
                setShowSuccess(false)
                setShowError(false)
            }, 3000);
        }

    }

    const handleChangeDropdown = (selectedOption: any) => {
        setSelectedEmployeeOption(selectedOption)
    }

    const handleChangeDropdownAssignedEmployee = (selectedOption: any) => {
        setNewScheduleData({...newScheduleData, employee_id: selectedOption.value})
        setSelectedEmployeeOptionAssignSchedule(selectedOption)
    }

    const handleChangeBranch = (e: any) => {
        setNewScheduleData({...newScheduleData, branch_id: e.target.value})
        getAllShifts(e.target.value)
    }

    const handleChangeShift = (e: any) => {
        setNewScheduleData({...newScheduleData, shift_id: e.target.value})
    }

    const handleSaveNewSchedule = async() => {
        if(newScheduleData.employee_id == 0){
            setShowError(true)
            setError("Please select employee.")
        }else if(newScheduleData.branch_id == 0){
            setShowError(true)
            setError("Please select branch.")
        }else if(newScheduleData.shift_id == 0){
            setShowError(true)
            setError("Please select shift.")
        }else{
            await axios.post(API_ENDPOINT.SET_SCHEDULE, {
                employee_id: newScheduleData.employee_id,
                date: newScheduleData.actual_date,
                branch_id: Number(newScheduleData.branch_id),
                shift_id: Number(newScheduleData.shift_id),
                notes: newScheduleData.notes
            }).then(response => {
                if (response?.data?.status * 1 === 1) {
                    setShowSuccess(true)
                    setSuccess(SUCCESS_MESSAGE.SCHEDULE.UPDATED)

                    getEmployeeCalendarView(status, branchId, calendarType, currentMonthYear)
                    handleCloseAssignedEmployeeForm()
                }
            }).catch(error => {
                setShowError(true)
                setError(ERROR_MESSAGE.SYSTEM_ERROR)
            })
        }



        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const filterScheduling = (value: any) => {
        getAllEmployees(null ,value.branch_id, value.date_from, value.date_to, sortColumn, sort, value.status, entries)
        getEmployeeCalendarView(value.status, value.branch_id, calendarType, currentMonthYear)
        setBranchId(value.branch_id)
        setStatus(value.status)
    }

    const autoSave = async(employee_id: number, date: string, branch_id:  number, shift_id: number, notes: string) => {

        await axios.post(API_ENDPOINT.SET_SCHEDULE, {
            employee_id: employee_id,
            date: date,
            branch_id: branch_id,
            shift_id: shift_id,
            notes: notes
        }).then(response => {
            if (response?.data?.status * 1 === 1) {
                setShowSuccess(true)
                setSuccess(SUCCESS_MESSAGE.SCHEDULE.UPDATED)

                const employeeData = employees;

                employeeData.map(employee => {
                    if(employee.employee_id == employee_id){
                        employee.schedule.map(sched => {
                            if(sched.schedule_date == date){
                                sched.id = response?.data?.results?.id
                            }
                        })
                    }
                })

                setEmployees(employeeData)

            }
        }).catch(error => {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        })

        setTimeout(() => {
            setShowSuccess(false)
            setShowError(false)
        }, 3000);
    }

    const getAllShifts = async(branchId: number) => {
        try {
            if(branchId != 0){
                const res = await axios.get<IAPI_Response<IHoursShift[]>>(API_ENDPOINT.GET_HOURS_SHIFT_BY_BRANCH+"/"+branchId)
                const result = await res
                if (result?.data?.results?.length) {
                    setHoursShift(result?.data?.results)
                }else{
                    setHoursShift([])
                }
            }else{
                setHoursShift([])
            }

        } catch {
            console.log('There is something wrong')
        }
    }

    const getAllEmployee = async () => {
        try {
            const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
            const result = await res
            if (result?.data?.results?.length) {
                const employees = result?.data?.results;
                setEmployeeOptions([])
                setEmployeeOptions(current => [...current, {value: 0, label: "All Employees"}]);
                employees.map(employee => {
                    setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
                })
            }
        } catch {
            console.log('There is something wrong')
        }
    }

    const getAllEmployees = async (page_number: number | null, branch: number, date_from: string, date_to: string, column: string, sortType: string, status: number, entriesNumber: number) => {
        try {
            const res = await axios.get<IAPI_Response<IEmployeeList>>(API_ENDPOINT.EMPLOYEE_BASED_ON_BRANCH + "/" + branch, {
                params: {
                    page: page_number,
                    employee_id: selectedEmployeeOption.value,
                    date_from: date_from,
                    date_to: date_to,
                    sort: sortType,
                    sort_column: column,
                    status: status,
                    entries: entriesNumber
                },
            })
            const result = await res
            if (result?.data?.results?.data?.length) {
                setEmployees(result?.data?.results?.data)
                setCurrentPage(result?.data?.results?.current_page)
                setLastPage(result?.data?.results?.last_page)
                setPerPage(result?.data?.results?.per_page)
                setItemCount(result?.data?.results?.total)
                setFrom(result?.data?.results?.from)

                const employeesOnBranch = result?.data?.results?.data;

                const from = moment(date_from);
                const to = moment(date_to);

                // FOR TABLE HEADER
                // @ts-ignore
                const daysToPush :moment[] = [];

                daysToPush.push(from.clone())
                while(from.add(1, 'days').diff(to) <= 0) {
                    daysToPush.push(from.clone());
                }

                setDays(daysToPush)

            }else{
                setEmployees([])
                setCurrentPage(0)
                setLastPage(0)
                setPerPage(10)
                setItemCount(0)
                setFrom(0)
            }

        } catch {
            console.log('There is something wrong')
        }
    }

    const getAllBranch = async () => {
        try {
            const res = await axios.get<IAPI_Response<[]>>(API_ENDPOINT.ALL_BRANCH)
            const result = await res
            if (result?.data?.results?.length) {
                setBranch(result?.data?.results)
            }
        } catch {
            console.log('There is something wrong')
        }
    }


    const getDateFormat = async () => {
        try {
            const res = await axios.get<IAPI_Response<{dateFormat:string}>>(API_ENDPOINT.GET_DATE_FORMAT)
            const result = await res
            if (result?.data?.results) {
                setDateFormat(result?.data?.results?.dateFormat)

            }
            setShowLoading(false)
        } catch {
            setShowError(true)
            setError(ERROR_MESSAGE.SYSTEM_ERROR)
        }

        setTimeout(() => {
            setShowError(false)
        }, 3000);
    }

    const getEmployeeCalendarView = async (status_id: number, branch_id: number, calendar_Type: string, currentMonthAndYear: {month: string, year: string, start_week: string, end_week: string, current_date: string}) => {

        try {
            const res = await axios.get<IAPI_Response<any>>(API_ENDPOINT.EMPLOYEE_SCHEDULE_CALENDAR_TYPE, {
                params: {
                    employee_id: selectedEmployeeOption.value,
                    status: status_id,
                    branch: branch_id,
                    calendar_type: calendar_Type,
                    month: currentMonthAndYear.month,
                    year: currentMonthAndYear.year,
                    start_week: currentMonthAndYear.start_week,
                    end_week: currentMonthAndYear.end_week,
                    current_date: currentMonthAndYear.current_date
                },
            })
            const result = await res

            if(calendar_Type != "day"){
                setCalendarData(result.data.results)
            }else{
                setCalendarDataForDay(result.data.results)
            }


        } catch {
            console.log('There is something wrong')
        }
    }

    const handleShowEmployeesAssigned = async (page_number: number | null, actual_date: string, entries_number: number) => {
        try {
            const res = await axios.get<IAPI_Response<IEmployeeScheduleList>>(API_ENDPOINT.GET_EMPLOYEES_SCHEDULE_ASSIGNED, {
                params: {
                    page: page_number,
                    employee_id: selectedEmployeeOption.value,
                    status: status,
                    branch: branchId,
                    actual_date: actual_date,
                    entries: entries_number
                },
            })
            const result = await res
            if (result?.data?.results?.data?.length) {
                setEmployeesAssigned(result?.data?.results?.data)
                setCurrentPage1(result?.data?.results?.current_page)
                setLastPage1(result?.data?.results?.last_page)
                setPerPage1(result?.data?.results?.per_page)
                setItemCount1(result?.data?.results?.total)
                setFrom1(result?.data?.results?.from)
            }else{
                setEmployeesAssigned([])
            }
            handleShowEmployeeAssignedList()
            // setCalendarData(result.data.results)

        } catch {
            console.log('There is something wrong')
        }
    }

    const handleShowEmployeeLeave = async(page_number: null | number, actual_date: string, entries_number: number) => {
        try {
            const res = await axios.get<IAPI_Response<IEmployeeLeaveList>>(API_ENDPOINT.GET_EMPLOYEES_LEAVE_ON_DATE, {
                params: {
                    page: page_number,
                    employee_id: selectedEmployeeOption.value,
                    status: status,
                    branch: branchId,
                    actual_date: actual_date,
                    entries: entries_number
                },
            })
            const result = await res
            if (result?.data?.results?.data?.length) {
                setEmployeesLeave(result?.data?.results?.data)
                setCurrentPage2(result?.data?.results?.current_page)
                setLastPage2(result?.data?.results?.last_page)
                setPerPage2(result?.data?.results?.per_page)
                setItemCount2(result?.data?.results?.total)
                setFrom2(result?.data?.results?.from)
            }else{
                setEmployeesLeave([])
            }
            handleShowEmployeeLeaveList()
            // setCalendarData(result.data.results)

        } catch {
            console.log('There is something wrong')
        }
    }

    useEffect(() => {
        checkScrollPosition();
        const handleScroll = () => checkScrollPosition();
        if(tableContainerRef.current){
            tableContainerRef.current.addEventListener('scroll', handleScroll);
            return () => tableContainerRef?.current?.removeEventListener('scroll', handleScroll);
        }

    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (tableContainerRef.current) {
                const containerRect = tableContainerRef.current.getBoundingClientRect();
                const containerTop = containerRect.top;
                const containerHeight = containerRect.height;
                const containerBottom = containerRect.bottom;
                const viewportHeight = window.innerHeight;
                const centerY = containerTop + containerHeight / 2;
                const offsetTop = Math.max(containerTop, 0);
                const offsetBottom = Math.min(containerBottom, viewportHeight);
                const visibleHeight = offsetBottom - offsetTop;
                const top = offsetTop + visibleHeight / 2 - containerTop;

                // console.log("top ", top)
                // console.log("container height ", containerHeight)
                // console.log("position top ", Math.min(Math.max(0, top), containerHeight))
                const sample = containerHeight - top
                setButtonPosition({
                    top: `${Math.min(Math.max(0, top + 120), containerHeight)}px`
                });
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    useEffect(() => {
        ;(async () => {
            getDateFormat()
            getAllBranch()
            getAllEmployees(null,0, dateRange.date_from, dateRange.date_to, sortColumn, sort, status, entries)
            getAllEmployee()
            getEmployeeCalendarView(status, branchId, calendarType, currentMonthYear)
        })()
    }, [])
    return (
        <>
            <Container fluid>
                <h5 className='fw-bold'>Scheduling</h5>
                <Breadcrumb className='breadcrumbs'>
                    <Breadcrumb.Item className=' text-decoration-none' href='/home'>
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className=' text-decoration-none' href='#'>
                        Employees
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Scheduling</Breadcrumb.Item>
                </Breadcrumb>

                <Alert
                    type="copy"
                    show={showCopy}
                    message={"Copied Data"}
                    setShow={() => {
                        setShowCopy(false);
                    }
                    }
                />

                <Alert
                    type="success"
                    show={showSuccess}
                    message={success}
                    setShow={() => {
                        setShowSuccess(false);
                    }
                    }
                />

                <Alert
                    type="error"
                    show={showError}
                    message={error}
                    setShow={() => {
                        setShowError(false);
                    }
                    }
                />

                <Card className='border-0  p-0 bordered-card'>
                    <Card.Body>
                        <Col sm={12}>
                            <Formik
                                initialValues={
                                    {
                                        branch_id: '0',
                                        date_to: moment().add(6, 'days').format("YYYY-MM-DD"),
                                        date_from: moment().format("YYYY-MM-DD"),
                                        employee_id: 0,
                                        status: 0
                                    }
                                }
                                validationSchema={validationSchema2}
                                onSubmit={filterScheduling}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting
                                  }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row>
                                            <Col sm={2} className="employee-dropdown">
                                                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                    <Form.Label className='fw-bold form-labels'>Employee</Form.Label>

                                                    <Select
                                                        name="employee_id"
                                                        value={selectedEmployeeOption}
                                                        options={employeeOptions}
                                                        onChange={(e: any) => {
                                                            handleChangeDropdown(e);
                                                            handleSubmit();
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.employee_id}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={2}>
                                                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                    <Form.Label className='fw-bold form-labels'>Status</Form.Label>
                                                    <Form.Select
                                                        size={'sm'}
                                                        name="status"
                                                        value={values.status}
                                                        onChange={(e: any) => {
                                                            handleChange(e)
                                                            handleSubmit()
                                                        }}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.status && !!errors.status}
                                                    >
                                                        <option value="0">All Status</option>
                                                        <option value="1">Active</option>
                                                        <option value="2">Inactive</option>
                                                        <option value="3">Terminated</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.status}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col sm={2}>
                                                <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                    <Form.Label className='fw-bold form-labels'>Branch</Form.Label>
                                                    <Form.Select
                                                        size='sm'
                                                        name="branch_id"
                                                        value={values.branch_id}
                                                        onChange={(e: any) => {
                                                            handleChange(e)
                                                            handleSubmit()
                                                        }}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.branch_id && !!errors.branch_id}

                                                    >
                                                      <option value="0">All Branches</option>
                                                      {branches.map((branch, index) => (
                                                          <option value={branch.branch_id} key={index}>{branch.branch_name}</option>
                                                      ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.branch_id}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            {
                                                view == "default" ?
                                                    <>
                                                        <Col sm={2}>
                                                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                                <Form.Label className='fw-bold form-labels'>Date From</Form.Label>
                                                                <InputGroup className='mb-2 ' size={'sm'}>
                                                                    <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                                                        <Calendar/>
                                                                    </InputGroup.Text>
                                                                    <Form.Control
                                                                        size={'sm'}
                                                                        type='date'
                                                                        placeholder='Friday, June 03, 2022'
                                                                        name='date_from'
                                                                        value={values.date_from}
                                                                        onChange={(e: any) => {
                                                                            handleChange(e);
                                                                            handleSubmit();
                                                                            setDateRange({...dateRange, date_from: e.target.value})
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        isInvalid={touched.date_from && !!errors.date_from}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.date_from}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                                                                <Form.Label className='fw-bold form-labels'>Date To</Form.Label>
                                                                <InputGroup className='mb-2 ' size={'sm'}>
                                                                    <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                                                        <Calendar/>
                                                                    </InputGroup.Text>
                                                                    <Form.Control
                                                                        size={'sm'}
                                                                        type='date'
                                                                        placeholder='Friday, June 03, 2022'
                                                                        name='date_to'
                                                                        value={values.date_to}
                                                                        onChange={(e: any) => {
                                                                            handleChange(e);
                                                                            handleSubmit();
                                                                            setDateRange({...dateRange, date_to: e.target.value})
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        isInvalid={touched.date_to && !!errors.date_to}
                                                                        min={moment(values.date_from).format("YYYY-MM-DD")}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors.date_to}
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </Col>
                                                    </> :
                                                    <>
                                                        <Col sm={2}>
                                                            {
                                                                view == "calendar" ?
                                                                    <Form.Group controlId='formHorizontalEmail'>
                                                                        <Form.Label className='fw-bold form-labels'>Calendar Type</Form.Label>
                                                                        <Form.Select
                                                                            size='sm'
                                                                            name="table_type"
                                                                            value={calendarType}
                                                                            onChange={handleChangeCalendarType}

                                                                        >
                                                                            <option value="day">Day</option>
                                                                            <option value="week">Week</option>
                                                                            <option value="month">Month</option>

                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                    :""
                                                            }

                                                        </Col>
                                                        <Col sm={2}>
                                                            {
                                                                view == "calendar" ?
                                                                    <div className="custom-navigation">
                                                                        <Button onClick={handlePreviousMonth} variant="transparent" size={"sm"} className="text-primary navigation-button">
                                                                            {"<"}
                                                                        </Button>

                                                                            {(() => {
                                                                                if(calendarType == "month") {   // if calendar type is month
                                                                                    return (
                                                                                        <span className="current-item">{moment(currentMonthYear.month, 'M').format('MMM')}. {moment(currentMonthYear.year, 'Y').format('YYYY')}</span>
                                                                                    )
                                                                                }else if(calendarType == "week"){
                                                                                    return (
                                                                                        <span className="current-item">{moment(currentMonthYear.start_week).format('MMM')}. {moment(currentMonthYear.start_week).format('YYYY')}</span>
                                                                                    )
                                                                                }else{
                                                                                    return (
                                                                                        <span className="current-item">{moment(currentMonthYear.current_date).format('MMM')}. {moment(currentMonthYear.current_date).format('YYYY')}</span>
                                                                                    )
                                                                                }

                                                                            })()}


                                                                        <Button onClick={handleNextMonth} variant="transparent" size={"sm"} className="text-primary navigation-button">
                                                                            {">"}
                                                                        </Button>
                                                                    </div>

                                                                    :""
                                                            }

                                                        </Col>
                                                    </>
                                            }



                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                        <Row>

                            <Col sm={1}>
                                <Form.Group >
                                    <Button
                                        className=' add-new-btn rounded text-secondary p-0'
                                        variant='transparent'
                                    >
                                        <span style={{backgroundColor: '#82C341', borderRadius: '10px'}}>&nbsp; &nbsp; &nbsp;</span>
                                        &nbsp; On Duty
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                                <Form.Group >
                                    <Button
                                        className=' add-new-btn rounded text-secondary p-0'
                                        variant='transparent'
                                    >
                                        <span style={{backgroundColor: '#27699C', borderRadius: '10px'}}>&nbsp; &nbsp; &nbsp;</span>
                                        &nbsp; On Leave
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col sm={1}>
                                <Form.Group >
                                    <Button
                                        className=' add-new-btn rounded text-secondary p-0'
                                        variant='transparent'
                                    >
                                        <span style={{backgroundColor: '#A7A9AC', borderRadius: '10px'}}>&nbsp; &nbsp; &nbsp;</span>
                                        &nbsp; Rest Day
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col sm={5}></Col>
                            <Col sm={4} className='d-flex justify-content-end hidden'>
                                <Form.Group controlId='formHorizontalEmail'>
                                    <ButtonToolbar>

                                        <ToggleButtonGroup
                                            className="switch-view"
                                            type="radio"
                                            name="option-views"
                                            value={view}
                                            // onChange={(selected: any) => setView(selected)}
                                        >
                                            <ToggleButton onClick={() => setView("default")} style={{fontSize: '85%', color: view == "default" ? "#f26930" : "#a7a9ac"}} className={view == "default" ? "switch-button-active" : "switch-view-button"} value={"default"}>{view == "default" ? <HomeIcon/> : <HomeIconWhite/>} Default View</ToggleButton>
                                            <ToggleButton onClick={() => setView("calendar")} style={{fontSize: '85%', color: view == "calendar" ? "#f26930" : "#a7a9ac"}} className={view == "calendar" ? "switch-button-active" : "switch-view-button"} value={"calendar"}>{view == "calendar" ? <Calendar/> : <CalendarWhite/>} Calendar View</ToggleButton>
                                        </ToggleButtonGroup>
                                    </ButtonToolbar>
                                </Form.Group>

                            </Col>
                        </Row>
                        {
                            view == "default" ?
                                <>
                                    <div className='table-responsive table-scheduling' ref={tableContainerRef}>
                                        <Table className='mt-3' ref={tableRef}>
                                            <thead>
                                            <tr>
                                                <th className="sticky-col first-col text-center table-header" style={{minWidth: '110px' }} onClick={() => handleSortingChange("branch")}>Branch {sortColumn == "branch" ? (sort == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                                <th className="sticky-col second-col text-center table-border-right table-header" style={{minWidth: '125px' }} onClick={() => handleSortingChange("employee_name")}>Employee {sortColumn == "employee_name" ? (sort == "asc" ? <ArrowUp/> : <ArrowDown/> ) : ""}</th>
                                                {
                                                    days.map((day, index) => (
                                                        <th key={index} style={{ minWidth: '190px' }} className={moment(day).day() == 6 ? "text-center table-border-right" : "text-center"}>
                                                            {moment(day).format("MMM D")} <br/> {moment(day).format("dddd")}
                                                        </th>
                                                    ))
                                                }

                                            </tr>
                                            </thead>
                                            <tbody>

                                            {
                                                employees.length > 0 ?
                                                    employees.map((employee, index) => (
                                                        <tr key={index}>
                                                            <td className="sticky-col first-col center-align">{employee.branch.branch_name}</td>
                                                            <td className="sticky-col second-col center-align table-border-right">
                                                                <Stack direction='row' spacing={1}>
                                                                    <div>
                                                                        {
                                                                            employee.photo != "" ?
                                                                                <Avatar
                                                                                    sx={{ width: 20, height: 20 }}
                                                                                    src={process.env.REACT_APP_IMAGE_URL+ employee.photo}
                                                                                    className={"profile-avatar"}
                                                                                ></Avatar> :
                                                                                <Avatar
                                                                                    sx={{ width: 20, height: 20}}
                                                                                    src={""}
                                                                                    className={"profile-avatar"}
                                                                                ></Avatar>
                                                                        }
                                                                    </div>
                                                                    <div>{employee.last_name}, {employee.first_name}</div>
                                                                </Stack>
                                                            </td>
                                                            {
                                                                employee.schedule.map((schedule, index) => (
                                                                    <td key={index} className={moment(schedule.schedule_date).day() == 6 ? "text-center table-border-right" : "text-center table-no-border"}>
                                                                        <Card className='p-0 bordered-card scheduling-container'
                                                                              draggable
                                                                              onDragStart={(e) => handleDragStart(e, schedule.shift_id, schedule.shift_name, schedule.branch_id, schedule.branch_name, schedule.notes)}
                                                                              onDragOver={(e) => handleDragOver(e)}
                                                                              onDrop={(e) => handleDrop(e, schedule.employee_id, schedule.schedule_date)}
                                                                        >
                                                                            <Card.Body style={{padding: '10px'}}>
                                                                                <Row style={{padding: '3px'}}>
                                                                                    <Col sm={12} className="d-flex justify-content-end hidden">
                                                                                        <Button
                                                                                            className=' add-new-btn rounded text-white copy-paste-button'
                                                                                            variant='primary'
                                                                                            title={"Reset"}
                                                                                            style={{backgroundColor: 'white', padding: '2px', borderColor: 'transparent', marginTop: '-11px', height:'0px'}}
                                                                                            onClick={() => resetData(schedule.id, schedule.employee_id)}
                                                                                        >
                                                                                            <ResetIcon/>
                                                                                        </Button>
                                                                                        &nbsp;
                                                                                        <Button
                                                                                            className=' add-new-btn rounded text-white copy-paste-button'
                                                                                            variant='primary'
                                                                                            title={"Copy"}
                                                                                            style={{backgroundColor: 'white', padding: '2px', borderColor: 'transparent', marginTop: '-11px', height:'0px'}}
                                                                                            onClick={() => copyData(schedule.shift_id, schedule.shift_name, schedule.branch_id, schedule.branch_name, schedule.notes)}
                                                                                        >
                                                                                            <CopyIcon/>
                                                                                        </Button>
                                                                                        &nbsp;
                                                                                        <Button
                                                                                            className=' add-new-btn rounded text-white copy-paste-button'
                                                                                            variant='primary'
                                                                                            title={"Paste"}
                                                                                            style={{backgroundColor: 'white', padding: '2px', borderColor: 'transparent', marginTop: '-11px', height:'0px'}}
                                                                                            onClick={() => pasteData(employee.employee_id, schedule.schedule_date)}
                                                                                            disabled={copiedData.shift_id == 0 && copiedData.branch_id == 0 ? true: false}
                                                                                        >
                                                                                            <PasteIcon/>
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col sm={2}>
                                                                                        <Button
                                                                                            className=' add-new-btn rounded text-white copy-paste-button'
                                                                                            variant='primary'
                                                                                            title={"Branch"}
                                                                                            style={{backgroundColor: 'white', padding: '0px', borderColor: 'transparent'}}
                                                                                            onClick={() => {
                                                                                                handleClickBranch(schedule.schedule_date, employee.employee_id)
                                                                                                setSelectedBranch({
                                                                                                    branch_id: schedule.branch_id,
                                                                                                    branch_name: schedule.branch_name
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <BlackLocationIcon/>
                                                                                        </Button>
                                                                                    </Col>
                                                                                    <Col sm={9} style={{lineHeight: '1rem'}}>
                                                                        <span
                                                                            onClick={() => {
                                                                                handleClickBranch(schedule.schedule_date, employee.employee_id)
                                                                                setSelectedBranch({
                                                                                    branch_id: schedule.branch_id,
                                                                                    branch_name: schedule.branch_name
                                                                                })
                                                                            }}
                                                                            className={schedule.branch_name == "" ? "branch-name" : "text-success branch-name"}
                                                                            style={{fontSize: '11px'}}
                                                                        >
                                                                            {handleDisplay(schedule.branch_name, 'branch', schedule.shift_id, schedule.leave_details)}
                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col sm={2}>
                                                                                        <Button
                                                                                            className=' add-new-btn rounded text-white copy-paste-button'
                                                                                            variant='primary'
                                                                                            title={"Shift"}
                                                                                            style={{backgroundColor: 'white', padding: '0px', borderColor: 'transparent'}}
                                                                                            onClick={() => {
                                                                                                handleClickShift(schedule.schedule_date, employee.employee_id, schedule.branch_id)
                                                                                                setSelectedShift({
                                                                                                    shift_id: schedule.shift_id,
                                                                                                    shift_name: schedule.shift_name,
                                                                                                    hours_shift_name: ""
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <ClockBlackIcon/>
                                                                                        </Button>
                                                                                    </Col>
                                                                                    <Col sm={9} style={{lineHeight: '1rem'}}>
                                                                        <span
                                                                            onClick={() => {
                                                                                handleClickShift(schedule.schedule_date, employee.employee_id, schedule.branch_id)
                                                                                setSelectedShift({
                                                                                    shift_id: schedule.shift_id,
                                                                                    shift_name: schedule.shift_name,
                                                                                    hours_shift_name: ""
                                                                                })
                                                                            }}
                                                                            className={schedule.shift_name == "" ? "branch-name" : "text-success branch-name"}
                                                                            style={{fontSize: '11px'}}
                                                                        >
                                                                            {handleDisplay(schedule.shift_name, 'shift', schedule.shift_id, schedule.leave_details)}
                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col sm={2}>
                                                                                        {/*<Button*/}
                                                                                        {/*    className=' add-new-btn rounded text-white copy-paste-button'*/}
                                                                                        {/*    variant='primary'*/}
                                                                                        {/*    title={"Shift"}*/}
                                                                                        {/*    style={{backgroundColor: 'white', padding: '0px', borderColor: 'transparent'}}*/}
                                                                                        {/*    onClick={() => {*/}
                                                                                        {/*        handleClickShift(schedule.schedule_date, employee.employee_id, schedule.branch_id)*/}
                                                                                        {/*        setSelectedShift({*/}
                                                                                        {/*            shift_id: schedule.shift_id,*/}
                                                                                        {/*            shift_name: schedule.shift_name*/}
                                                                                        {/*        })*/}
                                                                                        {/*    }}*/}
                                                                                        {/*>*/}
                                                                                        {/*    <ClockBlackIcon/>*/}
                                                                                        {/*</Button>*/}
                                                                                    </Col>
                                                                                    <Col sm={9} style={{lineHeight: '1rem'}}>
                                                                        <span
                                                                            // onClick={() => {
                                                                            //     handleClickShift(schedule.schedule_date, employee.employee_id, schedule.branch_id)
                                                                            //     setSelectedShift({
                                                                            //         shift_id: schedule.shift_id,
                                                                            //         shift_name: schedule.shift_name
                                                                            //     })
                                                                            // }}
                                                                            className={schedule.hours_shift_name == "" ? "branch-name" : "text-success branch-name"}
                                                                            style={{fontSize: '11px'}}
                                                                        >
                                                                            {handleDisplay(schedule.hours_shift_name, 'hours_shift_name', 0, schedule.leave_details)}
                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col sm={2}>
                                                                                        <Button
                                                                                            className=' add-new-btn rounded text-white copy-paste-button'
                                                                                            variant='primary'
                                                                                            title={"Notes"}
                                                                                            style={{backgroundColor: 'white', padding: '0px', borderColor: 'transparent'}}
                                                                                            onClick={() => {
                                                                                                handleClickNotes(schedule.schedule_date, employee.employee_id, schedule.shift_id, schedule.branch_id)
                                                                                                setNotes(schedule.notes)
                                                                                            }}
                                                                                        >
                                                                                            <WriteBlackIcon/>
                                                                                        </Button>
                                                                                    </Col>
                                                                                    <Col sm={9} style={{lineHeight: '1rem'}}>
                                                                        <span
                                                                            onClick={() => {
                                                                                handleClickNotes(schedule.schedule_date, employee.employee_id, schedule.shift_id, schedule.branch_id)
                                                                                setNotes(schedule.notes)
                                                                            }}
                                                                            className={schedule.notes == "" ? "branch-name" : "text-success branch-name display-notes"}
                                                                            style={{fontSize: '11px'}}
                                                                        >
                                                                            {handleDisplayNotes(schedule.notes)}
                                                                        </span>
                                                                                    </Col>
                                                                                </Row>
                                                                                <br/>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </td>
                                                                ))
                                                            }


                                                        </tr>
                                                    )) :
                                                    <tr>
                                                        <td colSpan={9} className="text-center">
                                                            No Data Available.
                                                        </td>
                                                    </tr>

                                            }

                                            </tbody>
                                        </Table>
                                        {showRightButton && <button className="floating-button-right" onClick={handleScrollRight} style={{top: buttonPosition.top }}>›</button>}
                                        {showLeftButton && <button className="floating-button-left" onClick={handleScrollLeft} style={{top: buttonPosition.top }}>‹</button>}
                                    </div>


                                    <Row className="mt-2">
                                        <Col sm={6}>
                                            <Pagination
                                                itemsCount={itemsCount}
                                                itemsPerPage={itemsPerPage}
                                                currentPage={currentPage}
                                                lastPage={lastPage}
                                                setCurrentPage={setCurrentPage}
                                                alwaysShown={false}
                                                pageClicked={(page: number) => {
                                                    getAllEmployees(page, branchId, dateRange.date_from, dateRange.date_to, sortColumn, sort, status, entries);
                                                }
                                                }
                                            />
                                        </Col>
                                        <Col sm={6} className='right-align d-flex justify-content-end hidden'>
                                            <Form.Group >
                                                <Form.Select
                                                    size={'sm'}
                                                    name="entries"
                                                    value={entries}
                                                    onChange={handleChangeEntries}
                                                    style={{width: '100%'}}
                                                >
                                                    <option value="10" selected>10</option>
                                                    <option value="25" >25</option>
                                                    <option value="50" >50</option>
                                                    <option value="100" >100</option>

                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                <div className='table-responsive table-calendar'>
                                    <Table className='mt-3'>
                                        <thead>
                                            {
                                                calendarType != "day" ?
                                                    <tr>
                                                        <th className="text-center table-header days-of-the-week weekend">SUN</th>
                                                        <th className="text-center table-header days-of-the-week">MON</th>
                                                        <th className="text-center table-header days-of-the-week">TUE</th>
                                                        <th className="text-center table-header days-of-the-week">WED</th>
                                                        <th className="text-center table-header days-of-the-week">THU</th>
                                                        <th className="text-center table-header days-of-the-week">FRI</th>
                                                        <th className="text-center table-header days-of-the-week weekend">SAT</th>
                                                    </tr>
                                                    :
                                                    <tr>
                                                        <th className="text-center table-header days-of-the-week">
                                                                <b>{moment(currentMonthYear.current_date).format('dddd').substring(0,3).toUpperCase()}</b>
                                                            <br/>
                                                            <span>{moment(currentMonthYear.current_date).format(dateFormat)}</span>
                                                        </th>
                                                    </tr>
                                            }

                                        </thead>
                                        <tbody>
                                        {
                                            calendarType != "day" ?
                                                calendarData.map((calendar: any, index: number) => (
                                                    <tr key={index}>
                                                        {
                                                            calendar.map((subCalendar: any, index2: number) => (
                                                                <td key={index2} className={moment().format('YYYY-MM-DD') == subCalendar.actual_date ? "current-day": ""}>
                                                                    <Row style={{padding: '3px'}}>
                                                                        <Col sm={6}>
                                                                            {
                                                                                subCalendar.includedOnTheMonth &&
                                                                                <Button className={"rounded"} variant="transparent" size={"sm"} onClick={() => handleAssignedEmployee(subCalendar.actual_date)}>
                                                                                    <CircleCreate/>
                                                                                </Button>
                                                                            }

                                                                        </Col>
                                                                        <Col sm={6} className="d-flex justify-content-end hidden">
                                                                            {subCalendar.includedOnTheMonth ? <b className={moment().format('YYYY-MM-DD') == subCalendar.actual_date ? "circle": ""}>{subCalendar.dayNumber}</b>: <span className="not-included-month">{subCalendar.dayNumber}</span>}
                                                                        </Col>
                                                                    </Row>
                                                                    {
                                                                        subCalendar.includedOnTheMonth &&
                                                                            <>
                                                                                <Row>
                                                                                    <Col sm={12} className="d-flex justify-content-center">
                                                                                        {(() => {
                                                                                            if(selectedEmployeeOption.value == 0){   // if all employees is selected
                                                                                                if(subCalendar.employees_assigned.length == 1){
                                                                                                    return (<Button
                                                                                                        className=" p-1 pt-0 pb-0"
                                                                                                        style={{fontSize: '12px', color: 'white'}}
                                                                                                        variant="success"
                                                                                                        size="sm"
                                                                                                        onClick={() => {
                                                                                                            setActualDate(subCalendar.actual_date)
                                                                                                            handleShowEmployeesAssigned(null, subCalendar.actual_date, entriesEmployeesAssigned)
                                                                                                        }}
                                                                                                    >
                                                                                                        {subCalendar.employees_assigned[0].first_name + " " + subCalendar.employees_assigned[0].last_name}
                                                                                                    </Button>)
                                                                                                }else if(subCalendar.employees_assigned.length == 0){
                                                                                                    return (<Button
                                                                                                        className=" p-1 pt-0 pb-0"
                                                                                                        style={{fontSize: '12px', color: 'white'}}
                                                                                                        variant="secondary"
                                                                                                        size="sm"
                                                                                                        onClick={() => {
                                                                                                            setActualDate(subCalendar.actual_date)
                                                                                                            handleShowEmployeesAssigned(null, subCalendar.actual_date, entriesEmployeesAssigned)
                                                                                                        }}
                                                                                                    >
                                                                                                        No Employees
                                                                                                    </Button>)
                                                                                                }else if(subCalendar.employees_assigned.length > 1){
                                                                                                    return (<Button
                                                                                                        className=" p-1 pt-0 pb-0"
                                                                                                        style={{fontSize: '12px', color: 'white'}}
                                                                                                        variant="success"
                                                                                                        size="sm"
                                                                                                        onClick={() => {
                                                                                                            setActualDate(subCalendar.actual_date)
                                                                                                            handleShowEmployeesAssigned(null, subCalendar.actual_date, entriesEmployeesAssigned)
                                                                                                        }}
                                                                                                    >
                                                                                                        {subCalendar.employees_assigned.length} Employees
                                                                                                    </Button>)
                                                                                                }
                                                                                            }else {   // if specific employee is selected
                                                                                                if(subCalendar.employees_assigned.length >= 1){
                                                                                                    return (<Button
                                                                                                        className=" p-1 pt-0 pb-0"
                                                                                                        style={{fontSize: '12px', color: 'white'}}
                                                                                                        variant="success"
                                                                                                        size="sm"
                                                                                                        onClick={() => {
                                                                                                            setActualDate(subCalendar.actual_date)
                                                                                                            handleShowEmployeesAssigned(null, subCalendar.actual_date, entriesEmployeesAssigned)
                                                                                                        }}
                                                                                                    >
                                                                                                        {subCalendar.employees_assigned[0].first_name + " " + subCalendar.employees_assigned[0].last_name}
                                                                                                    </Button>)
                                                                                                }else if(subCalendar.employees_leave.length >= 1){
                                                                                                    return (<Button
                                                                                                        className=" p-1 pt-0 pb-0"
                                                                                                        style={{fontSize: '12px', color: 'white'}}
                                                                                                        variant="info"
                                                                                                        size="sm"
                                                                                                        onClick={() => {
                                                                                                            setActualDate(subCalendar.actual_date)
                                                                                                            handleShowEmployeeLeave(null, subCalendar.actual_date, entriesEmployeesLeave)
                                                                                                        }}
                                                                                                    >
                                                                                                        {subCalendar.employees_leave[0].first_name + " " + subCalendar.employees_leave[0].last_name}
                                                                                                    </Button>)
                                                                                                }else{
                                                                                                    return (<Button
                                                                                                        className=" p-1 pt-0 pb-0"
                                                                                                        style={{fontSize: '12px', color: 'white'}}
                                                                                                        variant="secondary"
                                                                                                        size="sm"
                                                                                                    >
                                                                                                        Rest Day
                                                                                                    </Button>)
                                                                                                }
                                                                                            }


                                                                                        })()}

                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mt-2">
                                                                                    <Col sm={12} className="d-flex justify-content-center">
                                                                                        {(() => {
                                                                                            if(selectedEmployeeOption.value == 0) {   // if all employees is selected
                                                                                                if(subCalendar.employees_leave.length == 1){
                                                                                                    return (<Button
                                                                                                        className=" p-1 pt-0 pb-0"
                                                                                                        style={{fontSize: '12px', color: 'white'}}
                                                                                                        variant="info"
                                                                                                        size="sm"
                                                                                                        onClick={() => {
                                                                                                            setActualDate(subCalendar.actual_date)
                                                                                                            handleShowEmployeeLeave(null, subCalendar.actual_date, entriesEmployeesLeave)
                                                                                                        }}
                                                                                                    >
                                                                                                        1 Leave
                                                                                                    </Button>)
                                                                                                }else if(subCalendar.employees_leave.length == 0){
                                                                                                    return ""
                                                                                                    // return (<Button
                                                                                                    //     className=" p-1 pt-0 pb-0"
                                                                                                    //     style={{fontSize: '12px', color: 'white'}}
                                                                                                    //     variant="info"
                                                                                                    //     size="sm"
                                                                                                    //     onClick={() => {
                                                                                                    //         setActualDate(subCalendar.actual_date)
                                                                                                    //         handleShowEmployeeLeave(null, subCalendar.actual_date, entriesEmployeesLeave)
                                                                                                    //     }}
                                                                                                    // >
                                                                                                    //     No Leave
                                                                                                    // </Button>)
                                                                                                }else if(subCalendar.employees_leave.length > 1){
                                                                                                    return (<Button
                                                                                                        className=" p-1 pt-0 pb-0"
                                                                                                        style={{fontSize: '12px', color: 'white'}}
                                                                                                        variant="info"
                                                                                                        size="sm"
                                                                                                        onClick={() => {
                                                                                                            setActualDate(subCalendar.actual_date)
                                                                                                            handleShowEmployeeLeave(null, subCalendar.actual_date, entriesEmployeesLeave)
                                                                                                        }}
                                                                                                    >
                                                                                                        {subCalendar.employees_leave.length} Leaves
                                                                                                    </Button>)
                                                                                                }
                                                                                            }

                                                                                        })()}

                                                                                    </Col>
                                                                                </Row>
                                                                                <br/>
                                                                            </>

                                                                    }

                                                                </td>
                                                            ))
                                                        }
                                                    </tr>

                                                ))
                                                :
                                                <tr>
                                                    <td>
                                                        <Row className='mb-3'>
                                                            {displayDayDataAssignedEmployees()}
                                                        </Row>
                                                        <Row>
                                                            <Col sm={5}></Col>
                                                            <Col sm={2}>
                                                            {
                                                                calendarDataForDay.employees_leave.length > 0 ?

                                                                    calendarDataForDay.employees_leave.map((employee, index) => (
                                                                      <>
                                                                          <Stack key={index} direction='row' style={{cursor: 'pointer'}}>
                                                                              <div>{index + 1}. &nbsp;</div>
                                                                              <div>
                                                                                  {
                                                                                      employee.photo != "" ?
                                                                                          <Avatar
                                                                                              sx={{width: 20, height: 20}}
                                                                                              src={process.env.REACT_APP_IMAGE_URL + employee.photo}
                                                                                              className={"profile-avatar"}
                                                                                          ></Avatar> :
                                                                                          <Avatar
                                                                                              sx={{width: 20, height: 20}}
                                                                                              src={""}
                                                                                              className={"profile-avatar"}
                                                                                          ></Avatar>
                                                                                  }
                                                                              </div>
                                                                              &nbsp;
                                                                              <Button
                                                                                  className="mb-1 p-1 pt-0 pb-0"
                                                                                  style={{fontSize: '12px', color: 'white'}}
                                                                                  variant="info"
                                                                                  size="sm"
                                                                              >
                                                                                  {employee.last_name}, {employee.first_name}
                                                                              </Button>
                                                                          </Stack>
                                                                      </>

                                                                    ))
                                                                    :
                                                                    <Button
                                                                        className="mb-1 p-1 pt-0 pb-0"
                                                                        style={{fontSize: '12px', color: 'white'}}
                                                                        variant="info"
                                                                        size="sm"
                                                                    >
                                                                        No Leave
                                                                    </Button>

                                                            }
                                                            </Col>
                                                        </Row>

                                                    </td>
                                                </tr>

                                        }
                                        </tbody>
                                    </Table>
                                </div>
                                </>

                        }



                        <Dialog
                            show={showConfirm}
                            setShow={handleCloseConfirm}
                            setEvent={() => {
                                // deleteNotice(item.notice_id)
                            }
                            }
                        />

                        <Loader show={showLoading}/>

                        {/* SELECT BRANCH MODAL*/}
                        <Modal className='modal' centered show={showSelectBranch} onHide={handleCloseSelectBranch}>
                            <Modal.Header closeButton>
                                <Modal.Title>Select Branch</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="mb-3">
                                    <Col sm={12}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                            <Form.Label className='text-muted'>Branch</Form.Label>
                                            <Form.Select
                                                size='sm'
                                                name="branch_id"
                                                onChange={(e: any) => handleSelectedBranch(e)}
                                                value={selectedBranch.branch_id}
                                                // onChange={(e: any) => {
                                                //     handleChange(e)
                                                //     handleSubmit()
                                                // }}
                                                // onBlur={handleBlur}
                                                // isInvalid={touched.date_range && !!errors.date_range}

                                            >
                                                <option selected disabled value="0">Select Branch</option>
                                                <option value="-1">Rest Day</option>
                                                {
                                                    branches.map((branch, index) => (
                                                        <option key={index} value={branch.branch_id}>
                                                            {branch.branch_name}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                {/*<Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseSelectBranch}>*/}
                                {/*    Cancel*/}
                                {/*</Button>*/}
                                {/*<Button*/}
                                {/*    type="button"*/}
                                {/*    form="departmentCreate"*/}
                                {/*    variant='primary text-white'*/}
                                {/*    size={'sm'}*/}
                                {/*    className='mx-2'*/}
                                {/*    onClick={handleSaveBranch}*/}
                                {/*>*/}
                                {/*    Save*/}
                                {/*</Button>*/}
                            </Modal.Footer>
                        </Modal>

                        {/* SELECT SHIFT MODAL*/}
                        <Modal className='modal' centered show={showSelectShift} onHide={handleCloseSelectShift}>
                            <Modal.Header closeButton>
                                <Modal.Title>Select Shift</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="mb-3">
                                    <Col sm={12}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                            <Form.Label className='text-muted'>Shift</Form.Label>
                                            <Form.Select
                                                size='sm'
                                                name="shift_id"
                                                value={selectedShift.shift_id}
                                                onChange={(e: any) => handleSelectedShift(e)}
                                                // onBlur={handleBlur}
                                                // isInvalid={touched.date_range && !!errors.date_range}

                                            >
                                                <option selected disabled value="0">Select Shift</option>
                                                <option value="-1">Rest Day</option>
                                                {
                                                    hoursShift.map((shift, index) => (
                                                        <option key={index} value={shift.id}>
                                                            {moment(new Date("2023-01-01 " + shift.time_from ?? "")).format("hh:mm A")} - {moment(new Date("2023-01-01 " + shift.time_to ?? "")).format("hh:mm A")}
                                                            {shift.isEightHourShift == 1 ? " (8 Hours)" : shift.isEightHourShift == 2 ? " (8 Hours + 3 Hours OT)" :
                                                                shift.isEightHourShift == 3 ? " (8 Hours + 1 Hour OT)" : shift.isEightHourShift == 4 ? " (8 Hours + 2 Hours OT)" : ""}
                                                        </option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                {/*<Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseSelectShift}>*/}
                                {/*    Cancel*/}
                                {/*</Button>*/}
                                {/*<Button*/}
                                {/*    type="button"*/}
                                {/*    form="departmentCreate"*/}
                                {/*    variant='primary text-white'*/}
                                {/*    size={'sm'}*/}
                                {/*    className='mx-2'*/}
                                {/*    onClick={handleSaveShift}*/}
                                {/*>*/}
                                {/*    Save*/}
                                {/*</Button>*/}
                            </Modal.Footer>
                        </Modal>

                        {/* ADD NOTES MODAL*/}
                        <Modal className='modal' centered show={showAddNote} onHide={handleCloseAddNote}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Notes</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="mb-3">
                                    <Col sm={12}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                            <Form.Label className='text-muted'>Notes</Form.Label>
                                            <Form.Control
                                                as='textarea'
                                                placeholder='Add notes'
                                                style={{fontSize: '90%'}}
                                                rows={3}
                                                name="notes"
                                                value={notes}
                                                onChange={(e: any) => handleChangeNotes(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseAddNote}>
                                    Cancel
                                </Button>
                                <Button
                                    type="button"
                                    form="departmentCreate"
                                    variant='primary text-white'
                                    size={'sm'}
                                    className='mx-2'
                                    onClick={handleSaveNotes}
                                >
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* EMPLOYEE ASSIGNED LIST MODAL*/}
                        <Modal className='modal' centered show={showEmployeeAssignedList} onHide={handleCloseEmployeeAssignedList}>
                            <Modal.Header closeButton>
                                <Modal.Title>Employees On Duty</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='table-responsive'>
                                    <Table className='mt-3'>
                                        <thead>
                                            <tr>
                                                <th className='text-center' style={{width: '10%'}}>No.</th>
                                                <th className='text-center' style={{width: '30%'}}>Employee</th>
                                                <th className='text-center' style={{width: '30%'}}>Shift</th>
                                                <th className='text-center'>Branch</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                employeesAssigned.length > 0 ?
                                                    employeesAssigned.map((employee, index) => (
                                                        <tr key={index}>
                                                            <td className='text-center'>{from1 + index}</td>
                                                            <td>
                                                                <Stack direction='row' style={{cursor: 'pointer'}}>
                                                                    <div>
                                                                        {
                                                                            employee.photo != "" ?
                                                                                <Avatar
                                                                                    sx={{ width: 20, height: 20 }}
                                                                                    src={process.env.REACT_APP_IMAGE_URL+ employee.photo}
                                                                                    className={"profile-avatar"}
                                                                                ></Avatar> :
                                                                                <Avatar
                                                                                    sx={{ width: 20, height: 20}}
                                                                                    src={""}
                                                                                    className={"profile-avatar"}
                                                                                ></Avatar>
                                                                        }
                                                                    </div>
                                                                    <div>&nbsp;{employee.last_name}, {employee.first_name}</div>
                                                                </Stack>

                                                            </td>
                                                            <td>{moment(new Date("2023-01-01 " + employee.start_from)).format("hh:mm A")} - {moment(new Date("2023-01-01 " + employee.end_to)).format("hh:mm A")}</td>
                                                            <td>{employee.branch_name}</td>
                                                        </tr>
                                                    ))
                                                    : <tr><td colSpan={3}>No Data Available.</td></tr>
                                            }

                                        </tbody>
                                    </Table>
                                </div>
                                <Row className="mt-2">
                                    <Col sm={6}>
                                        <Pagination
                                            itemsCount={itemsCount1}
                                            itemsPerPage={itemsPerPage1}
                                            currentPage={currentPage1}
                                            lastPage={lastPage1}
                                            setCurrentPage={setCurrentPage1}
                                            alwaysShown={false}
                                            pageClicked={(page: number) => {
                                                handleShowEmployeesAssigned(page, actualDate, entriesEmployeesAssigned);
                                            }
                                            }
                                        />
                                    </Col>
                                    <Col sm={6} className='right-align d-flex justify-content-start hidden'>
                                        <Form.Group >
                                            <Form.Select
                                                size={'sm'}
                                                name="entries"
                                                value={entriesEmployeesAssigned}
                                                onChange={handleChangeEntriesEmployeesAssigned}
                                                style={{width: '100%'}}
                                            >
                                                <option value="10" selected>10</option>
                                                <option value="25" >25</option>
                                                <option value="50" >50</option>
                                                <option value="100" >100</option>

                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseEmployeeAssignedList}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* EMPLOYEE LEAVE LIST MODAL*/}
                        <Modal className='modal' centered show={showEmployeeLeaveList} onHide={handleCloseEmployeeLeaveList}>
                            <Modal.Header closeButton>
                                <Modal.Title>Employees On Leave</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='table-responsive'>
                                    <Table className='mt-3'>
                                        <thead>
                                        <tr>
                                            <th className='text-center' style={{width: '10%'}}>No.</th>
                                            <th className='text-center' style={{width: '30%'}}>Employee</th>
                                            <th className='text-center' style={{width: '15%'}}>Leave</th>
                                            <th className='text-center' style={{width: '45%'}}>Reason</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            employeesLeave.length > 0 ?
                                                employeesLeave.map((employee, index) => (
                                                    <tr key={index}>
                                                        <td className='text-center'>{from2 + index}</td>
                                                        <td>
                                                            <Stack direction='row' style={{cursor: 'pointer'}}>
                                                                <div>
                                                                    {
                                                                        employee.photo != "" ?
                                                                            <Avatar
                                                                                sx={{ width: 20, height: 20 }}
                                                                                src={process.env.REACT_APP_IMAGE_URL+ employee.photo}
                                                                                className={"profile-avatar"}
                                                                            ></Avatar> :
                                                                            <Avatar
                                                                                sx={{ width: 20, height: 20}}
                                                                                src={""}
                                                                                className={"profile-avatar"}
                                                                            ></Avatar>
                                                                    }
                                                                </div>
                                                                <div>&nbsp;{employee.last_name}, {employee.first_name}</div>
                                                            </Stack>

                                                        </td>
                                                        <td>{employee.leave_type_name}</td>
                                                        <td>{employee.purpose}</td>
                                                    </tr>
                                                ))
                                                : <tr><td colSpan={3}>No Data Available.</td></tr>
                                        }

                                        </tbody>
                                    </Table>
                                </div>
                                <Row className="mt-2">
                                    <Col sm={6}>
                                        <Pagination
                                            itemsCount={itemsCount2}
                                            itemsPerPage={itemsPerPage2}
                                            currentPage={currentPage2}
                                            lastPage={lastPage2}
                                            setCurrentPage={setCurrentPage2}
                                            alwaysShown={false}
                                            pageClicked={(page: number) => {
                                                handleShowEmployeeLeave(page, actualDate, entriesEmployeesLeave);
                                            }
                                            }
                                        />
                                    </Col>
                                    <Col sm={6} className='right-align d-flex justify-content-start hidden'>
                                        <Form.Group >
                                            <Form.Select
                                                size={'sm'}
                                                name="entries"
                                                value={entriesEmployeesLeave}
                                                onChange={handleChangeEntriesEmployeesLeave}
                                                style={{width: '100%'}}
                                            >
                                                <option value="10" selected>10</option>
                                                <option value="25" >25</option>
                                                <option value="50" >50</option>
                                                <option value="100" >100</option>

                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseEmployeeLeaveList}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* EMPLOYEE ASSIGN FORM MODAL*/}
                        <Modal className='modal' centered show={showAssignedEmployeeForm} onHide={handleCloseAssignedEmployeeForm}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add Schedule</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className='mb-2'>
                                    <Col sm={2}></Col>
                                    <Col sm={8}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                            <Form.Label className='text-muted'>Date</Form.Label>
                                            <Form.Control
                                                placeholder='Date'
                                                style={{fontSize: '90%'}}
                                                name="actual_date"
                                                value={moment(newScheduleData.actual_date).format(dateFormat)}
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Col sm={2}></Col>
                                    <Col sm={8}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                            <Form.Label className='text-muted'>Employee</Form.Label>
                                            <Select
                                                name="employee_id"
                                                value={selectedEmployeeOptionAssignSchedule}
                                                options={employeeOptions}
                                                onChange={(e: any) => {
                                                    handleChangeDropdownAssignedEmployee(e);
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Col sm={2}></Col>
                                    <Col sm={8}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                            <Form.Label className='text-muted'>Branch</Form.Label>
                                            <Form.Select
                                                size={'sm'}
                                                name="branches"
                                                value={newScheduleData.branch_id}
                                                onChange={handleChangeBranch}
                                                style={{width: '100%'}}
                                            >
                                                <option value="10" selected>Select Branch</option>
                                                {
                                                    branches.map((branch, index) => (
                                                        <option key={index} value={branch.branch_id} >{branch.branch_name}</option>
                                                    ))
                                                }


                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mb-2'>
                                    <Col sm={2}></Col>
                                    <Col sm={8}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                            <Form.Label className='text-muted'>Shift</Form.Label>
                                            <Form.Select
                                                size={'sm'}
                                                name="shift_id"
                                                value={newScheduleData.shift_id}
                                                onChange={handleChangeShift}
                                                style={{width: '100%'}}
                                            >
                                                <option selected disabled value="0">Select Shift</option>
                                                <option value="-1">Rest Day</option>
                                                {
                                                    hoursShift.map((shift, index) => (
                                                        <option key={index} value={shift.id}>
                                                            {moment(new Date("2023-01-01 " + shift.time_from ?? "")).format("hh:mm A")} - {moment(new Date("2023-01-01 " + shift.time_to ?? "")).format("hh:mm A")}
                                                            {shift.isEightHourShift == 1 ? " (8 Hours)" : shift.isEightHourShift == 2 ? " (8 Hours + 3 Hours OT)" :
                                                                shift.isEightHourShift == 3 ? " (8 Hours + 1 Hour OT)" : shift.isEightHourShift == 4 ? " (8 Hours + 2 Hours OT)" : ""}
                                                        </option>
                                                    ))
                                                }

                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col sm={2}></Col>
                                    <Col sm={8}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                            <Form.Label className='text-muted'>Notes</Form.Label>
                                            <Form.Control
                                                as='textarea'
                                                placeholder='Add notes'
                                                style={{fontSize: '90%'}}
                                                rows={3}
                                                name="notes"
                                                value={newScheduleData.notes}
                                                onChange={(e: any) => setNewScheduleData({...newScheduleData, notes: e.target.value})}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='outline-primary' className='mx-2' size={'sm'} onClick={handleCloseAssignedEmployeeForm}>
                                    Close
                                </Button>
                                <Button
                                    type="button"
                                    form="departmentCreate"
                                    variant='primary text-white'
                                    size={'sm'}
                                    className='mx-2'
                                    onClick={handleSaveNewSchedule}
                                >
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}
